import React, {useEffect, useState} from "react";
import CustomModal from "../../../../global/components/CustomModal";
import {Col, Divider, Empty, Row} from "antd";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {BookingsPaymentsURL} from "../../../../global/urls";
import Hide from "../../../../global/components/Hide";
import {Loading} from "../../../../global/components/Loading";
import {AddButton} from "../../../../global/components/AddButton";
import {BookingPaymentRow} from "./BookingPaymentRow";
import moment from "moment";
import {generateRandomString, isValidValue, openNotification} from "../../../../global/helper_functions";
import {ModelActionButton} from "../../../../global/components/ModelActionButton";
import {payment_methods} from "../../../../global/variables";
import {isAgent} from "../../../../global/auth_functions";
import {StaticLanguageTabs} from "../../../../global/components/StaticLanguageTabs";
import {checkPermission} from "../../../../global/permissions";
import {permission_data} from "../../../../global/permissions_data";

export const BookingItemPaymentsModal = (props) => {
    const booking_item = props.booking_item || "";
    const booking_item_data = props.booking_item_data || {};
    const [is_agent] = useState(isAgent());
    const [data, setData] = useState([]);
    const [language, setLanguage] = useState(props.language || "en");
    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState([]);
    const [error, setError] = useState({});
    const [edit_rows, setEditRows] = useState({});
    const [can_edit_paid] = useState(checkPermission(permission_data.booking_payment.edit_paid));

    const fetchData = async () => {
        await setLoading(true);

        let response = await httpRequestWithAuth(BookingsPaymentsURL, "GET", null, {booking_item});

        if (response.status) {
            setData(response.data);
            setHelperText(new Array(response.data.length).fill({}));
        }
        await setLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, [])


    const handleFieldChange = (name, value, index) => {
        let temp_data = [...data];
        temp_data[index][name] = value
        setData(temp_data);
    }


    const addPayment = () => {
        const new_payment = {
            BookingItemId: booking_item,
            id: `#temp-${generateRandomString(6)}`,
            title_en: "",
            payment_type: "Payment",
            payment_status: "Pending",
            amount: 0,
            currency: booking_item_data.currency,
            payment_deadline: moment(),
        }


        setData([...data, new_payment])
        setHelperText([...helper_text, {}])
    }

    const onRemove = (row_data, index) => {

        const temp_data = [...data];
        temp_data.splice(index, 1);
        setData(temp_data);
    }


    const checkData = () => {
        let temp_helper_text = [];
        let error = false;

        for (let i = 0; i < data.length; i++) {
            const payment_data = data[i];
            const payment_helper_text = {};

            if (!isValidValue(payment_data.title_ar)) {
                payment_data.title_ar = payment_data.title_en
            }

            if (!isValidValue(payment_data.title_en)) {
                payment_data.title_en = payment_data.title_ar
            }

            if (!isValidValue(payment_data.title_en)) {
                payment_helper_text.title_en = "this field is required"
            }

            if (!isValidValue(payment_data.title_ar)) {
                payment_helper_text.title_ar = "this field is required"
            }

            if (!isValidValue(payment_data.amount)) {
                payment_helper_text.amount = "this field is required"
            }

            if (!["Paid", "Pending"].includes(payment_data.payment_status)) {
                payment_helper_text.payment_status = "this field is required"
            }

            if ((payment_data.payment_status === "Paid" || isValidValue(payment_data.payment_method))) {
                const option = payment_methods.filter(option => option.value === payment_data.payment_method);
                if (option.length !== 1) {
                    payment_helper_text.payment_method = "this field is required"
                }
            }

            if (payment_data.payment_status === "Paid" && !isValidValue(payment_data.payment_ref)) {
                payment_helper_text.payment_ref = "this field is required"
            }


            if (!isValidValue(payment_data.payment_deadline)) {
                payment_helper_text.payment_deadline = "this field is required"
            }


            if (payment_data.payment_status === "Paid" && !isValidValue(payment_data.payment_date)) {
                payment_helper_text.payment_date = "this field is required"
            }

            if (Object.keys(payment_helper_text).length > 0) {
                error = true;
            }

            temp_helper_text.push(payment_helper_text);
        }

        setHelperText(temp_helper_text);
        return !error
    }

    const onSave = async () => {
        setLoading(true);
        setHelperText(new Array(data.length).fill({}));
        setError({})

        let temp_payment = [];
        data.map(payment => temp_payment.push({
            ...payment,
            payment_deadline: isValidValue(payment.payment_deadline) ? moment(payment.payment_deadline).format("DD-MM-YYYY hh:mm") : "",
            payment_date: isValidValue(payment.payment_date) ? moment(payment.payment_date).format("DD-MM-YYYY hh:mm") : "",
        }));

        const error = checkData();

        if (error) {
            let response = await httpRequestWithAuth(BookingsPaymentsURL, "PUT", {
                booking_item: booking_item,
                payments_data: temp_payment
            })
            if (response.status) {
                openNotification("success", "Update Booking Payment", `Payment has been updated`)
                props.handleModelClose();
            } else {
                setHelperText(response.error_feedback.payments || helper_text);
                setError(response.error_feedback)
            }
        }

        setLoading(false);
    }


    const handleEditRow = (id) => {
        let temp_edit_rows = {...edit_rows}
        if (temp_edit_rows[id]) {
            delete temp_edit_rows[id]
        } else {
            temp_edit_rows[id] = true;
        }

        setEditRows(temp_edit_rows)
    }


    const handleLanguageChange = (new_value) => {
        setLanguage(new_value);
    };

    return (
        <CustomModal size="lg" title="Booking Payments" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>
                <Hide hide={!loading}>
                    <Col xs={24}>
                        <Loading/>
                    </Col>
                </Hide>

                <Hide hide={loading}>
                    <Col xs={24}>
                        <StaticLanguageTabs value={language} onChange={handleLanguageChange}/>
                    </Col>

                    <Hide hide={props.is_full_paid}>
                        <Col xs={24} className="text-end">
                            <AddButton onClick={addPayment} block={false}>Add Payment</AddButton>
                        </Col>

                    <Divider className="m-0 border-2"/>
                    </Hide>

                    <Col xs={24}>

                        {data.map((payment_data, index) => {
                            const payment_helper_text = index < helper_text.length ? helper_text[index] : {};

                            return (<BookingPaymentRow
                                index={index}
                                is_edit={edit_rows[payment_data.id]}
                                setIsEdit={handleEditRow}
                                is_agent={is_agent}
                                language={language}
                                payment_data={payment_data}
                                handleFieldChange={handleFieldChange}
                                helper_text={payment_helper_text}
                                removePayment={onRemove}
                                is_full_paid={props.is_full_paid}
                            />)
                        })}
                    </Col>


                    <Hide hide={data.length !== 0}>
                        <Col xs={24}>
                            <Empty/>
                        </Col>
                    </Hide>


                    <Col xs={24} className="p-0">
                        <ModelActionButton
                            error_msg={error.non_field_errors}
                            loading={loading}

                            onSave={(!props.is_full_paid || can_edit_paid) && onSave}
                            onCancel={props.handleModelClose}
                        />
                    </Col>

                </Hide>


            </Row>
        </CustomModal>
    )
}
