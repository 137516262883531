import React, {useEffect, useRef, useState} from "react";
import {checkPermission} from "../../../global/permissions";
import {permission_data} from "../../../global/permissions_data";
import {isValidValue} from "../../../global/helper_functions";
import {Col, Row, Tabs} from "antd";
import Hide from "../../../global/components/Hide";
import {CustomTabPane} from "../../../global/components/CustomTabPane";
import {getUserType, isAdmin} from "../../../global/auth_functions";
import {DigitalGiftVoucherDetails} from "./DigitalGiftVoucherDetails";
import {CustomizeVoucher} from "../CustomizeVoucher";
import {Bookings} from "../../bookings/Bookings";
import {ProductsSales} from "../../products/products_sales/ProductsSales";

export const DigitalGiftVouchersInfo = props => {
    const slug = props.slug || "";
    const url_params = props.match.params || {};
    const [voucher_id] = React.useState(url_params.id || 'new');
    const url_query = new URLSearchParams(props.location.search);
    const tab = (url_query.get("tab") || "1") + "";
    const [user_type] = useState(getUserType());
    const [loading, setLoading] = useState(false);
    const [current_tab, setCurrentTab] = useState("1");
    const [is_new, setIsNew] = useState(voucher_id === "new");
    const [voucher_data, setVoucherData] = useState({});
    const is_admin = isAdmin();
    const [language] = useState("en");


    useEffect(() => {
        if ((!isValidValue(url_params.id) && props.location.pathname !== `/${slug}/vouchers/digital-gift/new`) || (voucher_id !== "new" && !checkPermission(permission_data.voucher.view)) || (voucher_id === "new" && !checkPermission(permission_data.voucher.add))) {
            props.history.push(`/${slug}/vouchers/digital-gift`);
        }
        setCurrentTab(tab);
    }, []);

    const handleLoading = (value) =>{
        if (value){
            setTimeout(()=>{
                setLoading(false);
            },800)
        }else{
            setLoading(true)
        }
    }

    const handleTabsChange = (new_value, extra_params={}) => {
        handleLoading(true);
        new_value = new_value + "";
        let params_attributes = {tab: new_value, ...extra_params};
        const params = new URLSearchParams(params_attributes);

        props.history.push({
            pathname: props.location.pathname,
            search: params.toString()
        });

        setCurrentTab(new_value);
        handleLoading(false);
    };


    return (
        <>
            <div className="m-0 p-0 d-flex justify-content-between">
                <h5 className="mb-3 mt-2">{voucher_id === "new" ? "Add Digital Gift Voucher" : "Edit Digital Gift Voucher"}</h5>
            </div>


            <div className="paper pt-0 w-100">

                <Tabs activeKey={current_tab} onChange={handleTabsChange}>
                    <CustomTabPane tab="Details" key="1"/>
                    <CustomTabPane tab="Customize Voucher" key="2" disabled={is_new}/>
                    <CustomTabPane tab="Bookings Usage" key="3"
                                   disabled={is_new || !checkPermission(permission_data.booking.view)}/>
                    <CustomTabPane tab="Product Sales Usage" key="4"
                                   disabled={is_new || !checkPermission(permission_data.product_sale.view)}/>
                </Tabs>

                    <Hide hide={current_tab !== '1'}>
                        <DigitalGiftVoucherDetails
                            {...props}
                            is_new={is_new}
                            user_type={user_type}
                            voucher_id={voucher_id}
                            setVoucherData={setVoucherData}
                            handleTabsChange={handleTabsChange}
                        />
                    </Hide>

                    <Hide hide={current_tab !== '2'}>
                        <CustomizeVoucher
                            {...props}
                            is_new={is_new}
                            user_type={user_type}
                            voucher_id={voucher_id}
                            voucher_data={voucher_data}
                            setVoucherData={setVoucherData}
                            handleTabsChange={handleTabsChange}
                        />
                    </Hide>


                <Hide hide={current_tab !== '3'}>
                    <Bookings
                        {...props}
                        tab_view
                        user_type={user_type}
                        is_admin={is_admin}
                        language={language}
                        voucher_id={voucher_id}
                        interval="All"
                    />
                </Hide>

                <Hide hide={current_tab !== '4'}>
                    <ProductsSales
                        {...props}
                        tab_view
                        user_type={user_type}
                        is_admin={is_admin}
                        language={language}
                        voucher_id={voucher_id}
                    />
                </Hide>

            </div>

        </>)
}
