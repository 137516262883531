import {Col, Image, Row} from "antd";
import {CustomDynamicTable} from "../../../global/components/CustomDynamicTable";
import React, {useState} from "react";
import {TrustedPartnersURL} from "../../../global/urls";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {openNotification} from "../../../global/helper_functions";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import {AddButton} from "../../../global/components/AddButton";
import {TrustedPartnerFormModal} from "./components/TrustedPartnerFormModal";
import {IsActiveView} from "../../../global/components/IsActiveView";
import Hide from "../../../global/components/Hide";


export const TrustedPartners = props =>{
    const [open_from_data, setOpenFormData] = useState(false);
    const [form_data, setFormData] = useState({});
    const [reload, setReload] = useState(false);

    const [trusted_partner_perm] = useState({
        delete: checkPermission(permission_data.trusted_partner.delete),
        edit: checkPermission(permission_data.trusted_partner.edit),
        add: checkPermission(permission_data.trusted_partner.add),
    });

    const [columns] = useState([
        {
            title: "Logo",
            dataIndex: "display_logo",
            width: "25%",
            align: "center",
            render:(value)=>(
                <Image
                    src={value}
                    style={{width:200}}
                />
            )
        },
        {
            title: "Title EN",
            dataIndex: "title_en",
            width: "25%"
        },
        {
            title: "Title Ar",
            dataIndex: "title_ar",
            width: "25%"
        },
        {
            title: "Order",
            dataIndex: "order",
            width: "10%",
            align:"center",
        },

        {
            title: "Activate",
            dataIndex: "is_active",
            width: "5%",
            align:"center",
            render: (value)=><IsActiveView value={value} view_only={!trusted_partner_perm.edit}/>
        },
    ])

    const handelOpenFormData = (data={})=>{
        setFormData(data)
        setOpenFormData(true)
    }

    const handelCloseFormData = (reload_data=false)=>{
        setFormData({});
        setOpenFormData(false);

        if (reload_data){
            setReload(Math.random());
        }
    }

    const onDelete = async (row_data) =>{
        let response = await httpRequestWithAuth(`${TrustedPartnersURL}${row_data.id}`, "DELETE");
        if (response.status){
            openNotification("success", "Delete Trusted Partners", `${row_data.title_en} trusted partner has been deleted`)
        }else{
            openNotification("error", "Delete Trusted Partners", response.error_feedback.non_field_errors)
        }
    }

    const onEdit = async (row_data) =>{
        handelOpenFormData(row_data)
    }

    const onAdd = async () =>{
        handelOpenFormData()
    }

    const freeAction = (
        <>
            <AddButton onClick={onAdd}>Add Partner</AddButton>
        </>
    );

    return(
        <Row>
            <Hide hide={!open_from_data}>
                <TrustedPartnerFormModal
                    open={open_from_data}
                    data={form_data}
                    handleModelClose={handelCloseFormData}
                />
            </Hide>

            <Col xs={24} className="paper">
                <CustomDynamicTable
                    title="Trusted Partners"
                    reload_data={reload}
                    columns={columns}
                    fetch_url={TrustedPartnersURL}
                    free_action={freeAction}
                    onDelete={trusted_partner_perm.delete && onDelete}
                    onEdit={trusted_partner_perm.edit && onEdit}
                />
            </Col>
        </Row>
    )
}
