import DynamicAutocomplete from "./DynamicAutocomplete";
import {nationalities_list} from "../../variables";


export const NationalityAutoComplete = props =>{
    return(
        <DynamicAutocomplete
            {...props}
            title_key="id"
            mode={props.multiple ? "multiple":""}
            static_options={nationalities_list}
        />
    )
}










