import React, {useEffect, useState} from "react";
import { DatePicker, Form } from "antd";
import moment from "moment";
import CustomFieldWrapper from "../CustomFieldWrapper";
import {customMoment, isValidValue} from "../../helper_functions";
import {CustomTextField} from "./CustomTextField";

export const CustomDateTimeField = (props) => {
    const format = props.format || "DD-MM-YYYY hh:mm A";

    const [date_value, setDateValue] = useState(undefined);
    const [formatted_date, setFormattedDate] = useState(undefined);

    const handleFieldChange = async (date) => {
    if (!props.view_only && !props.disabled) {
      await props.onChange(props.name, isValidValue(date)?moment(date):"");
    }
  };


    useEffect(()=>{
        if (isValidValue(props.value)){
            const new_date = customMoment(props.value);

        if (new_date.isValid()){
            setDateValue(new_date);
            setFormattedDate(new_date.format(format));
        }else{
            setDateValue(undefined)
            console.error(props.name, "not valid date")
        }
        }else{
            setDateValue(undefined)
        }

    },[props.value])


    if (props.view_only || props.disabled){
        return <CustomTextField {...props} value={formatted_date}/>
    }

    return (
    <CustomFieldWrapper {...props} value={formatted_date}>
      <DatePicker
        {...props}
        getPopupContainer={(trigger) => trigger.parentNode}
        value={date_value}
        onChange={handleFieldChange}
        onSelect={handleFieldChange}
        showTime={{ format: "HH:mm A" }}
        use12Hours={true}
        format={format}

        // disabledDate={(current) => {
        //   let customDate = moment().format("DD-MM-YYYY HH:mm");
        //   return current && current < moment(customDate, "DD-MM-YYYY HH:mm");
        // }}
        className={`w-100 ${props.className}`}
        status={props.error ? "error" : null}
      />
    </CustomFieldWrapper>
  );
};
