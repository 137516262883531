import React, {useEffect, useState} from "react";
import CustomModal from "../../../../global/components/CustomModal";
import {Col, Row} from "antd";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {BookingUpdateTransferredAmountToAgencyURL} from "../../../../global/urls";
import Hide from "../../../../global/components/Hide";
import {Loading} from "../../../../global/components/Loading";
import {openNotification} from "../../../../global/helper_functions";
import {ModelActionButton} from "../../../../global/components/ModelActionButton";
import {CustomTextField} from "../../../../global/components/fields/CustomTextField";

export const UpdateBookingTransferredAmount = (props) => {
    const booking_item = props.booking_item || "";
    const data = props.data || {};
    const [transferred_amount_to_agency, setTransferredAmountToAgency] = useState(props.current_value);
    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});

    const handelFieldChange = (value) =>{
        setTransferredAmountToAgency(value);
    }

    const onSave = async () =>{
         setLoading(true);
        setHelperText({})

             let response = await httpRequestWithAuth(`${BookingUpdateTransferredAmountToAgencyURL}${booking_item}`, "PATCH", {transferred_amount_to_agency, booking_item})

             if (response.status){
                 openNotification("success", "Update Booking Transferred Amount", `Transferred amount has been updated`)
                 props.handleModelClose();
             }else{
                 setHelperText(response.error_feedback)
             }

         setLoading(false);
    }




    return(
        <CustomModal title="Update Booking Transferred Amount" visible={props.open} onCancel={props.handleModelClose} size="small">
            <Row gutter={[12, 10]}>

                <Hide hide={!loading}>
                    <Col xs={24}>
                        <Loading/>
                    </Col>
                </Hide>

                <Hide hide={loading}>

                    <Col xs={24}>
                        <CustomTextField
                            label="Transferred Amount To Agency"
                            value={transferred_amount_to_agency}
                            onChange={(name, value)=>handelFieldChange(value)}
                            // error={helper_text.transferred_amount_to_agency !== undefined}
                            // helper_text={helper_text.transferred_amount_to_agency}
                        />
                    </Col>



                    <Col xs={24} className="p-0">
                        <ModelActionButton
                            error_msg={helper_text.non_field_errors}
                            loading={loading}
                            onSave={onSave}
                            onCancel={props.handleModelClose}
                        />
                    </Col>

                </Hide>



            </Row>
        </CustomModal>
        )
}
