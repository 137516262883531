import React, {useEffect, useState} from "react";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {VouchersURL} from "../../../global/urls";
import {
    customMoment,
    generateRandomString,
    isValidValue,
    openNotification,
    showConfirm,
    showError
} from "../../../global/helper_functions";
import {Button, Col, Divider, Row} from "antd";
import {ActionButton} from "../../../global/components/ActionButton";
import CustomSelectField from "../../../global/components/fields/CustomSelectField";
import {CustomTextField} from "../../../global/components/fields/CustomTextField";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import moment from "moment";
import {HELoading} from "../../../global/components/HELoading";
import {CustomDateTimeField} from "../../../global/components/fields/CustomDateTimeField";
import CustomCheckBox from "../../../global/components/fields/CustomCheckBox";
import Hide from "../../../global/components/Hide";
import {DownOutlined, SmileOutlined, UpOutlined} from "@ant-design/icons";
import {payment_methods} from "../../../global/variables";
import CustomDropDown from "../../../global/components/CustomDropDown";
import {CustomTextAreaField} from "../../../global/components/fields/CustomTextAreaField";



const deducts_from_options = [
    {title: "Hello Explorer", value: "HelloExplorer"},
    {title: "Agency", value: "Agency"},
    {title: "HelloExplorer & Agency", value: "HelloExplorer & Agency"},
];

const discount_type_options = [
    {title: "Fixed", value: "Fixed"},
    {title: "Percentage", value: "Percentage"},
];




export const DigitalGiftVoucherDetails = props => {
    const is_new = props.is_new;
    const voucher_id = props.voucher_id || "";
    const slug = props.slug || "";

    const [has_perm] = useState(checkPermission(permission_data.voucher.edit) || (voucher_id === "new" && checkPermission(permission_data.voucher.add)));
    const [data, setData] = useState({
        code: generateRandomString(8),
        discount_type: "Percentage",
        digital_gift_amount: 0,
        service_charge: 0,
        price: 0,
        net_total: 0,
        discount_amount: 5,
        deducts_from: "Agency",
        start_date: moment().utc(true),
        end_date: moment().utc(true).add(10, "days"),
        total_number_of_use: 100,
        number_used_per_user: 100,
        minimum_item: 1,
        minimum_spend: 1,
        maximum_spend: 30000,
        maximum_discount_amount: 30000,
        is_active: true,
    });

    const [show_advance_options, setShowAdvanceOptions] = useState(false);
    const [loading, setLoading] = useState(true);
    const [helper_text, setHelperText] = useState({});

    const actions_options = [
        {
            key: '1',
            label: "Send Payment Receipt Email",
            disabled: !data.is_paid,
            icon: <SmileOutlined/>,
        },
        {
            key: '2',
            label: "Send Payment Request Email",
            icon: <SmileOutlined/>,
            disabled: data.is_paid,
        },
        {
            key: '3',
            label: "Generate Payment Link",
            icon: <SmileOutlined/>,
            disabled: data.is_paid,

        },
    ];


    const handelActionButtonOptions = ({key}) => {
        if (key === "1") {
        }
    }

    const fetchData = async () => {
        let response = await httpRequestWithAuth(`${VouchersURL}${voucher_id}`, "GET");
        if (response.status) {
            setData(response.data);
            props.setVoucherData(response.data);
        } else {
            showError("Get Digital Gift Voucher Data", response.error_feedback.non_field_errors);
            onCancel();
        }
        setLoading(false);
    }


    const handleFieldChange = (name, value) => {
        let temp_data = {...data};
        temp_data[name] = value;
        setData(temp_data);
    }


    useEffect(() => {
        if (!is_new) {
            fetchData();
        } else {
            setLoading(false);
        }

    }, [])


    const onCancel = () => {
        props.history.push(`/${slug}/vouchers/digital-gift`);
    }


    const onDelete = async () => {
        await setLoading(true);
        let response = await httpRequestWithAuth(`${VouchersURL}${data.id}`, "DELETE");
        if (response.status) {
            openNotification("success", "Delete Digital Gift Voucher", `${data.title} voucher has been deleted`)
            onCancel();
        } else {
            openNotification("error", "Delete Digital Gift Voucher", response.error_feedback.non_field_errors)
        }
        await setLoading(false);
    }


    const handleDelete = () => {
        showConfirm("Delete Digital Gift Voucher", `Are you sure to delete ${data.title} voucher ?`, onDelete);
    }


    const onSave = async () => {
        await setLoading(true);

        let temp_data = {
            ...data,
            start_date: isValidValue(data.start_date) ? customMoment(data.start_date).format("DD-MM-YYYY hh:mm") : "",
            end_date: isValidValue(data.end_date) ? customMoment(data.end_date).format("DD-MM-YYYY hh:mm") : "",
        }

        let response;
        if (is_new) {
            response = await httpRequestWithAuth(VouchersURL, "POST", temp_data);
        } else {
            response = await httpRequestWithAuth(`${VouchersURL}${data.id}`, "PUT", temp_data);
        }

        if (response.status) {
            if (is_new) {
                openNotification("success", "Add Voucher", `${data.title} voucher has been added`);
                // props.history.push(`/${slug}/vouchers/digital-gift/${response.data.id}/?tab=2`);
                onCancel();
            } else {
                openNotification("success", "Edit Digital Gift Voucher", response.data.msg || `${data.title} voucher has been updated`);
                onCancel();
            }


        } else {
            setHelperText(response.error_feedback)
        }

        await setLoading(false);
    }

    if (loading) {
        return <HELoading/>
    }

    return (
        <>

            <Row gutter={[12, 10]}>



                {/*<Col xs={24} className="d-flex justify-content-end">*/}
                {/*    <CustomDropDown btn_type="primary" label="Actions" options={actions_options}*/}
                {/*                    onChange={handelActionButtonOptions}/>*/}
                {/*</Col>*/}

                <Col xs={24} md={16}>
                    <CustomTextField
                        view_only
                        label="Title"
                        value={data.title}
                    />
                </Col>


                <Col xs={24} md={8}>
                    <CustomTextField
                        view_only
                        label="Code"
                        value={data.code}
                    />
                </Col>

                <Col xs={24} md={8}>
                    <CustomTextField
                        view_only
                        label="Sender Name"
                        value={data.sender_name}
                    />
                </Col>

                <Col xs={24} md={8}>
                    <CustomTextField
                        view_only
                        label="Sender Email"
                        value={data.sender_email}
                    />
                </Col>

                <Col xs={24} md={8}>
                    <CustomTextField
                        view_only
                        label="Sender Contact Number"
                        value={data.sender_contact_number_view}
                    />
                </Col>


                <Col xs={24} md={8}>
                    <CustomTextField
                        view_only
                        label="Recipient Name"
                        value={data.recipient_name}
                    />
                </Col>

                <Col xs={24} md={8}>
                    <CustomTextField
                        view_only
                        label="Recipient Email"
                        value={data.recipient_email}
                    />
                </Col>

                <Col xs={24} md={8}>
                    <CustomTextField
                        view_only
                        label="recipient Contact Number"
                        value={data.recipient_contact_number_view}
                    />
                </Col>

                <Col xs={24} md={8}>
                    <CustomTextField
                        view_only
                        label="Gift Amount"
                        value={data.digital_gift_amount +" KD"}
                    />
                </Col>

                <Col xs={24} md={8}>
                    <CustomTextField
                        view_only
                        label="Balance Amount"
                        value={data.maximum_discount_amount +" KD"}
                    />
                </Col>


                <Col xs={24} md={8}>
                    <CustomTextField
                        required
                        view_only={!has_perm}
                        label="Total Number Of Use"
                        name="total_number_of_use"
                        value={data.total_number_of_use}
                        onChange={handleFieldChange}
                        error={helper_text.total_number_of_use !== undefined}
                        helper_text={helper_text.total_number_of_use}
                        type="number"
                    />
                </Col>


                <Col xs={24} md={12}>
                    <CustomDateTimeField
                        required
                        view_only={!has_perm}
                        label="Start Date"
                        name="start_date"
                        value={data.start_date}
                        onChange={handleFieldChange}
                        error={helper_text.start_date !== undefined}
                        helper_text={helper_text.start_date}
                    />
                </Col>




                <Col xs={24} md={12}>
                    <CustomDateTimeField
                        required
                        view_only={!has_perm}
                        label="End Date"
                        name="end_date"
                        value={data.end_date}
                        onChange={handleFieldChange}
                        error={helper_text.end_date !== undefined}
                        helper_text={helper_text.end_date}
                    />
                </Col>


                <Col xs={24}>
                    <CustomTextAreaField
                        view_only
                        label="Message For Recipient"
                        value={data.message_for_recipient}
                    />
                </Col>



                {/*<Col xs={24}>*/}
                {/*    <CustomCheckBox*/}
                {/*        view_only*/}
                {/*        label="Is Active"*/}
                {/*        name="is_active"*/}
                {/*        value={data.is_active}*/}
                {/*        onChange={handleFieldChange}*/}
                {/*    />*/}
                {/*</Col>*/}

                <Col xs={24} className="mt-2">
                    <Divider orientation="left" orientationMargin="0" className="mt-2 mb-3 fw-bold">
                        Payments Details
                    </Divider>
                </Col>


                <Col xs={24} md={8}>
                    <CustomTextField
                        required
                        view_only
                        label="Price"
                        name="price"
                        value={data.price +" KD"}
                        onChange={handleFieldChange}
                        error={helper_text.price !== undefined}
                        helper_text={helper_text.price}
                    />
                </Col>

                <Col xs={24} md={8}>
                    <CustomTextField
                        required
                        view_only
                        label="Service Charge"
                        name="service_charge"
                        value={data.service_charge +" KD"}
                        onChange={handleFieldChange}
                        error={helper_text.service_charge !== undefined}
                        helper_text={helper_text.service_charge}
                    />
                </Col>

                <Col xs={24} md={8}>
                    <CustomTextField
                        required
                        view_only
                        label="Net Total"
                        name="net_total"
                        value={data.net_total +" KD"}
                        onChange={handleFieldChange}
                        error={helper_text.net_total !== undefined}
                        helper_text={helper_text.net_total}
                    />
                </Col>


                <Col xs={24} md={8}>
                    <CustomSelectField
                        required
                        label="Payment Method"
                        name="payment_method"
                        value={data.payment_method}
                        onChange={handleFieldChange}
                        error={helper_text.payment_method !== undefined}
                        helper_text={helper_text.payment_method}
                        options={payment_methods}
                        view_only={!has_perm}
                        disabled={!data.is_paid}
                        view_value_only={!has_perm || !data.is_paid}
                    />
                </Col>

                <Col xs={24} md={8}>
                    <CustomDateTimeField
                        view_only={!has_perm}
                        label="Payment Date"
                        name="payment_date"
                        value={data.payment_date}
                        onChange={handleFieldChange}
                        helper_text={helper_text.payment_date}
                        error={helper_text.payment_date !== undefined}
                        disabled={!data.is_paid}
                    />
                </Col>

                <Col xs={24} md={8}>
                    <CustomTextField
                        view_only={!has_perm}
                        label="Payment Ref No"
                        name="payment_ref_no"
                        value={data.payment_ref_no}
                        onChange={handleFieldChange}
                        helper_text={helper_text.payment_ref_no}
                        error={helper_text.payment_ref_no !== undefined}
                        disabled={!data.is_paid}
                    />
                </Col>

                <Col xs={24}>
                    <CustomCheckBox
                        name="is_paid"
                        label="Is Paid"
                        value={data.is_paid}
                        onChange={handleFieldChange}
                    />
                </Col>

                <Col xs={24}>
                    <ActionButton
                        error_msg={helper_text.non_field_errors}
                        onDelete={handleDelete}
                        onCancel={onCancel}
                        allow_delete={isValidValue(data.id) && checkPermission(permission_data.voucher.delete)}
                        allow_save={has_perm}
                        onSave={onSave}
                    />
                </Col>

            </Row>
        </>

)
}
