import React, {useEffect, useState} from "react";
import CustomModal from "../../../global/components/CustomModal";
import {Col, Row} from "antd";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {CustomizeVoucherURL} from "../../../global/urls";
import {isValidValue, openNotification, showError} from "../../../global/helper_functions";
import {ModelActionButton} from "../../../global/components/ModelActionButton";
import CustomCheckBox from "../../../global/components/fields/CustomCheckBox";
import {AgencyAutoComplete} from "../../../global/components/fields/AgencyAutoComplete";
import CustomSelectField from "../../../global/components/fields/CustomSelectField";
import {package_type} from "../../../global/variables";
import {TierAutoComplete} from "../../../global/components/fields/TierAutoComplete";
import {PackageCategoryAutoComplete} from "../../../global/components/fields/PackageCategoryAutoComplete";
import {AgencyPackageAutoComplete} from "../../../global/components/fields/AgencyPackageAutoComplete";
import {PackageOptionsAutoComplete} from "../../../global/components/fields/PackageOptionsAutoComplete";
import {PackageOptionsDatesAutoComplete} from "../../../global/components/fields/PackageOptionsDatesAutoComplete";
import Hide from "../../../global/components/Hide";
import {
    PackageOptionsDatesTimesAutoComplete
} from "../../../global/components/fields/PackageOptionsDatesTimesAutoComplete";
import {CustomDateField} from "../../../global/components/fields/CustomDateField";
import {CustomTimeField} from "../../../global/components/fields/CustomTimeField";
import {Loading} from "../../../global/components/Loading";
import {CustomTextAreaField} from "../../../global/components/fields/CustomTextAreaField";
import {ProductAutoComplete} from "../../../global/components/fields/ProductAutoComplete";


const customer_type_options = [
    {title: "All", value: "All"},
    {title: "Non-Registered User", value: "Non-Registered User"},
    {title: "Registered User", value: "Registered User"},
]


const dates_type_options = [
    {title: "No Specific Date", value: "No Specific Date"},
    {title: "General Date", value: "General Date"},
    {title: "General Date Range", value: "General Date Range"},
    {title: "Packages Options Dates", value: "Packages Options Dates"},
]


const default_times_type_options = [
    {title: "No Specific Time", value: "No Specific Time"},
    {title: "General Time", value: "General Time"},
    {title: "General Time Range", value: "General Time Range"},
    {title: "Packages Options Dates Times", value: "Packages Options Dates Times"},
]


export const CustomizeVoucherModal = props => {

    const [times_type_options, setTimesTypeOptions] = useState(default_times_type_options);
    const [data, setData] = useState({
        dates_type: "No Specific Date",
        times_type: "No Specific Time",
        package_type: "All",
        customer_type: "All",
        custom_for: "All",
        is_active: true,
        AgencyId: "",
        packages_id: [],
        packages_options_id: [],
        packages_options_dates_id: [],
        packages_options_dates_times_id: [],
        ...props.data
    });


    const [package_type_options] = useState([
        {title: "All", value: "All"},
        ...package_type
    ]);

    const [custom_for_options] = useState([
        {title: "All", value: "All"},
        {title: "Packages", value: "Packages"},
        {title: "Products", value: "Products"},
    ]);

    const [loading, setLoading] = useState(false);
    const [fetch_loading, setFetchLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});

    useEffect(() => {
        if (data.dates_type === "Packages Options Dates") {
            setTimesTypeOptions(default_times_type_options);
        } else {
            setTimesTypeOptions(default_times_type_options.slice(0, 3));
        }
    }, [data.dates_type]);


    useEffect(() => {
        const fetchData = async () => {
            setFetchLoading(true);
            let response = await httpRequestWithAuth(`${CustomizeVoucherURL}${data.id}`, "GET");
            if (response.status) {
                setData(response.data);
            } else {
                showError("Fetch data failed", response.error_feedback.non_field_errors);
            }

            setFetchLoading(false);
        }

        if (isValidValue(data.id)) {
            fetchData();
        }
    }, [])

    const handleFieldChange = (name, value) => {
        const temp_data = {...data};
        temp_data[name] = value || "";

        if (name === "dates_type" && value !== "Packages Options Dates" && temp_data.times_type === "Packages Options Dates Times") {
            temp_data.times_type = "No Specific Time";
        }

        setData(temp_data);
    }

    const onSave = async () => {
        await setLoading(true);


        const is_new = !isValidValue(data.id);
        let response;

        if (is_new) {
            response = await httpRequestWithAuth(CustomizeVoucherURL, "POST", data);
        } else {
            response = await httpRequestWithAuth(`${CustomizeVoucherURL}${data.id}`, "PUT", data);
        }

        if (response.status) {
            if (is_new) {
                openNotification("success", "Add Voucher Customize Voucher", `${data.title} customize voucher has been added`)
            } else {
                openNotification("success", "Update Voucher Customize Voucher", `${data.title} customize voucher has been updated`)
            }
            props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
        }

        await setLoading(false);

    }

    return (
        <CustomModal title="Customize Voucher Form" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>

                <Hide hide={!fetch_loading}>
                    <Loading/>
                </Hide>

                <Hide hide={fetch_loading}>

                    <Col xs={24} md={12}>
                        <CustomSelectField
                            label="For"
                            name="custom_for"
                            value={data.custom_for}
                            onChange={handleFieldChange}
                            error={helper_text.custom_for !== undefined}
                            helper_text={helper_text.custom_for}
                            options={custom_for_options}
                        />
                    </Col>


                    <Hide hide={data.custom_for !== "Packages"}>
                        <Col xs={24} md={12}>
                            <CustomSelectField
                                label="Package Type"
                                name="package_type"
                                value={data.package_type}
                                onChange={handleFieldChange}
                                error={helper_text.package_type !== undefined}
                                helper_text={helper_text.package_type}
                                options={package_type_options}
                            />
                        </Col>
                    </Hide>


                    <Col xs={24} md={12}>
                        <CustomSelectField
                            label="Customer Type"
                            name="customer_type"
                            value={data.customer_type}
                            onChange={handleFieldChange}
                            error={helper_text.customer_type !== undefined}
                            helper_text={helper_text.customer_type}
                            options={customer_type_options}
                        />
                    </Col>


                    <Hide hide={data.custom_for !== "Packages"}>
                        <Col xs={24} md={12}>
                            <CustomSelectField
                                label="Dates Type"
                                name="dates_type"
                                value={data.dates_type}
                                onChange={handleFieldChange}
                                error={helper_text.dates_type !== undefined}
                                helper_text={helper_text.dates_type}
                                options={dates_type_options}
                            />
                        </Col>
                    </Hide>

                    <Hide hide={data.custom_for !== "Packages"}>
                        <Col xs={24} md={12}>
                            <CustomSelectField
                                label="Times Type"
                                name="times_type"
                                value={data.times_type}
                                onChange={handleFieldChange}
                                error={helper_text.times_type !== undefined}
                                helper_text={helper_text.times_type}
                                options={times_type_options}
                            />
                        </Col>
                    </Hide>

                    <Hide hide={data.customer_type !== "Registered User"}>
                        <Col xs={24}>
                            <TierAutoComplete
                                multiple
                                label="User Tiers"
                                name="tiers_id"
                                value={data.tiers_id}
                                onChange={handleFieldChange}
                                error={helper_text.tiers_id !== undefined}
                                helper_text={helper_text.tiers_id}
                            />
                        </Col>
                    </Hide>


                    <Hide hide={data.dates_type !== "General Date" || data.custom_for !== "Packages"}>
                        <Col xs={24}>
                            <CustomDateField
                                label="Date"
                                name="date"
                                value={data.date}
                                onChange={handleFieldChange}
                                error={helper_text.date !== undefined}
                                helper_text={helper_text.date}
                            />
                        </Col>
                    </Hide>


                    <Hide hide={data.dates_type !== "General Date Range" || data.custom_for !== "Packages"}>
                        <Col xs={24} md={12}>
                            <CustomDateField
                                label="From Date"
                                name="from_date"
                                value={data.from_date}
                                onChange={handleFieldChange}
                                error={helper_text.from_date !== undefined}
                                helper_text={helper_text.from_date}
                            />
                        </Col>

                        <Col xs={24} md={12}>
                            <CustomDateField
                                label="To Date"
                                name="to_date"
                                value={data.to_date}
                                onChange={handleFieldChange}
                                error={helper_text.to_date !== undefined}
                                helper_text={helper_text.to_date}
                            />
                        </Col>
                    </Hide>


                    <Hide hide={data.times_type !== "General Time" || data.custom_for !== "Packages"}>
                        <Col xs={24}>
                            <CustomTimeField
                                label="Time"
                                name="time"
                                value={data.time}
                                onChange={handleFieldChange}
                                error={helper_text.time !== undefined}
                                helper_text={helper_text.time}
                            />
                        </Col>
                    </Hide>


                    <Hide hide={data.times_type !== "General Time Range" || data.custom_for !== "Packages"}>
                        <Col xs={24} md={12}>
                            <CustomTimeField
                                label="From Time"
                                name="from_time"
                                value={data.from_time}
                                onChange={handleFieldChange}
                                error={helper_text.from_time !== undefined}
                                helper_text={helper_text.from_time}
                            />
                        </Col>

                        <Col xs={24} md={12}>
                            <CustomTimeField
                                label="To Time"
                                name="to_time"
                                value={data.to_time}
                                onChange={handleFieldChange}
                                error={helper_text.to_time !== undefined}
                                helper_text={helper_text.to_time}
                            />
                        </Col>
                    </Hide>


                    <Hide hide={data.custom_for !== "Packages"}>
                        <Col xs={24}>
                            <PackageCategoryAutoComplete
                                multiple
                                label="Categories"
                                name="categories_id"
                                value={data.categories_id || []}
                                onChange={handleFieldChange}
                                error={helper_text.categories_id !== undefined}
                                helper_text={helper_text.categories_id}
                            />
                        </Col>


                        <Col xs={24}>
                            <AgencyAutoComplete
                                label="Agency"
                                name="AgencyId"
                                value={data.AgencyId}
                                onChange={handleFieldChange}
                                error={helper_text.AgencyId !== undefined}
                                helper_text={helper_text.AgencyId}
                            />
                        </Col>


                        <Hide hide={!isValidValue(data.AgencyId)}>
                            <Col xs={24} md={24}>
                                <AgencyPackageAutoComplete
                                    multiple
                                    keep_current_value
                                    package_type={data.package_type}
                                    categories={data.categories_id}
                                    agency={data.AgencyId}
                                    label="Agency Packages"
                                    name="packages_id"
                                    value={data.packages_id}
                                    onChange={handleFieldChange}
                                    error={helper_text.packages_id !== undefined}
                                    helper_text={helper_text.packages_id}
                                />
                            </Col>
                        </Hide>

                        <Hide hide={data.packages_id.length === 0 || !isValidValue(data.AgencyId)}>
                            <Col xs={24} md={24}>
                                <PackageOptionsAutoComplete
                                    multiple
                                    packages={data.packages_id}
                                    group_key="package_title_en"
                                    label="Packages Options"
                                    name="packages_options_id"
                                    value={data.packages_options_id}
                                    onChange={handleFieldChange}
                                    error={helper_text.packages_options_id !== undefined}
                                    helper_text={helper_text.packages_options_id}
                                />
                            </Col>
                        </Hide>


                        <Hide
                            hide={data.dates_type !== "Packages Options Dates" || data.packages_options_id.length === 0 || data.packages_id.length === 0 || !isValidValue(data.AgencyId)}>
                            <Col xs={24} md={24}>
                                <PackageOptionsDatesAutoComplete
                                    multiple
                                    title_key="title_view"
                                    packages_options={data.packages_options_id}
                                    group_key="package_option_title_en"
                                    label="Packages Options Dates"
                                    name="packages_options_dates_id"
                                    value={data.packages_options_dates_id}
                                    onChange={handleFieldChange}
                                    error={helper_text.packages_options_dates_id !== undefined}
                                    helper_text={helper_text.packages_options_dates_id}
                                />
                            </Col>
                        </Hide>


                        <Hide
                            hide={data.times_type !== "Packages Options Dates Times" || data.packages_options_dates_id.length === 0 || data.packages_options_id.length === 0 || data.packages_id.length === 0 || !isValidValue(data.AgencyId)}>
                            <Col xs={24} md={24}>
                                <PackageOptionsDatesTimesAutoComplete
                                    multiple
                                    title_key="title_view"
                                    packages_options_dates={data.packages_options_dates_id}
                                    group_key="package_option_date_title_en"
                                    label="Packages Options Dates Times"
                                    name="packages_options_dates_times_id"
                                    value={data.packages_options_dates_times_id}
                                    onChange={handleFieldChange}
                                    error={helper_text.packages_options_dates_times_id !== undefined}
                                    helper_text={helper_text.packages_options_dates_times_id}
                                />
                            </Col>
                        </Hide>
                    </Hide>


                    <Hide hide={data.custom_for !== "Products"}>
                        <Col xs={24} md={24}>
                            <ProductAutoComplete
                                keep_current_value
                                multiple
                                title_key="title_en"
                                label="Specific Products"
                                name="products_id"
                                value={data.products_id}
                                onChange={handleFieldChange}
                                error={helper_text.products_id !== undefined}
                                helper_text={helper_text.products_id}
                            />
                        </Col>
                    </Hide>

                    <Col xs={24}>
                        <CustomTextAreaField
                            label="Notes"
                            name="notes"
                            value={data.notes}
                            onChange={handleFieldChange}
                            error={helper_text.notes !== undefined}
                            helper_text={helper_text.notes}
                        />
                    </Col>


                    <Col xs={24}>
                        <CustomCheckBox
                            label="Is Active"
                            name="is_active"
                            value={data.is_active}
                            onChange={handleFieldChange}
                        />
                    </Col>


                    <Col xs={24} className="p-0">
                        <ModelActionButton
                            error_msg={helper_text.non_field_errors}
                            loading={loading}
                            onSave={onSave}
                            onCancel={props.handleModelClose}
                        />
                    </Col>
                </Hide>

            </Row>
        </CustomModal>
    )
}
