import React, { useState } from "react";
import { Col, Row } from "antd";
import { FacebookOutlined, InstagramOutlined } from "@ant-design/icons";

const HEFooter = () => {
  const [copyright] = useState(
    `Copyright  @${new Date().getFullYear()} Hello Explorer`
  );

  return (
    <div className="pt-3 pb-4">
      <Row justify="center" className="mb-2">
        <Col className="text-center">
          <a
            className="ms-1 me-1"
            href="https://www.helloexplorer.com/contact"
            style={{ color: "grey" }}
          >
            Contact Us
          </a>
          <a
            className="ms-1 me-1"
            href="https://www.helloexplorer.com/about"
            style={{ color: "grey" }}
          >
            About Us
          </a>
          <a
            className="ms-1 me-1"
            href="https://www.helloexplorer.com/services"
            style={{ color: "grey" }}
          >
            Services
          </a>
          <a
            className="ms-1 me-1"
            href="https://www.helloexplorer.com/privacy-policy"
            style={{ color: "grey" }}
          >
            Privacy Policy
          </a>
          <a
            className="ms-1 me-1"
            href="https://www.helloexplorer.com/terms"
            style={{ color: "grey" }}
          >
            Terms
          </a>
        </Col>
      </Row>

      <Row justify="center">
        <Col className="d-flex justify-content-center mt-1 mb-1">
          <img
            className="w-50 mt-1"
            style={{ maxWidth: "250px" }}
            src="https://i.imgur.com/qRwxEk5.png"
            alt="Hello Explorer Logo"
          />
        </Col>
      </Row>

      <Row justify="center">
        <Col className="text-center">Follow Us On Social Media</Col>
      </Row>

      <Row justify="center">
        <Col className="d-flex justify-content-center">
          <a
            href="https://instagram.com/helloexplorer.travel"
            target="_blank"
            style={{ color: "rgba(0,0,0,0.6)" }}
          >
            <InstagramOutlined style={{ fontSize: 25 }} className="m-1" />
          </a>
          <a
            href="https://www.facebook.com/Hello-Explorer-108026764368192"
            target="_blank"
            style={{ color: "rgba(0,0,0,0.6)" }}
          >
            <FacebookOutlined style={{ fontSize: 25 }} className="m-1" />
          </a>
        </Col>
      </Row>

      <Row justify="center">
        <Col className="text-center">{copyright}</Col>
      </Row>
    </div>
  );
};
export default HEFooter;
