import React, {useEffect, useState} from 'react';
import {CustomTextField} from "../../global/components/fields/CustomTextField";
import {adminSignIn, isAuthenticated} from "../../global/auth_functions";
import Logo from "../../assets/img/he_logo_black.png";
import Bg from "../../assets/img/bg_1.jpg";
import {Button, Col, Divider, Row, Steps} from "antd";
import {useParams} from "react-router-dom";
import {CustomSlugField} from "../../global/components/fields/CustomSlugField";
import {CustomPhoneNumberField} from "../../global/components/fields/CustomPhoneNumberField";
import {CountryAutoComplete} from "../../global/components/fields/CountryAutoComplete";
import {CustomUploadField} from "../../global/components/fields/CustomUploadField";
import CustomSelectField from "../../global/components/fields/CustomSelectField";
import {title_options} from "../../global/variables";
import {isValidValue, showInfo} from "../../global/helper_functions";
import {httpRequestWithoutAuth} from "../../global/axios_instance";
import {AgenciesRegistrationAllURL, BaseBackEndAPIsURL} from "../../global/urls";
import CustomCheckBox from "../../global/components/fields/CustomCheckBox";
// const {Step} = Steps;

export const AgencyRegistration = props => {
    const [data, setData] = useState({
        is_active: true
    });
    // const [current_step, setCurrent] = useState(0);
    const [helper_text, setHelperText] = useState({});
    const [is_loading, setLoading] = useState(false);
    const [user_data, setUserData] = useState({});

    const handleFieldChange = (name, value) => {
        let temp_data =  {...data}

        if (["lata_license_copy", "company_license_copy"].includes(name)){
            temp_data[`${name}_filename`] = isValidValue(value)? value.name: "";
        }
        temp_data[name] = value;
        setData(temp_data)
    }

    const handleUserFieldChange = (name, value) => {
        let temp_user_data = {...user_data};
        temp_user_data[name] = value;
        setUserData(temp_user_data);

        if (['confirm_password', 'password'].includes(name)) {
            if (temp_user_data.confirm_password !== temp_user_data.password && isValidValue(temp_user_data.confirm_password)) {
                setHelperText({...helper_text, confirm_password: "Password does not match"})
            } else {
                setHelperText({...helper_text, confirm_password: undefined})
            }
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        await setLoading(true);

        if (user_data.confirm_password !== user_data.password) {
            setHelperText({...helper_text, confirm_password: "Password does not match"})
            await setLoading(false);
            return
        }

        let form_data = new FormData();

        Object.keys(data).map(Name=> {
            if (isValidValue(data[Name])) {
                form_data.append(Name, data[Name]);
            }
        });

        Object.keys(user_data).map(Name=> {
            if (isValidValue(user_data[Name])) {
                form_data.append(`user_${Name}`, user_data[Name]);
            }
        });


        let response = await httpRequestWithoutAuth(`${BaseBackEndAPIsURL}${AgenciesRegistrationAllURL}`, "POST", form_data);

        if (response.status) {
            // props.history.push("/admin/dashboard");
            showInfo("Register as Agency", "Register successfully, Please wait for admin response", ()=>{
                props.history.push(`/${response.data.slug}/signin`);
            });
        } else {
            setHelperText(response.error_feedback)
        }

        await setLoading(false);
    }


    return (
        <main className="vh-100 overflow-hidden bg-light">
            <img src={Bg} className="vh-100 vw-100 position-absolute z-index-0"/>
        <Row className="d-flex justify-content-center align-items-start   overflow-auto h-100 pt-5" style={{paddingBottom: "5rem"}}>
            <Col xs={22} sm={18} md={14} lg={12}
                 className="shadow border-radius-10 bg-white d-flex flex-column align-items-center p-4 mt-5 ">

                <Col xs={21} sm={18} md={16} lg={8}>
                    <img src={Logo} className="w-100"/>
                </Col>

                <h1 className="fw-bolder text-center">
                            Welcome to HelloExplorer
                </h1>

                <h3 className="fw-bolder text-center">
                    Register Your Agency
                </h3>

                <span className="text-danger mt-2">
                            {helper_text.non_field_errors}
                </span>

                {/*<CustomSteps className='mt-4 mb-3' current={current_step} responsive={false}>*/}
                {/*    <Step title="Agency Info" icon={<UserOutlined/>} />*/}
                {/*    <Step title="User Info" icon={<SolutionOutlined/>} />*/}
                {/*    <Step title="Done" icon={<SmileOutlined/>} />*/}
                {/*</CustomSteps>*/}


                    <Row gutter={[12, 12]} c className="w-100">

                        <Col xs={24} className="mt-2">
                            <Divider orientation="left" orientationMargin="0" className="mt-2 mb-3 fw-bold">
                                Agency Info
                            </Divider>
                        </Col>

                        <Col xs={24} md={12}>
                            <CustomTextField
                                required
                                label="Agency Name"
                                name="name"
                                value={data.name}
                                onChange={handleFieldChange}
                                error={helper_text.name !== undefined}
                                helper_text={helper_text.name}
                            />
                        </Col>


                        <Col xs={24} md={12}>
                            <CustomSlugField
                                required
                                label="Slug"
                                name="slug"
                                value={data.slug}
                                onChange={handleFieldChange}
                                error={helper_text.slug !== undefined}
                                helper_text={helper_text.slug}
                            />
                        </Col>

                        <Col xs={24} md={12}>
                            <CustomTextField
                                required
                                label="Agency Contact E-mail"
                                name="agency_contact_email"
                                value={data.agency_contact_email}
                                onChange={handleFieldChange}
                                error={helper_text.agency_contact_email !== undefined}
                                helper_text={helper_text.agency_contact_email}
                                type="email"
                            />
                        </Col>


                        <Col xs={24} md={12}>
                            <CustomPhoneNumberField
                                required
                                label="Agency Contact No."
                                name="agency_contact_number"
                                value={data.agency_contact_number}
                                onChange={handleFieldChange}
                                error={helper_text.agency_contact_number !== undefined}
                                helper_text={helper_text.agency_contact_number}
                            />
                        </Col>

                        <Col xs={24}>
                            <CountryAutoComplete
                                no_auth
                                multiple
                                label="Countries"
                                name="countries_id"
                                value={data.countries_id || []}
                                onChange={handleFieldChange}
                                error={helper_text.countries_id !== undefined}
                                helper_text={helper_text.countries_id}
                            />
                        </Col>

                        <Col xs={24} md={12}>
                            <CustomUploadField
                                display_value={data.lata_license_copy_filename}
                                label="Lata License Copy"
                                name="lata_license_copy"
                                value={data.lata_license_copy}
                                onChange={handleFieldChange}
                                error={helper_text.lata_license_copy !== undefined}
                                helper_text={helper_text.lata_license_copy}
                            />
                        </Col>



                        <Col xs={24} md={12}>
                            <CustomUploadField
                                display_value={data.company_license_copy_filename}
                                label="Company License Copy"
                                name="company_license_copy"
                                value={data.company_license_copy}
                                onChange={handleFieldChange}
                                error={helper_text.company_license_copy !== undefined}
                                helper_text={helper_text.company_license_copy}
                            />
                        </Col>


                        <Col xs={24} className="mt-2">
                                <Divider orientation="left" orientationMargin="0" className="mt-2 mb-3 fw-bold">
                                    Services <span className="text-danger">*</span><span className="text-danger font-size-13 mr-2">{helper_text.non_field_errors_service}</span>
                                </Divider>
                        </Col>

                        <Col xs={24} sm={12}>
                            <CustomCheckBox
                                label="Has Experiences"
                                name="has_experiences"
                                value={data.has_experiences}
                                onChange={handleFieldChange}
                            />
                        </Col>

                        <Col xs={24} sm={12}>
                            <CustomCheckBox
                                label="Has Travels"
                                name="has_travels"
                                value={data.has_travels}
                                onChange={handleFieldChange}
                            />
                        </Col>




                        <Col xs={24} className="mt-2">
                            <Divider orientation="left" orientationMargin="0" className="mt-2 mb-3 fw-bold">
                                User Info
                            </Divider>
                        </Col>


                        <Col xs={24} md={4}>
                            <CustomSelectField
                                required
                                label="Title"
                                name="title"
                                value={user_data.title}
                                onChange={handleUserFieldChange}
                                error={helper_text.title !== undefined}
                                helper_text={helper_text.title}
                                options={title_options}
                            />
                        </Col>

                        <Col xs={24} md={10}>
                            <CustomTextField
                                required
                                label="First Name"
                                name="first_name"
                                value={user_data.first_name}
                                onChange={handleUserFieldChange}
                                error={helper_text.first_name !== undefined}
                                helper_text={helper_text.first_name}
                            />
                        </Col>

                        <Col xs={24} md={10}>
                            <CustomTextField
                                required
                                label="Last Name"
                                name="last_name"
                                value={user_data.last_name}
                                onChange={handleUserFieldChange}
                                error={helper_text.first_name !== undefined}
                                helper_text={helper_text.last_name}
                            />
                        </Col>


                        <Col xs={24} md={12}>
                            <CustomTextField
                                required
                                label="E-mail"
                                name="email"
                                value={user_data.email}
                                onChange={handleUserFieldChange}
                                error={helper_text.email !== undefined}
                                helper_text={helper_text.email}
                                type="email"
                            />
                        </Col>


                        <Col xs={24} md={12}>
                            <CustomPhoneNumberField
                                required
                                label="Contact No."
                                name="contact_number"
                                value={user_data.contact_number}
                                onChange={handleUserFieldChange}
                                error={helper_text.contact_number !== undefined}
                                helper_text={helper_text.contact_number}
                            />
                        </Col>


                        <Col xs={24} md={12}>
                            <CustomTextField
                                required
                                label="Password"
                                name="password"
                                value={user_data.password}
                                onChange={handleUserFieldChange}
                                error={helper_text.password !== undefined}
                                helper_text={helper_text.password}
                                type="password"
                            />
                        </Col>

                        <Col xs={24} md={12}>
                            <CustomTextField
                                required
                                label="Confirm Password"
                                name="confirm_password"
                                value={user_data.confirm_password}
                                onChange={handleUserFieldChange}
                                error={helper_text.confirm_password !== undefined}
                                helper_text={helper_text.confirm_password}
                                type="password"
                            />
                        </Col>

                        <Col xs={24} className="text-end">
                            <Button
                                    loading={is_loading}
                                    disabled={is_loading}
                                    onClick={onSubmit}
                                    type="primary"
                                    className="bg-black mt-4"
                                >
                                    Register
                                </Button>
                        </Col>

                        {/*<Col span={24} className=" d-inline-flex">*/}
                        {/*    <Button*/}
                        {/*        loading={is_loading}*/}
                        {/*        disabled={is_loading}*/}
                        {/*        className="mt-4"*/}
                        {/*    >*/}

                        {/*        Back*/}
                        {/*    </Button>*/}

                        {/*    <div className="ms-auto"/>*/}

                        {/*    <Button*/}
                        {/*        loading={is_loading}*/}
                        {/*        disabled={is_loading}*/}
                        {/*        onClick={onSubmit}*/}
                        {/*        type="primary"*/}
                        {/*        className="bg-black mt-4"*/}
                        {/*    >*/}
                        {/*        Next*/}
                        {/*    </Button>*/}
                        {/*</Col>*/}

                    </Row>

            </Col>
        </Row>
        </main>

    );
}

