import React, {useEffect, useState} from 'react';
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {GetBookingsRevenueOverMonthReportURL} from "../../../global/urls";
import {isValidValue} from "../../../global/helper_functions";
import {package_type} from "../../../global/variables";
import Hide from "../../../global/components/Hide";
import {Loading} from "../../../global/components/Loading";
import {checkPermission} from "../../../global/permissions";
import {permission_data} from "../../../global/permissions_data";
import moment from "moment";
import CustomRadioGroup from "../../../global/components/fields/CustomRadioGroup";
import {CustomDateField} from "../../../global/components/fields/CustomDateField";
import useWindowSize from "../../../global/useWindowSize";


const BookingRevenue = props => {
    const {inner_width} = useWindowSize();


    const [data, setData] = useState([]);
    const [has_perm] = useState(checkPermission(permission_data.reports.view_bookings_revenue_over_months));

    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState({
        year_one: moment().format("YYYY"),
        year_two: moment().add(-1, 'years').format("YYYY"),
    });

    useEffect(() => {
        const fetchData = async () => {

            if (has_perm) {
                setLoading(true)
                const params = {};


                if (isValidValue(filter.booking_type)) {
                    params.booking_type = filter.booking_type;
                }


                const temp_data = [
                    {
                        name: "Jan",
                        year_one: 0,
                        year_two: 0,
                    },
                    {
                        name: "Feb",
                        year_one: 0,
                        year_two: 0,
                    },
                    {
                        name: "Mar",
                        year_one: 0,
                        year_two: 0,
                    },
                    {
                        name: "Apr",
                        year_one: 0,
                        year_two: 0,
                    },
                    {
                        name: "May",
                        year_one: 0,
                        year_two: 0,
                    },
                    {
                        name: "Jun",
                        year_one: 0,
                        year_two: 0,
                    },
                    {
                        name: "Jul",
                        year_one: 0,
                        year_two: 0,
                    },
                    {
                        name: "Aug",
                        year_one: 0,
                        year_two: 0,
                    },
                    {
                        name: "Sep",
                        year_one: 0,
                        year_two: 0,
                    },
                    {
                        name: "Oct",
                        year_one: 0,
                        year_two: 0,
                    },
                    {
                        name: "Nov",
                        year_one: 0,
                        year_two: 0,
                    },
                    {
                        name: "Dec",
                        year_one: 0,
                        year_two: 0,
                    },
                ]

                const year_one_response = await httpRequestWithAuth(GetBookingsRevenueOverMonthReportURL, "GET", null, {
                    ...params,
                    year: filter.year_one
                });
                const year_two_response = await httpRequestWithAuth(GetBookingsRevenueOverMonthReportURL, "GET", null, {
                    ...params,
                    year: filter.year_two
                });

                if (year_one_response.status) {
                    temp_data[0][filter.year_one] = year_one_response.data[0].amount
                    temp_data[1][filter.year_one] = year_one_response.data[1].amount
                    temp_data[2][filter.year_one] = year_one_response.data[2].amount
                    temp_data[3][filter.year_one] = year_one_response.data[3].amount
                    temp_data[4][filter.year_one] = year_one_response.data[4].amount
                    temp_data[5][filter.year_one] = year_one_response.data[5].amount
                    temp_data[6][filter.year_one] = year_one_response.data[6].amount
                    temp_data[7][filter.year_one] = year_one_response.data[7].amount
                    temp_data[8][filter.year_one] = year_one_response.data[8].amount
                    temp_data[9][filter.year_one] = year_one_response.data[9].amount
                    temp_data[10][filter.year_one] = year_one_response.data[10].amount
                    temp_data[11][filter.year_one] = year_one_response.data[11].amount
                }


                if (year_two_response.status) {
                    temp_data[0][filter.year_two] = year_two_response.data[0].amount
                    temp_data[1][filter.year_two] = year_two_response.data[1].amount
                    temp_data[2][filter.year_two] = year_two_response.data[2].amount
                    temp_data[3][filter.year_two] = year_two_response.data[3].amount
                    temp_data[4][filter.year_two] = year_two_response.data[4].amount
                    temp_data[5][filter.year_two] = year_two_response.data[5].amount
                    temp_data[6][filter.year_two] = year_two_response.data[6].amount
                    temp_data[7][filter.year_two] = year_two_response.data[7].amount
                    temp_data[8][filter.year_two] = year_two_response.data[8].amount
                    temp_data[9][filter.year_two] = year_two_response.data[9].amount
                    temp_data[10][filter.year_two] = year_two_response.data[10].amount
                    temp_data[11][filter.year_two] = year_two_response.data[11].amount
                }

                setData(temp_data)
                setLoading(false)
            }

        }

        fetchData();
    }, [filter])


    const onChangeFilter = (name, value) => {
        if (loading) return;
        setFilter({...filter, [name]: value})
    }

    return (
        <div className="w-100 h-100 position-relative d-flex flex-column justify-content-between h-100">


            <div>
                <Hide hide={!loading}>
                    <div
                        className="position-absolute d-flex justify-content-center align-items-center w-100 h-100 z-index-100">
                        <div className="blur-bg w-100 pt-1 pb-3">
                            <Loading hide_text/>
                        </div>
                    </div>
                </Hide>

            <div className="d-flex justify-content-between">
                <h2 className="custom-grey-color font-size-16">
                    Bookings Revenue <label className="font-size-12 text-black-50">(based on booking date)</label>
                </h2>
            </div>

            <div className="mb-2 mt-2 w-100 d-inline-flex justify-content-between align-items-end">
                <CustomRadioGroup
                    add_all
                    name="booking_type"
                    value={filter.booking_type}
                    options={package_type}
                    onChange={onChangeFilter}
                />


                <div className="d-md-flex text-center align-items-center">
                    <CustomDateField
                        views={["year"]}
                        open_on="year"
                        format="YYYY"
                        disabled={loading}
                        placeholder="Select Year"
                        name="year_one"
                        value={filter.year_one}
                        onChange={onChangeFilter}
                    />

                    <label className="ms-1 me-1">
                        vs
                    </label>

                    <CustomDateField
                        views={["year"]}
                        open_on="year"
                        format="YYYY"
                        disabled={loading}
                        placeholder="Select Year"
                        name="year_two"
                        value={filter.year_two}
                        onChange={onChangeFilter}
                    />

                </div>

            </div>
            </div>

            <ResponsiveContainer width="100%" height={inner_width > 1500?"85%":"70%"}>
                <BarChart
                    width={500}
                    height={400}
                    data={data}
                    margin={{
                        top: 10,
                        right: 0,
                        left: -40,
                        bottom: 0,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="name" className="font-size-12"/>
                    <YAxis  allowDecimals={false} className="font-size-12"/>
                    <Tooltip/>
                    <Legend/>
                    <Bar dataKey={filter.year_one} fill="#8884d8"/>
                    <Bar dataKey={filter.year_two} fill="#82ca9d"/>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}


export default BookingRevenue;
