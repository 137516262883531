import {CustomModelDraggable} from "../../../../global/components/CustomModelDraggable";


export const HowToAddInstallment = props =>{
    return(
        <CustomModelDraggable
        open={props.open}
        onCancel={props.handleModelClose}
        title="How To Add Installment"
        width={600}
        >
            <p>
                apply this steps to add installment. <br/><br/>
                <label className="ms-2">
                    - On top right, "Add Installment" button, click it <br/><br/>
                    - Fill installment details <br/><br/>
                    - click on "Save" button <br/><br/>
                </label>

            </p>
        </CustomModelDraggable>
    )
}
