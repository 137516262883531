import {Button, Col, Row} from "antd";
import {CustomDynamicTable} from "../../../global/components/CustomDynamicTable";
import {useState} from "react";
import {VouchersURL} from "../../../global/urls";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {openNotification, showError, showInfo} from "../../../global/helper_functions";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import {AddButton} from "../../../global/components/AddButton";
import Hide from "../../../global/components/Hide";
import {IsActiveView} from "../../../global/components/IsActiveView";

export const DiscountCodeVouchers = props =>{
    const {slug} = props;
    const [reload, setReload] = useState(false);

    const [voucher_perm] = useState({
        delete: checkPermission(permission_data.voucher.delete),
        edit: checkPermission(permission_data.voucher.edit),
        add: checkPermission(permission_data.voucher.add),
    });

    const [columns] = useState([
        {
            title: "Code",
            dataIndex: "code",
            width: "10%",
        },
        {
            title: "Title",
            dataIndex: "title",
            width: "15%"
        },
        {
            title: "Start Date",
            dataIndex: "start_date_view",
            width: "15%"
        },
        {
            title: "End Date",
            dataIndex: "end_date_view",
            width: "15%"
        },
        {
            title: "Discount Percentage",
            dataIndex: "discount_amount",
            width: "8%",
            align: "center",
            render: (value, row_data) => `${value} %`
        },
        {
            title: "Deducts From",
            dataIndex: "deducts_from",
            width: "8%"
        },
        {
            title: "Activate",
            dataIndex: "is_active",
            width: "5%",
            align:"center",
            render: (value)=><IsActiveView value={value} view_only={!voucher_perm.edit}/>
        },
    ])


    const onDelete = async (row_data) =>{
        let response = await httpRequestWithAuth(`${VouchersURL}${row_data.id}`, "DELETE");
        if (response.status){
            openNotification("success", "Delete Vouchers", `${row_data.title} voucher has been deleted`)
        }else{
            openNotification("error", "Delete Vouchers", response.error_feedback.non_field_errors)
        }
    }

    const onEdit = async (row_data) =>{
        props.history.push(`/${slug}/vouchers/discount-code/${row_data.id}`);
    }

    const onAdd = async () =>{
        props.history.push(`/${slug}/vouchers/discount-code/new`);
    }

    const freeAction = (
        <>
            <AddButton onClick={onAdd}>Add Discount Code</AddButton>
        </>
    );

    return(
        <Row>

            <Col xs={24} className="paper">
                <CustomDynamicTable
                    title="Discount Code Vouchers"
                    reload_data={reload}
                    columns={columns}
                    fetch_url={VouchersURL}
                    free_action={freeAction}
                    onDelete={voucher_perm.delete && onDelete}
                    onEdit={voucher_perm.edit && onEdit}
                    custom_params={{voucher_type: "Discount Code"}}
                />
            </Col>
        </Row>
    )
}
