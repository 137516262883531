import React, {useState} from 'react';
import CustomRadioGroup from "../../../global/components/fields/CustomRadioGroup";
import {CustomDynamicTable} from "../../../global/components/CustomDynamicTable";
import {GetSalesIncomingDetailsURL} from "../../../global/urls";
import {customMoment, isValidValue, viewDateAndTime} from "../../../global/helper_functions";
import {isAgent} from "../../../global/auth_functions";
import Hide from "../../../global/components/Hide";
import CustomRadioField from "../../../global/components/fields/CustomRadioField";
import {package_type} from "../../../global/variables";
import {Col, Row} from "antd";
import {CustomDateField} from "../../../global/components/fields/CustomDateField";


const interval_options = [
    {label: "24h", value: 24},
    {label: "7d", value: 168},
    {label: "30d", value: 720},
    {label: "90d", value: 2160},
    {label: "180d", value: 4320},
    {label: "1y", value: 8760},
    {label: "Custom", value: -1},
];

export const incoming_details = [
    {label: "Booking", value: "Booking"},
    {label: "Invoices", value: "Invoices"},
    {label: "Consultations", value: "Consultations"},
    {label: "Products Sales", value: "Products Sales"},
    {label: "Service Charge", value: "Service Charge"},
];


const SaleIncomingDetails = props => {
    const [is_agent] = useState(isAgent());


    const [reload_data, setReloadData] = useState(false);
    const [filter, setFilter] = useState({
        incoming_type: "Booking",
        interval: 24,
        booking_type: "",
        from_date: customMoment().format("YYYY-MM-DD"),
        to_date: customMoment().format("YYYY-MM-DD"),
    });


    const [columns] = useState([
        {
            title: "Payment Ref",
            dataIndex: "ref",
            width: "15%",
            render: (value, row_data) => isValidValue(row_data.dashboard_url) ?
                <a href={row_data.dashboard_url} target="_blank">{value}</a> : value
        },
        // {
        //     title: "Customer Email",
        //     dataIndex: "customer_email",
        //     width: "25%"
        // },
        {
            title: "Customer Name",
            dataIndex: "customer_name",
            width: "25%"
        },
        {
            title: "Title",
            dataIndex: "title",
            width: "33%"
        },
        {
            title: "Amount",
            dataIndex: "amount",
            width: "10%",
            align: "center",
        },
        {
            title: "Date",
            dataIndex: "date",
            width: "22%",
            render: value => viewDateAndTime(value)
        }
    ]);


    const reloadData = () => {
        setReloadData(Math.random());
    }

    const onChangeFilter = (name, value) => {
        let temp_filter = {...filter, [name]: value};

        if (name === 'incoming_type') {
            temp_filter.booking_type = ""
        }

        setFilter(temp_filter);
        reloadData()
    }

    return (
        <div className="w-100 h-100 position-relative d-flex flex-column justify-content-between h-100">
            <div>


                <div className="d-flex justify-content-between">
                    <h2 className="custom-grey-color font-size-16">
                        Incoming Details
                    </h2>
                </div>

                <div className="mb-2 mt-2 w-100 d-md-inline-flex justify-content-between align-items-start">

                    <div>
                        <Hide hide={is_agent}>
                            <CustomRadioGroup
                                name="incoming_type"
                                value={filter.incoming_type}
                                options={incoming_details}
                                onChange={onChangeFilter}
                                container_class_name="mt-2"
                            />
                        </Hide>

                        <Hide hide={filter.incoming_type !== "Booking"}>
                            <div className="mt-2">
                                <CustomRadioField
                                    required
                                    add_all_option
                                    name="booking_type"
                                    value={filter.booking_type}
                                    onChange={onChangeFilter}
                                    options={package_type}
                                />
                            </div>
                        </Hide>
                    </div>

                    <div>
                        <CustomRadioGroup
                            name="interval"
                            value={filter.interval}
                            options={interval_options}
                            onChange={onChangeFilter}
                            container_class_name="mt-2"
                        />

                        <Hide hide={filter.interval !== -1}>
                            <Row gutter={[12, 12]} className="mt-2">
                                <Col xs={24} md={12}>
                                    <CustomDateField
                                        label="From Date"
                                        name="from_date"
                                        value={filter.from_date}
                                        onChange={onChangeFilter}
                                    />
                                </Col>

                                <Col xs={24} md={12}>
                                    <CustomDateField
                                        label="To Date"
                                        name="to_date"
                                        value={filter.to_date}
                                        onChange={onChangeFilter}
                                    />
                                </Col>
                            </Row>
                        </Hide>
                    </div>
                </div>


                <div className="w-100">
                    <CustomDynamicTable
                        hide_search
                        columns={columns}
                        fetch_url={GetSalesIncomingDetailsURL}
                        custom_params={filter}
                        reload_data={reload_data}
                    />
                </div>
            </div>


        </div>
    );
}


export default SaleIncomingDetails;
