import React, {useEffect, useState} from "react";
import CustomModal from "../../../../global/components/CustomModal";
import {Alert, Button, Col, Divider, Row, Tabs, Tooltip} from "antd";
import {CustomTextField} from "../../../../global/components/fields/CustomTextField";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {
    ListPackagesOptionDatesURL,
    PackagesOptionDateURL,
    PackagesPendingChangesForModalURL
} from "../../../../global/urls";
import {isValidValue, openNotification, showInfo} from "../../../../global/helper_functions";
import {ModelActionButton} from "../../../../global/components/ModelActionButton";
import CustomCheckBox from "../../../../global/components/fields/CustomCheckBox";
import {CustomDateField} from "../../../../global/components/fields/CustomDateField";
import {CustomTabPane} from "../../../../global/components/CustomTabPane";
import Hide from "../../../../global/components/Hide";
import moment from "moment";
import CustomBadge from "../../../../global/components/CustomBadge";
import {AddButton} from "../../../../global/components/AddButton";
import MinusRoundIcon from '@rsuite/icons/MinusRound';
import {CustomTimeField} from "../../../../global/components/fields/CustomTimeField";
import {getUserType} from "../../../../global/auth_functions";
import CustomSelectField from "../../../../global/components/fields/CustomSelectField";
import {package_status, PRIMARY_COLOR} from "../../../../global/variables";
import {InfoIconGreenFilled} from "../../../../global/components/Icons";
import ViewActionsDetails from "../../../../global/components/ViewActionsDetails";
import CustomMultiDateSelector from "../../../../global/components/fields/CustomMultiDateSelector";


export const PackageOptionDateFormModal = props => {
    const language = props.language || "";
    const [before_1_month] = useState(moment().add(-1, 'months'));
    const [disabled_dates, setDisabledDates] = useState([]);
    const package_option_data = props.package_option_data || {};
    const [is_admin] = useState(getUserType() === "Admin");
    const [data, setData] = useState({
        is_active: true,
        is_sold_out: false,
        is_unique: false,
        has_time: false,
        capacity: 1,
        status: is_admin?"Approved":"Pending",
        date: moment().format("YYYY-MM-DD"),
        ...props.data,
        add_type: "one",
        from_date: moment().format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
        skip_interval: 0,
        bulk_dates: [],
    });

    const [times, setTimes] = useState([
        {
            time: moment().format("HH:mm"),
            capacity: data.capacity || 1,
            is_active: true,
            is_sold_out: false,
            is_unique: false,
        },
    ]);

    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});
    const [time_helper_text, setTimeHelperText] = useState([{}]);
    const [new_pending_changes, setNewPendingChanges] = useState({});
    const is_new = !isValidValue(data.id);


    useEffect(()=>{
        const fetchDisabledDates = async () =>{
            const response = await httpRequestWithAuth(ListPackagesOptionDatesURL, "GET", null, {date_only: true, package_option: package_option_data.id})
            if (response.status){
                let temp_data = response.data.filter(option=>option !== data.date_view);
                setDisabledDates(temp_data);
            }
        }
        fetchDisabledDates();
    },[])



    useEffect(() => {
        if (isValidValue(data.id)) {
            getPendingChanges();
        }
    }, [])

    const disabledDate = (current) => {
        let is_disabled = false;
        const current_formated = current.format("DD-MM-YYYY");

        is_disabled = is_disabled || current.isBefore(before_1_month);
        is_disabled = is_disabled || disabled_dates.includes(current_formated);
        return is_disabled;
    };
    const getPendingChanges = async () =>{
        let response = await httpRequestWithAuth(PackagesPendingChangesForModalURL, "GET", null, {model_name: "PackageOptionDates", package: data.PackageId, object_id: data.id});
        if (response.status){
            setNewPendingChanges(response.data)
        }
    }
    const handleFieldChange = (name, value) => {
        let temp_helper_text = {...helper_text, [name]: undefined};
        let temp_data = {...data};
        temp_data[name] = value;

        if (temp_data.is_unique && temp_data.is_unique_sold_out){
            temp_data.is_sold_out = true;
        }
        setData(temp_data);
        setHelperText(temp_helper_text);
    }

    const addTime = () => {
        const new_time = {
            time: moment().format("HH:mm"),
            capacity: data.capacity || 1,
            is_active: true,
            is_sold_out: false,
            is_unique: false,
        }

        if (times.length > 1) {
            const last_index = times.length - 1;
            const last_date = moment(times[last_index].time, "HH:mm")
            let duration = last_date.diff(moment(times[last_index - 1].time, "HH:mm"))
            new_time.time = last_date.add(duration, 'milliseconds').format("HH:mm")
        }

        if (times.length > 0) {
            const last_index = times.length - 1;
            new_time.is_unique = times[last_index].is_unique;
        }

        setTimes([...times, new_time])
        setTimeHelperText([...time_helper_text, {}])
    }


    const removeTime = (index) => {
        let temp_times = [...times];
        let temp_times_helper = [...time_helper_text];
        temp_times.splice(index, 1);
        temp_times_helper.splice(index, 1);
        if (temp_times.length === 0) {
            temp_times.push({
                time: moment().format("HH:mm"),
                capacity: data.capacity || 1,
                is_active: true,
                is_sold_out: false,
                is_unique: false,
            })
            temp_times_helper.push({})
        }
        setTimes(temp_times);
        setTimeHelperText(temp_times_helper);
    }

    const handleTimeFieldChange = (name, value, index) => {
        let temp_times = [...times];
        temp_times[index][name] = value
        setTimes(temp_times);
    }


    const checkTimeFields = () => {
        let temp_helper_list = [];
        let error = false;
        times.map((time_data, index) => {
            const temp_helper = {};
            if (!isValidValue(time_data)) {
                temp_helper.time = "this field is required";
                error = true;
            }

            const exists_time = times.filter((row, row_index) => {
                return row_index < index && row.time === time_data.time
            });

            if (exists_time.length > 0) {
                temp_helper.time = "this time already added";
                error = true;
            }

            temp_helper_list.push(temp_helper);
        })

        setTimeHelperText(temp_helper_list);

        return !error;
    }

    const onSave = async () => {
        await setLoading(true);


        const is_new = !isValidValue(data.id);
        let response;

        if (is_new && data.has_time) {
            const check_time_data = checkTimeFields()
            if (!check_time_data) {
                await setLoading(false);
                return;
            }
        }

        if (is_new) {
            response = await httpRequestWithAuth(PackagesOptionDateURL, "POST", {...data, times});
        } else {
            response = await httpRequestWithAuth(`${PackagesOptionDateURL}${data.id}`, "PUT", {...data, times});
        }


        if (response.status) {

            if (is_new) {
                openNotification("success", "Add Package Option Date", `${response.data.date_view} package option date has been added`)
            } else {

                if (response.data.pending_change) {
                    showInfo("Update Package Option Date", response.data.msg);
                } else {
                    openNotification("success", "Update Package Option Date", response.data.msg || `${response.data.date_view} package option date has been updated`)
                }

            }

            props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
            if (Array.isArray(response.error_feedback.times_errors)) {
                setTimeHelperText(response.error_feedback.times_errors);
            }
        }

        await setLoading(false);

    }

    const handleAddNewDate = () => {
        const new_dates = data.new_dates || [];
        // if (!isValidValue(new_date)) {
        //     setHelperText({...helper_text, new_date: "this field is required"});
        //     return;
        // }
        let new_value = [...data.bulk_dates];
        for (let i = 0; i < new_dates.length; i++) {
            if (!data.bulk_dates.includes(new_dates[i])) {
                new_value.push(new_dates[i]);
            }
        }


        setData({...data, bulk_dates: new_value, new_dates: []});
    }

    const handleRemoveDate = (value) => {
        setData({...data, bulk_dates: data.bulk_dates.filter(date => date !== value)});
    }

    return (
        <CustomModal size="lg" title={`${package_option_data[`title_${language}`]}\n\nPackage Option Date Form`} visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>

                <Hide hide={isValidValue(data.id)}>
                    <Col xs={24}>
                        <Tabs activeKey={data.add_type} onChange={value => handleFieldChange("add_type", value)}>

                            <CustomTabPane tab={<>
                                Add One Date
                                <Tooltip
                                    className="ms-1"
                                    placement="topLeft"
                                    title="You can add only one date"
                                    color={PRIMARY_COLOR}
                                    arrowPointAtCenter
                                >
                                                                   <span className="p-0 m-0">
                                                                       <InfoIconGreenFilled/>
                                                                   </span>
                                </Tooltip>
                            </>} key="one"/>

                            <CustomTabPane tab={<>
                                Add Multiple Dates
                                <Tooltip
                                    className="ms-1"
                                    placement="topLeft"
                                    title="You can many different dates with same details"
                                    color={PRIMARY_COLOR}
                                    arrowPointAtCenter
                                >
                                                                   <span className="p-0 m-0">
                                                                       <InfoIconGreenFilled/>
                                                                   </span>
                                </Tooltip>
                            </>} key="bulk"/>

                            <CustomTabPane tab={<>
                            Add Range of Dates
                                <Tooltip
                                    className="ms-1"
                                    placement="topLeft"
                                    title="you can add add from date to date and skip some date dates by skip interval"
                                    color={PRIMARY_COLOR}
                                    arrowPointAtCenter
                                >
                                                                   <span className="p-0 m-0">
                                                                       <InfoIconGreenFilled/>
                                                                   </span>
                                </Tooltip>
                            </>} key="range"/>

                        </Tabs>
                    </Col>
                </Hide>


                <Hide hide={data.add_type !== "bulk"}>
                    <Col xs={24} className="d-flex justify-content-center">
                    <Col xs={24} sm={13} md={10}>
                        <CustomMultiDateSelector
                            show_legend
                            label="Click on the dates you would like to add"
                            disabledDate={disabledDate}
                            name="bulk_dates"
                            value={data.bulk_dates || []}
                            onChange={handleFieldChange}
                            error={helper_text.bulk_dates !== undefined}
                            helper_text={helper_text.bulk_dates}
                        />
                        </Col>
                    </Col>

                    {/* <Col xs={24} className="d-flex justify-content-end align-items-end">*/}
                    {/*    <Col xs={24} sm={12} md={3} className="p-0">*/}
                    {/*    <AddButton className="mb-0" onClick={handleAddNewDate}>Add Dates</AddButton>*/}
                    {/*    </Col>*/}
                    {/*</Col>*/}

                    <Col xs={24}>
                        <label className="w-100">Dates</label>
                        <div className="border p-1 w-100">
                            {(data.bulk_dates || []).map(Date => <CustomBadge className="m-1" title={moment(Date).format("ddd, DD MMM, YYYY")}
                                                                              onClose={() => handleRemoveDate(Date)}/>)}
                            <Hide hide={(data.bulk_dates || []).length !== 0}>
                                <span className="p-2">No Dates To View</span>
                            </Hide>
                        </div>
                    </Col>
                </Hide>

                <Hide hide={data.add_type !== "range"}>
                    <Col xs={24} md={8}>
                        <CustomDateField
                            required
                            label="From Date"
                            name="from_date"
                            value={data.from_date}
                            onChange={handleFieldChange}
                            error={helper_text.from_date !== undefined}
                            helper_text={helper_text.from_date}
                        />
                    </Col>

                    <Col xs={24} md={8}>
                        <CustomDateField
                            required
                            label="To Date"
                            name="to_date"
                            value={data.to_date}
                            onChange={handleFieldChange}
                            error={helper_text.to_date !== undefined}
                            helper_text={helper_text.to_date}
                        />
                    </Col>

                    <Col xs={24} md={8}>
                        <CustomTextField
                            required
                            label="Skip Interval"
                            name="skip_interval"
                            value={data.skip_interval}
                            onChange={handleFieldChange}
                            error={helper_text.skip_interval !== undefined}
                            helper_text={helper_text.skip_interval}
                            type="number"
                            tooltip="Number of days to skip between dates range"
                        />
                    </Col>
                </Hide>

                <Hide hide={data.add_type !== "one"}>
                    <Col xs={24} md={data.has_time?is_admin?12:24:24}>
                        <CustomDateField
                            required
                            disabledDate={disabledDate}
                            open_on="year"
                            label="Date"
                            name="date"
                            value={data.date}
                            onChange={handleFieldChange}
                            error={helper_text.date !== undefined}
                            helper_text={helper_text.date}
                            warning_tooltip={new_pending_changes['date']}
                            warning_tooltip_title="New Value Waiting Approve:"
                        />
                    </Col>
                </Hide>

                <Hide hide={data.has_time}>
                <Col xs={24} md={isValidValue(data.id) ? is_admin?12:24 : data.add_type === "one" ? is_admin?12:24 : is_admin?12:24}>
                    <CustomTextField
                        required
                        label="Date Qty"
                        name="capacity"
                        value={data.capacity}
                        onChange={handleFieldChange}
                        error={helper_text.capacity !== undefined}
                        helper_text={helper_text.capacity}
                        type="number"
                        warning_tooltip={new_pending_changes['capacity']}
                        warning_tooltip_title="New Value Waiting Approve:"
                    />
                </Col>
                </Hide>


                <Hide hide={!is_admin}>
                    <Col xs={24} md={12}>
                        <CustomSelectField
                            label="Status"
                            name="status"
                            value={data.status}
                            onChange={handleFieldChange}
                            error={helper_text.status !== undefined}
                            helper_text={helper_text.status}
                            options={package_status}
                            warning_tooltip={new_pending_changes['status']}
                            warning_tooltip_title="New Value Waiting Approve:"
                        />
                    </Col>
                </Hide>


                <Col xs={24} className="mt-2"/>

                <Col xs={24} md={12}>
                    <CustomCheckBox
                        label="Is Active"
                        name="is_active"
                        value={data.is_active}
                        onChange={handleFieldChange}
                        warning_tooltip={new_pending_changes['is_active']}
                        warning_tooltip_title="New Value Waiting Approve:"
                        tooltip="Hide date from the website"
                    />
                </Col>

                <Col xs={24} md={12}>
                    <CustomCheckBox
                        label="Has Specific Time"
                        name="has_time"
                        value={data.has_time}
                        onChange={handleFieldChange}
                        warning_tooltip={new_pending_changes['has_time']}
                        warning_tooltip_title="New Value Waiting Approve:"
                        tooltip="if date have specific times to be in"
                    />
                </Col>

                <Col xs={24} md={12}>
                    <CustomCheckBox
                        label="Is Unique"
                        name="is_unique"
                        value={data.is_unique}
                        onChange={handleFieldChange}
                        warning_tooltip={new_pending_changes['is_unique']}
                        warning_tooltip_title="New Value Waiting Approve:"
                        tooltip="disable all other similar dates in package options from booking if this date is sold first"
                    />
                </Col>

                <Col xs={24} md={12}>
                    <CustomCheckBox
                        disabled={data.is_unique && data.is_unique_sold_out}
                        label="Is Sold Out"
                        name="is_sold_out"
                        value={data.is_sold_out}
                        onChange={handleFieldChange}
                        warning_tooltip={new_pending_changes['is_sold_out']}
                        warning_tooltip_title="New Value Waiting Approve:"
                        tooltip="Customer will can't book this time but still showing in website and mark it as sold out"
                    />
                </Col>


                <Hide hide={isValidValue(data.id) || !data.has_time}>

                    <Col xs={24}>
                        <Divider orientation="left" orientationMargin="0" className=" mb-0 fw-bold font-size-18">
                            Times
                        </Divider>
                    </Col>

                    <Col xs={24} className="d-flex justify-content-end">
                        <Col xs={24} sm={6} md={5} className="d-flex d-md-block">
                            <AddButton onClick={addTime}>Add Time</AddButton>
                        </Col>
                    </Col>

                    {times.map((time_data, index) => (
                        <>

                            <Col xs={24} md={4}>
                                <CustomTimeField
                                    required
                                    label="Time"
                                    name="time"
                                    value={time_data.time}
                                    onChange={(name, value) => handleTimeFieldChange(name, value, index)}
                                    error={time_helper_text[index].time !== undefined}
                                    helper_text={time_helper_text[index].time}
                                />
                            </Col>

                            <Col xs={24} md={4}>
                                <CustomTextField
                                    required
                                    label="Time Qty"
                                    name="capacity"
                                    value={time_data.capacity}
                                    onChange={(name, value) => handleTimeFieldChange(name, value, index)}
                                    error={time_helper_text[index].capacity !== undefined}
                                    helper_text={time_helper_text[index].capacity}
                                    type="number"
                                />
                            </Col>

                            <Col xs={12} md={4}>
                                <CustomCheckBox
                                    label="Is Active"
                                    name="is_active"
                                    value={time_data.is_active}
                                    onChange={(name, value) => handleTimeFieldChange(name, value, index)}
                                />
                            </Col>

                            <Col xs={12} md={4}>
                                <CustomCheckBox
                                    label="Is Unique"
                                    name="is_unique"
                                    value={time_data.is_unique}
                                    onChange={(name, value) => handleTimeFieldChange(name, value, index)}
                                />
                            </Col>

                            <Col xs={12} md={4}>
                                <CustomCheckBox
                                    disabled={data.is_unique && data.is_unique_sold_out}
                                    label="Is Sold Out"
                                    name="is_sold_out"
                                    value={time_data.is_sold_out}
                                    onChange={(name, value) => handleTimeFieldChange(name, value, index)}
                                />
                            </Col>

                            <Col xs={12} md={4} className="d-flex align-items-end justify-content-end">
                                <Button danger type="primary" onClick={() => removeTime(index)}>
                                    <MinusRoundIcon className="me-1"/>
                                    Remove
                                </Button>
                            </Col>

                            <Hide hide={!isValidValue(time_helper_text[index].non_field_errors)}>
                                <Col xs={24}>
                                    <Alert
                                        className="text-danger"
                                        banner
                                        description={time_helper_text[index].non_field_errors}
                                        type="error"
                                    />
                                </Col>
                            </Hide>

                            <Divider className="m-0"/>
                        </>
                    ))}

                    <Hide hide={times.length < 5}>
                        <Col xs={24} className="d-flex justify-content-end">
                            <Col xs={24} sm={6} md={5} className="d-flex d-md-block">
                                <AddButton onClick={addTime}>Add Time</AddButton>
                            </Col>
                        </Col>
                    </Hide>

                </Hide>


                <Hide hide={is_new}>
                    <ViewActionsDetails data={data}/>
                </Hide>

                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>



                {/*<Hide hide={!isValidValue(data.id) || !data.has_time || !original_has_time}>*/}
                {/*    <Col xs={24}>*/}
                {/*        <Divider orientation="left" orientationMargin="0" className=" mb-0 fw-bold font-size-18">*/}
                {/*            Times*/}
                {/*        </Divider>*/}
                {/*    </Col>*/}

                {/*    <Col xs={24}>*/}
                {/*        <PackageOptionDateTimeForm*/}
                {/*            without_modal*/}
                {/*            package_option_date_data={data}*/}
                {/*        />*/}
                {/*    </Col>*/}
                {/*</Hide>*/}

            </Row>
        </CustomModal>
    )
}
