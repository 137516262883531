import React, {useState} from "react";
import {Tabs, Tooltip} from "antd";
import {PRIMARY_COLOR} from "../variables";
import {InfoIconGreenFilled} from "./Icons";
const {TabPane} = Tabs;

export const CustomTabPane = props =>{
    const default_key = useState(Math.random() + "");

    return(
        <TabPane tab={
            <span className={`${props.disabled?"text-black-50":"text-dark"}`}>
               {props.tab}
            </span>
        } key={props.key || default_key}/>

    )
}

//
// {props.tooltip && (
//     <Tooltip
//         className="ms-1"
//         placement="topLeft"
//         title={props.tooltip}
//         color={PRIMARY_COLOR}
//         arrowPointAtCenter
//     >
//                               <span className="p-0 m-0">
//                                   <InfoIconGreenFilled/>
//                               </span>
//     </Tooltip>
// )
