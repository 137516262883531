import {CustomModelDraggable} from "../../../../global/components/CustomModelDraggable";


export const WhatIsPackageInstallment = props =>{
    return(
        <CustomModelDraggable
        open={props.open}
        onCancel={props.handleModelClose}
        title="What Is Package Installment"
        width={600}
        >
            <p>
                Package Installment consists of the photos displayed for the package on the website. <br/><br/>
                The photos display depends on the order specified. <br/><br/>
            </p>
        </CustomModelDraggable>
    )
}
