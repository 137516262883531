import React, {useState} from 'react';
import CustomRadioGroup from "../../../global/components/fields/CustomRadioGroup";
import {CustomDynamicTable} from "../../../global/components/CustomDynamicTable";
import {GetSalesRefundDetailsURL} from "../../../global/urls";
import {isValidValue, viewDateAndTime} from "../../../global/helper_functions";
import CustomRadioField from "../../../global/components/fields/CustomRadioField";
import {package_type} from "../../../global/variables";


const interval_options = [
    {label: "24h", value:24},
    {label: "7d", value:168},
    {label: "30d", value:720},
    {label: "90d", value:2160},
    {label: "180d", value:4320},
    {label: "1y", value:8760},
];


const SaleRefundDetails = props => {
    const [reload_data, setReloadData] = useState(false);
    const [filter, setFilter] = useState({
        interval: 24,
        booking_type: "",
    });


    const [columns] = useState([
        {
            title: "Refund Ref",
            dataIndex: "ref",
            width: "15%",
            render: (value, row_data) => isValidValue(row_data.dashboard_url)?<a href={row_data.dashboard_url} target="_blank">{value}</a>:value
        },
        // {
        //     title: "Customer Email",
        //     dataIndex: "customer_email",
        //     width: "25%"
        // },
        {
            title: "Customer Name",
            dataIndex: "customer_name",
            width: "25%"
        },
        {
            title: "Title",
            dataIndex: "title",
            width: "33%"
        },
        {
            title: "Amount",
            dataIndex: "amount",
            width: "10%",
            align: "center",
        },
        {
            title: "Date",
            dataIndex: "date",
            width: "22%",
            render: value=>viewDateAndTime(value)
        }
    ]);



    const reloadData = () =>{
        setReloadData(Math.random());
    }

    const onChangeFilter = (name, value) => {
        let temp_filter = {...filter, [name]: value};
        setFilter(temp_filter);
        reloadData()
    }

    return (
        <div className="w-100 h-100 position-relative d-flex flex-column justify-content-between h-100">
            <div>


            <div className="d-flex justify-content-between">
                <h2 className="custom-grey-color font-size-16">
                    Refund Details
                </h2>
            </div>

                <div className="mb-2 mt-2 w-100 d-md-inline-flex justify-content-between">
                        <div>
                            <CustomRadioField
                                required
                                add_all_option
                                name="booking_type"
                                value={filter.booking_type}
                                onChange={onChangeFilter}
                                options={package_type}
                            />
                        </div>

                <CustomRadioGroup
                    name="interval"
                    value={filter.interval}
                    options={interval_options}
                    onChange={onChangeFilter}
                    container_class_name="mt-2"
                />
            </div>



                <div className="w-100">
                    <CustomDynamicTable
                        hide_search
                        columns={columns}
                        fetch_url={GetSalesRefundDetailsURL}
                        custom_params={filter}
                        reload_data={reload_data}
                    />
                </div>
            </div>


        </div>
    );
}


export default SaleRefundDetails;
