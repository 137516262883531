import {Button, Col, Row} from "antd";
import {CustomDynamicTable} from "../../../global/components/CustomDynamicTable";
import {useState} from "react";
import {VouchersURL} from "../../../global/urls";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {copyText, openNotification} from "../../../global/helper_functions";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import {AddButton} from "../../../global/components/AddButton";
import {IsActiveView} from "../../../global/components/IsActiveView";


const generatePaymentLink = ({id}) => {
    const url = `${process.env.REACT_APP_CUSTOMER_WEBSITE_URL}payments/digital-gift-voucher/?voucher=${id}`;
    openNotification("success", "", `Payment Link has been copied`)
    copyText(url);
}

export const DigitalGiftVouchers = props =>{
    const {slug} = props;
    const [reload, setReload] = useState(false);

    const [voucher_perm] = useState({
        delete: checkPermission(permission_data.voucher.delete),
        edit: checkPermission(permission_data.voucher.edit),
        add: checkPermission(permission_data.voucher.add),
    });

    const [columns] = useState([
        {
            title: "Code",
            dataIndex: "code",
            width: "10%",
        },
        {
            title: "Title",
            dataIndex: "title",
            width: "15%"
        },
        // {
        //     title: "Start Date",
        //     dataIndex: "start_date_view",
        //     width: "15%"
        // },
        // {
        //     title: "End Date",
        //     dataIndex: "end_date_view",
        //     width: "15%"
        // },
        {
            title: "Gift Amount",
            dataIndex: "digital_gift_amount",
            width: "8%",
            align: "center",
            render: (value, row_data) => `${value} KD`
        },
        {
            title: "Gift Balance",
            dataIndex: "maximum_discount_amount",
            width: "8%",
            align: "center",
            render: (value, row_data) => `${value} KD`
        },
        // {
        //     title: "Activate",
        //     dataIndex: "is_active",
        //     width: "5%",
        //     align:"center",
        //     render: (value)=><IsActiveView value={value} view_only={!voucher_perm.edit}/>
        // },
        {
            title: "Payment Status",
            dataIndex: "is_paid",
            width: "8%",
            align: "center",
            render: (value) => <IsActiveView value={value} active_label="Paid" inactive_label="Unpaid" view_only/>
        },
    ])

    const extra_actions = [
        {
            key: "generate-payment-link",
            label: "Generate Payment Link",
            onClick: generatePaymentLink,
            hide: (row_data)=>row_data.is_paid,
        },

    ]


    const onDelete = async (row_data) =>{
        let response = await httpRequestWithAuth(`${VouchersURL}${row_data.id}`, "DELETE");
        if (response.status){
            openNotification("success", "Delete Digital Gift Vouchers", `${row_data.title} voucher has been deleted`)
        }else{
            openNotification("error", "Delete Digital Gift Vouchers", response.error_feedback.non_field_errors)
        }
    }

    const onEdit = async (row_data) =>{
        props.history.push(`/${slug}/vouchers/digital-gift/${row_data.id}`);
    }

    const onAdd = async () =>{
        props.history.push(`/${slug}/vouchers/digital-gift/new`);
    }

    const freeAction = (
        <>
            <AddButton onClick={onAdd}>Add Digital Gift Vouchers</AddButton>
        </>
    );





    return(
        <Row>

            <Col xs={24} className="paper">
                <CustomDynamicTable
                    title="Digital Gift Vouchers"
                    reload_data={reload}
                    columns={columns}
                    fetch_url={VouchersURL}
                    // free_action={freeAction}
                    extra_actions={extra_actions}
                    onDelete={voucher_perm.delete && onDelete}
                    onEdit={voucher_perm.edit && onEdit}
                    custom_params={{voucher_type: "Digital Gift"}}
                />
            </Col>
        </Row>
    )
}
