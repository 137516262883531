import React, {useEffect, useState} from "react";
import {Button, Col, Table} from "antd";
import {AddButton} from "../../../../global/components/AddButton";
import Hide from "../../../../global/components/Hide";
import {PackageOptionSearchModal} from "./PackageOptionSearchModal";
import {isValidValue, showConfirm} from "../../../../global/helper_functions";
import {BookingItemPersons} from "./BookingItemPersons";
import {CustomTextField} from "../../../../global/components/fields/CustomTextField";


export const BookingItems = props => {
    const {booking_data, items_data, setItemsData} = props;
    const [summary_data, setSummaryData] = useState({total: 0.000})
    const [open_packages_modal, setOpenPackagesModal] = useState(false)
    const columns = [
        {
            title: "Package Title",
            dataIndex: "package_title_en",
            width: "25%",
            className: "pre-line",
            render: (value, row_data)=> `${value} ${row_data.addons_title_en}`
        },
        {
            title: "Option Title",
            dataIndex: "title_en",
            width: "25%",
        },
        {
            title: "Capacity",
            dataIndex: "capacity",
            width: "8%",
            align: "center",
            render: (value, row_data) => `${row_data.bookings_number} / ${value}`
        },
        {
            title: "Price " + booking_data.currency,
            dataIndex: "price",
            width: "10%",
            align: "end",
            render: value => parseFloat(value).toFixed(3)
        },
        {
            title: "Qty",
            dataIndex: "qty",
            width: "9%",
            align: "center",
            render: (value, row_data) => <CustomTextField type="number" name="qty"
                                                          onChange={(name, value) => handleItemChange(row_data.index, name, value)}
                                                          value={value} inputClassName="text-center"/>
        },
        {
            title: "Total Price " + booking_data.currency,
            dataIndex: "price",
            width: "10%",
            align: "end",
            render: (value, row_data) => (parseFloat(row_data.price || 0) * parseFloat(row_data.qty || 1) + row_data.addon_price).toFixed(3)
        },
        {
            title: "Action",
            dataIndex: "id",
            width: "8%",
            align: "center",
            render: (value, row_data) => (
                <Button danger type="primary" onClick={() => removeItem(row_data)}>
                    Remove
                </Button>
            )
        },
    ]


    const addNewItem = (data, confirm = false, confirm_remove_unique = false) => {
        const original_data = {...data}
        let temp_items = [...items_data];
        let exists_index = "";
        let exists_date_unique = "";
        let exists_time_unique = "";

        for (let i = 0; i < temp_items.length; i++) {
            const item = temp_items[i];

            if (item.id === data.id && item.PackageOptionDateId === data.PackageOptionDateId && item.PackageOptionDateTimeId === data.PackageOptionDateTimeId) {
                exists_index = i
            }

            if (!isValidValue(exists_index)) {
                if (isValidValue(item.date) && item.date === data.date) {
                    if (item.date_is_unique || data.date_is_unique) {
                        exists_date_unique = i;
                    }

                    if (isValidValue(item.time) && item.time === data.time) {
                        if (item.time_is_unique || data.time_is_unique) {
                            exists_time_unique = i;
                        }
                    }

                }
            }

        }

        if (isValidValue(exists_index)) {
            data.qty += temp_items[exists_index].qty;
        }

        if (!confirm_remove_unique) {
            if (isValidValue(exists_date_unique)) {
                showConfirm("Unique Date Package", "Do you want to remove other item with same unique date", () => addNewItem(original_data, false, true));
                return;
            }

            if (isValidValue(exists_time_unique)) {
                showConfirm("Unique Time Package", "Do you want to remove other item with same unique time", () => addNewItem(original_data, false, true));
                return;
            }
        }else{
            if (isValidValue(exists_date_unique)) {
                temp_items.splice(exists_date_unique, 1);
            }else if (isValidValue(exists_time_unique)) {
                temp_items.splice(exists_time_unique, 1);
            }
        }


        if (!confirm) {
            if ((data.bookings_number + data.qty) > data.capacity) {
                showConfirm("Over Capacity", "Are you sure you want to add over capacity", () => addNewItem(original_data, true));
                return;
            }
        }

        const persons = new Array(parseInt(original_data.qty) * parseInt(original_data.restricted_capacity)).fill({helper_text: {}});
        if (isValidValue(exists_index)) {
            temp_items[exists_index].qty = data.qty;
            temp_items[exists_index].persons = [temp_items[exists_index].persons, ...persons]
        } else {
            temp_items.push({...data, persons});
        }



        temp_items.map((item, index) => item.index = index)

        setItemsData(temp_items)
        setOpenPackagesModal(false)
    }


    const removeItem = (data) => {
        const temp_item = items_data.filter(item => {
            if (item.id !== data.id) return true;
            if (item.PackageOptionDateId !== data.PackageOptionDateId) return true;
            if (item.PackageOptionDateTimeId !== data.PackageOptionDateTimeId) return true;
        });
        setItemsData(temp_item);
    }

    useEffect(() => {
        let total = 0;

        items_data.map((item, index) => {
            total += item.qty * item.price
        });

        setSummaryData({total});

    }, [items_data])


    const handleItemChange = (index, name, value) => {
        const temp_items = [...items_data];
        const item = temp_items[index];

        if (name === "qty") {

            if (value < 1){
                value = 1
            }
            value = parseInt(value);

            if (item.qty > value){
                item.persons = item.persons.slice(0, value * item.restricted_capacity)
            }else if (item.qty < value) {
                const new_arr = new Array((value - item.qty) * item.restricted_capacity).fill({helper_text:{}})
                item.persons = [...item.persons, ...new_arr];
            }

        }

        item[name] = value
        temp_items[index] = item
        setItemsData(temp_items)
    }

    const handlePersonInformation = async (option_index, index, name, value) => {
        const temp_items = [...items_data];
        const data = {[name]: value}

        if (["passport_copy"].includes(name)) {
            data[`${name}_filename`] = isValidValue(value) ? value.name : "";
            data[name] = isValidValue(value) ? value.path : "";
        }

        temp_items[option_index].persons[index] = {...temp_items[option_index].persons[index], ...data};
        setItemsData(temp_items)
    }


    const handlePersonInformationBulk = (option_index, index, data) => {
        const temp_items = [...items_data];
        temp_items[option_index].persons[index] = {...temp_items[option_index].persons[index], ...data};
        setItemsData(temp_items)
    }


    return (
        <>
            <Hide hide={!open_packages_modal}>
                <PackageOptionSearchModal
                    defualt_filter={{
                        package_type: booking_data.booking_type,
                        countries: booking_data.booking_type === "Experience" ? booking_data.ExperienceCountryId || "" : "",
                        interval: "Upcoming",
                    }}
                    open={open_packages_modal}
                    handleModelClose={() => setOpenPackagesModal(false)}
                    addNewItem={addNewItem}
                />
            </Hide>

            <Col xs={24} className="d-md-flex justify-content-between text-center mb-2">

                <h2 className="font-size-22">
                    Booking Item
                </h2>


                <div className="d-flex justify-content-center">
                    <div className="d-md-flex w-100">
                        <AddButton onClick={() => setOpenPackagesModal(true)}>
                            Add Item
                        </AddButton>
                    </div>

                </div>

            </Col>

            <Col xs={24}>
                <Table
                    columns={columns}
                    dataSource={items_data}
                    pagination={false}
                    rowKey={(record) => `${record.id}-${record.package_title_en}-${record.title_en}`}
                    expandable={{
                        showExpandColumn: booking_data.booking_type === "Travel",
                        defaultExpandedRowKeys: ['0'],
                        columnWidth: "25",
                        expandedRowRender: (row_data) => <BookingItemPersons {...row_data}
                                                                             handlePersonInformation={handlePersonInformation}
                                                                             handlePersonInformationBulk={handlePersonInformationBulk}/>,
                        expandIcon: ({expanded, onExpand, record}) =>
                            expanded ? (
                                <Button onClick={e => onExpand(record, e)}>Hide Persons</Button>
                            ) : (
                                <Button onClick={e => onExpand(record, e)}>View Persons</Button>
                            )

                    }}
                    scroll={{
                        x: 750,
                    }}
                />
            </Col>

            <Col xs={24} className="text-center mt-4">
                <h2 className="fw-bolder">Total: {summary_data.total.toFixed(3)}</h2>
            </Col>

        </>
    )
}
