import React from "react";
import { Input } from "antd";
const { Search } = Input;


export const CustomSearchField = (props) => {

  const onChange = (e) =>{
    if (!props.view_only && !props.disabled) {
      props.onChange(props.name || "search", e.target.value);
    }
  }



  return (
    <Search
        {...props}
      placeholder={props.placeholder || "Search"}
      enterButton={props.enterButton}
      size={props.size}
      suffix={props.suffix}
      onSearch={props.onSearch}
      onChange={onChange}
      allowClear={props.allowClear}
      addonBefore={props.addonBefore}
      addonAfter={props.addonAfter}
    />
  );
};
