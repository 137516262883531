import React,{useEffect, useState} from "react";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {GetBookingsNumberURL} from "../../../global/urls";
import {ComponentThree} from "./ComponentThree";
import {checkPermission} from "../../../global/permissions";
import {permission_data} from "../../../global/permissions_data";
import {Button} from "antd";
import moment from "moment";

const TodayBookingNumber = (props) => {
    const slug = props.slug;
  const [data, setData] = useState(0);
  const [has_perm] = useState(checkPermission(permission_data.reports.view_today_booking_number));

  useEffect(()=>{
      const fetchData = async () =>{
          if (has_perm) {

              let response = await httpRequestWithAuth(GetBookingsNumberURL, "GET");
              if (response.status) {
                  setData(response.data);
              }
          }
      }

          fetchData();
  },[])


    const onClick = () =>{
      const today = moment().format("YYYY-MM-DD");
    props.history.push(`/${slug}/bookings?from-created-date=${today}&to-created-date=${today}`)
    }

    return (
        <Button className="w-100 h-100 border-0 text-start p-0 m-0" onClick={onClick}>

    <ComponentThree title="Today Bookings" value={data}/>
    </Button>
    )

}


export default TodayBookingNumber;
