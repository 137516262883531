import React, {useEffect, useState} from "react";
import {Button, Col, Divider, Row, Table} from "antd";
import {isValidValue} from "../../../../global/helper_functions";
import {BookingItemPersonsView} from "./BookingItemPersonsView";
import {CustomTextField} from "../../../../global/components/fields/CustomTextField";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {GetServiceChargeAmountURL, GetVoucherAmountForBookingURL} from "../../../../global/urls";
import {CheckCircleOutlined} from "@ant-design/icons";
import Hide from "../../../../global/components/Hide";

export const BookingOverview = props => {
    const {booking_data, setBookingData} = props;
    const [items_data, setItemData] = useState(props.items_data || []);
    const [voucher, setVoucher] = useState({
        code: "",
    });


    const items_columns = [
        {
            title: "Description",
            dataIndex: "package_title_en",
            width: "61%",
            className: "pre-line",
            render: (value, row_data) => `${row_data.index + 1}. ${value} ${isValidValue(row_data.title_en) ? "--> " + row_data.title_en : ""}${isValidValue(row_data.addons_title_en) ? row_data.addons_title_en : ""}`
        },
        {
            title: "Add-ons " + booking_data.currency,
            dataIndex: "addon_price",
            width: "15%",
            align: "center",
            render: value => value
        },
        {
            title: "Price " + booking_data.currency,
            dataIndex: "price",
            width: "8%",
            align: "end",
            render: (value, row_data) => {
                if (row_data.is_voucher_applied){
                    return (
                        <div>
                        <label className="text-decoration-line-through text-danger">
                            {parseFloat(value)}
                        </label>
                            <br/>

                            <label className="text-success">
                            {parseFloat(parseFloat(value) - parseFloat(row_data.discount_amount_per_item))}
                            </label>

                        </div>

                    )
                }
                return parseFloat(value)
            }
        },
        {
            title: "Qty",
            dataIndex: "qty",
            width: "8%",
            align: "center",
            render: value => value
        },
        {
            title: "Total Price " + booking_data.currency,
            dataIndex: "price",
            width: "8%",
            align: "end",
            render: (value, row_data) => {
                const total = parseFloat(row_data.price || 0) * parseFloat(row_data.qty || 1);
                if (row_data.is_voucher_applied){
                    return (
                        <div>

                            <label className="text-decoration-line-through text-danger">
                                {(total + row_data.addon_price)}
                            </label>
                                <br/>
                            <label className="text-success">
                                {parseFloat(total - row_data.discount_amount) + row_data.addon_price}
                            </label>

                        </div>
                    )
                }
                return parseFloat(total + (row_data.addon_price || 0))
            }
        },
    ]


    useEffect(() => {
        setBookingData({...booking_data, "VoucherId": ""})
    },[])

    useEffect(() => {
        const checkServiceCharge = async () =>{
                let temp_item_data = [];
        (props.items_data || []).map((item, index)=>{
                let discount_details = {};
            (voucher.voucher_apply_on || []).map(voucher_apply_item=>{
                if (item.PackageOptionId === voucher_apply_item.PackageOptionId
                    && item.PackageOptionDateId === voucher_apply_item.PackageOptionDateId
                    && item.PackageOptionDateTimeId === voucher_apply_item.PackageOptionDateTimeId){
                    discount_details = {
                        is_voucher_applied: true,
                        discount_amount: voucher_apply_item.discount_amount,
                        discount_amount_per_item: voucher_apply_item.discount_amount_per_item,
                    }
                }
            })
            temp_item_data.push({
                ...item,
                ...discount_details
            })
        })


            // if (isValidValue(voucher.VoucherId)){
            //         temp_item_data.push(  {
            //             index: temp_item_data.length,
            //             package_title_en: `${voucher.voucher_title}: ${voucher.voucher_code}`,
            //             price: -voucher.voucher_discount_amount,
            //             qty: 1
            //         })
            //     }

            if (booking_data.add_service_charge){
                const service_charge = await getServiceChargeAmount(getTotal(temp_item_data, true), booking_data.booking_type);
                temp_item_data.push(  {
                    index: temp_item_data.length,
                    package_title_en: "Service Charge",
                    price: service_charge,
                    qty: 1,
                    is_service_charge: true,
                })

            }
                setItemData(temp_item_data)

        }

        checkServiceCharge();
    }, [voucher.VoucherId])


    const handleVoucherChange = (name, value) =>{
        setVoucher({
            [name]: value
        })

        if (isValidValue(booking_data.VoucherId)){
            setBookingData({...booking_data, "VoucherId": ""})
        }

    }


    const getTotal = (data=items_data, exclude_service_charge=false)=>{
        let temp_total = 0;
        for (let i = 0; i < data.length; i++) {
            const item = data[i];
            if (exclude_service_charge && item.is_service_charge)continue;

            temp_total += item.price * item.qty + (item.addon_price || 0)
            if (item.is_voucher_applied)temp_total -= item.discount_amount
        }

        return temp_total
    }


    const getServiceChargeAmount = async (amount, package_type) => {
        let response = await httpRequestWithAuth(GetServiceChargeAmountURL, "GET", null, {currency: booking_data.currency, amount, package_type});
        let temp_service_charge = 0;

        if (response.status){
            temp_service_charge = response.data;
        }

            return temp_service_charge;
    }


    const applyVoucher = async () => {
        setVoucher({...voucher, loading: true, helper_text: undefined})

        const temp_data = {
            code: voucher.code,
            package_type: booking_data.booking_type,
            CustomerId: booking_data.CustomerId,
            booking_items: items_data.filter(item=>isValidValue(item.PackageOptionId)),
        };

        let response = await httpRequestWithAuth(GetVoucherAmountForBookingURL, "POST", temp_data);

        if (response.status){
            setBookingData({...booking_data, "VoucherId": response.data.VoucherId})
            setVoucher({
                ...voucher,
                ...response.data,
                VoucherId: response.data.VoucherId,
                voucher_title: response.data.voucher_title,
                voucher_code: response.data.voucher_code,
                voucher_discount_amount: response.data.discount_amount,
                loading: false,
                helper_text: undefined
            });
        }else{
            setBookingData({...booking_data, "VoucherId": ""})

            setVoucher({
                ...voucher,
                VoucherId: "",
                voucher_title: "",
                voucher_code: "",
                voucher_discount_amount: 0,
                loading: false,
                helper_text: response.error_feedback.non_field_errors
            });
        }
    }

    return (
        <>
        <Col xs={24} className="h-auto">
            <Row gutter={[12, 12]} className="h-auto">

                <Col xs={24} className="mb-2">
                    <h2 className="font-size-22">
                        Booking Overview
                    </h2>
                </Col>

                <Col xs={24} md={17}>

                    <Row gutter={[12, 12]} className="border bg-light p-2 m-1">

                        <Col xs={24} className="bg-light">

                            <h2 className="font-size-18 mt-1">
                                Booking Items
                            </h2>

                            <Divider className="mt-0 mb-0"/>

                            <Table
                                columns={items_columns}
                                dataSource={items_data}
                                pagination={false}
                                rowKey={(record)=> `${record.id}-${record.package_title_en}-${record.title_en}`}
                                rowClassName="bg-light"
                                className="bg-light"
                                expandable={{
                                    showExpandColumn: booking_data.booking_type === "Travel",
                                    expandedRowRender: (row_data) => <BookingItemPersonsView {...row_data}/>,
                                }}
                                scroll={{
                                    x: 750,
                                }}
                            />

                            <h2 className="text-end font-size-16 m-3 mb-0">
                                <label className="me-3">Total :</label> {parseFloat(getTotal())} {booking_data.currency}
                            </h2>

                        </Col>


                    </Row>
                </Col>

                <Col xs={24} md={7}>
                    <Row gutter={[12, 12]} className="border bg-light p-2 h-100 m-1">
                        <Col xs={24}>
                            <Row gutter={[12, 12]}>

                        <Col xs={24} className="h-auto">
                            <h2 className="font-size-18 mt-1">
                                Booking Info
                            </h2>
                            <Divider className="mt-0 mb-1"/>
                        </Col>

                        <Col xs={24}>
                            <h2 className="font-size-12 text-black-50">
                                Booking Type
                            </h2>

                            <h2 className="font-size-14 ms-2 mb-0">
                                {`${booking_data.booking_type}`}
                            </h2>
                        </Col>


                        <Col xs={24}>
                            <h2 className="font-size-12 text-black-50">
                                Service Charge
                            </h2>

                            <h2 className="font-size-14 ms-2 mb-0">
                                {`${booking_data.add_service_charge ? "Include Service Charge" : "Exclude Service Charge"}`}
                            </h2>
                        </Col>


                        <Col xs={24}>
                            <h2 className="font-size-12 text-black-50">
                                Customer Full Name
                            </h2>

                            <h2 className="font-size-14 ms-2 mb-0">
                                {`${booking_data.customer_title} ${booking_data.customer_first_name} ${booking_data.customer_last_name}`}
                            </h2>
                        </Col>


                        <Col xs={24}>
                            <h2 className="font-size-12 text-black-50">
                                Customer Email
                            </h2>

                            <h2 className="font-size-14 ms-2 mb-0">
                                {`${booking_data.customer_email}`}
                            </h2>
                        </Col>


                        <Col xs={24}>
                            <h2 className="font-size-12 text-black-50">
                                Customer Contact Number
                            </h2>

                            <h2 className="font-size-14 ms-2 mb-0">
                                {`${(booking_data.customer_contact_number || "").replace("-", " ")}`}
                            </h2>
                        </Col>



                                <Col xs={24} className="mt-4">
                                  <CustomTextField
                                  label="Voucher Code"
                                  name="code"
                                  value={voucher.code}
                                  onChange={handleVoucherChange}
                                  error={voucher.helper_text !== undefined}
                                  helper_text={voucher.helper_text}
                                  disabled={isValidValue(voucher.VoucherId)}
                                  addonAfter={isValidValue(voucher.VoucherId)?(
                                          <CheckCircleOutlined className="text-success"/>
                                      ):(
                                            <Button type="link" size="small" className="p-0" onClick={applyVoucher} loading={voucher.loading}>
                                                Apply
                                            </Button>
                                    )
                                  }
                                  />
                                    <Hide hide={!isValidValue(voucher.VoucherId)}>
                                        <div className="w-100 text-end">
                                            <Button className="font-size-12" type="link" size="small" danger onClick={()=> handleVoucherChange("code", "")}>
                                                Remove Voucher
                                            </Button>
                                        </div>
                                    </Hide>


                                </Col>

                            </Row>
                        </Col>
                    </Row>
                </Col>

            </Row>
        </Col>
        </>

    )
}
