import {Col, Row, Tabs} from "antd";
import {CustomDynamicTable} from "../../global/components/CustomDynamicTable";
import {useState} from "react";
import {ChangeLogURL} from "../../global/urls";
import moment from "moment";
import {ChangeLogDetails} from "./components/ChangeLogDetails";
import {viewDateAndTime} from "../../global/helper_functions";

export const ChangeLogs = props =>{
    const parent_id = props.parent_id || "";
    const model_name = props.model_name || "";

    const [reload, setReload] = useState(false);
    const [columns] = useState([
        {
            title: "Ref No.",
            dataIndex: `ref_no`,
            width: "15%"
        },
        {
            title: "Title",
            dataIndex: `title`,
            width: "30%"
        },
        {
            title: "For",
            dataIndex: `model_name_view`,
            width: "25%"
        },
        {
            title: "Created By",
            dataIndex: `created_by_view`,
            width: "15%",
            render:(value)=>value || "-------"
        },
        {
            title: "Created At",
            dataIndex: `createdAt`,
            width: "15%",
            render:(value)=>viewDateAndTime(value)
        },

        ]);

    const reloadData = ()=>{
        setReload(Math.random);
    }


    return(
        <div className="paper pt-0 w-100">
        <Row>

            <Col xs={24}>
                <CustomDynamicTable
                    default_search={props.search}
                    table_props={{ rowKey: (record)=> record.ref_no}}
                    title="Change Logs"
                    // re_render_columns={language}
                    expandable={{
                        defaultExpandedRowKeys: ['0'],
                        columnWidth:"25", expandedRowRender:({ref_no})=> <ChangeLogDetails key={ref_no} {...props} ref_no={ref_no} reloadMainData={reloadData}/>}}
                    reload_data={reload}
                    columns={columns}
                    fetch_url={ChangeLogURL}
                    custom_params={{parent_id, model_name}}
                />
            </Col>
        </Row>
        </div>
    )
}
