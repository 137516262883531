import React, {useEffect, useState} from 'react';
import {Pie, PieChart, ResponsiveContainer, Sector, Tooltip} from 'recharts';
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {GetBookingsStatusReportURL} from "../../../global/urls";
import {DANGER_COLOR, PRIMARY_COLOR, PRIMARY_COLOR_TWO, SECONDARY_2_COLOR} from "../../../global/variables";
import {Badge, Button} from "antd";
import {FunnelPlotFilled} from "@ant-design/icons";
import {isValidValue} from "../../../global/helper_functions";
import Hide from "../../../global/components/Hide";
import FilterModal from "./components_modal/FilterModal";
import {OverlayTrigger} from "react-bootstrap";
import {Loading} from "../../../global/components/Loading";
import {checkPermission} from "../../../global/permissions";
import {permission_data} from "../../../global/permissions_data";

const COLORS = {
    "Completed": PRIMARY_COLOR,
    "Approved": PRIMARY_COLOR,
    "Cancelled": DANGER_COLOR,
    "Payment Pending": SECONDARY_2_COLOR,
    "Pending Approval": PRIMARY_COLOR_TWO,
};


const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        fill,
        payload,
        percent,
        value,
        name
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    const no_data = name === "No Data";

    return (
        <g fill={COLORS[name]}>
            <text x={cx} y={cy - (no_data ? 5 : 25)} dy={8} textAnchor="middle" fill={COLORS[name]}
                  className={`fw-bolder font-size-${no_data ? "15" : "12"}`}>
                {name}
            </text>
            <Hide hide={no_data}>
                <text x={cx} y={cy - 5} dy={8} textAnchor="middle" fill={COLORS[name]}>
                    count: {value}
                </text>
                <text x={cx} y={cy + 15} dy={8} textAnchor="middle" fill={COLORS[name]}>
                    ({(percent * 100).toFixed(2)}%)
                </text>
            </Hide>

            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={COLORS[name]}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={COLORS[name]}
            />
        </g>
    );
};


const BookingsStatusReport = props => {
    const [data, setData] = useState([{name: "No Data", count: 1}]);
    const [has_perm] = useState(checkPermission(permission_data.reports.view_bookings_status));
    const [active_index, setActiveIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState({});
    const [open_filter_modal, setOpenFilterModal] = useState(false);
    const [filter_count, setFilterCount] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            if (has_perm) {
                setLoading(true)
                const params = {};

                if (isValidValue(filter.booking_type)) {
                    params.booking_type = filter.booking_type;
                }

                if (isValidValue(filter.from_date)) {
                    params.from_date = filter.from_date;
                }

                if (isValidValue(filter.to_date)) {
                    params.to_date = filter.to_date;
                }

                if (isValidValue(filter.package_id)) {
                    params.package_id = filter.package_id;
                }

                let response = await httpRequestWithAuth(GetBookingsStatusReportURL, "GET", null, params);

                if (response.status) {
                    let temp_data = []
                    response.data.map(item => {
                        if (item.count > 0) {
                            temp_data.push(item)
                        }
                    })

                    if (temp_data.length === 0) {
                        temp_data.push({name: "No Data", count: 1})
                    }

                    setData(temp_data)
                }


                let temp_filter_count = 0;
                Object.keys(filter).map(data_key => {
                    if (isValidValue(filter[data_key])) {
                        temp_filter_count++;
                    }
                })
                setFilterCount(temp_filter_count);

                setLoading(false)
            }

        }

        fetchData();
    }, [filter])

    const onPieEnter = (_, index) => {
        setActiveIndex(index)
    };

    return (
        <div className="w-100 h-100 position-relative">
            <Hide hide={!loading}>
                <div
                    className="position-absolute d-flex justify-content-center align-items-center w-100 h-100 z-index-100">
                    <div className="blur-bg w-100 pt-1 pb-3">
                        <Loading hide_text/>
                    </div>
                </div>
            </Hide>

            <Hide hide={!open_filter_modal}>
                <FilterModal
                    include_booking_type
                    title="Bookings Status"
                    open={open_filter_modal}
                    filter={filter}
                    onFilter={setFilter}
                    handleModelClose={() => setOpenFilterModal(false)}
                />
            </Hide>

            <div className="d-flex justify-content-between">
                <h2 className="custom-grey-color font-size-16">
                    Bookings Status
                </h2>

                <OverlayTrigger
                    overlay={
                        <Tooltip>
                            Filter
                        </Tooltip>
                    }
                >
                    <Button className="border-0" onClick={() => setOpenFilterModal(true)}>

                        <Badge status="Error" count={filter_count} size="small">
                            <FunnelPlotFilled/>
                        </Badge>
                    </Button>
                </OverlayTrigger>
            </div>

            <ResponsiveContainer width="100%" height="85%">
                <PieChart width={400} height={400}>
                    <Pie
                        activeIndex={active_index}
                        activeShape={renderActiveShape}
                        data={data}
                        cx="50%"
                        cy="50%"
                        innerRadius={65}
                        outerRadius={85}
                        fill={PRIMARY_COLOR}
                        dataKey="count"
                        onMouseEnter={onPieEnter}
                    />
                </PieChart>
            </ResponsiveContainer>
        </div>

    );
}

export default BookingsStatusReport;
