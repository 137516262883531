import DynamicAutocomplete from "./DynamicAutocomplete";
import {ProductsListAllURL} from "../../urls";

export const ProductAutoComplete = (props)=>{
    const fetch_all = props.fetch_all || false;
    const product_type = props.product_type || "";

    return(
        <DynamicAutocomplete
            {...props}
            fetch_url={ProductsListAllURL}
            title_key={["ref_no", "title_en"]}
            fetch_in_search={!fetch_all}
            mode={props.multiple ? "multiple":""}
            params_array={[fetch_all, product_type]}
            params={{fetch_all, product_type}}
        />
    )
}


