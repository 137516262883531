import Hide from "../../../../global/components/Hide";
import ImagePlaceholder from "../../../../assets/img/img_place_holder.png";
import {Badge, Button, Carousel, Col, Collapse, Divider, Row} from "antd";
import {DANGER_COLOR_2, DISABLED_COLOR} from "../../../../global/variables";
import {useEffect, useRef, useState} from "react";
import {PackageOptionsViewer} from "./PackageOptionsViewer";
import {LeftOutlined} from "@ant-design/icons";
import CustomBadge from "../../../../global/components/CustomBadge";
import {isValidValue} from "../../../../global/helper_functions";
import useWindowSize from "../../../../global/useWindowSize";


const { Panel } = Collapse;


export const PackageViewer = props => {
    const addNewItem = props.addNewItem;
    const package_data = props.package_data || {};
    const view_option = props.view_option || false;
    const setViewOptionFor = props.setViewOptionFor ;
    const img_ref = useRef(null);
    const [image_height, setImageHeight] = useState(300);
    const inner_width  = useWindowSize().inner_width || 1000;


    useEffect(()=>{
        if (isValidValue(img_ref) && isValidValue(img_ref.current)){
            setImageHeight(img_ref.current.offsetWidth)
        }
    },[inner_width, img_ref])

    const handleSelectOptionBtn = (id) =>{
        if (setViewOptionFor){
            setViewOptionFor(id)
        }
    }

    let image_section = (
        <>
            <Hide hide={(package_data.PackageGalleries || []).length !== 0}>
                <div className="d-flex justify-content-center w-100 h-100">
                    <img width="100%" style={{maxWidth: 300, height: image_height, maxHeight: 300}} src={ImagePlaceholder}/>
                </div>
            </Hide>

            <Carousel autoplay>

                {(package_data.PackageGalleries || []).map(gallery_item => (
                    <div ref={img_ref} className="d-flex justify-content-center w-100">
                        <img width="100%" style={{maxWidth: 300, height: image_height, maxHeight: 300}}
                             src={gallery_item.display_photo}/>
                    </div>
                ))}
            </Carousel>
        </>

    )

    if (package_data.is_sold_out) {
        image_section = (
            <Badge.Ribbon color={DANGER_COLOR_2} text="Sold Out" placement="start">
                {image_section}
            </Badge.Ribbon>
        )
    }


    if (!package_data.is_active) {
        image_section = (
            <Badge.Ribbon color={DISABLED_COLOR} text="Inactive"
                          className={`${package_data.is_sold_out ? "mt-5" : ""}`}
                          placement="start">
                {image_section}
            </Badge.Ribbon>
        )
    }

    return (
        <Col key={package_data.id} xs={24} md={12} xxl={8} className="h-auto">
            <Row gutter={[12, 10]} className="w-100 border-radius-5 border p-2 h-100 m-0">

                <Hide hide={!view_option}>
                    <Col xs={24} key={package_data.ref_no}>
                        <Button className="mb-2 p-0" onClick={() => handleSelectOptionBtn("")} type="link" icon={<LeftOutlined />}>Back</Button>

                        <Collapse className="w-100 overflow-scroll" style={{maxHeight: 485}}>
                        {(package_data.PackageOptions || []).map((option_data, index)=> {

                            let header = (
                                <div>
                                    <h6 className="font-size-14">
                                        {`${option_data.title_en} - `}
                                        <label className="fw-bolder main-color">{`Price: ${option_data.price} ${package_data.currency}`}</label>
                                    </h6>
                                </div>
                            )


                            return(
                                <Panel header={header} key={props.key}>
                                <PackageOptionsViewer
                                    interval={props.interval}
                                    key={index}
                                    package_data={package_data}
                                    package_option={option_data}
                                    addNewItem={addNewItem}
                                />
                                </Panel>
                            )})}
                    </Collapse>
                    </Col>
                    <Col xs={24} className="d-flex align-items-end p-0">
                    <Button className=" p-0" onClick={() => handleSelectOptionBtn("")} type="link" icon={<LeftOutlined />}>Back</Button>
                    </Col>
                </Hide>

                <Hide hide={view_option}>

                    <Col xs={24}>
                        {image_section}

                        <Row>
                            <Col xs={24}>
                                <h2 className="font-size-15 text-center m-0 fw-bolder mt-3 mb-2">
                                    {package_data.title_en}
                                </h2>
                            </Col>

                            <Col xs={24}>
                                <Divider className="mb-2 mt-2 fw-bolder" orientation="left"
                                         orientationMargin="0">Countries</Divider>
                                <h6 className="font-size-13 text-center">
                                    <label className="fw-bolder">
                                        {/*Kuwait, Egypt, Jordan, Japan, UAE*/}
                                        {package_data.countries_view || "No Countries"}
                                </label>
                                </h6>
                            </Col>


                            <Col xs={24}>
                                <Divider className="mb-2 mt-2 fw-bolder" orientation="left"
                                         orientationMargin="0">Categories</Divider>
                                <h6 className="font-size-13 text-center">
                                    <label className="fw-bolder">
                                        {package_data.categories_view || "No Categories"}                                                            </label>
                                </h6>
                            </Col>


                            <Col xs={24}>
                                <Divider className="mb-2 mt-2 fw-bolder" orientation="left"
                                         orientationMargin="0">Details</Divider>
                            </Col>


                            <Col xs={24} md={12}>
                                <h6 className="font-size-13 fw-bolder text-center">
                                    Duration: {package_data.duration_view || "----"}
                                </h6>
                            </Col>


                            <Col xs={24} md={12}>
                                <h6 className="font-size-13 fw-bolder text-center">
                                    Currency: {package_data.currency || "----"}
                                </h6>
                            </Col>

                            <Col xs={24} md={12}>
                                <h6 className="font-size-13 fw-bolder text-center">
                                    Min per customer: {package_data.min_capacity_per_customer || "----"}
                                </h6>
                            </Col>

                            <Col xs={24} md={12}>
                                <h6 className="font-size-13 fw-bolder text-center">
                                    Max per customer: {package_data.max_capacity_per_customer || "----"}
                                </h6>
                            </Col>

                        </Row>

                    </Col>

                    <Col xs={24} className="d-flex align-items-end w-100 mb-2 mt-2">
                        <Button block type="primary" onClick={() => handleSelectOptionBtn(package_data.id)}>
                            Select Option
                        </Button>
                    </Col>
                </Hide>

            </Row>
        </Col>
    )
}
