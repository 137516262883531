import React, {useEffect, useState} from "react";
import {Button, Row, Tooltip} from "antd";
import {CustomDynamicTable} from "../../../global/components/CustomDynamicTable";
import {PackagesOptionDateURL} from "../../../global/urls";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {isValidValue, openNotification, showConfirm, showInfo} from "../../../global/helper_functions";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import {AddButton} from "../../../global/components/AddButton";
import Hide from "../../../global/components/Hide";
import {PackageOptionDateFormModal} from "./components/PackageOptionDateFormModal";
import CustomBadge from "../../../global/components/CustomBadge";
import {InfoIconGreenFilled} from "../../../global/components/Icons";
import {PackageOptionDateTimeForm} from "./components/PackageOptionDateTimeForm";
import {BulkAddDateTimeFormModal} from "./components/BulkAddDateTimeFormModal";
import {CustomStatusView} from "../../../global/components/CustomStatusView";
import {WARNING_COLOR} from "../../../global/variables";
import {PackageOptionDatesGuidelines} from "./components/PackageOptionDatesGuidelines";
import CustomCheckBox from "../../../global/components/fields/CustomCheckBox";
import CustomDropDown from "../../../global/components/CustomDropDown";
import {DeleteOutlined, SmileOutlined} from "@ant-design/icons";


export const PackageOptionDates = props => {
    const package_option_id = props.package_option_id || "";
    const slug = props.slug || "";
    const language = props.language || "en";
    const package_option_data = props.package_option_data || {};
    const {is_admin, has_pending_status, approve_pending_status_loading, approveAllPendingStatus, checkHasPendingStatus} = props;

    const [open_from_data, setOpenFormData] = useState(false);
    const [form_data, setFormData] = useState({});
    const [reload, setReload] = useState(false);
    const [open_time_modal, setOpenTmeModel] = useState(false);
    const [open_bulk_add_time_modal, setOpenBulkAddTmeModel] = useState(false);
    const [data_time_modal, setDataTimeModal] = useState("");
    const [selected_items, setSelectedItems] = useState([]);
    const [action_loading, setActionLoading] = useState(false);

    const [package_option_date_perm] = useState({
        delete: checkPermission(permission_data.package_option_date.delete),
        edit: checkPermission(permission_data.package_option_date.edit),
        add: checkPermission(permission_data.package_option_date.add),
    });




    const columns = [
        {
            title: (table_data)=> <CustomCheckBox onChange={()=>onSelectAll(table_data)} value={isSelectedAll(table_data)}/>,
            dataIndex: "id",
            width: "2%",
            align: "center",
            render: value => <CustomCheckBox value={selected_items.includes(value)} onChange={onSelectChange}
                                             name={value}/>
        },
        {
            title:"Pending Changes",
            dataIndex: "has_changes",
            width: "5%",
            align:"center",
            render: (value, row_data)=> {
                if (!value)return <CustomBadge type="disabled" title="No Changes"/>;
                return(
                    <CustomBadge
                        bg_color={WARNING_COLOR}
                        title="View Changes"
                        onClick={()=>goToPendingChanges(row_data)}
                    />
                )
            }
        },
        {
            title: (
                <div className="w-100">

                    <span className="fw-bold">
                                        Date
                    </span>

                    <Tooltip
                        className="me-2"
                        placement="topLeft"
                        title={(
                            <div className="text-black">
                                <span className="d-flex align-items-center">
                                <CustomBadge type="danger" className="me-2"/>
                                for sold out date</span>

                                <span className="d-flex align-items-center">
                                <CustomBadge type="disabled" className="me-2"/>
                                for Inactive date
                                </span>

                                <span className="d-flex align-items-center">
                                <CustomBadge className="me-2"/>
                                for active date
                                </span>

                                <span className="d-flex align-items-center">
                                <CustomBadge type="primary" className="me-2"/>
                                for unique date
                                </span>

                            </div>
                        )}
                        color="whitesmoke"
                        arrowPointAtCenter
                    >
                         <span className="p-0 m-0">
            <InfoIconGreenFilled/>
              </span>
                    </Tooltip>
                </div>
            ),
            dataIndex: "date_view",
            width: "15%",
            render: (value, row_data) => {

                if (!row_data.is_active) {
                    return <CustomBadge type="disabled" className="m-1" title={value}/>
                }

                if (row_data.is_sold_out) {
                    return <CustomBadge type="danger" className="m-1" title={value}/>
                }

                if (row_data.is_unique) {
                    return <CustomBadge type="primary" className="m-1" title={value}/>
                }

                return (
                    <CustomBadge className="m-1" title={value}/>
                )
            }
        },
        {
            title: (
                <div className="w-100">

                    <span className="fw-bold">
                                        Times
                    </span>

                    <Tooltip
                        className="me-2"
                        placement="topLeft"
                        title={(
                            <div className="text-black">
                                <span className="d-flex align-items-center">
                                <CustomBadge type="danger" className="me-2"/>
                                for sold out time</span>

                                <span className="d-flex align-items-center">
                                <CustomBadge type="disabled" className="me-2"/>
                                for Inactive time
                                </span>

                                <span className="d-flex align-items-center">
                                <CustomBadge className="me-2"/>
                                for active time
                                </span>

                                <span className="d-flex align-items-center">
                                <CustomBadge type="primary" className="me-2"/>
                                for unique time
                                </span>

                            </div>
                        )}
                        color="whitesmoke"
                        arrowPointAtCenter
                    >
                         <span className="p-0 m-0">
            <InfoIconGreenFilled/>
              </span>
                    </Tooltip>
                </div>
            ),
            dataIndex: "PackageOptionDateTimes",
            width: "50%",
            render: (value, row_data) => {
                if (!row_data.has_time) {
                    return "This date doesn't have time"
                }



                return (
                    <>

                    <span>

                    {(value || []).map(time_data => (
                        <Tooltip
                            className="me-2"
                            placement="topLeft"
                            title={(
                                <div className="text-black">
                                    <div>Qty: <span className="me-1 fw-bold">{time_data.bookings_number}/{time_data.capacity}</span></div>
                                    <div>Sold Out: <span
                                        className="me-1 fw-bold">{time_data.is_sold_out ? "Yes" : "No"}</span></div>
                                    <div>Unique: <span
                                        className="me-1 fw-bold">{time_data.is_unique ? "Yes" : "No"}</span></div>
                                    <div>Activate: <span
                                        className="me-1 fw-bold">{time_data.is_active ? "Yes" : "No"}</span></div>
                                </div>
                            )}
                            color="white"
                            arrowPointAtCenter
                        >
                            <div className="d-inline">
                                {!time_data.is_active ?
                                    <CustomBadge type="disabled" className="m-1" title={time_data.time_view}/>
                                    : time_data.is_sold_out ?
                                        <CustomBadge type="danger" className="m-1" title={time_data.time_view}/>
                                        : time_data.is_unique ?
                                            <CustomBadge type="primary" className="m-1" title={time_data.time_view}/>
                                            : <CustomBadge className="m-1" title={time_data.time_view}/>}
                            </div>

                        </Tooltip>
                    ))}

                        <Hide hide={value.length > 0}>
                            No time has been added
                        </Hide>

                                            </span>

                        <span className="ms-3">
                        <Button className="m-1" onClick={() => handleOpenTimeModal(row_data)}>View Times</Button>
                    </span>

                        {/*<CustomBadge className="m-1" title="10:00 PM"/>*/
                        }
                        {/*<CustomBadge className="m-1" title="11:00 PM"/>*/
                        }
                        {/*<CustomBadge className="m-1" title="9:00 PM"/>*/
                        }
                    </>
                )
            }
        },
        {
            title: "Qty",
            dataIndex: "capacity",
            width: "5%",
            align: "center",
            render: (value, row_data)=>{
                if (row_data.has_time){
                    return "----";
                }

                return `${row_data.bookings_number} / ${value}`

            }
        },
        // {
        //     title: "Unique",
        //     dataIndex: "is_unique",
        //     width: "5%",
        //     align: "center",
        //     render: (value) => <IsActiveView active_label="Yes" inactive_label="No" value={value}
        //                                      view_only={!package_option_date_perm.edit}/>
        // },
        // {
        //     title: "Sold Out",
        //     dataIndex: "is_sold_out",
        //     width: "5%",
        //     align: "center",
        //     render: (value) => <IsActiveView active_label="Yes" inactive_label="No" value={value}
        //                                      view_only={!package_option_date_perm.edit}/>
        // },
        // {
        //     title: "Activate",
        //     dataIndex: "is_active",
        //     width: "5%",
        //     align: "center",
        //     render: (value) => <IsActiveView active_label="Yes" inactive_label="No" value={value}
        //                                      view_only={!package_option_date_perm.edit}/>
        // },
        {
            title: "Status",
            dataIndex: "status",
            width: "5%",
            align:"center",
            render: (value)=> <CustomStatusView value={value}/>
        },
    ];


    const actions_options = [
        {
            key: '1',
            label: <span className="fw-bold text-primary">Activate Dates</span>,
            hidden: !package_option_date_perm.edit,
            icon: <SmileOutlined className="text-primary"/>,
        },
        {
            key: '2',
            label: <span className="text-black-50">Deactivate Dates</span>,
            hidden: !package_option_date_perm.edit,
            icon: <SmileOutlined/>,
        },
        {
            key: '3',
            label: <span className="fw-bold main-color">Mark Dates as Available</span>,
            hidden: !package_option_date_perm.edit,
            icon: <SmileOutlined className="main-color"/>,
        },
        {
            key: '4',
            label: <span className="text-danger">Mark Dates as Sold Out</span>,
            hidden: !package_option_date_perm.edit,
            icon: <SmileOutlined className="text-danger"/>,
        },
        {
            key: '5',
            label: <span className="fw-bold">Mark Dates as Unique</span>,
            hidden: !package_option_date_perm.edit,
            icon: <SmileOutlined className="fw-bold"/>,
        },
        {
            key: '5',
            label: <span className=" text-black-50">Mark Dates as not Unique</span>,
            hidden: !package_option_date_perm.edit,
            icon: <SmileOutlined className="fw-bold text-black-50"/>,
        },
        {
            type: 'divider',
            hidden: !package_option_date_perm.edit || !package_option_date_perm.delete,
        },
        {
            key: '7',
            label: <span className="text-danger fw-bold">Delete Dates</span>,
            hidden: !package_option_date_perm.edit,
            icon: <DeleteOutlined className="text-danger fw-bold"/>,
        },
    ];


    useEffect(() => {
        setReload(Math.random());

        if (!has_pending_status){
            checkHasPendingStatus("PackageOptionDates")
        }

    }, [package_option_id, has_pending_status])


    const isSelectedAll = (table_data)=>{
        let is_select_all = table_data.length > 0;

        for (let i = 0; i < table_data.length; i++) {
            if (!selected_items.includes(table_data[i].id)){
                is_select_all = false;
                break;
            }
        }

        return is_select_all;
    }

    const onSelectChange = (id, is_selected) => {
        let temp_selected_item = [...selected_items];

        if (is_selected) {
            if (!temp_selected_item.includes(id)) {
                temp_selected_item.push(id)
            }
        } else {
            let item_index = temp_selected_item.indexOf(id);
            if (item_index > -1) {
                temp_selected_item.splice(item_index, 1);
            }
        }

        setSelectedItems(temp_selected_item);
    }

    const goToPendingChanges = (row_data) =>{
        props.handleTabsChange("7", {search: row_data.id})
    }


    const handelOpenFormData = (data = {}) => {
        setFormData(data)
        setOpenFormData(true)
    }

    const handelCloseFormData = (reload_data = false) => {
        setFormData({});
        setOpenFormData(false);

        setDataTimeModal("");
        setOpenTmeModel(false);

        setOpenBulkAddTmeModel(false);

        if (reload_data) {
            setReload(Math.random());
        }

    }


    const onSelectAll = (table_data) =>{
        let temp_selected_ids = [];
        if (!isSelectedAll(table_data)){
            table_data.map(item=>temp_selected_ids.push(item.id));
        }
        setSelectedItems(temp_selected_ids);
    }

    const onDelete = async (row_data) => {
        let response = await httpRequestWithAuth(`${PackagesOptionDateURL}${row_data.id}`, "DELETE");
        if (response.status) {
            openNotification("success", "Delete Package Option Date", `${row_data.date_view} package option date has been deleted`)
        } else {
            openNotification("error", "Delete Package Option Date", response.error_feedback.non_field_errors)
        }
    }


    const onEdit = async (row_data) => {
        handelOpenFormData({...row_data, PackageOptionId: package_option_id});
    }

    const onAdd = async () => {
        handelOpenFormData({
            PackageOptionId: package_option_id,
            capacity: package_option_data.default_capacity,
        })
    }


    const updateDates = async (items=[], new_data={}) => {
        await setActionLoading(true);
        let has_pending_change = false;
        let success_counter = 0;
        for (let i = 0; i < items.length; i++) {
        let response = await httpRequestWithAuth(`${PackagesOptionDateURL}${items[i]}`, "PATCH", new_data);

        if (response.status) {
                if (response.data.pending_change) {
                    has_pending_change = response.data.msg
                }else{
                    success_counter++;
                }

        } else {
            openNotification("error", "Update Package Option Date", response.error_feedback.non_field_errors)
        }

        }


        if (has_pending_change){
            showInfo("Update Package Option Dates", has_pending_change);
        }else if (success_counter > 0){
            openNotification("success", "Update Package Option Date",  `${success_counter === items.length? "All": `${success_counter} of`} selected dates has been updated`)
        }


        setReload(Math.random());
        await setActionLoading(false);

    }



    const handleDelete = (items) => {
        showConfirm("Delete",  `Are you sure to delete the selected (${selected_items.length}) dates ?`, ()=>deleteDates(items));
    }

    const deleteDates = async (items=[]) => {
        await setActionLoading(true);
        let success_counter = 0;

        for (let i = 0; i < items.length; i++) {
            let response = await httpRequestWithAuth(`${PackagesOptionDateURL}${items[i]}`, "Delete");

            if (response.status) {
                    success_counter++;
            } else {
                openNotification("error", "Delete Package Option Date", response.error_feedback.non_field_errors)
            }

        }


         if (success_counter > 0){
            openNotification("success", "Delete Package Option Dates",  `${success_counter === items.length? "All": `${success_counter} of`} selected dates has been deleted`)
        }


        setReload(Math.random());
        await setActionLoading(false);

    }

    const handleOpenTimeModal = async (row_data) => {
        setOpenTmeModel(true);
        setDataTimeModal(row_data)
    }

    const bulkAddTime = () => {
        setOpenBulkAddTmeModel(true)
    }



    const handelActionButtonOptions = ({key}) => {
        if (key === "7") {
            handleDelete(selected_items)
        }else{
            const need_to_update = {};

            if (key === "1"){
                need_to_update.is_active = true;
            }
            if (key === "2"){
                need_to_update.is_active = false;
            }
            if (key === "3"){
                need_to_update.is_sold_out = false;
            }
            if (key === "4"){
                need_to_update.is_sold_out = true;
            }
            if (key === "5"){
                need_to_update.is_unique = true;
            }
            if (key === "6"){
                need_to_update.is_unique = false;
            }

                updateDates(selected_items, need_to_update);
        }

        setSelectedItems([]);
    };

    const freeAction = (
        <>
            <Hide hide={selected_items.length > 0}>
            <AddButton onClick={onAdd}>Add Date</AddButton>
            <AddButton onClick={bulkAddTime}>Add Multiple Times</AddButton>
            </Hide>
            <Hide hide={selected_items.length === 0}>
                <CustomDropDown btn_type="primary" label="Actions" options={actions_options}
                                onChange={handelActionButtonOptions}/>
            </Hide>

        </>
    );

    return (
        <Row className="m-md-4 mt-md-0">
            <Hide hide={!open_from_data}>
                <PackageOptionDateFormModal
                    language={language}
                    open={open_from_data}
                    data={form_data}
                    handleModelClose={handelCloseFormData}
                    package_option_data={package_option_data}
                />
            </Hide>

            <Hide hide={!open_time_modal}>
                <PackageOptionDateTimeForm
                    language={language}
                    open={open_time_modal}
                    package_option_date_data={data_time_modal}
                    handleModelClose={handelCloseFormData}
                />
            </Hide>

            <Hide hide={!open_bulk_add_time_modal}>
                <BulkAddDateTimeFormModal
                    language={language}
                    open={open_bulk_add_time_modal}
                    handleModelClose={handelCloseFormData}
                />
            </Hide>

            <div className="w-100 mb-3">
            <PackageOptionDatesGuidelines/>
            </div>

            <CustomDynamicTable
                title={
                    <label className="text-start w-100">
                        Option Dates
                        <br/>

                        <span className="font-size-13 text-black-50">
                                        Should be there at least one Date
                    </span>

                        <span className="d-md-inline d-flex ms-md-3">
                    <CustomBadge type="danger" className="m-1" title="Sold Out"/>
                    <CustomBadge type="disabled" className="m-1" title="Inactive"/>
                    <CustomBadge className="m-1" title="Active"/>
                    <CustomBadge type="primary" className="m-1" title="Unique"/>
                </span>
                    </label>
                }
                reload_data={reload}
                columns={columns}
                fetch_url={PackagesOptionDateURL}
                free_action={freeAction}
                onDelete={package_option_date_perm.delete && onDelete}
                onEdit={package_option_date_perm.edit && onEdit}
                custom_params={{"package_option": package_option_id}}
                table_min_height={1}
            />
        </Row>
    )
}
