import {Button, Col, Row} from "antd";
import {CustomDynamicTable} from "../../global/components/CustomDynamicTable";
import {useState} from "react";
import {CustomizeVoucherURL} from "../../global/urls";
import {httpRequestWithAuth} from "../../global/axios_instance";
import {openNotification} from "../../global/helper_functions";
import {permission_data} from "../../global/permissions_data";
import {checkPermission} from "../../global/permissions";
import {AddButton} from "../../global/components/AddButton";
import Hide from "../../global/components/Hide";
import {IsActiveView} from "../../global/components/IsActiveView";
import {CustomizeVoucherModal} from "./components/CustomizeVoucherModal";

export const CustomizeVoucher = props =>{
    const {voucher_id} = props;
    const [open_from_data, setOpenFormData] = useState(false);
    const [form_data, setFormData] = useState({});
    const [reload, setReload] = useState(false);

    const [voucher_perm] = useState({
        delete: checkPermission(permission_data.customize_voucher.delete),
        edit: checkPermission(permission_data.customize_voucher.edit),
        add: checkPermission(permission_data.customize_voucher.add),
    });

    const [columns] = useState([
        {
            title: "For",
            dataIndex: "custom_for",
            width: "15%",
        },
        {
            title: "Customer Type",
            dataIndex: "customer_type",
            width: "15%"
        },
        // {
        //     title: "Dates Type",
        //     dataIndex: "dates_type",
        //     width: "15%"
        // },
        // {
        //     title: "Times Type",
        //     dataIndex: "times_type",
        //     width: "15%"
        // },
        {
            title: "Activate",
            dataIndex: "is_active",
            width: "5%",
            align:"center",
            render: (value)=><IsActiveView value={value} view_only={!voucher_perm.edit}/>
        },
        {
            title: "Notes",
            dataIndex: "notes",
            width: "30%"
        },
    ])

    const handelOpenFormData = (data={})=>{
        setFormData({...data, VoucherId: voucher_id})
        setOpenFormData(true)
    }

    const handelCloseFormData = (reload_data=false)=>{
        setFormData({});
        setOpenFormData(false);

        if (reload_data){
            setReload(Math.random());
        }

    }

    const onDelete = async (row_data) =>{
        let response = await httpRequestWithAuth(`${CustomizeVoucherURL}${row_data.id}`, "DELETE");
        if (response.status){
            openNotification("success", "Delete Customize Voucher", `Customize voucher has been deleted`)
        }else{
            openNotification("error", "Delete Customize Voucher", response.error_feedback.non_field_errors)
        }
    }

    const onEdit = async (row_data) =>{
        handelOpenFormData(row_data)
    }

    const onAdd = async () =>{
        handelOpenFormData()
    }

    const freeAction = (
        <>
            <AddButton onClick={onAdd}>Add Customization</AddButton>
        </>
    );

    return(
        <Row>
            <Hide hide={!open_from_data}>
                <CustomizeVoucherModal
                    open={open_from_data}
                    data={form_data}
                    handleModelClose={handelCloseFormData}
                />
            </Hide>

            <Col xs={24}>
                <CustomDynamicTable
                    title="Customize Voucher"
                    reload_data={reload}
                    columns={columns}
                    fetch_url={CustomizeVoucherURL}
                    free_action={freeAction}
                    onDelete={voucher_perm.delete && onDelete}
                    onEdit={voucher_perm.edit && onEdit}
                    custom_params={{"voucher": voucher_id}}
                />
            </Col>
        </Row>
    )
}
