import React from "react";
import {Button, Col, Row} from "antd";
import {CustomDynamicTable} from "../../global/components/CustomDynamicTable";
import {useState} from "react";
import {ConsultationRequestsURL} from "../../global/urls";
import {httpRequestWithAuth} from "../../global/axios_instance";
import {openNotification} from "../../global/helper_functions";
import {permission_data} from "../../global/permissions_data";
import {checkPermission} from "../../global/permissions";
import {AddButton} from "../../global/components/AddButton";
import Hide from "../../global/components/Hide";
import CustomBadge from "../../global/components/CustomBadge";
import CustomCheckBox from "../../global/components/fields/CustomCheckBox";
import {ConsultationRequestChangeStatusModal} from "./components/ConsultationRequestChangeStatusModal";
import {EyeOutlined} from "@ant-design/icons";
import {ConsultationResponseFormModal} from "./components/ConsultationResponseFormModal";


export const Consultations = props => {
    const {slug} = props;
    const [response_form_data, setResponseFormData] = useState("");
    const [reload, setReload] = useState(false);
    const [selected_items, setSelectedItems] = useState([]);
    const [open_change_status_modal, setOpenChangeStatusModal] = useState(false);

    const [consultation_request_perm] = useState({
        delete: checkPermission(permission_data.consultation_request.delete),
        edit: checkPermission(permission_data.consultation_request.edit),
        add: checkPermission(permission_data.consultation_request.add),
    });

    const columns = [
        {
            dataIndex: "id",
            width: "2%",
            align: "center",
            render: value => <CustomCheckBox value={selected_items.includes(value)} onChange={onSelectChange}
                                             name={value}/>
        },
        {
            title: "Ref",
            dataIndex: "ref_no",
            width: "10%",
        },
        {
            title: "Audience Type",
            dataIndex: "audience_type",
            width: "15%",
        },
        {
            title: "Name",
            dataIndex: "name",
            width: "15%",
        },
        {
            title: "Email",
            dataIndex: "email",
            width: "20%",
        },
        {
            title: "Status",
            dataIndex: "status",
            width: "10%",
            align: "center",
            render: (value) => <CustomBadge {...props} title={value}
                                            type={value === "New" ? "disabled" : value === "Cancelled" ? "danger" : ""}/>

        },
    ];


    const extra_actions = [
        {
            key: "action-1",
            icon: EyeOutlined,
            onClick: (row_data)=>setResponseFormData({...row_data.ConsultationResponses[0], consultation_request: row_data.id}),
            hide: (row_data)=> row_data.responses_number !== 1,
            render: (row_data)=> <span>View Response</span>,
        },
        {
            key: "action-2",
            icon: EyeOutlined,
            onClick: (row_data)=>setResponseFormData({consultation_request: row_data.id}),
            hide: (row_data)=> row_data.responses_number !== 0,
            render: (row_data)=> <span>Add Response</span>,
        },
        {
            key: "action-3",
            icon: EyeOutlined,
            onClick: (row_data)=>onEdit(row_data, "2"),
            hide: (row_data)=> row_data.responses_number < 2,
            render: (row_data)=> <span>View All Responses</span>,
        },
    ]

    const onSelectChange = (id, is_selected) => {
        let temp_selected_item = [...selected_items]
        if (is_selected) {
            if (!temp_selected_item.includes(id)) {
                temp_selected_item.push(id)
            }
        } else {
            let item_index = temp_selected_item.indexOf(id);
            if (item_index > -1) {
                temp_selected_item.splice(item_index, 1);
            }
        }

        setSelectedItems(temp_selected_item);
    }


    const handelCloseFormData = (reload_data = false) => {
        setResponseFormData("");
        if (reload_data) {
            setReload(Math.random());
        }

    }

    const onDelete = async (row_data) => {
        let response = await httpRequestWithAuth(`${ConsultationRequestsURL}${row_data.id}`, "DELETE");
        if (response.status) {
            openNotification("success", "Delete Consultation Request", `${row_data.title} consultation request has been deleted`)
        } else {
            openNotification("error", "Delete Consultation Request", response.error_feedback.non_field_errors)
        }
    }

    const onEdit = async (row_data, tab="") => {
        let url = `/${slug}/consultations/${row_data.id}`

        if (tab){
            url += `?tab=${tab}`
        }

        props.history.push(url);
    }

    const onAdd = async () => {
        props.history.push(`/${slug}/consultations/new`);
    }

    const openChangeStatusModal = async () => {
        setOpenChangeStatusModal(true);
    }
    const closeChangeStatusModal = async (remove_select = false) => {
        setReload(Math.random());
        if (remove_select) {
            setSelectedItems([]);
        }
        setOpenChangeStatusModal(false);
    }

    const freeAction = (
        <>

            <Hide hide={selected_items.length === 0}>
                <Button
                    block
                    type="primary"
                    className="mb-1 m-md-1 justify-content-center"
                    onClick={openChangeStatusModal}
                >
                    Change Status
                </Button>
            </Hide>

            <AddButton onClick={onAdd}>Add Consultation Request</AddButton>

        </>
    );

    return (
        <Row>

            <Hide hide={!response_form_data}>
                <ConsultationResponseFormModal
                    open={response_form_data}
                    data={response_form_data}
                    handleModelClose={handelCloseFormData}
                    consultation_request={response_form_data.consultation_request}
                />
            </Hide>

            <Hide hide={!open_change_status_modal}>
                <ConsultationRequestChangeStatusModal
                    open={open_change_status_modal}
                    consultation_requests={selected_items}
                    handleModelClose={closeChangeStatusModal}
                />
            </Hide>

            <Col xs={24} className="paper">
                <CustomDynamicTable
                    title="Consultation Request"
                    reload_data={reload}
                    columns={columns}
                    fetch_url={ConsultationRequestsURL}
                    free_action={freeAction}
                    onDelete={consultation_request_perm.delete && onDelete}
                    onEdit={consultation_request_perm.edit && onEdit}
                    extra_actions={extra_actions}
                />
            </Col>
        </Row>
    )
}
