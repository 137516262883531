import React from "react";
import {CustomTabPane} from "./CustomTabPane";
import {Tabs} from "antd";

export const StaticLanguageTabs = props =>{
    return(
        <Tabs {...props} activeKey={props.value} onChange={props.onChange} className={props.className} >
            <CustomTabPane tab="English" key="en"/>
            <CustomTabPane tab="Arabic" key="ar" disabled={props.disabled_ar}/>
        </Tabs>
    )
}