import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {HELoading} from "../../global/components/HELoading";
import Bg from "../../assets/img/bg_1.jpg";
import {Button, Col, Row} from "antd";
import Logo from "../../assets/img/he_logo_black.png";
import EmailVerified from "../../assets/img/email-verification_1.png";
import Hide from "../../global/components/Hide";
import {httpRequestWithoutAuth} from "../../global/axios_instance";
import {BaseBackEndAPIsURL, VerifyEmailURL} from "../../global/urls";

export const VerifyEmail = (props) => {
    const url_query = new URLSearchParams(props.location.search);
    const token = url_query.get("token") || "";
    const [is_loading, setLoading] = useState(true);
    const [result, setResult] = useState(false);
    const [data, setData] = useState({});

    useEffect(()=>{
        const verifyEmail = async ()=>{
            let response = await httpRequestWithoutAuth(`${BaseBackEndAPIsURL}${VerifyEmailURL}`, "POST", {verify_email_token:token})
            if (response.status){
                setData(response.data);
                setResult(true);
            }else{
                setData(response.error_feedback);
                setResult(false);
            }
            setLoading(false)
        }

        verifyEmail();
    },[])

    if (is_loading) {
        return <HELoading full_page/>
    }

    const signIn = () => {
        props.history.push(`/${data.slug}/signin`)
    }

    return (
        <main className="vh-100 overflow-hidden bg-light">
            <img src={Bg} className="vh-100 vw-100 position-absolute z-index-0"/>
            <Row className="d-flex justify-content-center align-items-start pt-5 mt-5">
                <Col xs={20} sm={16} md={10} lg={8}
                     className="shadow border-radius-10 bg-white d-flex flex-column align-items-center p-4 mt-5 ">

                    <Col xs={21} sm={18} md={16} lg={8}>
                        <img src={Logo} className="w-100"/>
                    </Col>

                    <div className="w-100 text-center mt-4 mb-4">
                        <Hide hide={result}>
                            <h1>
                                {data.non_field_errors}
                            </h1>
                        </Hide>

                        <Hide hide={!result}>

                            <div className="w-100 d-flex justify-content-center ">
                                <Col xs={21} sm={18} md={16} lg={8}>
                                    <img src={EmailVerified} className="w-100"/>
                                </Col>
                            </div>

                            <h1 className="mt-5 mb-0">
                                Email Verified! Thank You
                            </h1>

                            <Button
                                loading={is_loading}
                                disabled={is_loading}
                                onClick={signIn}
                                type="primary"
                                className="bg-black mt-4"
                            >
                                Sign In
                            </Button>
                        </Hide>

                    </div>

                </Col>
            </Row>
        </main>
    )
}