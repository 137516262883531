import DynamicAutocomplete from "./DynamicAutocomplete";
import {ListPackagesOptionDateTimeURL} from "../../urls";

export const PackageOptionsDatesTimesAutoComplete = (props)=>{
    const packages_options_dates = props.packages_options_dates || null;
    const package_option_date = props.package_option_date || null;
    const hide_unique_and_sold_out = props.hide_unique_and_sold_out || null;


    return(
        <DynamicAutocomplete
            {...props}
            fetch_url={ListPackagesOptionDateTimeURL}
            title_key={props.title_key || "time_view"}
            mode={props.multiple ? "multiple":""}
            params_array={[package_option_date, packages_options_dates]}
            params={{package_option_date, packages_options_dates, hide_unique_and_sold_out}}
        />
    )
}
