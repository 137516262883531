import React, {useEffect, useState} from "react";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {GetSalesRefundURL} from "../../../global/urls";
import {ComponentThree} from "./ComponentThree";
import {checkPermission} from "../../../global/permissions";
import {permission_data} from "../../../global/permissions_data";

const TodaySalesRefund = () => {
    const [data, setData] = useState(0);
    const [has_perm] = useState(checkPermission(permission_data.reports.view_today_refund));

    useEffect(() => {
        const fetchData = async () => {
            if (has_perm) {
                let response = await httpRequestWithAuth(GetSalesRefundURL, "GET");
                if (response.status) {
                    setData(response.data);
                }
            }
        }

        fetchData();
    }, [])


    return <ComponentThree title="Today Refund" value={`${data} KWD`}/>

}


export default TodaySalesRefund;
