import React, {useState} from "react";
import {Alert, Badge, Button, Col, Divider, Popconfirm, Row} from "antd";
import {CustomTextField} from "../../../../global/components/fields/CustomTextField";
import Hide from "../../../../global/components/Hide";
import {isValidValue, upperCaseView} from "../../../../global/helper_functions";
import CustomSelectField from "../../../../global/components/fields/CustomSelectField";
import {DANGER_COLOR_2, DISABLED_COLOR, payment_methods, payment_status} from "../../../../global/variables";
import {CustomDateTimeField} from "../../../../global/components/fields/CustomDateTimeField";
import {ViewInfo} from "../../../../global/components/ViewInfo";
import {EditFilled} from "@ant-design/icons";
import CustomBadge from "../../../../global/components/CustomBadge";
import {checkPermission} from "../../../../global/permissions";
import {permission_data} from "../../../../global/permissions_data";

export const BookingPaymentRow = props => {
    const {
        is_edit,
        setIsEdit,
        is_agent,
        language,
        payment_data,
        handleFieldChange,
        helper_text,
        removePayment,
        index
    } = props
    const is_new = (payment_data.id || "#").startsWith("#")
    const [is_paid] = useState(payment_data.payment_status === "Paid");
    const [can_edit_paid] = useState(checkPermission(permission_data.booking_payment.edit_paid));


    const view_body = (
        <Row gutter={[12, 10]} key={payment_data.id}>

            <Col xs={24} md={6}>
                <ViewInfo
                    title={`Title ${upperCaseView(language)}`}
                    value={payment_data[`title_${language}`]}
                    error={helper_text[`title_${language}`] !== undefined}
                    helper_text={helper_text[`title_${language}`]}
                />
            </Col>


            <Col xs={24} md={6}>
                <ViewInfo
                    title="Payment Method"
                    value={payment_data.payment_method || "-----"}
                    error={helper_text.payment_method !== undefined}
                    helper_text={helper_text.payment_method}
                />
            </Col>


            <Col xs={24} md={6}>
                <ViewInfo
                    title="Amount"
                    value={`${payment_data.amount} ${payment_data.currency}` || "-----"}
                    error={helper_text.amount !== undefined}
                    helper_text={helper_text.amount}
                />
            </Col>

            <Col xs={24} md={6}>
                <ViewInfo
                    title="Payment Status"
                    value={<CustomBadge type={payment_data.payment_status === "Pending"?"disabled":""} title={payment_data.payment_status || "-----"}/>}
                    error={helper_text.payment_status !== undefined}
                    helper_text={helper_text.payment_status}
                />
            </Col>

            <Col xs={24} md={6}>
                <ViewInfo
                    title="Payment Ref"
                    value={payment_data.payment_ref || "-----"}
                    error={helper_text.payment_ref !== undefined}
                    helper_text={helper_text.payment_ref}
                />
            </Col>

            <Col xs={24} md={6}>
                <ViewInfo
                    title="Payment Provider Ref"
                    value={payment_data.payment_provider_ref || "-----"}
                    error={helper_text.payment_provider_ref !== undefined}
                    helper_text={helper_text.payment_provider_ref}
                />
            </Col>


            <Col xs={24} md={6}>
                <ViewInfo
                    title="Payment Date"
                    value={payment_data.payment_date_view || "-----"}
                    error={helper_text.payment_date !== undefined}
                    helper_text={helper_text.payment_date}
                />
            </Col>


            <Col xs={24} md={6}>
                <ViewInfo
                    title="Payment Deadline"
                    value={<span className={`${payment_data.is_payment_deadline_passed?"text-danger":""}`}>
                        {payment_data.payment_deadline_view}
                    </span>}
                    error={helper_text.payment_deadline !== undefined}
                    helper_text={helper_text.payment_deadline}
                    text_color_class={`${payment_data.is_payment_deadline_passed?"text-danger":""}`}
                />
            </Col>

            <Hide hide={props.is_full_paid && !can_edit_paid}>
                <Col xs={24} className="d-md-flex align-items-center text-end">
                        <Hide hide={!payment_data.is_payment_deadline_passed}>
                            <label className="text-black fw-bold mb-3 mb-md-0 text-start w-100">
                                Note:
                                                            <span className="ms-2 text-danger">
                                Reminder sent {payment_data.number_of_remainder_sent} time(s)
                                                            </span>
                            </label>
                        </Hide>


                    <div className="ms-auto"/>
                    <Button
                        disabled={is_paid && !can_edit_paid}
                        size="small"
                        onClick={() => setIsEdit(payment_data.id)}>
                        <EditFilled/>
                        Edit
                    </Button>
                </Col>
            </Hide>

            <Hide hide={!isValidValue(helper_text.non_field_errors)}>
                <Col xs={24}>
                    <Alert
                        className="text-danger"
                        banner
                        description={helper_text.non_field_errors}
                        type="error"
                    />
                </Col>
            </Hide>


            <Col xs={24} className="mb-3">
                <Divider className="m-0 border-2"/>
            </Col>
        </Row>
    )


    const body = (
        <Row gutter={[12, 10]} key={payment_data.id}>

            <Col xs={24} md={6}>
                <CustomTextField
                    required
                    label={`Title ${upperCaseView(language)}`}
                    name={`title_${language}`}
                    value={payment_data[`title_${language}`]}
                    onChange={(name, value) => handleFieldChange(`title_${language}`, value, index)}
                    error={helper_text[`title_${language}`] !== undefined}
                    helper_text={helper_text[`title_${language}`]}
                    view_only={(is_paid && !can_edit_paid)}
                />
            </Col>


            <Col xs={24} md={6}>
                <CustomSelectField
                    required
                    label="Payment Method"
                    name="payment_method"
                    value={payment_data.payment_method}
                    onChange={(name, value) => handleFieldChange(name, value, index)}
                    error={helper_text.payment_method !== undefined}
                    helper_text={helper_text.payment_method}
                    options={payment_methods}
                    view_only={(is_paid && !can_edit_paid) || is_agent}
                    view_value_only={(is_paid && !can_edit_paid) || is_agent}
                />
            </Col>


            <Col xs={24} md={6}>
                <CustomTextField
                    required
                    label="Amount"
                    name="amount"
                    value={payment_data.amount}
                    onChange={(name, value) => handleFieldChange(name, value, index)}
                    error={helper_text.amount !== undefined}
                    helper_text={helper_text.amount}
                    type="number"
                    view_only={(is_paid && !can_edit_paid)}
                    addonAfter={payment_data.currency}
                />
            </Col>

            <Col xs={24} md={6}>
                <CustomSelectField
                    required
                    label="Payment Status"
                    name="payment_status"
                    value={payment_data.payment_status}
                    onChange={(name, value) => handleFieldChange(name, value, index)}
                    error={helper_text.payment_status !== undefined}
                    helper_text={helper_text.payment_status}
                    options={payment_status}
                    view_only={(is_paid && !can_edit_paid) || is_agent}
                    view_value_only={(is_paid && !can_edit_paid) || is_agent}
                    inputClassName={is_paid ? "text-success fw-bold" : ""}
                />
            </Col>

            <Col xs={24} md={6}>
                <CustomTextField
                    required
                    label="Payment Ref"
                    name="payment_ref"
                    value={payment_data.payment_ref}
                    onChange={(name, value) => handleFieldChange(name, value, index)}
                    error={helper_text.payment_ref !== undefined}
                    helper_text={helper_text.payment_ref}
                    view_only={(is_paid && !can_edit_paid) || is_agent}
                />
            </Col>

            <Col xs={24} md={6}>
                <CustomTextField
                    label="Payment Provider Ref"
                    name="payment_provider_ref"
                    value={payment_data.payment_provider_ref}
                    onChange={(name, value) => handleFieldChange(name, value, index)}
                    error={helper_text.payment_provider_ref !== undefined}
                    helper_text={helper_text.payment_provider_ref}
                    view_only={(is_paid && !can_edit_paid) || is_agent}
                />
            </Col>


            <Col xs={24} md={6}>
                <CustomDateTimeField
                    required
                    label="Payment Deadline"
                    name="payment_deadline"
                    value={payment_data.payment_deadline}
                    onChange={(name, value) => handleFieldChange(name, value, index)}
                    error={helper_text.payment_deadline !== undefined}
                    helper_text={helper_text.payment_deadline}
                    view_only={(is_paid && !can_edit_paid)}
                />
            </Col>


            <Col xs={24} md={6}>
                <CustomDateTimeField
                    required
                    label="Payment Date"
                    name="payment_date"
                    value={payment_data.payment_date}
                    onChange={(name, value) => handleFieldChange(name, value, index)}
                    error={helper_text.payment_date !== undefined}
                    helper_text={helper_text.payment_date}
                    disabled={(is_paid && !can_edit_paid) || is_agent}
                    view_only={(is_paid && !can_edit_paid) || is_agent}
                />
            </Col>


            <Col xs={24} className="d-flex justify-content-between">
                <Popconfirm
                    placement="bottomRight"
                    title={`Are you sure to Remove this row ??`}
                    onConfirm={() => removePayment(payment_data, index)}
                    okText="Yes"
                    cancelText="No"
                    disabled={is_paid && !can_edit_paid}
                >
                    <Button
                        disabled={is_paid}
                        className="m-1"
                        size="small"
                        danger type="primary">
                        Remove
                    </Button>
                </Popconfirm>

                <Button
                    disabled={is_paid && !can_edit_paid}
                    className="m-1"
                    size="small"
                    onClick={() => setIsEdit(payment_data.id)}>

                    Done
                </Button>

            </Col>


            <Hide hide={!isValidValue(helper_text.non_field_errors)}>
                <Col xs={24}>
                    <Alert
                        className="text-danger"
                        banner
                        description={helper_text.non_field_errors}
                        type="error"
                    />
                </Col>
            </Hide>

            <Col xs={24} className="mb-3">
                <Divider className="m-0 border-2"/>
            </Col>

        </Row>
    )

    let row_content = (
        <>
            <Hide hide={is_edit}>
                {view_body}
            </Hide>

            <Hide hide={!is_edit}>
                {body}
            </Hide>

        </>
    )

    if (!is_edit) {
        // if (payment_data.is_payment_deadline_passed) {
        //     row_content = (
        //         <Badge.Ribbon text="DeadLine Passed"
        //                       color={DANGER_COLOR_2} className="top-0 mt-5">
        //             {row_content}
        //         </Badge.Ribbon>
        //     )
        // }
        // row_content = (
        //     <Badge.Ribbon text={payment_data.payment_status}
        //                   color={payment_data.payment_status === "Pending" ? DISABLED_COLOR : ""} className="top-0">
        //         {row_content}
        //     </Badge.Ribbon>
        // )
    }

    return row_content

}
