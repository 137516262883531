import {Col, Row} from "antd";
import {CustomDynamicTable} from "../../global/components/CustomDynamicTable";
import React, {useState} from "react";
import {ContactUsURL} from "../../global/urls";
import {httpRequestWithAuth} from "../../global/axios_instance";
import {openNotification} from "../../global/helper_functions";
import {permission_data} from "../../global/permissions_data";
import {checkPermission} from "../../global/permissions";
import {AddButton} from "../../global/components/AddButton";
import {ContactUsFormModal} from "./components/ContactUsFormModal";
import Hide from "../../global/components/Hide";
import {CustomStatusView} from "../../global/components/CustomStatusView";

export const ContactUs = props => {
    const [open_from_data, setOpenFormData] = useState(false);
    const [form_data, setFormData] = useState({});
    const [reload, setReload] = useState(false);

    const [contact_us_perm] = useState({
        delete: checkPermission(permission_data.contact_us.delete),
        edit: checkPermission(permission_data.contact_us.edit),
        add: checkPermission(permission_data.contact_us.add),
    });

    const [columns] = useState([
        {
            title: "Ref No.",
            dataIndex: "ref_no",
            width: "10%",
        },
        {
            title: "Purpose",
            dataIndex: "purpose_of_contact",
            width: "20%",
        },
        {
            title: "Subject",
            dataIndex: "subject",
            width: "30%",
        },
        {
            title: "Name",
            dataIndex: "name",
            width: "15%",
        },
        {
            title: "Email",
            dataIndex: "email",
            width: "15%",
        },
        {
            title: "Status",
            dataIndex: "status",
            width: "10%",
            align: "center",
            render: (value)=> <CustomStatusView value={value}/>
        },
    ])


    const handelOpenFormData = (data = {}) => {
        setFormData(data)
        setOpenFormData(true)
    }

    const handelCloseFormData = (reload_data = false) => {
        setFormData({});
        setOpenFormData(false);

        if (reload_data) {
            setReload(Math.random());
        }

    }

    const onDelete = async (row_data) => {
        let response = await httpRequestWithAuth(`${ContactUsURL}${row_data.id}`, "DELETE");
        if (response.status) {
            openNotification("success", "Delete Contact Us", `${row_data.subject} contact us has been deleted`)
        } else {
            openNotification("error", "Delete Contact Us", response.error_feedback.non_field_errors)
        }
    }

    const onEdit = async (row_data) => {
        handelOpenFormData(row_data)
    }

    const onAdd = async () => {
        handelOpenFormData()
    }

    const freeAction = (
        <>
            <AddButton onClick={onAdd}>Add Contact Us</AddButton>
        </>
    );

    return (
        <Row>
            <Hide hide={!open_from_data}>
                <ContactUsFormModal
                    open={open_from_data}
                    data={form_data}
                    handleModelClose={handelCloseFormData}
                />
            </Hide>

            <Col xs={24} className="paper">
                <CustomDynamicTable
                    title="Contact Us"
                    reload_data={reload}
                    columns={columns}
                    fetch_url={ContactUsURL}
                    free_action={freeAction}
                    onDelete={contact_us_perm.delete && onDelete}
                    onEdit={contact_us_perm.edit && onEdit}
                />
            </Col>
        </Row>
    )
}
