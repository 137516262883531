import React, {useEffect, useState} from "react";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {GetSalesRefundURL} from "../../../global/urls";
import {ComponentThree} from "./ComponentThree";
import moment from "moment";
import {checkPermission} from "../../../global/permissions";
import {permission_data} from "../../../global/permissions_data";

const WeekSalesRefund = () => {
    const [data, setData] = useState(0);
    const [has_perm] = useState(checkPermission(permission_data.reports.view_week_refund));

    useEffect(() => {
        const fetchData = async () => {

            if (has_perm) {
                let from_date = moment().weekday(1).add(-1, "days")
                let to_date = moment().weekday(1).add(5, "days")

                from_date = from_date.format("YYYY-MM-DD")
                to_date = to_date.format("YYYY-MM-DD")

                let response = await httpRequestWithAuth(GetSalesRefundURL, "GET", null, {from_date, to_date});
                if (response.status) {
                    setData(response.data);
                }
            }
        }

        fetchData();
    }, [])


    return <ComponentThree title="Week Refund" value={`${data} KWD`}/>

}


export default WeekSalesRefund;
