export const payment_percentage = 3.0;

// Brand Colors
export const PRIMARY_COLOR = "#246F6C";
export const PRIMARY_COLOR_TWO = "#3c3c3b";
export const SECONDARY_1_COLOR = "#EBE6E3";
export const SECONDARY_2_COLOR = "#89A09B";
export const SECONDARY_3_COLOR = "#C9BAB1";
export const SECONDARY_4_COLOR = "#246F6C";
export const SECONDARY_5_COLOR = "#EDEDED";
export const PRIMARY_COLOR_GREY = "rgba(0,0,0,.85)";

// custom Colors
export const DISABLED_COLOR = "#d5d2d2";
export const DANGER_COLOR = "#ff4d4f";
export const DANGER_COLOR_2="#ff7875"
export const WARNING_COLOR="#FFA500"
export const Gold_COLOR = "#FFD700";


export const status_options = [
  {title:"Completed", value: "Complete", className: "main-color fw-bold"},
  {title:"Pending", value: "Pending", className: "text-black-50 fw-bold"},
  {title:"Cancelled", value: "Cancelled", className: "text-danger fw-bold"},
];

export const activation_option = [
  {title:"All", value: "",},
  {title:"Active", value: true, className: "main-color fw-bold"},
  {title:"Inactive", value: false, className: "text-black-50 fw-bold"},
];
export const file_source_type = [
  {title:"Link", value: "Link"},
  {title:"File", value: "File"},
];

export const product_type = [
  {title:"Digital Product", value: "Digital Product"},
  {title:"Affiliate Product", value: "Affiliate Product"},
];

export const booking_status = [
  {title:"Completed", value: "Completed"},
  {title:"Approved", value: "Approved"},
  {title:"Abandoned", value: "Abandoned"},
  {title:"Cancelled", value: "Cancelled"},
  {title:"Payment Pending", value: "Payment Pending"},
  {title:"Pending Approval", value: "Pending Approval"},
];

export const payment_status = [
  {title:"Pending", value: "Pending"},
  {title:"Paid", value: "Paid"},
];

export const payment_type = [
  {title:"Payment", value: "Payment"},
  {title:"Refund", value: "Refund"},
];

export const payment_methods = [
  {title:"Payment Link", value: "Payment Link"},
  {title:"KNET", value: "KNET"},
  {title:"VISA / MASTER", value: "VISA/MASTER"},
  // {title:"AMEX", value: "AMEX"},
  {title:"Sadad", value: "Sadad"},
  // {title:"Benefit", value: "Benefit"},
  // {title:"MADA", value: "MADA"},
  // {title:"UAE Debit Cards", value: "UAE Debit Cards"},
  // {title:"Qatar Debit Cards", value: "Qatar Debit Cards"},
  // {title:"Apple Pay", value: "Apple Pay"},
  // {title:"STC Pay", value: "STC Pay"},
  // {title:"Oman Net", value: "Oman Net"},
  // {title:"Mobile Wallet (Egypt)", value: "Mobile Wallet (Egypt)"},
  // {title:"Meeza", value: "Meeza"},
  {title:"Other", value: "Other"},
];

export const contract_type = [
  {title:"Experience", value: "Experience"},
  {title:"Travel", value: "Travel"},
  {title:"Tour Guide", value: "Tour Guide"},
];


export const package_type = [
  {title:"Travel", label:"Travel", value: "Travel"},
  {title:"Experience", label:"Experience", value: "Experience"},
];

export const consultation_type_options = [
  {title: "Private Trips Inquiries", label: "Private Trips Inquiries", value: "Private Trips Inquiries"},
  {title: "Group Trips Inquiries", label: "Group Trips Inquiries", value: "Group Trips Inquiries"},
  {title: "Kuwait Experiences", label: "Kuwait Experiences", value: "Kuwait Experiences"},
  {title: "Family Trip", label: "Family Trip", value: "Family Trip"},
  {title: "Friends Trip", label: "Friends Trip", value: "Friends Trip"},
  {title: "Solo Trip", label: "Solo Trip", value: "Solo Trip"},
  {title: "others", label: "others", value: "others"},
];


export const privacy_type = [
  {title:"Public", value: false},
  {title:"Private", value: true},
];


export const testimonial_feedback_for_options = [
  {title: "Agency", value: "Agency"},
  {title: "Package", value: "Package"},
  {title: "Consultation", value: "Consultation"},
  {title: "Product", value: "Product"},
]

export const interval_options = [
  {title: "All", value: "All"},
  {title: "Today", value: "Today"},
  {title: "Upcoming", value: "Upcoming"},
  {title: "Past", value: "Past"},
]

export const transferred_options = [
  {title: "All", value: ""},
  {title: "Full Transferred", value: "true"},
  {title: "Uncompleted Transferred", value: "false"},
]

export const physical_activity_options = [
  {title:"High", value: "High"},
  {title:"Moderate", value: "Moderate"},
  {title:"Low", value: "Low"},
];

export const package_status = [
  {title:"Approved", value: "Approved", className: "text-success fw-bold"},
  {title:"Rejected", value: "Rejected", className: "text-danger fw-bold"},
  {title:"Pending", value: "Pending"},
];
export const package_photo_order = [
  {title: 1, value: 1},
  {title: 2, value: 2},
  {title: 3, value: 3},
  {title: 4, value: 4},
  {title: 5, value: 5},
  {title: 6, value: 6},
  {title: 7, value: 7},
  {title: 8, value: 8},
  {title: 9, value: 9},
  {title: 10, value: 10},
];

export const week_days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
];

export const title_options = [
  {title: "Mr.", value: "Mr."},
  {title: "Mrs.", value: "Mrs."},
  {title: "Ms.", value: "Ms."},
  {title: "Dr.", value: "Dr."},
];

export const continents_list = [
  {title: "Asia", value: "Asia"},
  {title: "Africa", value: "Africa"},
  {title: "Europe", value: "Europe"},
  {title: "North America", value: "North America"},
  {title: "South America", value: "South America"},
  {title: "Australia/Oceania", value: "Australia/Oceania"},
  {title: "Antarctica", value: "Antarctica"},
];

export const file_axios_header = {'content-type': "multipart/form-data"};

export const PHONE_CODES = [
  { name: "Afghanistan", code: "AF", phone: "+93" },
  { name: "Aland Islands", code: "AX", phone: "+358" },
  { name: "Albania", code: "AL", phone: "+355" },
  { name: "Algeria", code: "DZ", phone: "+213" },
  { name: "American Samoa", code: "AS", phone: "+1684" },
  { name: "Andorra", code: "AD", phone: "+376" },
  { name: "Angola", code: "AO", phone: "+244" },
  { name: "Anguilla", code: "AI", phone: "+1264" },
  { name: "Antarctica", code: "AQ", phone: "+672" },
  { name: "Antigua and Barbuda", code: "AG", phone: "+1268" },
  { name: "Argentina", code: "AR", phone: "+54" },
  { name: "Armenia", code: "AM", phone: "+374" },
  { name: "Aruba", code: "AW", phone: "+297" },
  { name: "Australia", code: "AU", phone: "+61" },
  { name: "Austria", code: "AT", phone: "+43" },
  { name: "Azerbaijan", code: "AZ", phone: "+994" },
  { name: "Bahamas", code: "BS", phone: "+1242" },
  { name: "Bahrain", code: "BH", phone: "+973" },
  { name: "Bangladesh", code: "BD", phone: "+880" },
  { name: "Barbados", code: "BB", phone: "+1246" },
  { name: "Belarus", code: "BY", phone: "+375" },
  { name: "Belgium", code: "BE", phone: "+32" },
  { name: "Belize", code: "BZ", phone: "+501" },
  { name: "Benin", code: "BJ", phone: "+229" },
  { name: "Bermuda", code: "BM", phone: "+1441" },
  { name: "Bhutan", code: "BT", phone: "+975" },
  { name: "Bolivia", code: "BO", phone: "+591" },
  { name: "Bonaire, Sint Eustatius and Saba", code: "BQ", phone: "+599" },
  { name: "Bosnia and Herzegovina", code: "BA", phone: "+387" },
  { name: "Botswana", code: "BW", phone: "+267" },
  { name: "Bouvet Island", code: "BV", phone: "+55" },
  { name: "Brazil", code: "BR", phone: "+55" },
  { name: "British Indian Ocean Territory", code: "IO", phone: "+246" },
  { name: "Brunei Darussalam", code: "BN", phone: "+673" },
  { name: "Bulgaria", code: "BG", phone: "+359" },
  { name: "Burkina Faso", code: "BF", phone: "+226" },
  { name: "Burundi", code: "BI", phone: "+257" },
  { name: "Cambodia", code: "KH", phone: "+855" },
  { name: "Cameroon", code: "CM", phone: "+237" },
  { name: "Canada", code: "CA", phone: "+1" },
  { name: "Cape Verde", code: "CV", phone: "+238" },
  { name: "Cayman Islands", code: "KY", phone: "+1345" },
  { name: "Central African Republic", code: "CF", phone: "+236" },
  { name: "Chad", code: "TD", phone: "+235" },
  { name: "Chile", code: "CL", phone: "+56" },
  { name: "China", code: "CN", phone: "+86" },
  { name: "Christmas Island", code: "CX", phone: "+61" },
  { name: "Cocos (Keeling) Islands", code: "CC", phone: "+672" },
  { name: "Colombia", code: "CO", phone: "+57" },
  { name: "Comoros", code: "KM", phone: "+269" },
  { name: "Congo", code: "CG", phone: "+242" },
  {
    name: "Congo, Democratic Republic of the Congo",
    code: "CD",
    phone: "+242",
  },
  { name: "Cook Islands", code: "CK", phone: "+682" },
  { name: "Costa Rica", code: "CR", phone: "+506" },
  { name: "Cote D'Ivoire", code: "CI", phone: "+225" },
  { name: "Croatia", code: "HR", phone: "+385" },
  { name: "Cuba", code: "CU", phone: "+53" },
  { name: "Curacao", code: "CW", phone: "+599" },
  { name: "Cyprus", code: "CY", phone: "+357" },
  { name: "Czech Republic", code: "CZ", phone: "+420" },
  { name: "Denmark", code: "DK", phone: "+45" },
  { name: "Djibouti", code: "DJ", phone: "+253" },
  { name: "Dominica", code: "DM", phone: "+1767" },
  { name: "Dominican Republic", code: "DO", phone: "+1809" },
  { name: "Ecuador", code: "EC", phone: "+593" },
  { name: "Egypt", code: "EG", phone: "+20" },
  { name: "El Salvador", code: "SV", phone: "+503" },
  { name: "Equatorial Guinea", code: "GQ", phone: "+240" },
  { name: "Eritrea", code: "ER", phone: "+291" },
  { name: "Estonia", code: "EE", phone: "+372" },
  { name: "Ethiopia", code: "ET", phone: "+251" },
  { name: "Falkland Islands (Malvinas)", code: "FK", phone: "+500" },
  { name: "Faroe Islands", code: "FO", phone: "+298" },
  { name: "Fiji", code: "FJ", phone: "+679" },
  { name: "Finland", code: "FI", phone: "+358" },
  { name: "France", code: "FR", phone: "+33" },
  { name: "French Guiana", code: "GF", phone: "+594" },
  { name: "French Polynesia", code: "PF", phone: "+689" },
  { name: "French Southern Territories", code: "TF", phone: "+262" },
  { name: "Gabon", code: "GA", phone: "+241" },
  { name: "Gambia", code: "GM", phone: "+220" },
  { name: "Georgia", code: "GE", phone: "+995" },
  { name: "Germany", code: "DE", phone: "+49" },
  { name: "Ghana", code: "GH", phone: "+233" },
  { name: "Gibraltar", code: "GI", phone: "+350" },
  { name: "Greece", code: "GR", phone: "+30" },
  { name: "Greenland", code: "GL", phone: "+299" },
  { name: "Grenada", code: "GD", phone: "+1473" },
  { name: "Guadeloupe", code: "GP", phone: "+590" },
  { name: "Guam", code: "GU", phone: "+1671" },
  { name: "Guatemala", code: "GT", phone: "+502" },
  { name: "Guernsey", code: "GG", phone: "+44" },
  { name: "Guinea", code: "GN", phone: "+224" },
  { name: "Guinea-Bissau", code: "GW", phone: "+245" },
  { name: "Guyana", code: "GY", phone: "+592" },
  { name: "Haiti", code: "HT", phone: "+509" },
  { name: "Heard Island and Mcdonald Islands", code: "HM", phone: "+0" },
  { name: "Holy See (Vatican City State)", code: "VA", phone: "+39" },
  { name: "Honduras", code: "HN", phone: "+504" },
  { name: "Hong Kong", code: "HK", phone: "+852" },
  { name: "Hungary", code: "HU", phone: "+36" },
  { name: "Iceland", code: "IS", phone: "+354" },
  { name: "India", code: "IN", phone: "+91" },
  { name: "Indonesia", code: "ID", phone: "+62" },
  { name: "Iran, Islamic Republic of", code: "IR", phone: "+98" },
  { name: "Iraq", code: "IQ", phone: "+964" },
  { name: "Ireland", code: "IE", phone: "+353" },
  { name: "Isle of Man", code: "IM", phone: "+44" },
  { name: "Occupied Palestenian Territory", code: "IL", phone: "+972" },
  { name: "Italy", code: "IT", phone: "+39" },
  { name: "Jamaica", code: "JM", phone: "+1876" },
  { name: "Japan", code: "JP", phone: "+81" },
  { name: "Jersey", code: "JE", phone: "+44" },
  { name: "Jordan", code: "JO", phone: "+962" },
  { name: "Kazakhstan", code: "KZ", phone: "+7" },
  { name: "Kenya", code: "KE", phone: "+254" },
  { name: "Kiribati", code: "KI", phone: "+686" },
  { name: "Korea, Democratic People's Republic of", code: "KP", phone: "+850" },
  { name: "Korea, Republic of", code: "KR", phone: "+82" },
  { name: "Kosovo", code: "XK", phone: "+381" },
  { name: "Kuwait", code: "KW", phone: "+965" },
  { name: "Kyrgyzstan", code: "KG", phone: "+996" },
  { name: "Lao People's Democratic Republic", code: "LA", phone: "+856" },
  { name: "Latvia", code: "LV", phone: "+371" },
  { name: "Lebanon", code: "LB", phone: "+961" },
  { name: "Lesotho", code: "LS", phone: "+266" },
  { name: "Liberia", code: "LR", phone: "+231" },
  { name: "Libyan Arab Jamahiriya", code: "LY", phone: "+218" },
  { name: "Liechtenstein", code: "LI", phone: "+423" },
  { name: "Lithuania", code: "LT", phone: "+370" },
  { name: "Luxembourg", code: "LU", phone: "+352" },
  { name: "Macao", code: "MO", phone: "+853" },
  {
    name: "Macedonia, the Former Yugoslav Republic of",
    code: "MK",
    phone: "+389",
  },
  { name: "Madagascar", code: "MG", phone: "+261" },
  { name: "Malawi", code: "MW", phone: "+265" },
  { name: "Malaysia", code: "MY", phone: "+60" },
  { name: "Maldives", code: "MV", phone: "+960" },
  { name: "Mali", code: "ML", phone: "+223" },
  { name: "Malta", code: "MT", phone: "+356" },
  { name: "Marshall Islands", code: "MH", phone: "+692" },
  { name: "Martinique", code: "MQ", phone: "+596" },
  { name: "Mauritania", code: "MR", phone: "+222" },
  { name: "Mauritius", code: "MU", phone: "+230" },
  { name: "Mayotte", code: "YT", phone: "+269" },
  { name: "Mexico", code: "MX", phone: "+52" },
  { name: "Micronesia, Federated States of", code: "FM", phone: "+691" },
  { name: "Moldova, Republic of", code: "MD", phone: "+373" },
  { name: "Monaco", code: "MC", phone: "+377" },
  { name: "Mongolia", code: "MN", phone: "+976" },
  { name: "Montenegro", code: "ME", phone: "+382" },
  { name: "Montserrat", code: "MS", phone: "+1664" },
  { name: "Morocco", code: "MA", phone: "+212" },
  { name: "Mozambique", code: "MZ", phone: "+258" },
  { name: "Myanmar", code: "MM", phone: "+95" },
  { name: "Namibia", code: "NA", phone: "+264" },
  { name: "Nauru", code: "NR", phone: "+674" },
  { name: "Nepal", code: "NP", phone: "+977" },
  { name: "Netherlands", code: "NL", phone: "+31" },
  { name: "Netherlands Antilles", code: "AN", phone: "+599" },
  { name: "New Caledonia", code: "NC", phone: "+687" },
  { name: "New Zealand", code: "NZ", phone: "+64" },
  { name: "Nicaragua", code: "NI", phone: "+505" },
  { name: "Niger", code: "NE", phone: "+227" },
  { name: "Nigeria", code: "NG", phone: "+234" },
  { name: "Niue", code: "NU", phone: "+683" },
  { name: "Norfolk Island", code: "NF", phone: "+672" },
  { name: "Northern Mariana Islands", code: "MP", phone: "+1670" },
  { name: "Norway", code: "NO", phone: "+47" },
  { name: "Oman", code: "OM", phone: "+968" },
  { name: "Pakistan", code: "PK", phone: "+92" },
  { name: "Palau", code: "PW", phone: "+680" },
  { name: "Palestinian Territory, Occupied", code: "PS", phone: "+970" },
  { name: "Panama", code: "PA", phone: "+507" },
  { name: "Papua New Guinea", code: "PG", phone: "+675" },
  { name: "Paraguay", code: "PY", phone: "+595" },
  { name: "Peru", code: "PE", phone: "+51" },
  { name: "Philippines", code: "PH", phone: "+63" },
  { name: "Pitcairn", code: "PN", phone: "+64" },
  { name: "Poland", code: "PL", phone: "+48" },
  { name: "Portugal", code: "PT", phone: "+351" },
  { name: "Puerto Rico", code: "PR", phone: "+1787" },
  { name: "Qatar", code: "QA", phone: "+974" },
  { name: "Reunion", code: "RE", phone: "+262" },
  { name: "Romania", code: "RO", phone: "+40" },
  { name: "Russian Federation", code: "RU", phone: "+70" },
  { name: "Rwanda", code: "RW", phone: "+250" },
  { name: "Saint Barthelemy", code: "BL", phone: "+590" },
  { name: "Saint Helena", code: "SH", phone: "+290" },
  { name: "Saint Kitts and Nevis", code: "KN", phone: "+1869" },
  { name: "Saint Lucia", code: "LC", phone: "+1758" },
  { name: "Saint Martin", code: "MF", phone: "+590" },
  { name: "Saint Pierre and Miquelon", code: "PM", phone: "+508" },
  { name: "Saint Vincent and the Grenadines", code: "VC", phone: "+1784" },
  { name: "Samoa", code: "WS", phone: "+684" },
  { name: "San Marino", code: "SM", phone: "+378" },
  { name: "Sao Tome and Principe", code: "ST", phone: "+239" },
  { name: "Saudi Arabia", code: "SA", phone: "+966" },
  { name: "Senegal", code: "SN", phone: "+221" },
  { name: "Serbia", code: "RS", phone: "+381" },
  { name: "Serbia and Montenegro", code: "CS", phone: "+381" },
  { name: "Seychelles", code: "SC", phone: "+248" },
  { name: "Sierra Leone", code: "SL", phone: "+232" },
  { name: "Singapore", code: "SG", phone: "+65" },
  { name: "Sint Maarten", code: "SX", phone: "+1" },
  { name: "Slovakia", code: "SK", phone: "+421" },
  { name: "Slovenia", code: "SI", phone: "+386" },
  { name: "Solomon Islands", code: "SB", phone: "+677" },
  { name: "Somalia", code: "SO", phone: "+252" },
  { name: "South Africa", code: "ZA", phone: "+27" },
  {
    name: "South Georgia and the South Sandwich Islands",
    code: "GS",
    phone: "+500",
  },
  { name: "South Sudan", code: "SS", phone: "+211" },
  { name: "Spain", code: "ES", phone: "+34" },
  { name: "Sri Lanka", code: "LK", phone: "+94" },
  { name: "Sudan", code: "SD", phone: "+249" },
  { name: "Suriname", code: "SR", phone: "+597" },
  { name: "Svalbard and Jan Mayen", code: "SJ", phone: "+47" },
  { name: "Swaziland", code: "SZ", phone: "+268" },
  { name: "Sweden", code: "SE", phone: "+46" },
  { name: "Switzerland", code: "CH", phone: "+41" },
  { name: "Syrian Arab Republic", code: "SY", phone: "+963" },
  { name: "Taiwan, Province of China", code: "TW", phone: "+886" },
  { name: "Tajikistan", code: "TJ", phone: "+992" },
  { name: "Tanzania, United Republic of", code: "TZ", phone: "+255" },
  { name: "Thailand", code: "TH", phone: "+66" },
  { name: "Timor-Leste", code: "TL", phone: "+670" },
  { name: "Togo", code: "TG", phone: "+228" },
  { name: "Tokelau", code: "TK", phone: "+690" },
  { name: "Tonga", code: "TO", phone: "+676" },
  { name: "Trinidad and Tobago", code: "TT", phone: "+1868" },
  { name: "Tunisia", code: "TN", phone: "+216" },
  { name: "Turkey", code: "TR", phone: "+90" },
  { name: "Turkmenistan", code: "TM", phone: "+7370" },
  { name: "Turks and Caicos Islands", code: "TC", phone: "+1649" },
  { name: "Tuvalu", code: "TV", phone: "+688" },
  { name: "Uganda", code: "UG", phone: "+256" },
  { name: "Ukraine", code: "UA", phone: "+380" },
  { name: "United Arab Emirates", code: "AE", phone: "+971" },
  { name: "United Kingdom", code: "GB", phone: "+44" },
  { name: "United States", code: "US", phone: "+1" },
  { name: "United States Minor Outlying Islands", code: "UM", phone: "+1" },
  { name: "Uruguay", code: "UY", phone: "+598" },
  { name: "Uzbekistan", code: "UZ", phone: "+998" },
  { name: "Vanuatu", code: "VU", phone: "+678" },
  { name: "Venezuela", code: "VE", phone: "+58" },
  { name: "Viet Nam", code: "VN", phone: "+84" },
  { name: "Virgin Islands, British", code: "VG", phone: "+1284" },
  { name: "Virgin Islands, U.s.", code: "VI", phone: "+1340" },
  { name: "Wallis and Futuna", code: "WF", phone: "+681" },
  { name: "Western Sahara", code: "EH", phone: "+212" },
  { name: "Yemen", code: "YE", phone: "+967" },
  { name: "Zambia", code: "ZM", phone: "+260" },
  { name: "Zimbabwe", code: "ZW", phone: "+263" },
];



export const countries_list = [
  {id: 'Afghanistan', code: 'AF'},
  {id: 'Åland Islands', code: 'AX'},
  {id: 'Albania', code: 'AL'},
  {id: 'Algeria', code: 'DZ'},
  {id: 'American Samoa', code: 'AS'},
  {id: 'AndorrA', code: 'AD'},
  {id: 'Angola', code: 'AO'},
  {id: 'Anguilla', code: 'AI'},
  {id: 'Antarctica', code: 'AQ'},
  {id: 'Antigua and Barbuda', code: 'AG'},
  {id: 'Argentina', code: 'AR'},
  {id: 'Armenia', code: 'AM'},
  {id: 'Aruba', code: 'AW'},
  {id: 'Australia', code: 'AU'},
  {id: 'Austria', code: 'AT'},
  {id: 'Azerbaijan', code: 'AZ'},
  {id: 'Bahamas', code: 'BS'},
  {id: 'Bahrain', code: 'BH'},
  {id: 'Bangladesh', code: 'BD'},
  {id: 'Barbados', code: 'BB'},
  {id: 'Belarus', code: 'BY'},
  {id: 'Belgium', code: 'BE'},
  {id: 'Belize', code: 'BZ'},
  {id: 'Benin', code: 'BJ'},
  {id: 'Bermuda', code: 'BM'},
  {id: 'Bhutan', code: 'BT'},
  {id: 'Bolivia', code: 'BO'},
  {id: 'Bosnia and Herzegovina', code: 'BA'},
  {id: 'Botswana', code: 'BW'},
  {id: 'Bouvet Island', code: 'BV'},
  {id: 'Brazil', code: 'BR'},
  {id: 'British Indian Ocean Territory', code: 'IO'},
  {id: 'Brunei Darussalam', code: 'BN'},
  {id: 'Bulgaria', code: 'BG'},
  {id: 'Burkina Faso', code: 'BF'},
  {id: 'Burundi', code: 'BI'},
  {id: 'Cambodia', code: 'KH'},
  {id: 'Cameroon', code: 'CM'},
  {id: 'Canada', code: 'CA'},
  {id: 'Cape Verde', code: 'CV'},
  {id: 'Cayman Islands', code: 'KY'},
  {id: 'Central African Republic', code: 'CF'},
  {id: 'Chad', code: 'TD'},
  {id: 'Chile', code: 'CL'},
  {id: 'China', code: 'CN'},
  {id: 'Christmas Island', code: 'CX'},
  {id: 'Cocos (Keeling) Islands', code: 'CC'},
  {id: 'Colombia', code: 'CO'},
  {id: 'Comoros', code: 'KM'},
  {id: 'Congo', code: 'CG'},
  {id: 'Congo, The Democratic Republic of the', code: 'CD'},
  {id: 'Cook Islands', code: 'CK'},
  {id: 'Costa Rica', code: 'CR'},
  {id: 'Cote D\'Ivoire', code: 'CI'},
  {id: 'Croatia', code: 'HR'},
  {id: 'Cuba', code: 'CU'},
  {id: 'Cyprus', code: 'CY'},
  {id: 'Czech Republic', code: 'CZ'},
  {id: 'Denmark', code: 'DK'},
  {id: 'Djibouti', code: 'DJ'},
  {id: 'Dominica', code: 'DM'},
  {id: 'Dominican Republic', code: 'DO'},
  {id: 'Ecuador', code: 'EC'},
  {id: 'Egypt', code: 'EG'},
  {id: 'El Salvador', code: 'SV'},
  {id: 'Equatorial Guinea', code: 'GQ'},
  {id: 'Eritrea', code: 'ER'},
  {id: 'Estonia', code: 'EE'},
  {id: 'Ethiopia', code: 'ET'},
  {id: 'Falkland Islands (Malvinas)', code: 'FK'},
  {id: 'Faroe Islands', code: 'FO'},
  {id: 'Fiji', code: 'FJ'},
  {id: 'Finland', code: 'FI'},
  {id: 'France', code: 'FR'},
  {id: 'French Guiana', code: 'GF'},
  {id: 'French Polynesia', code: 'PF'},
  {id: 'French Southern Territories', code: 'TF'},
  {id: 'Gabon', code: 'GA'},
  {id: 'Gambia', code: 'GM'},
  {id: 'Georgia', code: 'GE'},
  {id: 'Germany', code: 'DE'},
  {id: 'Ghana', code: 'GH'},
  {id: 'Gibraltar', code: 'GI'},
  {id: 'Greece', code: 'GR'},
  {id: 'Greenland', code: 'GL'},
  {id: 'Grenada', code: 'GD'},
  {id: 'Guadeloupe', code: 'GP'},
  {id: 'Guam', code: 'GU'},
  {id: 'Guatemala', code: 'GT'},
  {id: 'Guernsey', code: 'GG'},
  {id: 'Guinea', code: 'GN'},
  {id: 'Guinea-Bissau', code: 'GW'},
  {id: 'Guyana', code: 'GY'},
  {id: 'Haiti', code: 'HT'},
  {id: 'Heard Island and Mcdonald Islands', code: 'HM'},
  {id: 'Holy See (Vatican City State)', code: 'VA'},
  {id: 'Honduras', code: 'HN'},
  {id: 'Hong Kong', code: 'HK'},
  {id: 'Hungary', code: 'HU'},
  {id: 'Iceland', code: 'IS'},
  {id: 'India', code: 'IN'},
  {id: 'Indonesia', code: 'ID'},
  {id: 'Iran, Islamic Republic Of', code: 'IR'},
  {id: 'Iraq', code: 'IQ'},
  {id: 'Ireland', code: 'IE'},
  {id: 'Isle of Man', code: 'IM'},
  {id: 'Israel', code: 'IL'},
  {id: 'Italy', code: 'IT'},
  {id: 'Jamaica', code: 'JM'},
  {id: 'Japan', code: 'JP'},
  {id: 'Jersey', code: 'JE'},
  {id: 'Jordan', code: 'JO'},
  {id: 'Kazakhstan', code: 'KZ'},
  {id: 'Kenya', code: 'KE'},
  {id: 'Kiribati', code: 'KI'},
  {id: 'Korea, Democratic People\'S Republic of', code: 'KP'},
  {id: 'Korea, Republic of', code: 'KR'},
  {id: 'Kuwait', code: 'KW'},
  {id: 'Kyrgyzstan', code: 'KG'},
  {id: 'Lao People\'S Democratic Republic', code: 'LA'},
  {id: 'Latvia', code: 'LV'},
  {id: 'Lebanon', code: 'LB'},
  {id: 'Lesotho', code: 'LS'},
  {id: 'Liberia', code: 'LR'},
  {id: 'Libyan Arab Jamahiriya', code: 'LY'},
  {id: 'Liechtenstein', code: 'LI'},
  {id: 'Lithuania', code: 'LT'},
  {id: 'Luxembourg', code: 'LU'},
  {id: 'Macao', code: 'MO'},
  {id: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'},
  {id: 'Madagascar', code: 'MG'},
  {id: 'Malawi', code: 'MW'},
  {id: 'Malaysia', code: 'MY'},
  {id: 'Maldives', code: 'MV'},
  {id: 'Mali', code: 'ML'},
  {id: 'Malta', code: 'MT'},
  {id: 'Marshall Islands', code: 'MH'},
  {id: 'Martinique', code: 'MQ'},
  {id: 'Mauritania', code: 'MR'},
  {id: 'Mauritius', code: 'MU'},
  {id: 'Mayotte', code: 'YT'},
  {id: 'Mexico', code: 'MX'},
  {id: 'Micronesia, Federated States of', code: 'FM'},
  {id: 'Moldova, Republic of', code: 'MD'},
  {id: 'Monaco', code: 'MC'},
  {id: 'Mongolia', code: 'MN'},
  {id: 'Montserrat', code: 'MS'},
  {id: 'Morocco', code: 'MA'},
  {id: 'Mozambique', code: 'MZ'},
  {id: 'Myanmar', code: 'MM'},
  {id: 'Namibia', code: 'NA'},
  {id: 'Nauru', code: 'NR'},
  {id: 'Nepal', code: 'NP'},
  {id: 'Netherlands', code: 'NL'},
  {id: 'Netherlands Antilles', code: 'AN'},
  {id: 'New Caledonia', code: 'NC'},
  {id: 'New Zealand', code: 'NZ'},
  {id: 'Nicaragua', code: 'NI'},
  {id: 'Niger', code: 'NE'},
  {id: 'Nigeria', code: 'NG'},
  {id: 'Niue', code: 'NU'},
  {id: 'Norfolk Island', code: 'NF'},
  {id: 'Northern Mariana Islands', code: 'MP'},
  {id: 'Norway', code: 'NO'},
  {id: 'Oman', code: 'OM'},
  {id: 'Pakistan', code: 'PK'},
  {id: 'Palau', code: 'PW'},
  {id: 'Palestinian Territory, Occupied', code: 'PS'},
  {id: 'Panama', code: 'PA'},
  {id: 'Papua New Guinea', code: 'PG'},
  {id: 'Paraguay', code: 'PY'},
  {id: 'Peru', code: 'PE'},
  {id: 'Philippines', code: 'PH'},
  {id: 'Pitcairn', code: 'PN'},
  {id: 'Poland', code: 'PL'},
  {id: 'Portugal', code: 'PT'},
  {id: 'Puerto Rico', code: 'PR'},
  {id: 'Qatar', code: 'QA'},
  {id: 'Reunion', code: 'RE'},
  {id: 'Romania', code: 'RO'},
  {id: 'Russian Federation', code: 'RU'},
  {id: 'RWANDA', code: 'RW'},
  {id: 'Saint Helena', code: 'SH'},
  {id: 'Saint Kitts and Nevis', code: 'KN'},
  {id: 'Saint Lucia', code: 'LC'},
  {id: 'Saint Pierre and Miquelon', code: 'PM'},
  {id: 'Saint Vincent and the Grenadines', code: 'VC'},
  {id: 'Samoa', code: 'WS'},
  {id: 'San Marino', code: 'SM'},
  {id: 'Sao Tome and Principe', code: 'ST'},
  {id: 'Saudi Arabia', code: 'SA'},
  {id: 'Senegal', code: 'SN'},
  {id: 'Serbia and Montenegro', code: 'CS'},
  {id: 'Seychelles', code: 'SC'},
  {id: 'Sierra Leone', code: 'SL'},
  {id: 'Singapore', code: 'SG'},
  {id: 'Slovakia', code: 'SK'},
  {id: 'Slovenia', code: 'SI'},
  {id: 'Solomon Islands', code: 'SB'},
  {id: 'Somalia', code: 'SO'},
  {id: 'South Africa', code: 'ZA'},
  {id: 'South Georgia and the South Sandwich Islands', code: 'GS'},
  {id: 'Spain', code: 'ES'},
  {id: 'Sri Lanka', code: 'LK'},
  {id: 'Sudan', code: 'SD'},
  {id: 'Suriid', code: 'SR'},
  {id: 'Svalbard and Jan Mayen', code: 'SJ'},
  {id: 'Swaziland', code: 'SZ'},
  {id: 'Sweden', code: 'SE'},
  {id: 'Switzerland', code: 'CH'},
  {id: 'Syrian Arab Republic', code: 'SY'},
  {id: 'Taiwan, Province of China', code: 'TW'},
  {id: 'Tajikistan', code: 'TJ'},
  {id: 'Tanzania, United Republic of', code: 'TZ'},
  {id: 'Thailand', code: 'TH'},
  {id: 'Timor-Leste', code: 'TL'},
  {id: 'Togo', code: 'TG'},
  {id: 'Tokelau', code: 'TK'},
  {id: 'Tonga', code: 'TO'},
  {id: 'Trinidad and Tobago', code: 'TT'},
  {id: 'Tunisia', code: 'TN'},
  {id: 'Turkey', code: 'TR'},
  {id: 'Turkmenistan', code: 'TM'},
  {id: 'Turks and Caicos Islands', code: 'TC'},
  {id: 'Tuvalu', code: 'TV'},
  {id: 'Uganda', code: 'UG'},
  {id: 'Ukraine', code: 'UA'},
  {id: 'United Arab Emirates', code: 'AE'},
  {id: 'United Kingdom', code: 'GB'},
  {id: 'United States', code: 'US'},
  {id: 'United States Minor Outlying Islands', code: 'UM'},
  {id: 'Uruguay', code: 'UY'},
  {id: 'Uzbekistan', code: 'UZ'},
  {id: 'Vanuatu', code: 'VU'},
  {id: 'Venezuela', code: 'VE'},
  {id: 'Viet Nam', code: 'VN'},
  {id: 'Virgin Islands, British', code: 'VG'},
  {id: 'Virgin Islands, U.S.', code: 'VI'},
  {id: 'Wallis and Futuna', code: 'WF'},
  {id: 'Western Sahara', code: 'EH'},
  {id: 'Yemen', code: 'YE'},
  {id: 'Zambia', code: 'ZM'},
  {id: 'Zimbabwe', code: 'ZW'}
]




export const nationalities_list = [
  {
    "id": "Peruvian"
  },
  {
    "id": "Brazilian"
  },
  {
    "id": "Libyan"
  },
  {
    "id": "Sammarinese"
  },
  {
    "id": "Thai"
  },
  {
    "id": "Malaysian"
  },
  {
    "id": "Paraguayan"
  },
  {
    "id": "Namibian"
  },
  {
    "id": "Slovak"
  },
  {
    "id": "Chinese"
  },
  {
    "id": "Honduran"
  },
  {
    "id": "Ugandan"
  },
  {
    "id": "Indonesian"
  },
  {
    "id": "South Georgia and the South Sandwich Islander"
  },
  {
    "id": "Yemeni"
  },
  {
    "id": "Azerbaijani"
  },
  {
    "id": "Israeli"
  },
  {
    "id": "Singaporean"
  },
  {
    "id": "Emirati"
  },
  {
    "id": "Bolivian"
  },
  {
    "id": "Irish"
  },
  {
    "id": "Central African"
  },
  {
    "id": "French Polynesian"
  },
  {
    "id": "Grenadian"
  },
  {
    "id": "Guinea-Bissauan"
  },
  {
    "id": "Nepalese"
  },
  {
    "id": "Panamanian"
  },
  {
    "id": "Burkinabe"
  },
  {
    "id": "Cambodian"
  },
  {
    "id": "Uzbekistani"
  },
  {
    "id": "Anguillian"
  },
  {
    "id": "South Korean"
  },
  {
    "id": "Curaçaoan"
  },
  {
    "id": "Wallis and Futuna Islander"
  },
  {
    "id": "Burmese"
  },
  {
    "id": "Egyptian"
  },
  {
    "id": "Gambian"
  },
  {
    "id": "Saudi Arabian"
  },
  {
    "id": "Argentinean"
  },
  {
    "id": "Canadian"
  },
  {
    "id": "Montenegrin"
  },
  {
    "id": "American"
  },
  {
    "id": "Cook Islander"
  },
  {
    "id": "Macanese"
  },
  {
    "id": "Cocos Islander"
  },
  {
    "id": "Saint Lucian"
  },
  {
    "id": "Cypriot"
  },
  {
    "id": "Icelander"
  },
  {
    "id": "Ivorian"
  },
  {
    "id": "Bangladeshi"
  },
  {
    "id": "Bhutanese"
  },
  {
    "id": "Kittian and Nevisian"
  },
  {
    "id": "Finnish"
  },
  {
    "id": "Macedonian"
  },
  {
    "id": "Qatari"
  },
  {
    "id": "Sudanese"
  },
  {
    "id": "Manx"
  },
  {
    "id": "Latvian"
  },
  {
    "id": "East Timorese"
  },
  {
    "id": "Sao Tomean"
  },
  {
    "id": "Swedish"
  },
  {
    "id": "Martinican"
  },
  {
    "id": "Sierra Leonean"
  },
  {
    "id": "South African"
  },
  {
    "id": "Tanzanian"
  },
  {
    "id": "Costa Rican"
  },
  {
    "id": "Iranian"
  },
  {
    "id": "Dominican"
  },
  {
    "id": "Nigerian"
  },
  {
    "id": "Palestinian"
  },
  {
    "id": "Bosnian, Herzegovinian"
  },
  {
    "id": "Caymanian"
  },
  {
    "id": "Maldivan"
  },
  {
    "id": "Japanese"
  },
  {
    "id": "Chadian"
  },
  {
    "id": "Guinean"
  },
  {
    "id": "Malagasy"
  },
  {
    "id": "Italian"
  },
  {
    "id": "Jordanian"
  },
  {
    "id": "Liechtensteiner"
  },
  {
    "id": "Mongolian"
  },
  {
    "id": "Tongan"
  },
  {
    "id": "Ecuadorean"
  },
  {
    "id": "Guamanian"
  },
  {
    "id": "Kuwaiti"
  },
  {
    "id": "Kirghiz"
  },
  {
    "id": "Mauritian"
  },
  {
    "id": "Ålandish"
  },
  {
    "id": "Bahraini"
  },
  {
    "id": "Mexican"
  },
  {
    "id": "Polish"
  },
  {
    "id": "Chilean"
  },
  {
    "id": "Djibouti"
  },
  {
    "id": "Ethiopian"
  },
  {
    "id": "Mauritanian"
  },
  {
    "id": "North Korean"
  },
  {
    "id": "Swazi"
  },
  {
    "id": "Aruban"
  },
  {
    "id": "Austrian"
  },
  {
    "id": "Haitian"
  },
  {
    "id": "Portuguese"
  },
  {
    "id": "Ni-Vanuatu"
  },
  {
    "id": "Christmas Island"
  },
  {
    "id": "Colombian"
  },
  {
    "id": "Cuban"
  },
  {
    "id": "French Guianese"
  },
  {
    "id": "Afghan"
  },
  {
    "id": "Bruneian"
  },
  {
    "id": "Jamaican"
  },
  {
    "id": "Moldovan"
  },
  {
    "id": "French"
  },
  {
    "id": "Kenyan"
  },
  {
    "id": "Omani"
  },
  {
    "id": "Albanian"
  },
  {
    "id": "Cape Verdian"
  },
  {
    "id": "Turkish"
  },
  {
    "id": "Zimbabwean"
  },
  {
    "id": "Algerian"
  },
  {
    "id": "Nigerien"
  },
  {
    "id": "Hungarian"
  },
  {
    "id": "Spanish"
  },
  {
    "id": "German"
  },
  {
    "id": "Guatemalan"
  },
  {
    "id": "Mozambican"
  },
  {
    "id": "Palauan"
  },
  {
    "id": "Mosotho"
  },
  {
    "id": "Marshallese"
  },
  {
    "id": "Surinamer"
  },
  {
    "id": "Guyanese"
  },
  {
    "id": "Sri Lankan"
  },
  {
    "id": "Lebanese"
  },
  {
    "id": "Mahoran"
  },
  {
    "id": "Samoan"
  },
  {
    "id": "Solomon Islander"
  },
  {
    "id": "Danish"
  },
  {
    "id": "I-Kiribati"
  },
  {
    "id": "Swiss"
  },
  {
    "id": "Lithuanian"
  },
  {
    "id": "Somali"
  },
  {
    "id": "Norfolk Islander"
  },
  {
    "id": "Taiwanese"
  },
  {
    "id": "Ghanaian"
  },
  {
    "id": "New Zealander"
  },
  {
    "id": "Senegalese"
  },
  {
    "id": "Togolese"
  },
  {
    "id": "Zambian"
  },
  {
    "id": "Belarusian"
  },
  {
    "id": "Saint Vincentian"
  },
  {
    "id": "Salvadoran"
  },
  {
    "id": "Norwegian"
  },
  {
    "id": "Maltese"
  },
  {
    "id": "Nicaraguan"
  },
  {
    "id": "Australian"
  },
  {
    "id": "Luxembourger"
  },
  {
    "id": "Pakistani"
  },
  {
    "id": "Saint Helenian"
  },
  {
    "id": "Turks and Caicos Islander"
  },
  {
    "id": "Sahrawi"
  },
  {
    "id": "Belgian"
  },
  {
    "id": "Eritrean"
  },
  {
    "id": "Seychellois"
  },
  {
    "id": "Antiguan, Barbudan"
  },
  {
    "id": "Liberian"
  },
  {
    "id": "Tuvaluan"
  },
  {
    "id": "Bermudian"
  },
  {
    "id": "Cameroonian"
  },
  {
    "id": "Saint-Pierrais"
  },
  {
    "id": "Ukrainian"
  },
  {
    "id": "Georgian"
  },
  {
    "id": "Dutch"
  },
  {
    "id": "Malian"
  },
  {
    "id": "Niuean"
  },
  {
    "id": "Puerto Rican"
  },
  {
    "id": "British"
  },
  {
    "id": "Armenian"
  },
  {
    "id": "Greenlandic"
  },
  {
    "id": "Kazakhstani"
  },
  {
    "id": "Réunionese"
  },
  {
    "id": "Saint Martin Islander"
  },
  {
    "id": "Belizean"
  },
  {
    "id": "Greek"
  },
  {
    "id": "Trinidadian"
  },
  {
    "id": "Beninese"
  },
  {
    "id": "Tadzhik"
  },
  {
    "id": "Czech"
  },
  {
    "id": "Indian"
  },
  {
    "id": "Laotian"
  },
  {
    "id": "Syrian"
  },
  {
    "id": "Turkmen"
  },
  {
    "id": "Motswana"
  },
  {
    "id": "Virgin Islander (British)"
  },
  {
    "id": "American Samoan"
  },
  {
    "id": "Guadeloupian"
  },
  {
    "id": "New Caledonian"
  },
  {
    "id": "Vietnamese"
  },
  {
    "id": "Comoran"
  },
  {
    "id": "Micronesian"
  },
  {
    "id": "Burundian"
  },
  {
    "id": "Moroccan"
  },
  {
    "id": "Saint Barthélemy Islander"
  },
  {
    "id": "Barbadian"
  },
  {
    "id": "Virgin Islander (U.S.)"
  },
  {
    "id": "Iraqi"
  },
  {
    "id": "Papua New Guinean"
  },
  {
    "id": "Equatorial Guinean"
  },
  {
    "id": "Gabonese"
  },
  {
    "id": "Uruguayan"
  },
  {
    "id": "Monegasque"
  },
  {
    "id": "Tunisian"
  },
  {
    "id": "Fijian"
  },
  {
    "id": "Rwandan"
  },
  {
    "id": "Angolan"
  },
  {
    "id": "Falkland Islander"
  },
  {
    "id": "Serbian"
  },
  {
    "id": "Slovenian"
  },
  {
    "id": "Congolese (Democratic Republic)"
  },
  {
    "id": "Malawian"
  },
  {
    "id": "Venezuelan"
  },
  {
    "id": "Bulgarian"
  },
  {
    "id": "Hong Kongese"
  },
  {
    "id": "Gibraltar"
  },
  {
    "id": "South Sudanese"
  },
  {
    "id": "Nauruan"
  },
  {
    "id": "Pitcairn Islander"
  },
  {
    "id": "Russian"
  },
  {
    "id": "Andorran"
  },
  {
    "id": "Faroese"
  },
  {
    "id": "Congolese"
  },
  {
    "id": "Croatian"
  },
  {
    "id": "Montserratian"
  },
  {
    "id": "Filipino"
  },
  {
    "id": "Kosovar"
  },
  {
    "id": "Romanian"
  },
  {
    "id": "Tokelauan"
  },
  {
    "id": "Bahamian"
  },
  {
    "id": "Estonian"
  }
]
