export const BaseBackEndAPIsURL = process.env.REACT_APP_BACKEND_API_URL;

export const UsersURL = "users/";
export const GetActionUserDetailsURL = `${UsersURL}action-user-details/`;
export const UsersGetAllPermissionsURL = `${UsersURL}get-all-permissions/`;
export const UserRestPasswordURL = `${UsersURL}rest-password/`;
export const AdminSignInURL = `${UsersURL}signin/admins/`;
export const AgentSignInURL = `${UsersURL}signin/agents/`;
export const AdminForgetPasswordURL = `${UsersURL}forget-password/admins/`;
export const AgentForgetPasswordURL = `${UsersURL}forget-password/agents/`;
export const VerifyEmailURL = `${UsersURL}verify-email/`;
export const ChangeUserStatusURL = `${UsersURL}change-status/`;

export const RulesURL = `${UsersURL}rules/`;
export const RulesListAllURL = `${RulesURL}all/`;

export const CustomersURL = `customers/`;
export const CustomerListAllURL = `${CustomersURL}list-all/`;
export const GetCustomerInfoURL = `${CustomersURL}get-info/`;
export const CustomerRegistrationURL = `${CustomersURL}registration/`;
export const TiersURL = `${CustomersURL}tiers/`;
export const TiersListAllURL = `${TiersURL}all/`;

export const AgenciesURL = `agencies/`;
export const AgenciesListAllURL = `${AgenciesURL}all/`;
export const AgenciesContractURL = `${AgenciesURL}contracts/`;
export const AgenciesDocumentURL = `${AgenciesURL}documents/`;
export const AgenciesRegistrationAllURL = `${AgenciesURL}registration/`;
export const AgenciesGetBySlugURL = `${AgenciesURL}get-by-slug/`;
export const JoinToAgencyURL = `${AgenciesURL}join/`;

export const CountriesURL = `countries/`;
export const CountriesListAllURL = `${CountriesURL}all/`;
export const CitiesURL = `${CountriesURL}cities/`;
export const CitiesListAllURL = `${CitiesURL}all/`;

export const HEGeneralURL = `he-general/`;
export const UploadFileToServerURL = `${HEGeneralURL}upload-file/`;
export const LanguagesURL = `${HEGeneralURL}languages/`;
export const LanguagesListAllURL = `${LanguagesURL}all/`;
export const AdvertisementsURL = `${HEGeneralURL}advertisements/`;
export const TrustedPartnersURL = `${HEGeneralURL}trusted-partners/`;
export const GlobalDataURL = `${HEGeneralURL}global-data/`;
export const HEMediaURL = `${HEGeneralURL}he-media/`;

export const FinanceURL = `finance/`;
export const ServiceChargesURL = `${FinanceURL}service-charges/`;
export const GetServiceChargeAmountURL = `${ServiceChargesURL}get-amount-for/`;
export const CurrenciesURL = `${FinanceURL}currencies/`;
export const OnlinePaymentsURL = `${FinanceURL}online-payments/`;
export const MyfatoorahPaymentMethodsURL = `${FinanceURL}myfatoorah-payment-methods/`;
export const ConversionRatesURL = `${FinanceURL}conversion-rates/`;

export const ProductsURL = `products/`;
export const ProductsGalleryURL = `${ProductsURL}gallery/`;
export const ProductsListAllURL = `${ProductsURL}list-all/`;
export const ProductsSalesURL = `${ProductsURL}sales/`;
export const ProductsSalesItemsURL = `${ProductsSalesURL}items/`;
export const ProductsSalesSendPaymentReceiptURL = (id) => `${ProductsSalesURL}${id}/send-payment-receipt/`;
export const ProductsSalesSendPaymentRequestURL = (id) => `${ProductsSalesURL}${id}/send-payment-request/`;
export const ProductsSalesItemUpdateLimitURL = (id) => `${ProductsSalesItemsURL}${id}/update-downloads-limit/`;

export const PackagesURL = `packages/`;
export const PackagesListAllURL = `${PackagesURL}list-all-packages/`;
export const PackagesBulkDeleteDatesFromMultipleOptionURL = `${PackagesURL}bulk-delete-dates-from-multiple-option/`;
export const PackagesHasPendingStatusURL = `${PackagesURL}has-pending-status/`;
export const PackagesApprovePendingStatusURL = `${PackagesURL}approve-all-pending-status/`;
export const PackagesListPackagesURL = `${PackagesURL}list-packages/`;
export const PackagesListAgencyPackagesURL = `${PackagesURL}list-agency-packages/`;
export const PackagesCategoriesURL = `${PackagesURL}categories/`;
export const PackagesSettingsURL = `${PackagesURL}settings/`;
export const PackagesSettingsUpdateSoldOutAndActivateStatusURL = `${PackagesSettingsURL}update-sold-out-and-activate-status/`;
export const PackagesCategoriesListAllURL = `${PackagesCategoriesURL}all/`;
export const PackagesInclusionsURL = `${PackagesURL}inclusions/`;
export const PackagesInclusionsListAllURL = `${PackagesInclusionsURL}all/`;
export const PackagesGalleryURL = `${PackagesURL}gallery/`;
export const PackagesPhotoAlbumURL = `${PackagesURL}photo-album/`;
export const PackagesInstallmentURL = `${PackagesURL}installment-rules/`;
export const PackagesPendingChangesURL = `${PackagesURL}pending-changes/`;
export const PackagesRequestChangesApprovalURL = `${PackagesURL}request-changes-approval/`;
export const PackagesPendingChangesForModalURL = `${PackagesURL}pending-changes-for-modal/`;
export const PackagesItineraryURL = `${PackagesURL}itineraries/`;
export const PackagesAddonURL = `${PackagesURL}add-ons/`;
export const ListAddonsForOptionURL = `${PackagesAddonURL}list-active/`;
export const PackagesOptionURL = `${PackagesURL}options/`;
export const PackagesOptionChangeOrderURL = `${PackagesOptionURL}change-order/`;
export const ListPackagesOptionsURL = `${PackagesOptionURL}list-packages-options/`;
export const PackagesOptionDateURL = `${PackagesOptionURL}dates/`;
export const ListPackagesOptionDatesURL = `${PackagesOptionDateURL}list-packages-options-dates/`;
export const PackagesOptionDateTimeURL = `${PackagesOptionDateURL}times/`;
export const ListPackagesOptionDateTimeURL = `${PackagesOptionDateTimeURL}list-packages-options-dates-times/`;
export const PackagesOptionDateTimeBulkAddURL = `${PackagesOptionDateTimeURL}bulk-add/`;
export const PackagesTestimonialsURL = `${PackagesURL}testimonials/`;
export const OtherPackagesTestimonialsURL = `${PackagesTestimonialsURL}other-packages/`;
export const PackagesAddExistsTestimonialsURL = `${PackagesTestimonialsURL}add-exists/`;
export const RemoveTestimonialsFromPackages0URL = `${PackagesTestimonialsURL}remove-from-package/`;
export const PackagesToursURL = `${PackagesURL}tours/`;
export const OtherPackagesToursURL = `${PackagesToursURL}other/`;
export const PackagesAddExistsToursURL = `${PackagesToursURL}add/`;
export const RemoveToursFromPackagesURL = `${PackagesToursURL}remove/`;
export const CustomersBookedPackageURL = `${PackagesURL}customers-booked-package/`;
export const GurusURL = `${PackagesURL}gurus/`;
export const ListAllGurusURL = `${GurusURL}all/`;

export const BookingsURL = `bookings/`;
export const BookingsPOSURL = `${BookingsURL}pos/`;
export const BookingsUpdateCustomerInfoURL = `${BookingsURL}update-customer-info/`;
export const BookingsUpdateServiceChargeStatusURL = `${BookingsURL}update-service-charge-status/`;
export const BookingsUpdateServiceChargePaymentURL = `${BookingsURL}update-service-charge-payment/`;
export const BookingsPaymentsURL = `${BookingsURL}payments/`;
export const BookingsRefundsURL = `${BookingsURL}refunds/`;
export const BookingsPersonsURL = `${BookingsURL}persons/`;
export const BookingsAddonsURL = `${BookingsURL}add-ons/`;
export const BookingSendPaymentReceiptToCustomerByEmailURL = `${BookingsURL}send-payment-receipt-to-customer-by-email/`;
export const BookingSendPaymentRequestToCustomerByEmailURL = `${BookingsURL}send-payment-request-to-customer-by-email/`;
export const BookingSendItemReceiptEmailToAgentURL = `${BookingsURL}send-booking-item-receipt-email-to-agent/`;
export const BookingUpdateStatusURL = `${BookingsURL}update-status/`;
export const BookingUpdateNotificationEmailURL = `${BookingsURL}update-notification-email/`;
export const BookingUpdateUpdateDateTimeURL = `${BookingsURL}update-date-Time/`;
export const BookingUpdateTransferredAmountToAgencyURL = `${BookingsURL}update-transferred-amount-to-agency/`;
export const getBookingCountURL = `${BookingsURL}count/`;

export const VouchersURL = `vouchers/`;
export const CustomizeVoucherURL = `${VouchersURL}/customize-voucher/`;
export const GetVoucherAmountForBookingURL = `${VouchersURL}get-voucher-amount-for-items/`;

export const ConsultationURL = `consultations/`;
export const ConsultationRequestsURL = `${ConsultationURL}requests/`;
export const ConsultationRequestsBulkUpdateStatusURL = `${ConsultationRequestsURL}bulk-update-status/`;
export const ConsultationRequestsListURL = `${ConsultationRequestsURL}list/`;
export const ConsultationResponsesURL = `${ConsultationURL}responses/`;
export const ConsultationResponseSendPaymentRequestByEmailURL = `${ConsultationResponsesURL}send-payment-request-by-email/`;
export const ConsultationResponseSendFeedbackByEmailURL = `${ConsultationResponsesURL}send-feedback-by-email/`;
export const ConsultationsToursURL = `${ConsultationURL}tours/`;
export const OtherConsultationsToursURL = `${ConsultationsToursURL}other/`;
export const ConsultationsAddExistsToursURL = `${ConsultationsToursURL}add/`;
export const RemoveToursFromConsultationsURL = `${ConsultationsToursURL}remove/`;

export const ContactUsURL = `contact-us/`;

export const CareerURL = `career/`;
export const JoinUsRequestsURL = `${CareerURL}join-us-requests/`;

export const ChangeLogURL = `change-logs/`;

export const ReportsURL = `reports/`;
export const GetBookingsNumberURL = `${ReportsURL}bookings-number/`;
export const GetSalesIncomingURL = `${ReportsURL}sales-incoming/`;
export const GetSalesIncomingDetailsURL = `${ReportsURL}sales-incoming-details/`;
export const GetSalesRefundDetailsURL = `${ReportsURL}sales-refund-details/`;
export const GetSalesRefundURL = `${ReportsURL}sales-refund/`;
export const GetAgeScaleURL = `${ReportsURL}age-scale/`;
export const GetCustomersTitleReportURL = `${ReportsURL}customers-title-report/`;
export const GetBookingsStatusReportURL = `${ReportsURL}bookings-status-report/`;
export const GetBookingsNumberOverMonthReportURL = `${ReportsURL}bookings-number-over-months/`;
export const GetBookingsRevenueOverMonthReportURL = `${ReportsURL}bookings-revenue-over-months/`;
export const GetConsultationsRevenueOverMonthReportURL = `${ReportsURL}consultations-revenue-over-months/`;
export const PackagesAnalyticsURL = `${ReportsURL}packages-analytics/`;
export const Top5PackagesURL = `${ReportsURL}top-5-packages/`;
export const BookingCommissionBreakdownURL = `${ReportsURL}booking-commission-breakdown/`;
export const PackageCommissionBreakdownURL = `${ReportsURL}package-commission-breakdown/`;

export const ITSupportURL = `it-support/`;
export const UpdateBookingItemPaymentsAmountRL = `${ITSupportURL}update-booking-item-payments-amount/`;
export const fixBookingNumberRL = `${ITSupportURL}fix-booking-number/`;
export const ITSupportVerifyPasswordRL = `${ITSupportURL}verify-password/`;
export const ITSupportUpdateBookingItemDateTimeURL = `${ITSupportURL}update-booking-item-date-time/`;
export const ITSupportRemoveActivationToFromFuturePackagesURL = `${ITSupportURL}remove-activation-to-from-future-packages/`;
export const ITSupportUpdateAndFixTravelBookingItemDetailsURL = `${ITSupportURL}update-and-fix-travel-booking-item-details/`;
export const ITSupportUpdateAndFixBookingFromItemsURL = `${ITSupportURL}update-and-fix-booking-from-items/`;
export const ITSupportUpdatePackagesDescriptionsURL = `${ITSupportURL}update-packages-descriptions/`;
export const ITSupportUpdatePackagesRemainingParticipantsNumberURL = `${ITSupportURL}update-packages-remaining-participants-number/`;
export const ITSupportUpdateBookingServiceChargePaidDateURL = `${ITSupportURL}update-booking-service-charge-paid-date/`;
export const ITSupportFixPackagesOptionsOrderURL = `${ITSupportURL}fix-packages-options-order/`;
export const ITSupportFixPackagesCustomRibbonURL = `${ITSupportURL}fix-packages-custom-ribbon/`;
export const ITSupportGetIncomingDetailsURL = `${ITSupportURL}get-incoming-details/`;
export const ITSupportFixPackagesNearestDateSortURL = `${ITSupportURL}fix-packages-nearest-date-sort/`;
export const ITSupportFixConsultationsAudienceTypeURL = `${ITSupportURL}fix-consultations-audience-type/`;
export const ITSupportFixPackagesPriceSortURL = `${ITSupportURL}fix-packages-price-sort/`;
export const ITSupportFixPackagesCategoriesOrderURL = `${ITSupportURL}fix-packages-categories-order/`;
export const ITSupportFixProductSalePriceURL = `${ITSupportURL}fix-product-sale-price/`;
export const ITSupportfixHEAgencyCommissionURL = `${ITSupportURL}fix-he-agency-commission/`;
export const ITSupportFillGuruFromPackageSettingsURL = `${ITSupportURL}fill-guru-from-package-settings/`;

export const InvoicesURL = 'invoices/';
export const InvoicesSendPaymentRequestURL = (id) => `${InvoicesURL}${id}/send-payment-request/`;
export const InvoicesSendPaymentReceiptURL = (id) => `${InvoicesURL}${id}/send-payment-receipt/`;
export const InvoicesItemsURL = `${InvoicesURL}items/`;


export const TestimonialsURL = `testimonials/`;
export const BlogsURL = 'blogs/';
export const BlogsGalleryURL = `${BlogsURL}gallery/`;

export const ToursURL = 'tours/';
export const ToursGalleryURL = `${ToursURL}gallery/`;
