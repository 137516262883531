import {useEffect, useState} from "react";
import CustomModal from "../../../../global/components/CustomModal";
import {Avatar, Button, Col, Row, Table} from "antd";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {ListAllGurusURL} from "../../../../global/urls";
import {CustomSearchField} from "../../../../global/components/fields/CustomSearchField";
import {isValidValue} from "../../../../global/helper_functions";


export const SelectGuruModal = props => {

    const [data, setData] = useState([]);
    const [filtered_data, setFilteredData] = useState([]);

    const [loading, setLoading] = useState(false);

    const columns = [
        {dataIndex: "ref_no", title: "Ref No.", width: "10%"},
        {dataIndex: "display_image", title: "Image", width: "20%", render: (value)=><Avatar size={120} src={value}/>},
            {dataIndex: "name_en", title: "Name", width: "25%"},
            {dataIndex: "email", title: "Email", width: "25%"},
            {dataIndex: "id", title: "Action", align: "center", render: (value, row_data)=>(
                    <Button
                        onClick={()=>onSelectGuru(row_data)}
                        type="primary"
                    >
                        Select
                    </Button>
                )},
    ];

    useEffect(()=>{
        const fetchData = async () =>{
            setLoading(true);
            let response = await httpRequestWithAuth(ListAllGurusURL, "GET");

            if (response.status){
                setData(response.data);
                setFilteredData(response.data);
            }

            setLoading(false)
        }

        fetchData();
    },[])

    const onSelectGuru = (guru_data) =>{
        props.onSelect(guru_data);
        props.handleModelClose()
    }

    const onSearchChange = (name, value) =>{
        value = (value || "").toLowerCase();
        let temp_data = data;
        if (isValidValue(value)){
            temp_data = data.filter(option=> {
                const option_value = option.name_en.toLowerCase() + option.name_ar.toLowerCase();
                return option_value.includes(value)

            })
        }

        setFilteredData(temp_data)
    }

    return (
        <CustomModal title="Select Guru" visible={props.open} onCancel={props.handleModelClose} size="md">
            <Row gutter={[12, 10]}>

                <Col xs={24}>
                    <CustomSearchField onChange={onSearchChange}/>
                </Col>

                <Col xs={24}>
                    <Table
                        pagination={{ defaultPageSize: 5}}
                        columns={columns}
                        dataSource={filtered_data}
                        loading={loading}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}
