import {CustomModelDraggable} from "../../../../global/components/CustomModelDraggable";


export const WhatIsPackageGallery = props =>{
    return(
        <CustomModelDraggable
        open={props.open}
        onCancel={props.handleModelClose}
        title="What is Package Gallery?"
        width={600}
        >
            <p>
                - Gallery is  where you can find the photos of your package. <br/><br/>
                - You can specify the order (1, 2, 3, etc.) you want for the images. <br/><br/>
                - You need to add photos for your package, otherwise it will not appear on the website. <br/><br/>
            </p>
        </CustomModelDraggable>
    )
}
