import React, {useState} from 'react'
import {Button, Col, Row} from 'antd'
import {CustomDynamicTable} from '../../../global/components/CustomDynamicTable'
import {PackagesTestimonialsURL, RemoveTestimonialsFromPackages0URL} from '../../../global/urls'
import {AddButton} from '../../../global/components/AddButton'
import Hide from '../../../global/components/Hide'
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {openNotification, showConfirm} from "../../../global/helper_functions";
import {checkPermission} from "../../../global/permissions";
import {permission_data} from "../../../global/permissions_data";
import CustomRate from "../../../global/components/fields/CustomRate";
import PackageTestimonialFormModal from "./components/PackageTestimonialFormModal";
import SelectExistsTestimonialModal from "./components/SelectExistsTestimonialModal";



const PackageTestimonials = (props) => {

    const {package_id} = props;

    const [form_data, setFormData] = useState({});
    const [open_from_data, setOpenFormData] = useState(false);
    const [open_select_exists_testimonial_modal, setOpenSelectExistsTestimonialModal] = useState(false);
    const [reload_data, setReloadData] = useState(false);
    const [remove_loading, setRemoveLoading] = useState("");

    const [testimonial_perm] = useState({
        edit: checkPermission(permission_data.testimonial.edit),
        add: checkPermission(permission_data.testimonial.add),
    });

    const onAdd = async () => {
        handelOpenFormData();
    }

    const onRemove = async (row_data) => {
        let response = await httpRequestWithAuth(`${PackagesTestimonialsURL}${row_data.id}`, "DELETE");
        if (response.status) {
            openNotification("success", "Remove Testimonial", `${row_data.customer_name} testimonial has been removed`)
        } else {
            openNotification("error", "Remove Testimonial", response.error_feedback.non_field_errors)
        }
    }

    const handelOpenFormData = (data = {}) => {
        setFormData(data)
        setOpenFormData(true)
    }

    const handelCloseFormData = (reload_data = false) => {
        setFormData({});
        setOpenFormData(false);
        setOpenSelectExistsTestimonialModal(false);

        if (reload_data) {
            setReloadData(Math.random());
        }

    }


    const freeAction = (
        <>
            <Hide hide={!testimonial_perm.edit}>
                <Button
                    onC onClick={()=>setOpenSelectExistsTestimonialModal(true)}
                    block
                    type="primary"
                    className="mb-1 m-md-1 justify-content-center"
                >
                    Add Existing Testimonial
                </Button>
            </Hide>

            <Hide hide={!testimonial_perm.add}>
                <AddButton onClick={onAdd}>Add New Testimonial</AddButton>
            </Hide>
        </>
    );

    const removeTestimonial = async id =>{
        setRemoveLoading(id);
        let response = await httpRequestWithAuth(RemoveTestimonialsFromPackages0URL, "PATCH", {package_id, testimonial_id: id})
        if (response.status){
            setReloadData(Math.random());
            openNotification("success", "Remove Testimonial", `Testimonial Removed Successfully`);
        }else{
            openNotification("error", "Remove Testimonial", response.error_feedback.non_field_errors);
        }
        setRemoveLoading("");
    };


    const handleRemoveTestimonial = (id) => {
        showConfirm("Remove Testimonial", `Are you sure to remove this testimonial ?`, ()=>removeTestimonial(id));
    }

    const columns = [
        {
            title: "Rating",
            dataIndex: "rating",
            width: "17%",
            render: (value) => <CustomRate view_only value={value}/>
        },
        {
            title: "Customer",
            dataIndex: "customer_name",
            width: "25%",

        },
        {
            title: "FeedBack",
            dataIndex: "feedback",
            width: "50%",
            render: (value) => <div className="pre-line">{value}</div>
        },
        {
            title: "Actions",
            dataIndex: "id",
            width: "15%",
            render: (value) => <Button onClick={()=>handleRemoveTestimonial(value)} loading={remove_loading === value} className="font-size-14" size="small" danger type="primary">Remove</Button>
        },

    ];



    return (
        <Row>

            <Hide hide={!open_select_exists_testimonial_modal}>
                <SelectExistsTestimonialModal
                    open={open_select_exists_testimonial_modal}
                    handleModelClose={handelCloseFormData}
                    package_id={package_id}
                />
            </Hide>

            <Hide hide={!open_from_data}>
                <PackageTestimonialFormModal
                    open={open_from_data}
                    data={form_data}
                    handleModelClose={handelCloseFormData}
                    package_id={package_id}
                />
            </Hide>

            <Col xs={24} className="paper pt-0">
                <CustomDynamicTable
                    title={
                        <label className="text-start">
                            Testimonials
                            <br/>
                            <span className="font-size-13 text-black-50">
                                The total rating will be updated within 3 hours after any modifications.
                            </span>
                        </label>
                    }
                    columns={columns}
                    fetch_url={PackagesTestimonialsURL}
                    free_action={freeAction}
                    reload_data={reload_data}
                    custom_params={{package: package_id}}
                />
            </Col>
        </Row>
    )
}

export default PackageTestimonials
