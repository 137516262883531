import React from "react";
import {Col} from "antd";
import UserActionField from "./fields/UserActionField";
import CustomDateAndTimeView from "./fields/CustomDateAndTimeView";

const ViewActionsDetails = (props) =>{
    const data = props.data || {};

    return(
        <>

            <Col xs={24} md={12}>
                <UserActionField
                    label="Created By"
                    value={data.createdBy}
                />
            </Col>

            <Col xs={24} md={12}>
                <UserActionField
                    label="Last Modified By"
                    value={data.modifiedBy}
                />
            </Col>

            <Col xs={24} md={12}>
                <CustomDateAndTimeView
                    label="Created At"
                    value={data.createdAt}
                />
            </Col>

            <Col xs={24} md={12}>
                <CustomDateAndTimeView
                    label="Last Modified At"
                    value={data.updatedAt}
                />
            </Col>

        </>
    )
}

export default ViewActionsDetails;
