import React, {useState} from "react";
import {Button} from "antd";
import {CustomTextField} from "./CustomTextField";
import {httpRequestWithAuth} from "../../axios_instance";
import {GetCustomerInfoURL} from "../../urls";


export const CustomerField = (props) => {
    const [is_exists, setIsExists] = useState(false);

    const onBlur = async (e) => {
        if (!props.view_only && !props.disabled) {
            await getCustomerInfo();
        }
    };

    const getCustomerInfo = async () => {

        if (props.getCustomerInfo) {

            let response = await httpRequestWithAuth(GetCustomerInfoURL, "GET", null, {search: props.value})

            if (response.status) {
                setIsExists(true);
                props.getCustomerInfo(response.data);
            } else {
                setIsExists(false);
            }
        }

    }


    const checkCustomerButton = (
        <Button type="link" size="small" className="w-100 h-100 border-radius-5-end m-0" onClick={getCustomerInfo}>
            Check
        </Button>
    )

    return (
        <CustomTextField
            {...props}
            onBlur={onBlur}
            addonAfter={checkCustomerButton}
        />
    );
};
