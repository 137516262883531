import React from 'react';
import {useState} from "react";


export const ComponentThree = props => {
    const [data, setData] = useState(0);

    return (
        <div>

            <h2 className="font-size-15 custom-grey-color">
                {props.title}
            </h2>


            <h5 className="ms-2 font-size-16 fw-bolder main-color">
                {props.value}
            </h5>

        </div>
    );
}
