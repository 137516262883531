import {CustomModelDraggable} from "../../../../global/components/CustomModelDraggable";
import CustomCheckBox from "../../../../global/components/fields/CustomCheckBox";


export const HowToAddDatesToOption = props =>{
    return(
        <CustomModelDraggable
            closable
        open={props.open}
        onCancel={props.handleModelClose}
        title="How to Add Dates to Option?"
        width={600}
        >



                <p className="m-0">
                    <b className="font-size-16">Follow these steps:</b><br/><br/>
                    1) Make sure that you have checked the <CustomCheckBox label={<b>Has Specific Date</b>} container_class_name="d-inline-flex" className="ms-1" value={true} view_only/> box below.<br/><br/>
                    2) Click Save. (After saving, a new section will appear below which is called <b>"Option Dates"</b>.) <br/><br/>
                    3) In the <b>Option Dates</b> section, you can click on the <b> Add date </b> button on the right hand side.  <br/><br/>
                    4) Enter the details on the form that appeared.  <br/><br/>
                    5) Click Save. <br/><br/>
                    Yay! A new date has been added. <br/>
                </p><br/>


        </CustomModelDraggable>
    )
}
