import React from "react";
import { Col, Grid, Row, Sidebar } from "rsuite";
import SideNavList from "./SideNavList";
import { Offcanvas } from "react-bootstrap";
import "../layout.css";
import Hide from "../../global/components/Hide";

const CustomSideBar = (props) => {
  const { side_menu_toggle, setSideMenuToggle } = props;

  return (
    <>
      <Grid fluid className="p-0 m-0 text-white side_bar_background">
        <Row className="show-grid h-100">

          <Hide hide_up_xl>
          <Col xs={12}>
            <Offcanvas
              className="side_bar_drawer"
              show={side_menu_toggle}
              onHide={setSideMenuToggle}
              backdrop
              onEscapeKeyDown={setSideMenuToggle}
            >
              <Offcanvas.Header
                closeButton
                closeVariant="white"
              ></Offcanvas.Header>

              <Offcanvas.Body className="p-0 m-0">
                <SideNavList {...props}/>
              </Offcanvas.Body>
            </Offcanvas>
          </Col>
          </Hide>

              <Hide hide_down_xl>
              <Col xs={12} className="h-100">
            <Sidebar
              className="d-flex flex-column p-0 overflow-auto h-100"
              width={260}
              collapsible
            >
              <SideNavList {...props}/>
            </Sidebar>
          </Col>
              </Hide>
        </Row>
      </Grid>
    </>
  );
};

export default CustomSideBar;
