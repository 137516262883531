import React, {useEffect, useState} from "react";
import {checkPermission} from "../../../global/permissions";
import {permission_data} from "../../../global/permissions_data";
import {isValidValue, openNotification, showError} from "../../../global/helper_functions";
import {Alert, Button, Col, Tabs} from "antd";
import Hide from "../../../global/components/Hide";
import {PackageDetails} from "./PackageDetails";
import {CustomTabPane} from "../../../global/components/CustomTabPane";
import {getUserType, isAdmin, isAgent} from "../../../global/auth_functions";
import {PackageGallery} from "./PackageGallery";
import {PackageInstallment} from "./PackageInstallment";
import {PackageOptions} from "./PackageOptions";
import {StaticLanguageTabs} from "../../../global/components/StaticLanguageTabs";
import {PackageItinerary} from "./PackageItinerary";
import {PackagePendingChanges} from "./PackagePendingChanges";
import {PackageSettings} from "./PackageSettings";
import {ChangeLogs} from "../../change_logs/ChangeLogs";
import {PackageGuidelines} from "./components/PackageGuidelines";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {
    PackagesApprovePendingStatusURL,
    PackagesHasPendingStatusURL,
    PackagesRequestChangesApprovalURL,
    PackagesURL
} from "../../../global/urls";
import {PushpinFilled} from "@ant-design/icons";
import CustomBadge from "../../../global/components/CustomBadge";
import {Bookings} from "../../bookings/Bookings";
import {PackageAddon} from "./PackageAddon";
import PackageTestimonials from "./PackageTestimonials";
import {PackagePhotoAlbum} from "./PackagePhotoAlbum";
import PackageTours from "./PackageTours";

const tabs_model_name = {
    2: "PackageGallery",
    3: "PackageOptions",
    4: "PackageInstallmentRules",
    5: "PackageItineraries",
}

export const PackageInfo = props => {
    const slug = props.slug || "";
    const url_params = props.match.params || {};
    const [package_id] = React.useState(url_params.id || 'new');
    const url_query = new URLSearchParams(props.location.search);
    const tab = (url_query.get("tab") || "1") + "";
    const details_tab = (url_query.get("details-tab") || "1") + "";
    const search = (url_query.get("search") || "") + "";
    const [lang_classes, setLangClasses] = useState("");
    const [user_type] = useState(getUserType());
    const is_admin = isAdmin();
    const is_agent = isAgent();
    const [loading, setLoading] = useState(false);
    const [language, setLanguage] = useState("en");
    const [current_tab, setCurrentTab] = useState("1");
    const [is_new, setIsNew] = useState(package_id === "new");
    const [package_data, setPackageData] = useState({});
    const [request_approval, setRequestApproval] = useState(false);
    const [request_loading, setRequestLoading] = useState(false);
    const [has_pending_status, setHasPendingStatus] = useState(false);
    const [approve_pending_status_loading, setApprovePendingStatusLoading] = useState(false);
    const [pin_update_loading, setPinUpdateLoading] = useState(false);
    const is_travel_package = package_data.package_type === "Travel";

    useEffect(() => {
        if ((!isValidValue(url_params.id) && props.location.pathname !== `/${slug}/packages/new`) || (package_id !== "new" && !checkPermission(permission_data.package.view)) || (package_id === "new" && !checkPermission(permission_data.package.add))) {
            onCancel();
        }

        if (tab !== "1"){
            getPackageData();
        }
        handleTabsChange(tab);
    }, []);


    useEffect(() => {
        if (props.scroll_height > 113) {
            setLangClasses("top-0 position-fixed bg-white z-index-100 w-100")
        } else {
            setLangClasses("")
        }
    }, [props.scroll_height])


    const getPackageData = async () => {
        handleLoading(true);

        let response = await httpRequestWithAuth(`${PackagesURL}${package_id}`, "GET");
        if (response.status) {
            setRequestApproval(false);
            await setPackageData(response.data);
        } else {
            showError("Get Package Data", response.error_feedback.non_field_errors);
            onCancel();
        }

        handleLoading(false);
        return response
    }


    const checkHasPendingStatus = async (model_name = "") => {
        let response = await httpRequestWithAuth(PackagesHasPendingStatusURL, "GET", null, {
            package: package_id,
            model_name
        });
        if (response.status) {
            setHasPendingStatus(response.data)
        } else {
            setHasPendingStatus(false)
        }
    }


    const approveAllPendingStatus = async (model_name, onApprove = null, no_notification = false) => {
        setApprovePendingStatusLoading(true);
        let response = await httpRequestWithAuth(PackagesApprovePendingStatusURL, "POST", {
            package: package_id,
            model_name
        });
        if (response.status) {
            if (!no_notification) {
                openNotification("success", "Approve All Pending Status")
            }
            if (onApprove) {
                onApprove()
            }
            setHasPendingStatus(false);
        } else {
            if (!no_notification) {
                openNotification("error", "Approve All Pending Status", response.error_feedback.non_field_errors)
            }
        }
        setApprovePendingStatusLoading(false);
    }

    const onCancel = () => {
        props.history.push(`/${slug}/packages`);
    };

    const handleLoading = (value) => {
        if (value) {
            setTimeout(() => {
                setLoading(false);
            }, 800)
        } else {
            setLoading(true)
        }
    }

    const handleTabsChange = (new_value, extra_params = {}) => {
        handleLoading(true);
        new_value = new_value + "";
        let params_attributes = {tab: new_value, ...extra_params};
        const params = new URLSearchParams(params_attributes);

        props.history.push({
            pathname: props.location.pathname,
            search: params.toString()
        });

        if (isValidValue(tabs_model_name[new_value])) {
            checkHasPendingStatus(tabs_model_name[new_value]);
        }

        setCurrentTab(new_value);
        handleLoading(false);
    };

    const onNext = () => {
        const new_tab = parseInt(current_tab) + 1
        handleTabsChange(new_tab <= 7 ? new_tab : current_tab)
    }

    const onBack = () => {
        const new_tab = parseInt(current_tab) - 1
        handleTabsChange(new_tab >= 1 ? new_tab : current_tab)
    }

    const handleLanguageChange = (new_value) => {
        handleLoading(true);
        setLanguage(new_value);
        handleLoading(false);
    };


    const onRequestChangesApproved = async () => {
        setRequestLoading(true)
        const response = await httpRequestWithAuth(`${PackagesRequestChangesApprovalURL}${package_id}`, "POST");
        if (response.status) {
            setRequestApproval(true);
            openNotification("success", "Request Changes Approved", `Request has been sent`);
        } else {
            openNotification("error", "Request Changes Approved", response.error_feedback.non_field_errors);
        }
        setRequestLoading(false)
    }

    const handelPinAction = async () => {
        setPinUpdateLoading(true)
        const response = await httpRequestWithAuth(`${PackagesURL}${package_id}`, "PATCH", {is_pinned: !package_data.is_pinned});
        if (response.status) {
            openNotification("success", package_data.is_pinned ? "Unpin The Package" : "Pin The Package", `package has been ${package_data.is_pinned ? "unpinned" : "pinned"}`);
            getPackageData();
        } else {
            openNotification("error", package_data.is_pinned ? "Unpin The Package" : "Pin The Package", response.error_feedback.non_field_errors);
        }
        setPinUpdateLoading(false)
    }

    return (
        <>


            <div className="m-0 p-0 d-md-flex justify-content-between">
                <h5 className="mb-3 mt-2">{package_id === "new" ? "Add Package" : (<>Edit Package > <span
                    className="text-black-50">{package_data.title_en}</span></>)}</h5>

                <PackageGuidelines is_new={is_new} tab={tab}/>
            </div>


            <div className="paper pt-0 w-100">
                <StaticLanguageTabs value={language} onChange={handleLanguageChange} disabled_ar={is_new}/>

                <Hide hide={!isValidValue(lang_classes)}>
                    <StaticLanguageTabs className={lang_classes} value={language} onChange={handleLanguageChange}
                                        disabled_ar={is_new}/>
                </Hide>

                <Hide hide={is_new}>
                    <Alert
                        description="Important Note: Fill both English & Arabic Details to reach more customers."
                        type="info"
                        showIcon
                        closable
                    />

                    <div className="w-100 mt-3 mb-2 d-sm-flex justify-content-between align-items-center">

                            <div className="mb-3 text-center text-sm-start">
                                <CustomBadge
                                    className="w-100"
                                    title={<h6
                                        className="font-size-13 text-center w-100 text-white p-0 m-0 fw-bold">Participants
                                        Number: <label
                                            className="ms-1 fw-bolder">{package_data.current_participants_number || 0} of {package_data.total_number_of_participants}</label>
                                    </h6>}
                                />
                            </div>

                        <div className="text-end ms-auto">
                            <Hide hide={!is_agent || !package_data.has_pending_changes}>
                                <Button className="custom-grey-bg fw-bold text-white" loading={request_loading}
                                        disabled={request_approval}
                                        onClick={onRequestChangesApproved}>
                                    Request Changes Approved
                                </Button>
                            </Hide>

                            <Button className="ms-3" type="primary" as="a" href={package_data.customer_website_url}
                                    target="_blank">
                                View In Website
                            </Button>


                            <Hide hide={is_agent}>
                                <Button className="ms-3" type={package_data.is_pinned ? "danger" : "primary"}
                                        loading={pin_update_loading} onClick={handelPinAction}>
                                    <PushpinFilled/>
                                    {package_data.is_pinned ? "Unpin" : "Pin"}
                                </Button>
                            </Hide>
                        </div>
                    </div>

                </Hide>

                <Tabs activeKey={current_tab} onChange={handleTabsChange}>
                    <CustomTabPane tab="Details" key="1"/>
                    <CustomTabPane tab="Gallery" key="2"
                                   disabled={is_new || !checkPermission(permission_data.package_gallery.view)}/>
                    <CustomTabPane tab="Options" key="3"
                                   disabled={is_new || !checkPermission(permission_data.package_option.view)}/>
                    <CustomTabPane tab="Add-ons" key="4"
                                   disabled={is_new || !checkPermission(permission_data.package_addon.view)}/>
                    <CustomTabPane tab="Installment Rules" key="5"
                                   disabled={is_new || !checkPermission(permission_data.package_installment.view)}/>

                    {(!is_new && is_travel_package) && (
                        <CustomTabPane tab="Itinerary" key="6"
                                       disabled={is_new || !checkPermission(permission_data.package_itinerary.view)}/>
                    )}
                    <CustomTabPane tab="Setting" key="7"
                                   disabled={is_new || !checkPermission(permission_data.package_setting.view)}/>

                    <CustomTabPane tab="Pending Changes" key="8"
                                   disabled={is_new || !checkPermission(permission_data.package_pending_change.view)}/>
                    <CustomTabPane tab="Bookings" key="9"
                                   disabled={is_new || !checkPermission(permission_data.booking.view)}/>
                    {is_admin && (
                        <CustomTabPane tab="Testimonials" key="10"
                                       disabled={is_new || !checkPermission(permission_data.testimonial.view)}/>
                    )}
                    {(!is_new && is_travel_package) && (
                        <CustomTabPane tab="Photo Album" key="11"
                                       disabled={is_new || !checkPermission(permission_data.package_photo_album.view)}/>
                    )}

                    {is_admin && (
                        <CustomTabPane tab="Tours" key="12"
                                       disabled={is_new || !checkPermission(permission_data.tour.view)}/>
                    )}
                    {is_admin && (
                        <CustomTabPane tab="Change Logs" key="13"
                                       disabled={is_new || !checkPermission(permission_data.change_log.view)}/>
                    )}
                </Tabs>
            </div>
            {/*<Hide hide={!loading}>*/}
            {/*    <CustomModal body_style={{background: 'none'}} visible={loading} centered closable={false}>*/}
            {/*    <HELoading/>*/}
            {/*    </CustomModal>*/}
            {/*</Hide>*/}

            <Hide hide={current_tab !== '1'}>
                <PackageDetails
                    {...props}
                    current_tab={current_tab}
                    details_tab={details_tab}
                    is_new={is_new}
                    user_type={user_type}
                    is_admin={is_admin}
                    language={language}
                    package_id={package_id}
                    handleTabsChange={handleTabsChange}
                    getPackageData={getPackageData}
                />
            </Hide>

            <Hide hide={current_tab !== '2'}>
                <PackageGallery
                    {...props}
                    onNext={onNext}
                    onBack={onBack}
                    user_type={user_type}
                    is_admin={is_admin}
                    language={language}
                    package_id={package_id}
                    handleTabsChange={handleTabsChange}
                    getPackageData={getPackageData}
                    checkHasPendingStatus={checkHasPendingStatus}
                    approveAllPendingStatus={approveAllPendingStatus}
                    has_pending_status={has_pending_status}
                    approve_pending_status_loading={approve_pending_status_loading}
                />
            </Hide>

            <Hide hide={current_tab !== '3'}>
                <PackageOptions
                    {...props}
                    onNext={onNext}
                    onBack={onBack}
                    package_data={package_data}
                    user_type={user_type}
                    is_admin={is_admin}
                    language={language}
                    package_id={package_id}
                    handleTabsChange={handleTabsChange}
                    getPackageData={getPackageData}
                    checkHasPendingStatus={checkHasPendingStatus}
                    approveAllPendingStatus={approveAllPendingStatus}
                    has_pending_status={has_pending_status}
                    approve_pending_status_loading={approve_pending_status_loading}
                />
            </Hide>

            <Hide hide={current_tab !== '4'}>
                <PackageAddon
                    {...props}
                    onNext={onNext}
                    onBack={onBack}
                    package_data={package_data}
                    user_type={user_type}
                    is_admin={is_admin}
                    language={language}
                    package_id={package_id}
                    handleTabsChange={handleTabsChange}
                    getPackageData={getPackageData}
                    checkHasPendingStatus={checkHasPendingStatus}
                    approveAllPendingStatus={approveAllPendingStatus}
                    has_pending_status={has_pending_status}
                    approve_pending_status_loading={approve_pending_status_loading}
                />
            </Hide>

            <Hide hide={current_tab !== '5'}>
                <PackageInstallment
                    {...props}
                    onNext={onNext}
                    onBack={onBack}
                    package_data={package_data}
                    user_type={user_type}
                    is_admin={is_admin}
                    language={language}
                    package_id={package_id}
                    handleTabsChange={handleTabsChange}
                    getPackageData={getPackageData}
                    checkHasPendingStatus={checkHasPendingStatus}
                    approveAllPendingStatus={approveAllPendingStatus}
                    has_pending_status={has_pending_status}
                    approve_pending_status_loading={approve_pending_status_loading}
                />
            </Hide>

            <Hide hide={current_tab !== '6'}>
                <PackageItinerary
                    {...props}
                    onNext={onNext}
                    onBack={onBack}
                    package_data={package_data}
                    user_type={user_type}
                    is_admin={is_admin}
                    language={language}
                    package_id={package_id}
                    handleTabsChange={handleTabsChange}
                    getPackageData={getPackageData}
                    checkHasPendingStatus={checkHasPendingStatus}
                    approveAllPendingStatus={approveAllPendingStatus}
                    has_pending_status={has_pending_status}
                    approve_pending_status_loading={approve_pending_status_loading}
                />
            </Hide>

            <Hide hide={current_tab !== '7'}>
                <PackageSettings
                    {...props}
                    onNext={onNext}
                    onBack={onBack}
                    package_data={package_data}
                    user_type={user_type}
                    is_admin={is_admin}
                    language={language}
                    package_id={package_id}
                    setPackageData={setPackageData}
                    getPackageData={getPackageData}
                    checkHasPendingStatus={checkHasPendingStatus}
                    approveAllPendingStatus={approveAllPendingStatus}
                    has_pending_status={has_pending_status}
                    approve_pending_status_loading={approve_pending_status_loading}
                />
            </Hide>

            <Hide hide={current_tab !== '8'}>
                <PackagePendingChanges
                    {...props}
                    package_data={package_data}
                    user_type={user_type}
                    is_admin={is_admin}
                    language={language}
                    package_id={package_id}
                    search={search}
                    getPackageData={getPackageData}
                    checkHasPendingStatus={checkHasPendingStatus}
                    approveAllPendingStatus={approveAllPendingStatus}
                    has_pending_status={has_pending_status}
                    approve_pending_status_loading={approve_pending_status_loading}
                />
            </Hide>

            <Hide hide={current_tab !== '9'}>
                <Col xs={24} className="paper pt-0">
                <Bookings
                    {...props}
                    tab_view
                    package_data={package_data}
                    user_type={user_type}
                    is_admin={is_admin}
                    language={language}
                    package_id={package_id}
                />
                </Col>
            </Hide>

            <Hide hide={current_tab !== '10'}>
                <PackageTestimonials
                    {...props}
                    package_data={package_data}
                    user_type={user_type}
                    is_admin={is_admin}
                    language={language}
                    package_id={package_id}
                />
            </Hide>

            <Hide hide={current_tab !== '11'}>
                <PackagePhotoAlbum
                    {...props}
                    onNext={onNext}
                    onBack={onBack}
                    package_data={package_data}
                    user_type={user_type}
                    is_admin={is_admin}
                    language={language}
                    package_id={package_id}
                    handleTabsChange={handleTabsChange}
                    getPackageData={getPackageData}
                    checkHasPendingStatus={checkHasPendingStatus}
                    approveAllPendingStatus={approveAllPendingStatus}
                    has_pending_status={has_pending_status}
                    approve_pending_status_loading={approve_pending_status_loading}
                />
            </Hide>

            <Hide hide={current_tab !== '12'}>
                <PackageTours
                    {...props}
                    package_data={package_data}
                    user_type={user_type}
                    is_admin={is_admin}
                    language={language}
                    package_id={package_id}
                />
            </Hide>

            <Hide hide={current_tab !== '13'}>
                <ChangeLogs
                    {...props}
                    parent_id={package_id}
                />
            </Hide>

        </>)
}
