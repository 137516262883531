import React, {useState} from "react";
import {Button, Col, Row} from "antd";
import {ViewInfo} from "../../../global/components/ViewInfo";
import CustomBadge from "../../../global/components/CustomBadge";
import {openNotification, showConfirm, viewDateAndTime} from "../../../global/helper_functions";
import Hide from "../../../global/components/Hide";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {BookingUpdateStatusURL} from "../../../global/urls";
import {checkPermission} from "../../../global/permissions";
import {permission_data} from "../../../global/permissions_data";
import {Loading} from "../../../global/components/Loading";

const BookingRowMobileView = (props) => {
    const {slug, tab_view, package_id, data, reloadData, view_summary} = props;
    const [loading, setLoading] = useState(false);
    const [booking_perm] = useState({
        add: checkPermission(permission_data.booking.add),
        edit: checkPermission(permission_data.booking.edit),
        delete: checkPermission(permission_data.booking.delete),
        send_payment_request: checkPermission(permission_data.booking.send_payment_request),
        send_payment_receipt: checkPermission(permission_data.booking.send_payment_receipt),
    });


    const onView = async () => {
        props.history.push(`/${slug}/bookings/${data.id}`);
    }


    const onChangeStatus = async (status, remove_refund = false) => {
        const booking_item = data.id;
        setLoading(true);

        let response = await httpRequestWithAuth(`${BookingUpdateStatusURL}${booking_item}`, "PATCH", {
            status,
            booking_item,
            remove_refund
        })
        if (response.status) {
            openNotification("success", "Change Booking Status", `Boobking status has been updated`)
            reloadData();
        } else {
            if (response.error_feedback.need_to_remove_refund) {
                showConfirm("Update Booking Status", "To change from Cancelled to Any other status, you have to delete the Refund first. By clicking on OK, refund will be deleted and status updated.", () => onChangeStatus(status, true));
            }
        }

        setLoading(false);
    }


    return (<Row className={view_summary?"mt-1 mb-1":"mt-3 mb-3"}>
            <Hide hide={!loading}>
                <Loading/>
            </Hide>
            <Hide hide={loading}>
                <Col xs={24} className="text-dots-two-line">
                    <h6 className="fw-bold">
                        <a href={`/${slug}/bookings/${data.id}`} target="_blank">
                            {data.title_en}
                        </a>
                    </h6>
                </Col>

                <Col xs={15}>
                    <ViewInfo
                        title="Customer"
                        value={data.customer_name}
                    />
                </Col>

                <Hide hide={view_summary}>
                <Col xs={9}>
                    <ViewInfo
                        title="Status"
                        value={<CustomBadge
                            type={data.status !== "Approved" ? data.status === "Cancelled" ? "danger" : "disabled" : ""}
                            title={data.status}/>}
                    />
                </Col>

                <Col xs={15}>
                    <ViewInfo
                        title="Ref No."
                        value={`#${data.ref_no}`}
                    />
                </Col>


                <Col xs={9}>
                    <ViewInfo
                        title="Type"
                        value={data.booking_type}
                    />
                </Col>

                <Col xs={15}>
                    <ViewInfo
                        title="Date Booked"
                        value={viewDateAndTime(data.createdAt)}
                    />
                </Col>
                </Hide>

                <Col xs={9}>
                    <ViewInfo
                        title="Qty"
                        value={data.qty}
                    />
                </Col>

                <Hide hide={view_summary}>
                    <Col xs={15}>
                        <ViewInfo
                            title="Paid Amount"
                            value={parseFloat(data.paid_amount) + ""}
                        />
                    </Col>


                    <Col xs={9}>
                        <ViewInfo
                            title="Unpaid Amount"
                            value={parseFloat(data.unpaid_amount) + ""}
                        />
                    </Col>
                </Hide>


                <Hide hide={data.status !== "Pending Approval" || !booking_perm.edit}>
                    <Col xs={12} className="pe-1">
                        <Button onClick={() => onChangeStatus('Cancelled')} className="fw-bold font-size-12"
                                size={view_summary?"small":"middle"} type="primary" danger block> Cancel</Button>
                    </Col>

                    <Col xs={12} className="ps-1">
                        <Button onClick={() => onChangeStatus('Approved')} className="fw-bold font-size-12"
                                size={view_summary?"small":"middle"} type="primary" block> Approve</Button>
                    </Col>
                </Hide>

                <Col xs={24} className="pt-2">
                    <Button onClick={onView} className="fw-bold border-2 main-color-border font-size-12" size={view_summary?"small":"middle"}
                            block> View Details</Button>
                </Col>
            </Hide>

        </Row>)
}


export default BookingRowMobileView;
