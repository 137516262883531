import DynamicAutocomplete from "./DynamicAutocomplete";
import {CustomerListAllURL} from "../../urls";

export const CustomerAutoComplete = (props)=>{
    const fetch_all = props.fetch_all || false;

    return(
        <DynamicAutocomplete
            {...props}
            fetch_url={CustomerListAllURL}
            title_key={["full_name", "email"]}
            fetch_in_search={!fetch_all}
            mode={props.multiple ? "multiple":""}
            params_array={[fetch_all]}
            params={{fetch_all}}
        />
    )
}
