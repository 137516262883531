import {useState} from "react";
import CustomModal from "../../../../global/components/CustomModal";
import {Col, Row} from "antd";
import {CustomTextField} from "../../../../global/components/fields/CustomTextField";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {TiersURL} from "../../../../global/urls";
import {isValidValue, openNotification} from "../../../../global/helper_functions";
import {ModelActionButton} from "../../../../global/components/ModelActionButton";
import CustomCheckBox from "../../../../global/components/fields/CustomCheckBox";
import {ImageUploaderWithView} from "../../../../global/components/ImageUploaderWithView";



export const TierFormModal = props => {
    const [data, setData] = useState({
        ...props.data
    });
    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});

    const handleFieldChange = (name, value) => {

        let temp_data = {...data};
        temp_data[name] = value;

        if (name === "badge_image") {
            temp_data[`display_${name}`] = isValidValue((value || {}).size)?URL.createObjectURL(value):null;
        }

        setData(temp_data);
    }

    const onSave = async () => {
        await setLoading(true);


        const form_data = new FormData();

        Object.keys(data).map(Name=>{
            form_data.append(Name, data[Name]);
        });

        const is_new = !isValidValue(data.id);
        let response;

        if (is_new) {
            response = await httpRequestWithAuth(TiersURL, "POST", form_data);
        } else {
            response = await httpRequestWithAuth(`${TiersURL}${data.id}`, "PUT", form_data);
        }

        if (response.status) {
            if (is_new) {
                openNotification("success", "Add Tier", `${data.title_en} tier has been added`)
            } else {
                openNotification("success", "Update Tier", `${data.title_en} tier has been updated`)
            }
            props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
        }

        await setLoading(false);

    }

    return (
        <CustomModal title="Tier Form" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>



                <Col xs={24}>
                    <ImageUploaderWithView
                        max_size_3_mb
                        display_image={data.display_badge_image}
                        label="Badge Image"
                        name="badge_image"
                        value={data.badge_image}
                        onChange={handleFieldChange}
                        error={helper_text.badge_image !== undefined}
                        helper_text={helper_text.badge_image}
                    />
                </Col>

                <Col xs={24} md={10}>
                    <CustomTextField
                        label="Title EN"
                        name="title_en"
                        value={data.title_en}
                        onChange={handleFieldChange}
                        error={helper_text.title_en !== undefined}
                        helper_text={helper_text.title_en}
                    />
                </Col>


                <Col xs={24} md={10}>
                    <CustomTextField
                        label="Title Ar"
                        name="title_ar"
                        value={data.title_ar}
                        onChange={handleFieldChange}
                        error={helper_text.title_ar !== undefined}
                        helper_text={helper_text.title_ar}
                    />
                </Col>

                <Col xs={24} md={4}>
                    <CustomTextField
                        label="To Rank"
                        name="to_rank"
                        value={data.to_rank}
                        onChange={handleFieldChange}
                        error={helper_text.to_rank !== undefined}
                        helper_text={helper_text.to_rank}
                        type="number"
                    />
                </Col>




                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}
