import { Button, Modal } from 'antd';
import React, { useRef, useState } from 'react';
import Draggable from 'react-draggable';

export const CustomModelDraggable = (props) => {
    const [bounds, setBounds] = useState({
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
    });
    const draggleRef = useRef(null);




    const onStart = (_event, uiData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();

        if (!targetRect) {
            return;
        }

        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };

    return (
        <>
            <Modal
                {...props}
                width={props.width}
                title={props.title && (<h2 className="p-0 m-0 font-size-18">{props.title}</h2>)}
                visible={props.open}
                footer={props.footer || null}
                closable={props.closable}
                maskClosable={props.maskClosable || true}
                keyboard={props.keyboard || false}
                destroyOnClose={props.destroyOnClose}
                className={`p-0 ${props.className}`}
                bodyStyle={{
                    background: "white",
                    paddingTop:props.paddingTop || "1rem",
                    paddingRight:"1.5",
                    paddingBottom:"0.5rem",
                    paddingLeft: "1.5rem",
                    ...(props.body_style || {})
                }}
                centered={props.centered}
                transitionName="none"
                modalRender={(modal) => (
                    <Draggable
                        bounds={bounds}
                        onStart={(event, uiData) => onStart(event, uiData)}
                    >
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
            >
                {props.children}
            </Modal>
        </>
    );
};

