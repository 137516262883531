import React, {useEffect, useState} from "react";
import {Route} from "react-router-dom";
import {isAuthenticated, logout} from "../global/auth_functions";
import {Loading} from "../global/components/Loading";
import {checkAllPermission} from "../global/permissions";

const ProtectedRoute = ({component: Component, ...rest}) => {
    const [is_loading, setIsLoading] = useState(true);
    const slug = rest.slug;

    useEffect(() => {
        const checkAuth= async () =>{
            const pathname = rest.location.pathname;

            const return_path = pathname.replace(`/${slug}/`,"");
            let is_auth = await isAuthenticated();
            if (!is_auth || (rest.permissions !== true && !checkAllPermission(rest.permissions))){
                logout();
                rest.history.push(`/${slug}/signin?returnPath=${return_path}`);
            }else{
                await setIsLoading(false);
            }
        }

        checkAuth();
    }, [])

    useEffect(() => {
        document.title = `${rest.title}`;
    }, [rest.location.pathname, rest.location.state])


    if (is_loading){
        return <Loading/>
    }

    return (
        <Route {...rest} render={(props) => <Component {...rest} {...props}/>}/>
    )
};

export default ProtectedRoute;
