import {Col, Row, Tabs, Tooltip} from "antd";
import {CustomDynamicTable} from "../../../global/components/CustomDynamicTable";
import React, {useState} from "react";
import {PackagesPendingChangesURL} from "../../../global/urls";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {openNotification} from "../../../global/helper_functions";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import moment from "moment";
import {PackagePendingChangesDetails} from "./components/PackagePendingChangesDetails";
import {AddButton} from "../../../global/components/AddButton";
import {CustomTabPane} from "../../../global/components/CustomTabPane";
import {HELoading} from "../../../global/components/HELoading";
import Hide from "../../../global/components/Hide";
import {PRIMARY_COLOR} from "../../../global/variables";
import {InfoIconGreenFilled} from "../../../global/components/Icons";

export const PackagePendingChanges = props =>{
    const package_id = props.package_id || "";
    const is_admin = (props.user_type || "") === "Admin";
    const [status, setStatus] = useState("Pending");
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);

    const [package_pending_change_perm] = useState({
        delete: checkPermission(permission_data.package_pending_change.delete),
        edit: checkPermission(permission_data.package_pending_change.edit),
        add: checkPermission(permission_data.package_pending_change.add),
    });



    const [columns] = useState([
        {
            title: "Ref No.",
            dataIndex: `ref_no`,
            width: "15%"
        },
        {
            title: "For",
            dataIndex: `model_name_view`,
            width: "70%"
        },
        {
            title: "Created At",
            dataIndex: `createdAt`,
            width: "15%",
            render:(value)=>moment(value).format("DD-MM-YYYY hh:mm A")
        },
    ]);

    const reloadData = ()=>{
        setReload(Math.random);
    }

    const onStatusChange = (new_value) =>{
        setLoading(true);
        setStatus(new_value);
        reloadData()
        setTimeout(()=>{
            setLoading(false)
        },200)
}

    const onRejected = async (row_data) =>{
        let response = await httpRequestWithAuth(PackagesPendingChangesURL, "PUT", {ref_no: row_data.ref_no, status: "Rejected"});

        if (response.status){
            openNotification("success", "Reject Pending Changes", `${row_data.ref_no} - ${row_data.model_name_view} changes has been rejected`)
        }else{
            openNotification("error", "Reject Pending Changes", response.error_feedback.non_field_errors)
        }

        reloadData()
    }



    const onApproved = async (row_data) =>{
        let response = await httpRequestWithAuth(PackagesPendingChangesURL, "PUT", {ref_no: row_data.ref_no, status: "Approved"});

        if (response.status){
            openNotification("success", "Approve Pending Changes", `${row_data.ref_no} - ${row_data.model_name_view} changes has been approved`)
        }else{
            openNotification("error", "Approved Pending Changes", response.error_feedback.non_field_errors)
        }
        reloadData()
    }

    return(
        <div className="paper pt-0 w-100">
        <Row>

            <Hide hide={!loading}>
                <Col xs={24} >
                <HELoading/>
                </Col>
            </Hide>

            <Hide hide={loading}>
            <Col xs={24} >
                <Tabs activeKey={status} onChange={onStatusChange}>
                        <CustomTabPane tab="Pending" key="Pending"/>
                        <CustomTabPane tab="Approved" key="Approved"/>
                        <CustomTabPane tab="Rejected" key="Rejected"/>
                </Tabs>
            </Col>

            <Col xs={24}>
                <CustomDynamicTable
                    default_search={props.search}
                    table_props={{ rowKey: (record)=> record.ref_no}}
                    title={<>
                        {status} Changes
                        <Tooltip
                            className="ms-1"
                            placement="topLeft"
                            title={`List of package changes that are ${status}`}
                            color={PRIMARY_COLOR}
                            arrowPointAtCenter
                        >
                                                                   <span className="p-0 m-0">
                                                                       <InfoIconGreenFilled/>
                                                                   </span>
                        </Tooltip>
                    </>}
                    // re_render_columns={language}
                    expandable={{
                        defaultExpandedRowKeys: ['0'],
                        columnWidth:"25", expandedRowRender:({ref_no})=> <PackagePendingChangesDetails key={ref_no} {...props} ref_no={ref_no} status={status} reloadMainData={reloadData}/>}}
                    reload_data={reload}
                    columns={columns}
                    fetch_url={PackagesPendingChangesURL}
                    custom_params={{"package": package_id, status}}
                    edit_label="Approve All"
                    delete_label="Reject All"
                    onDelete={(status === "Pending" && is_admin && package_pending_change_perm.edit) && onRejected}
                    onEdit={(status === "Pending" && is_admin && package_pending_change_perm.edit) && onApproved}
                    deleteLabelConfirm={row_data=>"Are you sure to reject this change ??"}
                    remove_action_icon
                />
            </Col>
            </Hide>
        </Row>
        </div>
    )
}
