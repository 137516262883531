import React, {useEffect, useState} from "react";
import {checkPermission} from "../../../global/permissions";
import {permission_data} from "../../../global/permissions_data";
import {isValidValue} from "../../../global/helper_functions";
import {Alert, Tabs} from "antd";
import Hide from "../../../global/components/Hide";
import {CustomTabPane} from "../../../global/components/CustomTabPane";
import {getUserType} from "../../../global/auth_functions";
import {StaticLanguageTabs} from "../../../global/components/StaticLanguageTabs";
import {ProductDetails} from "./ProductDetails";
import {ProductGallery} from "./ProductGallery";


export const ProductInfo = props => {
    const slug = props.slug || "";
    const url_params = props.match.params || {};
    const [product_id] = React.useState(url_params.id || 'new');
    const url_query = new URLSearchParams(props.location.search);
    const tab = (url_query.get("tab") || "1") + "";
    const [lang_classes, setLangClasses] = useState("");
    const [user_type] = useState(getUserType());
    const [language, setLanguage] = useState("en");
    const [current_tab, setCurrentTab] = useState("1");
    const [is_new] = useState(product_id === "new");
    const [product_data, setProductData] = useState({});

    useEffect(() => {
        if ((!isValidValue(url_params.id) && props.location.pathname !== `/${slug}/products/new`) || (product_id !== "new" && !checkPermission(permission_data.product.view)) || (product_id === "new" && !checkPermission(permission_data.product.add))) {
            props.history.push(`/${slug}/products`);
        }
        setCurrentTab(tab);
    }, []);

    const handleTabsChange = (new_value, extra_params={}) => {
        new_value = new_value + "";
        let params_attributes = {tab: new_value, ...extra_params};
        const params = new URLSearchParams(params_attributes);

        props.history.push({
            pathname: props.location.pathname,
            search: params.toString()
        });

        setCurrentTab(new_value);
    };

    const handleLanguageChange = (new_value) => {
        setLanguage(new_value);
    };

    useEffect(()=>{
        if (props.scroll_height > 113){
            setLangClasses("top-0 position-fixed bg-white z-index-100 w-100")
        }else{
            setLangClasses("")
        }
    },[props.scroll_height])

    return (
        <>


            <div className="m-0 p-0 d-md-flex justify-content-between">
                <h5 className="mb-3 mt-2">{product_id === "new" ? "Add Product" :  (<>Edit Product > <span className="text-black-50">{product_data.title_en}</span></>)}</h5>
            </div>


            <div className="paper pt-0 w-100">
                    <StaticLanguageTabs  value={language} onChange={handleLanguageChange} disabled_ar={is_new}/>

                <Hide hide={!isValidValue(lang_classes)}>
                    <StaticLanguageTabs className={lang_classes} value={language} onChange={handleLanguageChange} disabled_ar={is_new}/>
                </Hide>


                <Tabs activeKey={current_tab} onChange={handleTabsChange}>
                    <CustomTabPane tab="Details" key="1"/>
                    <CustomTabPane tab="Gallery" key="2"
                                   disabled={is_new || !checkPermission(permission_data.product_gallery.view)}/>
                </Tabs>
            </div>

                    <Hide hide={current_tab !== '1'}>
                        <ProductDetails
                            {...props}
                            current_tab={current_tab}
                            is_new={is_new}
                            user_type={user_type}
                            language={language}
                            product_id={product_id}
                            setProductData={setProductData}
                            handleTabsChange={handleTabsChange}
                        />
                    </Hide>

                    <Hide hide={current_tab !== '2'}>
                        <ProductGallery
                            {...props}
                            user_type={user_type}
                            language={language}
                            product_id={product_id}
                            handleTabsChange={handleTabsChange}
                        />
                    </Hide>


        </>)
}
