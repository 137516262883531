import React, {useState} from "react";
import {Col, Row} from "antd";
import Top5Packages from "./components/Top5Packages";
import BookingPerformance from "./components/BookingPerformance";
import BookingRevenue from "./components/BookingRevenue";
import SaleIncomingDetails from "./components/SaleIncomingDetails";
import {checkPermission} from "../../global/permissions";
import {permission_data} from "../../global/permissions_data";
import Hide from "../../global/components/Hide";
import SaleRefundDetails from "./components/SaleRefundDetails";
import ConsultationsRevenue from "./components/ConsultationsRevenue";
import {isAgent} from "../../global/auth_functions";


export const Reports = props => {
    const [is_agent] = useState(isAgent())

    return (
        <Row gutter={[12, 12]}>

            {/*<Col xs={24} md={24}>*/}
            {/*    <div className="paper border-radius-10 w-100 overflow-hidden mb-4">*/}
            {/*        <div className="w-100 h-100">*/}
            {/*            <PackageCommissionBreakdown {...props}/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</Col>*/}

            {/*<Col xs={24} md={24}>*/}
            {/*    <div className="paper border-radius-10 w-100 overflow-hidden mb-4">*/}
            {/*        <div className="w-100 h-100">*/}
            {/*            <BookingCommissionBreakdown {...props}/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</Col>*/}

            <Col xs={24}>
                <div className="paper border-radius-10 w-100 overflow-hidden mb-4" style={{height: "500px", minHeight: "500px"}}>
                    <div className="w-100 h-100">
                        <Top5Packages/>
                    </div>
                </div>
            </Col>

            <Hide hide={is_agent}>
            <Col xs={24}>
                <div className="paper border-radius-10 w-100 mb-4" style={{height: "350px", minHeight: "300px"}}>
                    <div className="w-100 h-100">
                        <ConsultationsRevenue/>
                    </div>
                </div>
            </Col>
            </Hide>

            <Col xs={24} md={12}>
                <div className="paper border-radius-10 w-100 mb-4" style={{height: "500px", minHeight: "500px"}}>
                    <div className="w-100 h-100">
                        <BookingPerformance/>
                    </div>
                </div>
            </Col>

            <Col xs={24} md={12}>
                <div className="paper border-radius-10 w-100 mb-4" style={{height: "500px", minHeight: "500px"}}>
                    <div className="w-100 h-100">
                        <BookingRevenue/>
                    </div>
                </div>
            </Col>


            <Hide hide={!checkPermission(permission_data.reports.view_incoming_details_report)}>
                <Col xs={24} md={24}>
                    <div className="paper border-radius-10 w-100 overflow-hidden mb-4">
                        <div className="w-100 h-100">
                            <SaleIncomingDetails/>
                        </div>
                    </div>
                </Col>
            </Hide>

            <Hide hide={!checkPermission(permission_data.reports.view_refund_details_report)}>
                <Col xs={24} md={24}>
                    <div className="paper border-radius-10 w-100 overflow-hidden mb-4">
                        <div className="w-100 h-100">
                            <SaleRefundDetails/>
                        </div>
                    </div>
                </Col>
            </Hide>

        </Row>
    )
}
