import {CustomTextField} from "./CustomTextField";
import {useEffect, useState} from "react";
import {viewDateAndTime} from "../../helper_functions";

const CustomDateAndTimeView = (props) =>{
    const [view_value, setViewValue] = useState("");

    useEffect(()=>{
        const temp_value =  viewDateAndTime(props.value);
        setViewValue(temp_value);
    },[props.value])
    return(
        <CustomTextField
            {...props}
            view_only
            disabled
            value={view_value}
        />
    )
}

export default CustomDateAndTimeView;
