import React, {useEffect, useState} from "react";
import CustomModal from "../../../../global/components/CustomModal";
import {Col, Divider, Empty, Row} from "antd";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {BookingsAddonsURL} from "../../../../global/urls";
import Hide from "../../../../global/components/Hide";
import {Loading} from "../../../../global/components/Loading";
import {isAgent} from "../../../../global/auth_functions";

export const BookingItemAddonsModal = (props) => {
    const booking_item = props.booking_item || "";
    const [is_agent] = useState(isAgent());
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchData = async () =>{
        await setLoading(true);

        let response = await httpRequestWithAuth(BookingsAddonsURL, "GET", null, {booking_item});

        if (response.status){
            setData(response.data);
        }
        await setLoading(false);
    }

    useEffect(()=>{
        fetchData();
    },[])





    return(
        <CustomModal title="Add-Ons" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]} className="p-2 pt-3 pb-3">
                <Hide hide={!loading}>
                    <Col xs={24}>
                        <Loading/>
                    </Col>
                </Hide>

                <Hide hide={loading}>


                    {data.map((addon_data, index) => (
                        <Col xs={24} md={12}>
                            {/*<Hide hide={index === 0}>*/}
                            {/*    <div className="w-100">*/}
                            {/*        <Divider className="mt-3 mb-3"/>*/}
                            {/*    </div>*/}
                            {/*</Hide>*/}
                            <div className="d-flex w-100 mt-1">
                                <img src={addon_data.display_photo} width={100} height={100}
                                     alt={addon_data.title_en}
                                     className="border-radius-10"/>

                                <div className="p-2 pt-0 w-100 d-flex flex-column justify-content-between">
                                            <div className="font-size-16 fw-bold text-dots border-bottom mb-2">
                                                {addon_data.title_en}
                                            </div>

                                        <div className="font-size-12 fw-bold">
                                            {addon_data.unit} Price: {parseFloat(addon_data.price_per_item)} {addon_data.currency}
                                        </div>

                                        <div className="font-size-12 fw-bold">
                                            Qty: {addon_data.qty}
                                        </div>

                                        <div className="font-size-12 fw-bold">
                                            Total: {parseFloat(addon_data.net_total)} {addon_data.currency}
                                        </div>

                                </div>
                            </div>
                        </Col>
                        ))}


                    <Hide hide={data.length !== 0}>
                        <Col xs={24}>
                            <Empty/>
                        </Col>
                    </Hide>

                </Hide>


            </Row>
        </CustomModal>
        )
}
