import DynamicAutocomplete from "./DynamicAutocomplete";
import {countries_list} from "../../variables";


export const AllWorldCountriesAutoComplete = props =>{
    return(
        <DynamicAutocomplete
            {...props}
            title_key="id"
            mode={props.multiple ? "multiple":""}
            static_options={countries_list}
        />
    )
}










