import {Col, Row} from "antd";
import {CustomDynamicTable} from "../../../global/components/CustomDynamicTable";
import {useState} from "react";
import {OnlinePaymentsURL} from "../../../global/urls";
import {viewDateAndTime} from "../../../global/helper_functions";

export const OnlinePayments = props =>{
    const [reload, setReload] = useState(false);

    const [columns] = useState([
        {
            title: "Ref No",
            dataIndex: "ref_no",
            width: "6%",
        },
        {
            title: "Invoice Id",
            dataIndex: "invoice_id",
            width: "6%"
        },
        {
            title: "Method",
            dataIndex: "payment_method",
            width: "5%"
        },
        {
            title: "Payment For",
            dataIndex: "payment_for",
            width: "5%"
        },
        {
            title: "Name",
            dataIndex: "customer_name",
            width: "15%"
        },
        {
            title: "Mobile",
            dataIndex: "customer_mobile_number",
            width: "10%"
        },,
        {
            title: "E-mail",
            dataIndex: "customer_email",
            width: "15%"
        },
        {
            title: "Amount",
            dataIndex: "invoice_value_other",
            width: "10%",
            align: 'center',
            render: (value, row_data) =>`${value} ${row_data.invoice_value_other_currency || ""}`
        },
        {
            title: "KWD Amount",
            dataIndex: "invoice_value_kwd",
            width: "10%",
            align: 'center',
            render: (value) =>`${value} KWD`
        },
        {
            title: "Rate to KWD",
            dataIndex: "conversion_rate_to_kw",
            width: "8%",
            align: 'center',
        },
        {
            title: "HE-Rate to KWD",
            dataIndex: "conversion_he_rate_to_kw",
            width: "8%",
            align: 'center',
        },
        {
            title: "Status",
            dataIndex: "invoice_status",
            width: "8%",
            align: 'center'
        },
        {
            title: "Created At",
            dataIndex: "created_at_view",
            width: "10%",
            align: 'center'
        },
        // {
        //     title: "Authorization Id",
        //     dataIndex: "transaction_authorization_id",
        //     width: "15%",
        //     render: (value)=> value || "----"
        // },
        // {
        //     title: "Transaction Date",
        //     dataIndex: "transaction_datetime_view",
        //     width: "20%"
        // },

    ])



    const export_columns = [
        {
            title: "Ref No",
            key: "ref_no",
        },
        {
            title: "Invoice Id",
            key: "invoice_id",
        },
        {
            title: "Payment Method",
            key: "payment_method",
        },
        {
            title: "Payment For",
            key: "payment_for",
        },
        {
            title: "Customer Name",
            key: "customer_name",
        },
        {
            title: "Customer Email",
            key: "customer_email",
        },
        {
            title: "Customer Contact Number",
            key: "customer_mobile_number",
        },
        {
            title: "Amount",
            key: "invoice_value_kwd",
        },
        {
            title: "Status",
            key: "invoice_status",
        },
        {
            title: "Created at",
            key: "createdAt",
            format: value => viewDateAndTime(value),
        },
    ]

    return(
        <Row>

            <Col xs={24} className="paper">
                <CustomDynamicTable
                    allow_export
                    export_columns={export_columns}
                    title="Online Payments"
                    reload_data={reload}
                    columns={columns}
                    fetch_url={OnlinePaymentsURL}
                />
            </Col>
        </Row>
    )
}
