import useWindowSize from "../useWindowSize";

const Hide = (props) => {
  const {inner_width} = useWindowSize();
  let null_value = props.children?null: false;

  if (props.hide_down_sm && inner_width <  576)return null_value;
  if (props.hide_down_md && inner_width <  768)return null_value;
  if (props.hide_down_lg && inner_width <  992)return null_value;
  if (props.hide_down_xl && inner_width <  1200)return null_value;
  if (props.hide_down_xxl && inner_width < 1400)return null_value;

  if (props.hide_on_xs && inner_width <  576)return null_value;
  if (props.hide_up_sm && inner_width >=  576)return null_value;
  if (props.hide_up_md && inner_width >=  768)return null_value;
  if (props.hide_up_lg && inner_width >=  992)return null_value;
  if (props.hide_up_xl && inner_width >=  1200)return null_value;
  if (props.hide_up_xxl && inner_width >=  1400)return null_value;

  if (props.hide_on_sm && 576 <= inner_width <  768)return null;
  if (props.hide_on_md && 768 <= inner_width <  992)return null;
  if (props.hide_on_lg && 992 <= inner_width <  1200)return null;
  if (props.hide_on_xl && 1200 <= inner_width <  1400)return null;

  if (props.hide) return null;
  return props.children || true;

};

export default Hide;
