import React, {useEffect, useState} from "react";
import CustomModal from "../../../global/components/CustomModal";
import {Col, Row} from "antd";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {BookingUpdateStatusURL} from "../../../global/urls";
import Hide from "../../../global/components/Hide";
import {Loading} from "../../../global/components/Loading";
import {openNotification, showConfirm} from "../../../global/helper_functions";
import {ModelActionButton} from "../../../global/components/ModelActionButton";
import CustomSelectField from "../../../global/components/fields/CustomSelectField";
import {booking_status} from "../../../global/variables";
import {CustomTextField} from "../../../global/components/fields/CustomTextField";

const all_reason = ["Bad Whether", "Equipment Issue", "Not Enough participants", "Personal Reason", "Found a Better Offer", "Poor Customer Service", ""];

export const cancelled_by_options = [
    {title: "Agency", value: "Agency"},
    {title: "Customer", value: "Customer"},
];

export const agency_cancelled_reason_options = [
    {title: "Bad Whether", value: "Bad Whether"},
    {title: "Equipment Issue", value: "Equipment Issue"},
    {title: "Not Enough participants", value: "Not Enough participants"},
    {title: "Other", value: "Other"},
];

export const customer_cancelled_reason_options = [
    {title: "Personal Reason", value: "Personal Reason"},
    {title: "Found a Better Offer", value: "Found a Better Offer"},
    {title: "Poor Customer Service", value: "Poor Customer Service"},
    {title: "Other", value: "Other"},
];

export const ChangeBookingStatus = (props) => {
    const booking_item = props.booking_item || "";
    const booking_item_data = props.booking_item_data || "";
    const [data, setData] = useState({
        status: props.data.status,
        cancelled_by: props.data.cancelled_by,
        cancelled_reason: all_reason.includes(props.data.cancelled_reason)?props.data.cancelled_reason:"Other",
        other_reason: !all_reason.includes(props.data.cancelled_reason)?props.data.cancelled_reason:"",
    });
    // const [remove_refund, setDeleteRefund] = useState(false);
    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});
    const [booking_status_options, setBookingStatusOptions] = useState(booking_status);

    useEffect(() => {
        let temp_options = [...booking_status];

        if (data.status === "Payment Pending") {
            temp_options.splice(5, 1);
        }

        if (["Approved", "Cancelled", "Abandoned"].includes(data.status)) {
            temp_options.splice(4, 2);
        }

        if (parseFloat(booking_item_data.paid_amount) > 0) {
            temp_options.splice(2, 1);
        }


        setBookingStatusOptions(temp_options)

    }, [])


    const handelStatusChange = (name, value) => {
        let temp_data = {...data};
        temp_data[name] = value;

        if (name === "cancelled_by"){
            temp_data.cancelled_reason = ""
            temp_data.other_reason = ""
        }

        setData(temp_data);
    }

    const onSave = async (e, remove_refund = false) => {
        setLoading(true);
        setHelperText({})

        let temp_data = {
            status: data.status,
            cancelled_by: data.cancelled_by,
            cancelled_reason: data.cancelled_reason,
        }

        if (data.cancelled_reason === "Other") {
            temp_data.cancelled_reason = data.other_reason;
        }

        let response = await httpRequestWithAuth(`${BookingUpdateStatusURL}${booking_item}`, "PATCH", {
            ...temp_data,
            booking_item,
            remove_refund
        })
        if (response.status) {
            openNotification("success", "Change Booking Status", `Boobking status has been updated`)
            props.handleModelClose();
        } else {
            if (response.error_feedback.need_to_remove_refund) {
                showConfirm("Update Booking Status", "To change from Cancelled to Any other status, you have to delete the Refund first. By clicking on OK, refund will be deleted and status updated.", () => onSave(e, true));

                // setDeleteRefund(true)
            }
            // openNotification("helper_text", "Send Payment Request", response.helper_text_feedback.non_field_errors)
            setHelperText(response.error_feedback)
        }

        setLoading(false);
    }


    return (
        <CustomModal title="Change Booking Status" visible={props.open} onCancel={props.handleModelClose} small>
            <Row gutter={[12, 10]}>

                <Hide hide={!loading}>
                    <Col xs={24}>
                        <Loading/>
                    </Col>
                </Hide>

                <Hide hide={loading}>

                    <Col xs={24}>
                        <CustomSelectField
                            label="Status"
                            name="status"
                            value={data.status}
                            onChange={handelStatusChange}
                            error={helper_text.status !== undefined}
                            helper_text={helper_text.status}
                            options={booking_status_options}
                        />
                    </Col>

                    <Hide hide={data.status !== "Cancelled"}>
                        <Col xs={24}>
                            <CustomSelectField
                                label="Cancelled By"
                                name="cancelled_by"
                                value={data.cancelled_by}
                                onChange={handelStatusChange}
                                error={helper_text.cancelled_by !== undefined}
                                helper_text={helper_text.cancelled_by}
                                options={cancelled_by_options}
                            />
                        </Col>


                        <Hide hide={!['Customer', "Agency"].includes(data.cancelled_by)}>
                            <Col xs={24}>
                                <CustomSelectField
                                    label="Cancelled Reason"
                                    name="cancelled_reason"
                                    value={data.cancelled_reason}
                                    onChange={handelStatusChange}
                                    error={helper_text.cancelled_by !== undefined}
                                    helper_text={helper_text.cancelled_by}
                                    options={data.cancelled_by === "Agency"?agency_cancelled_reason_options:customer_cancelled_reason_options}
                                />
                            </Col>

                        <Hide hide={data.cancelled_reason !== "Other"}>
                            <Col xs={24}>
                                <CustomTextField
                                    label="Other Reason"
                                    name="other_reason"
                                    value={data.other_reason}
                                    onChange={handelStatusChange}
                                    error={helper_text.other_reason !== undefined}
                                    helper_text={helper_text.other_reason}
                                />
                            </Col>
                        </Hide>
                        </Hide>

                    </Hide>


                    <Col xs={24} className="p-0">
                        <ModelActionButton
                            error_msg={helper_text.non_field_errors}
                            loading={loading}
                            onSave={onSave}
                            onCancel={props.handleModelClose}
                        />
                    </Col>

                </Hide>


            </Row>
        </CustomModal>
    )
}
