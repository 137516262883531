import React, {useEffect, useState} from "react";
import CustomModal from "../../../../global/components/CustomModal";
import {Col, Row} from "antd";
import {CustomTextField} from "../../../../global/components/fields/CustomTextField";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {PackagesInstallmentURL, PackagesPendingChangesForModalURL} from "../../../../global/urls";
import {customMoment, isValidValue, openNotification, upperCaseView} from "../../../../global/helper_functions";
import {ModelActionButton} from "../../../../global/components/ModelActionButton";
import {StaticLanguageTabs} from "../../../../global/components/StaticLanguageTabs";
import CustomSelectField from "../../../../global/components/fields/CustomSelectField";
import {package_status} from "../../../../global/variables";
import Hide from "../../../../global/components/Hide";
import {getUserType} from "../../../../global/auth_functions";
import {CustomDateTimeField} from "../../../../global/components/fields/CustomDateTimeField";
import ViewActionsDetails from "../../../../global/components/ViewActionsDetails";


export const PackageInstallmentFormModal = props => {
    const [is_admin] = useState(getUserType() === "Admin");
    const [language, setLanguage] = useState(props.language || "en");
    const [data, setData] = useState({
        amount: 0,
        status: "Approved",
        ...props.data
    });

    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});
    const [new_pending_changes, setNewPendingChanges] = useState({});
    const is_new = !isValidValue(data.id);

    useEffect(() => {
        if (isValidValue(data.id)) {
            getPendingChanges();
        }
    }, [])

    const getPendingChanges = async () =>{
        let response = await httpRequestWithAuth(PackagesPendingChangesForModalURL, "GET", null, {model_name: "PackageInstallmentRules", package: data.PackageId, object_id: data.id});
        if (response.status){
            setNewPendingChanges(response.data)
        }
    }

    const handleFieldChange = (name, value) => {
        let temp_data = {...data};
        temp_data[name] = value;

        setData(temp_data);
    }



    const onSave = async () => {
        await setLoading(true);


        let response;

        let temp_data = {
            ...data,
            installment_deadline: isValidValue(data.installment_deadline) ? customMoment(data.installment_deadline).format("DD-MM-YYYY hh:mm") : "",
        }

        if (is_new) {
            response = await httpRequestWithAuth(PackagesInstallmentURL, "POST", temp_data);
        } else {
            response = await httpRequestWithAuth(`${PackagesInstallmentURL}${data.id}`, "PUT", temp_data);
        }

        if (response.status) {
            if (is_new) {
                openNotification("success", "Add Package Installment", `${data[`title_${language}`]} package installment has been added`)
            } else {
                openNotification("success", "Update Package Installment", response.data.msg || `${data[`title_${language}`]} package installment has been updated`)
            }
            props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
        }

        await setLoading(false);

    }

    return (
        <CustomModal title="Package Installment Form" visible={props.open} onCancel={props.handleModelClose} paddingTop="0">
            <StaticLanguageTabs value={language} onChange={setLanguage} />

            <Row gutter={[12, 10]}>

                <Col xs={24} md={12}>
                    <CustomTextField
                        required
                        label={`Title ${upperCaseView(language)}`}
                        name={`title_${language}`}
                        value={data[`title_${language}`]}
                        onChange={handleFieldChange}
                        error={helper_text[`title_${language}`] !== undefined}
                        helper_text={helper_text[`title_${language}`]}
                        warning_tooltip={new_pending_changes[`title_${language}`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                    />
                </Col>

                <Col xs={24} md={12}>
                    <CustomDateTimeField
                        required
                        label="Installment Deadline"
                        name="installment_deadline"
                        value={data.installment_deadline}
                        onChange={handleFieldChange}
                        error={helper_text.installment_deadline !== undefined}
                        helper_text={helper_text.installment_deadline}
                        warning_tooltip={new_pending_changes[`installment_deadline`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                    />
                </Col>

                <Col xs={24} md={is_admin?12:24}>
                    <CustomTextField
                        required
                        label="Amount"
                        name="amount"
                        value={data.amount}
                        onChange={handleFieldChange}
                        error={helper_text.amount !== undefined}
                        helper_text={helper_text.amount}
                        type="number"
                        addonAfter="%"
                        warning_tooltip={new_pending_changes[`amount`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                    />
                </Col>

                <Hide hide={!is_admin}>
                    <Col xs={24} md={12}>
                        <CustomSelectField
                            label="Status"
                            name="status"
                            value={data.status}
                            onChange={handleFieldChange}
                            error={helper_text.status !== undefined}
                            helper_text={helper_text.status}
                            options={package_status}
                            warning_tooltip={new_pending_changes[`status`]}
                            warning_tooltip_title="New Value Waiting Approve:"
                        />
                    </Col>
                </Hide>

                <Hide hide={is_new}>
                    <ViewActionsDetails data={data}/>
                </Hide>

                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}
