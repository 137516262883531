import {CustomModelDraggable} from "../../../../global/components/CustomModelDraggable";
import {Button} from "antd";


export const HowToAddPackage = props =>{
    return(
        <CustomModelDraggable
        open={props.open}
        onCancel={props.handleModelClose}
        title="How To Add Package?"
        width={600}
        >
            <p>
                1) You must fill in all needed information: <br/>
                <label className="ms-2">- Title</label> <br/>
                <label className="ms-2">- Package Type</label> <br/>
                <label className="ms-2">- Categories (Minimum 2, Maximum 5)</label> <br/>
                <label className="ms-2">- Starting Price</label> <br/>
                <label className="ms-2">- Country</label> <br/>
                <label className="ms-2">- Description (Please include everything that the clients need to know!)</label> <br/>
                <label className="ms-2 mt-2">- <b>Click <Button className="ms-2" type="primary">SAVE</Button></b> </label> <br/>
            </p>
        </CustomModelDraggable>
    )
}
