import {Col, Row} from "antd";
import {CustomDynamicTable} from "../../../global/components/CustomDynamicTable";
import React, {useState} from "react";
import {PackagesInclusionsURL} from "../../../global/urls";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {openNotification} from "../../../global/helper_functions";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import {AddButton} from "../../../global/components/AddButton";
import {PackageInclusionsFormModal} from "./components/PackageInclusionsFormModal";
import Hide from "../../../global/components/Hide";


export const PackagesInclusions = props =>{
    const [open_from_data, setOpenFormData] = useState(false);
    const [form_data, setFormData] = useState({});
    const [reload, setReload] = useState(false);

    const [package_inclusions_perm] = useState({
        delete: checkPermission(permission_data.package_inclusions.delete),
        edit: checkPermission(permission_data.package_inclusions.edit),
        add: checkPermission(permission_data.package_inclusions.add),
    });

    const [columns] = useState([

        {
            title: "Name En",
            dataIndex: "name_en",
            width: "25%"
        },
        {
            title: "Name Ar",
            dataIndex: "name_ar",
            width: "25%"
        },
        {
            title: "Order",
            dataIndex: "order",
            width: "25%",
            align: 'center'
        },

    ]);

    const handelOpenFormData = (data={})=>{
        setFormData(data)
        setOpenFormData(true)
    }

    const handelCloseFormData = (reload_data=false)=>{
        setFormData({});
        setOpenFormData(false);

        if (reload_data){
            setReload(Math.random());
        }

    }

    const onDelete = async (row_data) =>{
        let response = await httpRequestWithAuth(`${PackagesInclusionsURL}${row_data.id}`, "DELETE");
        if (response.status){
            openNotification("success", "Delete Package Inclusions", `${row_data.name_en} package inclusions has been deleted`)
        }else{
            openNotification("error", "Delete Package Inclusions", response.error_feedback.non_field_errors)
        }
    }

    const onEdit = async (row_data) =>{
        handelOpenFormData(row_data)
    }

    const onAdd = async () =>{
        handelOpenFormData()
    }

    const freeAction = (
        <>
            <AddButton onClick={onAdd}>Add Package Inclusions</AddButton>
        </>
    );

    return(
        <Row>
            <Hide hide={!open_from_data}>
                <PackageInclusionsFormModal
                    open={open_from_data}
                    data={form_data}
                    handleModelClose={handelCloseFormData}
                />
            </Hide>

            <Col xs={24} className="paper">
                <CustomDynamicTable
                    title="Packages Inclusions"
                    reload_data={reload}
                    columns={columns}
                    fetch_url={PackagesInclusionsURL}
                    free_action={freeAction}
                    onDelete={package_inclusions_perm.delete && onDelete}
                    onEdit={package_inclusions_perm.edit && onEdit}
                />
            </Col>
        </Row>
    )
}
