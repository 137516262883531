import {Button, Col, Row} from "antd";
import {CustomDynamicTable} from "../../global/components/CustomDynamicTable";
import {useState} from "react";
import {RulesURL, UsersURL} from "../../global/urls";
import {httpRequestWithAuth} from "../../global/axios_instance";
import {openNotification, showError, showInfo} from "../../global/helper_functions";
import {permission_data} from "../../global/permissions_data";
import {checkPermission} from "../../global/permissions";
import {AddButton} from "../../global/components/AddButton";
import {RuleFormModal} from "./components/RuleFormModal";
import Hide from "../../global/components/Hide";

export const Rules = props =>{
    const [open_from_data, setOpenFormData] = useState(false);
    const [form_data, setFormData] = useState({});
    const [reload, setReload] = useState(false);

    const [rule_perm] = useState({
        delete: checkPermission(permission_data.rule.delete),
        edit: checkPermission(permission_data.rule.edit),
        add: checkPermission(permission_data.rule.add),
    });

    const [columns] = useState([
        {
            title: "Name",
            dataIndex: "name",
            width: "40%"
        },
        {
            title: "Rule for",
            dataIndex: "rule_for",
            width: "30%"
        },
        {
            title: "Num. of permissions",
            dataIndex: "number_of_permissions",
            width: "20%"
        }
    ])

    const handelOpenFormData = (data={})=>{
        setFormData(data)
        setOpenFormData(true)
    }

    const handelCloseFormData = (reload_data=false)=>{
        setFormData({});
        setOpenFormData(false);

        if (reload_data){
            setReload(Math.random());
        }

    }

    const onDelete = async (row_data) =>{
        let response = await httpRequestWithAuth(`${RulesURL}${row_data.id}`, "DELETE");
        if (response.status){
            openNotification("success", "Delete Rule", `${row_data.name} rule has been deleted`)
        }else{
            openNotification("error", "Delete Rule", response.error_feedback.non_field_errors)
        }
    }

    const onEdit = async (row_data) =>{
        handelOpenFormData(row_data)
    }

    const onAdd = async () =>{
        handelOpenFormData()
    }

    const freeAction = (
        <>
            <AddButton onClick={onAdd}>Add Rule</AddButton>
        </>
    );

    return(
        <Row>
            <Hide hide={!open_from_data}>
                <RuleFormModal
                    open={open_from_data}
                    data={form_data}
                    handleModelClose={handelCloseFormData}
                />
            </Hide>

            <Col xs={24} className="paper">
                <CustomDynamicTable
                    title="Rules"
                    reload_data={reload}
                    columns={columns}
                    fetch_url={RulesURL}
                    free_action={freeAction}
                    onDelete={rule_perm.delete && onDelete}
                    onEdit={rule_perm.edit && onEdit}
                />
            </Col>
        </Row>
    )
}