import React, {useEffect, useState} from "react";
import CustomModal from "../../../../global/components/CustomModal";
import {Col, Row} from "antd";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {
    ListPackagesOptionDatesURL,
    PackagesBulkDeleteDatesFromMultipleOptionURL,
} from "../../../../global/urls";
import {ModelActionButton} from "../../../../global/components/ModelActionButton";
import Hide from "../../../../global/components/Hide";
import moment from "moment";
import CustomBadge from "../../../../global/components/CustomBadge";
import CustomMultiDateSelector from "../../../../global/components/fields/CustomMultiDateSelector";
import {PackageOptionsAutoComplete} from "../../../../global/components/fields/PackageOptionsAutoComplete";


export const BulkDeleteOptionsDatesModal = props => {
    const language = props.language || "";
    const [before_1_month] = useState(moment().add(-1, 'months'));
    const [current_dates, setCurrentDates] = useState([]);
    const package_option_data = props.package_option_data || {};
    const [data, setData] = useState({
        dates: [],
        package_options_id: [],
    });



    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});

    useEffect(()=>{
        const fetchDisabledDates = async () =>{
            const response = await httpRequestWithAuth(ListPackagesOptionDatesURL, "GET", null, {date_only: true, package_id: props.package_id})
            if (response.status){
                let temp_data = response.data.filter(option=>option !== data.date_view);
                setCurrentDates(temp_data);
            }
        }
        fetchDisabledDates();
    },[])

    const availableDate = (current) => {
        const current_formatted = current.format("DD-MM-YYYY");

        return current_dates.includes(current_formatted);
    };

    const handleFieldChange = (name, value) => {
        let temp_helper_text = {...helper_text, [name]: undefined};
        let temp_data = {...data};
        temp_data[name] = value;

        setData(temp_data);
        setHelperText(temp_helper_text);
    }



    const onSave = async () => {
        await setLoading(true);

        let response = await httpRequestWithAuth(PackagesBulkDeleteDatesFromMultipleOptionURL, "DELETE", data);

        if (response.status) {

            props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
        }

        await setLoading(false);
    }


    const handleRemoveDate = (value) => {
        setData({...data, dates: data.dates.filter(date => date !== value)});
    }

    return (
        <CustomModal size="md" title="Bulk Delete Options Dates" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>


                    <Col xs={24} className="d-flex justify-content-center">
                        <Col xs={24} sm={13} md={10}>
                            <CustomMultiDateSelector
                                label={<span>Click on the dates you would like to <span className="text-danger fw-bold">delete</span></span>}
                                availableDate={availableDate}
                                name="dates"
                                value={data.dates || []}
                                onChange={handleFieldChange}
                                error={helper_text.dates !== undefined}
                                helper_text={helper_text.dates}
                            />
                            </Col>
                        </Col>


                    <Col xs={24}>
                        <label className="w-100">Dates</label>
                        <div className="border p-1 w-100">
                            {(data.dates || []).map(Date => <CustomBadge className="m-1" title={moment(Date).format("ddd, DD MMM, YYYY")}
                                                                              onClose={() => handleRemoveDate(Date)}/>)}
                            <Hide hide={(data.dates || []).length !== 0}>
                                <span className="p-2">No Dates To View</span>
                            </Hide>
                        </div>
                    </Col>


                <Col xs={24}>
                    <PackageOptionsAutoComplete
                        multiple
                        packages={[props.package_id]}
                        label="Package Options"
                        name="package_options_id"
                        value={data.package_options_id}
                        onChange={handleFieldChange}
                        error={helper_text.package_options_id !== undefined}
                        helper_text={helper_text.package_options_id}
                    />
                </Col>


                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        save_danger
                        hide_icon
                        saveLabel="Delete"
                        onSave={onSave}
                        onCancel={props.handleModelClose}

                    />
                </Col>

            </Row>
        </CustomModal>
    )
}
