import DynamicAutocomplete from "./DynamicAutocomplete";
import {GetActionUserDetailsURL} from "../../urls";

const UserActionField = (props)=>{
    const user_id = props.value || "";

    return(
        <DynamicAutocomplete
            {...props}
            view_only
            disabled
            fetch_url={GetActionUserDetailsURL}
            title_key={["user_type", "full_name"]}
            params_array={[user_id]}
            params={{user_id}}
        />
    )
}


export default UserActionField;

