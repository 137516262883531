import {Button} from "antd";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {ITSupportUpdateAndFixTravelBookingItemDetailsURL} from "../../../global/urls";
import {useState} from "react";
import {isValidValue, showError, showInfo} from "../../../global/helper_functions";
import ITSupportUploadFileModal from "./ITSupportUploadFileModal";
import Hide from "../../../global/components/Hide";
import { CSVLink } from "react-csv";

const UpdateAndFixTravelBookingItemDetails = (props) => {
    const it_support_data = props.it_support_data || {};
    const [loading, setLoading] = useState(false);
    const [open_upload_file, setOpenUploadFile] = useState(false);

    const onSave = async (data={})=>{
        await setLoading(true);
        const password = it_support_data.password || "";

        if (!isValidValue(data.file)){
            showError("Failed", "Please Select Json File");
            await setLoading(false)
            return;
        }

        let form_data = new FormData();
        form_data.append("password", password);
        form_data.append("file", data.file);

        let response = await httpRequestWithAuth(ITSupportUpdateAndFixTravelBookingItemDetailsURL, "POST", form_data);
        if (response.status){
            showInfo(<div>
                Done
                <br/>
                <br/>


                <CSVLink data={response.data.not_found}>
                    Download error rows
                </CSVLink>
            </div>,"");
            // downloadDataAsCsv("Skipped_UpdateAndFixTravelBookingItemDetails",response.data.not_found || []);
            setOpenUploadFile(false);
        }else{
            showError("Failed", response.error_feedback.non_field_errors)
        }
        await setLoading(false)
    }



  return(
      <>
          <Hide hide={!open_upload_file}>
          <ITSupportUploadFileModal
              open={open_upload_file}
              handleModelClose={()=>setOpenUploadFile(false)}
              onSave={onSave}
          />
          </Hide>

          <Button type="primary" loading={loading} onClick={()=>setOpenUploadFile(true)} block>
              Update And Fix Travel Booking Item Details
      </Button>
      </>

  )
}


export default UpdateAndFixTravelBookingItemDetails;
