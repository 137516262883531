import {Button, Col, Row} from "antd";
import {CustomDynamicTable} from "../../global/components/CustomDynamicTable";
import React, {useState} from "react";
import {BookingsURL} from "../../global/urls";
import {permission_data} from "../../global/permissions_data";
import {checkPermission} from "../../global/permissions";
import {AddButton} from "../../global/components/AddButton";
import {ViewMainBookingDetails} from "./components/ViewMainBookingDetails";
import Hide from "../../global/components/Hide";
import {isAgent} from "../../global/auth_functions";
import CustomBadge from "../../global/components/CustomBadge";
import CustomSelectField from "../../global/components/fields/CustomSelectField";
import {booking_status, interval_options, package_type} from "../../global/variables";
import CustomRadioField from "../../global/components/fields/CustomRadioField";
import {PackageAutoComplete} from "../../global/components/fields/PackageAutoComplete";
import {isValidValue, viewDateAndTime} from "../../global/helper_functions";
import CustomCheckBox from "../../global/components/fields/CustomCheckBox";
import {AgencyAutoComplete} from "../../global/components/fields/AgencyAutoComplete";
import {CustomDateField} from "../../global/components/fields/CustomDateField";
import BookingRowMobileView from "./components/BookingRowMobileView";

export const Bookings = props => {
    const url_query = new URLSearchParams(props.location.search);
    const {slug, tab_view, package_id, voucher_id, interval} = props;
    const [is_agent] = useState(isAgent());
    const [reload, setReload] = useState(false);
    const [view_booking_receipt, setViewBookingReceipt] = useState(false);
    const [booking_receipt_data, setBookingReceiptData] = useState({});
    const [filter, setFilter] = useState({
        voucher_id: voucher_id || "",
        package_id: package_id || "",
        interval: isValidValue(interval)?interval:(url_query.get("from-created-date") || url_query.get("to-created-date"))?"All":"Upcoming",
        status: "",
        from_created_date: url_query.get("from-created-date") || "",
        to_created_date: url_query.get("to-created-date") || "",
    });
    const [booking_perm] = useState({
        add: checkPermission(permission_data.booking.add),
        edit: checkPermission(permission_data.booking.edit),
        delete: checkPermission(permission_data.booking.delete),
    });

    const export_columns = [
        {
            title: "Booking Ref",
            key: "booking_ref_no",
        },
        {
            title: "Item No.",
            key: "ref_no",
        },
        {
            title: "Package Type",
            key: "booking_type",
        },
        {
            title: "Package",
            key: "title_en",
        },
        {
            title: "Customer Name",
            key: "customer_name",
        },
        {
            title: "Customer Email",
            key: "customer_email",
        },
        {
            title: "Customer Contact Number",
            key: "customer_contact_number_view",
        },
        {
            title: "Paid Amount",
            key: "paid_amount",
        },
        {
            title: "Net. Total",
            key: "net_total",
        },
        {
            title: "Status",
            key: "status",
        },
        {
            title: "Created at",
            key: "createdAt",
            format: value => viewDateAndTime(value),
        },
    ]

    const mobile_columns = [
        {
            dataIndex: "ref_no",
            width: "6%",
            render: (value, row_data) => <BookingRowMobileView {...props} reloadData={reloadData} data={row_data}/>
        },
    ]
    const columns = [
        // {
        //     title: "Booking Ref",
        //     dataIndex: "booking_ref_no",
        //     width: "6%",
        // },
        {
            title: "Item No.",
            dataIndex: "ref_no",
            width: "6%",
            render: (value, row_data) => <a href={`/${slug}/bookings/${row_data.id}`} target="_blank">{value}</a>
        },
        {
            title: "Title EN",
            dataIndex: "title_en",
            width: "25%",
            hide: isValidValue(package_id),
        },
        {
            title: "Type",
            dataIndex: "booking_type",
            width: "5%",
            hide: isValidValue(package_id),
        },
        {
            title: "Date",
            dataIndex: "date_view",
            width: "15%",
            hide: !isValidValue(package_id),
        },
        {
            title: "Customer Name",
            dataIndex: "customer_name",
            width: isValidValue(package_id)?"22%":"10%"
        },
        {
            title: "Qty",
            dataIndex: "qty",
            width: "5%",
            align: "center"
        },
        // {
        //     title: "Price",
        //     dataIndex: "price_overall",
        //     width: "5%",
        //     align: "end",
        //     render: value => parseFloat(value).toFixed(3)
        // },
        // {
        //     title: "Discount",
        //     dataIndex: "discount_amount_overall",
        //     width: "5%",
        //     align: "end",
        //     render: value => parseFloat(value).toFixed(3)
        // },
        {
            title: "Paid Amount",
            dataIndex: "paid_amount",
            width: isValidValue(package_id)?"14%":"5%",
            align: "end",
            render: value => parseFloat(value).toFixed(3),
        },
        {
            title: "Net. Total",
            dataIndex: "net_total",
            width: isValidValue(package_id)?"10%":"8%",
            align: "end",
            render: value => parseFloat(value).toFixed(3)
        },
        {
            title: "Status",
            dataIndex: "status",
            width: "5%",
            align: "center",
            render: (value) => <CustomBadge
                type={value !== "Approved" ? value === "Cancelled" ? "danger" : value === "Abandoned" ? "primary" : value === "Completed" ? "" : "disabled" : ""} title={value}/>
        },
        {
            title: "Created at",
            dataIndex: "createdAt",
            render: value => viewDateAndTime(value),
            width: "25%",
        },
        {
            title: "Actions",
            width: "10%",
            align: "center",
            render: (value, row_data) => (
                <>
                    <Button className="m-1" type="primary" onClick={() => onView(row_data)} block>
                        View
                    </Button>

                    <Hide hide={is_agent}>
                        <Button className="m-1" type="primary" onClick={() => openBookingReceipt(row_data)} block>
                            Receipt Details
                        </Button>
                    </Hide>

                </>
            )
        },
    ];


    const onView = async (row_data) => {
        props.history.push(`/${slug}/bookings/${row_data.id}`);
    }

    const onAdd = async () => {
        props.history.push(`/${slug}/bookings/new`);
    }

    const openBookingReceipt = (row_data = {}) => {
        setViewBookingReceipt(true);
        setBookingReceiptData(row_data.Booking || {})
    }


    const closeBookingReceipt = () => {
        setViewBookingReceipt(false);
        setBookingReceiptData({});
        reloadData();
    }

    const handleFilterChange = (name, value) => {
        setFilter({
            ...filter,
            [name]: value
        })
        reloadData();
    }

    const reloadData = () =>{
        setReload(Math.random());
    }

    const freeAction = (
        <>

            <AddButton className="m-1" onClick={onAdd}>Add Bookings</AddButton>
        </>
    );

    const filter_toolbar = (
        <Row gutter={[10, 10]}>
            <Hide hide={tab_view}>
            <Col xs={24} md={6}>
                <CustomSelectField
                    empty_title="All"
                    label="Booking Type"
                    name="booking_type"
                    value={filter.booking_type}
                    onChange={handleFilterChange}
                    options={package_type}
                />
            </Col>
            </Hide>


            <Col xs={24} md={tab_view?8:6}>
                <CustomSelectField
                    empty_title="All"
                    label="Status"
                    name="status"
                    value={filter.status}
                    onChange={handleFilterChange}
                    options={booking_status}
                />
            </Col>

            <Col xs={24} md={tab_view?8:6}>
                <CustomDateField
                    label="Date"
                    name="date"
                    value={filter.date}
                    onChange={handleFilterChange}
                />
            </Col>

            <Col xs={24} md={tab_view?8:6}>
                <CustomDateField
                    label="Created At"
                    name="created_at"
                    value={filter.created_at}
                    onChange={handleFilterChange}
                />
            </Col>

            <Hide hide={tab_view}>
                <Col xs={24} md={is_agent ? 24 : 12}>
                    <PackageAutoComplete
                        package_type={filter.booking_type || ""}
                        label="Package"
                        name="package_id"
                        value={filter.package_id}
                        onChange={handleFilterChange}
                    />
                </Col>

                <Hide hide={is_agent}>
                    <Col xs={24} md={12}>
                        <AgencyAutoComplete
                            package_type={filter.agency_id || ""}
                            label="Agency"
                            name="agency_id"
                            value={filter.agency_id}
                            onChange={handleFilterChange}
                        />
                    </Col>
                </Hide>
            </Hide>


            <Col xs={24} md={12} className="mb-0">
                <CustomRadioField
                    required
                    name="interval"
                    value={filter.interval}
                    onChange={handleFilterChange}
                    options={interval_options}
                />
            </Col>

            <Col xs={24} md={12}
                 className="mb-0 d-flex align-items-center justify-content-center justify-content-md-end">
                <CustomCheckBox label="Paid" name="is_paid" value={filter.is_paid} onChange={handleFilterChange}/>
                <CustomCheckBox label="Unpaid" name="is_unpaid" value={filter.is_unpaid} onChange={handleFilterChange}
                                className="ms-3"/>
                <CustomCheckBox label="Partially Paid" name="is_partially_paid" value={filter.is_partially_paid}
                                onChange={handleFilterChange} className="ms-3"/>
                <CustomCheckBox label="Refunded" name="is_refunded" value={filter.is_refunded}
                                onChange={handleFilterChange} className="ms-3"/>
            </Col>

        </Row>
    );

    return (
        <Row>
            <Hide hide={!view_booking_receipt}>
                <ViewMainBookingDetails
                    {...props}
                    open={view_booking_receipt}
                    data={booking_receipt_data}
                    handleModelClose={closeBookingReceipt}
                />
            </Hide>


            <Col xs={24} className={`${tab_view ? "pt-0" : "paper"}`}>
                <CustomDynamicTable
                    allow_export
                    export_columns={export_columns}
                    title="Bookings"
                    reload_data={reload}
                    columns={columns}
                    mobile_columns={mobile_columns}
                    fetch_url={BookingsURL}
                    free_action={(booking_perm.add && !tab_view) && freeAction}
                    filter_toolbar={filter_toolbar}
                    custom_params={filter}
                />
            </Col>
        </Row>
    )
}
