import React, {useEffect, useState} from "react";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {PackagesPendingChangesForModalURL, PackagesSettingsURL} from "../../../global/urls";
import {customMoment, isValidValue, openNotification, showError, upperCaseView} from "../../../global/helper_functions";
import {Avatar, Button, Col, Divider, Image, Row, Table} from "antd";
import {ActionButton} from "../../../global/components/ActionButton";
import CustomSelectField from "../../../global/components/fields/CustomSelectField";
import {DANGER_COLOR_2, package_status, physical_activity_options} from "../../../global/variables";
import {CustomTextField} from "../../../global/components/fields/CustomTextField";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import {CustomDateTimeField} from "../../../global/components/fields/CustomDateTimeField";
import CustomCheckBox from "../../../global/components/fields/CustomCheckBox";
import {HELoading} from "../../../global/components/HELoading";
import {CustomDurationField} from "../../../global/components/fields/CustomDurationField";
import {CountryAutoComplete} from "../../../global/components/fields/CountryAutoComplete";
import {PackageCategoryAutoComplete} from "../../../global/components/fields/PackageCategoryAutoComplete";
import CustomSwitch from "../../../global/components/fields/CustomSwitch";
import Hide from "../../../global/components/Hide";
import ViewActionsDetails from "../../../global/components/ViewActionsDetails";
import {SelectGuruModal} from "../gurus/components/SelectGuruModal";


const privacy_type_options = [
    // {title: "Specific Users", value: "Specific Users"},
    // {title: "Specific Tire", value: "Specific Tire"},
    {title: "Private Code", value: "Private Code"},
]

const commission_amount_option = [
    {title: "Percentage", value: "Percentage"},
    {title: "Fixed", value: "Fixed"},
]
export const PackageSettings = props => {
    const package_id = props.package_id || "";
    const package_data = props.package_data || "";
    const slug = props.slug || "";
    const user_type = props.user_type || "";
    const is_agent = user_type === "Agent";

    const lang = props.language || "en";
    const [has_perm] = useState(checkPermission(permission_data.package_setting.edit));
    const [has_perm_to_update_he_commission] = useState(checkPermission(permission_data.package_setting.update_he_commission));
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [open_guru_modal, setOpenGuruModal] = useState(false);
    const [helper_text, setHelperText] = useState({});
    const [new_pending_changes, setNewPendingChanges] = useState({});
    const is_travel_package = package_data.package_type === "Travel";

    useEffect(() => {
        if (isValidValue(data.id)) {
            getPendingChanges();
        }
    }, [])

    const getPendingChanges = async () => {
        let response = await httpRequestWithAuth(PackagesPendingChangesForModalURL, "GET", null, {
            model_name: "PackageSettings",
            package: data.PackageId,
            object_id: data.id
        });
        if (response.status) {
            setNewPendingChanges(response.data)
        }
    }

    const fetchData = async () => {
        let response = await httpRequestWithAuth(`${PackagesSettingsURL}`, "GET", null, {package: package_id});
        if (response.status) {
            setData(response.data);
            props.setPackageData({...package_data, ...response.data})
        } else {
            showError("Get Package Settings Data", response.error_feedback.non_field_errors);
            onCancel();
        }
        setLoading(false);
    }


    const handleFieldChange = (name, value) => {
        let temp_data = {...data};

        if (name === "guru_image") {
            temp_data[`${name}_filename`] = isValidValue(value) ? value.name : "";
        }

        temp_data[name] = value;
        setData(temp_data);
    }

    const getCountryObj = (country_obj) => {
        if (data.package_type !== "Travel") {
            let temp_data = {...data};

            if (isValidValue(country_obj)) {
                temp_data.countries_id = country_obj.id;
                // temp_data.currency = country_obj.currency;
            } else {
                temp_data.countries_id = "";
                temp_data.currency = "";
            }
            setData(temp_data);

        }
    }


    useEffect(() => {
        fetchData();
    }, [])


    const onCancel = () => {
        props.history.push(`/${slug}/packages`);
    }


    const onSave = async () => {
        await setLoading(true);
        setHelperText({})

        let temp_data = {
            ...data,
            active_from: isValidValue(data.active_from) ? customMoment(data.active_from).format("DD-MM-YYYY hh:mm") : "",
            active_to: isValidValue(data.active_to) ? customMoment(data.active_to).format("DD-MM-YYYY hh:mm") : "",
            last_installments_date: isValidValue(data.last_installments_date) ? customMoment(data.last_installments_date).format("DD-MM-YYYY hh:mm") : "",
        }


        const form_data = new FormData();

        Object.keys(temp_data).map(data_key => {
            form_data.append(data_key, temp_data[data_key]);
        })

        console.log("form_data================================================>>>", temp_data);
        let response = await httpRequestWithAuth(`${PackagesSettingsURL}${data.id}`, "PUT", form_data);

        if (response.status) {
            props.getPackageData();
            openNotification("success", "Edit Package Settings", response.data.msg || `Package settings has been updated`);
        } else {
            setHelperText(response.error_feedback)
            showError("Save Failed", "Please checks all fields");
        }

        await setLoading(false);
    }

    if (loading) {
        return <HELoading/>
    }

    const onSelectGuru = (guru_data) => {
      setData({
          ...data,
          GuruId: guru_data.id || "",
          guru_name: guru_data.name_en || "",
          guru_name_en: guru_data.name_en || "",
          guru_name_ar: guru_data.name_ar || "",
          guru_email: guru_data.email || "",
          display_guru_image: guru_data.display_image || "",
          guru_image_filename: guru_data.image_filename || "",
      });

        setOpenGuruModal(false);
    }

    return (
        <div className="paper pt-0 w-100">


            <Hide hide={!open_guru_modal}>
                <SelectGuruModal
                    open={open_guru_modal}
                    handleModelClose={()=>setOpenGuruModal(false)}
                    onSelect={onSelectGuru}
                />
            </Hide>

            <Row gutter={[12, 10]}>


                <Col xs={24}>
                    <Divider orientation="left" orientationMargin="0" className="m-0 fw-bold font-size-18">
                        General Settings
                    </Divider>
                </Col>

                <Hide hide={is_agent}>
                    <Col xs={24}>
                        <CustomTextField
                            view_only={!has_perm}
                            label="Video Url"
                            name="video_url"
                            value={data.video_url}
                            onChange={handleFieldChange}
                            error={helper_text.video_url !== undefined}
                            helper_text={helper_text.video_url}
                            warning_tooltip={new_pending_changes[`video_url`]}
                            warning_tooltip_title="New Value Waiting Approve:"
                            tooltip="https://www.youtube.com/embed/REF_NO_HERE"
                            placeholder="ex: https://www.youtube.com/embed/yyaxLFyzto4"
                        />
                    </Col>
                </Hide>

                <Col xs={24} md={12}>
                    <CustomDurationField
                        view_only={!has_perm}
                        label="Duration"
                        name="duration"
                        value={data.duration}
                        onChange={handleFieldChange}
                        error={helper_text.duration !== undefined}
                        helper_text={helper_text.duration}
                        warning_tooltip={new_pending_changes[`duration`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                        tooltip="Duration of the trip/experiance"
                    />
                </Col>

                <Col xs={24} md={6}>
                    <CustomSelectField
                        view_only={!has_perm}
                        label="Physical Activity"
                        name="physical_activity"
                        value={data.physical_activity}
                        onChange={handleFieldChange}
                        error={helper_text.physical_activity !== undefined}
                        helper_text={helper_text.physical_activity}
                        warning_tooltip={new_pending_changes[`physical_activity`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                        options={physical_activity_options}
                    />
                </Col>

                <Col xs={24} md={is_agent ? 3 : 6}>
                    <CustomTextField
                        disabled
                        label="Currency"
                        value={data.currency}
                        tooltip={is_travel_package ? "Travel packages currency always in KWD" : "Currency depends on county of experience"}
                    />
                </Col>


                <Col xs={24} md={is_agent ? 3 : 12}>
                    <CustomSelectField
                        view_only={!has_perm || is_agent}
                        label="Status"
                        name="status"
                        value={data.status}
                        onChange={handleFieldChange}
                        error={helper_text.status !== undefined}
                        helper_text={helper_text.status}
                        options={package_status}
                        warning_tooltip={new_pending_changes[`status`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                    />
                </Col>


                <Col xs={24}>
                    <CountryAutoComplete
                        getFullObj={getCountryObj}
                        multiple={is_travel_package}
                        for_agency_experience={data.package_type !== "Travel" ? data.AgencyId : ""}
                        // is_he_supported={data.package_type !== "Travel" ? true : null}
                        label={is_travel_package ? "Countries" : "Country"}
                        name="countries_id"
                        value={data.countries_id}
                        onChange={handleFieldChange}
                        error={helper_text.countries_id !== undefined}
                        helper_text={helper_text.countries_id}
                        warning_tooltip={new_pending_changes[`countries_id`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                        tooltip={is_travel_package ? "Countries included in the trip" : "Country of experience"}
                    />
                </Col>

                <Col xs={24}>
                    <PackageCategoryAutoComplete
                        multiple
                        label="Categories"
                        name="categories_id"
                        value={data.categories_id}
                        onChange={handleFieldChange}
                        error={helper_text.categories_id !== undefined}
                        helper_text={helper_text.categories_id}
                        warning_tooltip={new_pending_changes[`categories_id`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                        tooltip="Add suitable categories to increase package sales & viwws"
                    />
                </Col>


                <Hide hide={is_agent}>
                    <Col xs={24} md={8}>
                        <CustomTextField
                            view_only={!has_perm}
                            disabled={!data.view_custom_ribbon}
                            label={`Custom Ribbon ${lang}`}
                            name={`custom_ribbon_${lang}`}
                            value={data[`custom_ribbon_${lang}`]}
                            onChange={handleFieldChange}
                            error={helper_text[`custom_ribbon_${lang}`] !== undefined}
                            helper_text={helper_text[`custom_ribbon_${lang}`]}
                            warning_tooltip={new_pending_changes[`custom_ribbon_${lang}`]}
                            warning_tooltip_title="New Value Waiting Approve:"
                            // tooltip="custom_ribbon"
                        />
                    </Col>

                    <Col xs={24} md={8}>
                        <CustomTextField
                            view_only={!has_perm}
                            disabled={!data.view_custom_ribbon}
                            label="Custom Ribbon Color"
                            name="custom_ribbon_color"
                            value={data.custom_ribbon_color}
                            onChange={handleFieldChange}
                            error={helper_text.custom_ribbon_color !== undefined}
                            helper_text={helper_text.custom_ribbon_color}
                            warning_tooltip={new_pending_changes[`custom_ribbon_color`]}
                            warning_tooltip_title="New Value Waiting Approve:"
                            // tooltip="custom_ribbon_color"
                        />
                    </Col>

                    <Col xs={24} md={8} className="d-flex justify-content-end">
                        <CustomSwitch
                            name="view_custom_ribbon"
                            label="View Custom Ribbon"
                            checked_label="Yes"
                            unchecked_label="No"
                            value={data.view_custom_ribbon}
                            onChange={handleFieldChange}
                            warning_tooltip={new_pending_changes[`view_custom_ribbon`]}
                            warning_tooltip_title="New Value Waiting Approve:"
                        />
                    </Col>
                </Hide>


                <Hide hide={is_agent || !is_travel_package}>
                    <Col xs={24}>
                        <CustomCheckBox
                            name="traveller_information_not_required"
                            label="Traveller information not required"
                            value={data.traveller_information_not_required}
                            onChange={handleFieldChange}
                            warning_tooltip={new_pending_changes[`traveller_information_not_required`]}
                            warning_tooltip_title="New Value Waiting Approve:" f
                            tooltip="No need for the customer to input travellers information (e.g. passport details) in the cart."
                        />
                    </Col>
                </Hide>


                <Hide hide={is_agent}>
                    <Col xs={24}>
                        <CustomCheckBox
                            name="exclude_service_charge"
                            label="Exclude Service Charge"
                            value={data.exclude_service_charge}
                            onChange={handleFieldChange}
                            warning_tooltip={new_pending_changes[`exclude_service_charge`]}
                            warning_tooltip_title="New Value Waiting Approve:"
                        />
                    </Col>
                </Hide>

                {/*<Hide hide={!is_travel_package}>*/}
                <Col xs={24}>
                    <CustomCheckBox
                        name="requires_approval"
                        label="Requires Approval"
                        value={data.requires_approval}
                        onChange={handleFieldChange}
                        warning_tooltip={new_pending_changes[`requires_approval`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                        tooltip="Allow agents to approved customer before payment for travel"
                    />
                </Col>
                {/*</Hide>*/}


                <Hide hide={!is_travel_package}>
                    <Col xs={24}>
                        <CustomCheckBox
                            name="is_group_trip"
                            label="Group Trip"
                            value={data.is_group_trip}
                            onChange={handleFieldChange}
                            warning_tooltip={new_pending_changes[`is_group_trip`]}
                            warning_tooltip_title="New Value Waiting Approve:"
                        />
                    </Col>

                    <Col xs={24}>
                        <CustomCheckBox
                            name="hide_itinerary"
                            label="Hide Itinerary"
                            value={data.hide_itinerary}
                            onChange={handleFieldChange}
                            warning_tooltip={new_pending_changes[`hide_itinerary`]}
                            warning_tooltip_title="New Value Waiting Approve:"
                        />
                    </Col>
                </Hide>


                <Hide hide={is_agent}>

                    <Col xs={24} className="mt-5">
                        <Divider orientation="left" orientationMargin="0" className="m-0 fw-bold font-size-18">
                            Guru Details
                        </Divider>
                    </Col>


                    <Col xs={24} className="text-end">
                        <Button onClick={()=>onSelectGuru({})} type="primary" danger className="me-2">Remove Guru</Button>
                        <Button onClick={()=>setOpenGuruModal(true)} type="primary">Select Guru</Button>
                    </Col>

                    <Col xs={24}>
                        <Table
                            pagination={false}
                            columns={[
                                {dataIndex: "guru_image", title: "Image", width: "20%", render: (value)=><Avatar size={120} src={value}/>},
                                {dataIndex: "guru_name", title: "Name", width: "40%"},
                                {dataIndex: "guru_email", title: "Email", width: "40%"},
                            ]}
                            dataSource={data.GuruId?[{
                                guru_image: data.display_guru_image,
                                guru_name: data.guru_name,
                                guru_email: data.guru_email,
                            }]:[]}
                        />
                    </Col>

                </Hide>


                <Col xs={24} className="mt-5">
                    <Divider orientation="left" orientationMargin="0" className="m-0 fw-bold font-size-18">
                        Booking Settings
                    </Divider>
                </Col>


                <Col xs={24} md={12}>
                    <CustomTextField
                        view_only={!has_perm}
                        label="Starting Price"
                        name="starting_price"
                        value={data.starting_price}
                        onChange={handleFieldChange}
                        error={helper_text.starting_price !== undefined}
                        helper_text={helper_text.starting_price}
                        type="number"
                        addonAfter={data.currency}
                        warning_tooltip={new_pending_changes[`starting_price`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                        tooltip="Used for quick option creation"
                    />
                </Col>


                <Col xs={24} md={12}>
                    <CustomDateTimeField
                        view_only={!has_perm}
                        label="Last Installments Date"
                        name="last_installments_date"
                        value={data.last_installments_date}
                        onChange={handleFieldChange}
                        error={helper_text.last_installments_date !== undefined}
                        helper_text={helper_text.last_installments_date}
                        warning_tooltip={new_pending_changes[`last_installments_date`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                        tooltip="Stop installments for new bookings after this date & demand entire price"
                    />
                </Col>


                <Col xs={24} md={8}>
                    <CustomTextField
                        view_only={!has_perm}
                        label="Total Number Of Participants"
                        name="total_number_of_participants"
                        value={data.total_number_of_participants}
                        onChange={handleFieldChange}
                        error={helper_text.total_number_of_participants !== undefined}
                        helper_text={helper_text.total_number_of_participants}
                        type="number"
                        warning_tooltip={new_pending_changes[`total_number_of_participants`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                        tooltip="This is the total number of people we can accommodate on the trip, regardless of room setup"
                    />
                </Col>

                <Col xs={24} md={is_travel_package ? 8 : 12}>
                    <CustomTextField
                        view_only={!has_perm}
                        label="Min Capacity Per Customer"
                        name="min_capacity_per_customer"
                        value={data.min_capacity_per_customer}
                        onChange={handleFieldChange}
                        error={helper_text.min_capacity_per_customer !== undefined}
                        helper_text={helper_text.min_capacity_per_customer}
                        type="number"
                        warning_tooltip={new_pending_changes[`min_capacity_per_customer`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                        tooltip="Minimum quantity required for customer to book"
                    />
                </Col>


                <Col xs={24} md={is_travel_package ? 8 : 12}>
                    <CustomTextField
                        view_only={!has_perm}
                        label="Max Capacity Per Customer"
                        name="max_capacity_per_customer"
                        value={data.max_capacity_per_customer}
                        onChange={handleFieldChange}
                        error={helper_text.max_capacity_per_customer !== undefined}
                        helper_text={helper_text.max_capacity_per_customer}
                        type="number"
                        warning_tooltip={new_pending_changes[`max_capacity_per_customer`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                        tooltip="Maximum quantity for booking per customer"
                    />
                </Col>


                <Col xs={24} md={12}>
                    <CustomDurationField
                        view_only={!has_perm}
                        label="Closing Booking Before"
                        name="closing_before"
                        value={data.closing_before}
                        onChange={handleFieldChange}
                        error={helper_text.closing_before !== undefined}
                        helper_text={helper_text.closing_before}
                        warning_tooltip={new_pending_changes[`closing_before`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                        tooltip="Stop booking for package before duration"
                    />
                </Col>



                <Col xs={24} className="mt-5">
                    <Divider orientation="left" orientationMargin="0" className="m-0 fw-bold font-size-18">
                        Privacy Settings
                    </Divider>
                </Col>

                <Col xs={24} className="mb-3">
                    <CustomSwitch
                        name="is_private"
                        label="Privacy"
                        checked_label="Private"
                        unchecked_label="Public"
                        value={data.is_private}
                        onChange={handleFieldChange}
                        warning_tooltip={new_pending_changes[`is_private`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                    />
                </Col>

                {/*<Col xs={24} md={24}>*/}
                {/*    <CustomRadioField*/}
                {/*        disabled={!data.is_private}*/}
                {/*        label="Privacy Type"*/}
                {/*        name="privacy_type"*/}
                {/*        value={data.privacy_type}*/}
                {/*        onChange={handleFieldChange}*/}
                {/*        error={helper_text.privacy_type !== undefined}*/}
                {/*        helper_text={helper_text.privacy_type}*/}
                {/*        options={privacy_type_options}*/}
                {/*        warning_tooltip={new_pending_changes[`is_private`]}*/}
                {/*        warning_tooltip_title="New Value Waiting Approve:"*/}
                {/*    />*/}
                {/*</Col>*/}


                <Hide hide={data.privacy_type !== "Private Code"}>
                    <Col xs={24} md={8}>
                        <CustomTextField
                            disabled={!data.is_private}
                            label="Private Code"
                            name="private_code"
                            value={data.private_code}
                            onChange={handleFieldChange}
                            error={helper_text.private_code !== undefined}
                            helper_text={helper_text.private_code}
                            warning_tooltip={new_pending_changes[`private_code`]}
                            warning_tooltip_title="New Value Waiting Approve:"
                        />
                    </Col>

                    <Col xs={24} md={8}>
                        <CustomTextField
                            disabled={!data.is_private}
                            label="Total Use"
                            name="private_code_total_use"
                            value={data.private_code_total_use}
                            onChange={handleFieldChange}
                            error={helper_text.private_code_total_use !== undefined}
                            helper_text={helper_text.private_code_total_use}
                            warning_tooltip={new_pending_changes[`private_code_total_use`]}
                            warning_tooltip_title="New Value Waiting Approve:"
                        />
                    </Col>

                    <Col xs={24} md={8}>
                        <CustomTextField
                            disabled={!data.is_private}
                            label="Total Use Per Customer"
                            name="private_code_total_use_per_customer"
                            value={data.private_code_total_use_per_customer}
                            onChange={handleFieldChange}
                            error={helper_text.private_code_total_use_per_customer !== undefined}
                            helper_text={helper_text.private_code_total_use_per_customer}
                            warning_tooltip={new_pending_changes[`private_code_total_use_per_customer`]}
                            warning_tooltip_title="New Value Waiting Approve:"
                        />
                    </Col>
                </Hide>


                <Col xs={24} className="mt-5">
                    <Divider orientation="left" orientationMargin="0" className="m-0 fw-bold font-size-18">
                        Search Settings
                    </Divider>
                </Col>

                <Col xs={24}>
                    <CustomTextField
                        view_only={!has_perm}
                        label={`Keywords ${upperCaseView(lang)}`}
                        name={`keywords_${lang}`}
                        value={data[`keywords_${lang}`]}
                        onChange={handleFieldChange}
                        error={helper_text[`keywords_${lang}`] !== undefined}
                        helper_text={helper_text[`keywords_${lang}`]}
                        warning_tooltip={new_pending_changes[`keywords_${lang}`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                        tooltip="Include here some keywords that describe your trip, this will increase the views and reach of your package. (e.g. travel, adventure, food, instagram, fashion, luxury)"
                    />
                </Col>

                <Col xs={24} className="mt-5">
                    <Divider orientation="left" orientationMargin="0" className="m-0 fw-bold font-size-18">
                        Activation Settings
                    </Divider>
                </Col>


                <Col xs={24} md={6}>
                    <CustomDateTimeField
                        view_only={!has_perm}
                        label="Active From"
                        name="active_from"
                        value={data.active_from}
                        onChange={handleFieldChange}
                        error={helper_text.active_from !== undefined}
                        helper_text={helper_text.active_from}
                        warning_tooltip={new_pending_changes[`active_from`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                        tooltip="only show package on website starting from this date if date is selected"
                    />
                </Col>


                <Col xs={24} md={6}>
                    <CustomDateTimeField
                        view_only={!has_perm}
                        label="Active To"
                        name="active_to"
                        value={data.active_to}
                        onChange={handleFieldChange}
                        error={helper_text.active_to !== undefined}
                        helper_text={helper_text.active_to}
                        warning_tooltip={new_pending_changes[`active_to`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                        tooltip="stop showing package on website after this date if date is selected"
                    />
                </Col>


                <Col xs={24} md={24} className="mt-3">
                    <CustomSwitch
                        name="is_active"
                        label="Activation Status"
                        checked_label="Active"
                        unchecked_label="Inactive"
                        value={data.is_active}
                        onChange={handleFieldChange}
                        warning_tooltip={new_pending_changes[`is_active`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                    />
                </Col>


                <Col xs={24} md={24} className="mt-3">
                    <CustomSwitch
                        not_value
                        label="Sold Out Status"
                        name="is_sold_out"
                        checked_label="Available"
                        unchecked_label="Sold Out"
                        value={data.is_sold_out}
                        onChange={handleFieldChange}
                        unchecked_color={DANGER_COLOR_2}
                        warning_tooltip={new_pending_changes[`is_sold_out`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                        // tooltip="Update availability/sold out status"
                    />
                </Col>


                <Col xs={24} className="mt-5">
                    <Divider orientation="left" orientationMargin="0" className="m-0 fw-bold font-size-18">
                        Hello Explorer Commission
                    </Divider>
                </Col>


                <Col xs={24} md={8}>
                    <CustomSelectField
                        view_only={!has_perm_to_update_he_commission}
                        label="Commission Type"
                        name="he_commission_type"
                        value={data.he_commission_type}
                        onChange={handleFieldChange}
                        error={helper_text.he_commission_type !== undefined}
                        helper_text={helper_text.he_commission_type}
                        options={commission_amount_option}
                    />
                </Col>

                <Col xs={24} md={8}>
                    <CustomTextField
                        view_only={!has_perm_to_update_he_commission}
                        label="Commission Amount"
                        name="commission_amount"
                        value={data.commission_amount}
                        onChange={handleFieldChange}
                        error={helper_text.commission_amount !== undefined}
                        helper_text={helper_text.commission_amount}
                        addonAfter={data.he_commission_type === "Percentage" ? "%" : "KWD"}
                    />
                </Col>

                <Col xs={24} md={8}>
                    <CustomTextField
                        view_only={!has_perm_to_update_he_commission}
                        label="Minimum Commission Amount"
                        name="minimum_commission_amount"
                        value={data.minimum_commission_amount}
                        onChange={handleFieldChange}
                        error={helper_text.minimum_commission_amount !== undefined}
                        helper_text={helper_text.minimum_commission_amount}
                        addonAfter="KWD"
                    />
                </Col>

                <Col xs={24} className="mt-2 mb-2">
                    <Divider orientation="left" orientationMargin="0" className="m-0  mb-2"/>
                </Col>


                <Hide hide={!isValidValue(data.id)}>
                    <ViewActionsDetails data={data}/>
                </Hide>

                <Col xs={24} className="mt-5">
                    <ActionButton
                        error_msg={helper_text.non_field_errors}
                        onCancel={onCancel}
                        allow_save={has_perm}
                        onSave={onSave}
                    />
                </Col>
            </Row>
        </div>
    )
}
