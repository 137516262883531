import {Button, Col, Image, Row} from "antd";
import {CustomDynamicTable} from "../../../global/components/CustomDynamicTable";
import React, {useState} from "react";
import {PackagesCategoriesURL} from "../../../global/urls";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {isValidValue, openNotification, showError, showInfo} from "../../../global/helper_functions";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import {AddButton} from "../../../global/components/AddButton";
import {PackageCategoryFormModal} from "./components/PackageCategoryFormModal";
import Hide from "../../../global/components/Hide";
import {IsActiveView} from "../../../global/components/IsActiveView";

export const PackagesCategories = props =>{
    const [open_from_data, setOpenFormData] = useState(false);
    const [form_data, setFormData] = useState({});
    const [reload, setReload] = useState(false);

    const [package_category_perm] = useState({
        delete: checkPermission(permission_data.package_category.delete),
        edit: checkPermission(permission_data.package_category.edit),
        add: checkPermission(permission_data.package_category.add),
    });

    const [columns] = useState([
        // {
        //     title: "Image",
        //     dataIndex: "display_icon",
        //     width: "15%",
        //     align: "center",
        //     render:(value)=>(
        //         <Image
        //             src={value}
        //         />
        //     )
        // },
        {
            title: "Name En",
            dataIndex: "name_en",
            width: "25%"
        },
        {
            title: "Name Ar",
            dataIndex: "name_ar",
            width: "25%"
        },
        {
            title: "Main Category",
            dataIndex: "main_category_name_en",
            width: "20%",
            render: (value, row_data)=>{
                if (isValidValue(value)){
                    return `${value} - ${row_data.main_category_name_ar}`;
                }

                return "---------"
            }
        },
        {
            title: "Order",
            dataIndex: "order",
            width: "15%",
            align:"center",
        },
        {
            title: "View In Search",
            dataIndex: "view_in_search_page",
            width: "10%",
            align:"center",
            render: (value, row_data)=><IsActiveView active_label="Yes" inactive_label="No" value={value} onChange={()=>updateCategory(row_data, "view_in_search_page", !value)} view_only={!package_category_perm.edit}/>
        },
        {
            title: "Activate",
            dataIndex: "is_active",
            width: "5%",
            align:"center",
            render: (value)=><IsActiveView value={value} view_only={!package_category_perm.edit}/>
        },

    ]);

    const handelOpenFormData = (data={})=>{
        setFormData(data)
        setOpenFormData(true)
    }

    const handelCloseFormData = (reload_data=false)=>{
        setFormData({});
        setOpenFormData(false);

        if (reload_data){
            setReload(Math.random());
        }

    }

    const updateCategory = async (row_data, data_key="", value)=>{
        let response = await httpRequestWithAuth(`${PackagesCategoriesURL}${row_data.id}`, "PUT", {...row_data, [data_key]: value});
        if (response.status){
            openNotification("success", "Update Package Category", `${row_data.name_en} has been updated`)
            setReload(Math.random());
        }else{
            openNotification("error", "Update Package Category", response.error_feedback.non_field_errors)
        }
    }
    const onDelete = async (row_data) =>{
        let response = await httpRequestWithAuth(`${PackagesCategoriesURL}${row_data.id}`, "DELETE");
        if (response.status){
            openNotification("success", "Delete Package Category", `${row_data.name_en} package category has been deleted`)
        }else{
            openNotification("error", "Delete Package Category", response.error_feedback.non_field_errors)
        }
    }

    const onEdit = async (row_data) =>{
        handelOpenFormData(row_data)
    }

    const onAdd = async () =>{
        handelOpenFormData()
    }

    const freeAction = (
        <>
            <AddButton onClick={onAdd}>Add Package Category</AddButton>
        </>
    );

    return(
        <Row>
            <Hide hide={!open_from_data}>
                <PackageCategoryFormModal
                    open={open_from_data}
                    data={form_data}
                    handleModelClose={handelCloseFormData}
                />
            </Hide>

            <Col xs={24} className="paper">
                <CustomDynamicTable
                    title="Packages Categories"
                    reload_data={reload}
                    columns={columns}
                    fetch_url={PackagesCategoriesURL}
                    free_action={freeAction}
                    onDelete={package_category_perm.delete && onDelete}
                    onEdit={package_category_perm.edit && onEdit}
                />
            </Col>
        </Row>
    )
}
