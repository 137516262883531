import React, {useEffect, useState} from "react";
import CustomModal from "../../../global/components/CustomModal";
import {Button, Col, Row} from "antd";
import {copyText, openNotification} from "../../../global/helper_functions";
import {ModelActionButton} from "../../../global/components/ModelActionButton";


export const InvoiceGeneratePaymentLinkModal = props => {
    const url = `${process.env.REACT_APP_CUSTOMER_WEBSITE_URL}payments/invoice/?invoice=${props.invoice_id}`;


    useEffect(()=>{
        onCopy();
    },[])

    const onCopy = () => {
        openNotification("success", "", `Payment Link has been copied`)
        copyText(url);
    }

    return (
        <CustomModal title="Invoice Generate Payment Link" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>


                <Col xs={24} className="mt-2">
                    <div className="m-0 d-flex align-items-center justify-content-center bg-light border p-3">
                        {/*<p className="fw-bold">{payment_link}</p>*/}
                        <div className="text-center">
                            <h6 className="fw-bold font-size-16 main-color">Payment Link Generated successfully</h6>
                            <Button className="m-1" onClick={onCopy}>Click Here To Copy The Payment Link</Button>
                        </div>

                    </div>
                </Col>

                <Col xs={24} className="p-0">
                    <ModelActionButton
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}
