import React from "react";
import {Button, Col, Row, Tooltip} from "antd";
import {CustomDynamicTable} from "../../../global/components/CustomDynamicTable";
import {useEffect, useState} from "react";
import {PackagesInstallmentURL} from "../../../global/urls";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {openNotification} from "../../../global/helper_functions";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import {AddButton} from "../../../global/components/AddButton";
import Hide from "../../../global/components/Hide";
import {PackageInstallmentFormModal} from "./components/PackageInstallmentFormModal";
import {CustomStatusView} from "../../../global/components/CustomStatusView";
import CustomBadge from "../../../global/components/CustomBadge";
import {PRIMARY_COLOR, WARNING_COLOR} from "../../../global/variables";
import {InfoIconGreenFilled} from "../../../global/components/Icons";
import {ModelActionButton} from "../../../global/components/ModelActionButton";

export const PackageInstallment = props =>{
    const package_id = props.package_id || "";
    const slug = props.slug || "";
    const language = props.language || "en";
    const package_data = props.package_data || {};
    const {is_admin, has_pending_status, approve_pending_status_loading, approveAllPendingStatus} = props;

    const [open_from_data, setOpenFormData] = useState(false);
    const [form_data, setFormData] = useState({});
    const [reload, setReload] = useState(false);

    const [package_installment_perm] = useState({
        delete: checkPermission(permission_data.package_installment.delete),
        edit: checkPermission(permission_data.package_installment.edit),
        add: checkPermission(permission_data.package_installment.add),
    });


    const getColumns = language =>[
        {
            title:"Pending Changes",
            dataIndex: "has_changes",
            width: "5%",
            align:"center",
            render: (value, row_data)=> {
                if (!value)return <CustomBadge type="disabled" title="No Changes"/>;
                return(
                    <CustomBadge
                        bg_color={WARNING_COLOR}
                        title="View Changes"
                        onClick={()=>goToPendingChanges(row_data)}
                    />
                )
            }
        },
        {
            title: <label>Title <span className="text-capitalize">{language}</span></label>,
            dataIndex: `title_${language}`,
            width: "55%"
        },
        {
            title: "Installment Deadline",
            dataIndex: "installment_deadline_view",
            width: "20%",
        },
        {
            title: "Amount",
            dataIndex: "amount",
            width: "10%",
            align: "center",
            render: (value)=> `${parseFloat(value)}%`
        },
        {
            title: "Status",
            dataIndex: "status",
            width: "5%",
            align:"center",
            render: (value)=> <CustomStatusView value={value}/>
        },
    ]

    const [columns, setColumns] = useState(getColumns(language));


    useEffect(()=>{
        setColumns(getColumns(language));
    }, [language])

    const handelOpenFormData = (data={})=>{
        setFormData({...data, PackageId:package_id})
        setOpenFormData(true)
    }

    const handelCloseFormData = (reload_data=false)=>{
        setFormData({});
        setOpenFormData(false);

        if (reload_data){
            setReload(Math.random());
            props.getPackageData();
        }
    }

    const onDelete = async (row_data) =>{
        let response = await httpRequestWithAuth(`${PackagesInstallmentURL}${row_data.id}`, "DELETE");
        if (response.status){
            props.getPackageData();
            openNotification("success", "Delete Package Installment", `${row_data.title_en} package installment has been deleted`)
        }else{
            openNotification("error", "Delete Package Installment", response.error_feedback.non_field_errors)
        }
    }

    const onEdit = async (row_data) =>{
        handelOpenFormData({...row_data, PackageId: package_id});
    }

    const onAdd = async () =>{
        handelOpenFormData({PackageId: package_id})
    }
    const onApproveAllPending = () => {
        setReload(Math.random());
    }

    const approveAllPendingStatusBtn = () =>{
        approveAllPendingStatus("PackageInstallmentRules", onApproveAllPending)
    }

    const goToPendingChanges = (row_data) =>{
        props.handleTabsChange("7", {search: row_data.id})
    }

    const freeAction = (
        <>
            <Hide hide={!has_pending_status || !is_admin}>
                <Button
                    block
                    type="primary"
                    className="mb-1 m-md-1 justify-content-center"
                    onClick={approveAllPendingStatusBtn}
                    loading={approve_pending_status_loading}
                >
                    Approve All Pending Status
                </Button>
            </Hide>

            <AddButton onClick={onAdd}>Add Installment</AddButton>
        </>
    );

    return(
        <div className="paper pt-0 w-100">
        <Row>
            <Hide hide={!open_from_data}>
                <PackageInstallmentFormModal
                    language={language}
                    open={open_from_data}
                    data={form_data}
                    handleModelClose={handelCloseFormData}
                />
            </Hide>

                <CustomDynamicTable
                    title={
                    <label className="text-start">
                        Installment
                        <Tooltip
                            className="ms-1"
                            placement="topLeft"
                            title="you can make customer pay there amount for booking in many installment, please note amount of installment will percentage amount from total price"
                            color={PRIMARY_COLOR}
                            arrowPointAtCenter
                        >
                                                                   <span className="p-0 m-0">
                                                                       <InfoIconGreenFilled/>
                                                                   </span>
                        </Tooltip>

                        <br/>
                        <span className="font-size-13 text-black-50">If you require full payment, you may skip this section</span>

                    </label>
                    }
                    // re_render_columns={language}
                    reload_data={reload}
                    columns={columns}
                    fetch_url={PackagesInstallmentURL}
                    free_action={freeAction}
                    onDelete={package_installment_perm.delete && onDelete}
                    onEdit={package_installment_perm.edit && onEdit}
                    custom_params={{"package": package_id}}
                />

            <Col xs={24} className="mt-3">
                <ModelActionButton
                    hide_icon
                    cancel_label="Back"
                    onCancel={props.onBack}
                    saveLabel="Next"
                    onSave={props.onNext}
                />
            </Col>
        </Row>
        </div>
    )
}
