import React, {useEffect, useState} from "react";
import {httpRequestWithAuth} from "../../global/axios_instance";
import {
    BookingSendItemReceiptEmailToAgentURL,
    BookingSendPaymentReceiptToCustomerByEmailURL,
    BookingsURL
} from "../../global/urls";
import {isValidValue, openNotification, showError, upperCaseView} from "../../global/helper_functions";
import {Button, Col, Divider, Row} from "antd";
import Hide from "../../global/components/Hide";
import {
    CaretDownOutlined,
    CaretUpOutlined,
    DollarOutlined,
    SmileOutlined,
    UsergroupDeleteOutlined
} from "@ant-design/icons";
import {checkOnePermission, checkPermission} from "../../global/permissions";
import {permission_data} from "../../global/permissions_data";
import {BookingItemPaymentsModal} from "./components/view_booking_components/BookingItemPaymentsModal";
import {BookingItemPersonsModal} from "./components/view_booking_components/BookingItemPersonsModal";
import {ViewInfo} from "../../global/components/ViewInfo";
import CustomBadge from "../../global/components/CustomBadge";
import CustomDropDown from "../../global/components/CustomDropDown";
import {SendPaymentRequestModal} from "./components/SendPaymentRequestModal";
import {ChangeBookingStatus} from "./components/ChangeBookingStatus";
import {Loading} from "../../global/components/Loading";
import {AddonIcon, RefundIcon} from "../../global/components/Icons";
import {BookingItemRefundsModal} from "./components/view_booking_components/BookingItemRefundsModal";
import {ChangeBookingNotificationEmail} from "./components/ChangeBookingNotificationEmail";
import {getUserAgencyId} from "../../global/auth_functions";
import {ChangeBookingDateTime} from "./components/ChangeBookingDateTime";
import {BookingItemAddonsModal} from "./components/view_booking_components/BookingItemAddonsModal";

export const BookingDetails = props => {
    const slug = props.slug || "";
    const is_admin = props.is_admin || "";
    const language = props.language || "";
    const booking_item_id = props.booking_item_id || "";
    const setBookingItemData = props.setBookingItemData;
    const [agency_id] = useState(getUserAgencyId());
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const is_my_booking = is_admin ? true : agency_id === data.AgencyId;
    const [open_tab, setOpnTab] = useState({general: true, package_information: true});
    const [view_payments, setViewPayments] = useState(['true', true, 1, "1"].includes(props.view_payment));
    const [view_refunds, setViewRefunds] = useState(['true', true, 1, "1"].includes(props.view_refund));
    const [open_send_payment_request_modal, setOpenSendPaymentRequestModal] = useState(false);
    const [open_generate_payment_link_modal, setOpenGeneratePaymentLinkModal] = useState(false);
    const [open_change_status_modal, setOpenChangeStatusModal] = useState(false);
    const [open_change_notification_email_modal, setOpenChangeNotificationEmailModal] = useState(false);
    const [open_change_date_and_time_modal, setOpenChangeDateAndTimeModal] = useState(false);
    const [booking_perm] = useState({
        add: checkPermission(permission_data.booking.add),
        edit: checkPermission(permission_data.booking.edit),
        delete: checkPermission(permission_data.booking.delete),
        send_payment_request: checkPermission(permission_data.booking.send_payment_request),
        send_payment_receipt: checkPermission(permission_data.booking.send_payment_receipt),
    });

    const [view_persons, setViewPersons] = useState(false);
    const [view_addons, setViewAddons] = useState(false);
    const actions_options = [
        {
            key: '1',
            label: "Send Payment Receipt Email",
            disabled: (!data.is_approved && !data.is_completed),
            icon: <SmileOutlined/>,
            hidden: !booking_perm.send_payment_receipt,
        },
        {
            key: '2',
            label: "Send Payment Request Email",
            icon: <SmileOutlined/>,
            disabled: data.is_full_paid || data.is_pending_approval || data.is_cancelled || data.is_abandoned,
            hidden: !booking_perm.send_payment_request,
        },
        {
            key: '3',
            label: "Generate Payment Link",
            icon: <SmileOutlined/>,
            disabled: data.is_full_paid || data.is_pending_approval || data.is_cancelled || data.is_abandoned,

        },
        {
            key: '4',
            label: "Change Booking Status",
            icon: <SmileOutlined/>,
            hidden: !booking_perm.edit
        },
        {
            key: '5',
            label: "Change Booking Notification Email",
            icon: <SmileOutlined/>,
            hidden: !booking_perm.edit
        },
        {
            key: '6',
            label: `Change Booking Date ${isValidValue(data.PackageOptionDateTimeId)?"& Time":""}`,
            icon: <SmileOutlined/>,
            hidden: !booking_perm.edit
            // hidden: !isValidValue(data.PackageOptionDateId)
            // hidden: true
        },
        {
            key: '7',
            label: `Send Booking Receipt Email To Agent`,
            icon: <SmileOutlined/>,
            hidden: !is_admin
            // hidden: true
        },
    ];

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        setLoading(true)
        let response = await httpRequestWithAuth(`${BookingsURL}${booking_item_id}`, "GET");

        if (response.status) {
            setData({
                ...response.data,
                balance_amount: (response.data.net_total - response.data.paid_amount).toFixed(3),
                is_full_paid: response.data.net_total - response.data.paid_amount === 0,
            });
            setBookingItemData(response.data);
        } else {
            showError("Fetch data failed", response.error_feedback.non_field_errors);
            onCancel();
        }
        setLoading(false)
    }

    const onCancel = () => {
        props.history.push(`/${slug}/bookings`);
    }


    const handleDetailsTabsChange = (name) => {
        let temp_open_tab = {...open_tab};

        if (temp_open_tab[name]) {
            temp_open_tab[name] = false
        } else {
            temp_open_tab[name] = true
        }

        setOpnTab(temp_open_tab)
    };

    const sendPaymentReceiptToCustomerByEmail = async () => {

        let response = await httpRequestWithAuth(BookingSendPaymentReceiptToCustomerByEmailURL, "POST", {
            booking: data.BookingId,
            booking_item: booking_item_id,
        });

        if (response.status) {
            openNotification("success", "Send Payment Receipt", `Email has been sent`)
        } else {
            openNotification("error", "Send Payment Receipt", response.error_feedback.non_field_errors)
        }

    }

    const sendItemReceiptEmailAgent = async () => {

        let response = await httpRequestWithAuth(BookingSendItemReceiptEmailToAgentURL, "POST", {
            booking: data.BookingId,
            booking_item: booking_item_id,
        });

        if (response.status) {
            openNotification("success", "Send Booking Receipt Email To Agent", `Email has been sent`)
        } else {
            openNotification("error", "Send Booking Receipt Email To Agent", response.error_feedback.non_field_errors)
        }

    }

    const handelActionButtonOptions = ({key}) => {
        if (key === "1") {
            sendPaymentReceiptToCustomerByEmail();
        }

        if (key === "2") {
            setOpenSendPaymentRequestModal(true);
        }

        if (key === "3") {
            setOpenGeneratePaymentLinkModal(true)
            setOpenSendPaymentRequestModal(true)
            // const url = `${process.env.REACT_APP_CUSTOMER_WEBSITE_URL}payments/?booking=${data.BookingId}&booking_items=${booking_item_id}&booking_items_payments=e2094b34-b44f-4212-9390-b38c63f36866`
            // alert(url)
        }

        if (key === "4") {
            setOpenChangeStatusModal(true);
        }

        if (key === "5") {
            setOpenChangeNotificationEmailModal(true);
        }

        if (key === "6") {
            setOpenChangeDateAndTimeModal(true);
        }

        if (key === "7") {
            sendItemReceiptEmailAgent();
        }

    }

    return (
        <>

            <Hide hide={!open_change_date_and_time_modal}>
                <ChangeBookingDateTime
                    open={open_change_date_and_time_modal}
                    current_value={{
                        PackageOptionDateId:data.PackageOptionDateId,
                        PackageOptionDateTimeId:data.PackageOptionDateTimeId,
                    }}
                    handleModelClose={() => {
                        setOpenChangeDateAndTimeModal(false);
                        fetchData();
                    }}
                    booking_item={booking_item_id}
                    booking_item_data={data}
                />
            </Hide>

            <Hide hide={!open_change_notification_email_modal}>
                <ChangeBookingNotificationEmail
                    open={open_change_notification_email_modal}
                    current_value={data.notification_email}
                    handleModelClose={() => {
                        setOpenChangeNotificationEmailModal(false);
                        fetchData();
                    }}
                    booking_item={booking_item_id}
                />
            </Hide>

            <Hide hide={!open_change_status_modal}>
                <ChangeBookingStatus
                    open={open_change_status_modal}
                    data={data}
                    handleModelClose={() => {
                        setOpenChangeStatusModal(false);
                        fetchData();
                    }}
                    booking_item={booking_item_id}
                    booking_item_data={data}
                />
            </Hide>

            <Hide hide={!view_addons}>
                <BookingItemAddonsModal
                    open={view_addons}
                    handleModelClose={() => setViewAddons(false)}
                    booking_item={booking_item_id}
                />
            </Hide>

            <Hide hide={!view_persons}>
                <BookingItemPersonsModal
                    open={view_persons}
                    handleModelClose={() => setViewPersons(false)}
                    booking_item={booking_item_id}
                />
            </Hide>

            <Hide hide={!open_send_payment_request_modal}>
                <SendPaymentRequestModal
                    language={language}
                    generate_payment_link={open_generate_payment_link_modal}
                    open={open_send_payment_request_modal}
                    handleModelClose={() => {
                        setOpenSendPaymentRequestModal(false);
                        setOpenGeneratePaymentLinkModal(false);
                    }}
                    booking_item_data={data}
                    booking_item={booking_item_id}
                    booking={data.BookingId}
                />
            </Hide>

            <Hide hide={!view_payments || !checkPermission(permission_data.booking_payment.view) || data.is_free || !is_my_booking}>
                <BookingItemPaymentsModal
                    language={language}
                    open={view_payments}
                    handleModelClose={() => {
                        fetchData();
                        setViewPayments(false);
                    }}
                    booking_item={booking_item_id}
                    booking_item_data={data}
                    is_full_paid={data.is_full_paid}
                />
            </Hide>

            <Hide hide={!view_refunds || !checkPermission(permission_data.booking_refund.view) || !is_admin || data.is_free || !is_my_booking}>
                <BookingItemRefundsModal
                    language={language}
                    open={view_refunds}
                    handleModelClose={() => {
                        fetchData();
                        setViewRefunds(false);
                    }}
                    booking_item={booking_item_id}
                />
            </Hide>

            <Row gutter={[12, 10]}>

                {/*<Col xs={24}>*/}
                {/*    <h2 className="font-size-22 mb-0">*/}
                {/*        <label className="text-start">*/}
                {/*            Details*/}
                {/*        </label>*/}
                {/*    </h2>*/}
                {/*</Col>*/}


                {/*<Hide hide={open_tab !== "1"}>*/}
                <Hide hide={!loading}>
                    <Loading/>
                </Hide>

                <Hide hide={loading}>

                    <Hide
                        hide={!checkOnePermission([permission_data.booking_payment.view, permission_data.booking_person.view, permission_data.booking_refund.view])}>
                        <Col xs={24} className="d-sm-flex justify-content-between">
                            <div>
                                <Hide
                                    hide={!checkPermission(permission_data.booking_payment.view) || data.is_free || !is_my_booking}
                                >
                                    <Button onClick={() => setViewPayments(true)} className="m-1" type="primary"
                                            icon={<DollarOutlined/>} block={Hide({hide_up_sm: true})}>View
                                        Payments</Button>
                                </Hide>

                                <Hide
                                    hide={!checkPermission(permission_data.booking_refund.view) || !is_admin || data.is_free || !is_my_booking}>
                                    <Button
                                        // disabled={data.status !== "Cancelled"}
                                        onClick={() => setViewRefunds(true)}
                                            className="m-1" type="primary"
                                            icon={data.status === "Cancelled" && <RefundIcon/>}
                                            block={Hide({hide_up_sm: true})}>View Refunds</Button>
                                </Hide>

                                <Hide
                                    hide={!checkPermission(permission_data.package_addon.view)}>
                                    <Button onClick={() => setViewAddons(true)} className="m-1" type="primary"
                                            icon={<AddonIcon width={14} className="me-2"/>} block={Hide({hide_up_sm: true})}>View
                                        Add-ons</Button>
                                </Hide>

                                <Hide
                                    hide={!checkPermission(permission_data.booking_person.view) || data.booking_type !== "Travel" || !is_my_booking}>
                                    <Button disabled={data.addon_price === 0} onClick={() => setViewPersons(true)} className="m-1" type="primary"
                                            icon={<UsergroupDeleteOutlined/>} block={Hide({hide_up_sm: true})}>View
                                        Travellers</Button>
                                </Hide>
                            </div>

                            <div>
                            </div>

                        </Col>
                    </Hide>

                    <Col xs={24}>
                        <Row gutter={[12, 12]} className="border m-1 border-radius-5 overflow-hidden">
                            <Col xs={24} className="bg-light p-2 border-bottom d-flex justify-content-between">
                                <h2 className="font-size-16 m-0">
                                    <Button type="link" onClick={() => handleDetailsTabsChange("general")}>
                                        {open_tab['general'] ? <CaretUpOutlined/> : <CaretDownOutlined/>}
                                    </Button>
                                    General

                                    <Hide hide={!data.is_full_paid || data.is_free}>
                                        <CustomBadge className="ms-2" title="Full Paid" font_size="11"/>
                                    </Hide>
                                    <Hide hide={!data.is_free}><CustomBadge className="ms-2" title="Free"
                                                                            font_size="11"/></Hide>
                                </h2>

                                <Hide hide={!is_my_booking}>
                                    <CustomDropDown btn_type="primary" label="Actions" options={actions_options}
                                                    onChange={handelActionButtonOptions}/>
                                </Hide>

                            </Col>

                            <Hide hide={!open_tab['general']}>


                                <Col xs={24} md={8}>
                                    <ViewInfo
                                        title="Booking Ref"
                                        value={data.ref_no || "-----"}
                                    />
                                </Col>


                                <Col xs={24} md={8}>
                                    <ViewInfo
                                        title="Booking Type"
                                        value={data.booking_type}
                                    />
                                </Col>

                                <Col xs={24} md={8}>
                                    <ViewInfo
                                        title="Notification Email"
                                        value={data.notification_email}
                                    />
                                </Col>

                                <Col xs={24} md={8}>
                                    <ViewInfo
                                        title="Customer Name"
                                        value={data.customer_name || "-----"}
                                    />
                                </Col>

                                <Col xs={24} md={8}>
                                    <ViewInfo
                                        title="Customer Num."
                                        value={data.customer_contact_number || "-----"}
                                    />
                                </Col>

                                <Col xs={24} md={8}>
                                    <ViewInfo
                                        title="Customer Email"
                                        value={data.customer_email || "-----"}
                                    />
                                </Col>


                                {/*<Col xs={24} md={6}>*/}
                                {/*    <h2 className="font-size-12 text-black-50">*/}
                                {/*        Agency*/}
                                {/*    </h2>*/}

                                {/*    <h2 className="font-size-14 ms-2 mb-0">*/}
                                {/*        {`${data.agency_name}`}*/}
                                {/*    </h2>*/}
                                {/*</Col>*/}


                                <Col xs={24} className="mt-1 mb-0">
                                    <Divider className="mt-0 mb-0"/>
                                </Col>


                                <Col xs={24} md={8}>
                                    <ViewInfo
                                        title="Is POS"
                                        value={data.is_pos ? "Yes" : "No"}
                                    />
                                </Col>


                                <Col xs={24} md={8}>
                                    <ViewInfo
                                        title="Voucher Applied"
                                        value={data.is_voucher_applied ? "Yes" : "No"}
                                    />
                                </Col>


                                <Col xs={24} md={8}>
                                    <ViewInfo
                                        title="Discount Per Item"
                                        value={data.discount_amount_per_item}
                                        addonAfter={data.currency}
                                    />
                                </Col>

                                <Col xs={24} md={8}>
                                    <ViewInfo
                                        title="Price Per Item"
                                        value={data.price_per_item}
                                        addonAfter={data.currency}
                                    />
                                </Col>


                                <Col xs={24} md={8}>
                                    <ViewInfo
                                        title="Qty"
                                        value={data.qty}
                                    />
                                </Col>


                                <Col xs={24} md={8}>
                                    <ViewInfo
                                        title="Total Price"
                                        value={data.price_overall}
                                        addonAfter={data.currency}
                                    />
                                </Col>


                                <Col xs={24} className="mt-1 mb-0">
                                    <Divider className="mt-0 mb-0"/>
                                </Col>

                                <Col xs={24} md={8}>
                                    <ViewInfo
                                        title="Add-ons Price"
                                        value={data.addon_price}
                                        addonAfter={data.currency}
                                    />
                                </Col>


                                <Col xs={24} md={8}>
                                    <ViewInfo
                                        title="Total Discount"
                                        value={data.discount_amount_overall}
                                        addonAfter={data.currency}
                                    />
                                </Col>


                                <Col xs={24} md={8}>
                                    <ViewInfo
                                        title="Net. Total"
                                        value={data.net_total}
                                        addonAfter={data.currency}
                                    />
                                </Col>

                                <Col xs={24} md={8}>
                                    <ViewInfo
                                        title="Paid Amount"
                                        value={data.paid_amount}
                                        addonAfter={data.currency}
                                    />
                                </Col>

                                <Col xs={24} md={8}>
                                    <ViewInfo
                                        title="Balance Amount"
                                        value={data.balance_amount}
                                        addonAfter={data.currency}
                                    />
                                </Col>

                                <Col xs={24} md={8}>
                                    <ViewInfo
                                        title="Refund Amount"
                                        value={data.refund_amount}
                                        addonAfter={data.currency}
                                    />
                                </Col>


                                <Col xs={24} md={8}>
                                    <ViewInfo
                                        title="Status"
                                        value={(
                                            <CustomBadge
                                                type={!["Approved", "Completed"].includes(data.status) ? data.status === "Cancelled" ? "danger" : data.status === "Abandoned" ? "primary" : "disabled" : ""}
                                                title={data.status}/>
                                        )}
                                    />
                                </Col>

                                <Hide hide={data.status !== "Cancelled"}>
                                    <Col xs={24} md={8}>
                                        <ViewInfo
                                            title="Cancelled By"
                                            value={data.cancelled_by}
                                        />
                                    </Col>

                                    <Col xs={24} md={8}>
                                        <ViewInfo
                                            title="Cancelled Reson"
                                            value={data.cancelled_reason}
                                        />
                                    </Col>
                                </Hide>

                                <Col xs={24} md={8}>
                                    <ViewInfo
                                        title="Customer Came From"
                                        value={data.customer_came_from}
                                    />
                                </Col>


                                <Col xs={24} className="mt-1 mb-0">
                                    <Divider className="mt-0 mb-0"/>
                                </Col>


                                <Col xs={24} md={8}>
                                    <ViewInfo
                                        title="HE Commission Type"
                                        value={data.he_commission_type}
                                    />
                                </Col>

                                <Col xs={24} md={8}>
                                    <ViewInfo
                                        title="Commission Amount"
                                        value={`${data.commission_amount} ${data.he_commission_type === "Percentage"?"%":data.currency}`}
                                    />
                                </Col>

                                <Col xs={24} md={8}>
                                    <ViewInfo
                                        title="Minimum Commission Amount"
                                        value={`${data.minimum_commission_amount} ${data.currency}`}
                                    />
                                </Col>

                                <Col xs={24} md={8}>
                                    <ViewInfo
                                        title="HE Commission Amount"
                                        value={data.he_commission_amount || "0"}
                                    />
                                </Col>

                                <Col xs={24} md={8}>
                                    <ViewInfo
                                        title="Agency Commission Amount"
                                        value={data.agency_accrual_amount || "0"}
                                    />
                                </Col>

                                <Col xs={24} md={8}>
                                    <ViewInfo
                                        title="Transferred amount to agency"
                                        value={data.transferred_amount_to_agency || "0"}
                                    />
                                </Col>
                                <Col xs={24}/>

                            </Hide>

                        </Row>
                    </Col>
                    {/*</Hide>*/}


                    {/*<Hide hide={open_tab !== "2"}>*/}
                    <Col xs={24} className="mt-3">
                        <Row gutter={[12, 12]} className="border m-1 border-radius-5 overflow-hidden">
                            <Col xs={24} className="bg-light p-2 border-bottom">
                                <h2 className="font-size-16 m-0">
                                    <Button type="link" onClick={() => handleDetailsTabsChange("package_information")}>
                                        {open_tab['package_information'] ? <CaretUpOutlined/> : <CaretDownOutlined/>}
                                    </Button>
                                    Package Information
                                </h2>

                            </Col>

                            <Hide hide={!open_tab['package_information']}>


                                <Col xs={24} md={6}>
                                    <ViewInfo
                                        title="Agency"
                                        value={data.agency_name || "-----"}
                                    />
                                </Col>


                                <Col xs={24} md={9}>
                                    <ViewInfo
                                        title={`Package Title ${upperCaseView(language)}`}
                                        value={`${data[`package_title_${language}`]}`}
                                    />
                                </Col>


                                <Col xs={24} md={9}>
                                    <ViewInfo
                                        title={`Package Option Title ${upperCaseView(language)}`}
                                        value={`${data[`package_option_title_${language}`]}`}
                                    />
                                </Col>


                                <Col xs={24} className="mt-1 mb-0">
                                    <Divider className="mt-0 mb-0"/>
                                </Col>


                                <Col xs={24} md={6}>
                                    <ViewInfo
                                        title="Date"
                                        value={data.date_view || "-----"}
                                    />
                                </Col>


                                <Col xs={24} md={9}>
                                    <ViewInfo
                                        title="Time"
                                        value={data.time_view || "-----"}
                                    />
                                </Col>


                                <Col xs={24} md={9}>
                                    <ViewInfo
                                        title="Duration"
                                        value={data.duration_view || "-----"}
                                    />
                                </Col>


                                <Col xs={24} className="mt-1 mb-0">
                                    <Divider className="mt-0 mb-0"/>
                                </Col>


                                <Col xs={24} md={6}>
                                    <ViewInfo
                                        title="Restricted Capacity"
                                        value={data.restricted_capacity || "-----"}
                                    />
                                </Col>

                                <Col xs={24} md={9}>
                                    <ViewInfo
                                        title="Countries"
                                        value={data.countries_code || "-----"}
                                    />
                                </Col>


                                <Col xs={24} md={9}>
                                    <ViewInfo
                                        title="Requires Approval"
                                        value={data.requires_approval ? "Yes" : "No"}
                                    />
                                </Col>


                                <Col xs={24}>
                                    <ViewInfo
                                        title="Special Notes"
                                        value={data.special_notes}
                                    />
                                </Col>

                                <Col xs={24}/>

                            </Hide>
                        </Row>
                    </Col>
                    {/*</Hide>*/}


                    {/*<Hide hide={open_tab !== "3"}>*/}
                    <Col xs={24} className="mt-3">
                        <Row gutter={[12, 12]} className="border m-1 border-radius-5 overflow-hidden">
                            <Col xs={24} className="bg-light p-2 border-bottom">
                                <h2 className="font-size-16 m-0">
                                    <Button type="link" onClick={() => handleDetailsTabsChange("package_description")}>
                                        {open_tab['package_description'] ? <CaretUpOutlined/> : <CaretDownOutlined/>}
                                    </Button>
                                    Package Description {upperCaseView(language)}
                                </h2>

                            </Col>

                            <Hide hide={!open_tab['package_description']}>

                                <Col xs={24} md={12}>
                                    <h2 className="font-size-14 ms-2 mb-0">
                                        <div
                                            dangerouslySetInnerHTML={{__html: data[`package_description_${language}`] || "<br/>"}}
                                            className="w-100"/>
                                    </h2>
                                </Col>

                                <Col xs={24}/>

                            </Hide>
                        </Row>
                    </Col>
                    {/*</Hide>*/}


                    {/*<Hide hide={open_tab !== "4"}>*/}
                    <Col xs={24} className="mt-3">
                        <Row gutter={[12, 12]} className="border m-1 border-radius-5 overflow-hidden">
                            <Col xs={24} className="bg-light p-2 border-bottom">
                                <h2 className="font-size-16 m-0">
                                    <Button type="link" onClick={() => handleDetailsTabsChange("package_included")}>
                                        {open_tab['package_included'] ? <CaretUpOutlined/> : <CaretDownOutlined/>}
                                    </Button>
                                    Package Included {upperCaseView(language)}
                                </h2>

                            </Col>

                            <Hide hide={!open_tab['package_included']}>

                                <Col xs={24} md={12}>
                                    <h2 className="font-size-14 ms-2 mb-0">
                                        <div
                                            dangerouslySetInnerHTML={{__html: data[`package_included_${language}`] || "<br/>"}}
                                            className="w-100"/>
                                    </h2>
                                </Col>

                                <Col xs={24}/>

                            </Hide>
                        </Row>
                    </Col>
                    {/*</Hide>*/}


                    {/*<Hide hide={open_tab !== "4"}>*/}
                    <Col xs={24} className="mt-3">
                        <Row gutter={[12, 12]} className="border m-1 border-radius-5 overflow-hidden">
                            <Col xs={24} className="bg-light p-2 border-bottom">
                                <h2 className="font-size-16 m-0">
                                    <Button type="link" onClick={() => handleDetailsTabsChange("package_excluded")}>
                                        {open_tab['package_excluded'] ? <CaretUpOutlined/> : <CaretDownOutlined/>}
                                    </Button>
                                    Package Excluded {upperCaseView(language)}
                                </h2>

                            </Col>

                            <Hide hide={!open_tab['package_excluded']}>

                                <Col xs={24} md={12}>
                                    <h2 className="font-size-14 ms-2 mb-0">
                                        <div
                                            dangerouslySetInnerHTML={{__html: data[`package_excluded_${language}`] || "<br/>"}}
                                            className="w-100"/>
                                    </h2>
                                </Col>

                                <Col xs={24}/>

                            </Hide>

                        </Row>
                    </Col>
                    {/*</Hide>*/}


                    {/*<Hide hide={open_tab !== "5"}>*/}
                    <Col xs={24} className="mt-3">
                        <Row gutter={[12, 12]} className="border m-1 border-radius-5 overflow-hidden">
                            <Col xs={24} className="bg-light p-2 border-bottom">
                                <h2 className="font-size-16 m-0">
                                    <Button type="link"
                                            onClick={() => handleDetailsTabsChange("package_cancellation_policy")}>
                                        {open_tab['package_cancellation_policy'] ? <CaretUpOutlined/> :
                                            <CaretDownOutlined/>}
                                    </Button>
                                    Package Cancellation Policy {upperCaseView(language)}
                                </h2>

                            </Col>


                            <Hide hide={!open_tab['package_cancellation_policy']}>

                                <Col xs={24} md={12}>
                                    <h2 className="font-size-14 ms-2 mb-0">
                                        <div
                                            dangerouslySetInnerHTML={{__html: data[`package_cancellation_policy_${language}`] || "<br/>"}}
                                            className="w-100"/>
                                    </h2>
                                </Col>

                                <Col xs={24}/>

                            </Hide>
                        </Row>
                    </Col>
                    {/*</Hide>*/}


                    {/*<Hide hide={open_tab !== "6"}>*/}
                    <Col xs={24} className="mt-3">
                        <Row gutter={[12, 12]} className="border m-1 border-radius-5 overflow-hidden">
                            <Col xs={24} className="bg-light p-2 border-bottom">
                                <h2 className="font-size-16 m-0">
                                    <Button type="link" onClick={() => handleDetailsTabsChange("terms_and_conditions")}>
                                        {open_tab['terms_and_conditions'] ? <CaretUpOutlined/> : <CaretDownOutlined/>}
                                    </Button>
                                    Package Terms & Conditions {upperCaseView(language)}
                                </h2>

                            </Col>


                            <Hide hide={!open_tab['terms_and_conditions']}>

                                <Col xs={24} md={12}>
                                    <h2 className="font-size-14 ms-2 mb-0">
                                        <div
                                            dangerouslySetInnerHTML={{__html: data[`terms_and_conditions_${language}`] || "<br/>"}}
                                            className="w-100"/>
                                    </h2>
                                </Col>

                                <Col xs={24}/>

                            </Hide>

                        </Row>
                    </Col>
                    {/*</Hide>*/}
                </Hide>


            </Row>
        </>)
}
