import React, {useEffect, useState} from "react";
import {TimePicker} from "antd";
import CustomFieldWrapper from "../CustomFieldWrapper";
import moment from "moment";
import {isValidValue} from "../../helper_functions";

export const CustomTimeField = (props) => {
    const format = props.format || "hh:mm A";
    const value_format = props.value_format || "HH:mm";

    const [date_value, setDateValue] = useState(undefined);

    const handleFieldChange = async (time) => {
        if (!props.view_only && !props.disabled) {
            await props.onChange(props.name, moment(time).format(value_format));
        }
    };

    useEffect(() => {
        if (isValidValue(props.value)) {
            const new_date = moment(props.value, value_format);
            if (new_date.isValid()) {
                setDateValue(new_date);
            } else {
                setDateValue(undefined)
                console.error(props.name, "not valid date")
            }

        } else {
            setDateValue(undefined)
        }


    }, [props.value])


    return (
        <CustomFieldWrapper {...props}>
            <TimePicker
                {...props}
                value={date_value}
                use12Hours={true}
                onSelect={handleFieldChange}
                onChange={handleFieldChange}
                format={format}
                showTime={{format: format}}
                minuteStep={5}
                getPopupContainer={(trigger) => trigger.parentNode}
                className={`w-100 ${props.className}`}
                status={props.error ? "error" : null}
            />
        </CustomFieldWrapper>
    );
};
