import React from "react";

export const RefundIcon = (props) => {
    return (
        <img
            src={require("../../assets/icon/refund-icon.png")}
            style={{width: props.width || 22}}
            className={`me-1 ${props.className}`}
        />
    );
};

export const InfoIconGreenFilled = (props) => {
    return (
        <img
            src={require("../../assets/icon/INFO_ICON_1GG.png")}
            style={{width: props.width || 22}}
            className={`me-1 ${props.className}`}
        />
    );
};

export const InfoIconWarningFilled = (props) => {
    return (
        <img
            src={require("../../assets/icon/INFO_ICON_1OR.png")}
            style={{width: props.width || 15}}
        />
    );
};


export const InfoIconWashedGreenOutline = (props) => {
    return (
        <img
            src={require("../../assets/icon/INFO_ICON_2G.png")}
            style={{width: props.width || 22}}
            className={`me-1 ${props.className}`}
        />
    );
};

export const InfoIconWashedGreenFilled = (props) => {
    return (
        <img
            src={require("../../assets/icon/INFO_ICON_1G.png")}
            style={{width: props.width || 22}}
            className={`me-1 ${props.className}`}
        />
    );
};

export const InfoIconGreenOutline = (props) => {
    return (
        <img
            src={require("../../assets/icon/INFO_ICON_2GG.png")}
            style={{width: props.width || 22}}
            className={`me-1 ${props.className}`}
        />
    );
};


export const InfoIconGreyFilled = (props) => {
    return (
        <img
            src={require("../../assets/icon/INFO_ICON_1B.png")}
            style={{width: props.width || 22}}
            className={`me-1 ${props.className}`}
        />
    );
};

export const InfoIconGreyOutline = (props) => {
    return (
        <img
            src={require("../../assets/icon/INFO_ICON_2B.png")}
            style={{width: props.width || 22}}
            className={`me-1 ${props.className}`}
        />
    );
};

export const VoucherIcon = (props) => {
    return (
        <img
            {...props}
            src={require("../../assets/icon/voucher_icon.png")}
            style={{width: props.width || 22}}
            className={`me-1 ${props.className}`}
        />
    );
};

export const PackageIcon = (props) => {
    return (
        <img
            {...props}
            src={require("../../assets/icon/package_icon.png")}
            style={{width: props.width || 22}}
            className={`me-1 ${props.className}`}
        />
    );
};

export const ListIcon = (props) => {
    return (
        <img
            {...props}
            src={require("../../assets/icon/list_icon.png")}
            style={{width: props.width || 22}}
            className={`me-1 ${props.className}`}
        />
    );
};

export const CategoryIcon = (props) => {
    return (
        <img
            {...props}
            src={require("../../assets/icon/category_icon.png")}
            style={{width: props.width || 22}}
            className={`me-1 ${props.className}`}
        />
    );
};

export const AddonIcon = (props) => {
    return (
        <img
            {...props}
            src={require("../../assets/icon/addon_icon.png")}
            style={{width: props.width || 22}}
            className={`me-1 ${props.className}`}
        />
    );
};
export const AnalyticsIcon = (props) => {
    return (
        <img
            {...props}
            src={require("../../assets/icon/analytics_icon.png")}
            style={{width: props.width || 22}}
            className={`me-1 ${props.className}`}
        />
    );
};

export const ProductIcon = (props) => {
    return (
        <img
            {...props}
            src={require("../../assets/icon/product_icon.png")}
            style={{width: props.width || 22}}
            className={`me-1 ${props.className}`}
        />
    );
};

export const AgencyIcon = (props) => {
    return (
        <img
            {...props}
            src={require("../../assets/icon/agency_icon.png")}
            style={{width: props.width || 22}}
            className={`me-1 ${props.className}`}
        />
    );
};

export const CustomerIcon = (props) => {
    return (
        <img
            {...props}
            src={require("../../assets/icon/customer_icon.png")}
            style={{width: props.width || 22}}
            className={`me-1 ${props.className}`}
        />
    );
};

export const PaperIcon = (props) => {
    return (
        <img
            {...props}
            src={require("../../assets/icon/paper_icon.png")}
            style={{width: props.width || 22}}
            className={`me-1 ${props.className}`}
        />
    );
};

export const BookingIcon = (props) => {
    return (
        <img
            {...props}
            src={require("../../assets/icon/booking_icon.png")}
            style={{width: props.width || 22}}
            className={`me-1 ${props.className}`}
        />
    );
};

export const ProductSalesIcon = (props) => {
    return (
        <img
            {...props}
            src={require("../../assets/icon/product_sales_icon.png")}
            style={{width: props.width || 22}}
            className={`me-1 ${props.className}`}
        />
    );
};


export const ConsultationIcon = (props) => {
    return (
        <img
            {...props}
            src={require("../../assets/icon/consultation_icon.png")}
            style={{width: props.width || 22}}
            className={`me-1 ${props.className}`}
        />
    );
};


export const FinanceIcon = (props) => {
    return (
        <img
            {...props}
            src={require("../../assets/icon/finance_icon.png")}
            style={{width: props.width || 22}}
            className={`me-1 ${props.className}`}
        />
    );
};

export const BookingFinanceIcon = (props) => {
    return (
        <img
            {...props}
            src={require("../../assets/icon/booking_finance_icon.png")}
            style={{width: props.width || 22}}
            className={`me-1 ${props.className}`}
        />
    );
};

export const ServiceChargeIcon = (props) => {
    return (
        <img
            {...props}
            src={require("../../assets/icon/service_charge_icon.png")}
            style={{width: props.width || 22}}
            className={`me-1 ${props.className}`}
        />
    );
};


export const ConversionRateIcon = (props) => {
    return (
        <img
            {...props}
            src={require("../../assets/icon/conversion_rate_icon.png")}
            style={{width: props.width || 22}}
            className={`me-1 ${props.className}`}
        />
    );
};


export const PaymentMethodIcon = (props) => {
    return (
        <img
            {...props}
            src={require("../../assets/icon/payment_method_icon.png")}
            style={{width: props.width || 22}}
            className={`me-1 ${props.className}`}
        />
    );
};


export const OnlinePaymentIcon = (props) => {
    return (
        <img
            {...props}
            src={require("../../assets/icon/online_payment_icon.png")}
            style={{width: props.width || 22}}
            className={`me-1 ${props.className}`}
        />
    );
};


export const AdvertisementsIcon = (props) => {
    return (
        <img
            {...props}
            src={require("../../assets/icon/advertisements_icon.png")}
            style={{width: props.width || 22}}
            className={`me-1 ${props.className}`}
        />
    );
};


export const MediaIcon = (props) => {
    return (
        <img
            {...props}
            src={require("../../assets/icon/media_icon.png")}
            style={{width: props.width || 22}}
            className={`me-1 ${props.className}`}
        />
    );
};


export const GlobalDataIcon = (props) => {
    return (
        <img
            {...props}
            src={require("../../assets/icon/global_data_icon.png")}
            style={{width: props.width || 22}}
            className={`me-1 ${props.className}`}
        />
    );
};


export const BlogIcon = (props) => {
    return (
        <img
            {...props}
            src={require("../../assets/icon/blog_icon.png")}
            style={{width: props.width || 22}}
            className={`me-1 ${props.className}`}
        />
    );
};


export const TestimonialIcon = (props) => {
    return (
        <img
            {...props}
            src={require("../../assets/icon/testimonial_icon.png")}
            style={{width: props.width || 22}}
            className={`me-1 ${props.className}`}
        />
    );
};
