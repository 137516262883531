import React, {useState} from "react";
import {CustomSteps} from "../../global/components/CustomSteps";
import {Alert, Button, Col, Row} from "antd";
import {
    isValidEmail,
    isValidPhoneNumber,
    isValidValue,
    openNotification,
    showConfirm
} from "../../global/helper_functions";
import {BookingInfo} from "./components/add_booking_steps_components/BookingInfo";
import Hide from "../../global/components/Hide";
import {BookingItems} from "./components/add_booking_steps_components/BookingItems";
import {BookingOverview} from "./components/add_booking_steps_components/BookingOverview";
import {httpRequestWithAuth} from "../../global/axios_instance";
import {BookingsPOSURL} from "../../global/urls";

const steps = ["Booking Info", "Booking Items", "Overview"];


export const AddBooking = (props) => {
    const slug = props.slug || "";
    const [current_step, setCurrentStep] = useState(0);
    const [booking_data, setBookingData] = useState({
        booking_type: "Experience",
        add_service_charge: true,
        send_payment_link: true,
    });
    const [items_data, setItemsData] = useState([]);
    const [helper_text, setHelperText] = useState({});
    const [loading, setLoading] = useState(false);

    const handleBookingData = (name, value, bulk_data={}) => {
        const temp_booking_data = {...booking_data};
        temp_booking_data[name] = value;
        if (name === "booking_type"){
            setItemsData([]);
            if (value === "Travel"){
                temp_booking_data.currency = "KWD";
            }else{
                temp_booking_data.currency = "";
            }

            temp_booking_data.CountryId = "";
        }
        setBookingData({...temp_booking_data, ...bulk_data});
    }

    const getCustomerInfo = (customer) => {
        const temp_booking_data = {...booking_data};
        let temp_helper = {...helper_text};
        Object.keys(customer).map(data_key=>{
            const data_key_for_booking = `customer_${data_key}`;
            temp_booking_data[data_key_for_booking] = customer[data_key];

            if (isValidValue(customer[data_key])){
                delete temp_helper[data_key];
                delete temp_helper[data_key_for_booking];
            }
        })
        setHelperText(temp_helper);
        setBookingData(temp_booking_data);
    }


    const handleNextBtn = async () =>{
        if (current_step < steps.length -1){
            const check_data = await checkStepsData();

            if (check_data){
                setCurrentStep(current_step + 1);
            }

        }else{
            await submitBooking()
        }
    }


    const handleBackBtn = () =>{
        if (current_step === 0){
            showConfirm("Cancel Add Booking", "Are you sure to cancel add booking ?", onCancel)
        }else{
            setCurrentStep(current_step - 1);
        }
    }

    const onCancel = () => {
        props.history.push(`/${slug}/bookings`);
    }

    const checkFirstStepData = () =>{
        let temp_helper_text = {};

        if (!isValidValue(booking_data.customer_title)){
            temp_helper_text.customer_title = "this field is required";
        }

        if (!isValidValue(booking_data.customer_first_name)){
            temp_helper_text.customer_first_name = "this field is required";
        }

        if (!isValidValue(booking_data.customer_last_name)){
            temp_helper_text.customer_last_name = "this field is required";
        }

        if (!isValidValue(booking_data.customer_email)){
            temp_helper_text.customer_email = "this field is required";
        }else if (!isValidEmail(booking_data.customer_email)){
            temp_helper_text.customer_email = "not valid email";
        }

        if (!isValidValue(booking_data.customer_contact_number)){
            temp_helper_text.customer_contact_number = "this field is required";
        }else if(!isValidPhoneNumber(booking_data.customer_contact_number)){
            temp_helper_text.customer_contact_number = "not valid contact";
        }

        if (booking_data.booking_type === "Experience") {
            if (!isValidValue(booking_data.ExperienceCountryId)) {
                temp_helper_text.ExperienceCountryId = "this field is required";
            }
        }

        setHelperText(temp_helper_text);

        return Object.keys(temp_helper_text).length === 0;
    }


    const checkPersonData = (person_data) =>{
            let errors = {};


        if (!isValidValue(person_data.email)){
            errors.email = "this field is required";
        }

        if (!isValidValue(person_data.contact_number)){
            errors.contact_number = "this field is required";
        }

        if (!isValidValue(person_data.title)){
            errors.title = "this field is required";
        }

        if (!isValidValue(person_data.first_name)){
            errors.first_name = "this field is required";
        }

        if (!isValidValue(person_data.last_name)){
            errors.last_name = "this field is required";
        }

        if (!isValidValue(person_data.nationality)){
            errors.nationality = "this field is required";
        }

        if (!isValidValue(person_data.birthdate)){
            errors.birthdate = "this field is required";
        }

        if (!isValidValue(person_data.country_of_residence)){
            errors.country_of_residence = "this field is required";
        }

        if (!isValidValue(person_data.full_name_in_passport)){
            errors.full_name_in_passport = "this field is required";
        }

        if (!isValidValue(person_data.passport_number)){
            errors.passport_number = "this field is required";
        }

        if (!isValidValue(person_data.full_address)){
            errors.full_address = "this field is required";
        }

        if (!isValidValue(person_data.passport_copy)){
            errors.passport_copy = "this field is required";
        }

        if (Object.keys(errors).length > 0){
            errors.non_field_errors = "Please Fill All required field"
        }

        return errors

    }



    const checkSecondStepData = () =>{
        let no_error = true
        let temp_helper_text = {};

        if (items_data.length === 0){
            temp_helper_text.non_field_errors = "Booking can't be with items";
            no_error = false;
        }

        let  temp_item = [...items_data];

        if (booking_data.booking_type === "Travel"){

        temp_item.map((item, index)=>{
            let person_error = false;
            item.persons.map((person, index)=>{
                const check_person_data = checkPersonData(person);
                if (Object.keys(check_person_data).length > 0){
                    no_error = false;
                    person_error = true
                }
                person.helper_text = check_person_data;
            })


            if (person_error){
                temp_helper_text.non_field_errors = "Please fill all persons information";
            }
        })

        }

        setHelperText(temp_helper_text);
        setItemsData(temp_item);

        return no_error;
    }

    const checkStepsData = async () =>{
        let is_valid_data = false;

        if (current_step === 0){
            is_valid_data = checkFirstStepData();
        }

        if (current_step === 1){
            is_valid_data = checkSecondStepData();
        }

        return is_valid_data
    }


    const submitBooking = async () =>{
        setLoading(true);
        setHelperText({});

        const booking_items = [...items_data];


        const data = {
            ...booking_data,
            booking_items
        }

        let response = await httpRequestWithAuth(BookingsPOSURL, "POST", data);

        if (response.status){
            openNotification("success", "Add New Booking", `${response.data.ref_no} booking has been added`)
            onCancel();
        }else {
            setHelperText(response.error_feedback);
        }
        setLoading(false);
    }

    return (
        <>
            <div className="m-0 p-0 d-flex justify-content-between">
                <h5 className="mb-3 mt-2">Add New Booking</h5>
            </div>

            <div className="paper w-100">
                <CustomSteps current={current_step} steps={steps} className="pt-2 pb-2"/>


            <Row gutter={[12, 10]} className="p-2 h-auto">

                <Hide hide={current_step !== 0}>
                    <BookingInfo
                        booking_data={booking_data}
                        handleBookingData={handleBookingData}
                        helper_text={helper_text}
                        getCustomerInfo={getCustomerInfo}
                    />
                </Hide>

                <Hide hide={current_step !== 1}>
                    <BookingItems
                        booking_data={booking_data}
                        items_data={items_data}
                        setItemsData={setItemsData}
                    />
                </Hide>

                <Hide hide={current_step !== 2}>
                    <BookingOverview
                        booking_data={booking_data}
                        setBookingData={setBookingData}
                        items_data={items_data}
                    />
                </Hide>

                    <Hide hide={!isValidValue(helper_text.non_field_errors)}>
                        <Col xs={24} className="mb-2">
                            <Alert
                                className="text-danger pre-line"
                                banner
                                description={helper_text.non_field_errors}
                                type="error"
                                showIcon
                            />
                        </Col>
                    </Hide>

                <Col span={24} className="d-inline-flex mt-4">

                    <div className="ms-auto"/>

                    <Button
                        className="font-size-14 me-2"
                        onClick={handleBackBtn}
                        disabled={loading}
                    >
                        {current_step === 0?"Cancel": "Back"}
                    </Button>

                    <Button
                        type="primary"
                        className="font-size-14"
                        onClick={handleNextBtn}
                        loading={loading}
                    >
                        {current_step === steps.length -1?"Submit": "Next"}
                    </Button>
                </Col>
            </Row>


                {/*<Col xs={24}>*/}
                {/*    <ActionButton*/}
                {/*        error_msg={helper_text.non_field_errors}*/}
                {/*        onCancel={onCancel}*/}
                {/*        cancelLabel="Back"*/}
                {/*        allow_delete={isValidValue(data.id) && checkPermission(permission_data.package.delete)}*/}
                {/*        allow_save={has_perm}*/}
                {/*        onSave={onSave}*/}
                {/*    />*/}
                {/*</Col>*/}
            </div>

        </>
    )
}
