import {CustomModelDraggable} from "../../../../global/components/CustomModelDraggable";


export const HowToEditPackage = props => {
    return (
        <CustomModelDraggable
            open={props.open}
            onCancel={props.handleModelClose}
            title="How To Edit Package?"
            width={800}
        >

            <p>
                - You can change any details in your package, don't forget to click <b>Save</b> before moving to the next tab! <br/><br/>
            - The changes you made will be under approval. Once approved, the changes will be applied. <br/><br/>
            - You can view the changes you made in the "Pending Changes" Tab. <br/><br/>
            - You can update the following details without Hello Explorer permission:  <br/>
                <label className="ms-3">• <span className="text-success">• Active</span> to <span className="text-danger">Inactive</span></label><br/>
                <label className="ms-3">• <span className="text-success">• Available</span> to <span className="text-danger">Sold Out</span></label><br/><br/>


                - If you can edit your content without Hello Explorer assistance and you have met the criteria given, you can ask the Hello Explorer Team to give you permission to edit your package directly without the approval process. <br/><br/>


            </p>

            {/*<p>*/}
            {/*    - In case package still pending, changes will applied direct. <br/><br/>*/}
            {/*    - In case package is approved, Admins will view your changes then will approve or reject it (you can see*/}
            {/*    changes result in "Pending Changes").<br/><br/>*/}

            {/*    - In case package is approved and same field change twice before approved first change, first change*/}
            {/*    will be rejected automatically.<br/><br/>*/}

            {/*    - You can change package from active to inactive without admin approval.<br/><br/>*/}

            {/*    - If you are expert in content you can ask admins to make chnages in packages without approval from*/}
            {/*    them.<br/><br/>*/}
            {/*</p>*/}

        </CustomModelDraggable>
    )
}
