import Hide from "../../../global/components/Hide";
import {Col, Row, Tabs} from "antd";
import {Loading} from "../../../global/components/Loading";
import React, {useState} from "react";
import CustomModal from "../../../global/components/CustomModal";
import {ViewInfo} from "../../../global/components/ViewInfo";
import {isValidValue, openNotification} from "../../../global/helper_functions";
import {CustomTabPane} from "../../../global/components/CustomTabPane";
import {CustomerAutoComplete} from "../../../global/components/fields/CustomerAutoComplete";
import {CustomTextField} from "../../../global/components/fields/CustomTextField";
import {CustomPhoneNumberField} from "../../../global/components/fields/CustomPhoneNumberField";
import CustomSelectField from "../../../global/components/fields/CustomSelectField";
import {title_options} from "../../../global/variables";
import {ModelActionButton} from "../../../global/components/ModelActionButton";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {BookingsUpdateCustomerInfoURL} from "../../../global/urls";

const BookingsUpdateCustomerInfoModal = (props) => {
    const booking = props.booking || "";
    const [current_tab, setCurrentTab] = useState(isValidValue(props.data.CustomerId)? "2": "1");
    const [data, setData] = useState({...props.data,});
    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});


    const handleTabsChange = (new_value, extra_params = {}) => {
        new_value = new_value + "";
        setData({});
        setCurrentTab(new_value);
    };

    const onChange = (name, value) => {
        let temp_data = {...data};
        temp_data[name] = value;
        setData(temp_data)
    };

    const getCustomerInfo = (customer_obj) => {
        if (!isValidValue(customer_obj.id)){
            setData({});
        }else{
            setData({
                CustomerId: customer_obj.id,
                customer_title: customer_obj.title,
                customer_first_name: customer_obj.first_name,
                customer_last_name: customer_obj.last_name,
                customer_contact_number: customer_obj.contact_number,
                customer_email: customer_obj.email ,
            })
        }
    };

    const onSave = async () => {
      setLoading(true);
      setHelperText({});

      let response = await httpRequestWithAuth(`${BookingsUpdateCustomerInfoURL}${booking}`, "PATCH", data);

      if (response.status){
          openNotification("success", "Update Customer Info", `Customer Info has been updated`)
          props.handleModelClose();
      }else{
          setHelperText(true)
      }

      setLoading(false)
    }


    return (<CustomModal title={`Booking Receipt #${props.data.ref_no}: Update Customer Info.`} paddingTop="0"
                         visible={props.open} onCancel={props.handleModelClose}>


        <Row gutter={[12, 10]}>
            <Hide hide={!loading}>
                <Col xs={24}>
                    <Loading/>
                </Col>
            </Hide>


            <Hide hide={loading}>

                <Col xs={24}>
                    <Tabs activeKey={current_tab} onChange={handleTabsChange}>
                        <CustomTabPane tab="Guest Customer" key="1"/>
                        <CustomTabPane tab="Exists Customer" key="2"/>
                    </Tabs>
                </Col>

                <Hide hide={current_tab !== "1"}>
                    <Col xs={24} md={12}>
                        <CustomTextField
                            label="Email"
                            name="customer_email"
                            value={data.customer_email}
                            error={helper_text.customer_email !== undefined}
                            helper_text={helper_text.customer_email }
                            onChange={onChange}
                        />
                    </Col>

                    <Col xs={24} md={12}>
                        <CustomPhoneNumberField
                            label="Contact Number"
                            name="customer_contact_number"
                            value={data.customer_contact_number}
                            error={helper_text.customer_contact_number !== undefined}
                            helper_text={helper_text.customer_contact_number }
                            onChange={onChange}
                        />
                    </Col>

                    <Col xs={24} md={4}>
                        <CustomSelectField
                            label="Title"
                            name="customer_title"
                            value={data.customer_title}
                            onChange={onChange}
                            error={helper_text.customer_title !== undefined}
                            helper_text={helper_text.customer_title}
                            options={title_options}
                        />
                    </Col>

                    <Col xs={24} md={10}>
                        <CustomTextField
                            label="First Name"
                            name="customer_first_name"
                            value={data.customer_first_name}
                            error={helper_text.customer_first_name !== undefined}
                            helper_text={helper_text.customer_first_name}
                            onChange={onChange}
                        />
                    </Col>

                    <Col xs={24} md={10}>
                        <CustomTextField
                            label="Last Name"
                            name="customer_last_name"
                            value={data.customer_last_name}
                            error={helper_text.customer_last_name !== undefined}
                            helper_text={helper_text.customer_last_name }
                            onChange={onChange}
                        />
                    </Col>
                </Hide>


                <Hide hide={current_tab !== "2"}>

                <Col xs={24}>
                    <CustomerAutoComplete
                        label="Customer"
                        name="CustomerId"
                        value={data.CustomerId}
                        error={helper_text.CustomerId !== undefined}
                        helper_text={helper_text.CustomerId}
                        onChange={onChange}
                        getFullObj={getCustomerInfo}
                    />
                </Col>

                <Col xs={24} sm={14} md={8}>
                    <ViewInfo
                        title="Customer Title"
                        value={data.customer_title || "-----"}
                    />
                </Col>

                <Col xs={24} sm={14} md={8}>
                    <ViewInfo
                        title="Customer First Name"
                        value={data.customer_first_name || "-----"}
                    />
                </Col>

                <Col xs={24} sm={14} md={8}>
                    <ViewInfo
                        title="Customer Last Name"
                        value={data.customer_last_name || "-----"}
                    />
                </Col>

                <Col xs={24} sm={14} md={8}>
                    <ViewInfo
                        title="Customer Email"
                        value={data.customer_email || "-----"}
                    />
                </Col>

                <Col xs={24} sm={14} md={8}>
                    <ViewInfo
                        title="Customer Contact Number"
                        value={data.customer_contact_number || "-----"}
                    />
                </Col>

                </Hide>



                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Hide>


        </Row>
    </CustomModal>
    )
}

export default BookingsUpdateCustomerInfoModal;
