import {Col, Row} from "antd";
import Logo from "../../assets/img/he_logo_primary.png"
import ShortLogoFilled from "../../assets/img/short_logo_white.png"
import ShortLogoOutLined from "../../assets/img/short_logo.png"


import {useEffect, useState} from "react";
import Hide from "./Hide";

export const HELoading = props =>{
    const [loading_to, setLoadingTo] = useState(0);

    useEffect(()=>{
        setTimeout(()=>{
            setLoadingTo((loading_to + 1)%5)
        }, 250)
    },[loading_to])

    return(
        <div className="justify-content-center d-flex">
        <Row align="middle" justify="center" className={`${props.full_page?"vw-100 vh-100 position-absolute z-index-100 top-0 start-0 bg-white":props.full_container?"w-75 h-100 top-0 position-absolute z-index-2 bg-white": "w-100 pt-5 pb-5"} `}>
            <Col xs={20} sm={18} md={12} lg={8} className={`${!props.full_page?" pb-5":""} text-center`}>
                <Hide hide={props.hide_logo}>
                <img src={Logo} className="w-100"/>
                </Hide>

                <div className="mt-3">
                <img className="me-5" width={15} src={loading_to > 0?ShortLogoFilled: ShortLogoOutLined}/>
                <img className="me-5" width={15} src={loading_to > 1?ShortLogoFilled: ShortLogoOutLined}/>
                <img className="me-5" width={15} src={loading_to > 2?ShortLogoFilled: ShortLogoOutLined}/>
                <img width={15} src={loading_to > 3?ShortLogoFilled: ShortLogoOutLined}/>
                </div>

            </Col>

        </Row>
        </div>

    )
}