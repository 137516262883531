import {CustomModelDraggable} from "../../../../global/components/CustomModelDraggable";
import CustomCheckBox from "../../../../global/components/fields/CustomCheckBox";
import {Button, Divider} from "antd";


export const HowToAddTimesToDateOption = props =>{
    return(
        <CustomModelDraggable
        open={props.open}
        onCancel={props.handleModelClose}
        title="How To Add Time to the Date"
        width={600}
        >

            <p className="m-0">
                <b>There are 2 ways to add time(s) to the date(s).</b><br/><br/>
                {/*<div className="d-flex">*/}
                {/*    First, make sure that you have checked the <CustomCheckBox label={<b>Has Specific Time</b>} className="ms-1 w-auto" value={true} view_only/> for date(s).*/}
                {/*</div><br/>*/}

                <b>1st: While Creating The Date</b><br/><br/>
                <div className="ms-2">
                    1) Check the <CustomCheckBox label={<b>Has Specific Time</b>} container_class_name="d-inline-flex" className="ms-1 w-auto" value={true} view_only/> <br/><br/>
                2) A time section will appear, fill it in.<br/><br/>
                3) Click Save.
                </div>

                <Divider/>

                <b>2nd: When Date is Already Added</b><br/><br/>
                <div className="ms-2">
                1) Go to Actions on the left hand side, click on Edit. <br/><br/>
                    2) Check the <CustomCheckBox label={<b>Has Specific Time</b>} container_class_name="d-inline-flex" className="ms-1 w-auto" value={true} view_only/> and Save. <br/><br/>
                    3) In the date row <Button size="small">View Times</Button> Button appeared. Click it.<br/><br/>
                    4) Click <b> Add Time </b> and fill the form.<br/><br/>
                    5) Click on Create.<br/><br/>
                </div>

                <Divider/>

            </p>


            {/*<p>*/}
            {/*    apply this steps to add times for date. <br/><br/>*/}
            {/*    <label className="ms-2">*/}
            {/*        - Click on edit for date which need to add times on it <br/><br/>*/}
            {/*        - Will show modal for you contain date details, make sure has "Has Specific Time" is marked<br/><br/>*/}
            {/*        - Then Save date details <br/><br/>*/}
            {/*        - On same row of date in Times column, will find "View Times" button, click it <br/><br/>*/}
            {/*        - Modal will appear with "Add Time" on top right, click it <br/><br/>*/}
            {/*        - Change time details as need, then click "Create" button<br/><br/>*/}

            {/*    </label>*/}

            {/*</p>*/}
        </CustomModelDraggable>
    )
}
