import {Col, Row} from "react-bootstrap";
import Hide from "./Hide";
import {isValidValue} from "../helper_functions";

export const ViewInfo = props =>{

    return(
        <div className="w-100 break-line">
            <div className={`font-size-13 fw-bold text-dots mt-0 mb-0  ${props.text_color_class?props.text_color_class:"text-black-50"}`}>
                {props.title}

                <Hide hide={!isValidValue(props.helper_text)}>
                <span className={`m-0 ms-1 p-0 ${props.error ? "text-danger" : "text-black-50"} font-size-12`}>
                        ({props.helper_text})
                 </span>
                </Hide>

            </div>

            <p className="font-size-15 fw-bold ms-2 me-2  mt-0 text-dots-two-line">
                {props.value || "-----"} {props.addonAfter || ""}
            </p>
        </div>
    )
}
