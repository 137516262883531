import React, {useState} from "react";
import Hide from "../../../../global/components/Hide";
import {HowToAddPackage} from "../../../guidelines/global_components/packages/how_to_add_package";
import {Button} from "antd";
import {HowToEditPackage} from "../../../guidelines/global_components/packages/how_to_edit_package";
import {HowToIncreasePackageViews} from "../../../guidelines/global_components/packages/how_to_increase_package_views";
import {WhatIsPackageGallery} from "../../../guidelines/global_components/packages/what_is_package_gallery";
import {WhatIsPackageOptions} from "../../../guidelines/global_components/packages/what_is_package_options";
import {HowToAddDatesToOption} from "../../../guidelines/global_components/packages/how_to_add_dates_to_option";
import {WhatIsPackageInstallment} from "../../../guidelines/global_components/packages/what_is_package_installment";
import {HowToAddInstallment} from "../../../guidelines/global_components/packages/how_to_add_installment";

export const PackageGuidelines = props => {
    const tab = props.tab;
    const is_new = props.is_new;
    const [guidelines_model, setGuidelinesModel] = useState({});

    const handleGuidelinesModel = (name) => {
        let temp_guideline = {...guidelines_model};

        if (temp_guideline[name]) {
            delete temp_guideline[name]
        } else {
            temp_guideline[name] = true
        }

        setGuidelinesModel(temp_guideline)
    }
    return (
        <div className="text-sm-end text-center">

            <Hide hide={!guidelines_model.how_to_add_package}>
                <HowToAddPackage
                    handleModelClose={() => handleGuidelinesModel("how_to_add_package")}
                    open={guidelines_model.how_to_add_package}
                />
            </Hide>

            <Hide hide={!guidelines_model.how_to_edit_package}>
                <HowToEditPackage
                    handleModelClose={() => handleGuidelinesModel("how_to_edit_package")}
                    open={guidelines_model.how_to_edit_package}
                />
            </Hide>

            <Hide hide={!guidelines_model.how_to_increase_package_views}>
                <HowToIncreasePackageViews
                    handleModelClose={() => handleGuidelinesModel("how_to_increase_package_views")}
                    open={guidelines_model.how_to_increase_package_views}
                />
            </Hide>

            <Hide hide={!guidelines_model.what_is_package_gallery}>
                <WhatIsPackageGallery
                    handleModelClose={() => handleGuidelinesModel("what_is_package_gallery")}
                    open={guidelines_model.what_is_package_gallery}
                />
            </Hide>

            <Hide hide={!guidelines_model.what_is_package_options}>
                <WhatIsPackageOptions
                    handleModelClose={() => handleGuidelinesModel("what_is_package_options")}
                    open={guidelines_model.what_is_package_options}
                />
            </Hide>

            <Hide hide={!guidelines_model.how_to_add_dates_to_option}>
                <HowToAddDatesToOption
                    handleModelClose={() => handleGuidelinesModel("how_to_add_dates_to_option")}
                    open={guidelines_model.how_to_add_dates_to_option}
                />
            </Hide>

            <Hide hide={!guidelines_model.what_is_package_installment}>
                <WhatIsPackageInstallment
                    handleModelClose={() => handleGuidelinesModel("what_is_package_installment")}
                    open={guidelines_model.what_is_package_installment}
                />
            </Hide>

            <Hide hide={!guidelines_model.how_to_add_installment}>
                <HowToAddInstallment
                    handleModelClose={() => handleGuidelinesModel("how_to_add_installment")}
                    open={guidelines_model.how_to_add_installment}
                />
            </Hide>



            <Hide hide={tab !== "1"}>

                <Hide hide={!is_new}>
                    <Button type="link" size="small" onClick={() => handleGuidelinesModel("how_to_add_package")}>
                        How to add package?
                    </Button>
                </Hide>

                <Hide hide={is_new}>
                    <Button type="link" size="small" onClick={() => handleGuidelinesModel("how_to_edit_package")}>
                        How to edit package?
                    </Button>

                    <Button type="link" size="small"
                            onClick={() => handleGuidelinesModel("how_to_increase_package_views")}>
                        Increase Package Views
                    </Button>
                </Hide>

            </Hide>



            <Hide hide={tab !== "2"}>
                <Button type="link" size="small" onClick={() => handleGuidelinesModel("what_is_package_gallery")}>
                    What is package gallery?
                </Button>
            </Hide>


            <Hide hide={tab !== "3"}>
                <Button type="link" size="small" onClick={() => handleGuidelinesModel("what_is_package_options")}>
                    What is package Options?
                </Button>

                <Button type="link" size="small" onClick={() => handleGuidelinesModel("how_to_add_dates_to_option")}>
                    Add dates to option?
                </Button>

            </Hide>

            <Hide hide={tab !== "4"}>
                <Button type="link" size="small" onClick={() => handleGuidelinesModel("what_is_package_installment")}>
                    What is package Installment?
                </Button>

                <Button type="link" size="small" onClick={() => handleGuidelinesModel("how_to_add_installment")}>
                    Add Installment?
                </Button>

            </Hide>


        </div>
    )
}
