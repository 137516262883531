import React, {useState} from 'react'
import {Button, Col, Row} from 'antd'
import {CustomDynamicTable} from '../../../global/components/CustomDynamicTable'
import {PackagesToursURL, RemoveToursFromPackagesURL} from '../../../global/urls'
import Hide from '../../../global/components/Hide'
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {openNotification, showConfirm} from "../../../global/helper_functions";
import {checkPermission} from "../../../global/permissions";
import {permission_data} from "../../../global/permissions_data";
import SelectToursModal from "./components/SelectToursModal";



const PackageTours = (props) => {

    const {package_id, package_data, language} = props;

    const [open_select_exists_tour_modal, setOpenSelectTourModal] = useState(false);
    const [reload_data, setReloadData] = useState(false);
    const [remove_loading, setRemoveLoading] = useState("");

    const [tour_perm] = useState({
        edit: checkPermission(permission_data.tour.edit),
        add: checkPermission(permission_data.tour.add),
    });

    const handelCloseFormData = (reload_data = false) => {
        setOpenSelectTourModal(false);

        if (reload_data) {
            setReloadData(Math.random());
        }

    }

    const freeAction = (
        <>
            <Hide hide={!tour_perm.edit}>
                <Button
                    onC onClick={()=>setOpenSelectTourModal(true)}
                    block
                    type="primary"
                    className="mb-1 m-md-1 justify-content-center"
                >
                    Select Tours
                </Button>
            </Hide>

                <Button
                    as="a"
                    href={package_data.customer_tours_link}
                    target="_blank"
                    block
                    type="primary"
                    className="mb-1 m-md-1 justify-content-center"
                >
                    Tours In Website
                </Button>

        </>
    );

    const removeTour = async id =>{
        setRemoveLoading(id);
        let response = await httpRequestWithAuth(RemoveToursFromPackagesURL, "PATCH", {package_id, tour_id: id})
        if (response.status){
            setReloadData(Math.random());
            openNotification("success", "Remove Tour", `Tour Removed Successfully`);
        }else{
            openNotification("error", "Remove Tour", response.error_feedback.non_field_errors);
        }
        setRemoveLoading("");
    };

    const handleRemoveTour = (id) => {
        showConfirm("Remove Tour", `Are you sure to remove this tour ?`, ()=>removeTour(id));
    }

    const columns = [
        {
            title: "Ref No",
            dataIndex: "ref_no",
            width: "10%",
        },
        {
            title: `Title ${language}`,
            dataIndex: `title_${language}`,
            width: "70%",
        },
        {
            title: "Price",
            dataIndex: "price",
            width: "20%",
        },
        {
            title: "Actions",
            dataIndex: "id",
            width: "15%",
            render: (value) => <Button onClick={()=>handleRemoveTour(value)} loading={remove_loading === value} className="font-size-14" size="small" danger type="primary">Remove</Button>
        },

    ];

    return (
        <Row>

            <Hide hide={!open_select_exists_tour_modal}>
                <SelectToursModal
                    open={open_select_exists_tour_modal}
                    handleModelClose={handelCloseFormData}
                    package_id={package_id}
                    language={language}
                />
            </Hide>


            <Col xs={24} className="paper pt-0">
                <CustomDynamicTable
                    title={
                        <label className="text-start">
                            Tours
                            {/*<br/>*/}
                            {/*<span className="font-size-13 text-black-50">*/}
                            {/*    The total rating will be updated within 3 hours after any modifications.*/}
                            {/*</span>*/}
                        </label>
                    }
                    columns={columns}
                    fetch_url={PackagesToursURL}
                    free_action={freeAction}
                    reload_data={reload_data}
                    custom_params={{package: package_id}}
                />
            </Col>
        </Row>
    )
}

export default PackageTours
