import {Col, Row} from "antd";
import FixBookingNumber from "./components/FixBookingNumber";
import {useState} from "react";
import PasswordModal from "./components/PasswordModal";
import Hide from "../../global/components/Hide";
import UpdateBookingItemDateTime from "./components/UpdateBookingItemDateTime";
import RemoveActivationToFromFuturePackages from "./components/RemoveActivationToFromFuturePackages";
import UpdateAndFixTravelBookingItemDetails from "./components/UpdateAndFixTravelBookingItemDetails";
import UpdateAndFixBookingFromItems from "./components/UpdateAndFixBookingFromItems";
import UpdateBookingItemsPaymentsAmount from "./components/UpdateBookingItemsPaymentsAmount";
import UpdatePackagesDescription from "./components/UpdatePackagesDescription";
import UpdatePackagesRemainingParticipantsNumber from "./components/UpdatePackagesRemainingParticipantsNumber";
import UpdateBookingServiceChargePaidDate from "./components/UpdateBookingServiceChargePaidDate";
import FixPackageOptionOrder from "./components/FixPackageOptionOrder";
import FixPackagesCustomRibbon from "./components/FixPackagesCustomRibbon";
import GetIncomingDetails from "./components/GetIncomingDetails";
import FixPackagesNearestDateSort from "./components/FixPackagesNearestDateSort";
import FixConsultationAudienceType from "./components/FixConsultationAudienceType";
import FixPackagesPriceSort from "./components/FixPackagesPriceSort";
import FixPackagesCategoriesOrder from "./components/FixPackagesCategoriesOrder";
import FixProductSalePrice from "./components/FixProductSalePrice";
import FixHEAgencyCommission from "./components/FixHEAgencyCommission";
import FillGuruFromPackageSettings from "./components/FillGuruFromPackageSettings";

const ITSupport = (props) => {
    const {slug} = props;
    const [data, setData] = useState({});
    const [show_password_modal, setShowPasswordModal] = useState(true);

    const handelClosePasswordModal = () =>{
            props.history.push(`/${slug}/dashboard`);
    }

    const onSavePasswordModal = (modal_data={}) =>{
        let temp_data = {...data, ...modal_data};
        setData(temp_data);
        setShowPasswordModal(false)
    }

    return (
        <>
            <Hide hide={!show_password_modal}>
                <PasswordModal
                    open={show_password_modal}
                    handleModelClose={handelClosePasswordModal}
                    onSave={onSavePasswordModal}
                />
            </Hide>

            <Row gutter={[12, 12]}>
                <Col xs={24} sm={12}>
                    <div className="p-3">
                    <UpdateBookingItemDateTime it_support_data={data}/>
                    </div>
                </Col>

                <Col xs={24} sm={12}>
                    <div className="p-3">
                    <FixBookingNumber it_support_data={data}/>
                    </div>
                </Col>

                <Col xs={24} sm={12}>
                    <div className="p-3">
                    <RemoveActivationToFromFuturePackages it_support_data={data}/>
                    </div>
                </Col>

                <Col xs={24} sm={12}>
                    <div className="p-3">
                    <UpdateAndFixTravelBookingItemDetails it_support_data={data}/>
                    </div>
                </Col>

                <Col xs={24} sm={12}>
                    <div className="p-3">
                    <UpdateAndFixBookingFromItems it_support_data={data}/>
                    </div>
                </Col>

                <Col xs={24} sm={12}>
                    <div className="p-3">
                    <UpdateBookingItemsPaymentsAmount it_support_data={data}/>
                    </div>
                </Col>

                <Col xs={24} sm={12}>
                    <div className="p-3">
                    <UpdatePackagesDescription it_support_data={data}/>
                    </div>
                </Col>

                <Col xs={24} sm={12}>
                    <div className="p-3">
                    <UpdatePackagesRemainingParticipantsNumber it_support_data={data}/>
                    </div>
                </Col>

                <Col xs={24} sm={12}>
                    <div className="p-3">
                    <UpdateBookingServiceChargePaidDate it_support_data={data}/>
                    </div>
                </Col>

                <Col xs={24} sm={12}>
                    <div className="p-3">
                    <FixPackageOptionOrder it_support_data={data}/>
                    </div>
                </Col>

                <Col xs={24} sm={12}>
                    <div className="p-3">
                    <FixPackagesCustomRibbon it_support_data={data}/>
                    </div>
                </Col>

                <Col xs={24} sm={12}>
                    <div className="p-3">
                    <GetIncomingDetails it_support_data={data}/>
                    </div>
                </Col>

                <Col xs={24} sm={12}>
                    <div className="p-3">
                    <FixPackagesNearestDateSort it_support_data={data}/>
                    </div>
                </Col>

                <Col xs={24} sm={12}>
                    <div className="p-3">
                    <FixConsultationAudienceType it_support_data={data}/>
                    </div>
                </Col>

                <Col xs={24} sm={12}>
                    <div className="p-3">
                    <FixPackagesPriceSort it_support_data={data}/>
                    </div>
                </Col>

                <Col xs={24} sm={12}>
                    <div className="p-3">
                    <FixPackagesCategoriesOrder it_support_data={data}/>
                    </div>
                </Col>

                <Col xs={24} sm={12}>
                    <div className="p-3">
                    <FixProductSalePrice it_support_data={data}/>
                    </div>
                </Col>

                <Col xs={24} sm={12}>
                    <div className="p-3">
                    <FixHEAgencyCommission it_support_data={data}/>
                    </div>
                </Col>

                <Col xs={24} sm={12}>
                    <div className="p-3">
                    <FillGuruFromPackageSettings it_support_data={data}/>
                    </div>
                </Col>

            </Row>
        </>

    )
}


export default ITSupport;
