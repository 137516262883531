import React, {useState} from "react";
import {Button, Col, Row} from "antd";
import {ViewInfo} from "../../../../global/components/ViewInfo";
import CustomBadge from "../../../../global/components/CustomBadge";
import Hide from "../../../../global/components/Hide";
import {checkPermission} from "../../../../global/permissions";
import {permission_data} from "../../../../global/permissions_data";
import {Loading} from "../../../../global/components/Loading";
import {IsActiveView} from "../../../../global/components/IsActiveView";

const PackageRowMobileView = (props) => {
    const {slug, tab_view, package_id, data, reloadData} = props;
    const [loading, setLoading] = useState(false);
    const [package_perm] = useState({
        add: checkPermission(permission_data.package.add),
        edit: checkPermission(permission_data.package.edit),
        delete: checkPermission(permission_data.package.delete),
    });


    const onView = async () => {
        props.history.push(`/${slug}/packages/${data.id}`);
    }



    return(
      <Row gutter={[3,3]} className="mt-3 mb-3">
          <Hide hide={!loading}>
              <Loading/>
          </Hide>
          <Hide hide={loading}>
          <Col xs={24} className="text-dots-two-line">
              <h6 className="fw-bold">
                  <a href={`/${slug}/packages/${data.id}`} target="_blank">
                      {data.title_en}
                  </a>
              </h6>
          </Col>

              <Col xs={15}>
                  <ViewInfo
                      title="Ref No."
                      value={`#${data.ref_no}`}
                  />
              </Col>

              <Col xs={9}>
                  <ViewInfo
                      title="Type"
                      value={data.package_type}
                  />
              </Col>

          <Col xs={15}>
            <ViewInfo
            title="Agency"
            value={data.agency_name}
            />
          </Col>

          <Col xs={9}>
            <ViewInfo
            title="Status"
            value={<CustomBadge
                type={data.status !== "Approved" ? data.status === "Cancelled" ? "danger" : "disabled" : ""} title={data.status}/>}
            />
          </Col>




          <Col xs={15}>
              <ViewInfo
                  title="Bookings Number"
                  value={data.bookings_number + ""}
              />
          </Col>


          <Col xs={9}>
              <ViewInfo
                  title="Group Trip"
                  value={data.is_group_trip?"Yes":"No"}
              />
          </Col>

          <Col xs={15}>
              <ViewInfo
                  title="Availability"
                  value={<CustomBadge type={data.is_sold_out?"danger":""} title={data.is_sold_out?"Sold Out":"Available"} />}
              />
          </Col>


          <Col xs={9}>
              <ViewInfo
                  title="Activate"
                  value={<IsActiveView value={data.is_active} view_only={!package_perm.edit}/>}
              />
          </Col>


          <Col xs={24} className="pt-2">
              <Button onClick={onView} className="fw-bold border-2 main-color-border main-color font-size-14" size="middle" block> View Details</Button>
          </Col>
          </Hide>

      </Row>
  )
}


export default PackageRowMobileView;
