import {CustomModelDraggable} from "../../../../global/components/CustomModelDraggable";


export const HowToIncreasePackageViews = props => {
    return (
        <CustomModelDraggable
            open={props.open}
            onCancel={props.handleModelClose}
            title="How To Increase Package Views"
            width={800}
        >

            <p>
                - The title should describe what your package is about. <br/><br/>
                - Write your package in both English & Arabic to reach more customers. <br/><br/>
                - Make sure to select the proper categories for your package. <br/><br/>
                - Upload 5 square high-quality photos in the Gallery tab. <br/><br/>
                - Make sure to add the following: <br/>
                <label className="ms-3">• Cancellation Policy</label><br/>
                <label className="ms-3">• Inclusions & Exclusions</label><br/>
                <label className="ms-3">• Terms & Conditions</label><br/>
            </p>
            {/*<p>*/}
            {/*    - Need to make sure that you are write titles describe what package about. <br/><br/>*/}
            {/*    - Enter English & Arabic fields make your package reach to more customer and increase your sell. <br/><br/>*/}
            {/*    - Select correct categories from package -> setting that your package include it. <br/><br/>*/}
            {/*    - Upload at least 5 high quality photos in gallery for package (squared photo). <br/><br/>*/}
            {/*    - Fill all details in package. <br/><br/>*/}
            {/*</p>*/}

        </CustomModelDraggable>
    )
}
