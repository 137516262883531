import React, {useEffect, useState} from 'react';
import {CustomTextField} from "../../global/components/fields/CustomTextField";
import {agentSignIn, isAuthenticated, logout} from "../../global/auth_functions";
import Bg from "../../assets/img/bg_6.jpeg";
import {Avatar, Button, Col, Row} from "antd";
import {useParams} from "react-router-dom";
import Cookies from "js-cookie";
import Logo from "../../assets/img/he_logo_black.png";
import Hide from "../../global/components/Hide";
import {isValidValue} from "../../global/helper_functions";

export const AgentSignIn = props => {
    const params = useParams();
    const slug = params.slug || ""
    const [data, setData] = useState({});
    const [helper_text, setHelperText] = useState({});
    const [is_loading, setIsLoading] = useState(false);
    const agency_name = Cookies.get("agency_name");
    const agency_logo = Cookies.get("agency_logo") || "";
    const url_query = new URLSearchParams(props.location.search);
    const return_path = (url_query.get("returnPath") || "dashboard") + "";

    useEffect(() => {
        const checkAuth = async () => {
            const is_auth = await isAuthenticated();
            if (is_auth) {
                props.history.push(`/${slug}/${return_path}`);
            }else{
                logout()
            }
        }

        checkAuth();
        document.title = "Sigin";
    }, [])


    const handleFieldChange = (name, value) => {
        setData({...data, [name]: value});
    }


    const onSubmit = async (e) => {
        e.preventDefault();
        await setIsLoading(true);

        let response = await agentSignIn(data.email, data.password, slug);

        if (response.status) {
            props.history.push(`/${slug}/${return_path}`);
        } else {
            setHelperText(response.error_feedback)
        }

        await setIsLoading(false);
    }


    const forgetPassword = () =>{
        props.history.push(`/${slug}/forget-password`);
    }

    const signUpToAgency = () =>{
        props.history.push(`/${slug}/signup`);
    }

    return (
        <main className="vh-100 overflow-hidden">
        <Row className="h-100 overflow-auto">
            <Col xs={24} sm={15} md={10} lg={7} className="text-center p-4 pt-5">

                <Col xs={24} className="mt-5 pt-5 d-flex justify-content-center">
                    {/*<img src={Logo} className="w-100"/>*/}
                    <Col xs={21} sm={18} md={16} lg={10}>
                        <img src={Logo} className="w-100"/>
                        <Hide hide={isValidValue(agency_logo)}>
                            <h2 className="mt-5">{agency_name}</h2>
                        </Hide>
                    </Col>
                </Col>

                <Hide hide={!isValidValue(agency_logo)}>
                <Col xs={24} className="mt-4 d-flex justify-content-center mb-3">
                    {/*<img src={Logo} className="w-100"/>*/}
                    <Col xs={24}>
                        <Avatar src={agency_logo}  size={150}/>
                        <h2 className="mt-3">{agency_name}</h2>
                    </Col>
                </Col>
                </Hide>

                <span className="text-danger mt-2">
                            {helper_text.non_field_errors}
                </span>

                <form className="w-100" onSubmit={onSubmit}>


                    <Row gutter={[12, 12]}>

                        <Col xs={24}>
                            <CustomTextField
                                label="Email"
                                name="email"
                                value={data.email}
                                onChange={handleFieldChange}
                                helper_text={helper_text.email}
                                className="mt-2"
                            />
                        </Col>


                        <Col xs={24}>
                            <CustomTextField
                                label="Password"
                                name="password"
                                value={data.password}
                                onChange={handleFieldChange}
                                helper_text={helper_text.password}
                                type="password"
                                autoComplete="current-password"
                                className="mt-3"
                            />
                        </Col>

                    </Row>


                    <Button
                        block
                        loading={is_loading}
                        disabled={is_loading}
                        onClick={onSubmit}
                        type="primary"
                        className="bg-black mt-4"
                    >
                        Sign In
                    </Button>


                    <Button
                        onClick={forgetPassword}
                        type="link"
                        block
                        className="text-end mt-2"
                    >
                        Forget password?
                    </Button>


                    <Button
                        onClick={signUpToAgency}
                        type="link"
                        block
                        className="text-center mt-2"
                    >
                        Join Agency?
                    </Button>

                </form>
                <div className="mt-5"/>
            </Col>

            <Col xs={0} sm={9} md={14} lg={17} className="bg-white">
                <img src={Bg} className="w-100 vh-100 position-absolute "/>
            </Col>
        </Row>
        </main>

    );
}

