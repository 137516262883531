import React, {useEffect, useRef, useState} from "react";
import {checkPermission} from "../../global/permissions";
import {permission_data} from "../../global/permissions_data";
import {isValidValue, openNotification} from "../../global/helper_functions";
import {Col, Divider, Row, Tooltip} from "antd";
import Hide from "../../global/components/Hide";
import {getUserAgencyId} from "../../global/auth_functions";
import {httpRequestWithAuth} from "../../global/axios_instance";
import {AgenciesURL} from "../../global/urls";
import {Loading} from "../../global/components/Loading";
import {CustomTextField} from "../../global/components/fields/CustomTextField";
import {CustomSlugField} from "../../global/components/fields/CustomSlugField";
import {CustomPhoneNumberField} from "../../global/components/fields/CustomPhoneNumberField";
import {CountryAutoComplete} from "../../global/components/fields/CountryAutoComplete";
import {CustomUploadField} from "../../global/components/fields/CustomUploadField";
import CustomCheckBox from "../../global/components/fields/CustomCheckBox";
import {ActionButton} from "../../global/components/ActionButton";
import {PRIMARY_COLOR} from "../../global/variables";
import {InfoIconGreenFilled} from "../../global/components/Icons";


export const AgencyInfoForAgent = props => {
    const slug = props.slug || "";
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        has_travels: false,
        has_experiences: false,
    });
    const [helper_text, setHelperText] = useState({});
    const [edit_perm] = useState(checkPermission(permission_data.agency.edit));

    useEffect(() => {
            fetchData();
    }, []);


    const fetchData = async () =>{
        setLoading(true);
        const agency_id = getUserAgencyId();
        let response = await httpRequestWithAuth(AgenciesURL+ agency_id, "GET");

        if (response.status){
            setData(response.data);
        }else{
            onCancel();
        }
        setLoading(false);
    }

    const onCancel = () =>{
        props.history.push(`/${slug}/dashboard`);
    }

    const handleFieldChange = (name, value) => {
        let temp_data = {...data};
        temp_data[name] = value;


        setData(temp_data);
    }

    const onSave = async () =>{
        setLoading(true);
        const agency_id = getUserAgencyId();
        let response = await httpRequestWithAuth(AgenciesURL+ agency_id, "PUT", data);

        if (response.status){
            openNotification("success", "Update Agency", `Agency information has been updated`)
        }else{
            setHelperText(response.error_feedback);
        }
        setLoading(false);
    }

    return (
        <>
            <div className="paper w-100">
                <Col xs={24}>
                    <h2 className="font-size-22">
                        <label className="text-start">
                            Agency Information
                            <Hide hide={!edit_perm}>
                            <Tooltip
                                className="ms-1"
                                placement="topLeft"
                                title="You can edit 'Contact Email' & 'Contact Number', if any further information need to be updated please contact HelloExplorer Team"
                                color={PRIMARY_COLOR}
                                arrowPointAtCenter
                            >
                                                                   <span className="p-0 m-0">
                                                                       <InfoIconGreenFilled/>
                                                                   </span>
                            </Tooltip>
                            </Hide>
                        </label>
                    </h2>
                </Col>

                    <Hide hide={!loading}>
                        <Loading/>
                    </Hide>

                <Hide hide={loading}>

                    <Row gutter={[12, 10]}>


                        <Col xs={24} md={12}>
                            <CustomTextField
                                disabled
                                label="Agency Name"
                                name="name"
                                value={data.name}
                            />
                        </Col>


                        <Col xs={24} md={12}>
                            <CustomSlugField
                                disabled
                                label="Slug"
                                name="slug"
                                value={data.slug}
                            />
                        </Col>

                        <Col xs={24} md={12}>
                            <CustomTextField
                                label="Agency Contact E-mail"
                                name="agency_contact_email"
                                value={data.agency_contact_email}
                                onChange={handleFieldChange}
                                error={helper_text.agency_contact_email !== undefined}
                                helper_text={helper_text.agency_contact_email}
                                type="email"
                            />
                        </Col>


                        <Col xs={24} md={12}>
                            <CustomPhoneNumberField
                                label="Agency Contact No."
                                name="agency_contact_number"
                                value={data.agency_contact_number}
                                onChange={handleFieldChange}
                                error={helper_text.agency_contact_number !== undefined}
                                helper_text={helper_text.agency_contact_number}
                            />
                        </Col>

                        <Col xs={24}>
                            <CountryAutoComplete
                                disabled
                                multiple
                                label="Countries"
                                name="countries_id"
                                value={data.countries_id || []}
                            />
                        </Col>

                        <Col xs={24} md={12}>
                            <CustomUploadField
                                disabled
                                display_value={data.lata_license_copy_filename}
                                label="Lata License Copy"
                                name="lata_license_copy"
                                value={data.lata_license_copy}
                            />
                        </Col>



                        <Col xs={24} md={12}>
                            <CustomUploadField
                                disabled
                                display_value={data.company_license_copy_filename}
                                label="Company License Copy"
                                name="company_license_copy"
                                value={data.company_license_copy}
                            />
                        </Col>


                        <Col xs={24} className="mt-2">
                            <Divider className="mt-2 mb-3"/>
                            <h5 className="mb-0">Services</h5>
                        </Col>

                        <Col xs={24} sm={12}>
                            <CustomCheckBox
                                disabled
                                label="Has Experiences"
                                name="has_experiences"
                                value={data.has_experiences}
                            />
                        </Col>

                        <Col xs={24} sm={12}>
                            <CustomCheckBox
                                disabled
                                label="Has Travels"
                                name="has_travels"
                                value={data.has_travels}
                            />
                        </Col>


                        <Col xs={24}>
                            <Divider className="mt-0 pt-0"/>
                        </Col>

                        <Col xs={24} sm={12}>
                            <CustomCheckBox
                                disabled
                                label="Is Active"
                                name="is_active"
                                value={data.is_active}
                            />
                        </Col>

                        <Col xs={24} sm={12}>
                            <CustomCheckBox
                                disabled
                                label="Package Requires Approval"
                                name="package_requires_approval"
                                value={data.package_requires_approval}
                            />
                        </Col>



                        <Col xs={24}>
                            <ActionButton
                                error_msg={helper_text.non_field_errors}
                                allow_save={edit_perm}
                                onSave={onSave}
                            />
                        </Col>

                    </Row>
                </Hide>

            </div>

        </>)
}
