import React, {useState} from "react";
import {Alert, Button, Col, Divider, Row} from "antd";
import {CustomTextField} from "../../../../global/components/fields/CustomTextField";
import Hide from "../../../../global/components/Hide";
import {isValidValue, openNotification} from "../../../../global/helper_functions";
import CustomSelectField from "../../../../global/components/fields/CustomSelectField";
import {title_options} from "../../../../global/variables";
import {ViewInfo} from "../../../../global/components/ViewInfo";
import {EditFilled} from "@ant-design/icons";
import {CustomPhoneNumberField} from "../../../../global/components/fields/CustomPhoneNumberField";
import {CustomDateField} from "../../../../global/components/fields/CustomDateField";
import {CustomUploadField} from "../../../../global/components/fields/CustomUploadField";
import {ActionButton} from "../../../../global/components/ActionButton";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {BookingsPersonsURL} from "../../../../global/urls";
import {Loading} from "../../../../global/components/Loading";

export const BookingPersonRow = props => {
    const {is_agent, index} = props
    const [person_data, setPersonData] = useState(props.person_data);
    const [is_edit, setIsEdit] = useState(!props.person_data.id);
    const [helper_text, setHelperText] = useState({});
    const [loading, setLoading] = useState(false);

    const handleFieldChange = (name, value) => {
        const temp_data = {...person_data}
        temp_data[name] = value;

        if (["passport_copy"].includes(name)){
            temp_data[`${name}_filename`] = isValidValue(value)? value.name: "";
        }

        setPersonData(temp_data);
    }

    const onSave = async () => {
        setLoading(true);
        setHelperText({});

        const form_data = new FormData();
        Object.keys(person_data).map(data_key=>form_data.append(data_key, person_data[data_key]));

        let response;
        if (!isValidValue(person_data.id)){
            response = await httpRequestWithAuth(`${BookingsPersonsURL}`, "POST", form_data)
        }else{
            response = await httpRequestWithAuth(`${BookingsPersonsURL}${person_data.id}`, "PUt", form_data)
        }

        if (response.status) {
            props.handlePersonChange(index, response.data);
            setPersonData(response.data);
            setIsEdit(false);
            openNotification("success", "Update Booking Person", `${response.data.full_name} details has been updated`)
        } else {
            setHelperText(response.error_feedback)
        }

        setLoading(false);
    }

    const onCancel = () => {
        setIsEdit(false)
        setPersonData(props.person_data);
    };
    const view_body = (
        <Row gutter={[12, 10]} key={person_data.id}>

            <Col xs={24} md={8}>
                <ViewInfo
                    title="Name"
                    value={person_data.full_name || "-----"}
                />
            </Col>


            <Col xs={24} md={8}>
                <ViewInfo
                    title="Email"
                    value={person_data.email || "-----"}
                />
            </Col>


            <Col xs={24} md={8}>
                <ViewInfo
                    title="Contact Number"
                    value={person_data.contact_number_view || "-----"}
                />
            </Col>


            <Col xs={24} md={8}>
                <ViewInfo
                    title="Birthdate"
                    value={person_data.birthdate_view || "-----"}
                />
            </Col>


            <Col xs={24} md={8}>
                <ViewInfo
                    title="Nationality"
                    value={person_data.nationality || "-----"}
                />
            </Col>

            <Col xs={24} md={8}>
                <ViewInfo
                    title="Country Of Residence"
                    value={person_data.country_of_residence || "-----"}
                />
            </Col>



            <Col xs={24}/>


            <Col xs={24} md={8}>
                <ViewInfo
                    title="Passport Number"
                    value={person_data.passport_number || "-----"}
                />
            </Col>


            <Col xs={24} md={8}>
                <ViewInfo
                    title="Full Name In Passport"
                    value={person_data.full_name_in_passport || "-----"}
                />
            </Col>


            <Col xs={24} md={8}>
                <ViewInfo
                    title="Passport Copy"
                    value={(
                        <a href={person_data.display_passport_copy} target="_blank"><Button size="small"
                                                                                            className="font-size-13">View</Button></a>
                    )}
                />
            </Col>



            <Col xs={24} md={9}>
                <ViewInfo
                    title="Full Address"
                    value={person_data.full_address || "-----"}
                />
            </Col>


            <Col xs={24} className="text-end">

                <Button
                    size="small"
                    onClick={() => setIsEdit(true)}>
                    <EditFilled/>
                    Edit
                </Button>
            </Col>


            <Hide hide={!isValidValue(helper_text.non_field_errors)}>
                <Col xs={24}>
                    <Alert
                        className="text-danger"
                        banner
                        description={helper_text.non_field_errors}
                        type="error"
                    />
                </Col>
            </Hide>


            <Col xs={24} className="mb-3">
                <Divider className="m-0 border-2"/>
            </Col>
        </Row>
    )


    const body = (
        <Row gutter={[12, 10]} key={person_data.id}>

            <Hide hide={!loading}>
                <Loading/>
            </Hide>

            <Hide hide={loading}>

                <Col xs={24} md={12}>
                    <CustomTextField
                        required
                        label="Email"
                        name="email"
                        value={person_data.email}
                        onChange={(name, value) => handleFieldChange(name, value, index)}
                        error={helper_text.email !== undefined}
                        helper_text={helper_text.email}
                    />
                </Col>

                <Col xs={24} md={12}>
                    <CustomPhoneNumberField
                        label="Contact Number"
                        name="contact_number"
                        value={person_data.contact_number}
                        onChange={(name, value) => handleFieldChange(name, value, index)}
                        error={helper_text.contact_number !== undefined}
                        helper_text={helper_text.contact_number}
                    />
                </Col>


                <Col xs={24} md={5}>
                    <CustomSelectField
                        required
                        label="Title"
                        name="title"
                        value={person_data.title}
                        onChange={(name, value) => handleFieldChange(name, value, index)}
                        options={title_options}
                        error={helper_text.title !== undefined}
                        helper_text={helper_text.title}
                    />
                </Col>

                <Col xs={24} md={9}>
                    <CustomTextField
                        required
                        label="First Name"
                        name="first_name"
                        value={person_data.first_name}
                        onChange={(name, value) => handleFieldChange(name, value, index)}
                        error={helper_text.first_name !== undefined}
                        helper_text={helper_text.first_name}
                    />
                </Col>

                <Col xs={24} md={10}>
                    <CustomTextField
                        required
                        label="Last Name"
                        name="last_name"
                        value={person_data.last_name}
                        onChange={(name, value) => handleFieldChange(name, value, index)}
                        error={helper_text.last_name !== undefined}
                        helper_text={helper_text.last_name}
                    />
                </Col>


                <Col xs={24} md={5}>
                    <CustomDateField
                        required
                        open_on="year"
                        label="Birthdate"
                        name="birthdate"
                        value={person_data.birthdate}
                        onChange={(name, value) => handleFieldChange(name, value, index)}
                        error={helper_text.birthdate !== undefined}
                        helper_text={helper_text.birthdate}
                    />
                </Col>

                <Col xs={24} md={9}>
                    <CustomTextField
                        required
                        label="Nationality"
                        name="nationality"
                        value={person_data.nationality}
                        onChange={(name, value) => handleFieldChange(name, value, index)}
                        error={helper_text.nationality !== undefined}
                        helper_text={helper_text.nationality}
                    />
                </Col>


                <Col xs={24} md={10}>
                    <CustomTextField
                        required
                        label="Country Of Residence"
                        name="country_of_residence"
                        value={person_data.country_of_residence}
                        onChange={(name, value) => handleFieldChange(name, value, index)}
                        error={helper_text.country_of_residence !== undefined}
                        helper_text={helper_text.country_of_residence}
                    />
                </Col>


                <Col xs={24} md={5}>
                    <CustomTextField
                        required
                        label="Passport Number"
                        name="passport_number"
                        value={person_data.passport_number}
                        onChange={(name, value) => handleFieldChange(name, value, index)}
                        error={helper_text.passport_number !== undefined}
                        helper_text={helper_text.passport_number}
                    />
                </Col>


                <Col xs={24} md={9}>
                    <CustomTextField
                        required
                        label="Full Name In Passport"
                        name="full_name_in_passport"
                        value={person_data.full_name_in_passport}
                        onChange={(name, value) => handleFieldChange(name, value, index)}
                        error={helper_text.full_name_in_passport !== undefined}
                        helper_text={helper_text.full_name_in_passport}
                    />
                </Col>

                <Col xs={24} md={10}>
                    <CustomUploadField
                        required
                        display_value={person_data.passport_copy_filename}
                        label="Passport Copy"
                        name="passport_copy"
                        value={person_data.passport_copy}
                        onChange={(name, value) => handleFieldChange(name, value, index)}
                        error={helper_text.passport_copy !== undefined}
                        helper_text={helper_text.passport_copy}
                    />
                </Col>




                <Col xs={24}>
                    <CustomTextField
                        label="Full Address"
                        name="full_address"
                        value={person_data.full_address}
                        onChange={(name, value) => handleFieldChange(name, value, index)}
                        error={helper_text.full_address !== undefined}
                        helper_text={helper_text.full_address}
                    />
                </Col>


                <Col xs={24}>
                    <ActionButton
                        error_msg={helper_text.non_field_errors}
                        onCancel={onCancel}
                        allow_save
                        onSave={onSave}
                    />
                </Col>

            </Hide>

            <Col xs={24} className="mb-3">
                <Divider className="m-0 border-2"/>
            </Col>

        </Row>
    )

    return (
        <>
            <Hide hide={is_edit}>
                {view_body}
            </Hide>


            <Hide hide={!is_edit}>
                {body}
            </Hide>

        </>
    )

}
