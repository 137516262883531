import React, {useEffect, useState} from "react";
import {httpRequestWithAuth} from "../../global/axios_instance";
import {ToursURL} from "../../global/urls";
import {isValidValue, openNotification, showConfirm, showError, upperCaseView} from "../../global/helper_functions";
import {Alert, Col, Row} from "antd";
import {ActionButton} from "../../global/components/ActionButton";
import {CustomTextField} from "../../global/components/fields/CustomTextField";
import {permission_data} from "../../global/permissions_data";
import {checkPermission} from "../../global/permissions";
import {CustomTextAreaField} from "../../global/components/fields/CustomTextAreaField";
import {HELoading} from "../../global/components/HELoading";
import Hide from "../../global/components/Hide";
import CustomCheckBox from "../../global/components/fields/CustomCheckBox";
import ViewActionsDetails from "../../global/components/ViewActionsDetails";
import {CountryAutoComplete} from "../../global/components/fields/CountryAutoComplete";

export const TourDetails = props => {
    const getTourData = props.getTourData;
    const is_new = props.is_new;
    const tour_id = props.tour_id || "";
    const slug = props.slug || "";

    const lang = props.language || "en";
    const [has_perm] = useState(checkPermission(permission_data.tour.edit) || (tour_id === "new" && checkPermission(permission_data.tour.add)));
    const [data, setData] = useState({
        is_active: true,
        is_pinned: false,
    });

    const [loading, setLoading] = useState(true);
    const [helper_text, setHelperText] = useState({});

    const fetchData = async () => {
        let response = await getTourData();
        if (response.status) {
            setData(response.data);
            props.setTourData(response.data);
        } else {
            showError("Get Tour Data", response.error_feedback.non_field_errors);
            onCancel();
        }
        setLoading(false);
    }


    const handleFieldChange = (name, value) => {
        let temp_helper_text = {...helper_text};
        let temp_data = {...data};

        temp_data[name] = value;

        delete temp_helper_text[name];
        setHelperText(temp_helper_text);

        setData(temp_data);
    }


    useEffect(() => {
        if (is_new) {
            let temp_data = {...data};
            Object.keys(data).map(data_key => {
                if (data_key.includes("_en")) {
                    let lang_key = data_key.replace("_en", `_${lang}`);
                    if (!isValidValue(temp_data[lang_key])) {
                        temp_data[lang_key] = temp_data[data_key];
                    }
                }
            })
            setData(temp_data);
        }

    }, [lang])


    useEffect(() => {
        if (!is_new) {
            fetchData();
        } else {
            setLoading(false);
        }

    }, [])



    const onCancel = () => {
        props.history.push(`/${slug}/tours`);
    }


    const onDelete = async () => {
        await setLoading(true);
        let response = await httpRequestWithAuth(`${ToursURL}${data.id}`, "DELETE");
        if (response.status) {
            openNotification("success", "Delete Tour", `${data.title_en} tour has been deleted`)
            onCancel();
        } else {
            openNotification("error", "Delete Tour", response.error_feedback.non_field_errors)
        }
        await setLoading(false);
    }


    const handleDelete = () => {
        showConfirm("Delete Tour", `Are you sure to delete ${data.title_en} tour ?`, onDelete);
    }


    const onSave = async () => {
        await setLoading(true);
        setHelperText({});

        // const form_data = new FormData();
        // Object.keys(data).map(Name=>{
        //     form_data.append(Name, data[Name])
        // });

        let response;
        if (is_new) {
            response = await httpRequestWithAuth(ToursURL, "POST", data);
        } else {
            response = await httpRequestWithAuth(`${ToursURL}${data.id}`, "PUT", data);
        }

        if (response.status) {
            props.setTourData(response.data);
            if (is_new) {
                openNotification("success", "Add Tour", `${response.data.ref_no} tour has been added`);
            } else {
                openNotification("success", "Edit Tour", response.data.msg || `${response.data.ref_no} tour has been updated`);
            }


                if (is_new) {
                    props.history.push(`/${slug}/tours/${response.data.id}/?tab=2`);
                } else {
                    onCancel();
                }



        } else {
            // openNotification("error", is_new?"Add Tour":"Edit Tour", response.error_feedback.non_field_errors)
            setHelperText(response.error_feedback)
        }

        await setLoading(false);
    }



    return (
        <div className="paper pt-0 w-100">

            <Hide hide={!loading}>
                <HELoading/>
            </Hide>

            <Hide hide={loading}>
                <Row gutter={[12, 10]}>


                    <Col xs={24} className="d-flex align-items-center justify-content-between">
                        <h2 className="font-size-22 mb-0">
                                Details
                        </h2>

                    </Col>

                    <Col xs={24} md={12}>
                        <CustomTextField
                            view_only={!has_perm}
                            label={`Title ${upperCaseView(lang)}`}
                            name={`title_${lang}`}
                            value={data[`title_${lang}`]}
                            onChange={handleFieldChange}
                            error={helper_text[`title_${lang}`] !== undefined}
                            helper_text={helper_text[`title_${lang}`]}
                        />
                    </Col>

                    <Col xs={24} md={6}>
                        <CountryAutoComplete
                            required
                            label="Country"
                            name="CountryId"
                            value={data.CountryId || ""}
                            onChange={handleFieldChange}
                            error={helper_text.CountryId !== undefined}
                            helper_text={helper_text.CountryId}
                        />
                    </Col>

                    <Col xs={24} md={6}>
                        <CustomTextField
                            required
                            label="Price"
                            name="price"
                            value={data.price}
                            onChange={handleFieldChange}
                            error={helper_text.price !== undefined}
                            helper_text={helper_text.price}
                            type="number"
                            addonAfter="KWD"
                        />
                    </Col>


                    <Hide hide={is_new}>
                        <Col xs={24}>
                            <CustomTextField
                                view_only
                                label={`Slug ${upperCaseView(lang)}`}
                                name={`slug_${lang}`}
                                value={data[`slug_${lang}`]}
                            />
                        </Col>
                    </Hide>


                    <Col xs={24}>
                        <CustomTextAreaField
                            withTextEditor
                            view_only={!has_perm}
                            label={`Description ${upperCaseView(lang)}`}
                            name={`description_${lang}`}
                            value={data[`description_${lang}`]}
                            onChange={handleFieldChange}
                            error={helper_text[`description_${lang}`] !== undefined}
                            helper_text={helper_text[`description_${lang}`]}
                        />
                    </Col>


                    <Col xs={24}>
                        <CustomCheckBox
                            name="is_active"
                            label="Is Active"
                            value={data.is_active}
                            onChange={handleFieldChange}
                        />
                    </Col>

                    <Hide hide={is_new}>

                    <Col xs={24} className="mt-3"/>
                        <ViewActionsDetails data={data}/>
                    </Hide>

                    <Col xs={24}>
                        <ActionButton
                            error_msg={helper_text.non_field_errors}
                            onDelete={handleDelete}
                            onCancel={onCancel}
                            allow_delete={isValidValue(data.id) && checkPermission(permission_data.tour.delete)}
                            allow_save={has_perm}
                            onSave={onSave}
                        />
                    </Col>

                </Row>
            </Hide>
        </div>
    )
}
