import {Radio, Space} from "antd";
import React from "react";
import CustomFieldWrapper from "../CustomFieldWrapper";
import {isValidValue} from "../../helper_functions";
import Hide from "../Hide";

const CustomRadioField = (props) => {
    const options = props.add_all_option?[{title: "All", value: ""}, ...props.options]:props.options
    const display = props.vertical?"vertical":"";

    const handleFieldChange = (e) => {
      if (!props.view_only && !props.disabled) {
          props.onChange(props.name, e.target.value);
      }
  };

  return (
    <CustomFieldWrapper {...props}>
      <Radio.Group
        name={props.name}
        onChange={handleFieldChange}
        value={props.value}
        className="mb-2"

      >
          <Space direction={display} className="mt-1">

          {(options || []).map((option, index)=>(
              <Radio
                  key={index}
                  title={option.title}
                  value={option.value}
                  disabled={props.disabled}
              >
                  {option.title}
                  <Hide hide={!isValidValue(option.option_render_after)}>
                      {option.option_render_after}
                  </Hide>

              </Radio>
          ))}
          </Space>
      </Radio.Group>
    </CustomFieldWrapper>
  );
};

export default CustomRadioField;
