import {Col, Collapse, Row} from "antd";
import {CustomTextField} from "../../../../global/components/fields/CustomTextField";
import {CustomDateField} from "../../../../global/components/fields/CustomDateField";
import {CustomUploadField} from "../../../../global/components/fields/CustomUploadField";
import CustomSelectField from "../../../../global/components/fields/CustomSelectField";
import {title_options} from "../../../../global/variables";
import {CustomerField} from "../../../../global/components/fields/CustomerField";
import {CustomPhoneNumberField} from "../../../../global/components/fields/CustomPhoneNumberField";
import {AllWorldCountriesAutoComplete} from "../../../../global/components/fields/AllWorldCountriesAutoComplete";

const {Panel} = Collapse;

export const BookingItemPersons = props =>{
    const persons_data = props.persons || [];
    const handlePersonInformation = props.handlePersonInformation;
    const handlePersonInformationBulk = props.handlePersonInformationBulk;

    const handleFieldChange  = (index, name, value) =>{
        handlePersonInformation(props.index, index, name, value);
    }


    const getCustomerInfo = (index, customer) => {
        handlePersonInformationBulk(props.index, index, customer);
    }


    return(
        <Collapse defaultActiveKey={0}>
            {persons_data.map((person, index)=>(
                <Panel header={`Person ${index + 1} Information`}>
                    <Row gutter={[12, 10]}>

                        <Col xs={24} md={12}>
                            <CustomerField
                                required
                                label="Email"
                                name="email"
                                value={person.email}
                                onChange={(name, value)=>handleFieldChange(index, name, value)}
                                getCustomerInfo={customer=>getCustomerInfo(index, customer)}
                                error={person.helper_text.email !== undefined}
                                helper_text={person.helper_text.email}
                            />
                        </Col>

                        <Col xs={24} md={12}>
                            <CustomPhoneNumberField
                                required
                                label="Contact Number"
                                name="contact_number"
                                value={person.contact_number}
                                onChange={(name, value)=>handleFieldChange(index, name, value)}
                                error={person.helper_text.contact_number !== undefined}
                                helper_text={person.helper_text.contact_number}
                            />
                        </Col>


                        <Col xs={24} md={4}>
                            <CustomSelectField
                                required
                                label="Title"
                                name="title"
                                value={person.title}
                                onChange={(name, value)=>handleFieldChange(index, name, value)}
                                options={title_options}
                                error={person.helper_text.title !== undefined}
                                helper_text={person.helper_text.title}
                            />
                        </Col>

                        <Col xs={24} md={8}>
                            <CustomTextField
                                required
                                label="First Name"
                                name="first_name"
                                value={person.first_name}
                                onChange={(name, value)=>handleFieldChange(index, name, value)}
                                error={person.helper_text.first_name !== undefined}
                                helper_text={person.helper_text.first_name}
                            />
                        </Col>

                        <Col xs={24} md={12}>
                            <CustomTextField
                                required
                                label="Last Name"
                                name="last_name"
                                value={person.last_name}
                                onChange={(name, value)=>handleFieldChange(index, name, value)}
                                error={person.helper_text.last_name !== undefined}
                                helper_text={person.helper_text.last_name}
                            />
                        </Col>



                        <Col xs={24} md={4}>
                            <CustomDateField
                                required
                                open_on="year"
                                label="Birthdate"
                                name="birthdate"
                                value={person.birthdate}
                                onChange={(name, value)=>handleFieldChange(index, name, value)}
                                error={person.helper_text.birthdate !== undefined}
                                helper_text={person.helper_text.birthdate}
                            />
                        </Col>

                        <Col xs={24} md={8}>
                            <CustomTextField
                                required
                                label="Nationality"
                                name="nationality"
                                value={person.nationality}
                                onChange={(name, value)=>handleFieldChange(index, name, value)}
                                error={person.helper_text.nationality !== undefined}
                                helper_text={person.helper_text.nationality}
                            />
                        </Col>

                        <Col xs={24} md={12}>
                            <AllWorldCountriesAutoComplete
                                required
                                label="Country Of Residence"
                                name="country_of_residence"
                                value={person.country_of_residence}
                                onChange={(name, value)=>handleFieldChange(index, name, value)}
                                error={person.helper_text.country_of_residence !== undefined}
                                helper_text={person.helper_text.country_of_residence}
                            />
                        </Col>


                        <Col xs={24} md={4}>
                            <CustomTextField
                                required
                                label="Passport Number"
                                name="passport_number"
                                value={person.passport_number}
                                onChange={(name, value)=>handleFieldChange(index, name, value)}
                                error={person.helper_text.passport_number !== undefined}
                                helper_text={person.helper_text.passport_number}
                            />
                        </Col>


                        <Col xs={24} md={8}>
                            <CustomTextField
                                required
                                label="Full Name In Passport"
                                name="full_name_in_passport"
                                value={person.full_name_in_passport}
                                onChange={(name, value)=>handleFieldChange(index, name, value)}
                                error={person.helper_text.full_name_in_passport !== undefined}
                                helper_text={person.helper_text.full_name_in_passport}
                            />
                        </Col>


                        <Col xs={24} md={12}>
                            <CustomUploadField
                                required
                                upload_to_server
                                display_value={person.passport_copy_filename}
                                label="Passport Copy"
                                name="passport_copy"
                                value={person.passport_copy}
                                onChange={(name, value)=>handleFieldChange(index, name, value)}
                                error={person.helper_text.passport_copy !== undefined}
                                helper_text={person.helper_text.passport_copy}
                            />
                        </Col>



                        <Col xs={24}>
                            <CustomTextField
                                label="Full Address"
                                name="full_address"
                                value={person.full_address}
                                onChange={(name, value)=>handleFieldChange(index, name, value)}
                                error={person.helper_text.full_address !== undefined}
                                helper_text={person.helper_text.full_address}
                            />
                        </Col>
                    </Row>
                </Panel>
            ))}
        </Collapse>
    )
}
