import React, {useEffect, useState} from "react";
import {checkPermission} from "../../global/permissions";
import {permission_data} from "../../global/permissions_data";
import {isValidValue} from "../../global/helper_functions";
import {Tabs} from "antd";
import Hide from "../../global/components/Hide";
import {CustomTabPane} from "../../global/components/CustomTabPane";
import {getUserType} from "../../global/auth_functions";
import {AgencyDetails} from "./AgencyDetails";
import {AgencyContracts} from "./AgencyContracts";
import {AgencyDocuments} from "./AgencyDocuments";


export const AgencyInfo = props => {
    const slug = props.slug || "";
    const url_params = props.match.params || {};
    const [agency_id] = React.useState(url_params.id || 'new');
    const url_query = new URLSearchParams(props.location.search);
    const tab = (url_query.get("tab") || "1") + "";
    const [user_type] = useState(getUserType());
    const [current_tab, setCurrentTab] = useState("1");
    const [is_new] = useState(agency_id === "new");
    const [agency_data, setAgencyData] = useState({});

    useEffect(() => {
        if ((!isValidValue(url_params.id) && props.location.pathname !== `/${slug}/agencies/new`) || (agency_id !== "new" && !checkPermission(permission_data.agency.view)) || (agency_id === "new" && !checkPermission(permission_data.agency.add))) {
            props.history.push(`/${slug}/agencies`);
        }
        setCurrentTab(tab);
    }, []);

    const handleTabsChange = (new_value, extra_params={}) => {
        new_value = new_value + "";
        let params_attributes = {tab: new_value, ...extra_params};
        const params = new URLSearchParams(params_attributes);

        props.history.push({
            pathname: props.location.pathname,
            search: params.toString()
        });

        setCurrentTab(new_value);
    };


    return (
        <>


            <div className="m-0 p-0 d-md-flex justify-content-between">
                <h5 className="mb-3 mt-2">{agency_id === "new" ? "Add Agency" :  (<>Edit Agency > <span className="text-black-50">{agency_data.private_code}</span></>)}</h5>
            </div>


            <div className="paper pt-0 w-100">

                <Tabs activeKey={current_tab} onChange={handleTabsChange}>
                    <CustomTabPane tab="Details" key="1"/>
                    <CustomTabPane tab="Contracts" key="2"
                                   disabled={is_new || !checkPermission(permission_data.agency_contract.view)}/>
                    <CustomTabPane tab="Document" key="3"
                                   disabled={is_new || !checkPermission(permission_data.agency_document.view)}/>
                </Tabs>

                    <Hide hide={current_tab !== '1'}>
                        <AgencyDetails
                            {...props}
                            current_tab={current_tab}
                            is_new={is_new}
                            user_type={user_type}
                            agency_id={agency_id}
                            setAgencyData={setAgencyData}
                            handleTabsChange={handleTabsChange}
                        />
                    </Hide>

                    <Hide hide={current_tab !== '2'}>
                        <AgencyContracts
                            {...props}
                            user_type={user_type}
                            agency_id={agency_id}
                            handleTabsChange={handleTabsChange}
                        />
                    </Hide>

                    <Hide hide={current_tab !== '3'}>
                        <AgencyDocuments
                            {...props}
                            user_type={user_type}
                            agency_id={agency_id}
                            handleTabsChange={handleTabsChange}
                        />
                    </Hide>

            </div>

        </>)
}
