import {useState} from "react";
import CustomModal from "../../../../global/components/CustomModal";
import {Col, Row} from "antd";
import {CustomTextField} from "../../../../global/components/fields/CustomTextField";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {ProductsSalesItemUpdateLimitURL} from "../../../../global/urls";
import {openNotification} from "../../../../global/helper_functions";
import {ModelActionButton} from "../../../../global/components/ModelActionButton";


export const ChangeDownloadsLimitFormModal = props => {
    const [data, setData] = useState({
        ...props.data
    });
    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});


    const handleFieldChange = (name, value) => {
        let temp_data = {...data};
        temp_data[name] = value;

        setData(temp_data);
    }



    const onSave = async () => {
        await setLoading(true);


        let response = await httpRequestWithAuth(ProductsSalesItemUpdateLimitURL(data.id), "PUT", data);

        if (response.status) {
            openNotification("success", "Update Download Limit", response.data.msg || `${data.product_title_en} downloads limit has been updated`)
            props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
        }

        await setLoading(false);

    }

    return (
        <CustomModal size="xs" title="Change Download Limit Form" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>


                <Col xs={24}>
                    <CustomTextField
                        required
                        label="Download Limit"
                        name="download_limit"
                        value={data.download_limit}
                        onChange={handleFieldChange}
                        error={helper_text.download_limit !== undefined}
                        helper_text={helper_text.download_limit}
                        type="number"
                    />
                </Col>


                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}
