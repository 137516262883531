import React, {useEffect, useState} from 'react';
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {GetBookingsNumberOverMonthReportURL} from "../../../global/urls";
import {isValidValue} from "../../../global/helper_functions";
import {PRIMARY_COLOR} from "../../../global/variables";
import {Badge, Button} from "antd";
import {FunnelPlotFilled} from "@ant-design/icons";
import Hide from "../../../global/components/Hide";
import FilterModal from "./components_modal/FilterModal";
import {OverlayTrigger, Tooltip as RBTooltip} from "react-bootstrap";
import {Loading} from "../../../global/components/Loading";
import {checkPermission} from "../../../global/permissions";
import {permission_data} from "../../../global/permissions_data";



const BookingsNumberOverMonths = props => {
    const [data, setData] = useState([]);
    // const [has_perm] = useState(checkPermission(permission_data.reports.view_bookings_over_months));
    const [has_perm] = useState(checkPermission(permission_data.reports.view_bookings_over_months));

    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState({});
    const [open_filter_modal, setOpenFilterModal] = useState(false);
    const [filter_count, setFilterCount] = useState(0);

    useEffect(() => {
        const fetchData = async () => {

            if(has_perm){
            setLoading(true)
            const params = {};


            if (isValidValue(filter.booking_type)) {
                params.booking_type = filter.booking_type;
            }

            if (isValidValue(filter.year)) {
                params.year = filter.year;
            }


            if (isValidValue(filter.package_id)) {
                params.package_id = filter.package_id;
            }

            const repsonse = await httpRequestWithAuth(GetBookingsNumberOverMonthReportURL, "GET", null, params);

            if (repsonse.status) {
                setData(repsonse.data || []);
            }

            let temp_filter_count = 0;
            Object.keys(filter).map(data_key => {
                if (isValidValue(filter[data_key])) {
                    temp_filter_count++;
                }
            })

            setFilterCount(temp_filter_count);
            setLoading(false)
            }

        }

        fetchData();
    }, [filter])


    return (
        <div className="w-100 h-100 position-relative">
            <Hide hide={!loading}>
                <div className="position-absolute d-flex justify-content-center align-items-center w-100 h-100 z-index-100">
                    <div className="blur-bg w-100 pt-1 pb-3">
                        <Loading hide_text/>
                    </div>
                </div>
            </Hide>

            <Hide hide={!open_filter_modal}>
                <FilterModal
                    include_year
                    include_booking_type
                    title="Bookings Over Months"
                    open={open_filter_modal}
                    filter={filter}
                    onFilter={setFilter}
                    handleModelClose={() => setOpenFilterModal(false)}
                />
            </Hide>

            <div className="d-flex justify-content-between">
                <h2 className="custom-grey-color font-size-16">
                    Bookings Over Months
                </h2>


                <OverlayTrigger
                    overlay={
                        <RBTooltip>
                            Filter
                        </RBTooltip>
                    }
                >
                    <Button className="border-0" onClick={() => setOpenFilterModal(true)}>

                        <Badge status="Error" count={filter_count} size="small">
                            <FunnelPlotFilled/>
                        </Badge>
                    </Button>
                </OverlayTrigger>

            </div>

            <ResponsiveContainer width="100%" height="85%">
                <LineChart
                    width={500}
                    height={400}
                    data={data}
                    margin={{
                        top: 10,
                        right: 0,
                        left: -40,
                        bottom: 0,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="name" className="font-size-12"/>
                    <YAxis allowDecimals={false} className="font-size-12"/>
                    <Tooltip/>
                    <Line type="monotone" dataKey="count" stroke={PRIMARY_COLOR} activeDot={{ r: 8 }} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}


export default BookingsNumberOverMonths;
