import React, {useEffect, useState} from "react";
import {getUserSLug, isAuthenticated, logout} from "../global/auth_functions";
import {createAxiosInstance} from "../global/axios_instance";
import {getAllPermissions} from "../global/permissions";
import {HELoading} from "../global/components/HELoading";
import {useParams} from "react-router-dom";
import Layout from "../layout/Layout";

export const ProtectedApp = props => {
    const params = useParams();
    const slug = (params.slug || "").toLowerCase();
    const [user_slug] = useState((getUserSLug() || "").toLowerCase());

    const [is_loading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const is_auth = await isAuthenticated();

            if (is_auth && user_slug === slug) {
                const token = await localStorage.getItem("access_token")
                await createAxiosInstance(token, slug);
                await getAllPermissions();
            } else {
                const return_path = (props.location.pathname.toLowerCase()).replace(`/${slug.toLowerCase()}/`, "")
                logout();
                props.history.push(`/${slug}/signin?returnPath=${return_path}`);
            }

            await setIsLoading(false);
        }

        fetchData();
    }, [])

    if (is_loading) {
        return <HELoading full_page/>
    }

    return (
    <Layout {...props} slug={slug}/>
)
}
