import React, {useState} from 'react'
import {Col, Row} from 'antd'
import {httpRequestWithAuth} from '../../../../global/axios_instance';
import {isValidValue, openNotification} from '../../../../global/helper_functions';
import {OtherPackagesTestimonialsURL, PackagesAddExistsTestimonialsURL, TestimonialsURL} from '../../../../global/urls'
import CustomModal from '../../../../global/components/CustomModal';
import Hide from "../../../../global/components/Hide";
import {HELoading} from "../../../../global/components/HELoading";
import {ModelActionButton} from "../../../../global/components/ModelActionButton";
import {CustomDynamicTable} from "../../../../global/components/CustomDynamicTable";
import CustomRate from "../../../../global/components/fields/CustomRate";
import CustomCheckBox from "../../../../global/components/fields/CustomCheckBox";

const SelectExistsTestimonialModal = (props) => {
    const package_id = props.package_id || "";

    const [selected_ids, setSelectedIds] = useState([]);
    const [loading, setLoading] = useState(false);

    const [helper_text, setHelperText] = useState({});
    const columns = [
        {
            dataIndex: "id",
            width: "2%",
            align: "center",
            render: value => <CustomCheckBox value={selected_ids.includes(value)} onChange={onSelectChange} name={value}/>
        },
        {
            title: "Rating",
            dataIndex: "rating",
            width: "23%",
            render: (value) => <CustomRate view_only value={value}/>
        },
        {
            title: "Customer",
            dataIndex: "customer_name",
            width: "25%",

        },
        {
            title: "FeedBack",
            dataIndex: "feedback",
            width: "50%",
            render: (value) => <div className="pre-line">{value}</div>
        },

    ]



    const onSelectChange = (id) =>{
        let temp_selected_ids = [...selected_ids];
        const index_of = temp_selected_ids.indexOf(id);
        if (index_of < 0){
            temp_selected_ids.push(id);
        }else{
            temp_selected_ids.splice(index_of, 1);
        }
        setSelectedIds(temp_selected_ids);
    }


    const onSave = async () => {
        await setLoading(true);

        const data = {
            package_id,
            testimonials: selected_ids
        }

        const response =  await httpRequestWithAuth(PackagesAddExistsTestimonialsURL, "PATCH", data);

        if (response.status) {
            openNotification("success", "Add exists testimonial to package", `Testimonial Updated Successfully`);
            props.handleModelClose(true);
        } else {
            openNotification("error", "Add exists testimonial to package", response.error_feedback.non_field_errors);
            setHelperText(response.error_feedback);
        }

        await setLoading(false);
    }


    return (
        <CustomModal size="md" title="Select Exists Testimonial" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>

                <Hide hide={!loading}>
                    <HELoading/>
                </Hide>


                    <CustomDynamicTable
                        columns={columns}
                        fetch_url={OtherPackagesTestimonialsURL}
                        custom_params={{package: package_id}}
                    />

                    <Col xs={24} className="p-0">
                        <ModelActionButton
                            error_msg={helper_text.non_field_errors}
                            loading={loading}
                            saveLabel="Add"
                            onSave={onSave}
                            onCancel={props.handleModelClose}
                        />
                    </Col>


            </Row>
        </CustomModal>

    )
}

export default SelectExistsTestimonialModal
