import React from "react";
import { Alert, Button, Col, Row } from "antd";
import { isValidValue } from "../helper_functions";
import { CheckRound, CloseOutline } from "@rsuite/icons";
import Hide from "./Hide";

export const ModelActionButton = (props) => {
  return (
    <Row className={"m-0 p-0"}>
        <Hide hide={!isValidValue(props.error_msg)}>
          <Col xs={24}>
            <Alert
              className="text-danger"
              banner
              description={props.error_msg}
              type="error"
              showIcon={props.showIcon}
            />
          </Col>
        </Hide>

        <Col span={24} className={`d-inline-flex mt-${props.mt || 4} mb-3`}>
            <Button className="bg-light" onClick={!props.loading && props.onCancel}>
                <Hide hide={props.hide_icon}>
              <CloseOutline className="mb-1 me-2 p-0" />
                </Hide>

                {props.cancel_label || "Cancel"}
            </Button>

          <div className="ms-auto">
              <Hide hide={!props.onSave || props.hide_save}>
              <Button
                type="primary"
                onClick={props.onSave}
                loading={props.loading}
                danger={props.save_danger}
              >
                  <Hide hide={props.hide_icon}>
                  <CheckRound className="mb-1 me-2 p-0" />
                  </Hide>

                {props.saveLabel ? props.saveLabel : "Save"}
              </Button>
              </Hide>
          </div>
        </Col>
      </Row>
  );
};
