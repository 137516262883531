import {useState} from "react";
import CustomModal from "../../../../global/components/CustomModal";
import {Col, Row} from "antd";
import {CustomTextField} from "../../../../global/components/fields/CustomTextField";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {GurusURL} from "../../../../global/urls";
import {isValidValue, openNotification} from "../../../../global/helper_functions";
import {ModelActionButton} from "../../../../global/components/ModelActionButton";
import CustomCheckBox from "../../../../global/components/fields/CustomCheckBox";
import {PackageCategoryAutoComplete} from "../../../../global/components/fields/PackageCategoryAutoComplete";
import Hide from "../../../../global/components/Hide";
import {CustomUploadField} from "../../../../global/components/fields/CustomUploadField";


export const GuruFormModal = props => {

    const [data, setData] = useState({
        ...props.data
    });

    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});

    const handleFieldChange = (name, value) => {
        let temp_data = {...data};
        temp_data[name] = value || "";

        if (name === "image") {
            temp_data[`${name}_filename`] = isValidValue(value) ? value.name : "";
        }

        setData(temp_data);
    }

    const onSave = async () => {
        await setLoading(true);

        const form_data = new FormData();

        Object.keys(data).map(Name => {
            form_data.append(Name, data[Name]);
        })

        const is_new = !isValidValue(data.id);
        let response;

        if (is_new) {
            response = await httpRequestWithAuth(GurusURL, "POST", form_data);
        } else {
            response = await httpRequestWithAuth(`${GurusURL}${data.id}`, "PUT", form_data);
        }

        if (response.status) {
            if (is_new) {
                openNotification("success", "Add Guru", `${data.name_en} grur has been added`)
            } else {
                openNotification("success", "Update Guru", `${data.name_en} grur has been updated`)
            }
            props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
        }

        await setLoading(false);

    }

    return (
        <CustomModal title="Guru Form" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>

                <Col xs={24} md={12}>
                    <CustomTextField
                        label="Name En"
                        name="name_en"
                        value={data.name_en}
                        onChange={handleFieldChange}
                        error={helper_text.name_en !== undefined}
                        helper_text={helper_text.name_en}
                    />
                </Col>


                <Col xs={24} md={12}>
                    <CustomTextField
                        label="Name Ar"
                        name="name_ar"
                        value={data.name_ar}
                        onChange={handleFieldChange}
                        error={helper_text.name_ar !== undefined}
                        helper_text={helper_text.name_ar}
                    />
                </Col>

                <Col xs={24} md={12}>
                    <CustomTextField
                        label="Email"
                        name="email"
                        value={data.email}
                        onChange={handleFieldChange}
                        error={helper_text.email !== undefined}
                        helper_text={helper_text.email}
                    />
                </Col>

                <Col xs={24} md={12}>
                    <CustomUploadField
                        display_value={data.image_filename}
                        label="Image"
                        name="image"
                        value={data.image}
                        onChange={handleFieldChange}
                        error={helper_text.image !== undefined}
                        helper_text={helper_text.image}
                    />

                </Col>


                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}
