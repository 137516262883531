import React, {useState} from "react";
import CustomModal from "../../../global/components/CustomModal";
import {Col, Divider, Row} from "antd";
import {ModelActionButton} from "../../../global/components/ModelActionButton";
import {ViewInfo} from "../../../global/components/ViewInfo";
import Hide from "../../../global/components/Hide";
import CustomDropDown from "../../../global/components/CustomDropDown";
import {SmileOutlined} from "@ant-design/icons";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {
    BookingSendPaymentReceiptToCustomerByEmailURL,
    BookingsUpdateServiceChargeStatusURL
} from "../../../global/urls";
import {openNotification} from "../../../global/helper_functions";
import {SendPaymentRequestModal} from "./SendPaymentRequestModal";
import BookingsUpdateCustomerInfoModal from "./BookingsUpdateCustomerInfoModal";
import {checkPermission} from "../../../global/permissions";
import {permission_data} from "../../../global/permissions_data";
import {isAdmin} from "../../../global/auth_functions";
import BookingsUpdateServiceChargePaymentModal from "./BookingsUpdateServiceChargePaymentModal";

export const ViewMainBookingDetails = (props) => {
    const is_admin = isAdmin();
    const data = props.data || {};
    const language = props.language || "";
    const [update_customer_info, setUpdateCustomerInfo] = useState(false);
    const [open_send_payment_request_modal, setOpenSendPaymentRequestModal] = useState(false);
    const [open_generate_payment_link_modal, setOpenGeneratePaymentLinkModal] = useState(false);
    const [open_service_charge_payment_modal, setOpenServiceChargePaymentModal] = useState(false);
    const [perm] = useState({
        update_service_charge: is_admin && checkPermission(permission_data.booking.update_service_charge_info),
        update_customer: is_admin && checkPermission(permission_data.booking.update_customer_info),
        send_payment_receipt: is_admin && checkPermission(permission_data.booking.send_payment_receipt),
        send_payment_request: is_admin && checkPermission(permission_data.booking.send_payment_request),
    })
    const actions_options = [
        {
            key: '1',
            label: "Send Payment Receipt Email",
            icon: <SmileOutlined/>,
            hidden: !perm.send_payment_receipt
        },
        {
            key: '2',
            label: "Send Payment Request Email",
            disabled: data.is_full_paid,
            icon: <SmileOutlined/>,
            hidden: !perm.send_payment_request
        },
        {
            key: '3',
            label: "Generate Payment Link",
            icon: <SmileOutlined/>,
            disabled: data.is_full_paid,
            hidden: !perm.send_payment_request
        },
        {
            key: '4',
            label: "Update Customer Info",
            icon: <SmileOutlined/>,
            disabled: data.is_full_paid,
            hidden: !perm.update_customer
        },
        {
            key: '5',
            label: "Exclude Service Charge",
            icon: <SmileOutlined/>,
            hidden: !perm.update_service_charge || !data.add_service_charge
        },
        {
            key: '6',
            label: "Include Service Charge",
            icon: <SmileOutlined/>,
            hidden: !perm.update_service_charge || data.add_service_charge
        },
        {
            key: '7',
            label: "Update Service Charge Payment",
            icon: <SmileOutlined/>,
            hidden: !perm.update_service_charge || !data.add_service_charge
        },
    ]


    const sendPaymentReceiptToCustomerByEmail = async () => {

        let response = await httpRequestWithAuth(BookingSendPaymentReceiptToCustomerByEmailURL, "POST", {
            booking: data.id,
        });

        if (response.status) {
            openNotification("success", "Send Payment Receipt", `Email has been sent`)
        } else {
            openNotification("error", "Send Payment Receipt", response.error_feedback.non_field_errors)
        }

    }

    const updateServiceChargeStatus = async (add_service_charge) => {

        let response = await httpRequestWithAuth(`${BookingsUpdateServiceChargeStatusURL}${data.id}`, "PATCH", {
            add_service_charge
        });

        if (response.status) {
            openNotification("success", "Booking Service Charge", `Service Charge has been ${add_service_charge?"Included":"Excluded"} for receipt #${data.ref_no}`)
            props.handleModelClose();
        } else {
            openNotification("error", "Booking Service Charge", response.error_feedback.non_field_errors)
        }

    }

    const handelActionButtonOptions = ({key}) => {
        if (key === "1") {
            sendPaymentReceiptToCustomerByEmail();
        }

        if (key === "2") {
            setOpenSendPaymentRequestModal(true);
        }

        if (key === "3") {
            setOpenGeneratePaymentLinkModal(true);
            setOpenSendPaymentRequestModal(true);
            // const url = `${process.env.REACT_APP_CUSTOMER_WEBSITE_URL}payments/?booking=${data.BookingId}&booking_items=${booking_item_id}&booking_items_payments=e2094b34-b44f-4212-9390-b38c63f36866`
            // alert(url)
        }

        if (key === "4") {
            setUpdateCustomerInfo(true);
        }
        if (key === "5") {
            updateServiceChargeStatus(false);
        }
        if (key === "6") {
            updateServiceChargeStatus(true);
        }
        if (key === "7") {
            setOpenServiceChargePaymentModal(true);
        }
    }


    if (open_send_payment_request_modal){
     return (
         <Hide hide={!open_send_payment_request_modal}>
             <SendPaymentRequestModal
                 language={language}
                 generate_payment_link={open_generate_payment_link_modal}
                 open={open_send_payment_request_modal}
                 handleModelClose={() => {
                     setOpenSendPaymentRequestModal(false);
                     setOpenGeneratePaymentLinkModal(false);
                 }}
                 booking={data.id}
             />
         </Hide>
     )
    }

    if (update_customer_info){
     return (
         <Hide hide={!update_customer_info}>
             <BookingsUpdateCustomerInfoModal
                 language={language}
                 open={update_customer_info}
                 handleModelClose={() => {
                     setUpdateCustomerInfo(false);
                     props.handleModelClose();
                 }}
                 booking={data.id}
                 data={data}
             />
         </Hide>
     )
    }

    if (open_service_charge_payment_modal){
        return (
            <Hide hide={!open_service_charge_payment_modal}>
                <BookingsUpdateServiceChargePaymentModal
                    language={language}
                    open={open_service_charge_payment_modal}
                    handleModelClose={() => {
                        setOpenServiceChargePaymentModal(false);
                        props.handleModelClose();
                    }}
                    booking={data.id}
                    data={data}
                />
            </Hide>
        )
    }

    return (
        <CustomModal title="View Booking Receipt Details" visible={props.open} onCancel={props.handleModelClose} small>
            <Row gutter={[12, 10]}>

                <Col xs={24} className="d-flex justify-content-end">
                    <CustomDropDown btn_type="primary" label="Actions" options={actions_options}
                                    onChange={handelActionButtonOptions}/>
                </Col>

                <Col xs={24} md={12}>
                    <ViewInfo
                        title="Ref No."
                        value={data.ref_no}
                    />
                    <Divider className="m-1 p-1"/>
                </Col>

                <Col xs={24} md={12}>
                    <ViewInfo
                        title="Booking Type"
                        value={data.booking_type}
                    />
                    <Divider className="m-1 p-1"/>
                </Col>


                <Col xs={24} md={12}>
                    <ViewInfo
                        title="Customer"
                        value={data.customer_name}
                    />
                    <Divider className="m-1 p-1"/>
                </Col>


                <Col xs={24} md={12}>
                    <ViewInfo
                        title="Contact Number"
                        value={data.customer_contact_number_view}
                    />
                    <Divider className="m-1 p-1"/>
                </Col>


                <Col xs={24} md={12}>
                    <ViewInfo
                        title="Email"
                        value={data.customer_email}
                    />
                    <Divider className="m-1 p-1"/>
                </Col>


                <Col xs={24} md={12}>
                    <ViewInfo
                        title="Currency"
                        value={data.currency}
                    />
                    <Divider className="m-1 p-1"/>
                </Col>


                <Col xs={24} md={12}>
                    <ViewInfo
                        title="has Service Charge"
                        value={data.add_service_charge ? "Yes" : "No"}
                    />
                    <Divider className="m-1 p-1"/>
                </Col>


                <Hide hide={!data.add_service_charge}>
                    <Col xs={24} md={12}>
                        <ViewInfo
                            title="Service Charge Amount"
                            value={data.service_charge_amount}
                        />
                        <Divider className="m-1 p-1"/>
                    </Col>

                    <Col xs={24} md={12}>
                        <ViewInfo
                            title="Is Service Charge Paid"
                            value={data.is_service_charge_paid ? "Yes" : "No"}
                        />
                        <Divider className="m-1 p-1"/>
                    </Col>


                    <Col xs={24} md={12}>
                        <ViewInfo
                            title="Service Charge Paid Ref No."
                            value={data.service_charge_paid_ref_no}
                        />
                        <Divider className="m-1 p-1"/>
                    </Col>
                </Hide>

                <Hide hide={!data.is_voucher_applied}>
                    <Col xs={24} md={12}>
                        <ViewInfo
                            title="Voucher Code"
                            value={data.voucher_code}
                        />
                        <Divider className="m-1 p-1"/>
                    </Col>

                    <Col xs={24} md={12}>
                        <ViewInfo
                            title="Voucher Type"
                            value={data.voucher_type}
                        />
                        <Divider className="m-1 p-1"/>
                    </Col>

                    {/*<Col xs={24} md={12}>*/}
                    {/*    <ViewInfo*/}
                    {/*        title="Voucher Discount Percentage"*/}
                    {/*        value={parseFloat(data.voucher_amount) + " %"}*/}
                    {/*    />*/}
                    {/*    <Divider className="m-1 p-1"/>*/}
                    {/*</Col>*/}

                    <Col xs={24} md={12}>
                        <ViewInfo
                            title="Total Discount"
                            value={data.total_discount + " " + data.currency}
                        />
                        <Divider className="m-1 p-1"/>
                    </Col>
                </Hide>


                <Col xs={24} className="p-0">
                    <ModelActionButton
                        cancel_label="Close"
                        onCancel={props.handleModelClose}
                    />
                </Col>


            </Row>
        </CustomModal>
    )
}
