import {Button, Col, Row} from "antd";
import {CustomDynamicTable} from "../../../global/components/CustomDynamicTable";
import {useState} from "react";
import {HEMediaURL} from "../../../global/urls";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {copyText, openNotification} from "../../../global/helper_functions";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import {AddButton} from "../../../global/components/AddButton";
import {HEMediaFormModal} from "./components/HEMediaFormModal";
import Hide from "../../../global/components/Hide";
import { FileIcon, defaultStyles } from "react-file-icon";

export const HEMedia = props => {
    const [open_from_data, setOpenFormData] = useState(false);
    const [form_data, setFormData] = useState({});
    const [reload, setReload] = useState(false);

    const [he_media_perm] = useState({
        delete: checkPermission(permission_data.he_media.delete),
        edit: checkPermission(permission_data.he_media.edit),
        add: checkPermission(permission_data.he_media.add),
    });


    const [columns] = useState([
        {
            title: "File",
            dataIndex: "display_image",
            width: "10%",
            render:(value, row_data)=> (
                <div className="icon" style={{maxWidth: 100}}>
                    <FileIcon extension={row_data.file_type} {...defaultStyles[row_data.file_type]} />
                </div>
            )
        },
        {
            title: "Title",
            dataIndex: "title",
            width: "20%",
        },
        {
            title: "File Link",
            dataIndex: "display_file",
            width: "60%",
            render: value => <div className="d-md-flex w-100 align-items-center">
                <a className="me-2" target="_blank" href={value}>
                {value}
                </a>
                <Button size="small" className="m-1" onClick={()=>copyText(value)}>Copy</Button>
                <a target="_blank" href={value} download>
                <Button size="small" className="m-1" >Open</Button>
                </a>

            </div>
        },


    ])



    const handelOpenFormData = (data = {}) => {
        setFormData(data)
        setOpenFormData(true)
    }

    const handelCloseFormData = (reload_data = false) => {
        setFormData({});
        setOpenFormData(false);

        if (reload_data) {
            setReload(Math.random());
        }

    }

    const onDelete = async (row_data) => {
        let response = await httpRequestWithAuth(`${HEMediaURL}${row_data.id}`, "DELETE");
        if (response.status) {
            openNotification("success", "Delete Media", `${row_data.title} media has been deleted`)
        } else {
            openNotification("error", "Delete Media", response.error_feedback.non_field_errors)
        }
    }

    const onEdit = async (row_data) => {
        handelOpenFormData(row_data)
    }

    const onAdd = async () => {
        handelOpenFormData()
    }

    const freeAction = (
        <>
            <AddButton onClick={onAdd}>Add Media</AddButton>
        </>
    );

    return (
        <Row>
            <Hide hide={!open_from_data}>
                <HEMediaFormModal
                    open={open_from_data}
                    data={form_data}
                    handleModelClose={handelCloseFormData}
                />
            </Hide>

            <Col xs={24} className="paper">
                <CustomDynamicTable
                    title="Media"
                    reload_data={reload}
                    columns={columns}
                    fetch_url={HEMediaURL}
                    free_action={freeAction}
                    onDelete={he_media_perm.delete && onDelete}
                    onEdit={he_media_perm.edit && onEdit}
                />
            </Col>
        </Row>
    )
}
