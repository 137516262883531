import {useEffect, useState} from "react";
import {httpRequestWithAuth} from "../../axios_instance";
import {CustomersBookedPackageURL} from "../../urls";
import CustomSelectField from "./CustomSelectField";
import {isValidValue} from "../../helper_functions";

const PackageBookedCustomersSelect = (props) => {
  const package_id = props.package_id || "";
  const [options, setOptions] = useState([]);

  useEffect(()=>{
    const fetchOptions = async ()=>{
      const response = await httpRequestWithAuth(CustomersBookedPackageURL, "GET", null, {package: package_id});

      if (response.status){
        const data = response.data || [];
        let temp_options = [];
        for (let i = 0; i < data.length; i++) {
          temp_options.push({
            title: `${data[i].customer_name} (${data[i].customer_email})`,
            value: data[i].customer_email
          });
        }
        setOptions(temp_options);
      }
    }

    fetchOptions()
  },[])

  const onChange = (name, value) =>{
    if (props.multiple){
      value = value.join(",")
    }

    props.onChange(name, value);
  }

  return <CustomSelectField
      {...props}
      mode={props.multiple?"multiple":""}
      options={options}
      value={props.multiple?isValidValue(props.value)?props.value.split(","):[]: props.value}
      onChange={onChange}
  />
}


export default PackageBookedCustomersSelect;
