import React, {useState} from "react";
import CustomModal from "../../../../global/components/CustomModal";
import {Alert, Button, Col, Divider, Row} from "antd";
import {CustomTextField} from "../../../../global/components/fields/CustomTextField";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {PackagesOptionDateTimeBulkAddURL} from "../../../../global/urls";
import {isValidValue, openNotification} from "../../../../global/helper_functions";
import {ModelActionButton} from "../../../../global/components/ModelActionButton";
import CustomCheckBox from "../../../../global/components/fields/CustomCheckBox";
import {CustomDateField} from "../../../../global/components/fields/CustomDateField";
import Hide from "../../../../global/components/Hide";
import moment from "moment";
import {AddButton} from "../../../../global/components/AddButton";
import MinusRoundIcon from '@rsuite/icons/MinusRound';
import {CustomTimeField} from "../../../../global/components/fields/CustomTimeField";


export const BulkAddDateTimeFormModal = props => {
    const [data, setData] = useState({
        from_date: moment().format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
        skip_interval: 0,
        bulk_dates: [],
    });

    const [times, setTimes] = useState([
        {
            time: moment().format("HH:mm"),
            capacity: data.capacity || 0,
            is_active: true,
            is_sold_out: false,
            is_unique: false,
        },
    ]);
    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});
    const [time_helper_text, setTimeHelperText] = useState([{}]);

    const handleFieldChange = (name, value) => {
        let temp_helper_text = {...helper_text, [name]: undefined};
        let temp_data = {...data};
        temp_data[name] = value;

        setData(temp_data);
        setHelperText(temp_helper_text);
    }

    const addTime = () => {
        const new_time = {
            time: moment().format("HH:mm"),
            capacity: data.capacity || 0,
            is_active: true,
            is_sold_out: false,
            is_unique: false,
        }

        if (times.length > 1) {
            const last_index = times.length - 1;
            const last_date = moment(times[last_index].time, "HH:mm")
            let duration = last_date.diff(moment(times[last_index - 1].time, "HH:mm"))
            new_time.time = last_date.add(duration, 'milliseconds').format("HH:mm")
        }

        setTimes([...times, new_time])
        setTimeHelperText([...time_helper_text, {}])
    }


    const removeTime = (index) => {
        let temp_times = [...times];
        let temp_times_helper = [...time_helper_text];
        temp_times.splice(index, 1);
        temp_times_helper.splice(index, 1);
        if (temp_times.length === 0) {
            temp_times.push({
                time: moment().format("HH:mm"),
                capacity: data.capacity || 0,
                is_active: true,
                is_sold_out: false,
                is_unique: false,
            })
            temp_times_helper.push({})
        }
        setTimes(temp_times);
        setTimeHelperText(temp_times_helper);
    }

    const handleTimeFieldChange = (name, value, index) => {
        let temp_times = [...times];
        temp_times[index][name] = value
        setTimes(temp_times);
    }


    const checkTimeFields = () => {
        let temp_helper_list = [];
        let error = false;
        times.map((time_data, index) => {
            const temp_helper = {};
            if (!isValidValue(time_data)) {
                temp_helper.time = "this field is required";
                error = true;
            }

            const exists_time = times.filter((row, row_index) => {
                return row_index < index && row.time === time_data.time
            });

            if (exists_time.length > 0) {
                temp_helper.time = "this time already added";
                error = true;
            }

            temp_helper_list.push(temp_helper);
        })

        setTimeHelperText(temp_helper_list);

        return !error;
    }

    const onSave = async () => {
        await setLoading(true);


        const is_new = !isValidValue(data.id);
        let response;

        if (is_new && data.has_time) {
            const check_time_data = checkTimeFields()
            if (!check_time_data) {
                await setLoading(false);
                return;
            }
        }

        if (is_new) {
            response = await httpRequestWithAuth(PackagesOptionDateTimeBulkAddURL, "POST", {...data, times});
        } else {
            response = await httpRequestWithAuth(`${PackagesOptionDateTimeBulkAddURL}${data.id}`, "PUT", {
                ...data,
                times
            });
        }

        if (response.status) {
            if (is_new) {
                openNotification("success", "Add Package Option Date", `${response.date_view} package option date has been added`)
            } else {
                openNotification("success", "Update Package Option Date", `${response.date_view} package option date has been updated`)
            }
            props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
            if (Array.isArray(response.error_feedback.times_errors)) {
                setTimeHelperText(response.error_feedback.times_errors);
            }
        }

        await setLoading(false);

    }


    return (
        <CustomModal title="Add Multiple Times" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>

                <Col xs={24}>
                    <Alert
                        message="Add Multiple Time will be applied on a date under following conditions:"
                        description={(
                            <label>
                                - The date(s) must exist
                                <br/>
                                - The date(s) must be within the range (From Date, To Date)
                                <br/>
                                - The date(s) must have the <CustomCheckBox className="ms-2" label={<b>Has Specific Time</b>} container_class_name="d-inline-flex" value={true} view_only/> checked

                            </label>
                        )}
                        type="info"
                        showIcon
                    />
                </Col>


                <Col xs={24} md={8}>
                    <CustomDateField
                        required
                        label="From Date"
                        name="from_date"
                        value={data.from_date}
                        onChange={handleFieldChange}
                        error={helper_text.from_date !== undefined}
                        helper_text={helper_text.from_date}
                    />
                </Col>


                <Col xs={24} md={8}>
                    <CustomDateField
                        required
                        label="To Date"
                        name="to_date"
                        value={data.to_date}
                        onChange={handleFieldChange}
                        error={helper_text.to_date !== undefined}
                        helper_text={helper_text.to_date}
                    />
                </Col>


                <Col xs={24} md={8}>
                    <CustomTextField
                        required
                        label="Skip Interval"
                        name="skip_interval"
                        value={data.skip_interval}
                        onChange={handleFieldChange}
                        error={helper_text.skip_interval !== undefined}
                        helper_text={helper_text.skip_interval}
                        type="number"
                        tooltip="Number of days to skip between dates range"
                    />
                </Col>


                <Col xs={24}>
                    <Divider orientation="left" orientationMargin="0" className=" mb-0 fw-bold font-size-18">
                        Timing
                    </Divider>
                </Col>

                <Col xs={24} className="d-flex justify-content-end">
                    <Col xs={24} sm={6} md={5} className="d-flex d-md-block">
                        <AddButton onClick={addTime}>Add Time</AddButton>
                    </Col>
                </Col>

                {times.map((time_data, index) => (
                    <>

                        <Col xs={24} md={12}>
                            <CustomTimeField
                                required
                                label="Time"
                                name="time"
                                value={time_data.time}
                                onChange={(name, value) => handleTimeFieldChange(name, value, index)}
                                error={time_helper_text[index].time !== undefined}
                                helper_text={time_helper_text[index].time}
                            />
                        </Col>

                        <Col xs={24} md={12}>
                            <CustomTextField
                                required
                                label="Time Qty"
                                name="capacity"
                                value={time_data.capacity}
                                onChange={(name, value) => handleTimeFieldChange(name, value, index)}
                                error={time_helper_text[index].capacity !== undefined}
                                helper_text={time_helper_text[index].capacity}
                                type="number"
                            />
                        </Col>

                        <Col xs={12} md={6}>
                            <CustomCheckBox
                                label="Is Active"
                                name="is_active"
                                value={time_data.is_active}
                                onChange={(name, value) => handleTimeFieldChange(name, value, index)}
                            />
                        </Col>

                        <Col xs={12} md={6}>
                            <CustomCheckBox
                                label="Is Unique"
                                name="is_unique"
                                value={time_data.is_unique}
                                onChange={(name, value) => handleTimeFieldChange(name, value, index)}
                            />
                        </Col>

                        <Col xs={12} md={6}>
                            <CustomCheckBox
                                label="Is Sold Out"
                                name="is_sold_out"
                                value={time_data.is_sold_out}
                                onChange={(name, value) => handleTimeFieldChange(name, value, index)}
                            />
                        </Col>

                        <Col xs={12} md={6} className="d-flex align-items-end justify-content-end">
                            <Button danger type="primary" onClick={() => removeTime(index)}>
                                <MinusRoundIcon className="me-1"/>
                                Remove
                            </Button>
                        </Col>

                        <Hide hide={!isValidValue(time_helper_text[index].non_field_errors)}>
                            <Col xs={24}>
                                <Alert
                                    className="text-danger"
                                    banner
                                    description={time_helper_text[index].non_field_errors}
                                    type="error"
                                />
                            </Col>
                        </Hide>

                        <Divider className="m-0"/>
                    </>
                ))}

                <Hide hide={times.length < 5}>
                    <Col xs={24} className="d-flex justify-content-end">
                        <Col xs={24} sm={6} md={5} className="d-flex d-md-block">
                            <AddButton onClick={addTime}>Add Time</AddButton>
                        </Col>
                    </Col>
                </Hide>


                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}
