import React, {useEffect, useState} from "react";
import {Route, Switch} from "react-router-dom";
import {isSuperAdmin, logout} from "../global/auth_functions";
import {Dashboard} from "../pages/dashboard/Dashboard";
import ProtectedRoute from "./ProtectedRoutes";
import {Rules} from "../pages/rules/rules";
import {permission_data} from "../global/permissions_data";
import {Users} from "../pages/users/users";
import {Agencies} from "../pages/agencies/Agencies";
import {Countries} from "../pages/locations/countries/Countries";
import {Cities} from "../pages/locations/cities/Cities";
import {Languages} from "../pages/he_general/languages/Languages";
import {Advertisements} from "../pages/he_general/advertisements/Advertisements";
import {GlobalData} from "../pages/he_general/global_data/GlobalData";
import {HEMedia} from "../pages/he_general/he_media/HEMedia";
import {ServiceCharges} from "../pages/finance/service_charges/ServiceCharges";
import {Currencies} from "../pages/finance/currencies/Currencies";
import {AgencyContracts} from "../pages/agencies/AgencyContracts";
import {AgencyDocuments} from "../pages/agencies/AgencyDocuments";
import {PackagesCategories} from "../pages/packages/packages_catogeries/PackagesCategories";
import {DiscountCodeVouchers} from "../pages/vouchers/discount_codes_vouchers/DiscountCodeVouchers";
import {Tiers} from "../pages/customers/tiers/Tiers";
import {Packages} from "../pages/packages/packages/Packages";
import {PackageInfo} from "../pages/packages/packages/PackageInfo";
import {DiscountCodeVoucherInfo} from "../pages/vouchers/discount_codes_vouchers/DiscountCodeVoucherInfo";
import {OnlinePayments} from "../pages/finance/online_payments/OnlinePayments";
import {Bookings} from "../pages/bookings/Bookings";
import {AddBooking} from "../pages/bookings/AddBooking";
import {Customers} from "../pages/customers/customers/Customers";
import {BookingItemInfo} from "../pages/bookings/BookingItemInfo";
import {MyFatoorahPaymentMethods} from "../pages/finance/myfatoorah_payment_methods/MyFatoorahPaymentMethods";
import {ConversionRates} from "../pages/finance/conversion_rates/ConversionRates";
import {AgencyInfoForAgent} from "../pages/agencies/AgencyInfoForAgent";
import {ContactUs} from "../pages/contact_us/ContactUs";
import {Products} from "../pages/products/products/Products";
import {ProductInfo} from "../pages/products/products/ProductInfo";
import {ProductsSales} from "../pages/products/products_sales/ProductsSales";
import {ProductSaleInfo} from "../pages/products/products_sales/ProductSaleInfo";
import ITSupport from "../pages/it_support/ITSupport";
import {Invoices} from "../pages/invoices/Invoices";
import {InvoiceInfo} from "../pages/invoices/InvoiceInfo";
import {PackagesAnalytics} from "../pages/packages/packages_analytics/PackagesAnalytics";
import Testimonials from "../pages/testimonials/Testimonials";
import {Blogs} from "../pages/blogs/Blogs";
import {BlogInfo} from "../pages/blogs/BlogInfo";
import {Reports} from "../pages/reports/Reports";
import {ConsultationInfo} from "../pages/consultations/ConsultationInfo";
import {Consultations} from "../pages/consultations/Consultations";
import {TrustedPartners} from "../pages/he_general/trusted_partners/TrustedPartners";
import {PackagesInclusions} from "../pages/packages/packages_iclusions/PackagesInclusions";
import {DigitalGiftVouchers} from "../pages/vouchers/digital_gift_vouchers/DigitalGiftVouchers";
import {DigitalGiftVouchersInfo} from "../pages/vouchers/digital_gift_vouchers/DigitalGiftVouchersInfo";
import {AgencyInfo} from "../pages/agencies/AgencyInfo";
import {BookingsFinance} from "../pages/finance/booking_finance/BookingsFinance";
import {TourInfo} from "../pages/tours/TourInfo";
import {Tours} from "../pages/tours/Tours";
import {JoinUsRequests} from "../pages/career/join_us_requests/JoinUsRequests";
import {Gurus} from "../pages/packages/gurus/Gurus";


const NotFound = (props) =>{
    // useEffect(()=>{
    //     logout();
    //     props.history.push(`${props.slug}/signin`);
    // },[])

    return(
        <h2>Not found</h2>
    )
}


export const Routes = props =>{
    const is_super_admin = isSuperAdmin();

    return(
        <Switch>

            <ProtectedRoute {...props} title="Gurus" path="/:slug/gurus" component={Gurus} permissions={[permission_data.guru.view]}/>

            <ProtectedRoute {...props} title="Join Us Requests" path="/:slug/career/join-us-requests" component={JoinUsRequests} permissions={[permission_data.join_us_request.view]}/>

            <ProtectedRoute {...props} title="Contact Us" path="/:slug/contact-us" component={ContactUs} permissions={[permission_data.contact_us.view]}/>

            <ProtectedRoute {...props} title="Agency Info" path="/:slug/agency-info" component={AgencyInfoForAgent} permissions={[permission_data.agency.view]}/>


            <ProtectedRoute {...props} title="Add Consultation"  path="/:slug/consultations/new" key="new-consultation" component={ConsultationInfo} permissions={[permission_data.consultation_request.add]}/>
            <ProtectedRoute {...props} title="Edit Consultation"  path="/:slug/consultations/:id" key="edit-consultation" component={ConsultationInfo} permissions={[permission_data.consultation_request.view]}/>
            <ProtectedRoute {...props} title="Consultations"  path="/:slug/consultations" component={Consultations} permissions={[permission_data.consultation_request.view]}/>



            {/*<ProtectedRoute {...props} title="Consultation Responses" path="/:slug/consultations/responses" component={ConsultationResponses} permissions={[permission_data.consultation_response.view]}/>*/}
            {/*<ProtectedRoute {...props} title="Consultation Requests" path="/:slug/consultations/requests" component={ConsultationRequests} permissions={[permission_data.consultation_request.view]}/>*/}

            <ProtectedRoute {...props} title="Edit Product Sale"  path="/:slug/products-sales/:id" key="edit-products-sales" component={ProductSaleInfo} permissions={[permission_data.product_sale.view]}/>
            <ProtectedRoute {...props} title="Products Sales" path="/:slug/products-sales" component={ProductsSales} permissions={[permission_data.product_sale.view]}/>

            <ProtectedRoute {...props} title="Add Tour"  path="/:slug/tours/new" key="new-tour" component={TourInfo} permissions={[permission_data.tour.add]}/>
            <ProtectedRoute {...props} title="Edit Tour"  path="/:slug/tours/:id" key="edit-tour" component={TourInfo} permissions={[permission_data.tour.view]}/>
            <ProtectedRoute {...props} title="Tours"  path="/:slug/tours" component={Tours} permissions={[permission_data.tour.view]}/>

            <ProtectedRoute {...props} title="Add Blog"  path="/:slug/blogs/new" key="new-blog" component={BlogInfo} permissions={[permission_data.blog.add]}/>
            <ProtectedRoute {...props} title="Edit Blog"  path="/:slug/blogs/:id" key="edit-blog" component={BlogInfo} permissions={[permission_data.blog.view]}/>
            <ProtectedRoute {...props} title="Blog Articles"  path="/:slug/blogs" component={Blogs} permissions={[permission_data.blog.view]}/>

            <ProtectedRoute {...props} title="Add Invoice"  path="/:slug/invoices/new" key="new-invoice" component={InvoiceInfo} permissions={[permission_data.invoice.add]}/>
            <ProtectedRoute {...props} title="Edit Invoice"  path="/:slug/invoices/:id" key="edit-invoice" component={InvoiceInfo} permissions={[permission_data.invoice.view]}/>
            <ProtectedRoute {...props} title="Invoices"  path="/:slug/invoices" component={Invoices} permissions={[permission_data.invoice.view]}/>

            <ProtectedRoute {...props} title="Add Product"  path="/:slug/products/new" key="new-products" component={ProductInfo} permissions={[permission_data.product.add]}/>
            <ProtectedRoute {...props} title="Edit Product"  path="/:slug/products/:id" key="edit-products" component={ProductInfo} permissions={[permission_data.product.view]}/>
            <ProtectedRoute {...props} title="Products"  path="/:slug/products" component={Products} permissions={[permission_data.product.view]}/>

            <ProtectedRoute {...props} title="Add Packages"  path="/:slug/packages/new" key="new-package" component={PackageInfo} permissions={[permission_data.package.add]}/>
            <ProtectedRoute {...props} title="Edit Packages"  path="/:slug/packages/:id" key="edit-package" component={PackageInfo} permissions={[permission_data.package.view]}/>
            <ProtectedRoute {...props} title="Packages"  path="/:slug/packages" component={Packages} permissions={[permission_data.package.view]}/>
            <ProtectedRoute {...props} title="Testimonials"  path="/:slug/testimonials" component={Testimonials} permissions={[permission_data.package.view]}/>
            {/* <ProtectedRoute {...props} title="Testimonials Create"  path="/:slug/testimonial/new" component={TestimonialFormModal} permissions={[permission_data.package.view]}/> */}

            <ProtectedRoute {...props} title="Customers"  path="/:slug/customers" component={Customers} permissions={[permission_data.customer.view]}/>
            <ProtectedRoute {...props} title="Tiers"  path="/:slug/tiers" component={Tiers} permissions={[permission_data.tier.view]}/>


                <ProtectedRoute {...props} title="Add Booking" path="/:slug/bookings/new" key="new-Bookings" component={AddBooking} permissions={[permission_data.booking.add]}/>
                <ProtectedRoute {...props} title="View Booking" path="/:slug/bookings/:id" key="view-booking" component={BookingItemInfo} permissions={[permission_data.booking.view]}/>
                <ProtectedRoute {...props} title="Bookings"  path="/:slug/bookings" component={Bookings} permissions={[permission_data.booking.view]}/>


                <ProtectedRoute {...props} title="Add Digital Gift Voucher"  path="/:slug/vouchers/digital-gift/new" key="new-digital-gift" component={DigitalGiftVouchersInfo} permissions={[permission_data.voucher.add]}/>
                <ProtectedRoute {...props} title="Edit Digital Gift Voucher"  path="/:slug/vouchers/digital-gift/:id" key="edit-digital-gift" component={DigitalGiftVouchersInfo} permissions={[permission_data.voucher.view]}/>
                <ProtectedRoute {...props} title="Digital Gift Vouchers"  path="/:slug/vouchers/digital-gift" component={DigitalGiftVouchers} permissions={[permission_data.voucher.view]}/>

                <ProtectedRoute {...props} title="Add Discount Code Voucher"  path="/:slug/vouchers/discount-code/new" key="new-discount-code" component={DiscountCodeVoucherInfo} permissions={[permission_data.voucher.add]}/>
                <ProtectedRoute {...props} title="Edit Discount Code Voucher"  path="/:slug/vouchers/discount-code/:id" key="edit-discount-code" component={DiscountCodeVoucherInfo} permissions={[permission_data.voucher.view]}/>
                <ProtectedRoute {...props} title="Discount Code Vouchers" path="/:slug/vouchers/discount-code" component={DiscountCodeVouchers} permissions={[permission_data.voucher.view]}/>
                {/*<ProtectedRoute {...props} title="Vouchers" path="/:slug/vouchers" component={DiscountCodeVouchers} permissions={[permission_data.voucher.view]}/>*/}

            <ProtectedRoute {...props} title="Packages Inclusions"  path="/:slug/packages-inclusions" component={PackagesInclusions} permissions={[permission_data.package_inclusions.view]}/>
            <ProtectedRoute {...props} title="Packages Categories"  path="/:slug/packages-categories" component={PackagesCategories} permissions={[permission_data.package_category.view]}/>
            <ProtectedRoute {...props} title="Packages Analytics"  path="/:slug/packages-analytics" component={PackagesAnalytics} permissions={[permission_data.package.view_analytics]}/>

            <ProtectedRoute {...props} title="Bookings Finance"  path="/:slug/bookings-finance" component={BookingsFinance} permissions={[permission_data.booking.view_booking_finance]}/>

            <ProtectedRoute {...props} title="Online Payments"  path="/:slug/online-payments" component={OnlinePayments} permissions={[permission_data.online_payment.view]}/>


            <ProtectedRoute {...props} title="Currencies"  path="/:slug/currencies" component={Currencies} permissions={[permission_data.currency.view]}/>

            <ProtectedRoute {...props} title="MyFatoorah Payment Methods"  path="/:slug/myfatoorah-payment-methods" component={MyFatoorahPaymentMethods} permissions={[permission_data.myfatoorah_payment_method.view]}/>

            <ProtectedRoute {...props} title="Conversion Rates"  path="/:slug/conversion-rates" component={ConversionRates} permissions={[permission_data.conversion_rate.view]}/>

            <ProtectedRoute {...props} title="Service Charges"  path="/:slug/service-charges" component={ServiceCharges} permissions={[permission_data.service_charge.view]}/>

            <ProtectedRoute {...props} title="HE Media"  path="/:slug/he-media" component={HEMedia} permissions={[permission_data.he_media.view]}/>

            <ProtectedRoute {...props} title="Global Data"  path="/:slug/global-data" component={GlobalData} permissions={[permission_data.global_data.view]}/>

            <ProtectedRoute {...props} title="Trusted Partners"  path="/:slug/trusted-partners" component={TrustedPartners} permissions={[permission_data.trusted_partner.view]}/>

            <ProtectedRoute {...props} title="Advertisements"  path="/:slug/advertisements" component={Advertisements} permissions={[permission_data.advertisement.view]}/>

            <ProtectedRoute {...props} title="Languages"  path="/:slug/languages" component={Languages} permissions={[permission_data.language.view]}/>

            <ProtectedRoute {...props} title="Cities"  path="/:slug/cities" component={Cities} permissions={[permission_data.city.view]}/>

            <ProtectedRoute {...props} title="Countries"  path="/:slug/countries" component={Countries} permissions={[permission_data.country.view]}/>

            <ProtectedRoute {...props} title="Agencies Documents"  path="/:slug/agencies-documents" component={AgencyDocuments} permissions={[permission_data.agency_document.view]}/>

            <ProtectedRoute {...props} title="Agencies Contract"  path="/:slug/agencies-contracts" component={AgencyContracts} permissions={[permission_data.agency_contract.view]}/>

            <ProtectedRoute {...props} title="New Agency"  path="/:slug/agencies/new" key="new-agency" component={AgencyInfo} permissions={[permission_data.agency.view]}/>
            <ProtectedRoute {...props} title="Edit Agency"  path="/:slug/agencies/:id" key="edit-agency" component={AgencyInfo} permissions={[permission_data.agency.view]}/>
            <ProtectedRoute {...props} title="Agencies"  path="/:slug/agencies" component={Agencies} permissions={[permission_data.agency.view]}/>

            <ProtectedRoute {...props} title="Users"  path="/:slug/users" component={Users} permissions={[permission_data.user.view]}/>

            <ProtectedRoute {...props} title="Rules"  path="/:slug/rules" component={Rules} permissions={[permission_data.rule.view]}/>
            {/**/}

            <ProtectedRoute {...props} title="IT Support" path="/:slug/it-support" component={ITSupport} permissions={is_super_admin}/>

            <ProtectedRoute {...props} title="Dashboard"  path="/:slug/dashboard" component={Dashboard} permissions={true}/>

            <ProtectedRoute {...props} title="Dashboard"  path="/:slug/reports" component={Reports} permissions={true}/>

            <Route path="*" component={NotFound}/>

        </Switch>
    )
}
