import React, {useEffect, useState} from 'react';
import {CustomTextField} from "../../global/components/fields/CustomTextField";
import {adminSignIn, isAuthenticated} from "../../global/auth_functions";
import Logo from "../../assets/img/he_logo_black.png";
import Bg from "../../assets/img/bg_1.jpg";
import {Button, Col, Divider, Row, Steps} from "antd";
import {useParams} from "react-router-dom";
import {CustomSlugField} from "../../global/components/fields/CustomSlugField";
import {CustomPhoneNumberField} from "../../global/components/fields/CustomPhoneNumberField";
import {CountryAutoComplete} from "../../global/components/fields/CountryAutoComplete";
import {CustomUploadField} from "../../global/components/fields/CustomUploadField";
import CustomSelectField from "../../global/components/fields/CustomSelectField";
import {title_options} from "../../global/variables";
import {isValidValue, showInfo} from "../../global/helper_functions";
import {httpRequestWithoutAuth} from "../../global/axios_instance";
import {JoinToAgencyURL, BaseBackEndAPIsURL} from "../../global/urls";
// const {Step} = Steps;

export const AgentSignUp = props => {
    const params = useParams();
    const slug = params.slug || ""

    const [data, setData] = useState({
        is_active: true
    });

    // const [current_step, setCurrent] = useState(0);
    const [helper_text, setHelperText] = useState({});
    const [is_loading, setLoading] = useState(false);

    const handleFieldChange = (name, value) => {
        setData({...data, [name]: value});
    }



    const onSubmit = async (e) => {
        e.preventDefault();
        await setLoading(true);

        if (data.confirm_password !== data.password) {
            setHelperText({...helper_text, confirm_password: "Password does not match"})
            await setLoading(false);
            return
        }



        let response = await httpRequestWithoutAuth(`${BaseBackEndAPIsURL}${JoinToAgencyURL}`, "POST", {...data, slug});

        if (response.status) {
            showInfo("Register as Agency", "Sign up to agency successfully, Please verify your email then login", ()=>{
                props.history.push(`/${response.data.slug}/signin`);
            });
        } else {
            setHelperText(response.error_feedback)
        }

        await setLoading(false);
    }


    return (
        <main className="vh-100 overflow-hidden bg-light">
            <img src={Bg} className="vh-100 vw-100 position-absolute z-index-0"/>
        <Row className="d-flex justify-content-center align-items-start   overflow-auto h-100 pt-5" style={{paddingBottom: "5rem"}}>
            <Col xs={22} sm={18} md={14} lg={12}
                 className="shadow border-radius-10 bg-white d-flex flex-column align-items-center p-4 mt-5 ">

                <Col xs={21} sm={18} md={16} lg={8}>
                    <img src={Logo} className="w-100"/>
                </Col>

                <h3 className="fw-bolder text-center">
                    Join To Agency
                </h3>

                <span className="text-danger mt-2">
                            {helper_text.non_field_errors}
                </span>


                    <Row gutter={[12, 12]} c className="w-100">

                        <Col xs={24} md={4}>
                            <CustomSelectField
                                label="Title"
                                name="title"
                                value={data.title}
                                onChange={handleFieldChange}
                                error={helper_text.title !== undefined}
                                helper_text={helper_text.title}
                                options={title_options}
                            />
                        </Col>

                        <Col xs={24} md={10}>
                            <CustomTextField
                                label="First Name"
                                name="first_name"
                                value={data.first_name}
                                onChange={handleFieldChange}
                                error={helper_text.first_name !== undefined}
                                helper_text={helper_text.first_name}
                            />
                        </Col>

                        <Col xs={24} md={10}>
                            <CustomTextField
                                label="Last Name"
                                name="last_name"
                                value={data.last_name}
                                onChange={handleFieldChange}
                                error={helper_text.first_name !== undefined}
                                helper_text={helper_text.last_name}
                            />
                        </Col>


                        <Col xs={24} md={12}>
                            <CustomTextField
                                label="E-mail"
                                name="email"
                                value={data.email}
                                onChange={handleFieldChange}
                                error={helper_text.email !== undefined}
                                helper_text={helper_text.email}
                                type="email"
                            />
                        </Col>


                        <Col xs={24} md={12}>
                            <CustomPhoneNumberField
                                label="Contact No."
                                name="contact_number"
                                value={data.contact_number}
                                onChange={handleFieldChange}
                                error={helper_text.contact_number !== undefined}
                                helper_text={helper_text.contact_number}
                            />
                        </Col>


                        <Col xs={24} md={12}>
                            <CustomTextField
                                label="Password"
                                name="password"
                                value={data.password}
                                onChange={handleFieldChange}
                                error={helper_text.password !== undefined}
                                helper_text={helper_text.password}
                                type="password"
                            />
                        </Col>

                        <Col xs={24} md={12}>
                            <CustomTextField
                                label="Confirm Password"
                                name="confirm_password"
                                value={data.confirm_password}
                                onChange={handleFieldChange}
                                error={helper_text.confirm_password !== undefined}
                                helper_text={helper_text.confirm_password}
                                type="password"
                            />
                        </Col>

                        <Col xs={24}>
                            <CustomTextField
                                label="Private Code"
                                name="private_code"
                                value={data.private_code}
                                onChange={handleFieldChange}
                                error={helper_text.private_code !== undefined}
                                helper_text={helper_text.private_code}
                            />
                        </Col>

                        <Col xs={24} className="text-end">
                            <Button
                                    loading={is_loading}
                                    disabled={is_loading}
                                    onClick={onSubmit}
                                    type="primary"
                                    className="bg-black mt-4"
                                >
                                    Sign Up
                                </Button>
                        </Col>

                    </Row>

            </Col>
        </Row>
        </main>

    );
}

