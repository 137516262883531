import React, {useEffect, useState} from "react";
import {Input} from "antd";
import CustomFieldWrapper from "../CustomFieldWrapper";
import {isValidValue} from "../../helper_functions";

export const CustomDurationField = (props) => {
    const value = parseInt(props.value || 0);


    const [view_value, setViewValue] = useState({
        days: 0, hours: 0, minutes: 0
    })

    useEffect(()=>{
        const days = Math.floor(value / 60 / 24) ;
        const hours = Math.floor((value % 1440) / 60) ;
        const minutes = (value % 1440) % 60 ;

        setViewValue({days, hours, minutes})
    },[value])


    const handleFieldChange = async (e) => {
        if (!props.view_only && !props.disabled && props.onChange) {
            let temp_view_value = {...view_value};

            const name = e.target.name;
            let new_value = e.target.value;

            if (name === "hours") {
                if (new_value > 23) return;
            }

            if (name === "minutes") {
                if (new_value > 59) return;
            }

            temp_view_value[name] = new_value;

            setViewValue(temp_view_value)

            let original_value = parseInt((temp_view_value.days || 0) * 24 * 60) + parseInt((temp_view_value.hours || 0) * 60) + parseInt((temp_view_value.minutes || 0))
            props.onChange(props.name, original_value);


        }
    };

    const onBlur = async (e) => {
            let temp_view_value = {...view_value};

            const name = e.target.name;
            let value = e.target.value;


            if (!isValidValue(value)){
                value = 0;
                temp_view_value[name] = value;
                setViewValue(temp_view_value)
            }

        }

    return (
        <CustomFieldWrapper {...props}>
            <div className="d-md-flex justify-content-between w-100 ">
            <Input
                {...props}
                onChange={handleFieldChange}
                name="days"
                value={view_value.days}
                placeholder="days"
                className={` ${props.error?"border-danger": ""} me-md-2`}
                type="number"
                addonBefore="Days"
                onBlur={onBlur}

            />

                <Input
                    {...props}
                    onChange={handleFieldChange}
                    name="hours"
                    value={view_value.hours}
                    placeholder="hours"
                    className={`${props.error?"border-danger": ""}  me-md-2 mt-2 mt-md-0`}
                    type="number"
                    addonBefore="Hours"
                    onBlur={onBlur}
                />

                <Input
                    {...props}
                    onChange={handleFieldChange}
                    name="minutes"
                    value={view_value.minutes}
                    placeholder="minutes"
                    className={`${props.error?"border-danger": ""} mt-2 mt-md-0`}
                    type="number"
                    addonBefore="Minutes"
                    onBlur={onBlur}
                />
            </div>

        </CustomFieldWrapper>
    );
};
