import React, {useEffect, useState} from "react";
import CustomModal from "../../../../global/components/CustomModal";
import {Button, Col, Divider, Empty, Row} from "antd";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {BookingsPersonsURL} from "../../../../global/urls";
import Hide from "../../../../global/components/Hide";
import {Loading} from "../../../../global/components/Loading";
import {isAgent} from "../../../../global/auth_functions";
import {BookingPersonRow} from "./BookingPersonRow";
import {checkPermission} from "../../../../global/permissions";
import {permission_data} from "../../../../global/permissions_data";
import {AddButton} from "../../../../global/components/AddButton";

export const BookingItemPersonsModal = (props) => {
    const booking_item = props.booking_item || "";
    const [is_agent] = useState(isAgent());
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [perm] = useState({
        delete: checkPermission(permission_data.booking_person.delete),
        edit: checkPermission(permission_data.booking_person.edit),
        add: checkPermission(permission_data.booking_person.add),
    });

    const fetchData = async () =>{
        await setLoading(true);

        let response = await httpRequestWithAuth(BookingsPersonsURL, "GET", null, {booking_item});

        if (response.status){
            setData(response.data);
        }
        await setLoading(false);
    }

    useEffect(()=>{
        fetchData();
    },[])



    const handlePersonChange = (index, person_data) => {
        let temp_data = [...data];
            temp_data[index] = {...person_data}
            setData(temp_data);
    }

    const onAddTraveller = () =>{
        setData([
            ...data,
            {BookingItemId: booking_item}
        ])
    }



    return(
        <CustomModal title="Travellers" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>
                <Hide hide={!loading}>
                    <Col xs={24}>
                        <Loading/>
                    </Col>
                </Hide>

                <Hide hide={loading}>

                    <Hide hide={!perm.add}>
                    <Col xs={24} className="text-end">
                        <AddButton onClick={onAddTraveller} block={false}>Add Traveller</AddButton>
                    </Col>
                    </Hide>

                    <Col xs={24}>

                    {data.map((person_data, index) => {

                        return(
                            <BookingPersonRow
                            index={index}
                            is_agent={is_agent}
                            person_data={person_data}
                            handlePersonChange={handlePersonChange}
                            perm={perm}
                        />)
                    })}
                    </Col>


                    <Hide hide={data.length !== 0}>
                        <Col xs={24}>
                            <Empty/>
                        </Col>
                    </Hide>

                </Hide>


            </Row>
        </CustomModal>
        )
}
