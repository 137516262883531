import DynamicAutocomplete from "./DynamicAutocomplete";
import {ConsultationRequestsListURL} from "../../urls";

export const ConsultationRequestAutoComplete = (props)=>{
    const fetch_all = props.fetch_all || false;

    return(
        <DynamicAutocomplete
            {...props}
            fetch_url={ConsultationRequestsListURL}
            title_key={['ref_no', 'name', 'subject']}
            fetch_in_search={!fetch_all}
            mode={props.multiple ? "multiple":""}
            params_array={[fetch_all]}
            params={{fetch_all}}
        />
    )
}
