import {Button, Col, Row} from "antd";
import {CustomDynamicTable} from "../../global/components/CustomDynamicTable";
import React, {useEffect, useState} from "react";
import {ChangeUserStatusURL, UsersURL} from "../../global/urls";
import {httpRequestWithAuth} from "../../global/axios_instance";
import {isValidValue, openNotification, showError, showInfo, viewDateAndTime} from "../../global/helper_functions";
import {permission_data} from "../../global/permissions_data";
import {checkPermission} from "../../global/permissions";
import {AddButton} from "../../global/components/AddButton";
import {UserFormModal} from "./components/UserFormModal";
import Hide from "../../global/components/Hide";
import CustomBadge from "../../global/components/CustomBadge";
import {IsActiveView} from "../../global/components/IsActiveView";
import {getUserType, isAdmin} from "../../global/auth_functions";
import CustomSelectField from "../../global/components/fields/CustomSelectField";
import {AgencyAutoComplete} from "../../global/components/fields/AgencyAutoComplete";
import {EyeOutlined} from "@ant-design/icons";
import ViewUserAgencyDetails from "./components/ViewUserAgencyDetails";



const user_type_options = [
    {title: "Admin", value: "Admin"},
    {title: "Agent", value: "Agent"},
    {title: "Customer", value: "Customer"},
];
const user_status_options = [
    {title: "Approved", value: "Approved"},
    {title: "Rejected", value: "Rejected"},
    {title: "Pending", value: "Pending"},
];

const export_columns = [
    {
        title: "Title",
        key: "title",
    },

    {
        title: "Name",
        key: "full_name",
    },
    {
        title: "Email",
        key: "email",
    },
    {
        title: "Contact No",
        key: "contact_number",
    },
    {
        title: "Type",
        key: "user_type",
    },
    {
        title: "Status",
        key: "status",
    },
    {
        title: "Created at",
        key: "createdAt",
        format: value => viewDateAndTime(value),
    },
]

export const Users = props =>{
    const [is_admin] = useState(isAdmin());
    const [open_from_data, setOpenFormData] = useState(false);
    const [view_user_agency, setViewUserAgency] = useState(false);
    const [user_agency_id, setUserAgencyId] = useState("");
    const [form_data, setFormData] = useState({});
    const [reload, setReload] = useState(false);
    const [user_type] = useState(getUserType());
    const [filter, setFilter] = useState({
        status: "Approved"
    });

    const [user_perm] = useState({
        delete: checkPermission(permission_data.user.delete),
        edit: checkPermission(permission_data.user.edit),
        add: checkPermission(permission_data.user.add),
        change_status: checkPermission(permission_data.user.change_status),
    });

    const [columns] = useState([
        {
            title: "Title",
            dataIndex: "title",
            width: "20px"
        },
        {
            title: "Name",
            dataIndex: "full_name",
            width: "30%"
        },
        {
            title: "E-mail",
            dataIndex: "email",
            width: "20%"
        },
        {
            title: "Contact No.",
            dataIndex: "contact_number",
            width: "15%"
        },

        {
            title: "Type",
            dataIndex: "user_type",
            hide: user_type === "Agent",
            width: "15%",
            render:(value, row_data)=>(
                <>
                    <Hide hide={row_data.is_agency_admin}>
                        {value}
                    </Hide>

                    <Hide hide={!row_data.is_agency_admin}>
                        <CustomBadge title={"Agency Admin"}/>
                    </Hide>
                </>
            )
        },
        {
            title: "Status",
            dataIndex: "status",
            width: "20%",
            render: (value, row_data)=>{
                if (!user_perm.change_status){
                    return value
                }
                if (value === "Approved") {
                    return <CustomBadge title={value}/>

                }


                    return(
                        <div className="d-block d-md-flex">
                            <Button onClick={()=>changeUserStatus(row_data, "Approved")} className="m-1" block>Approve</Button>

                            <Hide hide={row_data.status === "Rejected"}>
                            <Button onClick={()=>changeUserStatus(row_data, "Rejected")} danger className="m-1" block>Reject</Button>
                            </Hide>

                        </div>
                    )
            }
        },
        {
            title: "Activate",
            dataIndex: "is_active",
            width: "20%",
            align:"center",
            render: (value)=><IsActiveView value={value} view_only={user_perm.edit}/>
        },

    ])


    const changeUserStatus = async (row_data, status) =>{
        let response = await httpRequestWithAuth(`${ChangeUserStatusURL}${row_data.id}`, "PUT", {status})
        if (response.status){
            openNotification("success", "Update User", `${row_data.full_name} user status has been updated`)
        }else{
            openNotification("error", "Update User", response.error_feedback.non_field_errors)
        }
        setReload(Math.random());

    }

    const handelOpenFormData = (data={})=>{
        setFormData(data)
        setOpenFormData(true)
    }

    const handelCloseFormData = (reload_data=false)=>{
        setFormData({});
        setOpenFormData(false);

        if (reload_data){
            setReload(Math.random());
        }

    }

    const onDelete = async (row_data) =>{
        let response = await httpRequestWithAuth(`${UsersURL}${row_data.id}`, "DELETE");
        if (response.status){
            openNotification("success", "Delete User", `${row_data.full_name} user has been deleted`)
        }else{
            openNotification("error", "Delete User", response.error_feedback.non_field_errors)
        }
    }

    const onEdit = async (row_data) =>{
        handelOpenFormData(row_data)
    }

    const onAdd = async () =>{
        handelOpenFormData()
    }


    const handleFilterChange = (name, value) =>{
        setFilter({
            ...filter,
            [name]: value
        })
        setReload(Math.random());
    }

    const freeAction = (
        <>
            <AddButton onClick={onAdd}>Add User</AddButton>
        </>
    );


    const filter_toolbar = (
        <Row gutter={[10,10]}>
            <Col xs={24} md={6}>
                <CustomSelectField
                    empty_title="All"
                    label="User Type"
                    name="user_type"
                    value={filter.user_type}
                    onChange={handleFilterChange}
                    options={user_type_options}
                />
            </Col>

            <Col xs={24} md={6}>
                <CustomSelectField
                    empty_title="All"
                    label="Status"
                    name="status"
                    value={filter.status}
                    onChange={handleFilterChange}
                    options={user_status_options}
                />
            </Col>

            <Hide hide={filter.user_type !== "Agent"}>
                <Col xs={24} md={12}>
                <AgencyAutoComplete
                    label="Agency"
                    name="AgencyId"
                    value={filter.AgencyId}
                    onChange={handleFilterChange}
                />
                </Col>
            </Hide>

        </Row>
    );

    const handelCloseUserAgency = () =>{
        setViewUserAgency(false);
        setUserAgencyId("");
    }

    const openUserAgency = (row_data) =>{
        setViewUserAgency(true);
        setUserAgencyId(row_data.AgencyId);
    }


    const extra_actions = [
        {
            key: "view-user-agency",
            icon: EyeOutlined,
            render: (row_data)=> <span className="main-color">View Agency</span>,
            hide: (row_data)=> !is_admin || row_data.user_type !== "Agent" || !checkPermission(permission_data.agency.view),
            onClick: openUserAgency
        }
    ];


    return(
        <Row>
            <Hide hide={!view_user_agency}>
                <ViewUserAgencyDetails
                    open={view_user_agency}
                    agecny_id={user_agency_id}
                    handleModelClose={handelCloseUserAgency}
                />
            </Hide>

            <Hide hide={!open_from_data}>
                <UserFormModal
                    open={open_from_data}
                    data={form_data}
                    handleModelClose={handelCloseFormData}
                />
            </Hide>

            <Col xs={24} className="paper">
                <CustomDynamicTable
                    title="Users"
                    reload_data={reload}
                    columns={columns}
                    fetch_url={UsersURL}
                    free_action={freeAction}
                    filter_toolbar={is_admin && filter_toolbar}
                    extra_actions={extra_actions}
                    onDelete={user_perm.delete && onDelete}
                    onEdit={user_perm.edit && onEdit}
                    custom_params={filter}
                    allow_export
                    export_columns={export_columns}
                />
            </Col>
        </Row>
    )
}
