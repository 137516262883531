import {Button} from "antd";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {ITSupportfixHEAgencyCommissionURL} from "../../../global/urls";
import {useState} from "react";
import {showError, showInfo} from "../../../global/helper_functions";

const FixHEAgencyCommission = (props) => {
    const it_support_data = props.it_support_data || {};
    const [loading, setLoading] = useState(false);

    const onClick = async ()=>{
        await setLoading(true);
        const password = it_support_data.password || "";

        let response = await httpRequestWithAuth(ITSupportfixHEAgencyCommissionURL, "POST", {password});
        if (response.status){
            showInfo("Done","")
        }else{
            showError("Failed", response.error_feedback.non_field_errors)
        }
        await setLoading(false)
    }

  return(
      <Button type="primary" loading={loading} onClick={onClick} block>
          Fix HE Agency Commission
      </Button>
  )
}


export default FixHEAgencyCommission;
