import {CustomModelDraggable} from "../../../../global/components/CustomModelDraggable";


export const WhatIsAddMultipleTime = props => {
    return (
        <CustomModelDraggable
            open={props.open}
            onCancel={props.handleModelClose}
            title="What Is Add Multiple Time?"
            width={600}
        >

                <p className="m-0">
                    This gives you the option to add multiple times on an already existing date(s).<br/><br/>
                </p>

        </CustomModelDraggable>
    )
}
