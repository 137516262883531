import React, {useEffect, useState} from "react";
import {checkPermission} from "../../../global/permissions";
import {permission_data} from "../../../global/permissions_data";
import {isValidValue} from "../../../global/helper_functions";
import Hide from "../../../global/components/Hide";
import {getUserType} from "../../../global/auth_functions";
import {StaticLanguageTabs} from "../../../global/components/StaticLanguageTabs";
import {ProductSaleDetails} from "./ProductSaleDetails";


export const ProductSaleInfo = props => {
    const slug = props.slug || "";
    const url_params = props.match.params || {};
    const [product_sale_id] = React.useState(url_params.id || 'new');
    const [lang_classes, setLangClasses] = useState("");
    const [user_type] = useState(getUserType());
    const [language, setLanguage] = useState("en");
    const [is_new] = useState(product_sale_id === "new");
    const [product_sale_data, setProductSaleData] = useState({});

    useEffect(() => {
        if ((!isValidValue(url_params.id) && props.location.pathname !== `/${slug}/products-sales/new`) || (product_sale_id !== "new" && !checkPermission(permission_data.product_sale.view)) || (product_sale_id === "new" && !checkPermission(permission_data.product_sale.add))) {
            props.history.push(`/${slug}/products-sales`);
        }
    }, []);

    const handleLanguageChange = (new_value) => {
        setLanguage(new_value);
    };

    useEffect(() => {
        if (props.scroll_height > 113) {
            setLangClasses("top-0 position-fixed bg-white z-index-100 w-100")
        } else {
            setLangClasses("")
        }
    }, [props.scroll_height])

    return (
        <>


            <div className="m-0 p-0 d-md-flex justify-content-between">
                <h5 className="mb-3 mt-2">{product_sale_id === "new" ? "Add Product Sale" : (<>Edit Product Sale > <span
                    className="text-black-50">{product_sale_data.ref_no}</span></>)}</h5>
            </div>


            <div className="paper pt-0 w-100">
                <StaticLanguageTabs value={language} onChange={handleLanguageChange} disabled_ar={is_new}/>

                <Hide hide={!isValidValue(lang_classes)}>
                    <StaticLanguageTabs className={lang_classes} value={language} onChange={handleLanguageChange}
                                        disabled_ar={is_new}/>
                </Hide>
            </div>


            <ProductSaleDetails
                {...props}
                is_new={is_new}
                user_type={user_type}
                language={language}
                product_sale_id={product_sale_id}
                setProductSaleData={setProductSaleData}
            />

        </>
    )
}
