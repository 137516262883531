import React from "react";
import {Checkbox, Form, Radio, Tooltip} from "antd";
import CustomFieldWrapper from "../CustomFieldWrapper";
import {PRIMARY_COLOR} from "../../variables";
import {InfoIconGreenFilled} from "../Icons";

const CustomCheckBox = (props) => {
  const handleFieldChange = (e) => {
      if (!props.disabled && !props.view_only){
            props.onChange(props.name, e.target.checked);
      }
  };

  return (
    <CustomFieldWrapper {...props} label={null} tooltip={null} container_class_name={`h-100 ${props.container_class_name}`}>
      <Checkbox
        {...props}
        name={props.name}
        onChange={handleFieldChange}
        checked={props.value}
        disabled={props.disabled}
      >
          {props.label}

          {props.tooltip && (
              <Tooltip
                  className="ms-1"
                  placement="topLeft"
                  title={props.tooltip}
                  color={PRIMARY_COLOR}
                  arrowPointAtCenter
              >
                              <span className="p-0 m-0">
                                  <InfoIconGreenFilled/>
                              </span>
              </Tooltip>
          )}
      </Checkbox>
    </CustomFieldWrapper>
  );
};

export default CustomCheckBox;
