import React, {useEffect, useState} from "react";
import CustomModal from "../../../global/components/CustomModal";
import {Col, Row} from "antd";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {BookingUpdateUpdateDateTimeURL} from "../../../global/urls";
import Hide from "../../../global/components/Hide";
import {Loading} from "../../../global/components/Loading";
import {isValidValue, openNotification} from "../../../global/helper_functions";
import {ModelActionButton} from "../../../global/components/ModelActionButton";
import {PackageOptionsDatesAutoComplete} from "../../../global/components/fields/PackageOptionsDatesAutoComplete";
import CustomBadge from "../../../global/components/CustomBadge";
import {
    PackageOptionsDatesTimesAutoComplete
} from "../../../global/components/fields/PackageOptionsDatesTimesAutoComplete";

export const ChangeBookingDateTime = (props) => {
    const booking_item = props.booking_item || "";
    const booking_item_data = props.booking_item_data || {};
    const [data, setData] = useState(props.current_value || {});
    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});
    const [date_obj, setDateObj] = useState({});
    const [time_obj, setTimeObj] = useState({});


    const handelFieldChange = (name, value) =>{
        let temp_data = {...data};
        temp_data[name] = value;


        if (name === "PackageOptionDateId") {
            setTimeObj({});
        }

        setData(temp_data)
    }

    const onSave = async () =>{
         setLoading(true);
        setHelperText({})

             let response = await httpRequestWithAuth(`${BookingUpdateUpdateDateTimeURL}${booking_item}`, "PATCH", data)

             if (response.status){
                 openNotification("success", "Change Date & Time", `Booking has been updated`)
                 props.handleModelClose();
             }else{
                 setHelperText(response.error_feedback)
             }

         setLoading(false);
    }




    return(
        <CustomModal title="Change Date & Time" visible={props.open} onCancel={props.handleModelClose} small>
            <Row gutter={[12, 10]}>

                <Hide hide={!loading}>
                    <Col xs={24}>
                        <Loading/>
                    </Col>
                </Hide>

                <Hide hide={loading}>

                    <Col xs={24}>
                        <Col xs={24} md={24}>
                            <PackageOptionsDatesAutoComplete
                                // status="Approved"
                                // hide_unique_and_sold_out={true}
                                // select_first
                                getFullObj={setDateObj}
                                title_key="date_view"
                                packages_options={booking_item_data.PackageOptionId}
                                label={
                                    <>
                                        Date
                                        {isValidValue(date_obj.id) && !date_obj.is_active && (<CustomBadge padding="0" font_size={10} type="disabled" className="m-1" title="Inactive"/>)}
                                        {date_obj.is_sold_out && (<CustomBadge padding="0" font_size={10} type="danger" className="m-1" title="Sold Out"/>)}
                                        {date_obj.is_unique && (<CustomBadge padding="0" font_size={10} type="primary" className="m-1" title="Unique"/>)}
                                        {date_obj.is_active && (<CustomBadge padding="0" font_size={10} className="m-1" title="Active"/>)}
                                    </>
                                }
                                name="PackageOptionDateId"
                                value={data.PackageOptionDateId}
                                onChange={handelFieldChange}
                                error={helper_text.PackageOptionDateId !== undefined}
                                helper_text={helper_text.PackageOptionDateId}
                            />
                        </Col>
                    </Col>

                    <Hide hide={!date_obj.has_time}>
                        <Col xs={24} md={24}>
                            <PackageOptionsDatesTimesAutoComplete
                                // select_first
                                // hide_unique_and_sold_out={true}
                                getFullObj={setTimeObj}
                                title_key="time_view"
                                package_option_date={date_obj.id}
                                label={<>
                                    Time
                                    {!time_obj.is_active && (<CustomBadge padding="0" font_size={10} type="disabled" className="m-1" title="Inactive"/>)}
                                    {time_obj.is_sold_out && (<CustomBadge padding="0" font_size={10} type="danger" className="m-1" title="Sold Out"/>)}
                                    {time_obj.is_unique && (<CustomBadge padding="0" font_size={10} type="primary" className="m-1" title="Unique"/>)}
                                    {time_obj.is_active && (<CustomBadge padding="0" font_size={10} className="m-1" title="Active"/>)}
                                </>}
                                name="PackageOptionDateTimeId"
                                value={data.PackageOptionDateTimeId}
                                onChange={handelFieldChange}
                                error={helper_text.PackageOptionDateTimeId !== undefined}
                                helper_text={helper_text.PackageOptionDateTimeId}
                            />
                        </Col>
                    </Hide>


                    <Col xs={24} className="p-0">
                        <ModelActionButton
                            error_msg={helper_text.non_field_errors}
                            loading={loading}
                            onSave={(data.PackageOptionDateId && (date_obj.has_time?data.PackageOptionDateTimeId:true)) && onSave}
                            onCancel={props.handleModelClose}
                        />
                    </Col>

                </Hide>



            </Row>
        </CustomModal>
        )
}
