import React, {useEffect, useState} from "react";
import CustomModal from "../../../global/components/CustomModal";
import {Col, Divider, Row} from "antd";
import {ModelActionButton} from "../../../global/components/ModelActionButton";
import {ViewInfo} from "../../../global/components/ViewInfo";
import Hide from "../../../global/components/Hide";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {AgenciesURL} from "../../../global/urls";
import {isValidValue} from "../../../global/helper_functions";
import {ImageUploaderWithView} from "../../../global/components/ImageUploaderWithView";
import {CustomAvatarWithView} from "../../../global/components/CustomAvatarWithView";

const ViewUserAgencyDetails = (props) => {
    const agecny_id = props.agecny_id || "";
        const [data, setData] = useState({});

    useEffect(()=>{
        const fetchData = async () =>{
            const response = await httpRequestWithAuth(`${AgenciesURL}${agecny_id}`, "GET");
            if (response.status){
                setData(response.data);
            }
        }
        fetchData()
    },[agecny_id])

    return (
        <CustomModal title="User Agency Details" visible={props.open} onCancel={props.handleModelClose} small>
            <Row gutter={[12, 10]}>

                <Hide hide={!isValidValue(data.display_logo)}>
                    <Col xs={24} md={24} className="mb-4">
                        <CustomAvatarWithView src={data.display_logo}/>
                    </Col>
                </Hide>

                <Col xs={24} md={12}>
                    <ViewInfo
                        title="Private Code"
                        value={data.private_code}
                    />
                    <Divider className="m-1 p-1"/>
                </Col>

                <Col xs={24} md={12}>
                    <ViewInfo
                        title="Agency Name"
                        value={data.name}
                    />
                    <Divider className="m-1 p-1"/>
                </Col>


                <Col xs={24} md={12}>
                    <ViewInfo
                        title="Slug"
                        value={data.slug}
                    />
                    <Divider className="m-1 p-1"/>
                </Col>

                <Col xs={24} md={12}>
                    <ViewInfo
                        title="Services"
                        value={data.services}
                    />
                    <Divider className="m-1 p-1"/>
                </Col>


                <Col xs={24} md={12}>
                    <ViewInfo
                        title="Agency Contact Number"
                        value={data.agency_contact_number}
                    />
                    <Divider className="m-1 p-1"/>
                </Col>


                <Col xs={24} md={12}>
                    <ViewInfo
                        title="Agency Contact Email"
                        value={data.agency_contact_email}
                    />
                    <Divider className="m-1 p-1"/>
                </Col>



                <Col xs={24} className="p-0">
                    <ModelActionButton
                        cancel_label="Close"
                        onCancel={props.handleModelClose}
                    />
                </Col>


            </Row>
        </CustomModal>
    )
}


export default ViewUserAgencyDetails;
