import React, {useEffect, useState} from 'react';
import {CustomTextField} from "../../global/components/fields/CustomTextField";
import {adminSignIn, isAuthenticated, logout} from "../../global/auth_functions";
import Logo from "../../assets/img/he_logo_black.png";
import Bg from "../../assets/img/bg_5.jpeg";
import {Button, Col, Row} from "antd";


export const AdminSignIn = props => {
    const url_query = new URLSearchParams(props.location.search);
    const return_path = (url_query.get("returnPath") || "dashboard") + "";

    const [data, setData] = useState({});
    const [helper_text, setHelperText] = useState({});
    const [is_loading, setIsLoading] = useState(false);


    useEffect(() => {
        const checkAuth = async () => {
            const is_auth = await isAuthenticated();
            if (is_auth) {
                props.history.push(`/admin/${return_path}`);
            }else{
                logout()
            }
        }

        checkAuth();
        document.title = "Sigin";
    }, [])


    const handleFieldChange = (name, value) => {
        setData({...data, [name]: value});
    }


    const onSubmit = async (e) => {
        e.preventDefault();
        await setIsLoading(true);

        let response = await adminSignIn(data.email, data.password);

        if (response.status) {
            props.history.push(`/admin/${return_path}`);
        } else {
            setHelperText(response.error_feedback)
        }

        await setIsLoading(false);
    }

    const forgetPassword = () =>{
        props.history.push("/admin/forget-password")
    }


    return (
        <main className="vh-100 overflow-hidden bg-light">
            <img src={Bg} className="vh-100 vw-100 position-absolute z-index-0"/>
        <Row className="d-flex justify-content-center align-items-start pt-5 mt-5">
            <Col xs={20} sm={16} md={10} lg={8}
                 className="shadow border-radius-10 bg-white d-flex flex-column align-items-center p-4 mt-5">

                <Col xs={21} sm={18} md={16} lg={8}>
                    <img src={Logo} className="w-100"/>
                </Col>

                <span className="text-danger mt-2">
                            {helper_text.non_field_errors}
                </span>

                <form className="w-100" onSubmit={onSubmit}>


                    <Row gutter={[12, 12]}>

                        <Col xs={24}>
                            <CustomTextField
                                label="Email"
                                name="email"
                                value={data.email}
                                onChange={handleFieldChange}
                                helper_text={helper_text.email}
                                className="mt-2"
                            />
                        </Col>


                        <Col xs={24}>
                            <CustomTextField
                                label="Password"
                                name="password"
                                value={data.password}
                                onChange={handleFieldChange}
                                helper_text={helper_text.password}
                                type="password"
                                autoComplete="current-password"
                                className="mt-3"
                            />
                        </Col>

                    </Row>


                    <Button
                        block
                        loading={is_loading}
                        disabled={is_loading}
                        onClick={onSubmit}
                        type="primary"
                        className="bg-black mt-4"
                    >
                        Sign In
                    </Button>


                    <Button
                        onClick={forgetPassword}
                        type="link"
                        block
                        className="text-end mt-2"
                    >
                        Forget password?
                    </Button>

                </form>

            </Col>
        </Row>
        </main>

    );
}

