import React, {useEffect, useState} from "react";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {ProductsURL} from "../../../global/urls";
import {isValidValue, openNotification, showConfirm, showError, upperCaseView} from "../../../global/helper_functions";
import {Alert, Col, Row} from "antd";
import {ActionButton} from "../../../global/components/ActionButton";
import CustomSelectField from "../../../global/components/fields/CustomSelectField";
import {file_source_type, product_type} from "../../../global/variables";
import {CustomTextField} from "../../../global/components/fields/CustomTextField";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import {CustomTextAreaField} from "../../../global/components/fields/CustomTextAreaField";
import {HELoading} from "../../../global/components/HELoading";
import Hide from "../../../global/components/Hide";
import {CustomUploadField} from "../../../global/components/fields/CustomUploadField";
import CustomCheckBox from "../../../global/components/fields/CustomCheckBox";
import CustomBadge from "../../../global/components/CustomBadge";

export const ProductDetails = props => {
    const is_new = props.is_new;
    const product_id = props.product_id || "";
    const slug = props.slug || "";
    const user_type = props.user_type || "";
    const is_agent = user_type === "Agent";

    const lang = props.language || "en";
    const [has_perm] = useState(checkPermission(permission_data.product.edit) || (product_id === "new" && checkPermission(permission_data.product.add)));
    const [data, setData] = useState({
        number_of_downloads: 1,
        product_type: "Digital Product",
        file_source_type: "File",
        is_sold_out: false,
        is_active: true
    });

    const [loading, setLoading] = useState(true);
    const [save_loading, setSaveLoading] = useState(false);
    const [currency, setCurrency] = useState("");
    const [helper_text, setHelperText] = useState({});


    const fetchData = async () => {
        let response = await httpRequestWithAuth(`${ProductsURL}${product_id}`, "GET");
        if (response.status) {
            setData(response.data);
            props.setProductData(response.data);
        } else {
            showError("Get Product Data", response.error_feedback.non_field_errors);
            onCancel();
        }
        setLoading(false);
    }


    const handleFieldChange = (name, value) => {
        let temp_helper_text = {...helper_text};
        let temp_data = {...data};
        temp_data[name] = value;

        if (name === "product_type") {
            if (value !== "Digital Product") {
                temp_data.file_source_type = "Link"
            }
            temp_data.file = ""
            temp_data.link = ""
        }

        if (name === "file") {
            temp_data[`${name}_filename`] = isValidValue(value) ? value.name : "";
        }

        delete temp_helper_text[name];
        setHelperText(temp_helper_text);

        setData(temp_data);
    }


    useEffect(() => {
        if (is_new) {
            let temp_data = {...data};
            Object.keys(data).map(data_key => {
                if (data_key.includes("_en")) {
                    let lang_key = data_key.replace("_en", `_${lang}`);
                    if (!isValidValue(temp_data[lang_key])) {
                        temp_data[lang_key] = temp_data[data_key];
                    }
                }
            })
            setData(temp_data);
        }

    }, [lang])


    useEffect(() => {
        if (!is_new) {
            fetchData();
        } else {
            setLoading(false);
        }

    }, [])


    const onCancel = () => {
        props.history.push(`/${slug}/products`);
    }


    const onDelete = async () => {
        await setLoading(true);
        let response = await httpRequestWithAuth(`${ProductsURL}${data.id}`, "DELETE");
        if (response.status) {
            openNotification("success", "Delete Product", `${data.title_en} product has been deleted`)
            onCancel();
        } else {
            openNotification("error", "Delete Product", response.error_feedback.non_field_errors)
        }
        await setLoading(false);
    }


    const handleDelete = () => {
        showConfirm("Delete Product", `Are you sure to delete ${data.title_en} product ?`, onDelete);
    }


    const onSave = async () => {
        await setLoading(true);
        await setSaveLoading(true);
        setHelperText({});
        let temp_data = {
            ...data
        }

        const form_data = new FormData();

        Object.keys(data).map(data_key => {
            if (isValidValue(data[data_key])) {
                form_data.append(data_key, data[data_key]);
            }
        })

        let response;
        if (is_new) {
            response = await httpRequestWithAuth(ProductsURL, "POST", form_data);
        } else {
            response = await httpRequestWithAuth(`${ProductsURL}${data.id}`, "PUT", form_data);
        }

        if (response.status) {
            props.setProductData(response.data);
            if (is_new) {
                openNotification("success", "Add Product", `${data.title_en} product has been added`);
            } else {
                openNotification("success", "Edit Product", response.data.msg || `${data.title_en} product has been updated`);
            }


            let to_tab = -1;

            switch (true) {
                case checkPermission(permission_data.product_gallery.view):
                    to_tab = 2;
                    break;
                default:
                    to_tab = -1;
            }

            if (to_tab > 0) {
                if (is_new) {
                    props.history.push(`/${slug}/products/${response.data.id}/?tab=${to_tab}`);
                } else {
                    // props.handleTabsChange(to_tab);
                    onCancel();
                }
            } else {
                onCancel();
            }


        } else {
            // openNotification("error", is_new?"Add Product":"Edit Product", response.error_feedback.non_field_errors)
            setHelperText(response.error_feedback)
        }

        await setLoading(false);
        await setSaveLoading(false);
    }


    return (
        <div className="paper pt-0 w-100">

            <Hide hide={!loading}>
                <HELoading/>

            <Hide hide={!save_loading}>
                <h3 className="font-size-16 w-100 text-center fw-bold">Please don't close page, it will take up to 4-5 minutes</h3>
            </Hide>
            </Hide>

            <Hide hide={loading}>
                <Row gutter={[12, 10]}>

                    <Hide hide={data.product_type !== "Digital Product"}>
                        <Col xs={24}>
                            <Alert
                                description="Important Note: Upon uploading your Digital Product, file should be compressed type like: .zip, .rar, etc. And maximum file size is 200 MB."
                                type="info"
                                showIcon
                                closable
                            />
                        </Col>
                    </Hide>

                    <Col xs={24} className="d-flex justify-content-between align-items-center">
                        <h2 className="font-size-22 mb-0">
                            <label className="text-start">
                                Details
                            </label>
                        </h2>

                        {/*<CustomBadge*/}
                        {/*    title={<h6 className="font-size-13 text-center w-100 text-white p-0 m-0 fw-bold">Sales Number: <label*/}
                        {/*            className="ms-1 fw-bolder">{data.sales_number || 0}</label></h6>}*/}
                        {/*/>*/}

                    </Col>


                    <Hide hide={is_new}>
                        <Col xs={24} md={4}>
                            <CustomTextField
                                view_only
                                label="Ref No"
                                name="ref_no"
                                value={data.ref_no}
                            />
                        </Col>

                        <Col xs={24} md={20}>
                            <CustomTextField
                                view_only
                                label={`Slug ${upperCaseView(lang)}`}
                                name={`slug_${lang}`}
                                value={data[`slug_${lang}`]}
                            />
                        </Col>
                    </Hide>

                    <Col xs={24} md={14}>
                        <CustomTextField
                            required
                            view_only={!has_perm}
                            label={`Title ${upperCaseView(lang)}`}
                            name={`title_${lang}`}
                            value={data[`title_${lang}`]}
                            onChange={handleFieldChange}
                            error={helper_text[`title_${lang}`] !== undefined}
                            helper_text={helper_text[`title_${lang}`]}
                        />
                    </Col>


                    <Col xs={24} md={5}>
                        <CustomTextField
                            required
                            view_only={!has_perm}
                            label="Price"
                            name="price"
                            value={data.price}
                            onChange={handleFieldChange}
                            error={helper_text.price !== undefined}
                            helper_text={helper_text.price}
                            type="number"
                            addonAfter="KWD"
                        />
                    </Col>

                    <Col xs={24} md={5}>
                        <CustomTextField
                            required
                            view_only={!has_perm}
                            label="Download limit"
                            name="download_limit"
                            value={data.download_limit}
                            onChange={handleFieldChange}
                            error={helper_text.download_limit !== undefined}
                            helper_text={helper_text.download_limit}
                            type="number"
                        />
                    </Col>


                    <Col xs={24} md={7}>
                        <CustomSelectField
                            required
                            view_only={!has_perm || !is_new}
                            label="Product Type"
                            name="product_type"
                            value={data.product_type}
                            onChange={handleFieldChange}
                            error={helper_text.product_type !== undefined}
                            helper_text={helper_text.product_type}
                            options={product_type}
                        />
                    </Col>


                    <Hide hide={data.product_type !== "Digital Product"}>
                        <Col xs={24} md={7}>
                            <CustomSelectField
                                required
                                view_only={!has_perm}
                                label="File Source Type"
                                name="file_source_type"
                                value={data.file_source_type}
                                onChange={handleFieldChange}
                                error={helper_text.file_source_type !== undefined}
                                helper_text={helper_text.file_source_type}
                                options={file_source_type}
                            />
                        </Col>
                    </Hide>


                    <Hide hide={data.file_source_type !== "Link" || data.product_type !== "Digital Product"}>
                        <Col xs={24} md={10}>
                            <CustomTextField
                                required
                                view_only={!has_perm}
                                label="Link"
                                name="link"
                                value={data.link}
                                onChange={handleFieldChange}
                                error={helper_text.link !== undefined}
                                helper_text={helper_text.link}
                            />
                        </Col>
                    </Hide>

                    <Hide hide={data.file_source_type !== "Link" || data.product_type !== "Affiliate Product"}>
                        <Col xs={24} md={17}>
                            <CustomTextField
                                required
                                view_only={!has_perm}
                                label="Affiliate Link"
                                name="affiliate_link"
                                value={data.affiliate_link}
                                onChange={handleFieldChange}
                                error={helper_text.affiliate_link !== undefined}
                                helper_text={helper_text.affiliate_link}
                            />
                        </Col>
                    </Hide>

                    <Hide hide={data.file_source_type !== "File"}>
                        <Col xs={24} md={10}>
                            <CustomUploadField
                                max_size={209715200}
                                max_size_name="200 MB"
                                required
                                view_only={!has_perm}
                                display_value={data.file_filename}
                                label="File"
                                name="file"
                                value={data.file}
                                onChange={handleFieldChange}
                                error={helper_text.file !== undefined}
                                helper_text={helper_text.file}
                            />
                        </Col>
                    </Hide>


                    <Col xs={24}>
                        <CustomTextAreaField
                            withTextEditor
                            view_only={!has_perm}
                            label={`Description ${upperCaseView(lang)}`}
                            name={`description_${lang}`}
                            value={data[`description_${lang}`]}
                            onChange={handleFieldChange}
                            error={helper_text[`description_${lang}`] !== undefined}
                            helper_text={helper_text[`description_${lang}`]}
                        />
                    </Col>

                    <Col xs={24}>
                        <CustomCheckBox
                            name="is_sold_out"
                            label="Is Sold Out"
                            value={data.is_sold_out}
                            onChange={handleFieldChange}
                        />
                    </Col>

                    <Col xs={24}>
                        <CustomCheckBox
                            name="is_active"
                            label="Is Active"
                            value={data.is_active}
                            onChange={handleFieldChange}
                        />
                    </Col>

                    <Col xs={24}>
                        <ActionButton
                            error_msg={helper_text.non_field_errors}
                            onDelete={handleDelete}
                            onCancel={onCancel}
                            allow_delete={isValidValue(data.id) && checkPermission(permission_data.product.delete)}
                            allow_save={has_perm}
                            onSave={onSave}
                        />
                    </Col>

                </Row>
            </Hide>
        </div>
    )
}
