import React, {useEffect, useState} from "react";
import CustomModal from "../../../../global/components/CustomModal";
import {Col, Row, Tabs} from "antd";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {PackagesPhotoAlbumURL, PackagesPendingChangesForModalURL} from "../../../../global/urls";
import {isValidValue, openNotification} from "../../../../global/helper_functions";
import {ModelActionButton} from "../../../../global/components/ModelActionButton";
import CustomCheckBox from "../../../../global/components/fields/CustomCheckBox";
import CustomSelectField from "../../../../global/components/fields/CustomSelectField";
import {package_status} from "../../../../global/variables";
import {getUserType} from "../../../../global/auth_functions";
import Hide from "../../../../global/components/Hide";
import ViewActionsDetails from "../../../../global/components/ViewActionsDetails";
import {CustomTextAreaField} from "../../../../global/components/fields/CustomTextAreaField";
import CustomRadioGroup from "../../../../global/components/fields/CustomRadioGroup";
import PackageBookedCustomersSelect from "../../../../global/components/fields/PackageBookedCustomersSelect";
import {CustomUploadField} from "../../../../global/components/fields/CustomUploadField";
import {CustomTabPane} from "../../../../global/components/CustomTabPane";
import CustomDragNDrop from "../../../../global/components/CustomDragNDrop";


const specific_for_options = [
    {label: "All Customers", value: 'false'},
    {label: "Specific Customers", value: 'true'},
]

export const PackagePhotoAlbumFormModal = props => {
    const package_id = props.package_id || ""
    const [is_admin] = useState(getUserType() === "Admin");
    const [data, setData] = useState({
        is_active: true,
        is_for_specific_customers: "false",
        specific_customers_emails: "",
        status: "Approved",
        add_type: "one",
        ...props.data
    });
    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});
    const [new_pending_changes, setNewPendingChanges] = useState({});
    const is_new = !isValidValue(data.id);
    const is_bulk_add = data.add_type === "bulk";

    useEffect(() => {
        if (isValidValue(data.id)) {
            getPendingChanges();
        }

    }, [])


    const getPendingChanges = async () => {
        let response = await httpRequestWithAuth(PackagesPendingChangesForModalURL, "GET", null, {
            model_name: "PackagePhotoAlbum",
            package: data.PackageId,
            object_id: data.id
        });

        if (response.status) {
            setNewPendingChanges(response.data)
        }

    }

    const handleFieldChange = (name, value) => {
        if (loading)return;

        let temp_helper_text = {...helper_text};
        delete temp_helper_text[name];
        setHelperText(temp_helper_text);

        let temp_data = {...data};
        temp_data[name] = value;

        if (name === "is_for_specific_customers") {
            temp_data.specific_customers_emails = "";
        }

        if (name === "add_type"){
            temp_data.photo = value==="bulk"?[]:"";
            delete temp_data.photo_filename;

        }

        if (["photo"].includes(name)){
            temp_data[`${name}_filename`] = isValidValue(value)? value.name: "";
        }

        // if (name === "photo") {
        //     temp_data[`display_${name}`] = isValidValue((value || {}).size) ? URL.createObjectURL(value) : null;
        // }

        setData(temp_data);
    }


    const onSave = async () => {
        setLoading(true);

        if (is_bulk_add){

            if (data.photo.length < 1){
                setHelperText({
                    photo: "add at least one photo",
                });
                setLoading(false);
                return;
            }


            for (let i = 0; i < data.photo.length; i++) {
                await createUpdatePhotoAlbum({
                    ...data,
                    photo: data.photo[i]
                });
            }

        }else {
            await createUpdatePhotoAlbum(data);
        }


        setLoading(false);

    }

    const createUpdatePhotoAlbum = async (album_data) => {

        const form_data = new FormData();
        Object.keys(album_data).map(Name => {
            form_data.append(Name, album_data[Name])
        })

        let response;


        if (is_new) {
            response = await httpRequestWithAuth(PackagesPhotoAlbumURL, "POST", form_data);
        } else {
            response = await httpRequestWithAuth(`${PackagesPhotoAlbumURL}${album_data.id}`, "PUT", form_data);
        }

        if (response.status) {
            if (is_new) {
                openNotification("success", "Add Package Photo Album", `photo has been added`)
            } else {
                openNotification("success", "Update Package Photo Album", response.data.msg || `photo has been updated`)
            }
            props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
        }
    }

    return (
        <CustomModal title="Package Photo Album Form" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>

                <Hide hide={isValidValue(data.id)}>
                <Col xs={24}>
                    <Tabs activeKey={data.add_type} onChange={value => handleFieldChange("add_type", value)}>

                        <CustomTabPane tab={<>
                            Add One Photo
                        </>} key="one"/>

                        <CustomTabPane tab={<>
                            Add Multiple Photo
                        </>} key="bulk"/>

                    </Tabs>
                </Col>
                </Hide>

                <Hide hide={is_bulk_add}>
                <Col xs={24} md={is_admin?12:24}>
                    <CustomUploadField
                        required
                        display_value={data.photo_filename}
                        label="Photo"
                        name="photo"
                        value={data.photo}
                        onChange={handleFieldChange}
                        error={helper_text.photo !== undefined}
                        helper_text={helper_text.photo}
                        accept="image/*"
                    />
                </Col>
                </Hide>

                <Hide hide={!is_bulk_add}>
                    <Col xs={24}>
                    <CustomDragNDrop
                        multiple
                        label="Click or drop photos here"
                        name="photo"
                        value={data.photo || []}
                        onChange={handleFieldChange}
                        error={helper_text.photo !== undefined}
                        helper_text={helper_text.photo}
                        accept="image/*"
                    />
                    </Col>
                </Hide>

                <Hide hide={!is_bulk_add}>
                    <Col xs={24} md={12}>
                        <CustomRadioGroup
                            label="Share With"
                            name="is_for_specific_customers"
                            value={data.is_for_specific_customers + ""}
                            onChange={handleFieldChange}
                            error={helper_text.is_for_specific_customers !== undefined}
                            helper_text={helper_text.is_for_specific_customers}
                            warning_tooltip={new_pending_changes[`is_for_specific_customers`]}
                            warning_tooltip_title="New Value Waiting Approve:"
                            options={specific_for_options}
                            type="default"
                        />
                    </Col>
                </Hide>

                <Hide hide={!is_admin}>
                    <Col xs={24} md={12}>
                        <CustomSelectField
                            label="Status"
                            name="status"
                            value={data.status}
                            onChange={handleFieldChange}
                            error={helper_text.status !== undefined}
                            helper_text={helper_text.status}
                            options={package_status}
                            warning_tooltip={new_pending_changes[`status`]}
                            warning_tooltip_title="New Value Waiting Approve:"
                        />
                    </Col>
                </Hide>

                <Hide hide={is_bulk_add}>
                <Col xs={24}>
                    <CustomRadioGroup
                        label="Share With"
                        name="is_for_specific_customers"
                        value={data.is_for_specific_customers + ""}
                        onChange={handleFieldChange}
                        error={helper_text.is_for_specific_customers !== undefined}
                        helper_text={helper_text.is_for_specific_customers}
                        warning_tooltip={new_pending_changes[`is_for_specific_customers`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                        options={specific_for_options}
                        type="default"
                    />
                </Col>
                </Hide>

                <Hide hide={[false, "false"].includes(data.is_for_specific_customers)}>
                    <Col xs={24}>
                        <PackageBookedCustomersSelect
                            package_id={package_id}
                            multiple
                            label="Specific Customer"
                            name="specific_customers_emails"
                            value={data.specific_customers_emails}
                            onChange={handleFieldChange}
                            error={helper_text.specific_customers_emails !== undefined}
                            helper_text={helper_text.specific_customers_emails}
                            warning_tooltip={new_pending_changes[`specific_customers_emails`]}
                            warning_tooltip_title="New Value Waiting Approve:"
                        />
                    </Col>
                </Hide>

                <Col xs={24}>
                    <CustomTextAreaField
                        label="Description"
                        name="description"
                        value={data.description}
                        onChange={handleFieldChange}
                        error={helper_text.description !== undefined}
                        helper_text={helper_text.description}
                        warning_tooltip={new_pending_changes[`description`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                        rows={2}
                    />
                </Col>

                <Col xs={24}>
                    <CustomCheckBox
                        label="Is Active"
                        name="is_active"
                        value={data.is_active}
                        onChange={handleFieldChange}
                        warning_tooltip={new_pending_changes[`is_active`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                    />
                </Col>

                <Hide hide={is_new}>
                    <ViewActionsDetails data={data}/>
                </Hide>

                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}
