import {Button} from "antd";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {ITSupportGetIncomingDetailsURL} from "../../../global/urls";
import React, {useState} from "react";
import {showError, showInfo} from "../../../global/helper_functions";
import { CSVLink } from "react-csv";
import moment from "moment";

const GetIncomingDetails = (props) => {
    const it_support_data = props.it_support_data || {};
    const [loading, setLoading] = useState(false);

    const getIncomingDetails = async ()=>{
        await setLoading(true);
        const password = it_support_data.password || "";

        let response = await httpRequestWithAuth(ITSupportGetIncomingDetailsURL, "POST", {password});
        if (response.status){
            showInfo("Done",
                <CSVLink className="pe-2" c data={response.data} filename={`Incoming Details - ${moment().format("DD-MM-YYYY__hh:mm:ss")}`}>
                <Button
                    block
                    type="primary"
                    className=" m-md-1"
                >
                    Download Data
                </Button>
            </CSVLink>
            )
        }else{
            showError("Failed", response.error_feedback.non_field_errors)
        }
        await setLoading(false)
    }

  return(
      <Button type="primary" loading={loading} onClick={getIncomingDetails} block>
          Get Incoming Details
      </Button>
  )
}


export default GetIncomingDetails;
