import Hide from "../../../global/components/Hide";
import {Col, Row} from "antd";
import {Loading} from "../../../global/components/Loading";
import React, {useState} from "react";
import CustomModal from "../../../global/components/CustomModal";
import {openNotification} from "../../../global/helper_functions";
import {CustomTextField} from "../../../global/components/fields/CustomTextField";
import CustomSelectField from "../../../global/components/fields/CustomSelectField";
import {payment_status} from "../../../global/variables";
import {ModelActionButton} from "../../../global/components/ModelActionButton";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {BookingsUpdateServiceChargePaymentURL} from "../../../global/urls";
import {CustomDateField} from "../../../global/components/fields/CustomDateField";

const BookingsUpdateServiceChargePaymentModal = (props) => {
    const booking = props.booking || "";
    const [data, setData] = useState({...props.data});
    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});


    const onChange = (name, value) => {
        let temp_data = {...data};
        if (name==="is_service_charge_paid"){
            value = value === "Paid"
        }
        temp_data[name] = value;
        setData(temp_data)
    };


    const onSave = async () => {
      setLoading(true);
      setHelperText({});

      let response = await httpRequestWithAuth(`${BookingsUpdateServiceChargePaymentURL}${booking}`, "PATCH", data);

      if (response.status){
          openNotification("success", "Update Service Charge Payment", `Service Charge Payment has been updated`)
          props.handleModelClose();
      }else{
          setHelperText(true)
      }

      setLoading(false)
    }


    return (<CustomModal title={`Booking Receipt #${props.data.ref_no}: Update Service Charge Payment`} paddingTop="0"
                         visible={props.open} onCancel={props.handleModelClose}>


        <Row gutter={[12, 10]}>
            <Hide hide={!loading}>
                <Col xs={24}>
                    <Loading/>
                </Col>
            </Hide>


            <Hide hide={loading}>



                <Col xs={24} md={6}>
                    <CustomSelectField
                        required
                        label="Payment Status"
                        name="is_service_charge_paid"
                        value={data.is_service_charge_paid?"Paid":"Pending"}
                        onChange={onChange}
                        error={helper_text.is_service_charge_paid !== undefined}
                        helper_text={helper_text.is_service_charge_paid}
                        options={payment_status}
                        inputClassName={data.is_service_charge_paid ? "text-success fw-bold" : ""}
                    />
                </Col>

                    <Col xs={24} md={9}>
                        <CustomTextField
                            disabled={!data.is_service_charge_paid}
                            label="Service Charge Paid Ref No."
                            name="service_charge_paid_ref_no"
                            value={data.service_charge_paid_ref_no}
                            error={helper_text.service_charge_paid_ref_no !== undefined}
                            helper_text={helper_text.service_charge_paid_ref_no }
                            onChange={onChange}
                        />
                    </Col>

                    <Col xs={24} md={9}>
                        <CustomDateField
                            disabled={!data.is_service_charge_paid}
                            label="Service Charge Paid Date"
                            name="service_charge_paid_date"
                            value={data.service_charge_paid_date}
                            error={helper_text.service_charge_paid_date !== undefined}
                            helper_text={helper_text.service_charge_paid_date }
                            onChange={onChange}
                        />
                    </Col>


                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Hide>


        </Row>
    </CustomModal>
    )
}

export default BookingsUpdateServiceChargePaymentModal;
