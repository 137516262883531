import React, {useState} from 'react'
import {PackageAutoComplete} from '../../../global/components/fields/PackageAutoComplete'
import {Col, Row} from 'antd'
import {httpRequestWithAuth} from '../../../global/axios_instance';
import {isValidValue, openNotification} from '../../../global/helper_functions';
import {TestimonialsURL} from '../../../global/urls'
import CustomModal from '../../../global/components/CustomModal';
import {CustomTextAreaField} from "../../../global/components/fields/CustomTextAreaField";
import CustomRate from "../../../global/components/fields/CustomRate";
import Hide from "../../../global/components/Hide";
import {HELoading} from "../../../global/components/HELoading";
import {CustomTextField} from "../../../global/components/fields/CustomTextField";
import CustomRadioField from "../../../global/components/fields/CustomRadioField";
import {testimonial_feedback_for_options} from "../../../global/variables";
import CustomCheckBox from "../../../global/components/fields/CustomCheckBox";
import {AgencyAutoComplete} from "../../../global/components/fields/AgencyAutoComplete";
import {ModelActionButton} from "../../../global/components/ModelActionButton";
import {CustomPhoneNumberField} from "../../../global/components/fields/CustomPhoneNumberField";
import {ConsultationRequestAutoComplete} from "../../../global/components/fields/ConsultationRequestAutoComplete";

const TestimonialFormModal = (props) => {

    const [data, setData] = useState({
        view_in_home_page: false,
        is_active: true,
        rating: 5,
        feedback_for: "Package",
        ...props.data
    });

    const [loading, setLoading] = useState(false);

    const [helper_text, setHelperText] = useState({});


    const handleFieldChange = (name, value) => {
        let temp_data = {...data};
        temp_data[name] = value || "";

        setData(temp_data);
    }


    const onSave = async () => {

        await setLoading(true);
        const is_new = !isValidValue(data.id);
        let response;
        if (is_new) {
            response = await httpRequestWithAuth(TestimonialsURL, "POST", data);
        } else {
            response = await httpRequestWithAuth(`${TestimonialsURL}${data.id}`, "PUT", data);

        }
        if (response.status) {
            if (is_new) {
                openNotification("success", "Create Testimonial", `Testimonial  Created Successfully`)
            } else {
                openNotification("success", "Update Testimonial", `Testimonial  Updated Successfully`)
            }

            props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
        }
        await setLoading(false);


    }
    return (<CustomModal size="md" title="Testimonials Form" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>

                <Hide hide={!loading}>
                    <HELoading/>
                </Hide>


                <Col xs={24}>
                    <CustomRate
                        required
                        name="rating"
                        label="Rating"
                        value={data.rating}
                        onChange={handleFieldChange}
                        type="number"
                        // error={helper_text.rating !== undefined}
                        // helper_text={helper_text.rating}
                    />
                </Col>


                <Hide hide={loading}>
                    <Col xs={24} md={8}>
                        <CustomTextField
                            required
                            label="Customer Name"
                            name="customer_name"
                            value={data.customer_name}
                            onChange={handleFieldChange}
                            error={helper_text.customer_name !== undefined}
                            helper_text={helper_text.customer_name}
                        />
                    </Col>

                    <Col xs={24} md={8}>
                        <CustomTextField
                            required
                            label="Customer Email"
                            name="customer_email"
                            value={data.customer_email}
                            onChange={handleFieldChange}
                            error={helper_text.customer_email !== undefined}
                            helper_text={helper_text.customer_email}
                        />
                    </Col>

                    <Col xs={24} md={8}>
                        <CustomPhoneNumberField
                            required
                            label="Customer Contact Number"
                            name="customer_contact_number"
                            value={data.customer_contact_number}
                            onChange={handleFieldChange}
                            error={helper_text.customer_contact_number !== undefined}
                            helper_text={helper_text.customer_contact_number}
                        />
                    </Col>


                    <Col xs={24}>
                        <CustomTextAreaField
                            label="Feedback"
                            name="feedback"
                            value={data.feedback}
                            onChange={handleFieldChange}
                            error={helper_text.feedback !== undefined}
                            helper_text={helper_text.feedback}
                        />
                    </Col>


                    <Col xs={24} md={12} className="mb-0">
                        <CustomRadioField
                            required
                            label="Feedback For"
                            name="feedback_for"
                            value={data.feedback_for}
                            onChange={handleFieldChange}
                            options={testimonial_feedback_for_options}
                            error={helper_text.feedback_for !== undefined}
                            helper_text={helper_text.feedback_for}
                        />
                    </Col>

                    <Hide hide={data.feedback_for !== "Package"}>
                        <Col xs={24}>
                            <PackageAutoComplete
                                keep_current_value
                                required
                                multiple
                                label="Packages"
                                name="packages_id"
                                value={data.packages_id}
                                onChange={handleFieldChange}
                                error={helper_text.packages_id !== undefined}
                                helper_text={helper_text.packages_id}
                            />
                        </Col>
                    </Hide>

                    <Hide hide={data.feedback_for !== "Agency"}>
                        <Col xs={24}>
                            <AgencyAutoComplete
                                required
                                label="Agency"
                                name="AgencyId"
                                value={data.AgencyId}
                                onChange={handleFieldChange}
                                error={helper_text.AgencyId !== undefined}
                                helper_text={helper_text.AgencyId}
                            />
                        </Col>
                    </Hide>

                    <Hide hide={data.feedback_for !== "Consultation"}>
                        <Col xs={24}>
                            <ConsultationRequestAutoComplete
                                required
                                label="Consultation Request"
                                name="ConsultationRequestId"
                                value={data.ConsultationRequestId}
                                onChange={handleFieldChange}
                                error={helper_text.ConsultationRequestId !== undefined}
                                helper_text={helper_text.ConsultationRequestId}
                            />
                        </Col>
                    </Hide>

                    <Col xs={24}>
                        <CustomCheckBox
                            label="Is Active"
                            name="is_active"
                            value={data.is_active}
                            onChange={handleFieldChange}
                        />
                    </Col>


                    <Col xs={24}>
                        <CustomCheckBox
                            label="View in home"
                            name="view_in_home_page"
                            value={data.view_in_home_page}
                            onChange={handleFieldChange}
                        />
                    </Col>

                    <Col xs={24} className="p-0">
                        <ModelActionButton
                            error_msg={helper_text.non_field_errors}
                            loading={loading}
                            onSave={onSave}
                            onCancel={props.handleModelClose}
                        />
                    </Col>

                </Hide>

            </Row>
        </CustomModal>

    )
}

export default TestimonialFormModal
