import { httpRequestWithAuth } from "./axios_instance";
import { UsersGetAllPermissionsURL } from "./urls";
export let user_permissions = {};

export const setPermissions = (perms) => {
  user_permissions = perms;
};

export const getAllPermissions = async () => {
  let response = await httpRequestWithAuth(
    `${UsersGetAllPermissionsURL}`,
    "GET"
  );
  if (response.status) {
    await setPermissions(response.data);
  }
  return { status: true };
};


export const checkPermission = (perm) => {
  return user_permissions[perm];
};


export const checkAllPermission = (perms) => {
  let has_perm = true;
  for (let i = 0; i < perms.length; i++) {
    if (!user_permissions[perms[i]]) {
      has_perm = false;
      break;
    }
  }
  return has_perm;
};


export const checkOnePermission = (perms) => {
  let has_perm = false;
  for (let i = 0; i < perms.length; i++) {
    if (user_permissions[perms[i]]) {
      has_perm = true;
      break;
    }
  }
  return has_perm;
};
