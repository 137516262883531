import React, {useEffect, useState} from "react";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {GetBookingsNumberURL} from "../../../global/urls";
import {ComponentThree} from "./ComponentThree";
import moment from "moment";
import {checkPermission} from "../../../global/permissions";
import {permission_data} from "../../../global/permissions_data";
import {Button} from "antd";

const MonthBookingNumber = (props) => {
    const slug = props.slug;

    const [data, setData] = useState(0);
    const [has_perm] = useState(checkPermission(permission_data.reports.view_month_booking_number));

    useEffect(() => {
        const fetchData = async () => {
            if (has_perm) {
                let from_date = moment().startOf('month')
                let to_date = moment().endOf('month')

                from_date = from_date.format("YYYY-MM-DD")
                to_date = to_date.format("YYYY-MM-DD")

                let response = await httpRequestWithAuth(GetBookingsNumberURL, "GET", null, {from_date, to_date});
                if (response.status) {
                    setData(response.data);
                }
            }
        }

        fetchData();
    }, [])



    const onClick = () =>{
        let from_date = moment().startOf('month')
        let to_date = moment().endOf('month')

        from_date = from_date.format("YYYY-MM-DD")
        to_date = to_date.format("YYYY-MM-DD")

        props.history.push(`/${slug}/bookings?from-created-date=${from_date}&to-created-date=${to_date}`)
    }

    return (
        <Button className="w-100 h-100 border-0 text-start p-0 m-0" onClick={onClick}>
            <ComponentThree title="Month Bookings" value={data}/>
        </Button>
    )


}


export default MonthBookingNumber;
