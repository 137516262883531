import React, {useEffect, useState} from "react";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {GetSalesIncomingURL} from "../../../global/urls";
import {ComponentThree} from "./ComponentThree";
import moment from "moment";
import {checkPermission} from "../../../global/permissions";
import {permission_data} from "../../../global/permissions_data";
import {ViewInfo} from "../../../global/components/ViewInfo";

const WeekSalesIncoming = () => {
    const [data, setData] = useState({
        total: 0,
        he_commission_amount: 0,
        agency_accrual_amount: 0,
    });
    const [has_perm] = useState(checkPermission(permission_data.reports.view_week_incoming));

    useEffect(() => {
        const fetchData = async () => {

            if (has_perm) {
                let from_date = moment().weekday(1).add(-1, "days")
                let to_date = moment().weekday(1).add(5, "days")

                from_date = from_date.format("YYYY-MM-DD")
                to_date = to_date.format("YYYY-MM-DD")

                let response = await httpRequestWithAuth(GetSalesIncomingURL, "GET", null, {from_date, to_date});
                if (response.status) {
                    setData(response.data);
                }
            }
        }

        fetchData();
    }, [])


    return (
        <>
            <ComponentThree title="Week Revenue" value={`${data.total} KWD`}/>

            {/*<div className="w-100 d-flex justify-content-between mt-3">*/}
            {/*    <ViewInfo*/}
            {/*        title="HE Amount"*/}
            {/*        value={<span className="text-primary font-size-14">{`${data.he_commission_amount} KWD`}</span>}*/}
            {/*    />*/}

            {/*    <ViewInfo*/}
            {/*        title="Agency Accrual"*/}
            {/*        value={<span className="text-success font-size-14">{`${data.agency_accrual_amount} KWD`}</span>}*/}
            {/*    />*/}
            {/*</div>*/}
        </>
    )

}


export default WeekSalesIncoming;
