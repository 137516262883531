import {Button, Col, Row} from "antd";
import {CustomDynamicTable} from "../../global/components/CustomDynamicTable";
import {useState} from "react";
import {AgenciesDocumentURL} from "../../global/urls";
import {httpRequestWithAuth} from "../../global/axios_instance";
import {isValidValue, openNotification} from "../../global/helper_functions";
import {permission_data} from "../../global/permissions_data";
import {checkPermission} from "../../global/permissions";
import {AddButton} from "../../global/components/AddButton";
import Hide from "../../global/components/Hide";
import {AgencyDocumentFormModal} from "./components/AgencyDocumentFormModal";

export const AgencyDocuments = props => {
    const {agency_id} = props;
    const [open_from_data, setOpenFormData] = useState(false);
    const [form_data, setFormData] = useState({});
    const [reload, setReload] = useState(false);

    const [agency_document_perm] = useState({
        delete: checkPermission(permission_data.agency_document.delete),
        edit: checkPermission(permission_data.agency_document.edit),
        add: checkPermission(permission_data.agency_document.add),
    });

    const [columns] = useState([

        {
            title: "Name",
            dataIndex: "name",
            width: "50%",
        },
        {
            title: "",
            dataIndex: "display_file",
            width: "20%",
            align: "center",
            render: (value) => {
                    return (
                        <a href={value} target="_blank">
                            <Button>
                                View Document
                            </Button>
                        </a>
                    )
            }
        },
    ])

    const handelOpenFormData = (data = {}) => {
        setFormData(data)
        setOpenFormData(true)
    }


    const handelCloseFormData = (reload_data = false) => {
        setFormData({});
        setOpenFormData(false);

        if (reload_data) {
            setReload(Math.random());
        }

    }

    const onDelete = async (row_data) => {
        let response = await httpRequestWithAuth(`${AgenciesDocumentURL}${row_data.id}`, "DELETE");

        if (response.status) {
            openNotification("success", "Delete Agency Document", `${row_data.name} document has been deleted`)
        } else {
            openNotification("error", "Delete Agency Document", response.error_feedback.non_field_errors)
        }
    }

    const onEdit = async (row_data) => {
        handelOpenFormData(row_data)
    }

    const onAdd = async () => {
        handelOpenFormData()
    }

    const freeAction = (
        <>
            <AddButton onClick={onAdd}>Add Agency Document</AddButton>
        </>
    );

    return (
        <Row>
            <Hide hide={!open_from_data}>
                <AgencyDocumentFormModal
                    open={open_from_data}
                    data={{AgencyId: agency_id, ...form_data}}
                    handleModelClose={handelCloseFormData}
                    agency_id={agency_id}
                />
            </Hide>

            <Col xs={24}>
                <CustomDynamicTable
                    title="Agencies Documents"
                    reload_data={reload}
                    columns={columns}
                    fetch_url={AgenciesDocumentURL}
                    free_action={freeAction}
                    custom_params={{agency: agency_id}}
                    onDelete={agency_document_perm.delete && onDelete}
                    onEdit={agency_document_perm.edit && onEdit}
                />
            </Col>
        </Row>
    )
}
