import React from "react";
import {Button, Col, Image, Row, Tooltip} from "antd";
import {CustomDynamicTable} from "../../../global/components/CustomDynamicTable";
import {useEffect, useState} from "react";
import {PackagesAddonURL} from "../../../global/urls";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {openNotification} from "../../../global/helper_functions";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import {AddButton} from "../../../global/components/AddButton";
import Hide from "../../../global/components/Hide";
import {PackageAddonFormModal} from "./components/PackageAddonFormModal";
import {CustomStatusView} from "../../../global/components/CustomStatusView";
import CustomBadge from "../../../global/components/CustomBadge";
import {PRIMARY_COLOR, WARNING_COLOR} from "../../../global/variables";
import {InfoIconGreenFilled} from "../../../global/components/Icons";
import {ModelActionButton} from "../../../global/components/ModelActionButton";

export const PackageAddon = props =>{
    const package_id = props.package_id || "";
    const slug = props.slug || "";
    const language = props.language || "en";
    const package_data = props.package_data || {};
    const {is_admin, has_pending_status, approve_pending_status_loading, approveAllPendingStatus} = props;

    const [open_from_data, setOpenFormData] = useState(false);
    const [form_data, setFormData] = useState({});
    const [reload, setReload] = useState(false);

    const [package_addon_perm] = useState({
        delete: checkPermission(permission_data.package_addon.delete),
        edit: checkPermission(permission_data.package_addon.edit),
        add: checkPermission(permission_data.package_addon.add),
    });


    const getColumns = language =>[
        {
            title:"Pending Changes",
            dataIndex: "has_changes",
            width: "5%",
            align:"center",
            render: (value, row_data)=> {
                if (!value)return <CustomBadge type="disabled" title="No Changes"/>;
                return(
                    <CustomBadge
                        bg_color={WARNING_COLOR}
                        title="View Changes"
                        onClick={()=>goToPendingChanges(row_data)}
                    />
                )
            }
        },
        {
            title: "Photo",
            dataIndex: "display_photo",
            width: "20%",
            align: "center",
            render:(value)=>(
                <Image
                    width={100}
                    src={value}
                />
            )
        },
        {
            title: <label>Title <span className="text-capitalize">{language}</span></label>,
            dataIndex: `title_${language}`,
            width: "15%"
        },
        {
            title: "Price",
            dataIndex: "price",
            width: "10%",
            align: "center",
            render: (value)=> `${parseFloat(value)} ${package_data.currency}`
        },
        {
            title: "Qty",
            dataIndex: "qty",
            width: "10%",
            align: "center",
        },
        {
            title: "Applied By",
            dataIndex: "applied_by",
            width: "10%",
            align: "center",
            hide: !is_admin
        },
        {
            title: "Status",
            dataIndex: "status",
            width: "5%",
            align:"center",
            render: (value)=> <CustomStatusView value={value}/>
        },
    ]

    const [columns, setColumns] = useState(getColumns(language));


    useEffect(()=>{
        setColumns(getColumns(language));
    }, [language, package_data.currency])

    const handelOpenFormData = (data={})=>{
        setFormData({...data, PackageId:package_id})
        setOpenFormData(true)
    }

    const handelCloseFormData = (reload_data=false)=>{
        setFormData({});
        setOpenFormData(false);

        if (reload_data){
            setReload(Math.random());
            props.getPackageData();
        }
    }

    const onDelete = async (row_data) =>{
        let response = await httpRequestWithAuth(`${PackagesAddonURL}${row_data.id}`, "DELETE");
        if (response.status){
            props.getPackageData();
            openNotification("success", "Delete Package Addon", `${row_data.title_en} package addon has been deleted`)
        }else{
            openNotification("error", "Delete Package Addon", response.error_feedback.non_field_errors)
        }
    }

    const onEdit = async (row_data) =>{
        handelOpenFormData({...row_data, PackageId: package_id});
    }

    const onAdd = async () =>{
        handelOpenFormData({PackageId: package_id})
    }
    const onApproveAllPending = () => {
        setReload(Math.random());
    }

    const approveAllPendingStatusBtn = () =>{
        approveAllPendingStatus("PackageAddons", onApproveAllPending)
    }

    const goToPendingChanges = (row_data) =>{
        props.handleTabsChange("8", {search: row_data.id})
    }

    const freeAction = (
        <>
            <Hide hide={!has_pending_status || !is_admin}>
                <Button
                    block
                    type="primary"
                    className="mb-1 m-md-1 justify-content-center"
                    onClick={approveAllPendingStatusBtn}
                    loading={approve_pending_status_loading}
                >
                    Approve All Pending Status
                </Button>
            </Hide>

            <AddButton onClick={onAdd}>Add Addon</AddButton>
        </>
    );

    return(
        <div className="paper pt-0 w-100">
        <Row>
            <Hide hide={!open_from_data}>
                <PackageAddonFormModal
                    language={language}
                    open={open_from_data}
                    data={form_data}
                    handleModelClose={handelCloseFormData}
                    package_id={package_id}
                />
            </Hide>

                <CustomDynamicTable
                    title={
                    <label className="text-start">
                        Add-ons
                        <Tooltip
                            className="ms-1"
                            placement="topLeft"
                            title="A sellable service or product that is added to your package. "
                            color={PRIMARY_COLOR}
                            arrowPointAtCenter
                        >
                                                                   <span className="p-0 m-0">
                                                                       <InfoIconGreenFilled/>
                                                                   </span>
                        </Tooltip>

                        <br/>
                        <span className="font-size-13 text-black-50">If your package doesn't have any add-ons, you may skip this section</span>

                    </label>
                    }
                    // re_render_columns={language}
                    reload_data={reload}
                    columns={columns}
                    fetch_url={PackagesAddonURL}
                    free_action={freeAction}
                    onDelete={package_addon_perm.delete && onDelete}
                    onEdit={package_addon_perm.edit && onEdit}
                    custom_params={{"package": package_id}}
                />

            <Col xs={24} className="mt-3">
                <ModelActionButton
                    hide_icon
                    cancel_label="Back"
                    onCancel={props.onBack}
                    saveLabel="Next"
                    onSave={props.onNext}
                />
            </Col>
        </Row>
        </div>
    )
}
