import {useState} from "react";
import {Button, Col, Row} from "antd";
import {CustomDateField} from "../../../../global/components/fields/CustomDateField";
import CustomModal from "../../../../global/components/CustomModal";
import {ModelActionButton} from "../../../../global/components/ModelActionButton";
import {PackageAutoComplete} from "../../../../global/components/fields/PackageAutoComplete";
import Hide from "../../../../global/components/Hide";
import CustomSelectField from "../../../../global/components/fields/CustomSelectField";
import {package_type} from "../../../../global/variables";

const FilterModal = (props) => {
    const include_booking_type = props.include_booking_type;
    const include_year = props.include_year;

    const [filter, setFilter] = useState({...props.filter});

    const handelFieldChange = (name, value) => {
        setFilter({...filter, [name]: value})
    }
    const clearFilter = () => {
        setFilter({})
    }

    const onSave = () => {
        props.onFilter(filter);
        props.handleModelClose()
    }
    return (
        <CustomModal size="xs" title={props.title} visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>


                <Col xs={24} className="w-100 d-flex justify-content-end m-0 p-0">
                    <Button size="small" type="link" onClick={clearFilter} className="font-size-13 p-0 m-0">
                        Clear Filter
                    </Button>
                </Col>

                <Hide hide={!include_booking_type}>
                <Col xs={24} md={12} className="mt-0 pt-0">
                    <CustomSelectField
                        label="Booking Type"
                        name="booking_type"
                        value={filter.booking_type}
                        onChange={handelFieldChange}
                        options={package_type}
                    />
                </Col>
                </Hide>

                <Hide hide={!include_year}>
                <Col xs={24} md={12} className="mt-0 pt-0">
                    <CustomDateField
                        views={["year"]}
                        open_on="year"
                        format="YYYY"
                        label="Year"
                        name="year"
                        value={filter.year}
                        onChange={handelFieldChange}
                    />
                </Col>
                </Hide>

                <Col xs={24} md={include_booking_type?include_year?24:12:24} className="mt-0 pt-0">
                    <PackageAutoComplete
                        package_type={props.package_type || filter.booking_type}
                        label="Package"
                        name="package_id"
                        value={filter.package_id}
                        onChange={handelFieldChange}
                    />
                </Col>

                <Hide hide={include_year}>
                <Col xs={24} md={12}>
                    <CustomDateField
                        label="From Date"
                        name="from_date"
                        value={filter.from_date}
                        onChange={handelFieldChange}
                    />
                </Col>

                <Col xs={24} md={12}>
                    <CustomDateField
                        label="To Date"
                        name="to_date"
                        value={filter.to_date}
                        onChange={handelFieldChange}
                    />
                </Col>
                </Hide>


                <Col xs={24} className="p-0">
                    <ModelActionButton
                        onSave={onSave}
                        saveLabel="Filter"
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}


export default FilterModal;
