import {Button, Col, Row} from "antd";
import {CustomDynamicTable} from "../../global/components/CustomDynamicTable";
import React, {useEffect, useState} from "react";
import {
    ConsultationResponseSendFeedbackByEmailURL,
    ConsultationResponseSendPaymentRequestByEmailURL,
    ConsultationResponsesURL
} from "../../global/urls";
import {httpRequestWithAuth} from "../../global/axios_instance";
import {copyText, isValidValue, openNotification} from "../../global/helper_functions";
import {permission_data} from "../../global/permissions_data";
import {checkPermission} from "../../global/permissions";
import {AddButton} from "../../global/components/AddButton";
import {ConsultationResponseFormModal} from "./components/ConsultationResponseFormModal";
import Hide from "../../global/components/Hide";
import {IsActiveView} from "../../global/components/IsActiveView";
import {ViewInfo} from "../../global/components/ViewInfo";
import {ConsultationGeneratePaymentLinkModal} from "./components/ConsultationGeneratePaymentLinkModal";


export const ConsultationResponses = props => {
    const {slug} = props;
    const consultation_request = props.consultation_id
    const consultation_request_ref = props.consultation_data.ref_no;
    const consultation_request_subject = props.consultation_data.subject;
    const consultation_request_name = props.consultation_data.name;
    const consultation_type = props.consultation_data.consultation_type;

    const [open_from_data, setOpenFormData] = useState(false);
    const [form_data, setFormData] = useState({});
    const [reload, setReload] = useState(false);
    const [open_generate_link, setOpenGenerateLink] = useState(false);
    const [generate_link_data, setGenerateLinkData] = useState("");

    useEffect(()=>{
        if (!isValidValue(consultation_request)){
            props.history.push(`/${slug}/consultations/requests`)
        }
    },[])

    const [consultation_response_perm] = useState({
        delete: checkPermission(permission_data.consultation_response.delete),
        edit: checkPermission(permission_data.consultation_response.edit),
        add: checkPermission(permission_data.consultation_response.add),
        send_payment_request: checkPermission(permission_data.consultation_response.send_payment_request),
        can_send_consultation_response_email: checkPermission(permission_data.consultation_response.can_send_consultation_response_email),
    });


    const [columns] = useState([
        {
            title: "Response Ref",
            dataIndex: "ref_no",
            width: "10%",
        },
        // {
        //     title: "Requester Name",
        //     dataIndex: "request_name",
        //     width: "20%",
        // },
        // {
        //     title: "Request Subject",
        //     dataIndex: "request_subject",
        //     width: "20%",
        // },
        {
            title: "Price",
            dataIndex: "price",
            width: "8%",
            align:"center",
        },
        {
            title: "Discount Amount",
            dataIndex: "discount_amount",
            width: "15%",
            align:"center",
        },
        {
            title: "Net Total",
            dataIndex: "net_total",
            width: "10%",
            align:"center",
        },
        {
            title: "Downloads",
            dataIndex: "download_limit",
            width: "10%",
            align:"center",
            render: (value, row_data)=>`${row_data.number_of_downloads} / ${row_data.download_limit}`
        },
        {
            title: "Available Until",
            dataIndex: "available_until_view",
            width: "15%",
            align:"center",
            render: (value)=>  value || "-----"
        },
        {
            title: "Availability",
            dataIndex: "is_available",
            width: "5%",
            align:"center",
            render: (value)=><IsActiveView active_label="Available" inactive_label="Unavailable" value={value} view_only/>
        },
        {
            title: "Payment",
            dataIndex: "is_paid",
            width: "5%",
            align:"center",
            render: (value)=><IsActiveView active_label="Paid" inactive_label="Unpaid" value={value} view_only/>
        },
    ])

    const onCopy = (value) => {
        openNotification("success", "", `Customer Link has been copied`)
        copyText(value);
    }

    const extra_actions = [
        {
            key: "-1",
            label: "Copy Customer Link",
            disabled: (row_data)=>!isValidValue(row_data.consultation_file) || !row_data.is_paid,
            onClick: (row_data)=>onCopy(`${row_data.response_customer_website_url}`),

            // render: (row_data)=><a className="w-100" href={row_data.response_customer_website_url} target="_blank">View Response In Website</a>,
        },
        {
            key: "0",
            label: "View Response In Website",
            disabled: (row_data)=>!isValidValue(row_data.consultation_file) || !row_data.is_paid,
            onClick: (row_data)=>window.open(row_data.response_customer_website_url, '_blank').focus()
            // render: (row_data)=><a className="w-100" href={row_data.response_customer_website_url} target="_blank">View Response In Website</a>,
        },
        {
            key: '1',
            label: "Download Consultation File",
            disabled: (row_data)=>!isValidValue(row_data.consultation_file),
            onClick: (row_data)=>window.open(row_data.display_consultation_file, '_blank').focus(),
        },
        {
            key: '2',
            label: "Send Payment Request Email",
            disabled: (row_data)=>row_data.is_paid,
            hide: (row_data)=>!consultation_response_perm.send_payment_request,
            onClick: (row_data)=>sendPaymentRequest(row_data.id),
        },
        {
            key: '3',
            label: "Generate Payment Link",
            disabled: (row_data)=>row_data.is_paid,
            onClick: (row_data)=>handelGenerateLinkModal(row_data),
        },
        {
            key: '4',
            label: "Send Consultation Response Email",
            disabled: (row_data)=>!isValidValue(row_data.consultation_file) || !row_data.is_paid,
            hide: !consultation_response_perm.can_send_consultation_response_email,
            onClick: (row_data)=>sendResponseFeedback(row_data.id),
        },
    ]



    const sendPaymentRequest = async (consultation_response) =>{
        let response = await httpRequestWithAuth(ConsultationResponseSendPaymentRequestByEmailURL, "POST", {consultation_response});
        if (response.status) {
            openNotification("success", "Send Payment Request Email", `Email Sent`)
        } else {
            openNotification("error", "Send Payment Request Email", response.error_feedback.non_field_errors)
        }
    }

    const sendResponseFeedback = async (consultation_response) =>{
        let response = await httpRequestWithAuth(ConsultationResponseSendFeedbackByEmailURL, "POST", {consultation_response});
        if (response.status) {
            openNotification("success", "Send Consultation Response Email", `Email Sent`)
        } else {
            openNotification("error", "Send Consultation Response Email", response.error_feedback.non_field_errors)
        }
    }

    const handelOpenFormData = (data = {}) => {
        setFormData(data)
        setOpenFormData(true)
    }

    const handelCloseFormData = (reload_data = false) => {
        setFormData({});
        setOpenFormData(false);

        if (reload_data) {
            setReload(Math.random());
        }

    }

    const onDelete = async (row_data) => {
        let response = await httpRequestWithAuth(`${ConsultationResponsesURL}${row_data.id}`, "DELETE");
        if (response.status) {
            openNotification("success", "Delete Consultation Response", `${row_data.title} consultation response has been deleted`)
        } else {
            openNotification("error", "Delete Consultation Response", response.error_feedback.non_field_errors)
        }
    }

    const onEdit = async (row_data) => {
        handelOpenFormData(row_data)
    }

    const onAdd = async () => {
        handelOpenFormData()
    }

    const freeAction = (
        <>
            <AddButton onClick={onAdd}>Add Consultation Response</AddButton>
        </>
    );

    const backBtn = () =>{
        props.history.goBack();
    }

    const handelGenerateLinkModal = (row_data) =>{
        if (open_generate_link){
            setGenerateLinkData("")
        }else{
            setGenerateLinkData(row_data.ref_no)
        }
        setOpenGenerateLink(!open_generate_link)
    }

    return (
        <Row className="paper">

            <Hide hide={!open_generate_link}>
                <ConsultationGeneratePaymentLinkModal
                    consultation_response_ref_no={generate_link_data}
                    open={open_generate_link}
                    handleModelClose={handelGenerateLinkModal}
                />
            </Hide>


            <Hide hide={!open_from_data}>
                <ConsultationResponseFormModal
                    open={open_from_data}
                    data={form_data}
                    handleModelClose={handelCloseFormData}
                    consultation_request={consultation_request}
                />
            </Hide>


            <Col xs={24}>
                <CustomDynamicTable
                    title="Consultation Response"
                    reload_data={reload}
                    columns={columns}
                    fetch_url={ConsultationResponsesURL}
                    free_action={freeAction}
                    onDelete={consultation_response_perm.delete && onDelete}
                    onEdit={consultation_response_perm.edit && onEdit}
                    custom_params={{consultation_request}}
                    extra_actions={extra_actions}
                />
            </Col>
        </Row>
    )
}
