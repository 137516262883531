import {useState} from "react";
import {Steps} from "antd";
import useWindowSize from "../useWindowSize";
import {useEffect} from "react";
const { Step } = Steps;

export const CustomSteps = (props) =>{
    const vertical_on = props.vertical_on || "800";
    const direction = props.direction || "horizontal";
    const {inner_width} = useWindowSize();
    const [steps_direction, setStepsDirection] = useState(direction);


    useEffect(()=>{
        if (inner_width <= vertical_on){
            setStepsDirection("vertical");
        }else{
            setStepsDirection(direction);
        }
    },[inner_width])


    return(
        <Steps {...props} direction={steps_direction} size="small">
            {(props.steps || []).map(item => (
                <Step key={item} title={item} className="border-2 mb-2"/>
            ))}
            {props.children}
        </Steps>
    )
}