import React, {useEffect, useState} from "react";
import CustomModal from "../../../../global/components/CustomModal";
import {Col, Divider, Empty, Row} from "antd";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {BookingsRefundsURL} from "../../../../global/urls";
import Hide from "../../../../global/components/Hide";
import {Loading} from "../../../../global/components/Loading";
import {AddButton} from "../../../../global/components/AddButton";
import {BookingRefundRow} from "./BookingRefundRow";
import {StaticLanguageTabs} from "../../../../global/components/StaticLanguageTabs";
import {checkPermission} from "../../../../global/permissions";
import {permission_data} from "../../../../global/permissions_data";
import {isAgent} from "../../../../global/auth_functions";

export const BookingItemRefundsModal = (props) => {
    const is_agent = isAgent();
    const booking_item = props.booking_item || "";
    const [data, setData] = useState([]);
    const [language, setLanguage] = useState(props.language || "en");
    const [loading, setLoading] = useState(false);
    const [reload_data, setReloadData] = useState(false);

    const fetchData = async () =>{
        await setLoading(true);

        let response = await httpRequestWithAuth(BookingsRefundsURL, "GET", null, {booking_item});

        if (response.status){
         setData(response.data);
        }
        await setLoading(false);
    }

    useEffect(()=>{
        fetchData();
    },[reload_data])

    const reloadData = () =>{
        setReloadData(Math.random())
    }

    const addRefund = () => {
        const new_refund = {
            BookingItemId: booking_item,
            title_en:"",
            payment_type: "Refund",
            payment_status: "Refund",
            amount: 0,
        }
        setData([...data, new_refund])
    }



    const handleLanguageChange = (new_value) => {
        setLanguage(new_value);
    };

    return(
        <CustomModal title="Booking Refunds" size="sm" visible={props.open} onCancel={props.handleModelClose} paddingTop="0">
            <Row gutter={[12, 10]} className="pb-4">
                <Hide hide={!loading}>
                    <Col xs={24}>
                        <Loading/>
                    </Col>
                </Hide>

                <Hide hide={loading}>
                    <Col xs={24}>
                        <StaticLanguageTabs value={language} onChange={handleLanguageChange}/>
                    </Col>


                    <Hide hide={is_agent || !checkPermission(permission_data.booking_refund.view)}>
                        <Col xs={24} className="text-end">
                            <AddButton onClick={addRefund} block={false}>Add Refund</AddButton>
                        </Col>
                    </Hide>

                    <Col xs={24}>

                    {data.map((refund_data, index) => {

                        return(<BookingRefundRow
                            key={index}
                            language={language}
                            data={refund_data}
                            reloadData={reloadData}
                        />)
                    })}
                    </Col>


                    <Hide hide={is_agent || !checkPermission(permission_data.booking_refund.view) || data.length < 5}>
                    <Col xs={24} className="text-end">
                        <AddButton onClick={addRefund} block={false}>Add Refund</AddButton>
                    </Col>
                    </Hide>

                    <Hide hide={data.length !== 0}>
                        <Col xs={24}>
                            <Empty/>
                        </Col>
                    </Hide>



                </Hide>


            </Row>
        </CustomModal>
        )
}
