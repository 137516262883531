import {Button, Col, Row} from "antd";
import {CustomDynamicTable} from "../../../global/components/CustomDynamicTable";
import React, {useState} from "react";
import {BookingsURL, BookingUpdateTransferredAmountToAgencyURL} from "../../../global/urls";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import Hide from "../../../global/components/Hide";
import CustomBadge from "../../../global/components/CustomBadge";
import CustomSelectField from "../../../global/components/fields/CustomSelectField";
import {booking_status, interval_options, package_type, transferred_options} from "../../../global/variables";
import CustomRadioField from "../../../global/components/fields/CustomRadioField";
import {PackageAutoComplete} from "../../../global/components/fields/PackageAutoComplete";
import {isValidValue} from "../../../global/helper_functions";
import CustomCheckBox from "../../../global/components/fields/CustomCheckBox";
import {AgencyAutoComplete} from "../../../global/components/fields/AgencyAutoComplete";
import {CustomDateField} from "../../../global/components/fields/CustomDateField";
import {UpdateBookingTransferredAmount} from "./components/UpdateBookingTransferredAmount";
import BookingFinanceRowView from "./components/BookingFinanceRowView";

export const BookingsFinance = props => {
    const url_query = new URLSearchParams(props.location.search);
    const {slug, tab_view, package_id, voucher_id, interval} = props;
    const [reload, setReload] = useState(false);
    const [filter, setFilter] = useState({
        is_paid: true,
        is_partially_paid: true,
        is_full_amount_transferred_to_agency: false,
        voucher_id: voucher_id || "",
        package_id: package_id || "",
        interval: isValidValue(interval)?interval:(url_query.get("from-created-date") || url_query.get("to-created-date"))?"All":"All",
        status: "Approved",
        from_created_date: url_query.get("from-created-date") || "",
        to_created_date: url_query.get("to-created-date") || "",
    });

    // const [booking_perm] = useState({
    //     update_transferred_amount: checkPermission(permission_data.booking.update_transferred_amount_to_agency),
    // });
    //
    const [open_update_transferred_amount, setOpenUpdateTransferredAmount] = useState({});


    const columns = [
        // {
        //     title: "Booking Ref",
        //     dataIndex: "booking_ref_no",
        //     width: "6%",
        // },
        {
            dataIndex: "ref_no",
            width: "6%",
            render: (value, row_data) => <BookingFinanceRowView {...props} reloadData={reloadData} data={row_data} setOpenUpdateTransferredAmount={setOpenUpdateTransferredAmount}/>
        },
        // {
        //     title: "Item No.",
        //     dataIndex: "ref_no",
        //     width: "6%",
        //     render: (value, row_data) => <a href={`/${slug}/bookings/${row_data.id}`} target="_blank">{value}</a>
        // },
        // {
        //     title: "Title EN",
        //     dataIndex: "title_en",
        //     width: "25%",
        //     hide: isValidValue(package_id),
        // },
        //
        // {
        //     title: "Customer Name",
        //     dataIndex: "customer_name",
        //     width: isValidValue(package_id)?"22%":"10%"
        // },
        // {
        //     title: "Qty",
        //     dataIndex: "qty",
        //     width: "5%",
        //     align: "center"
        // },
        // {
        //     title: "Total Price",
        //     dataIndex: "price_overall",
        //     width: isValidValue(package_id)?"10%":"8%",
        //     align: "end",
        //     render: value => parseFloat(value).toFixed(3)
        // },
        // {
        //     title: "Discount Amount",
        //     dataIndex: "discount_amount_overall",
        //     width: isValidValue(package_id)?"10%":"8%",
        //     align: "end",
        //     render: value => parseFloat(value).toFixed(3)
        // },
        // {
        //     title: "Net Total",
        //     dataIndex: "net_total",
        //     width: isValidValue(package_id)?"10%":"8%",
        //     align: "end",
        //     render: value => parseFloat(value).toFixed(3)
        // },
        // {
        //     title: "Paid Amount",
        //     dataIndex: "paid_amount",
        //     width: isValidValue(package_id)?"14%":"5%",
        //     align: "end",
        //     render: value => parseFloat(value).toFixed(3),
        // },
        // {
        //     title: "HE Commission",
        //     dataIndex: "he_commission_amount",
        //     width: isValidValue(package_id)?"10%":"8%",
        //     align: "end",
        //     render: value => parseFloat(value).toFixed(3)
        // },
        // {
        //     title: "Agency accrual amount",
        //     dataIndex: "agency_accrual_amount",
        //     width: isValidValue(package_id)?"10%":"8%",
        //     align: "end",
        //     render: value => parseFloat(value).toFixed(3)
        // },
        // {
        //     title: "Status",
        //     dataIndex: "status",
        //     width: "5%",
        //     align: "center",
        //     render: (value) => <CustomBadge
        //         type={value !== "Approved" ? value === "Cancelled" ? "danger" : value === "Abandoned" ? "primary" : "disabled" : ""} title={value}/>
        // },
        // {
        //     title: "Transferred Amount",
        //     dataIndex: "transferred_amount_to_agency",
        //     width: "8%",
        //     align: "end",
        //     render: value => parseFloat(value).toFixed(3)
        // },
        // {
        //     title: "Created at",
        //     dataIndex: "createdAt",
        //     render: value => viewDateAndTime(value),
        //     width: "25%",
        // },
        // {
        //     title: "Actions",
        //     width: "10%",
        //     align: "center",
        //     render: (value, row_data) => (
        //         <>
        //             <Button className="m-1" type="primary" onClick={() => onView(row_data)} block>
        //                 View
        //             </Button>
        //
        //             <Hide hide={!booking_perm.update_transferred_amount}>
        //                 <Button className="m-1" type="primary" onClick={() => handelTransferFullAmount(row_data)} block>
        //                     Transfer Full Amount
        //                 </Button>
        //
        //                 <Button className="m-1" type="primary" onClick={() => setOpenUpdateTransferredAmount(row_data)} block>
        //                     Update Transferred Amount
        //                 </Button>
        //             </Hide>
        //
        //         </>
        //     )
        // },
    ];


    // const onView = async (row_data) => {
    //     props.history.push(`/${slug}/bookings/${row_data.id}`);
    // }
    //
    // const handelTransferFullAmount = async (row_data) => {
    //     let response = await httpRequestWithAuth(`${BookingUpdateTransferredAmountToAgencyURL}${row_data.id}`, "PATCH", {transferred_amount_to_agency: row_data.agency_accrual_amount})
    //     if (response.status){
    //         openNotification("success", "Transfer Full Amount", `Transferred Amount To Agency has been updated`)
    //         reloadData()
    //     }else{
    //         showError("Transfer Full Amount", response.error_feedback.non_field_errors);
    //     }
    // }


    const handleFilterChange = (name, value) => {
        setFilter({
            ...filter,
            [name]: value
        })
        reloadData();
    }

    const reloadData = () =>{
        setReload(Math.random());
    }


    const filter_toolbar = (
        <Row gutter={[10, 10]}>
            <Hide hide={tab_view}>
            <Col xs={24} md={6}>
                <CustomSelectField
                    empty_title="All"
                    label="Booking Type"
                    name="booking_type"
                    value={filter.booking_type}
                    onChange={handleFilterChange}
                    options={package_type}
                />
            </Col>
            </Hide>


            <Col xs={24} md={tab_view?8:6}>
                <CustomSelectField
                    empty_title="All"
                    label="Booking Status"
                    name="status"
                    value={filter.status}
                    onChange={handleFilterChange}
                    options={booking_status}
                />
            </Col>

            <Col xs={24} md={tab_view?8:6}>
                <CustomDateField
                    label="Date"
                    name="date"
                    value={filter.date}
                    onChange={handleFilterChange}
                />
            </Col>

            <Col xs={24} md={tab_view?8:6}>
                <CustomDateField
                    label="Created At"
                    name="created_at"
                    value={filter.created_at}
                    onChange={handleFilterChange}
                />
            </Col>

            <Hide hide={tab_view}>
                <Col xs={24} md={8}>
                    <PackageAutoComplete
                        package_type={filter.booking_type || ""}
                        label="Package"
                        name="package_id"
                        value={filter.package_id}
                        onChange={handleFilterChange}
                    />
                </Col>

                    <Col xs={24} md={8}>
                        <AgencyAutoComplete
                            package_type={filter.agency_id || ""}
                            label="Agency"
                            name="agency_id"
                            value={filter.agency_id}
                            onChange={handleFilterChange}
                        />
                    </Col>

                    <Col xs={24} md={8}>
                        <CustomSelectField
                            label="Agency Transferred Status"
                            name="is_full_amount_transferred_to_agency"
                            value={filter.is_full_amount_transferred_to_agency}
                            onChange={handleFilterChange}
                            options={transferred_options}
                        />
                    </Col>
            </Hide>


            <Col xs={24} md={12} className="mb-0">
                <CustomRadioField
                    required
                    name="interval"
                    value={filter.interval}
                    onChange={handleFilterChange}
                    options={interval_options}
                />
            </Col>

            <Col xs={24} md={12}
                 className="mb-0 d-flex align-items-center justify-content-center justify-content-md-end">
                <CustomCheckBox label="Paid" name="is_paid" value={filter.is_paid} onChange={handleFilterChange}/>
                <CustomCheckBox label="Unpaid" name="is_unpaid" value={filter.is_unpaid} onChange={handleFilterChange}
                                className="ms-3"/>
                <CustomCheckBox label="Partially Paid" name="is_partially_paid" value={filter.is_partially_paid}
                                onChange={handleFilterChange} className="ms-3"/>
                <CustomCheckBox label="Refunded" name="is_refunded" value={filter.is_refunded}
                                onChange={handleFilterChange} className="ms-3"/>
            </Col>



        </Row>
    );

    const handleModelClose = () =>{
        setOpenUpdateTransferredAmount({});
        reloadData();
    }

    return (
        <Row>

            <Hide hide={!isValidValue(open_update_transferred_amount.id)}>
                <UpdateBookingTransferredAmount
                    open={isValidValue(open_update_transferred_amount.id)}
                    handleModelClose={handleModelClose}
                    current_value={open_update_transferred_amount.transferred_amount_to_agency}
                    booking_item={open_update_transferred_amount.id}
                />
            </Hide>

            <Col xs={24} className={`${tab_view ? "pt-0" : "paper"}`}>
                <CustomDynamicTable
                    allow_export
                    title="Bookings Finance"
                    reload_data={reload}
                    columns={columns}
                    fetch_url={BookingsURL}
                    filter_toolbar={filter_toolbar}
                    custom_params={filter}
                />
            </Col>
        </Row>
    )
}
