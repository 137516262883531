import {Button, Col, Row} from "antd";
import {CustomDynamicTable} from "../../global/components/CustomDynamicTable";
import {useState} from "react";
import {AgenciesContractURL} from "../../global/urls";
import {httpRequestWithAuth} from "../../global/axios_instance";
import {isValidValue, openNotification} from "../../global/helper_functions";
import {permission_data} from "../../global/permissions_data";
import {checkPermission} from "../../global/permissions";
import {AddButton} from "../../global/components/AddButton";
import Hide from "../../global/components/Hide";
import {IsActiveView} from "../../global/components/IsActiveView";
import {AgencyContractFormModal} from "./components/AgencyContractFormModal";
import {EyeOutlined} from "@ant-design/icons";

export const AgencyContracts = props => {
    const {agency_id} = props;
    const [open_from_data, setOpenFormData] = useState(false);
    const [form_data, setFormData] = useState({});
    const [reload, setReload] = useState(false);

    const [agency_contract_perm] = useState({
        delete: checkPermission(permission_data.agency_contract.delete),
        edit: checkPermission(permission_data.agency_contract.edit),
        add: checkPermission(permission_data.agency_contract.add),
    });

    const [columns] = useState([
        {
            title: "Signature Name",
            dataIndex: "agency_signature_full_name",
            width: "20%",
        },
        {
            title: "Commission Type",
            dataIndex: "he_commission_type",
            width: "18%",
        },
        {
            title: "Commission Amount",
            dataIndex: "commission_amount",
            width: "18%",
            align: "center",
            render: (value, row_data)=>`${parseFloat(value)} ${row_data.he_commission_type === "Percentage"?"%":"KWD"}`
        },
        {
            title: "Start Date",
            dataIndex: "start_date_view",
            width: "15%",
        },
        {
            title: "End Date",
            dataIndex: "end_date_view",
            width: "15%",
        },
        {
            title: "Signature Date",
            dataIndex: "signature_date_view",
            width: "20%"
        },
        {
            title: "Cancelled",
            dataIndex: "is_cancelled",
            width: "5%",
            align: "center",
            render: (value) => <IsActiveView active_label="Yes" inactive_label="No" value={value}
                                             view_only={agency_contract_perm.edit}/>
        },

    ]);

    const extra_actions = [
        {
            key: "display_contract_documents",
            icon: EyeOutlined,
            render: (row_data)=><a href={row_data.display_contract_documents} target="_blank">View Document</a>,
        }
        ];

    const handelOpenFormData = (data = {}) => {
        setFormData(data)
        setOpenFormData(true)
    }


    const handelCloseFormData = (reload_data = false) => {
        setFormData({});
        setOpenFormData(false);

        if (reload_data) {
            setReload(Math.random());
        }

    }

    const onDelete = async (row_data) => {
        let response = await httpRequestWithAuth(`${AgenciesContractURL}${row_data.id}`, "DELETE");
        if (response.status) {
            openNotification("success", "Delete Agency Contract", `agency contract has been deleted`)
        } else {
            openNotification("error", "Delete Agency Contract", response.error_feedback.non_field_errors)
        }
    }

    const onEdit = async (row_data) => {
        handelOpenFormData(row_data)
    }

    const onAdd = async () => {
        handelOpenFormData()
    }

    const freeAction = (
        <>
            <AddButton onClick={onAdd}>Add Agency Contract</AddButton>
        </>
    );

    return (
        <Row>
            <Hide hide={!open_from_data}>
                <AgencyContractFormModal
                    open={open_from_data}
                    data={{AgencyId: agency_id, ...form_data}}
                    handleModelClose={handelCloseFormData}
                    agency_id={agency_id}
                />
            </Hide>

            <Col xs={24}>
                <CustomDynamicTable
                    title="Agencies Contract"
                    reload_data={reload}
                    columns={columns}
                    fetch_url={AgenciesContractURL}
                    free_action={freeAction}
                    onDelete={agency_contract_perm.delete && onDelete}
                    onEdit={agency_contract_perm.edit && onEdit}
                    custom_params={{agency: agency_id}}
                    extra_actions={extra_actions}
                />
            </Col>
        </Row>
    )
}
