import React, {useState} from 'react'
import {Col, Row} from 'antd'
import {httpRequestWithAuth} from '../../../global/axios_instance';
import {openNotification} from '../../../global/helper_functions';
import {OtherConsultationsToursURL, ConsultationsAddExistsToursURL} from '../../../global/urls'
import CustomModal from '../../../global/components/CustomModal';
import Hide from "../../../global/components/Hide";
import {HELoading} from "../../../global/components/HELoading";
import {ModelActionButton} from "../../../global/components/ModelActionButton";
import {CustomDynamicTable} from "../../../global/components/CustomDynamicTable";
import CustomCheckBox from "../../../global/components/fields/CustomCheckBox";


const SelectToursModal = (props) => {
    const consultation_id = props.consultation_id || "";
    const language = props.language || "en";

    const [selected_ids, setSelectedIds] = useState([]);
    const [loading, setLoading] = useState(false);

    const [helper_text, setHelperText] = useState({});
    const columns = [
        {
            dataIndex: "id",
            width: "2%",
            align: "center",
            render: value => <CustomCheckBox value={selected_ids.includes(value)} onChange={onSelectChange} name={value}/>
        },
        {
            title: "Ref No",
            dataIndex: "ref_no",
            width: "10%",
        },
        {
            title: `Title ${language}`,
            dataIndex: `title_${language}`,
            width: "50%",
        },
        {
            title: `Country ${language}`,
            dataIndex: `country_${language}`,
            width: "20%",
        },
        {
            title: "Price",
            dataIndex: "price",
            width: "20%",
        },

    ];


    const onSelectChange = (id) =>{
        let temp_selected_ids = [...selected_ids];
        const index_of = temp_selected_ids.indexOf(id);
        if (index_of < 0){
            temp_selected_ids.push(id);
        }else{
            temp_selected_ids.splice(index_of, 1);
        }
        setSelectedIds(temp_selected_ids);
    }


    const onSave = async () => {
        await setLoading(true);

        const data = {
            consultation_id,
            tours: selected_ids
        }

        const response =  await httpRequestWithAuth(ConsultationsAddExistsToursURL, "PATCH", data);

        if (response.status) {
            openNotification("success", "Add exists tour to consultation", `Tour Updated Successfully`);
            props.handleModelClose(true);
        } else {
            openNotification("error", "Add exists tour to consultation", response.error_feedback.non_field_errors);
            setHelperText(response.error_feedback);
        }

        await setLoading(false);
    }


    return (
        <CustomModal size="md" title="Select Tours" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>

                <Hide hide={!loading}>
                    <HELoading/>
                </Hide>


                    <CustomDynamicTable
                        columns={columns}
                        fetch_url={OtherConsultationsToursURL}
                        custom_params={{consultation: consultation_id}}
                    />

                    <Col xs={24} className="p-0">
                        <ModelActionButton
                            error_msg={helper_text.non_field_errors}
                            loading={loading}
                            saveLabel="Add"
                            onSave={onSave}
                            onCancel={props.handleModelClose}
                        />
                    </Col>


            </Row>
        </CustomModal>

    )
}

export default SelectToursModal
