import {httpRequestWithoutAuth} from "./axios_instance";
import {AdminSignInURL, AgentSignInURL, BaseBackEndAPIsURL} from "./urls";
import {isValidValue} from "./helper_functions";

export const adminSignIn = async (email, password) => {
  let response = await httpRequestWithoutAuth(
    `${BaseBackEndAPIsURL}${AdminSignInURL}`,
    "POST",
    { email, password }
  );

  if (response.status) {
    await localStorage.setItem("access_token", response.data.token);
    await localStorage.setItem("full_name", response.data.full_name);
    await localStorage.setItem("user_type_id", response.data.user_type_id);
    await localStorage.setItem("user_type", response.data.user_type);
    await localStorage.setItem("is_super_admin", response.data.is_super_admin);
    await localStorage.setItem("slug", response.data.slug);
    // await createAxiosInstance(response.data.token);
    // await getAllPermissions();
  }
  return response;
};


export const agentSignIn = async (email, password, slug) => {
  let response = await httpRequestWithoutAuth(
      `${BaseBackEndAPIsURL}${AgentSignInURL}`,
      "POST",
      { email, password, slug }
  );

  if (response.status) {
    await localStorage.setItem("access_token", response.data.token);
    await localStorage.setItem("full_name", response.data.full_name);
    await localStorage.setItem("user_type_id", response.data.user_type_id);
    await localStorage.setItem("user_type", response.data.user_type);
    await localStorage.setItem("is_agency_admin", response.data.is_agency_admin);
    await localStorage.setItem("slug", response.data.slug);
    // await createAxiosInstance(response.data.token);
    // await getAllPermissions();
  }
  return response;
};


export const logout = () => {
  localStorage.clear();
};

export const isAuthenticated = async () => {
  let token = await localStorage.getItem("access_token");
  return isValidValue(token);
};

export const isSuperAdmin =  () => {
  return localStorage.getItem("is_super_admin") === "true";
};

export const getUserType =  () => {
  return localStorage.getItem("user_type");
};

export const getUserSLug =  () => {
  return localStorage.getItem("slug");
};


export const isAgent =  () => {
  return localStorage.getItem("user_type") === "Agent";
};

export const isAdmin =  () => {
  return localStorage.getItem("user_type") === "Admin";
};


export const getUserAgencyId =  () => {
  if (getUserType() === "Agent"){
      return localStorage.getItem("user_type_id");
  }
  return  ""
};