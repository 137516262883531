import React, {useState} from "react";
import Hide from "../../../../global/components/Hide";
import {HowToAddPackage} from "../../../guidelines/global_components/packages/how_to_add_package";
import {Button} from "antd";
import {HowToEditPackage} from "../../../guidelines/global_components/packages/how_to_edit_package";
import {HowToIncreasePackageViews} from "../../../guidelines/global_components/packages/how_to_increase_package_views";
import {WhatIsPackageGallery} from "../../../guidelines/global_components/packages/what_is_package_gallery";
import {WhatIsPackageOptions} from "../../../guidelines/global_components/packages/what_is_package_options";
import {HowToAddDatesToOption} from "../../../guidelines/global_components/packages/how_to_add_dates_to_option";
import {
    HowToAddTimesToDateOption
} from "../../../guidelines/global_components/packages/how_to_add_times_to_date_option";
import {WhatIsAddMultipleTime} from "../../../guidelines/global_components/packages/what_is_add_multiple_time";

export const PackageOptionDatesGuidelines = props => {
    const [guidelines_model, setGuidelinesModel] = useState({});

    const handleGuidelinesModel = (name) => {
        let temp_guideline = {...guidelines_model};

        if (temp_guideline[name]) {
            delete temp_guideline[name]
        } else {
            temp_guideline[name] = true
        }

        setGuidelinesModel(temp_guideline)
    }
    return (
        <div className="text-sm-end text-center">


            <Hide hide={!guidelines_model.how_to_add_times_for_date}>
                <HowToAddTimesToDateOption
                    handleModelClose={() => handleGuidelinesModel("how_to_add_times_for_date")}
                    open={guidelines_model.how_to_add_times_for_date}
                />
            </Hide>

            <Hide hide={!guidelines_model.what_is_add_multiple_time}>
                <WhatIsAddMultipleTime
                    handleModelClose={() => handleGuidelinesModel("what_is_add_multiple_time")}
                    open={guidelines_model.what_is_add_multiple_time}
                />
            </Hide>




                <Button type="link" size="small" onClick={() => handleGuidelinesModel("how_to_add_times_for_date")}>
                    Add Times For Date?
                </Button>

                <Button type="link" size="small" onClick={() => handleGuidelinesModel("what_is_add_multiple_time")}>
                    What is Add Multiple Time?
                </Button>

        </div>
    )
}
