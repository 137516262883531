import {useState} from "react";
import CustomModal from "../../../../global/components/CustomModal";
import {Col, Row} from "antd";
import {CustomTextField} from "../../../../global/components/fields/CustomTextField";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {AdvertisementsURL} from "../../../../global/urls";
import {customMoment, isValidValue, openNotification} from "../../../../global/helper_functions";
import {ModelActionButton} from "../../../../global/components/ModelActionButton";
import CustomCheckBox from "../../../../global/components/fields/CustomCheckBox";
import CustomSelectField from "../../../../global/components/fields/CustomSelectField";
import {CustomDateTimeField} from "../../../../global/components/fields/CustomDateTimeField";
import {ImageUploaderWithView} from "../../../../global/components/ImageUploaderWithView";
import {WeekDaysSelectChips} from "../../../../global/components/WeekDaysSelectChips";
import {AgencyAutoComplete} from "../../../../global/components/fields/AgencyAutoComplete";
import Hide from "../../../../global/components/Hide";

const show_for_options =[
    {title: "User", value:"User"},
    {title: "Non-Registered User", value:"Non-Registered User"},
    {title: "All", value:"All"}
];

const type_options =[
    {title: "Banner", value:"Banner"},
    {title: "Popup", value:"Popup"},
    {title: "Notification", value:"Notification"}
]
const page_name_options =[
    {title: "Home", value:"home"},
    {title: "Group Trips", value:"group_trips"},
    {title: "Private Packages", value:"private_packages"},
    {title: "Local Experiences", value:"local_experiences"},
    {title: "Products", value:"products"},
    {title: "Search", value:"search"},
    {title: "Blog", value:"blog"},
    {title: "About us", value:"about_us"},
    {title: "Tours", value:"tours"},
]

export const AdvertisementsFormModal = props => {
    const [data, setData] = useState({
        type: "Banner",
        is_active: true,
        price: 0,
        auto_disappear_in: 0,
        show_for: "All",
        active_in_weekdays: "Sunday,Saturday,Monday,Tuesday,Wednesday,Thursday,Friday",
        ...props.data
    });
    const is_banner = data.type === "Banner";

    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});

    const handleFieldChange = (name, value) => {
        let temp_data = {...data};
        temp_data[name] = value;

        if (name === "image") {
            temp_data[`display_${name}`] = isValidValue((value || {}).size)?URL.createObjectURL(value):null;
        }

        setData(temp_data);
    }



    const onSave = async () => {
        await setLoading(true);

        const form_data = new FormData();
        Object.keys(data).map(Name=>{
            if (['active_from', 'active_to'].includes(Name)){
                if (isValidValue(data[Name])){
                    form_data.append(Name, customMoment(data[Name]).format("DD-MM-YYYY hh:mm"))
                }else{
                    form_data.append(Name, "")
                }
            }else{
                form_data.append(Name, data[Name])
            }
        })


        const is_new = !isValidValue(data.id);
        let response;


        if (is_new) {
            response = await httpRequestWithAuth(AdvertisementsURL, "POST", form_data);
        } else {
            response = await httpRequestWithAuth(`${AdvertisementsURL}${data.id}`, "PUT", form_data);
        }

        if (response.status) {
            if (is_new) {
                openNotification("success", "Add Advertisement", `${data.title_en} advertisement has been added`)
            } else {
                openNotification("success", "Update Advertisement", `${data.title_en} advertisement has been updated`)
            }
            props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
        }

        await setLoading(false);

    }

    return (
        <CustomModal size="md" title="Advertisement Form" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>


                <Col xs={24}>
                    <ImageUploaderWithView
                        required
                        label="Upload Image"
                        display_image={data.display_image}
                        name="image"
                        value={data.image}
                        onChange={handleFieldChange}
                        error={helper_text.image !== undefined}
                        helper_text={helper_text.image}
                    />
                </Col>



                <Col xs={24} md={10}>
                    <CustomTextField
                        required
                        label="Title EN"
                        name="title_en"
                        value={data.title_en}
                        onChange={handleFieldChange}
                        error={helper_text.title_en !== undefined}
                        helper_text={helper_text.title_en}
                    />
                </Col>


                <Col xs={24} md={10}>
                    <CustomTextField
                        required
                        label="Title Ar"
                        name="title_ar"
                        value={data.title_ar}
                        onChange={handleFieldChange}
                        error={helper_text.title_ar !== undefined}
                        helper_text={helper_text.title_ar}
                    />
                </Col>

                <Col xs={24} md={4}>
                    <CustomCheckBox
                        label="Show Title"
                        name="show_title"
                        value={data.show_title}
                        onChange={handleFieldChange}
                        error={helper_text.show_title !== undefined}
                        helper_text={helper_text.show_title}
                    />
                </Col>


                <Col xs={24} md={12}>
                    <CustomSelectField
                        required
                        label="Type"
                        name="type"
                        value={data.type}
                        onChange={handleFieldChange}
                        error={helper_text.type !== undefined}
                        helper_text={helper_text.type}
                        options={type_options}
                    />
                </Col>

                <Col xs={24} md={12}>
                    <AgencyAutoComplete
                        label="Agency"
                        name="AgencyId"
                        value={data.AgencyId}
                        onChange={handleFieldChange}
                        error={helper_text.AgencyId !== undefined}
                        helper_text={helper_text.AgencyId}
                    />
                </Col>

                <Col xs={24} md={7}>
                    <CustomTextField
                        label="Price"
                        name="price"
                        value={data.price}
                        onChange={handleFieldChange}
                        error={helper_text.price !== undefined}
                        helper_text={helper_text.price}
                        type="number"
                        addonAfter="KWD"
                    />
                </Col>


                <Hide hide={is_banner}>
                <Col xs={24} md={10}>
                    <CustomTextField
                        required
                        label="Auto Disappear In (sec)"
                        name="auto_disappear_in"
                        value={data.auto_disappear_in}
                        onChange={handleFieldChange}
                        error={helper_text.auto_disappear_in !== undefined}
                        helper_text={helper_text.auto_disappear_in}
                        type="number"
                    />
                </Col>
                </Hide>

                <Col xs={24} md={7}>
                    <CustomSelectField
                        required
                        label="Show For"
                        name="show_for"
                        value={data.show_for}
                        onChange={handleFieldChange}
                        error={helper_text.show_for !== undefined}
                        helper_text={helper_text.show_for}
                        options={show_for_options}
                    />
                </Col>


                <Hide hide={!is_banner}>
                    <Col xs={24} md={10}>
                        <CustomSelectField
                            required
                            label="Page Name"
                            name="page_name"
                            value={data.page_name}
                            onChange={handleFieldChange}
                            error={helper_text.page_name !== undefined}
                            helper_text={helper_text.page_name}
                            tooptil="Show advertisement in which page"
                            options={page_name_options}
                        />
                    </Col>
                </Hide>


                <Col xs={24}>
                    <WeekDaysSelectChips
                        multiple
                        label="Active In Weekdays"
                        name="active_in_weekdays"
                        value={data.active_in_weekdays}
                        onChange={handleFieldChange}
                        error={helper_text.active_in_weekdays !== undefined}
                        helper_text={helper_text.active_in_weekdays}
                    />
                </Col>



                <Hide hide={is_banner}>
                <Col xs={24} md={12}>
                    <CustomTextField
                        required
                        label="Page Name"
                        name="page_name"
                        value={data.page_name}
                        onChange={handleFieldChange}
                        error={helper_text.page_name !== undefined}
                        helper_text={helper_text.page_name}
                        tooptil="Show advertisement in which page"
                    />
                </Col>
                </Hide>



                <Col xs={24} md={is_banner?24:12}>
                    <CustomTextField
                        label="Redirect URL"
                        name="redirect_url"
                        value={data.redirect_url}
                        onChange={handleFieldChange}
                        error={helper_text.redirect_url !== undefined}
                        helper_text={helper_text.redirect_url}
                    />
                </Col>


                <Hide hide={is_banner}>
                <Col xs={24}>
                    <CustomTextField
                        label="Button Text"
                        name="btn_text"
                        value={data.btn_text}
                        onChange={handleFieldChange}
                        error={helper_text.btn_text !== undefined}
                        helper_text={helper_text.btn_text}
                    />
                </Col>
                </Hide>

                <Col xs={24} md={12}>
                    <CustomDateTimeField
                        required
                        label="Active From"
                        name="active_from"
                        value={data.active_from}
                        onChange={handleFieldChange}
                        error={helper_text.active_from !== undefined}
                        helper_text={helper_text.active_from}
                    />
                </Col>

                <Col xs={24} md={12}>
                    <CustomDateTimeField
                        required
                        label="Active To"
                        name="active_to"
                        value={data.active_to}
                        onChange={handleFieldChange}
                        error={helper_text.active_to !== undefined}
                        helper_text={helper_text.active_to}
                    />
                </Col>






                <Col xs={24}>
                    <CustomCheckBox
                        label="Is Active"
                        name="is_active"
                        value={data.is_active}
                        onChange={handleFieldChange}
                    />
                </Col>

                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}
