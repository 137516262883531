import React, {useEffect, useRef, useState} from "react";
import {Button, Col, Empty, Row, Tabs, Tooltip} from "antd";
import {PackagesOptionChangeOrderURL, PackagesOptionURL, PackagesPendingChangesForModalURL} from "../../../global/urls";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {
    isValidValue,
    openNotification,
    showConfirm,
    showError,
    showInfo,
    upperCaseView
} from "../../../global/helper_functions";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import {AddButton} from "../../../global/components/AddButton";
import Hide from "../../../global/components/Hide";
import {CustomTabPane} from "../../../global/components/CustomTabPane";
import {HELoading} from "../../../global/components/HELoading";
import {CustomTextField} from "../../../global/components/fields/CustomTextField";
import {CustomTextAreaField} from "../../../global/components/fields/CustomTextAreaField";
import CustomCheckBox from "../../../global/components/fields/CustomCheckBox";
import {ActionButton} from "../../../global/components/ActionButton";
import {PackageOptionDates} from "./PackageOptionDates";
import {CustomStatusView} from "../../../global/components/CustomStatusView";
import CustomSwitch from "../../../global/components/fields/CustomSwitch";
import {DANGER_COLOR_2, package_status, payment_percentage, PRIMARY_COLOR} from "../../../global/variables";
import CustomSelectField from "../../../global/components/fields/CustomSelectField";
import {InfoIconGreenFilled} from "../../../global/components/Icons";
import CustomBadge from "../../../global/components/CustomBadge";
import ViewActionsDetails from "../../../global/components/ViewActionsDetails";
import {LeftCircleFilled, RightCircleFilled} from "@ant-design/icons";
import {BulkDeleteOptionsDatesModal} from "./components/BulkDeleteOptionsDatesModal";


export const PackageOptions = props => {
    const package_id = props.package_id || "";
    const slug = props.slug || "";
    const language = props.language || "en";
    const package_data = props.package_data || {};
    const {
        is_admin,
        has_pending_status,
        approve_pending_status_loading,
        approveAllPendingStatus,
    } = props;
    const package_option_date_ref = useRef(null)
    const [current_option, setCurrentOption] = useState("0");
    const [package_options, setPackageOptions] = useState([]);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});
    const [new_pending_changes, setNewPendingChanges] = useState({});
    const [is_saved, setIsSaved] = useState(false);
    const [open_bulk_delete_options_dates, setOpenBulkDeleteOptionsDates] = useState(false);

    useEffect(() => {
        if (is_saved && data.show_dates && isValidValue(package_option_date_ref) && isValidValue(package_option_date_ref.current)) {
            package_option_date_ref.current.scrollIntoView({behavior: 'smooth'})
        }
        setIsSaved(false)
    }, [is_saved])

    const getPendingChanges = async (object_id = "") => {
        let response = await httpRequestWithAuth(PackagesPendingChangesForModalURL, "GET", null, {
            model_name: "PackageOptions",
            package: package_id,
            object_id
        });
        if (response.status) {
            setNewPendingChanges(response.data)
        }
    }

    const [package_option_perm] = useState({
        delete: checkPermission(permission_data.package_option.delete),
        edit: checkPermission(permission_data.package_option.edit),
        add: checkPermission(permission_data.package_option.add),
    });


    useEffect(() => {
        fetchPackageOption()
    }, [])

    useEffect(() => {
        onChangeOptionActions()
    }, [current_option, package_options])

    const fetchPackageOption = async (selected_option = current_option) => {
        await setLoading(true)

        let response = await httpRequestWithAuth(PackagesOptionURL, "GET", null, {package: package_id});

        if (response.status) {
            if (response.data.length > 0) {
                let temp_data = [];
                (response.data || []).map(option_data => {
                    temp_data.push({
                        ...option_data,
                        show_dates: option_data.has_date
                    })
                })
                setPackageOptions(temp_data);
                setData(temp_data[selected_option])
                getPendingChanges(temp_data[selected_option].id);
                setCurrentOption(selected_option + "")
            }
        } else {
            showError("Get Package Option Failed", response.error_feedback.non_field_errors);
        }

        await setLoading(false);
    }

    const changeOptionOrder = async (index_one, index_two) => {
        const package_option_one = package_options[index_one];
        const package_option_two = package_options[index_two];

        const package_option_one_response = await httpRequestWithAuth(`${PackagesOptionChangeOrderURL}${package_option_one.id}`, "PATCH", {order: package_option_two.order});
        if (package_option_one_response.status) {
            const package_option_two_response = await httpRequestWithAuth(`${PackagesOptionChangeOrderURL}${package_option_two.id}`, "PATCH", {order: package_option_one.order});
            if (package_option_two_response.status) {
                fetchPackageOption(index_two);
            }
        }
    }

    const handleOptionChange = (value) => {
        updatePackageOptions(data, current_option);
        setCurrentOption(value);
    }

    const onChangeOptionActions = async () => {
        setHelperText({});
        if (package_options.length > 0) {
            setData(package_options[current_option]);
            if (isValidValue(package_options[current_option].id)) {
                getPendingChanges(package_options[current_option].id);
            } else {
                setNewPendingChanges({});
            }
        }
    }

    const updatePackageOptions = (new_data, index = current_option) => {
        const current_option_index = parseInt(index);
        let temp_options = [...package_options];
        temp_options[current_option_index] = {...new_data};
        setPackageOptions(temp_options);
    }

    const handleFieldChange = (name, value) => {
        let temp_data = {...data};
        if (["restricted_capacity", "default_capacity"].includes(name)) {
            value = parseInt(value || 1);
            if (value < 1) {
                value = 1;
            }
        }
        temp_data[name] = value;
        setData(temp_data)
    }

    const onSave = async (confirm_zero=false) => {
        await setLoading(true);
        setHelperText({});
        const is_new = !isValidValue(data.id);


        if (!confirm_zero){
            const old_price = parseFloat(package_options[current_option].price);
            const new_price = parseFloat(data.price);

            if ((old_price !== new_price || is_new) && new_price === 0){
                showConfirm("Complimentary Package Option", `Are you sure to make package option with price equal zero ?`, ()=>onSave(true));
                await setLoading(false);
                return;
            }
        }

        let response;


        if (is_new) {
            response = await httpRequestWithAuth(PackagesOptionURL, "POST", {
                ...data,
                PackageId: package_id,
                status: data.status === "New" ? "" : data.status
            });
        } else {
            response = await httpRequestWithAuth(`${PackagesOptionURL}${data.id}`, "PUT", {
                ...data,
                PackageId: package_id
            });
        }

        if (response.status) {
            setIsSaved(true)
            props.getPackageData();

            if (is_new) {
                openNotification("success", "Add Package Option", `${data[`title_${language}`]} package option has been added`)
            } else {
                if (response.data.pending_change) {
                    showInfo("Update Package Option", response.data.msg);
                } else {
                    openNotification("success", "Update Package Option", response.data.msg || `${data[`title_${language}`]} package option has been updated`)
                }
            }

            if (!response.data.pending_change) {
                const temp_data = {...response.data, show_dates: response.data.has_date}
                updatePackageOptions(temp_data);
                setData(temp_data);
            }

            getPendingChanges(response.data.id);


        } else {
            setHelperText(response.error_feedback);
            showError("Save Failed", "Please checks all fields");
        }

        await setLoading(false);
    }

    const onDelete = async () => {
        let response = await httpRequestWithAuth(`${PackagesOptionURL}${data.id}`, "DELETE");
        if (response.status) {
            props.getPackageData();
            openNotification("success", "Delete Package Option", `${data[`title_${language}`]} package option has been deleted`)
            const index = parseInt(current_option);
            let temp_options = [...package_options]
            temp_options.splice(index, 1);
            setPackageOptions(temp_options)
            setCurrentOption("0")
        } else {
            openNotification("error", "Delete Package Option", response.error_feedback.non_field_errors)
        }
    }

    const onAdd = async () => {
        if (package_options.length === 0 || isValidValue(package_options[package_options.length - 1].id)) {
            const new_obj = {
                PackageId: package_id,
                title_en: "",
                description_en: "",
                status: "New",
                price: parseInt(package_data.starting_price),
                can_cancel_before: 2880,
                restricted_capacity: 1,
                default_capacity: 10,
                is_sold_out: false,
                has_date: false,
                has_extra_email_details: false,
                is_active: true,
            }


            let temp_options = [...package_options];


            if (package_data.length > 0) {
                const current_option_index = parseInt(current_option);
                temp_options[current_option_index] = {...data};
            }

            temp_options.push(new_obj);

            setPackageOptions(temp_options);
            setCurrentOption(package_options.length + "");
        }
    }


    const onCancel = () => {
        props.history.push(`/${slug}/packages`);
    }


    const handleDelete = () => {
        showConfirm("Delete Package Option", `Are you sure to delete ${data[`title_${language}`]} package option ?`, onDelete);
    }


    const onApproveAllPending = async () => {
        await fetchPackageOption();
    }

    const approveAllPendingStatusBtn = () => {
        approveAllPendingStatus("PackageOptions", onApproveAllPending)
        approveAllPendingStatus("PackageOptionDates", onApproveAllPending, true)
    }

    return (
        <>

            <Hide hide={!open_bulk_delete_options_dates}>
                <BulkDeleteOptionsDatesModal
                    open={open_bulk_delete_options_dates}
                    handleModelClose={() => setOpenBulkDeleteOptionsDates(false)}
                    package_id={package_id}
                    language={language}
                />
            </Hide>
            <div className="paper pt-0 w-100">

                <Row gutter={[12, 10]}>

                    <Hide hide={!loading}>
                        <Col xs={24}>
                            <HELoading/>
                        </Col>
                    </Hide>


                    <Hide hide={loading}>

                        <Col xs={24} className="d-md-flex justify-content-between align-items-center">

                            <h2 className="font-size-22 mb-2">
                                <label className="text-start">
                                    Options
                                    <Tooltip
                                        className="ms-1"
                                        placement="topLeft"
                                        title="Consists of the photos displayed for the package on the website"
                                        color={PRIMARY_COLOR}
                                        arrowPointAtCenter
                                    >
                                                                   <span className="p-0 m-0">
                                                                       <InfoIconGreenFilled/>
                                                                   </span>
                                    </Tooltip>
                                    <br/>
                                    <span className="font-size-13 text-black-50 mb-0">Add minimum one option & maximum five</span>
                                </label>
                            </h2>


                            <div className="d-flex justify-content-center">
                                <div className="d-md-flex w-100">

                                    <Hide hide={!has_pending_status || !is_admin}>
                                        <Button
                                            block
                                            type="primary"
                                            className="mb-1 m-md-1 justify-content-center"
                                            onClick={approveAllPendingStatusBtn}
                                            loading={approve_pending_status_loading}
                                        >
                                            Approve All Pending Status
                                        </Button>
                                    </Hide>

                                    <Hide hide={!checkPermission(permission_data.package_option_date.delete)}>
                                        <Button type="primary" danger className="mb-1 m-md-1"
                                                onClick={() => setOpenBulkDeleteOptionsDates(true)}>Bulk Delete Options
                                            Dates</Button>
                                    </Hide>

                                    <AddButton className="mb-1 m-md-1" onClick={onAdd}>Add New Option</AddButton>

                                </div>
                            </div>

                        </Col>

                        <Col xs={24}>
                            <Tabs activeKey={current_option} onChange={handleOptionChange}

                            >
                                {package_options.map((Option, index) => (
                                    <CustomTabPane tab={(
                                        <>
                                            {Option[`title_${language}`]}
                                            <CustomStatusView className="ms-2" value={Option.status}/>
                                        </>

                                    )} key={index + ""}/>
                                ))}
                            </Tabs>
                        </Col>
                    </Hide>


                    <Hide hide={loading || package_options.length !== 0}>
                        <Col xs={24} className="pt-5 pb-5">
                            <Empty/>
                        </Col>
                    </Hide>

                    <Hide hide={loading || package_options.length === 0}>


                        <Col xs={24} className="d-flex justify-content-end align-items-center">
                            <Hide hide={data.order === 1}>
                                <Button
                                    onClick={() => changeOptionOrder(parseInt(current_option), parseInt(current_option) - 1)}
                                    className="bg-secondary text-white m-1 mt-0"><LeftCircleFilled/> Move Left</Button>
                            </Hide>

                            <Hide hide={data.order === package_options.length}>
                                <Button
                                    onClick={() => changeOptionOrder(parseInt(current_option), parseInt(current_option) + 1)}
                                    className="bg-secondary text-white m-1 mt-0">Move
                                    Right <RightCircleFilled/></Button>
                            </Hide>
                        </Col>

                        <Col xs={24} className="d-sm-flex justify-content-between align-items-center">
                            <div className="mb-3 mb-md-0 text-center text-sm-start">
                                <CustomBadge
                                    className="w-100"
                                    title={<h6
                                        className="font-size-13 text-center w-100 text-white p-0 m-0 fw-bold">Bookings
                                        Number: <label
                                            className="ms-1 fw-bolder">{data.bookings_number || 0}</label></h6>}
                                />

                            </div>
                            <div className="d-md-flex">

                                <div className="d-flex justify-content-center justify-md-content-end mb-3 mb-md-0">
                                    <CustomSwitch
                                        not_value
                                        hide_label_sm
                                        view_only={package_data.current_participants_number < data.restricted_capacity && package_data.package_type === "Travel"}
                                        label="Sold Out Status"
                                        name="is_sold_out"
                                        checked_label="Available"
                                        unchecked_label="Sold Out"
                                        value={data.is_sold_out}
                                        onChange={handleFieldChange}
                                        unchecked_color={DANGER_COLOR_2}
                                        className="me-3"
                                    />

                                    <CustomSwitch
                                        hide_label_sm
                                        label="Activation Status"
                                        name="is_active"
                                        checked_label="Active"
                                        unchecked_label="Inactive"
                                        value={data.is_active}
                                        onChange={handleFieldChange}
                                    />
                                </div>
                            </div>
                        </Col>

                        <Col xs={24}>
                            <CustomTextField
                                required
                                label={`Title ${upperCaseView(language)}`}
                                name={`title_${language}`}
                                value={data[`title_${language}`]}
                                onChange={handleFieldChange}
                                error={helper_text[`title_${language}`] !== undefined}
                                helper_text={helper_text[`title_${language}`]}
                                warning_tooltip={new_pending_changes[`title_${language}`]}
                                warning_tooltip_title="New Value Waiting Approve:"
                            />
                        </Col>


                        <Col xs={24} md={data.has_date ? is_admin ? 12 : 12 : 12}>
                            <CustomTextField
                                required
                                label="Price"
                                name="price"
                                value={data.price}
                                onChange={handleFieldChange}
                                error={helper_text.price !== undefined}
                                helper_text={helper_text.price}
                                addonAfter={package_data.currency}
                                type="number"
                                warning_tooltip={new_pending_changes['price']}
                                warning_tooltip_title="New Value Waiting Approve:"
                            />
                        </Col>

                        <Col xs={24} md={data.has_date ? is_admin ? 12 : 12 : 12}>
                            <CustomTextField
                                inputClassName="bg-dark"
                                view_only
                                label="Selling Price"
                                value={data.price * (payment_percentage/100 + 1)}
                                addonAfter={package_data.currency}
                                type="number"
                            />
                        </Col>


                        {/*<Col xs={24} md={12}>*/}
                        {/*    <CustomDurationField*/}
                        {/*        required*/}
                        {/*        label="Can Cancel Before"*/}
                        {/*        name="can_cancel_before"*/}
                        {/*        value={data.can_cancel_before}*/}
                        {/*        onChange={handleFieldChange}*/}
                        {/*        error={helper_text.can_cancel_before !== undefined}*/}
                        {/*        helper_text={helper_text.can_cancel_before}*/}
                        {/*        type="number"*/}
                        {/*        warning_tooltip={new_pending_changes[`can_cancel_before`]}*/}
                        {/*        warning_tooltip_title="New Value Waiting Approve:"*/}
                        {/*        tooltip="Customer can cancel his booking for this option as maximum deadline"*/}
                        {/*    />*/}
                        {/*</Col>*/}


                        <Hide hide={data.has_date}>
                            <Col xs={24} md={is_admin?8:12}>
                                <CustomTextField
                                    required
                                    label="Option Qty"
                                    name="default_capacity"
                                    value={data.default_capacity}
                                    onChange={handleFieldChange}
                                    error={helper_text.default_capacity !== undefined}
                                    helper_text={helper_text.default_capacity}
                                    type="number"
                                    warning_tooltip={new_pending_changes[`default_capacity`]}
                                    warning_tooltip_title="New Value Waiting Approve:"
                                    tooltip="If no specific dates qty of option, otherwise it will be default value for date qty"
                                />
                            </Col>
                        </Hide>


                        <Col xs={24} md={data.has_date ? is_admin ? 12 : 12 : is_admin?8:12}>
                            <CustomTextField
                                required
                                label="Required Capacity Per Qty"
                                name="restricted_capacity"
                                value={data.restricted_capacity}
                                onChange={handleFieldChange}
                                error={helper_text.restricted_capacity !== undefined}
                                helper_text={helper_text.restricted_capacity}
                                type="number"
                                warning_tooltip={new_pending_changes[`restricted_capacity`]}
                                warning_tooltip_title="New Value Waiting Approve:"
                                tooltip="Number of persons include for one qty"
                            />
                        </Col>

                        <Hide hide={!is_admin}>
                            <Col xs={24} md={data.has_date ? 12 : 8}>
                                <CustomSelectField
                                    disabled={!is_admin}
                                    label="Status"
                                    name="status"
                                    value={data.status}
                                    onChange={handleFieldChange}
                                    error={helper_text.status !== undefined}
                                    helper_text={helper_text.status}
                                    options={package_status}
                                    warning_tooltip={new_pending_changes[`status`]}
                                    warning_tooltip_title="New Value Waiting Approve:"
                                />
                            </Col>
                        </Hide>

                        <Col xs={24}>
                            <CustomTextAreaField
                                withTextEditor
                                required
                                label={`Description ${upperCaseView(language)}`}
                                name={`description_${language}`}
                                value={data[`description_${language}`]}
                                onChange={handleFieldChange}
                                error={helper_text[`description_${language}`] !== undefined}
                                helper_text={helper_text[`description_${language}`]}
                                warning_tooltip={new_pending_changes[`description_${language}`]}
                                warning_tooltip_title="New Value Waiting Approve:"
                            />
                        </Col>


                        <Hide hide={package_data.package_type !== "Experience"}>
                            <Hide hide={!data.has_extra_email_details}>
                                <Col xs={24}>
                                    <CustomTextAreaField
                                        withTextEditor
                                        required
                                        label="Extra Email Details"
                                        name="extra_email_details"
                                        value={data.extra_email_details}
                                        onChange={handleFieldChange}
                                        error={helper_text.extra_email_details !== undefined}
                                        helper_text={helper_text.extra_email_details}
                                        warning_tooltip={new_pending_changes.extra_email_details}
                                        warning_tooltip_title="New Value Waiting Approve:"
                                        tooltip="Add more details in remained email"
                                    />
                                </Col>
                            </Hide>

                            <Col xs={24}>
                                <CustomCheckBox
                                    name="has_extra_email_details"
                                    label="Has Extra Email Details"
                                    value={data.has_extra_email_details}
                                    onChange={handleFieldChange}
                                    warning_tooltip={new_pending_changes.has_extra_email_details}
                                    warning_tooltip_title="New Value Waiting Approve:"
                                    tooltip="Add more details in remained email"
                                />
                            </Col>
                        </Hide>

                        <Col xs={24}>
                            <CustomCheckBox
                                name="has_date"
                                label="Has Specific Date"
                                value={data.has_date}
                                onChange={handleFieldChange}
                                warning_tooltip={new_pending_changes[`has_date`]}
                                warning_tooltip_title="New Value Waiting Approve:"
                                tooltip="if option have Specific Dates to be in"
                            />
                        </Col>

                        <Col xs={24}>
                            <CustomCheckBox
                                view_only={package_data.current_participants_number < data.restricted_capacity && package_data.package_type === "Travel"}
                                name="is_sold_out"
                                label="Is Sold Out"
                                value={data.is_sold_out}
                                onChange={handleFieldChange}
                                warning_tooltip={new_pending_changes[`is_sold_out`]}
                                warning_tooltip_title="New Value Waiting Approve:"
                                tooltip="Customer will can't book this option but still showing in website and mark it as sold out"
                            />
                        </Col>

                        <Col xs={24}>
                            <CustomCheckBox
                                name="is_active"
                                label="Is Active"
                                value={data.is_active}
                                onChange={handleFieldChange}
                                warning_tooltip={new_pending_changes[`is_active`]}
                                warning_tooltip_title="New Value Waiting Approve:"
                                tooltip="Hide option from the website"
                            />
                        </Col>

                        <Hide hide={!isValidValue(data.id)}>
                            <ViewActionsDetails data={data}/>
                        </Hide>

                        <Col xs={24}>
                            <ActionButton
                                error_msg={helper_text.non_field_errors}
                                onDelete={handleDelete}
                                onCancel={onCancel}
                                allow_delete={isValidValue(data.id) && checkPermission(permission_data.package.delete)}
                                allow_save={isValidValue(data.id) ? package_option_perm.edit : package_option_perm.add}
                                onSave={()=>onSave(false)}
                            />
                        </Col>

                    </Hide>

                </Row>
            </div>

            <div ref={package_option_date_ref}/>
            <Hide hide={!isValidValue(data.id) || !data.has_date || !data.show_dates || open_bulk_delete_options_dates}>
                <div className="paper w-100 mt-5">
                    <Row gutter={[12, 10]}>

                        <Col xs={24}>
                            <PackageOptionDates
                                {...props}
                                package_option_id={data.id}
                                package_option_data={data}
                            />
                        </Col>
                    </Row>
                </div>

            </Hide>

        </>
    )
}
