import DynamicAutocomplete from "./DynamicAutocomplete";
import {PackagesListAllURL} from "../../urls";

export const PackageAutoComplete = (props)=>{
    const fetch_all = props.fetch_all || false;
    const package_type = props.package_type || "";

    return(
        <DynamicAutocomplete
            {...props}
            fetch_url={PackagesListAllURL}
            title_key={["ref_no", "title_en"]}
            fetch_in_search={!fetch_all}
            mode={props.multiple ? "multiple":""}
            params_array={[fetch_all, package_type]}
            params={{fetch_all, package_type}}
        />
    )
}


