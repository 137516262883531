import DynamicAutocomplete from "./DynamicAutocomplete";
import {AgenciesListAllURL} from "../../urls";

export const AgencyAutoComplete = (props)=>{
    const fetch_all = props.fetch_all || false;

    return(
        <DynamicAutocomplete
            {...props}
            fetch_url={AgenciesListAllURL}
            title_key="name"
            fetch_in_search={!fetch_all}
            mode={props.multiple ? "multiple":""}
            params_array={[fetch_all]}
            params={{fetch_all}}
        />
    )
}