import React, {useEffect, useState} from "react";
import {httpRequestWithAuth} from "../../global/axios_instance";
import {InvoicesURL, InvoicesSendPaymentRequestURL, InvoicesSendPaymentReceiptURL} from "../../global/urls";
import {isValidValue, openNotification, showConfirm, showError} from "../../global/helper_functions";
import {Col, Row} from "antd";
import {ActionButton} from "../../global/components/ActionButton";
import {CustomTextField} from "../../global/components/fields/CustomTextField";
import {permission_data} from "../../global/permissions_data";
import {checkPermission} from "../../global/permissions";
import {CustomTextAreaField} from "../../global/components/fields/CustomTextAreaField";
import {HELoading} from "../../global/components/HELoading";
import Hide from "../../global/components/Hide";
import CustomCheckBox from "../../global/components/fields/CustomCheckBox";
import {CustomPhoneNumberField} from "../../global/components/fields/CustomPhoneNumberField";
import {CustomDateField} from "../../global/components/fields/CustomDateField";
import moment from "moment";
import CustomSelectField from "../../global/components/fields/CustomSelectField";
import {payment_methods, status_options} from "../../global/variables";
import {CustomDateTimeField} from "../../global/components/fields/CustomDateTimeField";
import {isSuperAdmin} from "../../global/auth_functions";
import ViewActionsDetails from "../../global/components/ViewActionsDetails";
import CustomDropDown from "../../global/components/CustomDropDown";
import {SmileOutlined} from "@ant-design/icons";
import {InvoiceGeneratePaymentLinkModal} from "./components/InvoiceGeneratePaymentLinkModal";

export const InvoiceDetails = props => {
    const is_new = props.is_new;
    const invoice_id = props.invoice_id || "";
    const slug = props.slug || "";

    const lang = props.language || "en";
    const [has_perm, setHasPerm] = useState(checkPermission(permission_data.invoice.edit) || (invoice_id === "new" && checkPermission(permission_data.invoice.add)));
    const [data, setData] = useState({
        invoice_date: moment().format("YYYY-MM-DD"),
        net_total: 0,
        currency: "KWD",
        status: "Pending",
        is_paid: false
    });

    const [loading, setLoading] = useState(true);
    const [helper_text, setHelperText] = useState({});
    const [open_generate_link, setOpenGenerateLink] = useState(false);

    const actions_options = [
        {
            key: '1',
            label: "Send Payment Receipt Email",
            disabled: !data.is_paid,
            icon: <SmileOutlined/>,
            hide: !checkPermission(permission_data.invoice.send_payment_receipt)
        },
        {
            key: '2',
            label: "Send Payment Request Email",
            disabled: data.is_paid || data.status === "Cancelled",
            icon: <SmileOutlined/>,
            hide: !checkPermission(permission_data.invoice.send_payment_request)
        },
        {
            key: '3',
            label: "Generate Payment Link",
            icon: <SmileOutlined/>,
            disabled: data.is_paid || data.status === "Cancelled",
        },
    ];

    const fetchData = async () => {
        let response = await httpRequestWithAuth(`${InvoicesURL}${invoice_id}`, "GET");
        if (response.status) {
            setData(response.data);
            props.setInvoiceData(response.data);

            if (response.data.is_paid){
                setHasPerm(has_perm &&  isSuperAdmin());
            }

        } else {
            showError("Get Invoice Data", response.error_feedback.non_field_errors);
            onCancel();
        }
        setLoading(false);
    }


    const handleFieldChange = (name, value) => {
        let temp_helper_text = {...helper_text};
        let temp_data = {...data};
        temp_data[name] = value;

        setData(temp_data);
    }


    useEffect(() => {
        if (is_new) {
            let temp_data = {...data};
            Object.keys(data).map(data_key => {
                if (data_key.includes("_en")) {
                    let lang_key = data_key.replace("_en", `_${lang}`);
                    if (!isValidValue(temp_data[lang_key])) {
                        temp_data[lang_key] = temp_data[data_key];
                    }
                }
            })
            setData(temp_data);
        }

    }, [lang])


    useEffect(() => {
        if (!is_new) {
            fetchData();
        } else {
            setLoading(false);
        }

    }, [])


    const onCancel = () => {
        props.history.push(`/${slug}/invoices`);
    }


    const onDelete = async () => {
        await setLoading(true);
        let response = await httpRequestWithAuth(`${InvoicesURL}${data.id}`, "DELETE");
        if (response.status) {
            openNotification("success", "Delete Invoice", `${data.title_en} invoice has been deleted`)
            onCancel();
        } else {
            openNotification("error", "Delete Invoice", response.error_feedback.non_field_errors)
        }
        await setLoading(false);
    }


    const handleDelete = () => {
        showConfirm("Delete Invoice", `Are you sure to delete ${data.title_en} invoice ?`, onDelete);
    }


    const onSave = async () => {
        await setLoading(true);
        setHelperText({});
        let temp_data = {
            ...data
        }


        let response;
        if (is_new) {
            response = await httpRequestWithAuth(InvoicesURL, "POST", temp_data);
        } else {
            response = await httpRequestWithAuth(`${InvoicesURL}${data.id}`, "PUT", temp_data);
        }

        if (response.status) {
            props.setInvoiceData(response.data);
            if (is_new) {
                openNotification("success", "Add Invoice", `${response.data.ref_no} invoice has been added`);
            } else {
                openNotification("success", "Edit Invoice", response.data.msg || `${response.data.ref_no} invoice has been updated`);
            }


            let to_tab = 2;


            if (to_tab > 0) {
                if (is_new) {
                    props.history.push(`/${slug}/invoices/${response.data.id}/?tab=${to_tab}`);
                } else {
                    // props.handleTabsChange(to_tab);
                    onCancel();
                }
            } else {
                onCancel();
            }


        } else {
            setHelperText(response.error_feedback)
        }

        await setLoading(false);
    }


    const sendPaymentRequest = async () => {
        let response = await httpRequestWithAuth(InvoicesSendPaymentRequestURL(data.id), "POST")

        if (response.data){
            openNotification("success", "Send Payment Request", `Email has been sent`)
        }else{
            openNotification("error", "Send Payment Request", response.error_feedback.non_field_errors)
        }
    }

    const sendPaymentReceipt = async () => {
        let response = await httpRequestWithAuth(InvoicesSendPaymentReceiptURL(data.id), "POST")

        if (response.data){
            openNotification("success", "Send Payment Receipt", `Email has been sent`)
        }else{
            openNotification("error", "Send Payment Receipt", response.error_feedback.non_field_errors)
        }
    }


    const handelGenerateLinkModal = () =>{
        setOpenGenerateLink(!open_generate_link)
    }


    const handelActionButtonOptions = ({key}) => {

        if (key === "1"){
            sendPaymentReceipt()
        }

        if (key === "2"){
            sendPaymentRequest()
        }

        if (key === "3"){
            handelGenerateLinkModal()
        }


    }




    return (
        <div className="paper pt-0 w-100">


            <Hide hide={!open_generate_link}>
            <InvoiceGeneratePaymentLinkModal
                invoice_id={data.id}
                open={open_generate_link}
                handleModelClose={handelGenerateLinkModal}
            />
            </Hide>

            <Hide hide={!loading}>
                <HELoading/>
            </Hide>

            <Hide hide={loading}>
                <Row gutter={[12, 10]}>


                    <Col xs={24} className="d-flex align-items-center justify-content-between">
                        <h2 className="font-size-22 mb-0">
                                Details
                        </h2>

                        <Hide hide={is_new}>
                            <CustomDropDown btn_type="primary" label="Actions" options={actions_options}
                                            onChange={handelActionButtonOptions}/>
                        </Hide>
                    </Col>



                    <Col xs={24} md={8}>
                        <CustomTextField
                            required
                            view_only={!has_perm}
                            label="Customer Name"
                            name="customer_name"
                            value={data.customer_name}
                            onChange={handleFieldChange}
                            error={helper_text.customer_name !== undefined}
                            helper_text={helper_text.customer_name}
                        />
                    </Col>

                    <Col xs={24} md={8}>
                        <CustomTextField
                            required
                            view_only={!has_perm}
                            label="Customer Email"
                            name="customer_email"
                            value={data.customer_email}
                            onChange={handleFieldChange}
                            error={helper_text.customer_email !== undefined}
                            helper_text={helper_text.customer_email}
                        />
                    </Col>

                    <Col xs={24} md={8}>
                        <CustomPhoneNumberField
                            view_only={!has_perm}
                            label="Customer Contact Number"
                            name="customer_contact_number"
                            value={data.customer_contact_number}
                            onChange={handleFieldChange}
                            error={helper_text.customer_contact_number !== undefined}
                            helper_text={helper_text.customer_contact_number}
                        />
                    </Col>



                    <Hide hide={is_new}>
                        <Col xs={24} md={4}>
                            <CustomTextField
                                view_only
                                label="Ref No"
                                name="ref_no"
                                value={data.ref_no}
                            />
                        </Col>

                    </Hide>

                    <Col xs={24} md={is_new ? 24 : 20}>
                        <CustomTextField
                            required
                            view_only={!has_perm}
                            label="title"
                            name="title"
                            value={data.title}
                            onChange={handleFieldChange}
                            error={helper_text.title !== undefined}
                            helper_text={helper_text.title}
                        />
                    </Col>

                    <Col xs={24}>
                        <CustomTextAreaField
                            required
                            view_only={!has_perm}
                            label="Description"
                            name="description"
                            value={data.description}
                            onChange={handleFieldChange}
                            error={helper_text.description !== undefined}
                            helper_text={helper_text.description}
                        />
                    </Col>

                    <Col xs={24} md={8}>
                        <CustomDateField
                            required
                            view_only={!has_perm}
                            label="Invoice Date"
                            name="invoice_date"
                            value={data.invoice_date}
                            onChange={handleFieldChange}
                            error={helper_text.invoice_date !== undefined}
                            helper_text={helper_text.invoice_date}
                        />
                    </Col>

                    <Col xs={24} md={8}>
                        <CustomTextField
                            required
                            view_only={!has_perm}
                            label="Net Total"
                            name="net_total"
                            value={data.net_total}
                            onChange={handleFieldChange}
                            error={helper_text.net_total !== undefined}
                            helper_text={helper_text.net_total}
                            type="number"
                            addonAfter={data.currency}
                        />
                    </Col>

                    <Col xs={24} md={8}>
                        <CustomSelectField
                            required
                            view_only={!has_perm}
                            view_value_only={!has_perm}
                            label="Status"
                            name="status"
                            value={data.status}
                            onChange={handleFieldChange}
                            error={helper_text.status !== undefined}
                            helper_text={helper_text.status}
                            options={status_options}
                        />
                    </Col>

                    <Col xs={24} md={8}>
                        <CustomSelectField
                            required
                            label="Payment Method"
                            name="payment_method"
                            value={data.payment_method}
                            onChange={handleFieldChange}
                            error={helper_text.payment_method !== undefined}
                            helper_text={helper_text.payment_method}
                            options={payment_methods}
                            view_only={!has_perm}
                            disabled={!data.is_paid}
                            view_value_only={!has_perm || !data.is_paid}
                        />
                    </Col>

                    <Col xs={24} md={8}>
                        <CustomDateTimeField
                            view_only={!has_perm}
                            label="Payment Date"
                            name="payment_date"
                            value={data.payment_date}
                            onChange={handleFieldChange}
                            helper_text={helper_text.payment_date}
                            error={helper_text.payment_date !== undefined}
                            disabled={!data.is_paid}
                        />
                    </Col>

                    <Col xs={24} md={8}>
                        <CustomTextField
                            view_only={!has_perm}
                            label="Payment Ref No"
                            name="payment_ref_no"
                            value={data.payment_ref_no}
                            onChange={handleFieldChange}
                            helper_text={helper_text.payment_ref_no}
                            error={helper_text.payment_ref_no !== undefined}
                            disabled={!data.is_paid}
                        />
                    </Col>

                    <Col xs={24}>
                        <CustomCheckBox
                            name="is_paid"
                            label="Is Paid"
                            value={data.is_paid}
                            onChange={handleFieldChange}
                        />
                    </Col>

                    <Hide hide={is_new}>

                    <Col xs={24} className="mt-3"/>
                        <ViewActionsDetails data={data}/>
                    </Hide>

                    <Col xs={24}>
                        <ActionButton
                            error_msg={helper_text.non_field_errors}
                            onDelete={handleDelete}
                            onCancel={onCancel}
                            allow_delete={isValidValue(data.id) && checkPermission(permission_data.invoice.delete)}
                            allow_save={has_perm}
                            onSave={onSave}
                        />
                    </Col>

                </Row>
            </Hide>
        </div>
    )
}
