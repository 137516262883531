import CustomModal from "../../../global/components/CustomModal";
import {Col, Divider, Row} from "antd";
import {useState} from "react";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {AgenciesContractURL} from "../../../global/urls";
import {isValidValue, openNotification} from "../../../global/helper_functions";
import {ModelActionButton} from "../../../global/components/ModelActionButton";
import CustomCheckBox from "../../../global/components/fields/CustomCheckBox";
import {CustomUploadField} from "../../../global/components/fields/CustomUploadField";
import {AgencyAutoComplete} from "../../../global/components/fields/AgencyAutoComplete";
import {CustomDateField} from "../../../global/components/fields/CustomDateField";
import {CustomTextField} from "../../../global/components/fields/CustomTextField";
import Hide from "../../../global/components/Hide";
import CustomSelectField from "../../../global/components/fields/CustomSelectField";
import {contract_type} from "../../../global/variables";

const commission_amount_option = [
    {title:"Percentage", value:"Percentage"},
    {title:"Fixed", value:"Fixed"},
]

export const AgencyContractFormModal = props => {
    const [data, setData] = useState({
        is_cancelled: false,
        he_commission_type: "Percentage",
        commission_amount: 20,
        minimum_commission_amount: 5,
        ...props.data
    });
    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});

    const handleFieldChange = (name, value) => {
        let temp_data = {...data};
        temp_data[name] = value;

        if (["contract_documents"].includes(name)){
            temp_data[`${name}_filename`] = isValidValue(value)? value.name: "";
        }

        setData(temp_data);
    }



    const onSave = async () => {
        await setLoading(true);

        const is_new = !isValidValue(data.id);
        let response;


        let form_data = new FormData();

        Object.keys(data).map(Name=> {
            if (isValidValue(data[Name])) {
                form_data.append(Name, data[Name]);
            }
        });


        if (is_new) {
            response = await httpRequestWithAuth(AgenciesContractURL, "POST", form_data);
        } else {
            response = await httpRequestWithAuth(`${AgenciesContractURL}${data.id}`, "PUT", form_data);
        }

        if (response.status) {
            if (is_new) {
                openNotification("success", "Add Agency Contract", `agency contract has been added`)
            } else {
                openNotification("success", "Update Agency Contract", `agency contract has been updated`)
            }
            props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
        }

        await setLoading(false);

    }

    return (
        <CustomModal title="Agency Contract Form" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>


                {/*<Col xs={24} md={8}>*/}
                {/*    <AgencyAutoComplete*/}
                {/*        view_only*/}
                {/*        label="Agency"*/}
                {/*        value={data.AgencyId}*/}
                {/*    />*/}
                {/*</Col>*/}


                <Col xs={24} md={8}>
                    <CustomSelectField
                        required
                        label="Contract Type"
                        name="contract_type"
                        value={data.contract_type}
                        onChange={handleFieldChange}
                        error={helper_text.contract_type !== undefined}
                        helper_text={helper_text.contract_type}
                        options={contract_type}
                    />
                </Col>

                <Col xs={24} md={8}>
                    <CustomTextField
                        required
                        label="Signature Full Name"
                        name="agency_signature_full_name"
                        value={data.agency_signature_full_name}
                        onChange={handleFieldChange}
                        error={helper_text.agency_signature_full_name !== undefined}
                        helper_text={helper_text.agency_signature_full_name}
                    />
                </Col>

                <Col xs={24} md={8}>
                    <CustomDateField
                        required
                        label="Signature Date"
                        name="signature_date"
                        value={data.signature_date}
                        onChange={handleFieldChange}
                        error={helper_text.signature_date !== undefined}
                        helper_text={helper_text.signature_date}
                    />
                </Col>

                <Col xs={24} md={8}>
                    <CustomSelectField
                        required
                        label="HE Commission Type"
                        name="he_commission_type"
                        value={data.he_commission_type}
                        onChange={handleFieldChange}
                        error={helper_text.he_commission_type !== undefined}
                        helper_text={helper_text.he_commission_type}
                        options={commission_amount_option}
                    />
                </Col>

                <Col xs={24} md={8}>
                    <CustomTextField
                        required
                        label="Commission Amount"
                        name="commission_amount"
                        value={data.commission_amount}
                        onChange={handleFieldChange}
                        error={helper_text.commission_amount !== undefined}
                        helper_text={helper_text.commission_amount}
                        type="number"
                        addonAfter={data.he_commission_type === "Percentage"?"%":"KWD"}
                    />
                </Col>

                <Col xs={24} md={8}>
                    <CustomTextField
                        required
                        label="Minimum Commission Amount"
                        name="minimum_commission_amount"
                        value={data.minimum_commission_amount}
                        onChange={handleFieldChange}
                        error={helper_text.minimum_commission_amount !== undefined}
                        helper_text={helper_text.minimum_commission_amount}
                        type="number"
                        addonAfter="KWD"
                    />
                </Col>


                <Col xs={24} md={8}>
                    <CustomDateField
                        required
                        label="Start Date"
                        name="start_date"
                        value={data.start_date}
                        onChange={handleFieldChange}
                        error={helper_text.start_date !== undefined}
                        helper_text={helper_text.start_date}
                    />
                </Col>

                <Col xs={24} md={8}>
                    <CustomDateField
                        required
                        label="End Date"
                        name="end_date"
                        value={data.end_date}
                        onChange={handleFieldChange}
                        error={helper_text.end_date !== undefined}
                        helper_text={helper_text.end_date}
                    />
                </Col>

                <Col xs={24} md={8}>
                    <CustomUploadField
                        display_value={data.contract_documents_filename}
                        label="Contract Documents"
                        name="contract_documents"
                        value={data.contract_documents}
                        onChange={handleFieldChange}
                        error={helper_text.contract_documents !== undefined}
                        helper_text={helper_text.contract_documents}
                    />
                </Col>

                <Hide hide={!data.is_cancelled}>
                <Col xs={24} md={12}>
                    <CustomTextField
                        label="Cancelled Reason"
                        name="cancelled_reason"
                        value={data.cancelled_reason}
                        onChange={handleFieldChange}
                        error={helper_text.cancelled_reason !== undefined}
                        helper_text={helper_text.cancelled_reason}
                        disabled={!data.is_cancelled}
                    />
                </Col>

                <Col xs={24} md={12}>
                    <CustomDateField
                        label="Cancelled Date"
                        name="cancelled_date"
                        value={data.cancelled_date}
                        onChange={handleFieldChange}
                        error={helper_text.cancelled_date !== undefined}
                        helper_text={helper_text.cancelled_date}
                        disabled={!data.is_cancelled}
                    />
                </Col>
                </Hide>

                <Col xs={24}>
                    <CustomCheckBox
                        label="Is Cancelled"
                        name="is_cancelled"
                        value={data.is_cancelled}
                        onChange={handleFieldChange}
                    />
                </Col>

                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}
