import {useEffect, useState} from "react";
import {PackagesPendingChangesURL} from "../../../../global/urls";
import CustomBadge from "../../../../global/components/CustomBadge";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {openNotification, showError} from "../../../../global/helper_functions";
import {checkPermission} from "../../../../global/permissions";
import {permission_data} from "../../../../global/permissions_data";
import {Button, Col, Image, Popconfirm, Row, Table} from "antd";

export const PackagePendingChangesDetails = (props) => {
    const ref_no = props.ref_no;
    const status = props.status;
    const is_admin = (props.user_type || "") === "Admin";
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(false);

    const [package_pending_change_perm] = useState({
        delete: checkPermission(permission_data.package_pending_change.delete),
        edit: checkPermission(permission_data.package_pending_change.edit),
        add: checkPermission(permission_data.package_pending_change.add),
    });

    const [columns, setColumns] = useState([
        {
            title: "Field Name",
            dataIndex: `field_name_view`,
            width: "15%"
        },
        {
            title: "Old Value",
            dataIndex: `old_value_view`,
            width: "15%",
            render: (value, row_data) => {
                if (row_data.old_value_type === "Image") {
                    return <Image
                        width={300}
                        src={value}
                    />
                }
                return <div dangerouslySetInnerHTML={{__html: value}}/>;
            }
        },
        {
            title: "New Value",
            dataIndex: `new_value_view`,
            width: "15%",
            render: (value, row_data) => {
                if (row_data.new_value_type === "Image") {
                    return <Image
                        width={300}
                        src={value}
                    />
                }
                return <div dangerouslySetInnerHTML={{__html: value}}/>;
            }
        },
        {
            title: "Status",
            dataIndex: "status",
            width: "5%",
            align: "center",
            render: (value) => <CustomBadge title={value}
                                            type={value === "Pending" ? "disabled" : value === "Rejected" ? "danger" : ""}/>
        },
    ]);

    useEffect(() => {
        let temp_columns = [...columns];
        if (status === "Pending" && is_admin && package_pending_change_perm.edit) {
            temp_columns.push({
                title: "Actions",
                dataIndex: "status",
                width: "5%",
                align: "center",
                render: (value, row_data) => {
                    return (
                        <div className="d-flex justify-content-end">
                            <Button
                                onClick={() => onApproved(row_data)}
                                className="d-flex align-items-center m-1" type="primary"
                            >
                                Approve
                            </Button>

                            <Popconfirm
                                placement="bottomRight"
                                title="Are you sure you want to reject this row ??"
                                onConfirm={() => onRejected(row_data)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button
                                    className="d-flex align-items-center m-1" type="danger"
                                >
                                    Reject
                                </Button>
                            </Popconfirm>
                        </div>
                    )
                }
            })

        } else if (status !== "Pending") {
            temp_columns.push({
                title: "Action By",
                dataIndex: `action_by_name`,
                width: "15%"
            });
        }
        setColumns(temp_columns);
    }, [])

    useEffect(() => {
        fetchData();
    }, [reload])

    const reloadData = () => {
        props.reloadMainData();
        setReload(Math.random());
    }
    const fetchData = async () => {
        await setLoading(true);
        let response = await httpRequestWithAuth(`${PackagesPendingChangesURL}${ref_no}`, "GET", null, {status});
        if (response.status) {
            setData(response.data);
        } else {
            showError("Fetch data failed", response.error_feedback.non_field_errors);
        }
        await setLoading(false);
    }


    const onRejected = async (row_data) => {
        let response = await httpRequestWithAuth(PackagesPendingChangesURL, "PUT", {
            id: row_data.id,
            status: "Rejected"
        });
        if (response.status) {
            openNotification("success", "Reject Pending Changes", `${row_data.ref_no} - ${row_data.model_name_view} - ${row_data.field_name_view} changes has been rejected`)
        } else {
            openNotification("error", "Reject Pending Changes", response.error_feedback.non_field_errors)
        }
        reloadData();
    }


    const onApproved = async (row_data) => {
        let response = await httpRequestWithAuth(PackagesPendingChangesURL, "PUT", {
            id: row_data.id,
            status: "Approved"
        });
        if (response.status) {
            openNotification("success", "Approve Pending Changes", `${row_data.ref_no} - ${row_data.model_name_view} - ${row_data.field_name_view} changes has been approved`)
        } else {
            openNotification("error", "Approved Pending Changes", response.error_feedback.non_field_errors)
        }
        reloadData();
    }

    return (
        <Row key={props.key}>
            <Col xs={24}>
                <Table
                    loading={loading}
                    dataSource={data}
                    columns={columns}
                    pagination={false}
                />
            </Col>

        </Row>

    );
}