import { Switch } from "antd";
import React from "react";
import { DISABLED_COLOR, PRIMARY_COLOR } from "../variables";
import CustomBadge from "./CustomBadge";
import {isValidValue} from "../helper_functions";

export const IsActiveView = (props) => {
  if (props.view_only || !isValidValue(props.onChange)) {
    return (
      <CustomBadge
        type={!props.value ? "disabled": ""}
        color={props.value ? "white" : "black"}
        title={props.value ? props.active_label || "Active" : props.inactive_label || "Inactive"}
      />
    );
  }

  return (
    <Switch
      checked={props.value}
      onChange={props.onChange}
      name="is_active"
      color="primary"
    />
  );
};
