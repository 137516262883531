import React from "react";
import ShortLogo from "../../assets/img/short_logo.png"
import Hide from "./Hide";

export const Loading = props =>{
    return(
        <div className="w-100 text-center mt-4 pt-4 pb-2 mb-3">
            <img src={ShortLogo} width={150} className="spinner" style={{width:150, height:150}}/>
            <Hide hide={props.hide_text}>
            <h1 className="mt-2">{props.msg || "loading...."}</h1>
            </Hide>

        </div>
    )
}
