import DynamicAutocomplete from "./DynamicAutocomplete";
import {RulesListAllURL} from "../../urls";

export const MultiRulesAutoComplete = (props)=>{
    const params = {rule_for: props.rule_for};

    return(
        <DynamicAutocomplete
            {...props}
            fetch_url={RulesListAllURL}
            title_key="name"
            mode="multiple"
            params_array={[props.rule_for]}
            params={params}
        />
    )
}