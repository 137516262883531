import CustomModal from "../../../global/components/CustomModal";
import {Col, Collapse, Row} from "antd";
import {useState} from "react";
import {CustomTextField} from "../../../global/components/fields/CustomTextField";
import CustomSelectField from "../../../global/components/fields/CustomSelectField";
import CustomCheckBox from "../../../global/components/fields/CustomCheckBox";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {RulesURL} from "../../../global/urls";
import {isValidValue, openNotification} from "../../../global/helper_functions";
import {ModelActionButton} from "../../../global/components/ModelActionButton";

const {Panel} = Collapse;


const rule_for_options = [{title: "Admin", value: "Admin"}, {title: "Agent", value: "Agent"},]


const rule_depend_on = {

    'can_view_rule': [],
    'can_add_rule': ['can_view_rule'],
    'can_edit_rule': ['can_view_rule'],
    'can_delete_rule': ['can_view_rule'],
    'can_change_user_status': ['can_view_rule'],

    'can_view_user': [],
    'can_add_user': ['can_view_user'],
    'can_edit_user': ['can_view_user'],
    'can_delete_user': ['can_view_user'],

    "can_view_country": [],
    "can_add_country": ['can_view_country'],
    "can_edit_country": ['can_view_country'],
    "can_delete_country": ['can_view_country'],

    "can_view_city": [],
    "can_add_city": ['can_view_city'],
    "can_edit_city": ['can_view_city'],
    "can_delete_city": ['can_view_city'],

    "can_view_customer": [],
    "can_add_customer": ['can_view_customer'],
    "can_edit_customer": ['can_view_customer'],
    "can_delete_customer": ['can_view_customer'],

    "can_view_agency": [],
    "can_add_agency": ['can_view_agency'],
    "can_edit_agency": ['can_view_agency'],
    "can_delete_agency": ['can_view_agency'],

    "can_view_agency_contract": [],
    "can_add_agency_contract": ['can_view_agency_contract'],
    "can_edit_agency_contract": ['can_view_agency_contract'],
    "can_delete_agency_contract": ['can_view_agency_contract'],

    "can_view_agency_document": [],
    "can_add_agency_document": ['can_view_agency_document'],
    "can_edit_agency_document": ['can_view_agency_document'],
    "can_delete_agency_document": ['can_view_agency_document'],

    "can_view_language": [],
    "can_add_language": ['can_view_language'],
    "can_edit_language": ['can_view_language'],
    "can_delete_language": ['can_view_language'],

    "can_view_advertisement": [],
    "can_add_advertisement": ['can_view_advertisement'],
    "can_edit_advertisement": ['can_view_advertisement'],
    "can_delete_advertisement": ['can_view_advertisement'],

    "can_view_he_media": [],
    "can_add_he_media": ['can_view_he_media'],
    "can_edit_he_media": ['can_view_he_media'],
    "can_delete_he_media": ['can_view_he_media'],

    "can_view_global_data": [],
    "can_add_global_data": ['can_view_global_data'],
    "can_edit_global_data": ['can_view_global_data'],
    "can_delete_global_data": ['can_view_global_data'],
    "can_change_global_data_key": ['can_view_global_data'],

    "can_view_currency": [],
    "can_add_currency": ['can_view_currency'],
    "can_edit_currency": ['can_view_currency'],
    "can_delete_currency": ['can_view_currency'],

    "can_view_companion": [],
    "can_add_companion": ['can_view_companion'],
    "can_edit_companion": ['can_view_companion'],
    "can_delete_companion": ['can_view_companion'],

    "can_view_service_charge": [],
    "can_add_service_charge": ['can_view_service_charge'],
    "can_edit_service_charge": ['can_view_service_charge'],
    "can_delete_service_charge": ['can_view_service_charge'],

    "can_view_guru": [],
    "can_add_guru": ['can_view_guru'],
    "can_edit_guru": ['can_view_guru'],
    "can_delete_guru": ['can_view_guru'],

    "can_view_package_category": [],
    "can_add_package_category": ['can_view_package_category'],
    "can_edit_package_category": ['can_view_package_category'],
    "can_delete_package_category": ['can_view_package_category'],

    "can_view_package_inclusions": [],
    "can_add_package_inclusions": ['can_view_package_inclusions'],
    "can_edit_package_inclusions": ['can_view_package_inclusions'],
    "can_delete_package_inclusions": ['can_view_package_inclusions'],


    "can_view_package": [],
    "can_add_package": ['can_view_package'],
    "can_edit_package": ['can_view_package'],
    "can_delete_package": ['can_view_package'],
    "receive_email_for_new_travel_package": ['can_view_package'],
    "receive_email_for_new_experience_package": ['can_view_package'],

    "can_view_package_setting": ['can_view_package'],
    "can_edit_package_setting": ['can_view_package_setting', 'can_view_package'],
    "can_update_package_he_commission": ['can_view_package_setting', 'can_view_package'],

    "can_view_package_option": ['can_view_package'],
    "can_add_package_option": ['can_view_package_option', 'can_view_package'],
    "can_edit_package_option": ['can_view_package_option', 'can_view_package'],
    "can_delete_package_option": ['can_view_package_option', 'can_view_package'],

    "can_view_package_gallery": ['can_view_package'],
    "can_add_package_gallery": ['can_view_package_gallery', 'can_view_package'],
    "can_edit_package_gallery": ['can_view_package_gallery', 'can_view_package'],
    "can_delete_package_gallery": ['can_view_package_gallery', 'can_view_package'],

    "can_view_package_installment": ['can_view_package'],
    "can_add_package_installment": ['can_view_package_installment', 'can_view_package'],
    "can_edit_package_installment": ['can_view_package_installment', 'can_view_package'],
    "can_delete_package_installment": ['can_view_package_installment', 'can_view_package'],

    "can_view_package_pending_change": ['can_view_package'],
    "can_add_package_pending_change": ['can_view_package_pending_change', 'can_view_package'],
    "can_edit_package_pending_change": ['can_view_package_pending_change', 'can_view_package'],
    "can_delete_package_pending_change": ['can_view_package_pending_change', 'can_view_package'],
    "receive_email_for_new_travel_package_pending_change": [],
    "receive_email_for_new_experience_package_pending_change": [],

    "can_view_package_itinerary": ['can_view_package'],
    "can_add_package_itinerary": ['can_view_package_itinerary', 'can_view_package'],
    "can_edit_package_itinerary": ['can_view_package_itinerary', 'can_view_package'],
    "can_delete_package_itinerary": ['can_view_package_itinerary', 'can_view_package'],

    "can_view_package_photo_album": ['can_view_package'],
    "can_add_package_photo_album": ['can_view_package_photo_album', 'can_view_package'],
    "can_edit_package_photo_album": ['can_view_package_photo_album', 'can_view_package'],
    "can_delete_package_photo_album": ['can_view_package_photo_album', 'can_view_package'],

    "can_view_package_addon": ['can_view_package'],
    "can_add_package_addon": ['can_view_package_addon', 'can_view_package'],
    "can_edit_package_addon": ['can_view_package_addon', 'can_view_package'],
    "can_delete_package_addon": ['can_view_package_addon', 'can_view_package'],

    "can_view_package_option_date": ['can_view_package', 'can_view_package_option'],
    "can_add_package_option_date": ['can_view_package_option_date', 'can_view_package', 'can_view_package_option'],
    "can_edit_package_option_date": ['can_view_package_option_date', 'can_view_package', 'can_view_package_option'],
    "can_delete_package_option_date": ['can_view_package_option_date', 'can_view_package', 'can_view_package_option'],

    "can_view_package_option_date_time": ['can_view_package', 'can_view_package_option', 'can_view_package_option_date'],
    "can_add_package_option_date_time": ['can_view_package_option_date_time', 'can_view_package', 'can_view_package_option', 'can_view_package_option_date'],
    "can_edit_package_option_date_time": ['can_view_package_option_date_time', 'can_view_package', 'can_view_package_option', 'can_view_package_option_date'],
    "can_delete_package_option_date_time": ['can_view_package_option_date_time', 'can_view_package', 'can_view_package_option', 'can_view_package_option_date'],


    "can_view_change_log": ['can_view_package'],
    "can_add_change_log": ['can_view_change_log'],
    "can_edit_change_log": ['can_view_change_log'],
    "can_delete_change_log": ['can_view_change_log'],

    "can_view_tier": [],
    "can_add_tier": ['can_view_tier'],
    "can_edit_tier": ['can_view_tier'],
    "can_delete_tier": ['can_view_tier'],

    "can_view_booking": [],
    "can_add_booking": ['can_view_booking'],
    "can_edit_booking": ['can_view_booking'],
    "can_delete_booking": ['can_view_booking'],
    "can_update_booking_customer_info": ['can_view_booking'],
    "can_update_booking_service_charge_info": ['can_view_booking'],
    "can_view_booking_finance": ['can_view_booking'],
    "can_update_booking_transferred_amount_to_agency": ['can_view_booking', 'can_view_booking_finance'],

    "can_view_booking_payment": ['can_view_booking'],
    "can_add_booking_payment": ['can_view_booking', 'can_view_booking_payment'],
    "can_edit_booking_payment": ['can_view_booking', 'can_view_booking_payment'],
    "can_edit_paid_booking_payment": ['can_edit_booking_payment', 'can_view_booking', 'can_view_booking_payment'],
    "can_delete_booking_payment": ['can_view_booking', 'can_view_booking_payment'],

    "can_view_booking_refund": ['can_view_booking'],
    "can_add_booking_refund": ['can_view_booking', 'can_view_booking_refund'],
    "can_edit_booking_refund": ['can_view_booking', 'can_view_booking_refund'],
    "can_delete_booking_refund": ['can_view_booking', 'can_view_booking_refund'],

    "can_view_booking_person": ['can_view_booking'],
    "can_add_booking_person": ['can_view_booking', 'can_view_booking_person'],
    "can_edit_booking_person": ['can_view_booking', 'can_view_booking_person'],
    "can_delete_booking_person": ['can_view_booking', 'can_view_booking_person'],

    "can_send_payment_receipt_to_customer_by_email": ['can_view_booking'],
    "can_send_payment_request_to_customer_by_email": ['can_view_booking'],

    "receive_email_for_abandoned_travel_booking": [''],
    "receive_email_for_new_travel_booking": [''],
    "receive_email_for_travel_booking_payment": [''],

    "receive_email_for_abandoned_experience_booking": [''],
    "receive_email_for_new_experience_booking": [''],
    "receive_email_for_experience_booking_payment": [''],

    "can_view_conversion_rate": [''],
    "can_add_conversion_rate": ['can_view_conversion_rate'],
    "can_edit_conversion_rate": ['can_view_conversion_rate'],
    "can_delete_conversion_rate": ['can_view_conversion_rate'],


    "can_view_consultation_request": [''],
    "can_add_consultation_request": ['can_view_consultation_request'],
    "can_edit_consultation_request": ['can_view_consultation_request'],
    "can_delete_consultation_request": ['can_view_consultation_request'],


    "can_view_consultation_response": [''],
    "can_add_consultation_response": ['can_view_consultation_response'],
    "can_edit_consultation_response": ['can_view_consultation_response'],
    "can_delete_consultation_response": ['can_view_consultation_response'],


    "receive_email_for_new_consultation_request": [''],
    "can_send_payment_request_to_customer_for_consultation_response": ['can_view_consultation_response'],
    "can_send_consultation_response_in_email_to_customer": ['can_view_consultation_response'],
    "receive_email_for_consultation_response_payment": [''],


    "can_view_myfatoorah_payment_method": [''],
    "can_edit_myfatoorah_payment_method": ['can_view_myfatoorah_payment_method'],

    "can_view_online_payment": [''],


    "can_view_contact_us": [''],
    "can_add_contact_us": ['can_view_contact_us'],
    "can_edit_contact_us": ['can_view_contact_us'],
    "can_delete_contact_us": ['can_view_contact_us'],
    "receive_email_for_contact_us_request": [''],


    "can_view_join_us_request": [''],
    "can_add_join_us_request": ['can_view_join_us_request'],
    "can_edit_join_us_request": ['can_view_join_us_request'],
    "can_delete_join_us_request": ['can_view_join_us_request'],
    "receive_email_for_join_us_request": [''],

    "can_view_voucher": [''],
    "can_add_voucher": ['can_view_voucher'],
    "can_edit_voucher": ['can_view_voucher'],
    "can_delete_voucher": ['can_view_voucher'],
    "receive_email_for_digital_gift_voucher_payment": [],


    "can_view_customize_voucher": ['can_view_voucher'],
    "can_add_customize_voucher": ['can_view_customize_voucher', 'can_view_voucher'],
    "can_edit_customize_voucher": ['can_view_customize_voucher', 'can_view_voucher'],
    "can_delete_customize_voucher": ['can_view_customize_voucher', 'can_view_voucher'],


    "can_view_product": [''],
    "can_add_product": ['can_view_product'],
    "can_edit_product": ['can_view_product'],
    "can_delete_product": ['can_view_product'],

    "can_view_product_gallery": ['can_view_product'],
    "can_add_product_gallery": ['can_view_product', 'can_view_product_gallery'],
    "can_edit_product_gallery": ['can_view_product', 'can_view_product_gallery'],
    "can_delete_product_gallery": ['can_view_product', 'can_view_product_gallery'],

    "can_view_product_sale": [],
    "can_add_product_sale": ['can_view_product_sale'],
    "can_edit_product_sale": ['can_view_product_sale'],
    "can_delete_product_sale": ['can_view_product_sale'],
    "receive_email_for_new_product_sale": [],
    "receive_email_for_product_sale_payment": [],
    "send_product_sale_payment_request_email": [],
    "send_product_sale_payment_receipt_email": [],

    "view_today_booking_number_report": [],
    "view_week_booking_number_report": [],
    "view_month_booking_number_report": [],
    "view_year_booking_number_report": [],
    "view_today_incoming_report": [],
    "view_week_incoming_report": [],
    "view_month_incoming_report": [],
    "view_year_incoming_report": [],
    "view_incoming_details_report": [],
    "view_refund_details_report": [],
    "view_today_refund_report": [],
    "view_week_refund_report": [],
    "view_month_refund_report": [],
    "view_year_refund_report": [],
    "view_travel_age_scale_report": [],
    "view_experience_age_scale_report": [],
    "view_customers_statistics_report": [],
    "view_bookings_status_report": [],
    "view_bookings_over_months": [],
    "view_bookings_revenue_over_months": [],
    "view_consultations_revenue_over_months": [],
    "view_top_packages_report": [],

    "can_view_invoice": [],
    "can_add_invoice": ['can_view_invoice'],
    "can_edit_invoice": ['can_view_invoice'],
    "can_delete_invoice": ['can_view_invoice'],
    "receive_email_for_new_invoice": [],
    "receive_email_for_invoice_payment": [],
    "send_invoice_payment_request_email": [],
    "send_invoice_payment_receipt_email": [],


    "can_view_blog": [],
    "can_add_blog": ['can_view_blog'],
    "can_edit_blog": ['can_view_blog'],
    "can_delete_blog": ['can_view_blog'],


    "can_view_testimonial": [],
    "can_add_testimonial": ['can_view_testimonial'],
    "can_edit_testimonial": ['can_view_testimonial'],
    "can_delete_testimonial": ['can_view_testimonial'],
    "receive_email_for_new_testimonial": [],

    "can_view_tour": [],
    "can_add_tour": ['can_view_tour'],
    "can_edit_tour": ['can_view_tour'],
    "can_delete_tour": ['can_view_tour'],
}


export const RuleFormModal = props => {
    const [data, setData] = useState({rule_for: "Admin", ...props.data});
    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});

    const handleFieldChange = (name, value) => {
        if (loading) return;

        let temp_data = {...data};
        temp_data[name] = value;

        if (!['name', 'rule_for'].includes(name) && value) {
            rule_depend_on[name].map(depend_on => {
                temp_data[depend_on] = true;
            })
        }

        setData(temp_data);
    }

    const onSave = async () => {
        await setLoading(true);

        const is_new = !isValidValue(data.id);
        let response;

        if (is_new) {
            response = await httpRequestWithAuth(RulesURL, "POST", data);
        } else {
            response = await httpRequestWithAuth(`${RulesURL}${data.id}`, "PUT", data);
        }

        if (response.status) {
            if (is_new) {
                openNotification("success", "Add Rule", `${data.name} rule has been added`)
            } else {
                openNotification("success", "Update Rule", `${data.name} rule has been updated`)
            }
            props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
        }

        await setLoading(false);

    }

    return (<CustomModal title="Rule Form" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>

                <Col xs={24} md={12}>
                    <CustomTextField
                        required
                        label="Name"
                        name="name"
                        value={data.name}
                        onChange={handleFieldChange}
                        error={helper_text.name !== undefined}
                        helper_text={helper_text.name}
                    />
                </Col>


                <Col xs={24} md={12}>
                    <CustomSelectField
                        required
                        label="Rule For"
                        name="rule_for"
                        value={data.rule_for}
                        onChange={handleFieldChange}
                        error={helper_text.rule_for !== undefined}
                        helper_text={helper_text.rule_for}
                        options={rule_for_options}
                        disabled={isValidValue(data.id)}
                    />
                </Col>


                <Col xs={24} md={12}>
                    <Collapse
                        expandIconPosition="right"
                        className="bg-none border-0"
                    >


                        {data.rule_for !== "Agent" && (<Panel header="Advertisements" key="8">
                                <CustomCheckBox name="can_view_advertisement" label="View Advertisement"
                                                value={data.can_view_advertisement} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_add_advertisement" label="Add Advertisement"
                                                value={data.can_add_advertisement}
                                                onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_edit_advertisement" label="Update Advertisement"
                                                value={data.can_edit_advertisement} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_delete_advertisement" label="Delete Advertisement"
                                                value={data.can_delete_advertisement} onChange={handleFieldChange}/>
                            </Panel>)}


                        <Panel header="Agencies" key="5">
                            <CustomCheckBox name="can_view_agency" label="View Agency"
                                            value={data.can_view_agency} onChange={handleFieldChange}/>

                            {data.rule_for !== "Agent" && (
                                <CustomCheckBox name="can_add_agency" label="Add Agency" value={data.can_add_agency}
                                                onChange={handleFieldChange}/>)}
                            <CustomCheckBox name="can_edit_agency" label="Update Agency"
                                            value={data.can_edit_agency} onChange={handleFieldChange}/>
                            {data.rule_for !== "Agent" && (
                                <CustomCheckBox name="can_delete_agency" label="Delete Agency"
                                                value={data.can_delete_agency} onChange={handleFieldChange}/>)}
                        </Panel>


                        {data.rule_for !== "Agent" && (<Panel header="Agencies Contracts" key="15">
                                <CustomCheckBox name="can_view_agency_contract" label="View Agency Contract"
                                                value={data.can_view_agency_contract} onChange={handleFieldChange}/>

                                <CustomCheckBox name="can_add_agency_contract" label="Add Agency Contract"
                                                value={data.can_add_agency_contract}
                                                onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_edit_agency_contract" label="Update Agency Contract"
                                                value={data.can_edit_agency_contract} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_delete_agency_contract" label="Delete Agency Contract"
                                                value={data.can_delete_agency_contract} onChange={handleFieldChange}/>
                            </Panel>

                        )}


                        {data.rule_for !== "Agent" && (<Panel header="Agencies Document" key="16">
                                <CustomCheckBox name="can_view_agency_document" label="View Agency Document"
                                                value={data.can_view_agency_document} onChange={handleFieldChange}/>

                                <CustomCheckBox name="can_add_agency_document" label="Add Agency Document"
                                                value={data.can_add_agency_document}
                                                onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_edit_agency_document" label="Update Agency Document"
                                                value={data.can_edit_agency_document} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_delete_agency_document" label="Delete Agency Document"
                                                value={data.can_delete_agency_document} onChange={handleFieldChange}/>
                            </Panel>)}


                        {data.rule_for !== "Agent" && (<Panel header="Blog" key="29">
                                <CustomCheckBox name="can_view_blog" label="View Blog"
                                                value={data.can_view_blog} onChange={handleFieldChange}/>

                                <CustomCheckBox name="can_add_blog" label="Add Blog"
                                                value={data.can_add_blog}
                                                onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_edit_blog" label="Update Blog"
                                                value={data.can_edit_blog} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_delete_blog" label="Delete Blog"
                                                value={data.can_delete_blog} onChange={handleFieldChange}/>
                            </Panel>)}


                        <Panel header="Booking" key="18">
                            <CustomCheckBox name="can_view_booking" label="View Booking"
                                            value={data.can_view_booking} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_add_booking" label="Add Booking"
                                            value={data.can_add_booking} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_edit_booking" label="Update Booking"
                                             value={data.can_edit_booking} onChange={handleFieldChange}/>
                            {/*<CustomCheckBox name="can_delete_booking" label="Delete Booking"*/}
                            {/*                value={data.can_delete_booking} onChange={handleFieldChange}/>*/}
                            {data.rule_for === "Admin" && (
                                <CustomCheckBox name="can_update_booking_customer_info" label="Update Booking Customer Info."
                                            value={data.can_update_booking_customer_info} onChange={handleFieldChange}/>
                            )}
                            {data.rule_for === "Admin" && (
                                <CustomCheckBox name="can_update_booking_service_charge_info" label="Update Booking Service Charge."
                                            value={data.can_update_booking_service_charge_info} onChange={handleFieldChange}/>
                            )}



                            {data.rule_for === "Admin" && (
                                <>
                            <div className="mt-3"/>

                            <CustomCheckBox name="can_view_booking_finance" label="View Booking Finance"
                                            value={data.can_view_booking_finance} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_update_booking_transferred_amount_to_agency" label="Update Transferred Amount To Agency"
                                            value={data.can_update_booking_transferred_amount_to_agency} onChange={handleFieldChange}/>
                                </>
                            )}

                            <div className="mt-3"/>

                            <CustomCheckBox name="can_view_booking_payment" label="View Booking Payment"
                                            value={data.can_view_booking_payment} onChange={handleFieldChange}/>
                            {/*<CustomCheckBox name="can_add_booking_payment" label="Add Booking Payment"*/}
                            {/*                value={data.can_add_booking_payment} onChange={handleFieldChange}/>*/}
                            <CustomCheckBox name="can_edit_booking_payment" label="Update Booking Payment"
                                            value={data.can_edit_booking_payment} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_edit_paid_booking_payment" label="Update Paid Booking Payment"
                                            value={data.can_edit_paid_booking_payment} onChange={handleFieldChange}/>
                            {/*<CustomCheckBox name="can_delete_booking_payment" label="Delete Booking Payment"*/}
                            {/*                value={data.can_delete_booking_payment} onChange={handleFieldChange}/>*/}

                            <div className="mt-3"/>

                            <CustomCheckBox name="can_view_booking_refund" label="View Booking Refund"
                                            value={data.can_view_booking_refund} onChange={handleFieldChange}/>
                            {data.rule_for !== "Agent" && (<>
                                    <CustomCheckBox name="can_add_booking_refund" label="Add Booking Refund"
                                                    value={data.can_add_booking_refund} onChange={handleFieldChange}/>
                                    <CustomCheckBox name="can_edit_booking_refund" label="Update Booking Refund"
                                                    value={data.can_edit_booking_refund} onChange={handleFieldChange}/>
                                    <CustomCheckBox name="can_delete_booking_refund" label="Delete Booking Refund"
                                                    value={data.can_delete_booking_refund}
                                                    onChange={handleFieldChange}/>
                                </>)}

                            <div className="mt-3"/>

                            <CustomCheckBox name="can_view_booking_person" label="View Booking Person"
                                            value={data.can_view_booking_person} onChange={handleFieldChange}/>
                            {data.rule_for !== "Agent" && (
                            <CustomCheckBox name="can_add_booking_person" label="Add Booking Person"
                                             value={data.can_add_booking_person} onChange={handleFieldChange}/>
                            )}
                            <CustomCheckBox name="can_edit_booking_person" label="Update Booking Person"
                                            value={data.can_edit_booking_person} onChange={handleFieldChange}/>
                            {/*<CustomCheckBox name="can_delete_booking_person" label="Delete Booking Person"*/}
                            {/*                value={data.can_delete_booking_person} onChange={handleFieldChange}/>*/}


                            <div className="mt-3"/>

                            <CustomCheckBox name="can_send_payment_receipt_to_customer_by_email"
                                            label="Send Payment Receipt To Customer By Email"
                                            value={data.can_send_payment_receipt_to_customer_by_email}
                                            onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_send_payment_request_to_customer_by_email"
                                            label="Send Payment Request To Customer By Email"
                                            value={data.can_send_payment_request_to_customer_by_email}
                                            onChange={handleFieldChange}/>


                            <div className="mt-3"/>


                            <CustomCheckBox name="receive_email_for_abandoned_travel_booking"
                                            label="Receive Email For Abandoned Travel Booking"
                                            value={data.receive_email_for_abandoned_travel_booking}
                                            onChange={handleFieldChange}/>
                            <CustomCheckBox name="receive_email_for_new_travel_booking"
                                            label="Receive Email For New Travel Booking"
                                            value={data.receive_email_for_new_travel_booking}
                                            onChange={handleFieldChange}/>
                            <CustomCheckBox name="receive_email_for_travel_booking_payment"
                                            label="Receive Email For Travel Booking Payment"
                                            value={data.receive_email_for_travel_booking_payment}
                                            onChange={handleFieldChange}/>

                            <div className="mt-3"/>

                            <CustomCheckBox name="receive_email_for_abandoned_experience_booking"
                                            label="Receive Email For Abandoned Experience Booking"
                                            value={data.receive_email_for_abandoned_experience_booking}
                                            onChange={handleFieldChange}/>
                            <CustomCheckBox name="receive_email_for_new_experience_booking"
                                            label="Receive Email For New experience"
                                            value={data.receive_email_for_new_experience_booking}
                                            onChange={handleFieldChange}/>
                            <CustomCheckBox name="receive_email_for_experience_booking_payment"
                                            label="Receive Email For Experience Booking Payment"
                                            value={data.receive_email_for_experience_booking_payment}
                                            onChange={handleFieldChange}/>

                        </Panel>

                        {data.rule_for !== "Agent" && (<Panel header="Career" key="34">
                            <CustomCheckBox name="can_view_join_us_request" label="View Join Us Request"
                                            value={data.can_view_join_us_request} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_add_join_us_request" label="Add Join Us Request"
                                            value={data.can_add_join_us_request}
                                            onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_edit_join_us_request" label="Update Join Us Request"
                                            value={data.can_edit_join_us_request} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_delete_join_us_request" label="Delete Join Us Request"
                                            value={data.can_delete_join_us_request} onChange={handleFieldChange}/>

                            <CustomCheckBox name="receive_email_for_join_us_request"
                                            label="Receive Email For New Request"
                                            value={data.receive_email_for_join_us_request}
                                            onChange={handleFieldChange}/>
                        </Panel>)}

                        {data.rule_for !== "Agent" && (<Panel header="Companions" key="11">
                                <CustomCheckBox name="can_view_companion" label="View Companion"
                                                value={data.can_view_companion} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_add_companion" label="Add Companion"
                                                value={data.can_add_companion}
                                                onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_edit_companion" label="Update Companion"
                                                value={data.can_edit_companion} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_delete_companion" label="Delete Companion"
                                                value={data.can_delete_companion} onChange={handleFieldChange}/>
                            </Panel>)}


                        {data.rule_for !== "Agent" && (<Panel header="Consultation" key="22">


                                <CustomCheckBox name="can_view_consultation_request" label="View Consultation Request"
                                                value={data.can_view_consultation_request}
                                                onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_add_consultation_request" label="Add Consultation Request"
                                                value={data.can_add_consultation_request} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_edit_consultation_request" label="Update Consultation Request"
                                                value={data.can_edit_consultation_request}
                                                onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_delete_consultation_request"
                                                label="Delete Consultation Request"
                                                value={data.can_delete_consultation_request}
                                                onChange={handleFieldChange}/>

                                <div className="mt-3"/>

                                <CustomCheckBox name="can_view_consultation_response" label="View Consultation Response"
                                                value={data.can_view_consultation_response}
                                                onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_add_consultation_response" label="Add Consultation Response"
                                                value={data.can_add_consultation_response}
                                                onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_edit_consultation_response"
                                                label="Update Consultation Response"
                                                value={data.can_edit_consultation_response}
                                                onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_delete_consultation_response"
                                                label="Delete Consultation Response"
                                                value={data.can_delete_consultation_response}
                                                onChange={handleFieldChange}/>

                                <div className="mt-3"/>


                                <CustomCheckBox name="receive_email_for_new_consultation_request"
                                                label="Receive Email For New Consultation"
                                                value={data.receive_email_for_new_consultation_request}
                                                onChange={handleFieldChange}/>

                                <CustomCheckBox name="can_send_payment_request_to_customer_for_consultation_response"
                                                label="Send Payment Request Email For Response"
                                                value={data.can_send_payment_request_to_customer_for_consultation_response}
                                                onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_send_consultation_response_in_email_to_customer"
                                                label="Send Consultation Response Email"
                                                value={data.can_send_consultation_response_in_email_to_customer}
                                                onChange={handleFieldChange}/>
                                <CustomCheckBox name="receive_email_for_consultation_response_payment"
                                                label="Receive Email For Consultation Response Payment"
                                                value={data.receive_email_for_consultation_response_payment}
                                                onChange={handleFieldChange}/>

                            </Panel>)}


                        {data.rule_for !== "Agent" && (<Panel header="Contact Us" key="23">
                                <CustomCheckBox name="can_view_contact_us" label="View Contact Us"
                                                value={data.can_view_contact_us} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_add_contact_us" label="Add Contact Us"
                                                value={data.can_add_contact_us}
                                                onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_edit_contact_us" label="Update Contact Us"
                                                value={data.can_edit_contact_us} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_delete_contact_us" label="Delete Contact Us"
                                                value={data.can_delete_contact_us} onChange={handleFieldChange}/>

                                <CustomCheckBox name="receive_email_for_contact_us_request"
                                                label="Receive Email For New Request"
                                                value={data.receive_email_for_contact_us_request}
                                                onChange={handleFieldChange}/>
                            </Panel>)}

                        {data.rule_for !== "Agent" && (<Panel header="Conversion Rate" key="19">
                                <CustomCheckBox name="can_view_conversion_rate" label="View Conversion Rate"
                                                value={data.can_view_conversion_rate} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_add_conversion_rate" label="Add Conversion Rate"
                                                value={data.can_add_conversion_rate}
                                                onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_edit_conversion_rate" label="Update Conversion Rate"
                                                value={data.can_edit_conversion_rate} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_delete_conversion_rate" label="Delete Conversion Rate"
                                                value={data.can_delete_conversion_rate} onChange={handleFieldChange}/>
                            </Panel>)}

                        {/*{data.rule_for !== "Agent" && (*/}
                        {/*    <Panel header="Currencies" key="12">*/}
                        {/*        <CustomCheckBox name="can_view_currency" label="View Currency"*/}
                        {/*                        value={data.can_view_currency} onChange={handleFieldChange}/>*/}
                        {/*        <CustomCheckBox name="can_add_currency" label="Add Currency"*/}
                        {/*                        value={data.can_add_currency}*/}
                        {/*                        onChange={handleFieldChange}/>*/}
                        {/*        <CustomCheckBox name="can_edit_currency" label="Update Currency"*/}
                        {/*                        value={data.can_edit_currency} onChange={handleFieldChange}/>*/}
                        {/*        <CustomCheckBox name="can_delete_currency" label="Delete Currency"*/}
                        {/*                        value={data.can_delete_currency} onChange={handleFieldChange}/>*/}
                        {/*    </Panel>*/}
                        {/*)}*/}

                        {data.rule_for !== "Agent" && (<Panel header="Customers" key="2">
                                <CustomCheckBox name="can_view_customer" label="View Customer"
                                                value={data.can_view_customer} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_add_customer" label="Add Customer"
                                                value={data.can_add_customer}
                                                onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_edit_customer" label="Update Customer"
                                                value={data.can_edit_customer} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_delete_customer" label="Delete Customer"
                                                value={data.can_delete_customer} onChange={handleFieldChange}/>
                            </Panel>)}


                        {data.rule_for !== "Agent" && (<Panel header="Countries" key="3">
                                <CustomCheckBox name="can_view_country" label="View Country"
                                                value={data.can_view_country} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_add_country" label="Add Country" value={data.can_add_country}
                                                onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_edit_country" label="Update Country"
                                                value={data.can_edit_country} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_delete_country" label="Delete Country"
                                                value={data.can_delete_country} onChange={handleFieldChange}/>
                            </Panel>)}


                        {data.rule_for !== "Agent" && (<Panel header="Cities" key="6">
                                <CustomCheckBox name="can_view_city" label="View City"
                                                value={data.can_view_city} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_add_city" label="Add City" value={data.can_add_city}
                                                onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_edit_city" label="Update City"
                                                value={data.can_edit_city} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_delete_city" label="Delete City"
                                                value={data.can_delete_city} onChange={handleFieldChange}/>
                            </Panel>)}


                        <Panel header="Dashboard Reports" key="0">
                            <CustomCheckBox name="view_today_booking_number_report"
                                            label="View Today Booking Number Report"
                                            value={data.view_today_booking_number_report} onChange={handleFieldChange}/>
                            <CustomCheckBox name="view_week_booking_number_report"
                                            label="View Week Booking Number Report"
                                            value={data.view_week_booking_number_report}
                                            onChange={handleFieldChange}/>
                            <CustomCheckBox name="view_month_booking_number_report"
                                            label="View Month Booking Number Report"
                                            value={data.view_month_booking_number_report} onChange={handleFieldChange}/>
                            <CustomCheckBox name="view_year_booking_number_report"
                                            label="View Year Booking Number Report"
                                            value={data.view_year_booking_number_report} onChange={handleFieldChange}/>

                            <div className="mt-3"/>

                            <CustomCheckBox name="view_today_incoming_report" label="View Today Incoming Report"
                                            value={data.view_today_incoming_report} onChange={handleFieldChange}/>

                            <CustomCheckBox name="view_week_incoming_report" label="View Week Incoming Report"
                                            value={data.view_week_incoming_report} onChange={handleFieldChange}/>

                            <CustomCheckBox name="view_month_incoming_report" label="View Month Incoming Report"
                                            value={data.view_month_incoming_report} onChange={handleFieldChange}/>

                            <CustomCheckBox name="view_year_incoming_report" label="View Year Incoming Report"
                                            value={data.view_year_incoming_report} onChange={handleFieldChange}/>

                            <div className="mt-3"/>

                            <CustomCheckBox name="view_today_refund_report" label="View Today Refund Report"
                                            value={data.view_today_refund_report} onChange={handleFieldChange}/>

                            <CustomCheckBox name="view_week_refund_report" label="View Week Refund Report"
                                            value={data.view_week_refund_report} onChange={handleFieldChange}/>

                            <CustomCheckBox name="view_month_refund_report" label="View Month Refund Report"
                                            value={data.view_month_refund_report} onChange={handleFieldChange}/>

                            <CustomCheckBox name="view_year_refund_report" label="View Year Refund Report"
                                            value={data.view_year_refund_report} onChange={handleFieldChange}/>

                            <div className="mt-3"/>

                            <CustomCheckBox name="view_travel_age_scale_report" label="View Travel Age Scale Report"
                                            value={data.view_travel_age_scale_report} onChange={handleFieldChange}/>

                            <CustomCheckBox name="view_experience_age_scale_report"
                                            label="View Experience Age Scale Report"
                                            value={data.view_experience_age_scale_report} onChange={handleFieldChange}/>


                            <div className="mt-3"/>

                            <CustomCheckBox name="view_customers_statistics_report"
                                            label="View Customers Statistics Report"
                                            value={data.view_customers_statistics_report} onChange={handleFieldChange}/>

                            <CustomCheckBox name="view_bookings_status_report" label="View Bookings Status Report"
                                            value={data.view_bookings_status_report} onChange={handleFieldChange}/>
                            <CustomCheckBox name="view_bookings_over_months"
                                            label="View Bookings Over Months / Bookings Performance"
                                            value={data.view_bookings_over_months} onChange={handleFieldChange}/>
                        </Panel>


                        <Panel header="Detailed Reports" key="31">
                            <CustomCheckBox name="view_top_packages_report" label="View Top Packages"
                                            value={data.view_top_packages_report} onChange={handleFieldChange}/>
                            <CustomCheckBox name="view_consultations_revenue_over_months" label="View Consultations Revenue"
                                            value={data.view_consultations_revenue_over_months}
                                            onChange={handleFieldChange}/>
                            <CustomCheckBox name="view_bookings_revenue_over_months" label="View Bookings Revenue"
                                            value={data.view_bookings_revenue_over_months}
                                            onChange={handleFieldChange}/>
                            <CustomCheckBox name="view_incoming_details_report" label="View Incoming Details Report"
                                            value={data.view_incoming_details_report} onChange={handleFieldChange}/>
                            <CustomCheckBox name="view_refund_details_report" label="View Refund Details Report"
                                            value={data.view_refund_details_report} onChange={handleFieldChange}/>
                        </Panel>


                        {data.rule_for !== "Agent" && (<Panel header="Global Data" key="10">
                                <CustomCheckBox name="can_view_global_data" label="View Global Data"
                                                value={data.can_view_global_data} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_add_global_data" label="Add Global Data"
                                                value={data.can_add_global_data}
                                                onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_edit_global_data" label="Update Global Data"
                                                value={data.can_edit_global_data} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_delete_global_data" label="Delete Global Data"
                                                value={data.can_delete_global_data} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_change_global_data_key" label="Change Global Data Key"
                                                value={data.can_change_global_data_key} onChange={handleFieldChange}/>
                            </Panel>)}


                        {data.rule_for !== "Agent" && (<Panel header="Gurus" key="35">
                            <CustomCheckBox name="can_view_guru" label="View Guru"
                                            value={data.can_view_guru} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_add_guru" label="Add Guru"
                                            value={data.can_add_guru}
                                            onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_edit_guru" label="Update Guru"
                                            value={data.can_edit_guru} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_delete_guru" label="Delete Guru"
                                            value={data.can_delete_guru} onChange={handleFieldChange}/>
                        </Panel>)}

                    </Collapse>
                </Col>

                {/*right*/}
                {/*================================================================================================*/}
                {/*================================================================================================*/}

                <Col xs={24} md={12}>
                    <Collapse
                        expandIconPosition="right"
                        className="bg-none border-0"

                    >


                        {data.rule_for !== "Agent" && (<Panel header="Invoices" key="28">
                                <CustomCheckBox name="can_view_invoice" label="View Invoices"
                                                value={data.can_view_invoice} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_add_invoice" label="Add Invoices"
                                                value={data.can_add_invoice} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_edit_invoice" label="Update Invoices"
                                                value={data.can_edit_invoice} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_delete_invoice" label="Delete Invoices"
                                                value={data.can_delete_invoice} onChange={handleFieldChange}/>

                                <div className="mt-3"/>

                                <CustomCheckBox name="receive_email_for_new_invoice"
                                                label="Receive Email For New Invoices"
                                                value={data.receive_email_for_new_invoice}
                                                onChange={handleFieldChange}/>
                                <CustomCheckBox name="receive_email_for_invoice_payment"
                                                label="Receive Email For Product Invoices"
                                                value={data.receive_email_for_invoice_payment}
                                                onChange={handleFieldChange}/>

                                <CustomCheckBox name="send_invoice_payment_receipt_email"
                                                label="Send Invoices Payment Receipt Email"
                                                value={data.send_invoice_payment_receipt_email}
                                                onChange={handleFieldChange}/>
                                <CustomCheckBox name="send_invoice_payment_request_email"
                                                label="Send Invoices Payment Request Email"
                                                value={data.send_invoice_payment_request_email}
                                                onChange={handleFieldChange}/>

                            </Panel>)}


                        {data.rule_for !== "Agent" && (<Panel header="Languages" key="7">
                                <CustomCheckBox name="can_view_language" label="View Language"
                                                value={data.can_view_language} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_add_language" label="Add Language"
                                                value={data.can_add_language}
                                                onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_edit_language" label="Update Language"
                                                value={data.can_edit_language} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_delete_language" label="Delete Language"
                                                value={data.can_delete_language} onChange={handleFieldChange}/>
                            </Panel>)}


                        {data.rule_for !== "Agent" && (<Panel header="Media" key="9">
                                <CustomCheckBox name="can_view_he_media" label="View Media"
                                                value={data.can_view_he_media} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_add_he_media" label="Add Media" value={data.can_add_he_media}
                                                onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_edit_he_media" label="Update Media"
                                                value={data.can_edit_he_media} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_delete_he_media" label="Delete Media"
                                                value={data.can_delete_he_media} onChange={handleFieldChange}/>
                            </Panel>)}


                        {data.rule_for !== "Agent" && (<Panel header="Myfatoorah Payment Method" key="20">
                                <CustomCheckBox name="can_view_myfatoorah_payment_method"
                                                label="View Myfatoorah Payment Method"
                                                value={data.can_view_myfatoorah_payment_method}
                                                onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_edit_myfatoorah_payment_method"
                                                label="Update Myfatoorah Payment Method"
                                                value={data.can_edit_myfatoorah_payment_method}
                                                onChange={handleFieldChange}/>
                            </Panel>)}

                        {data.rule_for !== "Agent" && (<Panel header="Online Payment" key="21">
                                <CustomCheckBox name="can_view_online_payment" label="View Online Payment"
                                                value={data.can_view_online_payment} onChange={handleFieldChange}/>
                            </Panel>)}


                        <Panel header="Package" key="27">
                            <CustomCheckBox name="can_view_package" label="View Package"
                                            value={data.can_view_package} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_add_package" label="Add Package"
                                            value={data.can_add_package}
                                            onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_edit_package" label="Update Package"
                                            value={data.can_edit_package} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_delete_package" label="Delete Package"
                                            value={data.can_delete_package} onChange={handleFieldChange}/>

                            {data.rule_for !== "Agent" && (<>
                                    <CustomCheckBox name="receive_email_for_new_travel_package"
                                                    label="Receive Email For New Travel Package"
                                                    value={data.receive_email_for_new_travel_package}
                                                    onChange={handleFieldChange}/>
                                    <CustomCheckBox name="receive_email_for_new_experience_package"
                                                    label="Receive Email For New Experience Package"
                                                    value={data.receive_email_for_new_experience_package}
                                                    onChange={handleFieldChange}/>
                                </>

                            )}

                            <div className="mt-3"/>

                            <CustomCheckBox name="can_view_package_gallery" label="View Package Gallery"
                                            value={data.can_view_package_gallery} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_add_package_gallery" label="Add Package Gallery"
                                            value={data.can_add_package_gallery}
                                            onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_edit_package_gallery" label="Update Package Gallery"
                                            value={data.can_edit_package_gallery} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_delete_package_gallery" label="Delete Package Gallery"
                                            value={data.can_delete_package_gallery} onChange={handleFieldChange}/>

                            <div className="mt-3"/>

                            <CustomCheckBox name="can_view_package_option" label="View Package Option"
                                            value={data.can_view_package_option} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_add_package_option" label="Add Package Option"
                                            value={data.can_add_package_option}
                                            onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_edit_package_option" label="Update Package Option"
                                            value={data.can_edit_package_option} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_delete_package_option" label="Delete Package Option"
                                            value={data.can_delete_package_option} onChange={handleFieldChange}/>

                            <div className="mt-3"/>

                            <CustomCheckBox name="can_view_package_option_date" label="View Package Option Date"
                                            value={data.can_view_package_option_date} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_add_package_option_date" label="Add Package Option Date"
                                            value={data.can_add_package_option_date}
                                            onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_edit_package_option_date" label="Update Package Option Date"
                                            value={data.can_edit_package_option_date} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_delete_package_option_date" label="Delete Package Option Date"
                                            value={data.can_delete_package_option_date} onChange={handleFieldChange}/>

                            <div className="mt-3"/>

                            <CustomCheckBox name="can_view_package_option_date_time"
                                            label="View Package Option Date Time"
                                            value={data.can_view_package_option_date_time}
                                            onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_add_package_option_date_time" label="Add Package Option Date Time"
                                            value={data.can_add_package_option_date_time}
                                            onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_edit_package_option_date_time"
                                            label="Update Package Option Date Time"
                                            value={data.can_edit_package_option_date_time}
                                            onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_delete_package_option_date_time"
                                            label="Delete Package Option Date Time"
                                            value={data.can_delete_package_option_date_time}
                                            onChange={handleFieldChange}/>


                            <div className="mt-3"/>

                            <CustomCheckBox name="can_view_package_addon" label="View Package Add-on"
                                            value={data.can_view_package_addon} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_add_package_addon" label="Add Package Add-on"
                                            value={data.can_add_package_addon}
                                            onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_edit_package_addon" label="Update Package Add-on"
                                            value={data.can_edit_package_addon} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_delete_package_addon" label="Delete Package Add-on"
                                            value={data.can_delete_package_addon} onChange={handleFieldChange}/>


                            <div className="mt-3"/>

                            <CustomCheckBox name="can_view_package_installment" label="View Package Installment"
                                            value={data.can_view_package_installment} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_add_package_installment" label="Add Package Installment"
                                            value={data.can_add_package_installment}
                                            onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_edit_package_installment" label="Update Package Installment"
                                            value={data.can_edit_package_installment} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_delete_package_installment" label="Delete Package Installment"
                                            value={data.can_delete_package_installment} onChange={handleFieldChange}/>


                            <div className="mt-3"/>

                            <CustomCheckBox name="can_view_package_itinerary" label="View Package Itinerary"
                                            value={data.can_view_package_itinerary} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_add_package_itinerary" label="Add Package Itinerary"
                                            value={data.can_add_package_itinerary}
                                            onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_edit_package_itinerary" label="Update Package Itinerary"
                                            value={data.can_edit_package_itinerary} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_delete_package_itinerary" label="Delete Package Itinerary"
                                            value={data.can_delete_package_itinerary} onChange={handleFieldChange}/>



                            <div className="mt-3"/>

                            <CustomCheckBox name="can_view_package_photo_album" label="View Package Photo Album"
                                            value={data.can_view_package_photo_album} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_add_package_photo_album" label="Add Package Photo Album"
                                            value={data.can_add_package_photo_album}
                                            onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_edit_package_photo_album" label="Update Package Photo Album"
                                            value={data.can_edit_package_photo_album} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_delete_package_photo_album" label="Delete Package Photo Album"
                                            value={data.can_delete_package_photo_album} onChange={handleFieldChange}/>



                            <div className="mt-3"/>

                            <CustomCheckBox name="can_view_package_pending_change" label="View Package Pending Change"
                                            value={data.can_view_package_pending_change} onChange={handleFieldChange}/>
                            {/*<CustomCheckBox name="can_add_package_pending_change" label="Add Package Pending Change"*/}
                            {/*                value={data.can_add_package_pending_change}*/}
                            {/*                onChange={handleFieldChange}/>*/}

                            {data.rule_for !== "Agent" && (<CustomCheckBox name="can_edit_package_pending_change"
                                                                           label="Update Package Pending Change"
                                                                           value={data.can_edit_package_pending_change}
                                                                           onChange={handleFieldChange}/>)}

                            {/*<CustomCheckBox name="can_delete_package_pending_change" label="Delete Package Pending Change"*/}
                            {/*                value={data.can_delete_package_pending_change} onChange={handleFieldChange}/>*/}

                            {data.rule_for !== "Agent" && (<>
                                    <CustomCheckBox name="receive_email_for_new_travel_package_pending_change"
                                                    label="Receive Email For New Travel Package Pending Change"
                                                    value={data.receive_email_for_new_travel_package_pending_change}
                                                    onChange={handleFieldChange}/>

                                    <CustomCheckBox name="receive_email_for_new_experience_package_pending_change"
                                                    label="Receive Email For New Experience Package Pending Change"
                                                    value={data.receive_email_for_new_experience_package_pending_change}
                                                    onChange={handleFieldChange}/>
                                </>)}

                            <div className="mt-3"/>

                            <CustomCheckBox name="can_view_package_setting" label="View Package Setting"
                                            value={data.can_view_package_setting} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_edit_package_setting" label="Edit Package Setting"
                                            value={data.can_edit_package_setting}
                                            onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_update_package_he_commission" label="Update Package He Commission"
                                            value={data.can_update_package_he_commission}
                                            onChange={handleFieldChange}/>
                        </Panel>


                        {data.rule_for !== "Agent" && (<Panel header="Package Categories" key="14">
                                <CustomCheckBox name="can_view_package_category" label="View Package Category"
                                                value={data.can_view_package_category} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_add_package_category" label="Add Package Category"
                                                value={data.can_add_package_category}
                                                onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_edit_package_category" label="Update Package Category"
                                                value={data.can_edit_package_category} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_delete_package_category" label="Delete Package Category"
                                                value={data.can_delete_package_category} onChange={handleFieldChange}/>
                            </Panel>)}


                        {data.rule_for !== "Agent" && (<Panel header="Package inclusions" key="32">
                                <CustomCheckBox name="can_view_package_inclusions" label="View Package Inclusions"
                                                value={data.can_view_package_inclusions} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_add_package_inclusions" label="Add Package Inclusions"
                                                value={data.can_add_package_inclusions}
                                                onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_edit_package_inclusions" label="Update Package Inclusions"
                                                value={data.can_edit_package_inclusions} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_delete_package_inclusions" label="Delete Package Inclusions"
                                                value={data.can_delete_package_inclusions} onChange={handleFieldChange}/>
                            </Panel>)}


                        {data.rule_for !== "Agent" && (<Panel header="Product" key="25">
                                <CustomCheckBox name="can_view_product" label="View Product"
                                                value={data.can_view_product} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_add_product" label="Add Product"
                                                value={data.can_add_product} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_edit_product" label="Update Product"
                                                value={data.can_edit_product} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_delete_product" label="Delete Product"
                                                value={data.can_delete_product} onChange={handleFieldChange}/>

                                <div className="mt-3"/>

                                <CustomCheckBox name="can_view_product_gallery" label="View Product Gallery"
                                                value={data.can_view_product_gallery} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_add_product_gallery" label="Add Product Gallery"
                                                value={data.can_add_product_gallery} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_edit_product_gallery" label="Update Product Gallery"
                                                value={data.can_edit_product_gallery} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_delete_product_gallery" label="Delete Product Gallery"
                                                value={data.can_delete_product_gallery} onChange={handleFieldChange}/>

                            </Panel>)}


                        {data.rule_for !== "Agent" && (<Panel header="Products Sales" key="26">
                                <CustomCheckBox name="can_view_product_sale" label="View Product Sale"
                                                value={data.can_view_product_sale} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_add_product_sale" label="Add Product Sale"
                                                value={data.can_add_product_sale} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_edit_product_sale" label="Update Product Sale"
                                                value={data.can_edit_product_sale} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_delete_product_sale" label="Delete Product Sale"
                                                value={data.can_delete_product_sale} onChange={handleFieldChange}/>

                                <div className="mt-3"/>

                                <CustomCheckBox name="receive_email_for_new_product_sale"
                                                label="Receive Email For New Product Sale"
                                                value={data.receive_email_for_new_product_sale}
                                                onChange={handleFieldChange}/>
                                <CustomCheckBox name="receive_email_for_product_sale_payment"
                                                label="Receive Email For Product Sale Payment"
                                                value={data.receive_email_for_product_sale_payment}
                                                onChange={handleFieldChange}/>

                                <CustomCheckBox name="send_product_sale_payment_receipt_email"
                                                label="Send Product Sale Payment Receipt Email"
                                                value={data.send_product_sale_payment_receipt_email}
                                                onChange={handleFieldChange}/>
                                <CustomCheckBox name="send_product_sale_payment_request_email"
                                                label="Send Product Sale Payment Request Email"
                                                value={data.send_product_sale_payment_request_email}
                                                onChange={handleFieldChange}/>

                            </Panel>)}


                        {data.rule_for !== "Agent" && (<Panel header="Rules" key="4">
                                <CustomCheckBox name="can_view_rule" label="View Rule"
                                                value={data.can_view_rule} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_add_rule" label="Add Rule" value={data.can_add_rule}
                                                onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_edit_rule" label="Update Rule"
                                                value={data.can_edit_rule} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_delete_rule" label="Delete Rule"
                                                value={data.can_delete_rule} onChange={handleFieldChange}/>
                            </Panel>)}


                        {data.rule_for !== "Agent" && (<Panel header="Service Charges" key="13">
                                <CustomCheckBox name="can_view_service_charge" label="View Service Charge"
                                                value={data.can_view_service_charge} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_add_service_charge" label="Add Service Charge"
                                                value={data.can_add_service_charge}
                                                onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_edit_service_charge" label="Update Service Charge"
                                                value={data.can_edit_service_charge} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_delete_service_charge" label="Delete Service Charge"
                                                value={data.can_delete_service_charge} onChange={handleFieldChange}/>
                            </Panel>)}


                        {data.rule_for !== "Agent" && (<Panel header="Testimonial" key="30">
                                <CustomCheckBox name="can_view_testimonial" label="View Testimonial"
                                                value={data.can_view_testimonial} onChange={handleFieldChange}/>

                                <CustomCheckBox name="can_add_testimonial" label="Add Testimonial"
                                                value={data.can_add_testimonial}
                                                onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_edit_testimonial" label="Update Testimonial"
                                                value={data.can_edit_testimonial} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_delete_testimonial" label="Delete Testimonial"
                                                value={data.can_delete_testimonial} onChange={handleFieldChange}/>
                                <CustomCheckBox name="receive_email_for_new_testimonial"
                                                label="Receive Email For New Testimonial"
                                                value={data.receive_email_for_new_testimonial}
                                                onChange={handleFieldChange}/>
                            </Panel>)}


                        {data.rule_for !== "Agent" && (<Panel header="Tours" key="33">
                            <CustomCheckBox name="can_view_tour" label="View Tour"
                                            value={data.can_view_tour} onChange={handleFieldChange}/>

                            <CustomCheckBox name="can_add_tour" label="Add Tour"
                                            value={data.can_add_tour}
                                            onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_edit_tour" label="Update Tour"
                                            value={data.can_edit_tour} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_delete_tour" label="Delete Tour"
                                            value={data.can_delete_tour} onChange={handleFieldChange}/>
                        </Panel>)}

                        {data.rule_for !== "Agent" && (<Panel header="Tiers" key="17">
                                <CustomCheckBox name="can_view_tier" label="View Tier"
                                                value={data.can_view_tier} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_add_tier" label="Add Tier"
                                                value={data.can_add_tier}
                                                onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_edit_tier" label="Update Tier"
                                                value={data.can_edit_tier} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_delete_tier" label="Delete Tier"
                                                value={data.can_delete_tier} onChange={handleFieldChange}/>
                            </Panel>)}

                        <Panel header="Users" key="1">
                            <CustomCheckBox name="can_view_user" label="View User"
                                            value={data.can_view_user} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_add_user" label="Add User" value={data.can_add_user}
                                            onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_edit_user" label="Update User"
                                            value={data.can_edit_user} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_delete_user" label="Delete User"
                                            value={data.can_delete_user} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_change_user_status" label="Change User Status"
                                            value={data.can_change_user_status} onChange={handleFieldChange}/>
                        </Panel>


                        {data.rule_for !== "Agent" && (<Panel header="Voucher" key="24">
                                <CustomCheckBox name="can_view_voucher" label="View Voucher"
                                                value={data.can_view_voucher} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_add_voucher" label="Add Voucher"
                                                value={data.can_add_voucher} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_edit_voucher" label="Update Voucher"
                                                value={data.can_edit_voucher} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_delete_voucher" label="Delete Voucher"
                                                value={data.can_delete_voucher} onChange={handleFieldChange}/>
                                <CustomCheckBox name="receive_email_for_digital_gift_voucher_payment" label="Receive email for digital gift payment"
                                                value={data.receive_email_for_digital_gift_voucher_payment} onChange={handleFieldChange}/>


                                <div className="mt-3"/>

                                <CustomCheckBox name="can_view_customize_voucher" label="View Customize Voucher"
                                                value={data.can_view_customize_voucher} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_add_customize_voucher" label="Add Customize Voucher"
                                                value={data.can_add_customize_voucher} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_edit_customize_voucher" label="Update Customize Voucher"
                                                value={data.can_edit_customize_voucher} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_delete_customize_voucher" label="Delete Customize Voucher"
                                                value={data.can_delete_customize_voucher} onChange={handleFieldChange}/>


                            </Panel>)}


                    </Collapse>
                </Col>


                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>)
}
