import React, {Fragment} from "react";
import {Alert, Button, Col, Divider, Popconfirm, message} from "antd";
import {CustomTimeField} from "../../../../global/components/fields/CustomTimeField";
import {CustomTextField} from "../../../../global/components/fields/CustomTextField";
import CustomCheckBox from "../../../../global/components/fields/CustomCheckBox";
import Hide from "../../../../global/components/Hide";
import {isValidValue} from "../../../../global/helper_functions";
import CustomBadge from "../../../../global/components/CustomBadge";

export const PackageDateTimeRow = props =>{
    const {time_data, handleTimeFieldChange, helper_text, removeTime, save_loading, delete_loading, onSave, index} = props
    const is_new = (time_data.id || "#").startsWith("#")

    return(
        <Fragment key={time_data.id}>

            <Col xs={24} md={10}>
                <CustomTimeField
                    required
                    label="Time"
                    name="time"
                    value={time_data.time}
                    onChange={(name, value) => handleTimeFieldChange(name, value, index)}
                    error={helper_text[index].time !== undefined}
                    helper_text={helper_text[index].time}
                />
            </Col>


            <Col xs={16} md={10}>
                <CustomTextField
                    required
                    label="Time Qty"
                    name="capacity"
                    value={time_data.capacity}
                    onChange={(name, value) => handleTimeFieldChange(name, value, index)}
                    error={helper_text[index].capacity !== undefined}
                    helper_text={helper_text[index].capacity}
                    type="number"
                    tooltip="Capacity of time"
                />
            </Col>


            <Col xs={8} md={4} className="d-flex align-items-end justify-content-center">
                <CustomBadge
                    title={`Bookings: ${time_data.bookings_number || "0"}`}
                />
            </Col>

            <Col xs={24} md={8}>
                <CustomCheckBox
                    label="Is Active"
                    name="is_active"
                    value={time_data.is_active}
                    onChange={(name, value) => handleTimeFieldChange(name, value, index)}
                    tooltip="Hide time from the website"
                />
            </Col>

            <Col xs={24} md={8}>
                <CustomCheckBox
                    label="Is Unique"
                    name="is_unique"
                    value={time_data.is_unique}
                    onChange={(name, value) => handleTimeFieldChange(name, value, index)}
                    tooltip="disable all other similar times and date in package options from booking if this time is sold first"
                />
            </Col>

            <Col xs={24} md={8}>
                <CustomCheckBox
                    disabled={time_data.is_unique && time_data.is_unique_sold_out}
                    label="Is Sold Out"
                    name="is_sold_out"
                    value={time_data.is_sold_out}
                    onChange={(name, value) => handleTimeFieldChange(name, value, index)}
                    tooltip="Customer will can't book this date but still showing in website and mark it as sold out"
                />
            </Col>



            <Col xs={24} className="d-flex  align-items-end justify-content-end">
                    <Popconfirm
                        placement="bottomRight"
                        title={`Are you sure to ${!is_new ? "Delete" : "Remove"} this row ??`}
                        onConfirm={() => removeTime(time_data, index)}
                        okText="Yes"
                        cancelText="No"
                        disabled={delete_loading[time_data.id]}
                    >
                        <Button
                            loading={delete_loading[time_data.id]}
                            className="m-1" danger type="primary"
                                block={Hide({'hide_up_lg': true})}>
                            {!is_new ? "Delete" : "Remove"}
                        </Button>
                    </Popconfirm>

                    <Button
                        loading={save_loading[time_data.id]}
                        className="m-1" type="primary" onClick={() => onSave(time_data, index)}
                        block={Hide({'hide_up_lg': true})}>
                        {!is_new ? "Update" : "Create"}
                    </Button>
            </Col>


            <Hide hide={!isValidValue(helper_text[index].non_field_errors)}>
                <Col xs={24}>
                    <Alert
                        className="text-danger"
                        banner
                        description={helper_text[index].non_field_errors}
                        type="error"
                    />
                </Col>
            </Hide>

            <Divider className="m-0"/>
        </Fragment>
    )
}
