import React, {useEffect, useState} from "react";
import {Col, Row} from "antd";
import {CustomDynamicTable} from "../../../global/components/CustomDynamicTable";
import {PackagesSettingsUpdateSoldOutAndActivateStatusURL, PackagesURL} from "../../../global/urls";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {copyText, openNotification, showInfo, viewDateAndTime} from "../../../global/helper_functions";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import {AddButton} from "../../../global/components/AddButton";
import {IsActiveView} from "../../../global/components/IsActiveView";
import {isAgent} from "../../../global/auth_functions";
import {EyeOutlined} from "@ant-design/icons";
import CustomBadge from "../../../global/components/CustomBadge";
import CustomSelectField from "../../../global/components/fields/CustomSelectField";
import {
    privacy_type,
    package_status,
    package_type,
    interval_options,
    activation_option
} from "../../../global/variables";
import {AgencyAutoComplete} from "../../../global/components/fields/AgencyAutoComplete";
import Hide from "../../../global/components/Hide";
import CustomRadioField from "../../../global/components/fields/CustomRadioField";
import PackageRowMobileView from "./components/PackageRowMobileView";

export const Packages = props => {
    const {slug} = props;
    const is_agent = isAgent();
    const [reload_data, setReloadData] = useState(false);

    const [package_perm] = useState({
        delete: checkPermission(permission_data.package.delete),
        edit: checkPermission(permission_data.package.edit),
        add: checkPermission(permission_data.package.add),
    });
    const [filter, setFilter] = useState({
        interval: "All",
        is_active: "",
    });


    const export_columns = [
        {
            title: "Ref No",
            key: "ref_no",
        },

        {
            title: "Package Type",
            key: "package_type",
        },
        {
            title: "Agency",
            key: "agency_name",
        },
        {
            title: "Title",
            key: "title_en",
        },
        {
            title: "Bookings Number",
            key: "bookings_number",
        },

        {
            title: "Created at",
            key: "createdAt",
            format: value => viewDateAndTime(value),
        },
    ]

    const mobile_columns = [
        {
            dataIndex: "ref_no",
            width: "6%",
            render: (value, row_data) => <PackageRowMobileView {...props} reloadData={reloadData} data={row_data}/>
        },
    ]

    const [columns] = useState([
        {
            title: "Ref No.",
            dataIndex: "ref_no",
            width: "7%",
            render: (value, row_data) => <a href={`/${slug}/packages/${row_data.id}`} target="_blank">{value}</a>
        },
        {
            title: "Agency",
            dataIndex: "agency_name",
            width: "10%",
            hide: is_agent
        },
        {
            title: "Title EN",
            dataIndex: "title_en",
            width: "30%"
        },
        {
            title: "Package Type",
            dataIndex: "package_type",
            width: "15%",
            align: "center"
        },
        {
            title: "Bookings Number",
            dataIndex: "bookings_number",
            width: "5%",
            align: "center",
        },
        {
            title: "Availability",
            dataIndex: "is_sold_out",
            width: "5%",
            align: "center",
            render: (value) => <CustomBadge type={value?"danger":""} title={value?"Sold Out":"Available"} />

        },
        // {
        //     title: "Requires Approval",
        //     dataIndex: "requires_approval",
        //     width: "5%",
        //     align: "center",
        //     render: (value) => <IsActiveView active_label="Yes" inactive_label="No" value={value}
        //                                      view_only={!package_perm.edit}/>
        // },
        {
            title: "Activate",
            dataIndex: "is_active",
            width: "5%",
            align: "center",
            render: (value) => <IsActiveView value={value} view_only={!package_perm.edit}/>
        },

    ])

    const handleFilterChange = (name, value) =>{
        setFilter({
            ...filter,
            [name]: value
        })
        setReloadData(Math.random());
    }

    const onDelete = async (row_data) => {
        let response = await httpRequestWithAuth(`${PackagesURL}${row_data.id}`, "DELETE");
        if (response.status) {
            openNotification("success", "Delete Package", `${row_data.title_en} package has been deleted`)
        } else {
            openNotification("error", "Delete Package", response.error_feedback.non_field_errors)
        }
    }

    const onEdit = async (row_data) => {
        props.history.push(`/${slug}/packages/${row_data.id}`);
    }

    const onAdd = async () => {
        props.history.push(`/${slug}/packages/new`);
    }

    const changeSoldOutStatus = async (row_data) =>{
        const response = await httpRequestWithAuth(`${PackagesSettingsUpdateSoldOutAndActivateStatusURL}${row_data.id}`, "PATCH", {is_sold_out: !row_data.is_sold_out});
        if (response.status){
            reloadData();
            if (response.data.pending_change){
                showInfo("Update Sold Out Status", response.data.msg);
            }else{
                openNotification("success", "Update Sold Out Status", response.data.msg || `${row_data.title_en} package option has been updated`)
            }
        }else{
            openNotification("error", "Update Sold Out Status", response.error_feedback.non_field_errors)
        }
    }


    const changeActivateStatus = async (row_data) =>{
        const response = await httpRequestWithAuth(`${PackagesSettingsUpdateSoldOutAndActivateStatusURL}${row_data.id}`, "PATCH", {is_active: !row_data.is_active});
        if (response.status){
            reloadData();
            if (response.data.pending_change){
                showInfo("Update Activate Status", response.data.msg);
            }else{
                openNotification("success", "Update Activate Status", response.data.msg || `${row_data.title_en} package has been updated`)
            }
        }else{
            openNotification("error", "Update Activate Status", response.error_feedback.non_field_errors)
        }
    }
    const freeAction = (
        <>
            <Hide hide={!package_perm.add}>
                <AddButton className="m-1" onClick={onAdd}>Add Package</AddButton>
            </Hide>
        </>
    );

    const reloadData = () =>{
        setReloadData(Math.random());
    }

    const onCopy = (value) => {
        openNotification("success", "", `Testimonial Link has been copied`)
        copyText(value);
    }


    const extra_actions = [
        {
            key: "view-package-in-customer-website",
            icon: EyeOutlined,
            render: (row_data)=><a href={row_data.customer_website_url} target="_blank">View In Website</a>,
        },
        {
            key: "instagram-link",
            icon: EyeOutlined,
            onClick: (row_data)=>onCopy(`${row_data.customer_website_url}?customer_came_from=instagram`),
            hide: (row_data)=> is_agent,
            render: (row_data)=> <span>Copy Package Link For IG</span>,
        },
        {
            key: "testimonial-link",
            icon: EyeOutlined,
            onClick: (row_data)=>onCopy(row_data.testimonial_url),
            hide: (row_data)=> is_agent,
            render: (row_data)=> <span>Copy Testimonial Link</span>,
        },
        {
            key: "change-active-to-inactive",
            icon: EyeOutlined,
            onClick: changeActivateStatus,
            render: (row_data)=> <span className="text-danger">Deactivate Package</span>,
            hide: (row_data)=>!row_data.is_active,
            iconClassName: "text-danger"
        },
        {
            key: "change-inactive-to-active",
            icon: EyeOutlined,
            onClick: changeActivateStatus,
            render: (row_data)=> <span className="main-color">Activate Package</span>,
            hide: (row_data)=>row_data.is_active,
            iconClassName: "main-color"
        },
        {
            key: "change-available-to-sold-out",
            icon: EyeOutlined,
            onClick: changeSoldOutStatus,
            render: (row_data)=> <span className="text-danger">Mark as Sold Out</span>,
            hide: (row_data)=>row_data.is_sold_out,
            iconClassName: "text-danger"
        },
        {
            key: "change-sold-out-to-available",
            icon: EyeOutlined,
            onClick: changeSoldOutStatus,
            render: (row_data)=> <span className="main-color">Mark as Available</span>,
            hide: (row_data)=>!row_data.is_sold_out,
            iconClassName: "main-color"
        },

    ]


    const filter_toolbar = (
        <Row gutter={[10,10]}>

            <Hide hide={is_agent}>
                <Col xs={24} md={12}>
                    <AgencyAutoComplete
                        label="Agency"
                        name="AgencyId"
                        value={filter.AgencyId}
                        onChange={handleFilterChange}
                    />
                </Col>
            </Hide>

            <Col xs={24} md={is_agent?8:4}>
                <CustomSelectField
                    empty_title="All"
                    label="Package Type"
                    name="package_type"
                    value={filter.package_type}
                    onChange={handleFilterChange}
                    options={package_type}
                />
            </Col>


            <Col xs={24} md={is_agent?8:4}>
                <CustomSelectField
                    empty_title="All"
                    label="Status"
                    name="status"
                    value={filter.status}
                    onChange={handleFilterChange}
                    options={package_status}
                />
            </Col>


            <Col xs={24} md={is_agent?8:4}>
                <CustomSelectField
                    empty_title="All"
                    label="Privacy"
                    name="is_private"
                    value={filter.is_private}
                    onChange={handleFilterChange}
                    options={privacy_type}
                />
            </Col>


            <Col xs={24} md={12} className="mb-0">
                <CustomRadioField
                    required
                    name="interval"
                    value={filter.interval}
                    onChange={handleFilterChange}
                    options={interval_options}
                />
            </Col>

            <Col xs={24} md={12} className="mb-0">
                <CustomRadioField
                    required
                    name="is_active"
                    value={filter.is_active}
                    onChange={handleFilterChange}
                    options={activation_option}
                />
            </Col>

        </Row>
    );

    return (
        <Row>
            <Col xs={24} className="paper">
                <CustomDynamicTable
                    title="Packages"
                    columns={columns}
                    mobile_columns={mobile_columns}
                    fetch_url={PackagesURL}
                    free_action={freeAction}
                    onDelete={package_perm.delete && onDelete}
                    onEdit={package_perm.edit && onEdit}
                    extra_actions={extra_actions}
                    filter_toolbar={filter_toolbar}
                    reload_data={reload_data}
                    custom_params={filter}
                    allow_export
                    export_columns={export_columns}
                />
            </Col>
        </Row>
    )
}
