import DynamicAutocomplete from "./DynamicAutocomplete";
import {PackagesInclusionsListAllURL, RulesListAllURL} from "../../urls";

export const PackageInclusionsAutoComplete = (props)=>{
    const language = props.language || "en";
    const exclude = props.exclude || "";
    const params = {exclude};

    return(
        <DynamicAutocomplete
            {...props}
            fetch_url={PackagesInclusionsListAllURL}
            title_key={`name_${language}`}
            mode={props.multiple?"multiple":""}
            params_array={[exclude]}
            params={params}
        />
    )
}

export default PackageInclusionsAutoComplete;
