import React, {useEffect, useState} from "react";
import {httpRequestWithAuth} from "../../global/axios_instance";
import {isValidValue, openNotification, showConfirm, showError} from "../../global/helper_functions";
import {Col, Row} from "antd";
import {ActionButton} from "../../global/components/ActionButton";
import {CustomTextField} from "../../global/components/fields/CustomTextField";
import {permission_data} from "../../global/permissions_data";
import {checkPermission} from "../../global/permissions";
import {CustomTextAreaField} from "../../global/components/fields/CustomTextAreaField";
import {HELoading} from "../../global/components/HELoading";
import Hide from "../../global/components/Hide";
import {CustomPhoneNumberField} from "../../global/components/fields/CustomPhoneNumberField";
import {CustomDateField} from "../../global/components/fields/CustomDateField";
import moment from "moment";
import CustomSelectField from "../../global/components/fields/CustomSelectField";
import {isSuperAdmin} from "../../global/auth_functions";
import ViewActionsDetails from "../../global/components/ViewActionsDetails";
import {ConsultationRequestsURL} from "../../global/urls";
import {consultation_type_options} from "../../global/variables";


const status_options = [
    {title: "New", value: "New"},
    {title: "Completed", value: "Completed", className: "text-success fw-bold"},
    {title: "Cancelled", value: "Cancelled", className: "text-danger fw-bold"},
]

const budget_options = [
    {title: "Ultra Luxury", value: "Ultra Luxury"},
    {title: "Luxury", value: "Luxury"},
    {title: "Mid-range", value: "Mid-range"},
    {title: "Budget friendly", value: "Budget friendly"},
];

const audience_type_options = [
    {title: "General", value: "General"},
    {title: "Boubyan Premium", value: "Boubyan Premium"},
    {title: "Audit Bureau", value: "Audit Bureau"},
];




export const ConsultationDetails = props => {
    const is_new = props.is_new;
    const consultation_id = props.consultation_id || "";
    const slug = props.slug || "";

    const [has_perm, setHasPerm] = useState(checkPermission(permission_data.consultation_response.edit) || (consultation_id === "new" && checkPermission(permission_data.consultation_request.add)));
    const [data, setData] = useState({
        consultation_date: moment().format("YYYY-MM-DD"),
        net_total: 0,
        currency: "KWD",
        status: "Pending",
        is_paid: false
    });

    const [loading, setLoading] = useState(true);
    const [helper_text, setHelperText] = useState({});


    const fetchData = async () => {
        let response = await httpRequestWithAuth(`${ConsultationRequestsURL}${consultation_id}`, "GET");
        if (response.status) {
            setData(response.data);
            props.setConsultationData(response.data);

            if (response.data.is_paid){
                setHasPerm(has_perm &&  isSuperAdmin());
            }

        } else {
            showError("Get Consultation Data", response.error_feedback.non_field_errors);
            onCancel();
        }
        setLoading(false);
    }


    const handleFieldChange = (name, value) => {
        let temp_helper_text = {...helper_text};
        let temp_data = {...data};
        temp_data[name] = value;

        delete temp_helper_text[name];
        setHelperText(temp_helper_text);

        setData(temp_data);
    }


    useEffect(() => {
        if (!is_new) {
            fetchData();
        } else {
            setLoading(false);
        }

    }, [])


    const onCancel = () => {
        props.history.push(`/${slug}/consultations`);
    }


    const onDelete = async () => {
        await setLoading(true);
        let response = await httpRequestWithAuth(`${ConsultationRequestsURL}${data.id}`, "DELETE");
        if (response.status) {
            openNotification("success", "Delete Consultation", `${data.title_en} consultation has been deleted`)
            onCancel();
        } else {
            openNotification("error", "Delete Consultation", response.error_feedback.non_field_errors)
        }
        await setLoading(false);
    }


    const handleDelete = () => {
        showConfirm("Delete Consultation", `Are you sure to delete ${data.ref_no} consultation ?`, onDelete);
    }


    const onSave = async () => {
        await setLoading(true);
        setHelperText({});
        let temp_data = {
            ...data
        }


        let response;
        if (is_new) {
            response = await httpRequestWithAuth(ConsultationRequestsURL, "POST", temp_data);
        } else {
            response = await httpRequestWithAuth(`${ConsultationRequestsURL}${data.id}`, "PUT", temp_data);
        }

        if (response.status) {
            props.setConsultationData(response.data);
            if (is_new) {
                openNotification("success", "Add Consultation", `${response.data.ref_no} consultation has been added`);
            } else {
                openNotification("success", "Edit Consultation", response.data.msg || `${response.data.ref_no} consultation has been updated`);
            }


            let to_tab = 2;


            if (to_tab > 0) {
                if (is_new) {
                    props.history.push(`/${slug}/consultations/${response.data.id}/?tab=${to_tab}`);
                } else {
                    // props.handleTabsChange(to_tab);
                    onCancel();
                }
            } else {
                onCancel();
            }


        } else {
            // openNotification("error", is_new?"Add Consultation":"Edit Consultation", response.error_feedback.non_field_errors)
            setHelperText(response.error_feedback)
        }

        await setLoading(false);
    }





    return (
        <div className="paper pt-0 w-100">


            <Hide hide={!loading}>
                <HELoading/>
            </Hide>

            <Hide hide={loading}>
                <Row gutter={[12, 10]}>

                    <Col xs={24}>
                        <h2 className="font-size-22 mb-0">
                            Details
                        </h2>
                    </Col>

                    <Col xs={24} md={8}>
                        <CustomTextField
                            label="Customer Name"
                            name="name"
                            value={data.name}
                            onChange={handleFieldChange}
                            helper_text={helper_text.name}
                            error={helper_text.name !== undefined}
                        />
                    </Col>


                    <Col xs={24} md={8}>
                        <CustomTextField
                            label="Customer Email"
                            name="email"
                            value={data.email}
                            onChange={handleFieldChange}
                            helper_text={helper_text.email}
                            error={helper_text.email !== undefined}
                        />
                    </Col>


                    <Col xs={24} md={8}>
                        <CustomPhoneNumberField
                            label="Contact Number"
                            name="contact_number"
                            value={data.contact_number}
                            onChange={handleFieldChange}
                            helper_text={helper_text.contact_number}
                            error={helper_text.contact_number !== undefined}
                        />
                    </Col>

                    <Col xs={24} className="mt-3"/>

                    <Col xs={24} md={16}>
                        <CustomTextField
                            label="Subject"
                            name="subject"
                            value={data.subject}
                            onChange={handleFieldChange}
                            helper_text={helper_text.subject}
                            error={helper_text.subject !== undefined}
                        />
                    </Col>

                    <Col xs={24} md={8}>
                        <CustomSelectField
                            label="Consultation Type"
                            name="consultation_type"
                            value={data.consultation_type}
                            onChange={handleFieldChange}
                            helper_text={helper_text.consultation_type}
                            error={helper_text.consultation_type !== undefined}
                            options={consultation_type_options}
                        />
                    </Col>



                    <Col xs={24}>
                        <CustomTextAreaField
                            label="Message"
                            name="message"
                            value={data.message}
                            onChange={handleFieldChange}
                            helper_text={helper_text.message}
                            error={helper_text.message !== undefined}
                        />
                    </Col>


                    <Col xs={24} md={12}>
                        <CustomTextField
                            label="Destination(s)"
                            name="destinations"
                            value={data.destinations}
                            onChange={handleFieldChange}
                            helper_text={helper_text.destinations}
                            error={helper_text.destinations !== undefined}
                        />
                    </Col>

                    <Col xs={24} md={12}>
                        <CustomTextField
                            label="Trip type"
                            name="trip_type"
                            value={data.trip_type}
                            onChange={handleFieldChange}
                            helper_text={helper_text.trip_type}
                            error={helper_text.trip_type !== undefined}
                        />
                    </Col>

                    <Col xs={24} md={6}>
                        <CustomTextField
                            label="Number Of Travellers"
                            name="number_of_travellers"
                            value={data.number_of_travellers}
                            onChange={handleFieldChange}
                            helper_text={helper_text.number_of_travellers}
                            error={helper_text.number_of_travellers !== undefined}
                            type="number"
                        />
                    </Col>

                    <Col xs={24} md={6}>
                        <CustomTextField
                            label="Number Of Days"
                            name="number_of_days"
                            value={data.number_of_days}
                            onChange={handleFieldChange}
                            helper_text={helper_text.number_of_days}
                            error={helper_text.number_of_days !== undefined}
                            type="number"
                        />
                    </Col>


                    <Col xs={24} md={6}>
                        <CustomDateField
                            label="Expected Travel Date"
                            name="expected_travel_date"
                            value={data.expected_travel_date}
                            onChange={handleFieldChange}
                            helper_text={helper_text.expected_travel_date}
                            error={helper_text.expected_travel_date !== undefined}
                        />
                    </Col>


                    <Col xs={24} md={6}>
                        <CustomSelectField
                            empty_title="Not specified"
                            label="Budget"
                            name="budget"
                            value={data.budget}
                            onChange={handleFieldChange}
                            helper_text={helper_text.budget}
                            error={helper_text.budget !== undefined}
                            options={budget_options}
                        />
                    </Col>

                    <Col xs={24} md={12}>
                        <CustomTextField
                            label="Contact Time"
                            name="best_contact_time"
                            value={data.best_contact_time}
                            onChange={handleFieldChange}
                            helper_text={helper_text.best_contact_time}
                            error={helper_text.best_contact_time !== undefined}
                        />
                    </Col>

                    <Col xs={24} md={12}>
                        <CustomTextField
                            label="Preferred consultation method"
                            name="preferred_consultation_method"
                            value={data.preferred_consultation_method}
                            onChange={handleFieldChange}
                            helper_text={helper_text.preferred_consultation_method}
                            error={helper_text.preferred_consultation_method !== undefined}
                        />
                    </Col>

                    <Col xs={24} md={6}>
                        <CustomSelectField
                            disabled={data.id}
                            label="Audience Type"
                            name="audience_type"
                            value={data.audience_type}
                            onChange={handleFieldChange}
                            helper_text={helper_text.audience_type}
                            error={helper_text.audience_type !== undefined}
                            options={audience_type_options}
                        />
                    </Col>

                    <Col xs={24} md={6}>
                        <CustomSelectField
                            label="Status"
                            name="status"
                            value={data.status}
                            onChange={handleFieldChange}
                            helper_text={helper_text.status}
                            error={helper_text.status !== undefined}
                            options={status_options}
                        />
                    </Col>

                    <Hide hide={is_new}>

                    <Col xs={24} className="mt-3"/>

                        <ViewActionsDetails data={data}/>
                    </Hide>

                    <Col xs={24}>
                        <ActionButton
                            error_msg={helper_text.non_field_errors}
                            onDelete={handleDelete}
                            onCancel={onCancel}
                            allow_delete={isValidValue(data.id) && checkPermission(permission_data.consultation_request.delete)}
                            allow_save={has_perm}
                            onSave={onSave}
                        />
                    </Col>

                </Row>
            </Hide>
        </div>
    )
}
