import CustomModal from "../../../../global/components/CustomModal";
import {Button, Col, Empty, Pagination, Row} from "antd";
import {useEffect, useState} from "react";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {PackagesListPackagesURL} from "../../../../global/urls";
import Hide from "../../../../global/components/Hide";
import {CustomSearchField} from "../../../../global/components/fields/CustomSearchField";
import {CountryAutoComplete} from "../../../../global/components/fields/CountryAutoComplete";
import {PackageCategoryAutoComplete} from "../../../../global/components/fields/PackageCategoryAutoComplete";
import {CustomTextField} from "../../../../global/components/fields/CustomTextField";
import CustomRadioField from "../../../../global/components/fields/CustomRadioField";
import {getUserAgencyId, isAgent} from "../../../../global/auth_functions";
import {Loading} from "../../../../global/components/Loading";
import {ModelActionButton} from "../../../../global/components/ModelActionButton";
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import {PackageViewer} from "./PackageViewer";
import {interval_options} from "../../../../global/variables";

export const PackageOptionSearchModal = props => {
    const addNewItem = props.addNewItem;

    const [is_agent] = useState(isAgent());
    const [user_agency_id] = useState(getUserAgencyId());
    const title = props.title || "Packages Search";
    const [filter, setFilter] = useState({interval: "All", ...(props.defualt_filter || {})});
    const [show_advance_filter, setShowAdvanceFilter] = useState(false);
    const [data, setData] = useState([]);
    const [pagination_data, setPaginationData] = useState({
        item_per_page: 3,
        total: 0,
        current_page: 1,
    });
    const [reload, reloadData] = useState(false);
    const [loading, setLoading] = useState(true);
    const [view_option_for, setViewOptionFor] = useState("");

    const fetchData = async (rest = false) => {
        setLoading(true);
        const params = {
            ...filter,
            current_page: rest ? 1 : pagination_data.current_page || 1,
            item_per_page: pagination_data.item_per_page || 3,
        }

        let response = await httpRequestWithAuth(PackagesListPackagesURL, "GET", null, params)
        if (response.status) {
            setPaginationData(response.data || {})
            setData(response.data.data || [])
        }

        setLoading(false);
    }

    const reloadPackageData = () => {
        reloadData(Math.random());
    }

    const handleFilterChange = (name, value) => {
        setFilter({...filter, [name]: value});
    }

    const onPaginationChange = (current_page) => {
        setPaginationData({...pagination_data, current_page})
        reloadPackageData()
    }


    const onSearch = () => {
        fetchData(true);
    }
    useEffect(() => {
        fetchData();
    }, [reload])


    return (
        <CustomModal size="lg" title={title} visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>


                <Col xs={24}>
                    <CustomSearchField
                        onSearch={() => reloadData(!reload)}
                        onChange={handleFilterChange}
                        name="search"
                        value={filter.search}
                    />
                </Col>

                <Hide hide={!show_advance_filter}>

                    <Col xs={24} md={12}>
                        <CountryAutoComplete
                            disabled={filter.package_type !== "Travel"}
                            for_agency_experience={filter.package_type !== "Travel" ? is_agent ? user_agency_id : "" : ""}
                            placeholder="Country"
                            value={filter.countries}
                            onChange={handleFilterChange}
                            name="countries"
                        />
                    </Col>


                    <Col xs={24} md={12}>
                        <PackageCategoryAutoComplete
                            placeholder="Category"
                            value={filter.categories}
                            onChange={handleFilterChange}
                            name="categories"
                        />
                    </Col>

                    <Col xs={24} md={12}>
                        <CustomTextField
                            placeholder="Min Price"
                            value={filter.min_price}
                            onChange={handleFilterChange}
                            name="min_price"
                            type="number"
                        />
                    </Col>

                    <Col xs={24} md={12}>
                        <CustomTextField
                            placeholder="Max Price"
                            value={filter.max_price}
                            onChange={handleFilterChange}
                            name="max_price"
                            type="number"
                        />
                    </Col>


                </Hide>

                <Col xs={24} className="mb-0">
                    <CustomRadioField
                        required
                        name="interval"
                        value={filter.interval}
                        onChange={handleFilterChange}
                        options={interval_options}
                    />
                </Col>


                <Col xs={24} className="text-end mt-0">
                    <Button type="primary" onClick={onSearch}>Search</Button>
                </Col>


                <Col xs={24} className="text-end mt-0">
                    <Button className="font-size-14 fw-bolder" size="small" type="link"
                            onClick={() => setShowAdvanceFilter(!show_advance_filter)}>
                        {show_advance_filter ? "Hide advance filters" : "Show advance filters"}
                        {show_advance_filter ? <UpOutlined/> : <DownOutlined/>}
                    </Button>
                </Col>


                <Hide hide={!loading}>
                    <Loading/>
                </Hide>


                <Hide hide={loading}>
                    <Hide hide={loading || data.length === 0}>
                        <Col xs={24}>
                            <Row gutter={[12, 12]} className="h-auto w-100 d-flex justify-content-center">
                                {data.map((package_data) => {
                                    return <PackageViewer package_data={package_data}
                                                          interval={filter.interval}
                                                          view_option={view_option_for === package_data.id}
                                                          setViewOptionFor={setViewOptionFor}
                                                          addNewItem={addNewItem}
                                    />
                                })}
                            </Row>
                        </Col>


                        <Col xs={24} className="d-flex justify-content-center w-100 mt-3">
                            <Pagination onChange={onPaginationChange}
                                        current={pagination_data.current_page}
                                        total={pagination_data.total}
                                        pageSize={pagination_data.item_per_page}
                            />
                        </Col>
                    </Hide>

                </Hide>

                <Hide hide={loading || data.length !== 0}>
                    <Col xs={24} className="pt-5 pb-5">
                        <Empty/>
                    </Col>
                </Hide>

                <Col xs={24} className="p-0">
                    <ModelActionButton
                        loading={loading}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}
