import React, {useState} from "react";
import {isValidValue, showError} from "../helper_functions";
import Hide from "./Hide";
import {Button} from "react-bootstrap";
import UserIcon from "../../assets/icon/user_icon.png";
import {httpRequestWithAuth} from "../axios_instance";
import {UploadFileToServerURL} from "../urls";
import {Spin} from "antd";

const CustomFileUpload = (props) => {
    const upload_to_server = props.upload_to_server;
    const [uploading, setUploading] = useState(false);
    const [upload_error, setUploadError] = useState("");

    const avatar_shape = props.avatar_shape || false;

    const maxWidth = props.maxWidth || 100;

    const handleChange = (e) => {
        let file;

        if (isValidValue(e)) {
            file = e.target.files[0];

            if(props.max_size_3_mb && file.size > 3145728){
                showError("File is too big", "Please select a file whose size is less or equal than 3 MB");

                file = "";
            }
        }

        props.onChange(props.name, isValidValue(file) ? file : "");
    };


    const onChange = async (e) => {
        setUploadError("");

        if (!props.view_only && !props.disabled) {

            if (!upload_to_server) {
                handleChange(e)
            } else {

                let new_value = "";
                if (isValidValue(e)) {
                    new_value = e.target.files[0]
                }

                setUploading(true);

                const form_data = new FormData();
                form_data.append('file', new_value);
                let response = await httpRequestWithAuth(UploadFileToServerURL, "POST", form_data);
                if (response.status) {
                    props.onChange(props.name, {file: (new_value || {}), ...response.data});
                } else {
                    setUploadError("uploading file failed")
                }
                setUploading(false);
            }
        }
    }

    return (

        <>
            <div className="w-100 text-center">
                <div className="w-100 text-center d-flex justify-content-center">

                    <Hide hide={isValidValue(props.value)}>
                        {/*<Upload  onChange={handleChange} onDrop={handleChange} id={props.name} showUploadList={false}>*/}
                        {/*    <Button type="primary" icon={<UploadOutlined />}>*/}
                        {/*        {props.label}*/}
                        {/*    </Button>*/}
                        {/*</Upload>*/}
                        <>
                            <input
                                className="d-none"
                                type="file"
                                id={props.name}
                                onChange={onChange}
                                accept={props.accept}
                            />

                            <Hide hide={!uploading}>
                                <div className={`h-100 w-100 bg-light ${avatar_shape ? "p-0" : "p-2"}`}>
                                <Spin/>
                                </div>

                            </Hide>
                            <Hide hide={uploading}>

                            <Button
                                as="label"
                                id={props.name}
                                htmlFor={props.name}
                                className={`h-100 w-100 custom-button-bootstrap ${avatar_shape ? "p-0" : "p-2"}`}
                                style={{maxWidth}}
                                block
                            >

                                    <Hide hide={!avatar_shape}>
                                        <img shape="square" src={UserIcon} className="w-100 h-100 bg-white"/>
                                    </Hide>

                                    <Hide hide={avatar_shape}>
                                        <span className="fw-bold w-100 h-100">{props.label}</span>
                                    </Hide>

                            </Button>
                            </Hide>

                        </>
                    </Hide>

                    <Hide hide={!isValidValue(props.value) || props.hide_remove}>
                        <div className="d-block">
                            <p className="m-0">the file has been uploaded</p>
                            <p className="m-0">{(props.value || {}).name}</p>
                            <Button className="mt-2" variant="danger" onClick={() => handleChange(null)}>
                                Remove
                            </Button>
                        </div>
                    </Hide>

                </div>
                <span className={props.error && "text-danger"}>{props.helper_text || upload_error}</span>

            </div>

        </>
    );
};

export default CustomFileUpload;

