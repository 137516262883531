import React, {useState} from "react";
import {Button, Col, Row} from "antd";
import {CustomDynamicTable} from "../../../global/components/CustomDynamicTable";
import {ProductsSalesURL} from "../../../global/urls";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {isValidValue, openNotification} from "../../../global/helper_functions";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import {AddButton} from "../../../global/components/AddButton";
import {IsActiveView} from "../../../global/components/IsActiveView";
import Hide from "../../../global/components/Hide";
import CustomSelectField from "../../../global/components/fields/CustomSelectField";
import {booking_status, interval_options, package_type} from "../../../global/variables";
import {CustomDateField} from "../../../global/components/fields/CustomDateField";
import {PackageAutoComplete} from "../../../global/components/fields/PackageAutoComplete";
import {AgencyAutoComplete} from "../../../global/components/fields/AgencyAutoComplete";
import CustomRadioField from "../../../global/components/fields/CustomRadioField";
import CustomCheckBox from "../../../global/components/fields/CustomCheckBox";

export const ProductsSales = props => {
    const {slug, voucher_id, tab_view} = props;
    const [product_sale_perm] = useState({
        delete: checkPermission(permission_data.product_sale.delete),
        edit: checkPermission(permission_data.product_sale.edit),
        add: checkPermission(permission_data.product_sale.add),
    });
    const [reload, setReload] = useState(false);
    const [filter, setFilter] = useState({
        voucher_id: voucher_id || "",
    });

    const [columns] = useState([
        {
            title: "Ref No.",
            dataIndex: "ref_no",
            width: "7%",
            render: (value, row_data) => <a href={`/${slug}/products-sales/${row_data.id}`} target="_blank">{value}</a>
        },
        {
            title: "Type",
            dataIndex: "product_type",
            width: "10%"
        },
        {
            title: "Customer",
            dataIndex: "customer_name",
            width: "15%",
        },
        {
            title: "Customer Email",
            dataIndex: "customer_email",
            width: "20%",
        },,
        {
            title: "Customer Contact",
            dataIndex: "customer_contact_number_view",
            width: "15%",
        },
        {
            title: "Net Total",
            dataIndex: "net_total",
            width: "10%",
            align: "center",
            render: (value, row_data)=> `${value} ${row_data.currency}`
        },
        {
            title: "Payment Status",
            dataIndex: "is_paid",
            width: "5%",
            align: "center",
            render: (value) => <IsActiveView value={value} active_label="Paid" inactive_label="Unpaid" view_only={!product_sale_perm.edit}/>
        },
        {
            title: "Purchase Date",
            dataIndex: "created_at_view",
            width: "15%",
        },
        {
            title: "Actions",
            width: "10%",
            align: "center",
            render: (value, row_data) => (
                <>
                    <Button className="m-1" type="primary" onClick={() => onView(row_data)} block>
                        View
                    </Button>
                </>
            )},
    ])

    const reloadData = () =>{
        setReload(Math.random());
    }

    const handleFilterChange = (name, value) => {
        setFilter({
            ...filter,
            [name]: value
        })
        reloadData();
    }

    const filter_toolbar = (
        <Row gutter={[10, 10]}>
            <Col xs={24} md={24}
                 className="mb-0 d-flex align-items-center justify-content-center justify-content-md-end">
                <CustomCheckBox label="Paid" name="is_paid" value={filter.is_paid} onChange={handleFilterChange}/>
                <CustomCheckBox label="Unpaid" name="is_unpaid" value={filter.is_unpaid} onChange={handleFilterChange}
                                className="ms-3"/>
            </Col>

        </Row>
    );




    const onDelete = async (row_data) => {
        let response = await httpRequestWithAuth(`${ProductsSalesURL}${row_data.id}`, "DELETE");
        if (response.status) {
            openNotification("success", "Delete Product Sale", `${row_data.title_en} product sale has been deleted`)
        } else {
            openNotification("error", "Delete Product Sale", response.error_feedback.non_field_errors)
        }
    }

    const onView = async (row_data) => {
        props.history.push(`/${slug}/products-sales/${row_data.id}`);
    }

    const onAdd = async () => {
        props.history.push(`/${slug}/products-sales/new`);
    }

    const freeAction = (
        <>
            <AddButton onClick={onAdd}>Add Product Sale</AddButton>
        </>
    );

    return (
        <Row>
            <Col xs={24} className={`${tab_view ? "pt-0" : "paper"}`}>
                <CustomDynamicTable
                    title="Products Sales"
                    columns={columns}
                    fetch_url={ProductsSalesURL}
                    custom_params={filter}
                    filter_toolbar={filter_toolbar}
                    reload_data={reload}
                    // free_action={freeAction}
                    // onDelete={product_sale_perm.delete && onDelete}
                    // onEdit={product_sale_perm.edit && onEdit}
                />
            </Col>
        </Row>
    )
}
