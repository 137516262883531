import React from "react";
import {
    DANGER_COLOR_2,
    DISABLED_COLOR,
    PRIMARY_COLOR,
    PRIMARY_COLOR_TWO,
} from "../variables";
import Hide from "./Hide";
import {IconButton} from "rsuite";
import {CloseOutlined} from "@ant-design/icons";

const CustomBadge = (props) => {
    return (
        <span
            onClick={props.onClick}
            style={{
                backgroundColor: props.type === "danger"?DANGER_COLOR_2:props.type === "disabled"? DISABLED_COLOR: props.type === "primary"?PRIMARY_COLOR_TWO: props.bg_color || PRIMARY_COLOR,
                color: props.type === "disabled"?"black":props.color || "white",
                fontWeight: "bold",
            }}
            className={`badge rounded-pill p-0 p-md-${props.padding || 1} font-size-${props.font_size || 12}  ${props.className}`}
        >
            <div className="d-flex align-items-center p-1">
      {props.title}

            <Hide hide={!props.onClose}>
        <span className="ms-2">
            <IconButton className="p-0 m-0 vertical-align-0" onClick={props.onClose}>
                <CloseOutlined className="p-0 m-0 vertical-align-0 "/>
                {/*<CloseCircleOutlined className="p-0 m-0 vertical-align-0"/>*/}
            </IconButton>

        </span>
        </Hide>
            </div>

    </span>
    );
};

export default CustomBadge;
