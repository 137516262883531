import {Button, Dropdown, Menu} from "antd";
import React, {useEffect, useState} from "react";
import {DownOutlined} from "@ant-design/icons";
import Hide from "./Hide";

const CustomDropDown = (props) => {
    const [options, setOptions] = useState([]);

    useEffect(()=>{
        const temp_options = [];
        (props.options || []).map(option=>{
            temp_options.push({
                ...option,
                label: <label className="font-size-14">{option.label}</label>
            })
        })

        setOptions(temp_options)
    },[props.options])

    const menu = <Menu onClick={props.onChange} items={options} className="font-size-22 w-100"/>;

    return (
        <Dropdown
            disabled={props.disabled}
            trigger={props.trigger || ["click"]}
            overlay={menu}
            className={props.className}
        >
                <Button type={props.btn_type || "text"}>
                    {props.label}
                    <Hide hide={props.hide_icon}>
                    <DownOutlined className="ms-2"/>
                    </Hide>

                </Button>
        </Dropdown>
    );
};

export default CustomDropDown;
