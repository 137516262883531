import React, {useEffect, useState} from "react";
import {httpRequestWithAuth} from "../../global/axios_instance";
import {AgenciesRegistrationAllURL, AgenciesURL} from "../../global/urls";
import {isValidValue, openNotification, showConfirm, showError} from "../../global/helper_functions";
import {Col, Divider, Row} from "antd";
import {ActionButton} from "../../global/components/ActionButton";
import {CustomTextField} from "../../global/components/fields/CustomTextField";
import {permission_data} from "../../global/permissions_data";
import {checkPermission} from "../../global/permissions";
import {HELoading} from "../../global/components/HELoading";
import Hide from "../../global/components/Hide";
import CustomCheckBox from "../../global/components/fields/CustomCheckBox";
import {CustomPhoneNumberField} from "../../global/components/fields/CustomPhoneNumberField";
import CustomSelectField from "../../global/components/fields/CustomSelectField";
import {title_options} from "../../global/variables";
import ViewActionsDetails from "../../global/components/ViewActionsDetails";
import {ImageUploaderWithView} from "../../global/components/ImageUploaderWithView";
import {CustomSlugField} from "../../global/components/fields/CustomSlugField";
import {CustomUploadField} from "../../global/components/fields/CustomUploadField";
import {CountryAutoComplete} from "../../global/components/fields/CountryAutoComplete";

export const AgencyDetails = props => {
    const is_new = props.is_new;
    const agency_id = props.agency_id || "";
    const slug = props.slug || "";

    const lang = props.language || "en";
    const [has_perm] = useState(checkPermission(permission_data.agency.edit) || (agency_id === "new" && checkPermission(permission_data.agency.add)));
    const [user_data, setUserData] = useState({});
    const [data, setData] = useState({
        is_active: true,
        has_travels: false,
        has_experiences: false,
        ...props.data
    });

    const [loading, setLoading] = useState(true);
    const [helper_text, setHelperText] = useState({});

    const fetchData = async () => {
        let response = await httpRequestWithAuth(`${AgenciesURL}${agency_id}`, "GET");
        if (response.status) {
            setData(response.data);
            props.setAgencyData(response.data);

        } else {
            showError("Get Agency Data", response.error_feedback.non_field_errors);
            onCancel();
        }
        setLoading(false);
    }



    useEffect(() => {
        if (!is_new) {
            fetchData();
        } else {
            setLoading(false);
        }

    }, [])


    const onCancel = () => {
        props.history.push(`/${slug}/agencies`);
    }


    const onDelete = async () => {
        await setLoading(true);
        let response = await httpRequestWithAuth(`${AgenciesURL}${data.id}`, "DELETE");
        if (response.status) {
            openNotification("success", "Delete Agency", `${data.title_en} agency has been deleted`)
            onCancel();
        } else {
            openNotification("error", "Delete Agency", response.error_feedback.non_field_errors)
        }
        await setLoading(false);
    }


    const handleDelete = () => {
        showConfirm("Delete Agency", `Are you sure to delete ${data.title_en} agency ?`, onDelete);
    }

    const handleFieldChange = (name, value) => {
        let temp_data = {...data};
        temp_data[name] = value;


        if (name === "logo") {
            temp_data[`display_${name}`] = isValidValue((value || {}).size) ? URL.createObjectURL(value) : null;
        }

        if (["lata_license_copy", "company_license_copy"].includes(name)) {
            temp_data[`${name}_filename`] = isValidValue(value) ? value.name : "";
        }

        setData(temp_data);
    }


    const handleUserFieldChange = (name, value) => {
        let temp_user_data = {...user_data};
        temp_user_data[name] = value;
        setUserData(temp_user_data);

        if (['confirm_password', 'password'].includes(name)) {
            if (temp_user_data.confirm_password !== temp_user_data.password && isValidValue(temp_user_data.confirm_password)) {
                setHelperText({...helper_text, confirm_password: "Password does not match"})
            } else {
                setHelperText({...helper_text, confirm_password: undefined})
            }
        }
    }

    const onSave = async () => {
        await setLoading(true);
        setHelperText({})
        const is_new = !isValidValue(data.id);
        let response;


        if (is_new && user_data.confirm_password !== user_data.password) {
            setHelperText({...helper_text, confirm_password: "Password does not match"})
            await setLoading(false);
            return
        }

        let form_data = new FormData();

        Object.keys(data).map(Name => {
            if (isValidValue(data[Name])) {
                form_data.append(Name, data[Name]);
            }
        });


        if (is_new) {

            Object.keys(user_data).map(Name => {
                if (isValidValue(user_data[Name])) {
                    form_data.append(`user_${Name}`, user_data[Name]);
                }
            });

            response = await httpRequestWithAuth(AgenciesRegistrationAllURL, "POST", form_data);
        } else {
            response = await httpRequestWithAuth(`${AgenciesURL}${data.id}`, "PUT", form_data);
        }

        if (response.status) {
            if (is_new) {
                openNotification("success", "Add Agency", `${data.name} agency has been added`)
            } else {
                openNotification("success", "Update Agency", `${data.name} agency has been updated`)
            }
            props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
        }

        await setLoading(false);

    }





    return (
        <div className="w-100">


            <Hide hide={!loading}>
                <HELoading/>
            </Hide>

            <Hide hide={loading}>
                <Row gutter={[12, 10]}>


                    <Col xs={24} className="d-flex align-items-center justify-content-between">
                        <h2 className="font-size-22 mb-0">
                                Details
                        </h2>
                    </Col>



                    <Col xs={24}>
                        <ImageUploaderWithView
                            max_size_3_mb
                            label="Upload Logo"
                            display_image={data.display_logo}
                            name="logo"
                            value={data.logo}
                            onChange={handleFieldChange}
                            error={helper_text.logo !== undefined}
                            helper_text={helper_text.logo}
                        />
                    </Col>

                    <Col xs={24} md={12}>
                        <CustomTextField
                            required
                            label="Agency Name"
                            name="name"
                            value={data.name}
                            onChange={handleFieldChange}
                            error={helper_text.name !== undefined}
                            helper_text={helper_text.name}
                        />
                    </Col>


                    <Col xs={24} md={12}>
                        <CustomSlugField
                            required
                            label="Slug"
                            name="slug"
                            value={data.slug}
                            onChange={handleFieldChange}
                            error={helper_text.slug !== undefined}
                            helper_text={helper_text.slug}
                        />
                    </Col>

                    <Col xs={24} md={12}>
                        <CustomTextField
                            required
                            label="Agency Contact E-mail"
                            name="agency_contact_email"
                            value={data.agency_contact_email}
                            onChange={handleFieldChange}
                            error={helper_text.agency_contact_email !== undefined}
                            helper_text={helper_text.agency_contact_email}
                            type="email"
                        />
                    </Col>


                    <Col xs={24} md={12}>
                        <CustomPhoneNumberField
                            required
                            label="Agency Contact No."
                            name="agency_contact_number"
                            value={data.agency_contact_number}
                            onChange={handleFieldChange}
                            error={helper_text.agency_contact_number !== undefined}
                            helper_text={helper_text.agency_contact_number}
                        />
                    </Col>


                    <Col xs={24} md={12}>
                        <CustomUploadField
                            display_value={data.lata_license_copy_filename}
                            label="Lata License Copy"
                            name="lata_license_copy"
                            value={data.lata_license_copy}
                            onChange={handleFieldChange}
                            error={helper_text.lata_license_copy !== undefined}
                            helper_text={helper_text.lata_license_copy}
                        />
                    </Col>


                    <Col xs={24} md={12}>
                        <CustomUploadField
                            display_value={data.company_license_copy_filename}
                            label="Company License Copy"
                            name="company_license_copy"
                            value={data.company_license_copy}
                            onChange={handleFieldChange}
                            error={helper_text.company_license_copy !== undefined}
                            helper_text={helper_text.company_license_copy}
                        />
                    </Col>


                    <Col xs={24}>
                        <CountryAutoComplete
                            required
                            multiple
                            label="Countries"
                            name="countries_id"
                            value={data.countries_id || []}
                            onChange={handleFieldChange}
                            error={helper_text.countries_id !== undefined}
                            helper_text={helper_text.countries_id}
                        />
                    </Col>

                    <Col xs={24} sm={12}>
                        <CustomCheckBox
                            label="Is Active"
                            name="is_active"
                            value={data.is_active}
                            onChange={handleFieldChange}
                        />
                    </Col>

                    <Col xs={24} sm={12}>
                        <CustomCheckBox
                            label="Package Requires Approval"
                            name="package_requires_approval"
                            value={data.package_requires_approval}
                            onChange={handleFieldChange}
                        />
                    </Col>

                    <Col xs={24} className="mt-2">
                            <Divider orientation="left" orientationMargin="0" className="mt-2 mb-3 fw-bold">
                                Services <span
                                className="text-danger font-size-13 mr-2">{helper_text.non_field_errors_service}</span>
                            </Divider>
                    </Col>

                    <Col xs={24} sm={12}>
                        <CustomCheckBox
                            label="Has Experiences"
                            name="has_experiences"
                            value={data.has_experiences}
                            onChange={handleFieldChange}
                        />
                    </Col>

                    <Col xs={24} sm={12}>
                        <CustomCheckBox
                            label="Has Travels"
                            name="has_travels"
                            value={data.has_travels}
                            onChange={handleFieldChange}
                        />
                    </Col>


                    <Hide hide={!isValidValue(data.id)}>
                        <Col xs={24} className="mt-2">
                                <Divider orientation="left" orientationMargin="0" className="mt-2 mb-3 fw-bold">
                                    Hello Explorer Commission
                                </Divider>
                        </Col>

                        <Col xs={24} md={8}>
                            <CustomTextField
                                view_only
                                label="Commission Type"
                                value={data.he_commission_type}
                            />
                        </Col>

                        <Col xs={24} md={8}>
                            <CustomTextField
                                view_only
                                label="Commission Amount"
                                value={`${data.commission_amount} ${data.he_commission_type === "Percentage"?"%":"KWD"}`}
                            />
                        </Col>

                        <Col xs={24} md={8}>
                            <CustomTextField
                                view_only
                                label="Minimum Commission Amount"
                                value={`${data.minimum_commission_amount} KWD`}
                            />
                        </Col>
                    </Hide>


                    <Hide hide={isValidValue(data.id)}>
                        <Col xs={24}>
                            <Divider orientation="left" orientationMargin="0" className="fw-bold">
                                Agency Admin User Info
                            </Divider>
                        </Col>


                        <Col xs={24} md={3}>
                            <CustomSelectField
                                required
                                label="Title"
                                name="title"
                                value={user_data.title}
                                onChange={handleUserFieldChange}
                                error={helper_text.title !== undefined}
                                helper_text={helper_text.title}
                                options={title_options}
                            />
                        </Col>

                        <Col xs={24} md={9}>
                            <CustomTextField
                                required
                                label="First Name"
                                name="first_name"
                                value={user_data.first_name}
                                onChange={handleUserFieldChange}
                                error={helper_text.first_name !== undefined}
                                helper_text={helper_text.first_name}
                            />
                        </Col>

                        <Col xs={24} md={12}>
                            <CustomTextField
                                required
                                label="Last Name"
                                name="last_name"
                                value={user_data.last_name}
                                onChange={handleUserFieldChange}
                                error={helper_text.first_name !== undefined}
                                helper_text={helper_text.last_name}
                            />
                        </Col>


                        <Col xs={24} md={12}>
                            <CustomPhoneNumberField
                                required
                                label="Contact No."
                                name="contact_number"
                                value={user_data.contact_number}
                                onChange={handleUserFieldChange}
                                error={helper_text.contact_number !== undefined}
                                helper_text={helper_text.contact_number}
                            />
                        </Col>


                        <Col xs={24} md={12}>
                            <CustomTextField
                                required
                                label="E-mail"
                                name="email"
                                value={user_data.email}
                                onChange={handleUserFieldChange}
                                error={helper_text.email !== undefined}
                                helper_text={helper_text.email}
                                type="email"
                            />
                        </Col>

                        <Col xs={24} md={12}>
                            <CustomTextField
                                required
                                label="Password"
                                name="password"
                                value={user_data.password}
                                onChange={handleUserFieldChange}
                                error={helper_text.password !== undefined}
                                helper_text={helper_text.password}
                                type="password"
                            />
                        </Col>

                        <Col xs={24} md={12}>
                            <CustomTextField
                                required
                                label="Confirm Password"
                                name="confirm_password"
                                value={user_data.confirm_password}
                                onChange={handleUserFieldChange}
                                error={helper_text.confirm_password !== undefined}
                                helper_text={helper_text.confirm_password}
                                type="password"
                            />
                        </Col>
                    </Hide>

                    <Hide hide={!isValidValue(data.id)}>
                        <Col xs={24} className="mt-3"/>
                        <ViewActionsDetails data={data}/>
                    </Hide>

                    <Col xs={24}>
                        <ActionButton
                            error_msg={helper_text.non_field_errors}
                            onDelete={handleDelete}
                            onCancel={onCancel}
                            allow_delete={isValidValue(data.id) && checkPermission(permission_data.agency.delete)}
                            allow_save={has_perm}
                            onSave={onSave}
                        />
                    </Col>

                </Row>
            </Hide>
        </div>
    )
}
