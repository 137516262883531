import React, {useState} from "react";
import {Col, Row} from "antd";
import {CustomDynamicTable} from "../../global/components/CustomDynamicTable";
import {InvoicesURL} from "../../global/urls";
import {httpRequestWithAuth} from "../../global/axios_instance";
import {openNotification} from "../../global/helper_functions";
import {permission_data} from "../../global/permissions_data";
import {checkPermission} from "../../global/permissions";
import {AddButton} from "../../global/components/AddButton";
import {IsActiveView} from "../../global/components/IsActiveView";
import CustomBadge from "../../global/components/CustomBadge";

export const Invoices = props => {
    const {slug} = props;
    const [invoice_perm] = useState({
        delete: checkPermission(permission_data.invoice.delete),
        edit: checkPermission(permission_data.invoice.edit),
        add: checkPermission(permission_data.invoice.add),
    });

    const [columns] = useState([
        {
            title: "Ref No.",
            dataIndex: "ref_no",
            width: "7%",
            render: (value, row_data) => <a href={`/${slug}/invoices/${row_data.id}`} target="_blank">{value}</a>
        },
        {
            title: "Title",
            dataIndex: "title",
            width: "30%"
        },
        {
            title: "Name",
            dataIndex: "customer_name",
            width: "30%"
        },
        // {
        //     title: "Contact Number",
        //     dataIndex: "customer_contact_number_view",
        //     width: "30%"
        // },
        {
            title: "Payment",
            dataIndex: "is_paid",
            width: "5%",
            align: "center",
            render: (value)=><IsActiveView active_label="Paid" inactive_label="Unpaid" value={value} view_only/>
        },
        {
            title: "Net Total",
            dataIndex: "net_total",
            width: "15%",
            align: "center",
            render: (value, row_data)=> `${value} ${row_data.currency}`
        },
        {
            title: "Status",
            dataIndex: "status",
            width: "5%",
            align: "center",
            render: (value) => <CustomBadge type={value !== "Completed"?value === "Cancelled"?"danger":"disabled":""} title={value}/>
        },
    ])


    const onDelete = async (row_data) => {
        let response = await httpRequestWithAuth(`${InvoicesURL}${row_data.id}`, "DELETE");
        if (response.status) {
            openNotification("success", "Delete Invoice", `${row_data.title_en} invoice has been deleted`)
        } else {
            openNotification("error", "Delete Invoice", response.error_feedback.non_field_errors)
        }
    }

    const onEdit = async (row_data) => {
        props.history.push(`/${slug}/invoices/${row_data.id}`);
    }

    const onAdd = async () => {
        props.history.push(`/${slug}/invoices/new`);
    }

    const freeAction = (
        <>
            <AddButton onClick={onAdd}>Add Invoice</AddButton>
        </>
    );

    return (
        <Row>
            <Col xs={24} className="paper">
                <CustomDynamicTable
                    title="Invoices"
                    columns={columns}
                    fetch_url={InvoicesURL}
                    free_action={freeAction}
                    onDelete={invoice_perm.delete && onDelete}
                    onEdit={invoice_perm.edit && onEdit}
                />
            </Col>
        </Row>
    )
}
