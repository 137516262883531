import DynamicAutocomplete from "./DynamicAutocomplete";
import {ListPackagesOptionDatesURL} from "../../urls";

export const PackageOptionsDatesAutoComplete = (props)=>{
    const interval = props.interval || "";
    const packages_options = props.packages_options || "";
    const status = props.status || null;
    const hide_unique_and_sold_out = props.hide_unique_and_sold_out || null;


    return(
        <DynamicAutocomplete
            {...props}
            fetch_url={ListPackagesOptionDatesURL}
            title_key={props.title_key  || "date_view"}
            mode={props.multiple ? "multiple":""}
            params_array={[packages_options]}
            params={{packages_options, status, hide_unique_and_sold_out, interval}}
        />
    )
}
