import {Button, Col, Row} from "antd";
import {CustomDynamicTable} from "../../../global/components/CustomDynamicTable";
import React, {useState} from "react";
import {PackagesAnalyticsURL} from "../../../global/urls";
import {customMoment, disableFutureDates, sumByKey} from "../../../global/helper_functions";
import {CustomDateField} from "../../../global/components/fields/CustomDateField";
import Hide from "../../../global/components/Hide";
import {PackageAnalyticsDetailsModal} from "./components/PackageAnalyticsDetailsModal";


export const PackagesAnalytics = props => {

    const [open_from_data, setOpenFormData] = useState(false);
    const [form_data, setFormData] = useState({});
    const [reload, setReload] = useState(false);
    const [filter, setFilter] = useState({
        start_date: customMoment().format("YYYY-MM-DD"),
        end_date: customMoment().format("YYYY-MM-DD")
    });
    const [columns] = useState([
        {
            title: "Ref No.",
            dataIndex: "ref_no",
            width: "10%",
            render: (value, row_data) => <a href={row_data.customer_website_url} target="_blank">{row_data.ref_no}</a>,
        },
        {
            title: "Title",
            dataIndex: "title_en",
            width: "50%",
            render: (value, row_data) => <a href={row_data.customer_website_url} target="_blank">{row_data.title_en}</a>,
        },
        {
            title: "Package views",
            dataIndex: "views.title_en",
            width: "25%",
            align: "center",
            render: (value, row_data) => row_data.views.page_views,
        },

        {
            title: "Actions",
            dataIndex: "id",
            width: "25%",
            render: (value, row_data) => <Button type="primary" onClick={() => handelOpenFormData(row_data)}>View
                Details</Button>,
            align: "center",
        },


    ]);

    const handleFilterChange = (name, value) => {
        setFilter({
            ...filter,
            [name]: value
        })
        setReload(Math.random());
    }


    const handelCloseFormData = (reload_data = false) => {
        setFormData({});
        setOpenFormData(false);

        // if (reload_data) {
        //     setReload(Math.random());
        // }
    }


    const handelOpenFormData = (data = {}) => {
        setFormData(data)
        setOpenFormData(true)
    }


    const filter_toolbar = (
        <Row gutter={[10, 10]}>
            <Col xs={24} md={6}>
                <CustomDateField
                    label="From"
                    name="start_date"
                    value={filter.start_date}
                    disabledDate={disableFutureDates}

                    onChange={handleFilterChange}
                />
            </Col>

            <Col xs={24} md={6}>
                <CustomDateField
                    label="To"
                    disabledDate={disableFutureDates}
                    name="end_date"
                    value={filter.end_date}
                    onChange={handleFilterChange}
                />
            </Col>
        </Row>
    );


    return (
        <Row>

            <Hide hide={!open_from_data}>
                <PackageAnalyticsDetailsModal
                    open={open_from_data}
                    data={form_data}
                    handleModelClose={handelCloseFormData}
                />
            </Hide>

            <Col xs={24} className="paper">
                <CustomDynamicTable
                    title="Packages Analytics"
                    reload_data={reload}
                    columns={columns}
                    fetch_url={PackagesAnalyticsURL}
                    filter_toolbar={filter_toolbar}
                    custom_params={filter}

                />
            </Col>
        </Row>
    )
}
