import React, {useEffect, useState} from "react";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {PackagesPendingChangesForModalURL, PackagesURL} from "../../../global/urls";
import {isValidValue, openNotification, showConfirm, showError, upperCaseView} from "../../../global/helper_functions";
import {Button, Col, Divider, Row, Tabs} from "antd";
import {ActionButton} from "../../../global/components/ActionButton";
import CustomSelectField from "../../../global/components/fields/CustomSelectField";
import {package_type} from "../../../global/variables";
import {CustomTextField} from "../../../global/components/fields/CustomTextField";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import {CustomTextAreaField} from "../../../global/components/fields/CustomTextAreaField";
import Hide from "../../../global/components/Hide";
import moment from "moment";
import {AgencyAutoComplete} from "../../../global/components/fields/AgencyAutoComplete";
import {HELoading} from "../../../global/components/HELoading";
import {CustomTabPane} from "../../../global/components/CustomTabPane";
import {CountryAutoComplete} from "../../../global/components/fields/CountryAutoComplete";
import {getUserAgencyId} from "../../../global/auth_functions";
import {PackageCategoryAutoComplete} from "../../../global/components/fields/PackageCategoryAutoComplete";
import {CustomDurationField} from "../../../global/components/fields/CustomDurationField";
import ViewActionsDetails from "../../../global/components/ViewActionsDetails";
import CustomCheckBox from "../../../global/components/fields/CustomCheckBox";
import PackageInclusionsAutoComplete from "../../../global/components/fields/PackageInclusionsAutoComplete";
import {SuggestedCancellationPolicesModal} from "./components/SuggestedCancellationPolicesModal";

export const PackageDetails = props => {
    const getPackageData = props.getPackageData;
    const is_new = props.is_new;
    const package_id = props.package_id || "";
    const slug = props.slug || "";
    const user_type = props.user_type || "";
    const is_agent = user_type === "Agent";
    const [show_all_fields, setShowAllFields] = useState(false);
    const [open_suggestion_cancellation_polices, setOpenSuggestionCancellationPolices] = useState(false);
    const [custom_package_type, setCustomPackageType] = useState(package_type);
    const [new_pending_changes, setNewPendingChanges] = useState({});
    const [current_details_tab, setCurrentDetailsTab] = useState(props.details_tab || "1");

    const lang = props.language || "en";
    const [has_perm] = useState(checkPermission(permission_data.package.edit) || (package_id === "new" && checkPermission(permission_data.package.add)));
    const [data, setData] = useState({
        AgencyId: is_agent ? getUserAgencyId() : null, package_type: "Experience", duration: 120,
    });

    const [loading, setLoading] = useState(true);
    const [currency, setCurrency] = useState("");
    const [helper_text, setHelperText] = useState({});


    const handleDetailsTabsChange = (new_value) => {
        setLoading(true);
        new_value = new_value + "";
        let params_attributes = {tab: props.current_tab, 'details-tab': new_value};
        const params = new URLSearchParams(params_attributes);

        props.history.push({
            pathname: props.location.pathname, search: params.toString()
        });

        setCurrentDetailsTab(new_value)
        setLoading(false);
    };

    const getPendingChanges = async () => {
        let response = await httpRequestWithAuth(PackagesPendingChangesForModalURL, "GET", null, {
            model_name: "Packages", package: package_id, object_id: package_id
        });
        if (response.status) {
            setNewPendingChanges(response.data)
        }
    }

    const fetchData = async () => {
        let response = await getPackageData();
        if (response.status) {
            setData(response.data);
            setShowAllFields(true);
        } else {
            showError("Get Package Data", response.error_feedback.non_field_errors);
            onCancel();
        }
        setLoading(false);
    }


    const handleFieldChange = (name, value) => {
        let temp_data = {...data};
        temp_data[name] = value;

        if (name === "package_type") {
            if (value === "Travel") {
                temp_data.countries_id = [];
            } else {
                temp_data.countries_id = null;
            }
        }

        setData(temp_data);
    }


    useEffect(() => {
        if (is_new) {
            let temp_data = {...data};
            Object.keys(data).map(data_key => {
                if (data_key.includes("_en")) {
                    let lang_key = data_key.replace("_en", `_${lang}`);
                    if (!isValidValue(temp_data[lang_key])) {
                        temp_data[lang_key] = temp_data[data_key];
                    }
                }
            })
            setData(temp_data);
        }

    }, [lang])


    useEffect(() => {
        if (!is_new) {
            fetchData();
            getPendingChanges();
        } else {
            setLoading(false);
        }

    }, [])


    useEffect(() => {
        if (is_agent) {
            let temp_package_type_options = [];


            if (checkPermission(permission_data.agency.has_travels)) {
                temp_package_type_options.push(package_type[0]);
            }

            if (checkPermission(permission_data.agency.has_experiences)) {
                temp_package_type_options.push(package_type[1]);
            }


            if (is_new && temp_package_type_options.length === 1) {
                handleFieldChange('package_type', temp_package_type_options[0].value);
            }

            setCustomPackageType(temp_package_type_options);

        }

    }, [])

    const onCancel = () => {
        props.history.push(`/${slug}/packages`);
    }

    const onDelete = async () => {
        await setLoading(true);
        let response = await httpRequestWithAuth(`${PackagesURL}${data.id}`, "DELETE");
        if (response.status) {
            openNotification("success", "Delete Package", `${data.title_en} package has been deleted`)
            onCancel();
        } else {
            openNotification("error", "Delete Package", response.error_feedback.non_field_errors)
        }
        await setLoading(false);
    }


    const handleDelete = () => {
        showConfirm("Delete Package", `Are you sure to delete ${data.title_en} package ?`, onDelete);
    }


    const onSave = async (confirm_zero=false) => {
        await setLoading(true);
        setHelperText({});

        if (is_new && !confirm_zero){
            if (parseFloat(data.starting_price) === 0){
                showConfirm("Complimentary Package", `Are you sure to create package with price equal zero  ?`, ()=>onSave(true));
                await setLoading(false);
                return;
            }
        }

        let temp_data = {
            ...data,
            active_from: isValidValue(data.active_from) ? moment(data.active_from).format("DD-MM-YYYY hh:mm") : "",
            active_to: isValidValue(data.active_to) ? moment(data.active_to).format("DD-MM-YYYY hh:mm") : "",
            last_installments_date: isValidValue(data.last_installments_date) ? moment(data.last_installments_date).format("DD-MM-YYYY hh:mm") : "",
        }

        let response;
        if (is_new) {
            response = await httpRequestWithAuth(PackagesURL, "POST", temp_data);
        } else {
            response = await httpRequestWithAuth(`${PackagesURL}${data.id}`, "PUT", temp_data);
        }

        if (response.status) {
            if (is_new) {
                openNotification("success", "Add Package", `${data.title_en} package has been added`);
            } else {
                fetchData()
                openNotification("success", "Edit Package", response.data.msg || `${data.title_en} package has been updated`);
            }

            const next_details_tab = parseInt(current_details_tab) + 1;

            if (next_details_tab <= 4) {
                if (is_new) {
                    props.history.push(`/${slug}/packages/${response.data.id}/?tab=1&details-tab=${next_details_tab}`);
                } else {
                    handleDetailsTabsChange(next_details_tab);
                }
            } else {
                let to_tab = -1;
                switch (true) {
                    case checkPermission(permission_data.package_gallery.view):
                        to_tab = 2;
                        break;
                    case checkPermission(permission_data.package_option.view):
                        to_tab = 3;
                        break;
                    case checkPermission(permission_data.package_installment.view):
                        to_tab = 4;
                        break;
                    case checkPermission(permission_data.package_setting.view):
                        to_tab = 6;
                        break;
                    default:
                        to_tab = -1;
                }

                if (to_tab > 0) {
                    if (is_new) {
                        props.history.push(`/${slug}/packages/${response.data.id}/?tab=${to_tab}`);
                    } else {
                        props.handleTabsChange(to_tab);
                    }
                } else {
                    onCancel();
                }

            }


        } else {
            // openNotification("error", is_new?"Add Package":"Edit Package", response.error_feedback.non_field_errors)
            setHelperText(response.error_feedback)
            showError("Save Failed", "Please checks all fields");
        }

        await setLoading(false);
    }


    const getCurrencyFromCountry = (cuntry_obj) => {
        setCurrency(cuntry_obj.currency);
    }

    const onSelectCancellationOption = (value_en, value_ar) =>{
        let temp_data = {...data};
        temp_data.cancellation_policy_en = value_en;
        temp_data.cancellation_policy_ar = value_ar;
        setData(temp_data);
    }
    return (<div className="paper pt-0 w-100">

        <Hide hide={!loading}>
            <HELoading/>
        </Hide>

        <Hide hide={!open_suggestion_cancellation_polices}>
            <SuggestedCancellationPolicesModal
                lang={lang}
                open={open_suggestion_cancellation_polices}
                handleModelClose={()=>setOpenSuggestionCancellationPolices(false)}
                onSelect={onSelectCancellationOption}
            />
        </Hide>

        <Hide hide={loading}>

            <Row gutter={[12, 10]}>

                <Col xs={24}>
                    <h2 className="font-size-22 mb-0">
                        <label className="text-start">
                            Details
                        </label>
                    </h2>
                </Col>

                <Col xs={24}>
                    <Tabs activeKey={current_details_tab} onChange={handleDetailsTabsChange}>
                        <CustomTabPane tab="General" key="1" disabled={is_new}/>
                        <CustomTabPane tab="Include & Exclude" key="2" disabled={is_new}/>
                        <CustomTabPane tab="Cancellation Policy" key="3" disabled={is_new}/>
                        <CustomTabPane tab="Terms & Conditions" key="4" disabled={is_new}/>
                    </Tabs>
                </Col>

                <Hide hide={current_details_tab !== "1"}>
                    <Hide hide={is_agent}>
                        <Col xs={24}>
                            <AgencyAutoComplete
                                view_only={!has_perm}
                                label="Agency"
                                name="AgencyId"
                                value={data.AgencyId}
                                onChange={handleFieldChange}
                                error={helper_text.AgencyId !== undefined}
                                helper_text={helper_text.AgencyId}
                            />
                        </Col>
                    </Hide>


                    <Col xs={24} md={is_new ? 18 : 10}>
                        <CustomTextField
                            view_only={!has_perm}
                            label={`Title ${upperCaseView(lang)}`}
                            name={`title_${lang}`}
                            value={data[`title_${lang}`]}
                            onChange={handleFieldChange}
                            error={helper_text[`title_${lang}`] !== undefined}
                            helper_text={helper_text[`title_${lang}`]}
                            warning_tooltip={new_pending_changes[`title_${lang}`]}
                            warning_tooltip_title="New Value Waiting Approve:"
                        />
                    </Col>

                    <Hide hide={!is_new}>
                        <Col xs={24} md={6}>
                            <CustomSelectField
                                view_only={!has_perm || !is_new}
                                label="Package Type"
                                name="package_type"
                                value={data.package_type}
                                onChange={handleFieldChange}
                                error={helper_text.package_type !== undefined}
                                helper_text={helper_text.package_type}
                                options={custom_package_type}
                                warning_tooltip={new_pending_changes['package_type']}
                                warning_tooltip_title="New Value Waiting Approve:"
                            />
                        </Col>
                    </Hide>

                    <Hide hide={is_new}>
                        <Col xs={24} md={10}>
                            <CustomTextField
                                view_only
                                label={`Slug ${upperCaseView(lang)}`}
                                name={`slug_${lang}`}
                                value={data[`slug_${lang}`]}
                            />
                        </Col>
                    </Hide>

                    <Hide hide={is_new}>
                        <Col xs={24} md={4}>
                            <CustomSelectField
                                view_only={!has_perm || !is_new}
                                label="Package Type"
                                name="package_type"
                                value={data.package_type}
                                onChange={handleFieldChange}
                                error={helper_text.package_type !== undefined}
                                helper_text={helper_text.package_type}
                                options={custom_package_type}
                                warning_tooltip={new_pending_changes['package_type']}
                                warning_tooltip_title="New Value Waiting Approve:"
                            />
                        </Col>
                    </Hide>


                    <Hide hide={!is_new}>
                        <Col xs={24} md={18}>
                            <PackageCategoryAutoComplete
                                multiple
                                label="Categories"
                                name="categories_id"
                                value={data.categories_id}
                                onChange={handleFieldChange}
                                error={helper_text.categories_id !== undefined}
                                helper_text={helper_text.categories_id}
                                tooltip="Add suitable categories to increase package sales & viwws"
                            />
                        </Col>

                        <Col xs={24} md={6}>
                            <CustomTextField
                                view_only={!has_perm}
                                label="Starting Price"
                                name="starting_price"
                                value={data.starting_price}
                                onChange={handleFieldChange}
                                error={helper_text.starting_price !== undefined}
                                helper_text={helper_text.starting_price}
                                type="number"
                                addonAfter={data.package_type === "Travel" ? "KWD" : currency || ""}
                            />
                        </Col>
                    </Hide>


                    <Hide hide={!is_new}>


                        <Col xs={24} md={24}>
                            <CountryAutoComplete
                                multiple={data.package_type === "Travel"}
                                for_agency_experience={data.package_type !== "Travel" ? data.AgencyId || "null" : ""}
                                is_he_supported={data.package_type !== "Travel" ? true : null}
                                label={data.package_type === "Travel" ? "Countries" : "Country"}
                                name="countries_id"
                                value={data.countries_id}
                                onChange={handleFieldChange}
                                error={helper_text.countries_id !== undefined}
                                helper_text={helper_text.countries_id}
                                getFullObj={getCurrencyFromCountry}
                            />
                        </Col>


                        <Col xs={24} md={18}>
                            <CustomDurationField
                                view_only={!has_perm}
                                label="Duration"
                                name="duration"
                                value={data.duration}
                                onChange={handleFieldChange}
                                error={helper_text.duration !== undefined}
                                helper_text={helper_text.duration}
                                tooltip="Duration of the trip/experience"
                            />
                        </Col>

                    </Hide>


                    <Col xs={24}>
                        <CustomTextAreaField
                            withTextEditor
                            view_only={!has_perm}
                            label={`Description ${upperCaseView(lang)}`}
                            name={`description_${lang}`}
                            value={data[`description_${lang}`]}
                            onChange={handleFieldChange}
                            error={helper_text[`description_${lang}`] !== undefined}
                            helper_text={helper_text[`description_${lang}`]}
                            warning_tooltip={new_pending_changes[`description_${lang}`]}
                            warning_tooltip_title="New Value Waiting Approve:"
                        />
                    </Col>
                </Hide>


                <Hide hide={current_details_tab !== "2"}>
                    <Col xs={24}>
                        <CustomCheckBox
                            name="is_included_from_inclusions"
                            label="Select included from inclusions list"
                            value={data.is_included_from_inclusions}
                            onChange={handleFieldChange}
                        />
                    </Col>

                    <Hide hide={!data.is_included_from_inclusions}>
                        <Col xs={24}>
                            <PackageInclusionsAutoComplete
                                multiple
                                view_only={!has_perm}
                                label="Includes"
                                name="includes_id"
                                value={data.includes_id}
                                onChange={handleFieldChange}
                                warning_tooltip={new_pending_changes[`includes_id`]}
                                warning_tooltip_title="New Value Waiting Approve:"
                            />
                        </Col>
                    </Hide>

                    <Hide hide={data.is_included_from_inclusions}>
                        <Col xs={24}>
                            <CustomTextAreaField
                                withTextEditor
                                view_only={!has_perm}
                                label={`Included ${upperCaseView(lang)}`}
                                name={`included_${lang}`}
                                value={data[`included_${lang}`]}
                                onChange={handleFieldChange}
                                error={helper_text[`included_${lang}`] !== undefined}
                                helper_text={helper_text[`included_${lang}`]}
                                warning_tooltip={new_pending_changes[`included_${lang}`]}
                                warning_tooltip_title="New Value Waiting Approve:"
                            />
                        </Col>
                    </Hide>

                    <Divider/>

                    <Col xs={24}>
                        <CustomCheckBox
                            name="is_excluded_from_inclusions"
                            label="Select excluded from inclusions list"
                            value={data.is_excluded_from_inclusions}
                            onChange={handleFieldChange}
                        />
                    </Col>

                    <Hide hide={!data.is_excluded_from_inclusions}>
                        <Col xs={24}>
                            <PackageInclusionsAutoComplete
                                exclude={data.includes_id || []}
                                multiple
                                view_only={!has_perm}
                                label="Excludes"
                                name="excludes_id"
                                value={data.excludes_id}
                                onChange={handleFieldChange}
                                warning_tooltip={new_pending_changes[`excludes_id`]}
                                warning_tooltip_title="New Value Waiting Approve:"
                            />
                        </Col>
                    </Hide>

                    <Hide hide={data.is_excluded_from_inclusions}>
                        <Col xs={24}>
                            <CustomTextAreaField
                                withTextEditor
                                view_only={!has_perm}
                                label={`Excluded ${upperCaseView(lang)}`}
                                name={`excluded_${lang}`}
                                value={data[`excluded_${lang}`]}
                                onChange={handleFieldChange}
                                error={helper_text[`excluded_${lang}`] !== undefined}
                                helper_text={helper_text[`excluded_${lang}`]}
                                warning_tooltip={new_pending_changes[`excluded_${lang}`]}
                                warning_tooltip_title="New Value Waiting Approve:"
                            />
                        </Col>
                    </Hide>

                </Hide>

                <Hide hide={current_details_tab !== "3"}>

                    <Col xs={24}>
                        <div className="w-100 d-flex justify-content-end">
                        <Button onClick={()=>setOpenSuggestionCancellationPolices(true)} type="link" className="p-0">Suggested Cancellation Polices</Button>
                        </div>

                        <CustomTextAreaField
                            withTextEditor
                            view_only={!has_perm}
                            label={`Cancellation Policy ${upperCaseView(lang)}`}
                            name={`cancellation_policy_${lang}`}
                            value={data[`cancellation_policy_${lang}`]}
                            onChange={handleFieldChange}
                            error={helper_text[`cancellation_policy_${lang}`] !== undefined}
                            helper_text={helper_text[`cancellation_policy_${lang}`]}
                            warning_tooltip={new_pending_changes[`cancellation_policy_${lang}`]}
                            warning_tooltip_title="New Value Waiting Approve:"
                        />
                    </Col>
                </Hide>

                <Hide hide={current_details_tab !== "4"}>
                    <Col xs={24}>
                        <CustomTextAreaField
                            withTextEditor
                            view_only={!has_perm}
                            label={`Terms & Conditions ${upperCaseView(lang)}`}
                            name={`terms_and_conditions_${lang}`}
                            value={data[`terms_and_conditions_${lang}`]}
                            onChange={handleFieldChange}
                            error={helper_text[`terms_and_conditions_${lang}`] !== undefined}
                            helper_text={helper_text[`terms_and_conditions_${lang}`]}
                            warning_tooltip={new_pending_changes[`terms_and_conditions_${lang}`]}
                            warning_tooltip_title="New Value Waiting Approve:"
                        />
                    </Col>
                </Hide>


                <Hide hide={is_new}>
                    <ViewActionsDetails data={data}/>
                </Hide>


                {/*<Hide hide={show_all_fields}>*/}
                {/*    <Col xs={24} className="text-md-end text-center">*/}
                {/*        <Button type="link" onClick={() => setShowAllFields(true)}>View More Details ></Button>*/}
                {/*    </Col>*/}
                {/*</Hide>*/}


                <Col xs={24}>
                    <ActionButton
                        error_msg={helper_text.non_field_errors}
                        onDelete={handleDelete}
                        onCancel={onCancel}
                        allow_delete={isValidValue(data.id) && checkPermission(permission_data.package.delete)}
                        allow_save={has_perm}
                        onSave={()=>onSave()}
                    />
                </Col>

            </Row>
        </Hide>
    </div>)
}
