import DynamicAutocomplete from "./DynamicAutocomplete";
import {ListPackagesOptionsURL} from "../../urls";

export const PackageOptionsAutoComplete = (props)=>{
    const packages = props.packages || [];


    return(
        <DynamicAutocomplete
            {...props}
            fetch_url={ListPackagesOptionsURL}
            title_key="title_en"
            mode={props.multiple ? "multiple":""}
            params_array={[packages]}
            params={{packages}}
        />
    )
}