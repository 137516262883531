import {Button, Col, Row} from "antd";
import {CustomDynamicTable} from "../../../global/components/CustomDynamicTable";
import {useState} from "react";
import {TiersURL} from "../../../global/urls";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {openNotification, showError, showInfo} from "../../../global/helper_functions";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import {AddButton} from "../../../global/components/AddButton";
import {TierFormModal} from "./components/TierFormModal";
import Hide from "../../../global/components/Hide";

export const Tiers = props =>{
    const [open_from_data, setOpenFormData] = useState(false);
    const [form_data, setFormData] = useState({});
    const [reload, setReload] = useState(false);

    const [tier_perm] = useState({
        delete: checkPermission(permission_data.tier.delete),
        edit: checkPermission(permission_data.tier.edit),
        add: checkPermission(permission_data.tier.add),
    });

    const [columns] = useState([
        {
            title: "Badge",
            dataIndex: "display_badge_image",
            width: "7%",
            align: "center",
            render:(value)=>(
                <img src={value} width={35}/>
            )
        },
        {
            title: "Title EN",
            dataIndex: "title_en",
            width: "40%"
        },
        {
            title: "Title Ar",
            dataIndex: "title_ar",
            width: "40%"
        },
        {
            title: "To Rank",
            dataIndex: "to_rank",
            width: "40%",
            align: "center"
        },
    ])

    const handelOpenFormData = (data={})=>{
        setFormData(data)
        setOpenFormData(true)
    }

    const handelCloseFormData = (reload_data=false)=>{
        setFormData({});
        setOpenFormData(false);

        if (reload_data){
            setReload(Math.random());
        }

    }

    const onDelete = async (row_data) =>{
        let response = await httpRequestWithAuth(`${TiersURL}${row_data.id}`, "DELETE");
        if (response.status){
            openNotification("success", "Delete Tiers", `${row_data.title_en} tier has been deleted`)
        }else{
            openNotification("error", "Delete Tiers", response.error_feedback.non_field_errors)
        }
    }

    const onEdit = async (row_data) =>{
        handelOpenFormData(row_data)
    }

    const onAdd = async () =>{
        handelOpenFormData()
    }

    const freeAction = (
        <>
            <AddButton onClick={onAdd}>Add Tiers</AddButton>
        </>
    );

    return(
        <Row>
            <Hide hide={!open_from_data}>
                <TierFormModal
                    open={open_from_data}
                    data={form_data}
                    handleModelClose={handelCloseFormData}
                />
            </Hide>

            <Col xs={24} className="paper">
                <CustomDynamicTable
                    title="Tiers"
                    reload_data={reload}
                    columns={columns}
                    fetch_url={TiersURL}
                    free_action={freeAction}
                    onDelete={tier_perm.delete && onDelete}
                    onEdit={tier_perm.edit && onEdit}
                />
            </Col>
        </Row>
    )
}
