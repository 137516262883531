import React, {useEffect, useState} from 'react';

import {httpRequestWithAuth} from "../../../global/axios_instance";
import {Top5PackagesURL} from "../../../global/urls";
import {isValidValue} from "../../../global/helper_functions";
import {Button, Table} from "antd";
import Hide from "../../../global/components/Hide";
import {Loading} from "../../../global/components/Loading";
import {checkPermission} from "../../../global/permissions";
import {permission_data} from "../../../global/permissions_data";
import CustomRadioGroup from "../../../global/components/fields/CustomRadioGroup";
import {package_type} from "../../../global/variables";
import {CaretDownOutlined} from "@ant-design/icons";

const interval_options = [
    {label: "24h", value:24},
    {label: "7d", value:168},
    {label: "30d", value:720},
    {label: "90d", value:2160},
    {label: "180d", value:4320},
    {label: "1y", value:8760},
];

const Top5Packages = props => {
    const [data, setData] = useState([]);
    const [has_perm] = useState(checkPermission(permission_data.reports.view_top_packages_report));
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState({
        interval: 24,
        sort_by: "total_amount",
    });

    const columns = [
        {
            title: "Title",
            dataIndex: `package_title_en`,
            width: "30%",
            render: value => <span className="text-dots-two-line">{value}</span>
        },
        {
            title: "Type",
            dataIndex: `booking_type`,
            width: "10%",
        },
        {
            title: <Button onClick={()=>onChangeFilter('sort_by', "total_amount")} className="p-0 m-0 bg-none border-0">Sale (KWD)<CaretDownOutlined className="ms-1"/></Button>,
            dataIndex: `total_amount`,
            width: "20%",
            align: "center",
            render: (value, row_data) => <span>{parseFloat(value)} <span>({row_data.amount_percentage}%)</span></span>
        },
        {
            title: <Button onClick={()=>onChangeFilter('sort_by', "total_booking")} className="p-0 m-0 bg-none border-0">Booking <CaretDownOutlined className="ms-1"/></Button>,
            dataIndex: `total_booking`,
            width: "20%",
            align: "center",
            render: (value, row_data) => <span>{parseFloat(value)} <span>({row_data.booking_percentage}%)</span></span>
        },
        {
            title: <Button onClick={()=>onChangeFilter('sort_by', "total_views")} className="p-0 m-0 bg-none border-0">Traffic <CaretDownOutlined className="ms-1"/></Button>,
            dataIndex: `total_views`,
            width: "20%",
            align: "center",
            render: (value, row_data) => <span>{parseFloat(value)} <span>({row_data.views_percentage}%)</span></span>
        },
    ]
    useEffect(() => {
        const fetchData = async () => {
            if (has_perm) {
                setLoading(true)

                const params = {};

                params.sort_by = filter.sort_by || "total_amount";

                if (isValidValue(filter.package_type)) {
                    params.package_type = filter.package_type;
                }

                if (isValidValue(filter.interval)) {
                    params.interval = filter.interval;
                } else {
                    params.interval = 500;
                }


                const response = await httpRequestWithAuth(Top5PackagesURL, "GET", null, params);
                if (response.status) {
                    setData(response.data || []);
                }


                setLoading(false)
            }

        }

        fetchData();
    }, [filter])


    const onChangeFilter = (name, value) => {
        if (loading) return;
        setFilter({...filter, [name]: value})
    }

    return (
        <div className="w-100 h-100 position-relative">
            <Hide hide={!loading}>
                <div
                    className="position-absolute d-flex justify-content-center align-items-center w-100 h-100 z-index-100">
                    <div className="blur-bg w-100 pt-1 pb-3">
                        <Loading hide_text/>
                    </div>
                </div>
            </Hide>


            <div className="d-flex justify-content-between">
                <h2 className="custom-grey-color font-size-16">
                    Top 5 Packages
                </h2>
            </div>

            <div className="mb-2 mt-2 w-100 d-inline-flex justify-content-between">
                <CustomRadioGroup
                    add_all
                    name="package_type"
                    value={filter.package_type}
                    options={package_type}
                    onChange={onChangeFilter}
                />

                <CustomRadioGroup
                    name="interval"
                    value={filter.interval}
                    options={interval_options}
                    onChange={onChangeFilter}
                />
            </div>

            <Table
                loading={loading}
                dataSource={data}
                columns={columns}
                pagination={false}
                scroll={{
                    x: 750,
                    y:300
                }}
            />


        </div>
    );
}


export default Top5Packages;
