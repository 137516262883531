
import { useEffect, useState } from "react";

function getSize() {
    return {
        inner_height: window.innerHeight,
        inner_width: window.innerWidth,
        outer_height: window.outerHeight,
        outer_width: window.outerWidth,
    };
}

const useWindowSize = () => {
    let [windowSize, setWindowSize] = useState(getSize());

    function handleResize() {
        setWindowSize(getSize());
    }

    useEffect(() => {

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return windowSize;
};

export default useWindowSize;
