import DynamicAutocomplete from "./DynamicAutocomplete";
import {PackagesCategoriesListAllURL} from "../../urls";

export const PackageCategoryAutoComplete = (props)=>{

    return(
        <DynamicAutocomplete
            {...props}
            exclude={props.exclude}
            fetch_url={PackagesCategoriesListAllURL}
            title_key="name_en"
            mode={props.multiple ? "multiple":""}
        />
    )
}