import {useState} from "react";
import CustomModal from "../../../../global/components/CustomModal";
import {Col, Row} from "antd";
import {CustomTextField} from "../../../../global/components/fields/CustomTextField";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {GlobalDataURL} from "../../../../global/urls";
import {isValidValue, openNotification} from "../../../../global/helper_functions";
import {ModelActionButton} from "../../../../global/components/ModelActionButton";
import CustomCheckBox from "../../../../global/components/fields/CustomCheckBox";
import {isSuperAdmin} from "../../../../global/auth_functions";
import Hide from "../../../../global/components/Hide";
import {CustomTextAreaField} from "../../../../global/components/fields/CustomTextAreaField";
import {checkPermission} from "../../../../global/permissions";
import {permission_data} from "../../../../global/permissions_data";


export const GlobalDataFormModal = props => {
    const [package_data, setData] = useState({
        is_he_supported: false,
        only_super_admin: false,
        is_active: true,
        ...props.package_data
    });
    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});
    const [is_super_admin] = useState(isSuperAdmin());
    const [can_change_key] = useState(!isValidValue(props.package_data.id) || (isValidValue(props.package_data.id) && checkPermission(permission_data.global_data.change_key)));

    const handleFieldChange = (name, new_value) => {
        let temp_data = {...package_data};

        if (name === "with_text_editor" && !new_value){
            temp_data['value'] = (package_data.value || "").replace(/<[^>]+>/g, '')
        }

        temp_data[name] = new_value;
        setData(temp_data);
    }

    const onSave = async () => {
        await setLoading(true);


        const is_new = !isValidValue(package_data.id);
        let response;

        if (is_new) {
            response = await httpRequestWithAuth(GlobalDataURL, "POST", package_data);
        } else {
            response = await httpRequestWithAuth(`${GlobalDataURL}${package_data.id}`, "PUT", package_data);
        }

        if (response.status) {
            if (is_new) {
                openNotification("success", "Add Global Data", `${package_data.view_name} global package_data has been added`)
            } else {
                openNotification("success", "Update Global Data", `${package_data.view_name} global package_data has been updated`)
            }
            props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
        }

        await setLoading(false);

    }

    return (
        <CustomModal title="Global Data Form" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>

                <Col xs={24} md={12}>
                    <CustomTextField
                        required
                        label="Key"
                        name="key"
                        disabled={!can_change_key}
                        value={package_data.key}
                        onChange={handleFieldChange}
                        error={helper_text.key !== undefined}
                        helper_text={helper_text.key}
                    />
                </Col>


                <Col xs={24} md={12}>
                    <CustomTextField
                        label="View Name"
                        name="view_name"
                        value={package_data.view_name}
                        onChange={handleFieldChange}
                        error={helper_text.view_name !== undefined}
                        helper_text={helper_text.view_name}
                    />
                </Col>

                <Col xs={24}>
                    <CustomTextAreaField
                        withTextEditor={package_data.with_text_editor}
                        label="Value"
                        name="value"
                        value={package_data.value}
                        onChange={handleFieldChange}
                        error={helper_text.value !== undefined}
                        helper_text={helper_text.value}
                    />
                </Col>

                <Col xs={24}>
                    <CustomCheckBox
                        label="With Text Editor"
                        name="with_text_editor"
                        value={package_data.with_text_editor}
                        onChange={handleFieldChange}
                    />
                </Col>

                <Hide hide={!is_super_admin}>
                    <Col xs={24}>
                        <CustomCheckBox
                            label="Only Super Admin"
                            name="only_super_admin"
                            value={package_data.only_super_admin}
                            onChange={handleFieldChange}
                        />
                    </Col>
                </Hide>

                <Col xs={24}>
                    <CustomCheckBox
                        label="Is Active"
                        name="is_active"
                        value={package_data.is_active}
                        onChange={handleFieldChange}
                    />
                </Col>

                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}
