import React from "react";
import {Col, Collapse, Row} from "antd";

const {Panel} = Collapse;

export const BookingItemPersonsView = props => {
    const persons_data = props.persons || [];

    return (
        <Collapse defaultActiveKey={0} bordered={false} className="bg-light p-0" accordion>
            {persons_data.map((person, index) => (
                <Panel header={`Person ${index + 1} Information`} className="bg-light p-0">
                    <Row gutter={[12, 10]} className="bg-light p-md-3">

                        <Col xs={24} md={8}>
                            <Row gutter={[12, 10]}>

                                <Col xs={24}>
                                    <h2 className="font-size-12 text-black-50">
                                        Full Name
                                    </h2>

                                    <h2 className="font-size-14 ms-1 mb-0">
                                        {`${person.title} ${person.first_name} ${person.last_name}`}
                                    </h2>
                                </Col>

                                <Col xs={24}>
                                    <h2 className="font-size-12 text-black-50">
                                        Email
                                    </h2>

                                    <h2 className="font-size-14 ms-1 mb-0">
                                        {`${person.email}`}
                                    </h2>
                                </Col>

                                <Col xs={24}>
                                    <h2 className="font-size-12 text-black-50">
                                        Contact Number
                                    </h2>

                                    <h2 className="font-size-14 ms-1 mb-0">
                                        {`${person.contact_number}`}
                                    </h2>
                                </Col>


                            </Row>
                        </Col>


                        <Col xs={24} md={8}>
                            <Row gutter={[12, 10]}>

                                <Col xs={24}>
                                    <h2 className="font-size-12 text-black-50">
                                        Nationality
                                    </h2>

                                    <h2 className="font-size-14 ms-1 mb-0">
                                        {`${person.nationality}`}
                                    </h2>
                                </Col>


                                <Col xs={24}>
                                    <h2 className="font-size-12 text-black-50">
                                        Birthdate
                                    </h2>

                                    <h2 className="font-size-14 ms-1 mb-0">
                                        {`${person.birthdate}`}
                                    </h2>
                                </Col>


                                <Col xs={24}>
                                    <h2 className="font-size-12 text-black-50">
                                        Country Of Residence
                                    </h2>

                                    <h2 className="font-size-14 ms-1 mb-0">
                                        {`${person.country_of_residence}`}
                                    </h2>
                                </Col>

                            </Row>
                        </Col>


                        <Col xs={24} md={8}>
                            <Row gutter={[12, 10]}>


                                <Col xs={24}>
                                    <h2 className="font-size-12 text-black-50">
                                        Full Name In Passport
                                    </h2>

                                    <h2 className="font-size-14 ms-1 mb-0">
                                        {`${person.full_name_in_passport}`}
                                    </h2>
                                </Col>


                                <Col xs={24}>
                                    <h2 className="font-size-12 text-black-50">
                                        Passport Number
                                    </h2>

                                    <h2 className="font-size-14 ms-1 mb-0">
                                        {`${person.passport_number}`}
                                    </h2>
                                </Col>


                                <Col xs={24}>
                                    <h2 className="font-size-12 text-black-50">
                                        Full Address
                                    </h2>

                                    <h2 className="font-size-14 ms-1 mb-0">
                                        {`${person.full_address}`}
                                    </h2>
                                </Col>

                            </Row>
                        </Col>


                    </Row>
                </Panel>
            ))}
        </Collapse>
    )
}