import {Button} from "antd";
import {PlusCircleFilled} from "@ant-design/icons";
import Hide from "./Hide";

export const AddButton = props =>{
    return(
        <Button
            block
            {...props}
            type="primary"
            className={`mb-1 m-md-1 justify-content-center  ${props.className}`}
        >
            <Hide hide_down_md={props.hide_icon_sm}>
            <PlusCircleFilled/>
            </Hide>

            {props.children}
        </Button>
    )
}