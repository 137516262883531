import React, {Fragment, useState} from "react";
import CustomModal from "../../../../global/components/CustomModal";
import {Alert, Button, Col, Divider, Row} from "antd";
import {CustomTextField} from "../../../../global/components/fields/CustomTextField";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {PackagesOptionDateTimeBulkAddURL} from "../../../../global/urls";
import {isValidValue, openNotification} from "../../../../global/helper_functions";
import {ModelActionButton} from "../../../../global/components/ModelActionButton";
import CustomCheckBox from "../../../../global/components/fields/CustomCheckBox";
import {CustomDateField} from "../../../../global/components/fields/CustomDateField";
import Hide from "../../../../global/components/Hide";
import moment from "moment";
import {AddButton} from "../../../../global/components/AddButton";
import MinusRoundIcon from '@rsuite/icons/MinusRound';
import {CustomTimeField} from "../../../../global/components/fields/CustomTimeField";

const options = [
    {
        title: "Three Part Cancellation in Hours",
        value_en: "Cancellations made more than 72 hours before the scheduled date will receive a full refund." +
            "<br/>" +
            "Cancellations made between 48 to 72 hours before the scheduled date will receive a 50% refund." +
            "<br/>" +
            "Cancellations made less than 48 hours before the scheduled date are non-refundable",
        value_ar: "الإلغاء الذي تم إجراؤه أكثر من 72 ساعة قبل الموعد المحدد سيحصل على استرداد كامل." +
            "<br/>" +
            "الإلغاء الذي يتم بين 48 إلى 72 ساعة قبل الموعد المحدد سيحصل على استرداد 50٪." +
            "<br/>" +
            "الإلغاءات التي يتم إجراؤه أقل من 48 ساعة من الموعد المحدد غير قابلة للاسترداد",
    },
    {
        title: "Three Part Cancellation in Days",
        value_en: "Cancellations made more than 5 Days before the scheduled date will receive a full refund." +
            "<br/>" +
            "Cancellations made between 2 to 5 days before the scheduled date will receive a 50% refund." +
            "<br/>" +
            "Cancellations made less than 2 days before the scheduled date are non-refundable.",
        value_ar: "الإلغاء الذي تم إجراؤه أكثر من 5 أيام قبل الموعد المحدد سيحصل على استرداد كامل." +
            "<br/>" +
            "الإلغاء الذي يتم بين 2 أيام  إلى 5 أيام  قبل الموعد المحدد سيحصل على استرداد 50٪.<br/>" +
            "الإلغاء أقل من 2 أيام (٤٨ ساعة) من الموعد المحدد غير قابلة للاسترداد."
    },
    {
        title: "Non-Refundable",
        value_en: "This booking is non-refundable.",
        value_ar: "هذا الحجز غير قابل للاسترداد.",
    },
    {
        title: "Non-Refundable but can be rescheduled",
        value_en: "The booking is non-refundable.<br/>However, if you need to reschedule your booking, please notify us at least 48 hours in advance, and we will do our best to accommodate you in the next available time slot.",
        value_ar: "الحجز غير قابل للاسترداد.<br/>إذا كنت بحاجة إلى إعادة جدولة حجزك ، فيرجى إخطارنا قبل 48 ساعة على الأقل ، وسنبذل قصارى جهدنا لاستيعابك في الفترة الزمنية التالية المتاحة.",
    },
    {
        title: "Refundable",
        value_en: "We understand that plans can change, and we strive to be flexible. If you need to cancel your booking, please notify us at least 48 hours in advance to receive a full refund. Cancellations made less than 48 hours of the scheduled time is non-refundable.",
        value_ar: "نحن نفهم أن الخطط يمكن أن تتغير ، ونحن نسعى جاهدين لتكون مرنة. إذا كنت بحاجة إلى إلغاء حجزك ، فيرجى إخطارنا قبل 48 ساعة على الأقل لاسترداد المبلغ بالكامل. الإلغاء الذي تم إجراؤه أقل من 48 ساعة من الوقت المحدد غير قابل للاسترداد",
    }
];

export const SuggestedCancellationPolicesModal = props => {
    const lang = props.lang || "en";

    const onSelectOption = async (option) => {
            props.onSelect(option.value_en, option.value_ar);
            props.handleModelClose()
    }


    return (
        <CustomModal title="Suggested Cancellation Polices" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>
                {options.map((option, index)=>(
                    <Col xs={24} key={index} className="mb-3">
                        <Button className="p-2 border border-radius-10 d-block w-100 h-100 text-start" onClick={()=>onSelectOption(option)}>
                        <p className="font-size-14"><label className="fw-bold font-size-16">Option {index + 1}: </label> {option.title}</p>
                            <Divider className="mt-0"/>
                            <div dangerouslySetInnerHTML={{__html: option[`value_${lang}`]}}  className="pre-line p-2 pt-0"/>
                        </Button>
                    </Col>
                ))}
            </Row>
        </CustomModal>
    )
}
