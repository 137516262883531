import {
    CompassFilled,
    DashboardFilled,
    EnvironmentOutlined,
    GlobalOutlined,
    MailOutlined, ReadFilled,
    UnlockFilled,
    UserOutlined,
    NodeIndexOutlined,
} from "@ant-design/icons";
import HeIcon from "../../assets/icon/he_icon_white.png";
import React, {useEffect, useState} from "react";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import {checkOnePermission, checkPermission} from "../../global/permissions";
import {permission_data} from "../../global/permissions_data";
import {Tiers} from "../../pages/customers/tiers/Tiers";
import {getUserType, isSuperAdmin, logout} from "../../global/auth_functions";
import {
    AnalyticsIcon,
    AdvertisementsIcon,
    AgencyIcon, BlogIcon, BookingIcon,
    CategoryIcon, ConsultationIcon, ConversionRateIcon,
    CustomerIcon, FinanceIcon, GlobalDataIcon,
    ListIcon, MediaIcon, OnlinePaymentIcon,
    PackageIcon, PaperIcon, PaymentMethodIcon,
    ProductIcon, ProductSalesIcon, ServiceChargeIcon, TestimonialIcon,
    VoucherIcon, BookingFinanceIcon
} from "../../global/components/Icons";
import {Badge} from "rsuite";
import {httpRequestWithAuth} from "../../global/axios_instance";
import {getBookingCountURL} from "../../global/urls";
import Hide from "../../global/components/Hide";


const SidebarMenu = (props) => {
    const { side_menu_toggle, setSideMenuToggle } = props;

    const {slug} = props;
    const [user_type] = useState(getUserType());
    const [booking_pending_approval_count, setBookingsPendingApproval] = useState(0);

    const [is_super_admin] = useState(isSuperAdmin());
    const  is_agent = user_type === "Agent";
    const  is_admin = user_type === "Admin";

    useEffect(()=>{
        const getBookingPendingCount = async ()=>{
            const response = await httpRequestWithAuth(getBookingCountURL, "GET", null, {status: "Pending Approval"})

            if (response.status){
                setBookingsPendingApproval(response.data);
            }
        }

        getBookingPendingCount();
    },[])

    const onLogout = () =>{
        logout();
        props.history.push(`/${props.slug}/signin`)
    }
    const [perm] = useState({
        is_agent,
        is_admin,
        view_rule: checkPermission(permission_data.rule.view),
        view_user: checkPermission(permission_data.user.view),
        view_agency: checkPermission(permission_data.agency.view) && is_admin,
        view_agency_contract: checkPermission(permission_data.agency_contract.view),
        view_agency_document: checkPermission(permission_data.agency_document.view),
        view_agency_menu: checkOnePermission([permission_data.agency.view]),
        view_location: checkOnePermission([permission_data.country.view, permission_data.city.view]),
        view_country: checkPermission(permission_data.country.view),
        view_city: checkPermission(permission_data.city.view),
        view_he_general: checkOnePermission([permission_data.he_media.view, permission_data.trusted_partner.view, permission_data.advertisement.view, permission_data.language.view, permission_data.global_data.view]),
        view_he_media: checkPermission(permission_data.he_media.view),
        view_global_data: checkPermission(permission_data.global_data.view),
        view_advertisement: checkPermission(permission_data.advertisement.view),
        view_trusted_partner: checkPermission(permission_data.trusted_partner.view),
        view_language: checkPermission(permission_data.language.view),
        view_finance: checkOnePermission([permission_data.service_charge.view, permission_data.myfatoorah_payment_method.view, permission_data.online_payment.view, permission_data.currency.view, permission_data.conversion_rate.view, permission_data.booking.view_booking_finance]),
        view_conversion_rate: checkPermission(permission_data.conversion_rate.view),
        view_service_charge: checkPermission(permission_data.service_charge.view),
        view_booking_finance: checkPermission(permission_data.booking.view_booking_finance),
        view_currency: checkPermission(permission_data.currency.view),
        view_online_payment: checkPermission(permission_data.online_payment.view),
        view_myfatoorah_payment_method: checkPermission(permission_data.myfatoorah_payment_method.view),
        view_package_menu: checkOnePermission([permission_data.package_inclusions.view, permission_data.package_category.view, permission_data.package.view, permission_data.package.view_analytics, permission_data.guru.view_analytics]),
        view_guru: checkPermission(permission_data.guru.view),
        view_package_category: checkPermission(permission_data.package_category.view),
        view_package_inclusions: checkPermission(permission_data.package_inclusions.view),
        view_voucher: checkPermission(permission_data.voucher.view),
        view_customers_menu: checkOnePermission([permission_data.tier.view]),
        view_tier: checkPermission(permission_data.tier.view),
        view_customer: checkPermission(permission_data.customer.view),
        view_package: checkPermission(permission_data.package.view),
        view_package_analytics: checkPermission(permission_data.package.view_analytics),
        view_booking: checkPermission(permission_data.booking.view),
        view_consultations: checkOnePermission([permission_data.consultation_request.view,]),
        view_consultation_request: checkPermission(permission_data.consultation_request.view),
        view_consultation_response: checkPermission(permission_data.consultation_response.view),
        view_contact_us: checkPermission(permission_data.contact_us.view),
        view_join_us_request: checkPermission(permission_data.join_us_request.view),
        view_product: checkPermission(permission_data.product.view),
        view_product_sale: checkPermission(permission_data.product_sale.view),
        view_invoice: checkPermission(permission_data.invoice.view),
        view_blog: checkPermission(permission_data.blog.view),
        view_tour: checkPermission(permission_data.tour.view),
        view_testimonial: checkPermission(permission_data.testimonial.view),
        view_reports_menu: checkOnePermission([permission_data.reports.view_top_packages_report, permission_data.reports.view_bookings_over_months, permission_data.reports.view_bookings_revenue_over_months]),
    });


    const menuItems = [
        {
            key: "dashboard",
            className: "text-white fw-bold font-size-15",
            icon: <DashboardFilled className="text-white font-size-18 fw-bold me-2"/>,
            label: <Link className="text-white" to={`/${slug}/dashboard`} onClick={setSideMenuToggle}>Dashboard</Link>,
        },
        (perm.is_admin && perm.view_reports_menu) && {
            key: "reports",
            className: "text-white fw-bold font-size-15",
            icon: <DashboardFilled className="text-white font-size-18 fw-bold me-2"/>,
            label: <Link className="text-white" to={`/${slug}/reports`} onClick={setSideMenuToggle}>Reports</Link>,
        },
        perm.view_rule && {
            key: "rules",
            className: "text-white fw-bold font-size-15",
            icon: <UnlockFilled className="text-white font-size-18 fw-bold me-2"/>,
            label: <Link className="text-white" to={`/${slug}/rules`} onClick={setSideMenuToggle}>Rules</Link>,
        },
        perm.view_user && {
            key: "users",
            className: "text-white fw-bold font-size-15",
            icon: <UserOutlined className="text-white font-size-18 fw-bold me-2"/>,
            label: <Link className="text-white" to={`/${slug}/users`} onClick={setSideMenuToggle}>{is_agent?"Agency Users":"Users"}</Link>,
        },
        perm.view_agency && {
            key: "agencies",
            className: "text-white fw-bold font-size-15",
            icon: <AgencyIcon className="text-white font-size-18 fw-bold me-2"/>,
            label: <Link className="text-white" to={`/${slug}/agencies`} onClick={setSideMenuToggle}>Agencies</Link>,
        },
        perm.view_customers_menu && {
            key: "customers",
            className: "text-white fw-bold font-size-15",
            icon: <CustomerIcon className="text-white font-size-18 fw-bold me-2"/>,
            label: "Customers",
            children: [
                perm.view_customer && {
                    key: "Customers",
                    className: "text-white fw-bold font-size-15",
                    icon: (
                        <ListIcon className="text-white font-size-18 fw-bold me-2"/>
                    ),
                    label: <Link className="text-white" to={`/${slug}/customers`} onClick={setSideMenuToggle}>List</Link>,
                },
                perm.view_tier && {
                    key: "Tiers",
                    className: "text-white fw-bold font-size-15",
                    icon: (
                        <GlobalOutlined className="text-white font-size-18 fw-bold me-2"/>
                    ),
                    label: <Link className="text-white" to={`/${slug}/tiers`} onClick={setSideMenuToggle}>Tiers</Link>,
                },

            ],
        },

        (perm.is_admin && perm.view_blog) && {
            key: "blogs",
            className: "text-white fw-bold font-size-15",
            icon: <BlogIcon className="text-white font-size-18 fw-bold me-2"/>,
            label: <Link className="text-white" to={`/${slug}/blogs`} onClick={setSideMenuToggle}>Blog Articles</Link>,
        },

        perm.view_booking && {
            key: "bookings",
            className: "text-white fw-bold font-size-15",
            icon: <BookingIcon className="text-white font-size-18 fw-bold me-2"/>,
            label: <Link className="text-white d-flex justify-content-between  align-items-center" to={`/${slug}/bookings`} onClick={setSideMenuToggle}>
                <span>
                Bookings
                </span>

                <Hide hide={booking_pending_approval_count === 0}>
                <Badge content={booking_pending_approval_count} className="me-4">
                    <div/>
                </Badge>
                </Hide>

            </Link>,
        },

        (perm.is_agent && perm.view_package) && {
            key: "packages-list",
            className: "text-white fw-bold font-size-15",
            icon: (
                <PackageIcon className="text-white font-size-18 fw-bold me-2"/>
            ),
            label: <Link className="text-white" to={`/${slug}/packages`}  onClick={setSideMenuToggle}>Packages</Link>,
        },
        (perm.is_admin && perm.view_package_menu) && {
            key: "packages",
            className: "text-white fw-bold font-size-15",
            icon: <PackageIcon className="text-white font-size-18 fw-bold me-2"/>,
            label: "Packages",
            children: [
                perm.view_package && {
                    key: "packages-list",
                    className: "text-white fw-bold font-size-15",
                    icon: (
                        <ListIcon className="text-white font-size-18 fw-bold me-2"/>
                    ),
                    label: <Link className="text-white" to={`/${slug}/packages`}  onClick={setSideMenuToggle}>List</Link>,
                },
                perm.view_guru && {
                    key: "gurus",
                    className: "text-white fw-bold font-size-15",
                    icon: (
                        <CustomerIcon className="text-white font-size-18 fw-bold me-2"/>
                    ),
                    label: <Link className="text-white" to={`/${slug}/gurus`}  onClick={setSideMenuToggle}>Gurus</Link>,
                },
                perm.view_package_category && {
                    key: "packages-categories",
                    className: "text-white fw-bold font-size-15",
                    icon: (
                        <CategoryIcon className="text-white font-size-18 fw-bold me-2"/>
                    ),
                    label: <Link className="text-white" to={`/${slug}/packages-categories`}  onClick={setSideMenuToggle}>Categories</Link>,
                },
                perm.view_package_inclusions && {
                    key: "packages-inclusions",
                    className: "text-white fw-bold font-size-15",
                    icon: (
                        <NodeIndexOutlined className="text-white font-size-18 fw-bold me-2"/>
                    ),
                    label: <Link className="text-white" to={`/${slug}/packages-inclusions`}  onClick={setSideMenuToggle}>Inclusions</Link>,
                },
                perm.view_package_analytics && {
                    key: "packages-analytics",
                    className: "text-white fw-bold font-size-15",
                    icon: (
                        <AnalyticsIcon className="text-white font-size-18 fw-bold me-2"/>
                    ),
                    label: <Link className="text-white" to={`/${slug}/packages-analytics`}  onClick={setSideMenuToggle}>Analytics</Link>,
                },
            ],
        },

        (perm.is_admin && perm.view_product_sale) && {
            key: "products-sales",
            className: "text-white fw-bold font-size-15",
            icon: <ProductSalesIcon className="text-white font-size-18 fw-bold me-2"/>,
            label: <Link className="text-white" to={`/${slug}/products-sales`} onClick={setSideMenuToggle}>Products Sales</Link>,
        },

        (perm.is_admin && perm.view_product) && {
            key: "products",
            className: "text-white fw-bold font-size-15",
            icon: <ProductIcon className="text-white font-size-18 fw-bold me-2"/>,
            label: <Link className="text-white" to={`/${slug}/products`} onClick={setSideMenuToggle}>Products</Link>,
        },

        perm.view_consultations && {
            key: "consultations",
            className: "text-white fw-bold font-size-15",
            icon: <ConsultationIcon className="text-white font-size-18 fw-bold me-2"/>,
            // label: "Consultations",
            label: <Link className="text-white" to={`/${slug}/consultations`} onClick={setSideMenuToggle}>Consultations</Link>,

            // children: [
            //     perm.view_consultation_request && {
            //         key: "consultations-requests",
            //         className: "text-white fw-bold font-size-15",
            //         icon: (
            //             <GlobalOutlined className="text-white font-size-18 fw-bold me-2"/>
            //         ),
            //         label: <Link className="text-white" to={`/${slug}/consultations/requests`}>Requests</Link>,
            //     },
            //     perm.view_consultation_response && {
            //         key: "consultations-responses",
            //         className: "text-white fw-bold font-size-15",
            //         icon: (
            //             <GlobalOutlined className="text-white font-size-18 fw-bold me-2"/>
            //         ),
            //         label: <Link className="text-white" to={`/${slug}/consultations/responses`}>Responses</Link>,
            //     },
            // ],
        },

        (perm.is_admin && perm.view_tour) && {
            key: "tours",
            className: "text-white fw-bold font-size-15",
            icon: <BlogIcon className="text-white font-size-18 fw-bold me-2"/>,
            label: <Link className="text-white" to={`/${slug}/tours`} onClick={setSideMenuToggle}>Tours</Link>,
        },

        (perm.is_admin && perm.view_invoice) && {
            key: "invoices",
            className: "text-white fw-bold font-size-15",
            icon: <ReadFilled className="text-white font-size-18 fw-bold me-2"/>,
            label: <Link className="text-white" to={`/${slug}/invoices`} onClick={setSideMenuToggle}>Invoices</Link>,
        },


        perm.view_voucher && {
            key: "vouchers",
            className: "text-white fw-bold font-size-15",
            icon: <VoucherIcon className="text-white font-size-18 fw-bold me-2"/>,
            label: "Vouchers",
            children: [
                perm.view_voucher && {
                    key: "Discount Code",
                    className: "text-white fw-bold font-size-15",
                    icon: (
                        <VoucherIcon className="text-white font-size-18 fw-bold me-2"/>
                    ),
                    label: <Link className="text-white" to={`/${slug}/vouchers/discount-code`} onClick={setSideMenuToggle}>Discount Codes</Link>,
                },
                perm.view_voucher && {
                    key: "Digital Gift",
                    className: "text-white fw-bold font-size-15",
                    icon: (
                        <VoucherIcon className="text-white font-size-18 fw-bold me-2"/>
                    ),
                    label: <Link className="text-white" to={`/${slug}/vouchers/digital-gift`} onClick={setSideMenuToggle}>Digital Gift</Link>,
                },
            ],
        },

        perm.view_contact_us && {
            key: "contact_us",
            className: "text-white fw-bold font-size-15",
            icon: <MailOutlined className="text-white font-size-18 fw-bold me-2"/>,
            label: <Link className="text-white" to={`/${slug}/contact-us`} onClick={setSideMenuToggle}>Contact Us</Link>,
        },

        perm.view_join_us_request && {
            key: "join-us-requests",
            className: "text-white fw-bold font-size-15",
            icon: <MailOutlined className="text-white font-size-18 fw-bold me-2"/>,
            label: <Link className="text-white" to={`/${slug}/career/join-us-requests`} onClick={setSideMenuToggle}>Join Us Requests</Link>,
        },

        (perm.is_admin && perm.view_testimonial) && {
            key: "packages-testimonials",
            className: "text-white fw-bold font-size-15",
            icon: <TestimonialIcon className="text-white font-size-18 fw-bold me-2"/>,
            label: <Link className="text-white" to={`/${slug}/testimonials`}  onClick={setSideMenuToggle}>Testimonials</Link>,
        },
        (perm.is_agent && perm.view_agency_menu) && {
            key: "agency-info",
            className: "text-white fw-bold font-size-15",
            icon: <AgencyIcon className="text-white font-size-18 fw-bold me-2"/>,
            label: <Link className="text-white" to={`/${slug}/agency-info`} onClick={setSideMenuToggle}>Agency Info.</Link>,
        },

        perm.view_location && {
            key: "locations",
            className: "text-white fw-bold font-size-15",
            icon: <CompassFilled className="text-white font-size-18 fw-bold me-2"/>,
            label: "Locations",
            children: [
                perm.view_country && {
                    key: "Countries",
                    className: "text-white fw-bold font-size-15",
                    icon: (
                        <GlobalOutlined className="text-white font-size-18 fw-bold me-2"/>
                    ),
                    label: <Link className="text-white" to={`/${slug}/countries`} onClick={setSideMenuToggle}>Countries</Link>,
                },
                perm.view_city && {
                    key: "Cities",
                    className: "text-white fw-bold font-size-15",
                    icon: (
                        <EnvironmentOutlined className="text-white font-size-18 fw-bold me-2"/>
                    ),
                    label: <Link className="text-white" to={`/${slug}/cities`} onClick={setSideMenuToggle}>Cities</Link>,
                },
            ],
        },


        perm.view_he_general && {
            key: "he-general",
            className: "text-white fw-bold font-size-15",
            icon: (
                <img src={HeIcon} width={20} className="text-white font-size-18 fw-bold me-2"/>
            ),
            label: "HE General",
            children: [
                perm.view_advertisement && {
                    key: "advertisement",
                    className: "text-white fw-bold font-size-15",
                    icon: (
                        <AdvertisementsIcon className="text-white font-size-18 fw-bold me-2"/>
                    ),
                    label: <Link className="text-white" to={`/${slug}/advertisements`} onClick={setSideMenuToggle}>Advertisement</Link>,
                },
                perm.view_trusted_partner && {
                    key: "trusted_partner",
                    className: "text-white fw-bold font-size-15",
                    icon: (
                        <AdvertisementsIcon className="text-white font-size-18 fw-bold me-2"/>
                    ),
                    label: <Link className="text-white" to={`/${slug}/trusted-partners`} onClick={setSideMenuToggle}>Trusted Partners</Link>,
                },
                perm.view_language && {
                    key: "languages",
                    className: "text-white fw-bold font-size-15",
                    icon: (
                        <GlobalOutlined className="text-white font-size-18 fw-bold me-2"/>
                    ),
                    label: <Link className="text-white" to={`/${slug}/languages`} onClick={setSideMenuToggle}>Languages</Link>,
                },
                perm.view_he_media && {
                    key: "media",
                    className: "text-white fw-bold font-size-15",
                    icon: (
                        <MediaIcon className="text-white font-size-18 fw-bold me-2"/>
                    ),
                    label: <Link className="text-white" to={`/${slug}/he-media`} onClick={setSideMenuToggle}>Media</Link>,
                },
                perm.view_global_data && {
                    key: "GlobalData",
                    className: "text-white fw-bold font-size-15",
                    icon: (
                        <GlobalDataIcon className="text-white font-size-18 fw-bold me-2"/>
                    ),
                    label: <Link className="text-white" to={`/${slug}/global-data`} onClick={setSideMenuToggle}>Global Data</Link>,
                },
            ],
        },

        perm.view_finance && {
            key: "finance",
            className: "text-white fw-bold font-size-15",
            icon: <FinanceIcon className="text-white font-size-18 fw-bold me-2"/>,
            label: "Finance",
            children: [
                perm.view_booking_finance && {
                    key: "Booking-Finance",
                    className: "text-white fw-bold font-size-15",
                    icon: (
                        <BookingFinanceIcon className="text-white font-size-18 fw-bold me-2"/>
                    ),
                    label: <Link className="text-white" to={`/${slug}/bookings-finance`} onClick={setSideMenuToggle}>Booking Finance</Link>,
                },
                perm.view_service_charge && {
                    key: "service-charges",
                    className: "text-white fw-bold font-size-15",
                    icon: (
                        <ServiceChargeIcon className="text-white font-size-18 fw-bold me-2"/>
                    ),
                    label: <Link className="text-white" to={`/${slug}/service-charges`} onClick={setSideMenuToggle}>Service Charges</Link>,
                },
                // perm.view_currency && {
                //     key: "currencies",
                //     className: "text-white fw-bold font-size-15",
                //     icon: (
                //         <MailOutlined className="text-white font-size-18 fw-bold me-2"/>
                //     ),
                //     label: <Link className="text-white" to={`/${slug}/currencies`} onClick={setSideMenuToggle}>Currencies</Link>,
                // },
                perm.view_conversion_rate && {
                    key: "conversion-rates",
                    className: "text-white fw-bold font-size-15",
                    icon: (
                        <ConversionRateIcon className="text-white font-size-18 fw-bold me-2"/>
                    ),
                    label: <Link className="text-white" to={`/${slug}/conversion-rates`} onClick={setSideMenuToggle}>Conversion Rates</Link>,
                },
                perm.view_myfatoorah_payment_method && {
                    key: "myfatoorah-payment-methods",
                    className: "text-white fw-bold font-size-15",
                    icon: (
                        <PaymentMethodIcon className="text-white font-size-18 fw-bold me-2"/>
                    ),
                    label: <Link className="text-white" to={`/${slug}/myfatoorah-payment-methods`} onClick={setSideMenuToggle}>Payment Methods</Link>,
                },
                perm.view_online_payment && {
                    key: "online-payments",
                    className: "text-white fw-bold font-size-15",
                    icon: (
                        <OnlinePaymentIcon className="text-white font-size-18 fw-bold me-2"/>
                    ),
                    label: <Link className="text-white" to={`/${slug}/online-payments`} onClick={setSideMenuToggle}>Online Payments</Link>,
                },
            ],
        },

        (is_super_admin && is_admin) && {
            key: "it-support",
            className: "text-white fw-bold font-size-15",
            icon: <CompassFilled className="text-white font-size-18 fw-bold me-2"/>,
            label: <Link className="text-white" to={`/${slug}/it-support`} onClick={setSideMenuToggle}>IT Support</Link>,
        },
        {
            key: "logout",
            className: "text-white fw-bold font-size-15 mt-4",
            icon: <CompassFilled className="text-white font-size-18 fw-bold me-2"/>,
            // label: "Consultations",
            label: <Link className="text-white" onClick={onLogout}>Logout</Link>,

            // children: [
            //     perm.view_consultation_request && {
            //         key: "consultations-requests",
            //         className: "text-white fw-bold font-size-15",
            //         icon: (
            //             <GlobalOutlined className="text-white font-size-18 fw-bold me-2"/>
            //         ),
            //         label: <Link className="text-white" to={`/${slug}/consultations/requests`}>Requests</Link>,
            //     },
            //     perm.view_consultation_response && {
            //         key: "consultations-responses",
            //         className: "text-white fw-bold font-size-15",
            //         icon: (
            //             <GlobalOutlined className="text-white font-size-18 fw-bold me-2"/>
            //         ),
            //         label: <Link className="text-white" to={`/${slug}/consultations/responses`}>Responses</Link>,
            //     },
            // ],
        },
    ];


    return (
        <Menu
            className="custom text-white fw-bold font-size-17 pb-4"
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            mode="inline"
            items={menuItems}
        />
    );
};
export default SidebarMenu;
