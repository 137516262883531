import {Button, Col, Image, Row} from "antd";
import {CustomDynamicTable} from "../../../global/components/CustomDynamicTable";
import React, {useState} from "react";
import {GurusURL} from "../../../global/urls";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {isValidValue, openNotification, showError, showInfo} from "../../../global/helper_functions";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import {AddButton} from "../../../global/components/AddButton";
import {GuruFormModal} from "./components/GuruFormModal";
import Hide from "../../../global/components/Hide";

export const Gurus = props =>{
    const [open_from_data, setOpenFormData] = useState(false);
    const [form_data, setFormData] = useState({});
    const [reload, setReload] = useState(false);

    const [guru_perm] = useState({
        delete: checkPermission(permission_data.guru.delete),
        edit: checkPermission(permission_data.guru.edit),
        add: checkPermission(permission_data.guru.add),
    });

    const [columns] = useState([
        {
            title: "Ref No.",
            dataIndex: "ref_no",
            width: "8%"
        },
        {
            title: "Image",
            dataIndex: "display_image",
            width: "15%",
            align: "center",
            render:(value)=>(
                <Image
                    src={value}
                />
            )
        },
        {
            title: "Name En",
            dataIndex: "name_en",
            width: "25%"
        },
        {
            title: "Name Ar",
            dataIndex: "name_ar",
            width: "25%"
        },
        {
            title: "Email",
            dataIndex: "email",
            width: "25%"
        },

    ]);

    const handelOpenFormData = (data={})=>{
        setFormData(data)
        setOpenFormData(true)
    }

    const handelCloseFormData = (reload_data=false)=>{
        setFormData({});
        setOpenFormData(false);

        if (reload_data){
            setReload(Math.random());
        }

    }

    const onDelete = async (row_data) =>{
        let response = await httpRequestWithAuth(`${GurusURL}${row_data.id}`, "DELETE");
        if (response.status){
            openNotification("success", "Delete Guru", `${row_data.name_en} guru has been deleted`)
        }else{
            openNotification("error", "Delete Guru", response.error_feedback.non_field_errors)
        }
    }

    const onEdit = async (row_data) =>{
        handelOpenFormData(row_data)
    }

    const onAdd = async () =>{
        handelOpenFormData()
    }

    const freeAction = (
        <>
            <AddButton onClick={onAdd}>Add Guru</AddButton>
        </>
    );

    return(
        <Row>
            <Hide hide={!open_from_data}>
                <GuruFormModal
                    open={open_from_data}
                    data={form_data}
                    handleModelClose={handelCloseFormData}
                />
            </Hide>

            <Col xs={24} className="paper">
                <CustomDynamicTable
                    title="Gurus"
                    reload_data={reload}
                    columns={columns}
                    fetch_url={GurusURL}
                    free_action={freeAction}
                    onDelete={guru_perm.delete && onDelete}
                    onEdit={guru_perm.edit && onEdit}
                />
            </Col>
        </Row>
    )
}
