import {Button, Col, Empty, Row, Tabs, Tooltip} from "antd";
import React, {useEffect, useState} from "react";
import {PackagesItineraryURL, PackagesPendingChangesForModalURL} from "../../../global/urls";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {
    generateRandomString,
    isValidValue,
    openNotification,
    showConfirm,
    upperCaseView
} from "../../../global/helper_functions";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import {AddButton} from "../../../global/components/AddButton";
import Hide from "../../../global/components/Hide";
import {package_status, PRIMARY_COLOR} from "../../../global/variables";
import {InfoIconGreenFilled} from "../../../global/components/Icons";
import {CustomTabPane} from "../../../global/components/CustomTabPane";
import {CustomStatusView} from "../../../global/components/CustomStatusView";
import {CustomTextField} from "../../../global/components/fields/CustomTextField";
import {CustomTextAreaField} from "../../../global/components/fields/CustomTextAreaField";
import {CityAutoComplete} from "../../../global/components/fields/CityAutoComplete";
import CustomSelectField from "../../../global/components/fields/CustomSelectField";
import ViewActionsDetails from "../../../global/components/ViewActionsDetails";
import {ActionButton} from "../../../global/components/ActionButton";


export const PackageItinerary = props => {
    const package_id = props.package_id || "";
    const slug = props.slug || "";
    const language = props.language || "en";
    const package_data = props.package_data || {};
    const {is_admin, has_pending_status, approve_pending_status_loading, approveAllPendingStatus} = props;
    const countries = package_data.countries_id;
    const package_days_no = package_data.package_days_no;

    const [loading, setLoading] = useState(false);
    const [fetch_loading, setFetchLoading] = useState(false);
    const [package_itinerary_perm] = useState({
        delete: checkPermission(permission_data.package_itinerary.delete),
        edit: checkPermission(permission_data.package_itinerary.edit),
        add: checkPermission(permission_data.package_itinerary.add),
    });

    const [data, setData] = useState([]);
    const [current_data, setCurrentData] = useState({});
    const [current_option, setCurrentOption] = useState("0");
    const [helper_text, setHelperText] = useState({});
    const [new_pending_changes, setNewPendingChanges] = useState({});
    const [day_no_options, setDayNoOptions] = useState([]);
    const is_new = !isValidValue(current_data.id) || (current_data.id || "").startsWith("#")


    const fetchData = async () => {
        setFetchLoading(true);
        const response = await httpRequestWithAuth(PackagesItineraryURL, "GET", null, {package: package_id})

        if (response.status) {
            setData(response.data);
            if (!isValidValue(current_data.id) && response.data.length > 0) {
                setCurrentData(response.data[0]);
            }
        }
        setFetchLoading(false);
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        let options = [];

        for (let i = 1; i <= package_days_no; i++) {
            options.push({title: `${i}`, value: `${i}`});
        }
        setDayNoOptions(options)
    }, [package_days_no])

    useEffect(() => {
        if (!is_new) {
            getPendingChanges()
        }
    }, [current_data.id])


    const onDelete = async () => {
        const afterDelete = () => {
            let temp_data = [...data];
            temp_data.splice(parseInt(current_option), 1);
            setData(temp_data)
            setCurrentOption("0");
            setCurrentData(temp_data.length > 0 ? temp_data[0] : {})
        }

        if (!is_new) {
            let response = await httpRequestWithAuth(`${PackagesItineraryURL}${current_data.id}`, "DELETE");
            if (response.status) {
                openNotification("success", "Delete Package Itinerary", `${current_data.title_en} package itinerary has been deleted`)
                afterDelete()
            } else {
                openNotification("error", "Delete Package Itinerary", response.error_feedback.non_field_errors);
            }
        } else {
            afterDelete()
        }


    }


    const onAdd = async () => {
        if (data.length === 0 || !(data[data.length - 1].id || "").startsWith("#")) {
            const new_data = {
                PackageId: package_id,
                id: `#${generateRandomString(8)}`,
                title_en: "New Itinerary",
                status: is_admin?"Approved":"New",
            }
            setData([
                ...data,
                new_data
            ])

            setCurrentData(new_data);
            setCurrentOption(data.length + "");
        }

    }

    const onApproveAllPending = () => {
        fetchData()
    }

    const approveAllPendingStatusBtn = () => {
        approveAllPendingStatus("PackageItineraries", onApproveAllPending)
    }

    const goToPendingChanges = (row_data) => {
        props.handleTabsChange("7", {search: row_data.id});
    }

    const getPendingChanges = async () => {
        let response = await httpRequestWithAuth(PackagesPendingChangesForModalURL, "GET", null, {
            model_name: "PackageItineraries",
            package: package_id,
            object_id: current_data.id
        });
        if (response.status) {
            setNewPendingChanges(response.data)
        }
    }

    const handleItineraryChange = (value) => {
        const temp_data = [...data];
        temp_data[current_option] = {...current_data};
        setData(temp_data);
        setCurrentOption(value);
        setCurrentData(temp_data[value]);
        setHelperText({});
    }


    const handleFieldChange = (name, value) => {
        let temp_data = {...current_data};
        temp_data[name] = value;
        setCurrentData(temp_data);
    }


    const onSave = async () => {
        setHelperText({});

        await setLoading(true);

        let response;

        if (is_new) {
            response = await httpRequestWithAuth(PackagesItineraryURL, "POST", current_data);
        } else {
            response = await httpRequestWithAuth(`${PackagesItineraryURL}${current_data.id}`, "PUT", current_data);
        }

        if (response.status) {
            if (is_new) {
                openNotification("success", "Add Package Itinerary", `${current_data[`title_${language}`]} package itinerary has been added`)
            } else {
                openNotification("success", "Update Package Itinerary", response.data.msg || `${current_data[`title_${language}`]} package itinerary has been updated`)
            }

            if (is_admin){
                const temp_data = [...data];
                temp_data[current_option] = {...response.data};
                setData(temp_data);
                setCurrentData(response.data)
            }

        } else {
            setHelperText(response.error_feedback);
        }

        await setLoading(false);
    }


    const handleDelete = () => {
        showConfirm("Delete Package Itinerary", `Are you sure to delete ${current_data[`title_${language}`]} package option ?`, onDelete);
    }

    const onCancel = () => {
        props.history.push(`/${slug}/packages`);
    }
    return (
        <div className="paper pt-0 w-100">
            <Row>

                <Col xs={24} className="d-md-flex justify-content-between align-items-center">

                    <h2 className="font-size-22 mb-2">
                        <label className="text-start">
                            Itinerary
                            <Tooltip
                                className="ms-1"
                                placement="topLeft"
                                title="Consists of the photos displayed for the package on the website"
                                color={PRIMARY_COLOR}
                                arrowPointAtCenter
                            >
                               <span className="p-0 m-0">
                                   <InfoIconGreenFilled/>
                               </span>
                            </Tooltip>
                            <br/>
                            <span className="font-size-13 text-black-50 mb-0">Sort of itinerary to view for customer depend on --> Day No, Order then Create datetime</span>
                        </label>
                    </h2>

                    <Hide hide={loading}>

                        <div className="d-flex justify-content-center">
                            <div className="d-md-flex w-100">

                                <Hide hide={!has_pending_status || !is_admin}>
                                    <Button
                                        block
                                        type="primary"
                                        className="mb-1 m-md-1 justify-content-center"
                                        onClick={approveAllPendingStatusBtn}
                                        loading={approve_pending_status_loading}
                                    >
                                        Approve All Pending Status
                                    </Button>
                                </Hide>

                                <AddButton className="mb-1 m-md-1" onClick={onAdd}>Add New Itinerary</AddButton>

                            </div>
                        </div>
                    </Hide>
                </Col>

                <Hide hide={fetch_loading}>
                    <Col xs={24}>
                        <Tabs activeKey={current_option} onChange={handleItineraryChange}

                        >
                            {data.map((Option, index) => (
                                <CustomTabPane tab={(
                                    <>
                                        {`Day ${Option.day_no || "#"} - ${Option[`title_${language}`] || ""}`}
                                        <CustomStatusView className="ms-2" value={Option.status}/>
                                    </>

                                )} key={index + ""}/>
                            ))}
                        </Tabs>
                    </Col>
                </Hide>

                <Hide hide={data.length !== 0}>
                    <Col xs={24}>
                        <Empty/>
                    </Col>
                </Hide>

                <Hide hide={loading || data.length === 0}>
                    <Col xs={24}>
                        <Row gutter={[12, 10]}>

                            <Col xs={24} md={24}>
                                <CustomTextField
                                    required
                                    label={`Title ${upperCaseView(language)}`}
                                    name={`title_${language}`}
                                    value={current_data[`title_${language}`]}
                                    onChange={handleFieldChange}
                                    error={helper_text[`title_${language}`] !== undefined}
                                    helper_text={helper_text[`title_${language}`]}
                                    warning_tooltip={new_pending_changes[`title_${language}`]}
                                    warning_tooltip_title="New Value Waiting Approve:"
                                />
                            </Col>

                            <Col xs={24} md={24}>
                                <CustomTextAreaField
                                    label={`Description ${upperCaseView(language)}`}
                                    name={`description_${language}`}
                                    value={current_data[`description_${language}`]}
                                    onChange={handleFieldChange}
                                    error={helper_text[`description_${language}`] !== undefined}
                                    helper_text={helper_text[`description_${language}`]}
                                    warning_tooltip={new_pending_changes[`description_${language}`]}
                                    warning_tooltip_title="New Value Waiting Approve:"
                                />
                            </Col>


                            <Col xs={24} md={12}>
                                <CityAutoComplete
                                    group_key="country_name_en"
                                    include_country_name
                                    countries={countries}
                                    label="City"
                                    name="CityId"
                                    value={current_data.CityId}
                                    onChange={handleFieldChange}
                                    error={helper_text.CityId !== undefined}
                                    helper_text={helper_text.CityId}
                                    warning_tooltip={new_pending_changes[`CityId`]}
                                    warning_tooltip_title="New Value Waiting Approve:"
                                />
                            </Col>

                            <Col xs={24} md={12}>
                                <CustomSelectField
                                    required
                                    label="Day No."
                                    name="day_no"
                                    value={current_data.day_no}
                                    onChange={handleFieldChange}
                                    error={helper_text.day_no !== undefined}
                                    helper_text={helper_text.day_no}
                                    options={day_no_options}
                                    warning_tooltip={new_pending_changes[`day_no`]}
                                    warning_tooltip_title="New Value Waiting Approve:"
                                />
                            </Col>

                            <Col xs={24} md={12}>
                                <CustomTextField
                                    required
                                    label="order"
                                    name="order"
                                    value={current_data.order}
                                    onChange={handleFieldChange}
                                    error={helper_text.order !== undefined}
                                    helper_text={helper_text.order}
                                    type="number"
                                    warning_tooltip={new_pending_changes[`order`]}
                                    warning_tooltip_title="New Value Waiting Approve:"
                                />
                            </Col>


                            <Col xs={24} md={12}>
                                <CustomSelectField
                                    disabled={!is_admin}
                                    label="Status"
                                    name="status"
                                    value={current_data.status}
                                    onChange={handleFieldChange}
                                    error={helper_text.status !== undefined}
                                    helper_text={helper_text.status}
                                    options={package_status}
                                    warning_tooltip={new_pending_changes[`status`]}
                                    warning_tooltip_title="New Value Waiting Approve:"
                                />
                            </Col>

                            <Hide hide={is_new}>
                                <ViewActionsDetails data={current_data}/>
                            </Hide>

                            <Col xs={24}>
                                <ActionButton
                                    error_msg={helper_text.non_field_errors}
                                    onDelete={handleDelete}
                                    onCancel={onCancel}
                                    allow_delete={package_itinerary_perm.delete}
                                    allow_save={isValidValue(data.id) ? package_itinerary_perm.edit : package_itinerary_perm.add}
                                    onSave={onSave}
                                />
                            </Col>
                        </Row>

                    </Col>
                </Hide>

                {/*<Col xs={24} className="mt-3">*/}
                {/*    <ModelActionButton*/}
                {/*        hide_icon*/}
                {/*        cancel_label="Back"*/}
                {/*        onCancel={props.onBack}*/}
                {/*        saveLabel="Next"*/}
                {/*        onSave={props.onNext}*/}
                {/*    />*/}
                {/*</Col>*/}
            </Row>
        </div>
    )
}
