import React, {useEffect, useState} from 'react';
import {Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';

import {httpRequestWithAuth} from "../../../global/axios_instance";
import {GetAgeScaleURL} from "../../../global/urls";
import {isValidValue} from "../../../global/helper_functions";
import {PRIMARY_COLOR} from "../../../global/variables";
import {Badge, Button} from "antd";
import {FunnelPlotFilled} from "@ant-design/icons";
import Hide from "../../../global/components/Hide";
import FilterModal from "./components_modal/FilterModal";
import {OverlayTrigger, Tooltip as RBTooltip} from "react-bootstrap";
import {Loading} from "../../../global/components/Loading";
import {checkPermission} from "../../../global/permissions";
import {permission_data} from "../../../global/permissions_data";

const getPath = (x, y, width, height) => `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
          C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
          Z`;

const TriangleBar = (props) => {
    const {fill, x, y, width, height} = props;
    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill}/>;
};


const ExperienceAgeScale = props => {
    const booking_type = "Experience";
    const [data, setData] = useState([]);
    const [has_perm] = useState(checkPermission(permission_data.reports.view_experience_age_scale));
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState({});
    const [open_filter_modal, setOpenFilterModal] = useState(false);
    const [filter_count, setFilterCount] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            if (has_perm) {
                setLoading(true)

                const params = {};

                if (isValidValue(booking_type)) {
                    params.booking_type = booking_type;
                }

                if (isValidValue(filter.from_date)) {
                    params.from_date = filter.from_date;
                }

                if (isValidValue(filter.to_date)) {
                    params.to_date = filter.to_date;
                }

                if (isValidValue(filter.package_id)) {
                    params.package_id = filter.package_id;
                }


                const repsonse = await httpRequestWithAuth(GetAgeScaleURL, "GET", null, params);
                if (repsonse.status) {
                    setData(repsonse.data || []);
                }

                let temp_filter_count = 0;
                Object.keys(filter).map(data_key => {
                    if (isValidValue(filter[data_key])) {
                        temp_filter_count++;
                    }
                })
                setFilterCount(temp_filter_count);

                setLoading(false)
            }

        }

        fetchData();
    }, [filter])


    return (
        <div className="w-100 h-100 position-relative">
            <Hide hide={!loading}>
                <div
                    className="position-absolute d-flex justify-content-center align-items-center w-100 h-100 z-index-100">
                    <div className="blur-bg w-100 pt-1 pb-3">
                        <Loading hide_text/>
                    </div>
                </div>
            </Hide>

            <Hide hide={!open_filter_modal}>
                <FilterModal
                    title="Experience Age Scale"
                    package_type="Experience"
                    open={open_filter_modal}
                    filter={filter}
                    onFilter={setFilter}
                    handleModelClose={() => setOpenFilterModal(false)}
                />
            </Hide>

            <div className="d-flex justify-content-between">
                <h2 className="custom-grey-color font-size-16">
                    Experience Age Scale
                </h2>

                <OverlayTrigger
                    overlay={
                        <RBTooltip>
                            Filter
                        </RBTooltip>
                    }
                >
                    <Button className="border-0" onClick={() => setOpenFilterModal(true)}>

                        <Badge status="Error" count={filter_count} size="small">
                            <FunnelPlotFilled/>
                        </Badge>
                    </Button>
                </OverlayTrigger>
            </div>

            <ResponsiveContainer width="100%" height="85%">
                <BarChart
                    width={500}
                    height={400}
                    data={data}
                    margin={{
                        top: 10,
                        right: 0,
                        left: -20,
                        bottom: 0,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="name" className="font-size-12"/>
                    {/*<YAxis interval="preserveStartEnd" dataKey="count" allowDecimals={false} className="font-size-12"/>*/}
                    <YAxis />

                    <Tooltip/>
                    <Bar dataKey="count" fill={PRIMARY_COLOR} shape={<TriangleBar/>} label={{position: 'top'}}>
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={PRIMARY_COLOR}/>
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}


export default ExperienceAgeScale;
