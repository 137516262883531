import {CustomDynamicTable} from "../../../global/components/CustomDynamicTable";
import React, {useState} from "react";
import {BookingsURL} from "../../../global/urls";
import BookingRowMobileView from "../../bookings/components/BookingRowMobileView";

export const PendingApprovalBookingsList = props => {
    const {slug} = props;
    const [reload, setReload] = useState(false);
    const [filter] = useState({
        status: "Pending Approval",
        item_per_page: 3,
    });


    const mobile_columns = [
        {
            dataIndex: "ref_no",
            width: "6%",
            render: (value, row_data) => <BookingRowMobileView {...props} view_summary reloadData={reloadData}
                                                               data={row_data}/>
        },
    ];


    const reloadData = () => {
        setReload(Math.random());
    }

    return (
        <div className="w-100 h-100 position-relative overflow-scroll">
            <CustomDynamicTable
                title={
                    <h2 className="font-size-15 custom-grey-color w-100 text-start">
                        Pending Approval Bookings
                    </h2>
                }
                reload_data={reload}
                mobile_columns={mobile_columns}
                fetch_url={BookingsURL}
                custom_params={filter}
                hide_search
                hide_item_per_page
                hide_header
                hide_pagination
                onNoData={()=>{props.setHide(true)}}
            />
        </div>
    )
}

export default PendingApprovalBookingsList;
