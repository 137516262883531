import CustomFieldWrapper from "../CustomFieldWrapper";
import {Radio} from "antd";
import React from "react";

const CustomRadioGroup = (props) => {
  const options = props.options || [];

  const onChange = ({ target: { value } }) => {
    if (!props.view_only && !props.disabled) {
      props.onChange(props.name, value);
    }
  };

  return(
      <CustomFieldWrapper {...props}>
      <Radio.Group
          options={props.add_all?[{label:"All", value:""},...options]:options}
          onChange={onChange}
          value={props.value || ""}
          optionType={props.type || "button"}
          buttonStyle={props.style || "solid"}
          size={props.size || "middle"}
          className="mt-2"
      />
    </CustomFieldWrapper>
  )
}


export default CustomRadioGroup;
