import React, {useEffect, useState} from "react";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {VouchersURL} from "../../../global/urls";
import {
    customMoment,
    generateRandomString,
    isValidValue,
    openNotification,
    showConfirm,
    showError
} from "../../../global/helper_functions";
import {Button, Col, Row} from "antd";
import {ActionButton} from "../../../global/components/ActionButton";
import CustomSelectField from "../../../global/components/fields/CustomSelectField";
import {CustomTextField} from "../../../global/components/fields/CustomTextField";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import moment from "moment";
import {HELoading} from "../../../global/components/HELoading";
import {CustomDateTimeField} from "../../../global/components/fields/CustomDateTimeField";
import CustomCheckBox from "../../../global/components/fields/CustomCheckBox";
import Hide from "../../../global/components/Hide";
import {DownOutlined, UpOutlined} from "@ant-design/icons";



const deducts_from_options = [
    {title: "Hello Explorer", value: "HelloExplorer"},
    {title: "Agency", value: "Agency"},
    {title: "HelloExplorer & Agency", value: "HelloExplorer & Agency"},
];

const discount_type_options = [
    {title: "Fixed", value: "Fixed"},
    {title: "Percentage", value: "Percentage"},
];




export const DiscountCodeVoucherDetails = props => {
    const is_new = props.is_new;
    const voucher_id = props.voucher_id || "";
    const slug = props.slug || "";

    const [has_perm] = useState(checkPermission(permission_data.voucher.edit) || (voucher_id === "new" && checkPermission(permission_data.voucher.add)));
    const [data, setData] = useState({
        code: generateRandomString(8),
        voucher_type: "Discount Code",
        discount_type: "Percentage",
        discount_amount: 5,
        deducts_from: "Agency",
        start_date: moment().utc(true),
        end_date: moment().utc(true).add(10, "days"),
        total_number_of_use: 100,
        number_used_per_user: 100,
        minimum_item: 1,
        minimum_spend: 1,
        maximum_spend: 30000,
        maximum_discount_amount: 30000,
        is_active: true,
    });

    const [show_advance_options, setShowAdvanceOptions] = useState(false);
    const [loading, setLoading] = useState(true);
    const [helper_text, setHelperText] = useState({});



    const fetchData = async () => {
        let response = await httpRequestWithAuth(`${VouchersURL}${voucher_id}`, "GET");
        if (response.status) {
            setData(response.data);
            props.setVoucherData(response.data);
        } else {
            showError("Get Discount Code Voucher Data", response.error_feedback.non_field_errors);
            onCancel();
        }
        setLoading(false);
    }


    const handleFieldChange = (name, value) => {
        let temp_data = {...data};
        temp_data[name] = value;
        setData(temp_data);
    }


    useEffect(() => {
        if (!is_new) {
            fetchData();
        } else {
            setLoading(false);
        }

    }, [])


    const onCancel = () => {
        props.history.push(`/${slug}/vouchers/discount-code`);
    }


    const onDelete = async () => {
        await setLoading(true);
        let response = await httpRequestWithAuth(`${VouchersURL}${data.id}`, "DELETE");
        if (response.status) {
            openNotification("success", "Delete Discount Code Voucher", `${data.title} voucher has been deleted`)
            onCancel();
        } else {
            openNotification("error", "Delete Discount Code Voucher", response.error_feedback.non_field_errors)
        }
        await setLoading(false);
    }


    const handleDelete = () => {
        showConfirm("Delete Voucher", `Are you sure to delete ${data.title} voucher ?`, onDelete);
    }


    const onSave = async () => {
        await setLoading(true);

        let temp_data = {
            ...data,
            start_date: isValidValue(data.start_date) ? customMoment(data.start_date).format("DD-MM-YYYY hh:mm") : "",
            end_date: isValidValue(data.end_date) ? customMoment(data.end_date).format("DD-MM-YYYY hh:mm") : "",
        }

        let response;
        if (is_new) {
            response = await httpRequestWithAuth(VouchersURL, "POST", temp_data);
        } else {
            response = await httpRequestWithAuth(`${VouchersURL}${data.id}`, "PUT", temp_data);
        }

        if (response.status) {
            if (is_new) {
                openNotification("success", "Add Voucher", `${data.title} voucher has been added`);
                props.history.push(`/${slug}/vouchers/discount-code/${response.data.id}/?tab=2`);
            } else {
                openNotification("success", "Edit Discount Code Voucher", response.data.msg || `${data.title} voucher has been updated`);
                onCancel();
            }


        } else {
            setHelperText(response.error_feedback)
        }

        await setLoading(false);
    }

    if (loading) {
        return <HELoading/>
    }

    return (
        <>
            <Row gutter={[12, 10]}>



                <Col xs={24} md={12}>
                    <CustomTextField
                        required
                        label="Title"
                        name="title"
                        value={data.title}
                        onChange={handleFieldChange}
                        error={helper_text.title !== undefined}
                        helper_text={helper_text.title}
                    />
                </Col>


                <Col xs={24} md={12}>
                    <CustomTextField
                        required
                        label="Code"
                        name="code"
                        value={data.code}
                        onChange={handleFieldChange}
                        error={helper_text.code !== undefined}
                        helper_text={helper_text.code}
                    />
                </Col>


                <Col xs={24} md={12}>
                    <CustomSelectField
                        required
                        label="Deducts From"
                        name="deducts_from"
                        value={data.deducts_from}
                        onChange={handleFieldChange}
                        error={helper_text.deducts_from !== undefined}
                        helper_text={helper_text.deducts_from}
                        options={deducts_from_options}
                    />
                </Col>


                <Col xs={24} md={6}>
                    <CustomTextField
                        required
                        label="Discount Percentage"
                        name="discount_amount"
                        value={data.discount_amount}
                        onChange={handleFieldChange}
                        error={helper_text.discount_amount !== undefined}
                        helper_text={helper_text.discount_amount}
                        type="number"
                        addonAfter="%"
                    />
                </Col>

                <Col xs={24} md={6}>
                    <CustomTextField
                        required
                        label="Maximum Discount Amount"
                        name="maximum_discount_amount"
                        value={data.maximum_discount_amount}
                        onChange={handleFieldChange}
                        error={helper_text.maximum_discount_amount !== undefined}
                        helper_text={helper_text.maximum_discount_amount}
                        type="number"
                    />
                </Col>


                <Col xs={24} md={12}>
                    <CustomDateTimeField
                        required
                        label="Start Date"
                        name="start_date"
                        value={data.start_date}
                        onChange={handleFieldChange}
                        error={helper_text.start_date !== undefined}
                        helper_text={helper_text.start_date}
                    />
                </Col>




                <Col xs={24} md={12}>
                    <CustomDateTimeField
                        required
                        label="End Date"
                        name="end_date"
                        value={data.end_date}
                        onChange={handleFieldChange}
                        error={helper_text.end_date !== undefined}
                        helper_text={helper_text.end_date}
                    />
                </Col>


                <Col xs={24} className="d-flex justify-content-end">
                    <Button type="link" className="font-size-14" onClick={()=>setShowAdvanceOptions(!show_advance_options)}>
                        {show_advance_options?"Hide Advance Options":"Show Advance Options"}
                        {show_advance_options?<UpOutlined />:<DownOutlined />}
                    </Button>
                </Col>


                <Hide hide={!show_advance_options}>

                <Col xs={24} md={8}>
                    <CustomTextField
                        required
                        label="Total Number Of Use"
                        name="total_number_of_use"
                        value={data.total_number_of_use}
                        onChange={handleFieldChange}
                        error={helper_text.total_number_of_use !== undefined}
                        helper_text={helper_text.total_number_of_use}
                        type="number"
                    />
                </Col>


                <Col xs={24} md={8}>
                    <CustomTextField
                        required
                        label="Number Used Per User"
                        name="number_used_per_user"
                        value={data.number_used_per_user}
                        onChange={handleFieldChange}
                        error={helper_text.number_used_per_user !== undefined}
                        helper_text={helper_text.number_used_per_user}
                        type="number"
                    />
                </Col>


                <Col xs={24} md={8}>
                    <CustomTextField
                        required
                        label="Minimum Item"
                        name="minimum_item"
                        value={data.minimum_item}
                        onChange={handleFieldChange}
                        error={helper_text.minimum_item !== undefined}
                        helper_text={helper_text.minimum_item}
                        type="number"
                    />
                </Col>


                <Col xs={24} md={8}>
                    <CustomTextField
                        required
                        label="Minimum Spend"
                        name="minimum_spend"
                        value={data.minimum_spend}
                        onChange={handleFieldChange}
                        error={helper_text.minimum_spend !== undefined}
                        helper_text={helper_text.minimum_spend}
                        type="number"
                    />
                </Col>

                <Col xs={24} md={8}>
                    <CustomTextField
                        required
                        label="Maximum Spend"
                        name="maximum_spend"
                        value={data.maximum_spend}
                        onChange={handleFieldChange}
                        error={helper_text.maximum_spend !== undefined}
                        helper_text={helper_text.maximum_spend}
                        type="number"
                    />
                </Col>

                </Hide>

                <Col xs={24}>
                    <CustomCheckBox
                        label="Is Active"
                        name="is_active"
                        value={data.is_active}
                        onChange={handleFieldChange}
                    />
                </Col>


                <Col xs={24}>
                    <ActionButton
                        error_msg={helper_text.non_field_errors}
                        onDelete={handleDelete}
                        onCancel={onCancel}
                        allow_delete={isValidValue(data.id) && checkPermission(permission_data.voucher.delete)}
                        allow_save={has_perm}
                        onSave={onSave}
                    />
                </Col>

            </Row>
        </>

)
}
