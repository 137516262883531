import DynamicAutocomplete from "./DynamicAutocomplete";
import {CitiesListAllURL} from "../../urls";

export const CityAutoComplete = (props)=>{
    const include_country_name = props.include_country_name || "";
    const countries = props.countries || "";

    return(
        <DynamicAutocomplete
            {...props}
            fetch_url={CitiesListAllURL}
            title_key="name_en"
            mode={props.multiple ? "multiple":""}

            params_array={[include_country_name, countries]}
            params={{include_country_name, countries}}
        />
    )
}
