import {Button, Col, Collapse, Divider, Row} from "antd";
import React, {useEffect, useState} from "react";
import {CustomTextField} from "../../../../global/components/fields/CustomTextField";
import Hide from "../../../../global/components/Hide";
import {PackageOptionsDatesAutoComplete} from "../../../../global/components/fields/PackageOptionsDatesAutoComplete";
import {
    PackageOptionsDatesTimesAutoComplete
} from "../../../../global/components/fields/PackageOptionsDatesTimesAutoComplete";
import {isValidValue} from "../../../../global/helper_functions";
import CustomBadge from "../../../../global/components/CustomBadge";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {ListAddonsForOptionURL} from "../../../../global/urls";

const {Panel} = Collapse;


export const PackageOptionsViewer = props => {
    const addNewItem = props.addNewItem;
    const package_data = props.package_data;
    const [package_option, setPackageOption] = useState(props.package_option || []);
    const [date_obj, setDateObj] = useState({});
    const [time_obj, setTimeObj] = useState({});
    const [helper_text, setHelperText] = useState({});
    const [addons, setAddons] = useState([]);


    useEffect(() => {
        const fetchAddonsData = async () => {
            let response = await httpRequestWithAuth(ListAddonsForOptionURL, "GET", null, {package_option: package_option.id});

            if (response.status) {
                setAddons(response.data);
            }
        }

        fetchAddonsData();
    }, [])


    const handlePackageOptionChange = (name, value) => {
        let temp_package_option = {...package_option};
        temp_package_option[name] = value;

        if (name === "qty") {
            if (!isValidValue(value)) {
                temp_package_option[name] = 1;
            } else {
                temp_package_option[name] = Math.max(1, temp_package_option[name]);
            }

        }

        if (name === "PackageOptionDateId") {
            setTimeObj({});
        }

        setPackageOption(temp_package_option);
    }


    const onAdd = (data) => {
        let temp_helper_text = {};

        if (package_option.has_date && !isValidValue(package_option.PackageOptionDateId)) {
            temp_helper_text.PackageOptionDateId = "this field is required";
        }

        if (date_obj.has_time && !isValidValue(package_option.PackageOptionDateTimeId)) {
            temp_helper_text.PackageOptionDateTimeId = "this field is required";
        }

        if (Object.keys(temp_helper_text).length === 0) {

            const temp_addons = [];
            for (let i = 0; i < addons.length; i++) {
                if ((addons[i].selected_qty || 0) > 0){
                    temp_addons.push({
                        PackageAddonId: addons[i].id,
                        title_en: addons[i].title_en,
                        qty: addons[i].selected_qty
                    })
                }
            }

            const temp_data = {
                ...data,
                PackageOptionId: data.id,
                package_title_en: package_data.title_en,
                qty: data.qty || 1,
                title_en: `${data.title_en} ${data.has_date ? "- " + date_obj.date_view : ""} ${date_obj.has_time ? "- " + time_obj.time_view : ""}`,
                date: date_obj.date_view || "",
                date_is_unique: date_obj.is_unique || false,
                time: time_obj.time_view || "",
                time_is_unique: time_obj.is_unique || false,
                add_ons: temp_addons,
                addon_price: getAddonsPrice(),
                addons_title_en: ""
            }

            if (temp_addons.length > 0){
                temp_data.addons_title_en += "\n";
                for (let i = 0; i < temp_addons.length; i++) {
                    temp_data.addons_title_en +="\n"
                    temp_data.addons_title_en += temp_addons[i].title_en + " x " + temp_addons[i].qty
                }
            }

            if (!package_option.has_date) {
                temp_data.capacity = package_option.default_capacity
                temp_data.bookings_number = package_option.bookings_number
            } else if (!date_obj.has_time) {
                temp_data.capacity = date_obj.capacity
                temp_data.bookings_number = date_obj.bookings_number
            } else {
                temp_data.capacity = time_obj.capacity
                temp_data.bookings_number = time_obj.bookings_number
            }

            addNewItem(temp_data)

        }

        setHelperText(temp_helper_text)
    }

    const handelAddonQty = async (index, value) =>{
        let temp_addons = [...addons];
        value = parseInt(value);

        if (temp_addons[index].qty >= value && value >= 0){
            temp_addons[index].selected_qty = value;
        }

        setAddons(temp_addons)
    }

    const getAddonsPrice = () =>{
        let value = 0;

        for (let i = 0; i < addons.length; i++) {
            value += addons[i].price * (addons[i].selected_qty || 0);
        }

        return value
    }
    return (
        <Row gutter={[10, 10]}>

            <Hide hide={!package_option.has_date}>
                <Col xs={24} md={24}>
                    <PackageOptionsDatesAutoComplete
                        // status="Approved"
                        // hide_unique_and_sold_out={true}
                        interval={props.interval}
                        select_first
                        getFullObj={setDateObj}
                        title_key="date_view"
                        packages_options={package_option.id}
                        label={
                            <>
                                Date
                                {isValidValue(date_obj.id) && !date_obj.is_active && (
                                    <CustomBadge padding="0" font_size={10} type="disabled" className="m-1"
                                                 title="Inactive"/>)}
                                {date_obj.is_sold_out && (
                                    <CustomBadge padding="0" font_size={10} type="danger" className="m-1"
                                                 title="Sold Out"/>)}
                                {date_obj.is_unique && (
                                    <CustomBadge padding="0" font_size={10} type="primary" className="m-1"
                                                 title="Unique"/>)}
                                {date_obj.is_active && (
                                    <CustomBadge padding="0" font_size={10} className="m-1" title="Active"/>)}
                            </>
                        }
                        name="PackageOptionDateId"
                        value={package_option.PackageOptionDateId}
                        onChange={handlePackageOptionChange}
                        error={helper_text.PackageOptionDateId !== undefined}
                        helper_text={helper_text.PackageOptionDateId}
                    />
                </Col>
            </Hide>

            <Hide hide={!date_obj.has_time}>
                <Col xs={24} md={24}>
                    <PackageOptionsDatesTimesAutoComplete
                        select_first
                        hide_unique_and_sold_out={true}
                        getFullObj={setTimeObj}
                        title_key="time_view"
                        package_option_date={date_obj.id}
                        label={<>
                            Time
                            {!time_obj.is_active && (
                                <CustomBadge padding="0" font_size={10} type="disabled" className="m-1"
                                             title="Inactive"/>)}
                            {time_obj.is_sold_out && (
                                <CustomBadge padding="0" font_size={10} type="danger" className="m-1"
                                             title="Sold Out"/>)}
                            {time_obj.is_unique && (
                                <CustomBadge padding="0" font_size={10} type="primary" className="m-1"
                                             title="Unique"/>)}
                            {time_obj.is_active && (
                                <CustomBadge padding="0" font_size={10} className="m-1" title="Active"/>)}
                        </>}
                        name="PackageOptionDateTimeId"
                        value={package_option.PackageOptionDateTimeId}
                        onChange={handlePackageOptionChange}
                        error={helper_text.PackageOptionDateTimeId !== undefined}
                        helper_text={helper_text.PackageOptionDateTimeId}
                    />
                </Col>
            </Hide>


            <Col xs={24} md={12}>
                <CustomTextField
                    disabled
                    label="Restricted Cap"
                    value={package_option.restricted_capacity}
                />
            </Col>


            <Hide hide={package_option.has_date}>
                <Col xs={24} md={12}>
                    <CustomTextField
                        disabled
                        label="Capacity"
                        value={`${package_option.bookings_number} / ${package_option.default_capacity}`}
                    />
                </Col>
            </Hide>

            <Hide hide={!isValidValue(date_obj.id) || date_obj.has_time}>
                <Col xs={24} md={12}>
                    <CustomTextField
                        disabled
                        label="Capacity"
                        value={`${date_obj.bookings_number} / ${date_obj.capacity}`}
                    />
                </Col>
            </Hide>


            <Hide hide={!isValidValue(time_obj.id)}>
                <Col xs={24} md={12}>
                    <CustomTextField
                        disabled
                        label="Capacity"
                        value={`${time_obj.bookings_number} / ${time_obj.capacity}`}

                    />
                </Col>
            </Hide>


            <Hide hide={addons.length === 0}>
                <Collapse className="w-100 m-1 overflow-hidden" key="addons-list">
                    <Panel header={
                        <div>
                            <label className="font-size-14 fw-bold">
                                Add-ons
                            </label>
                        </div>
                    }>

                        {addons.map((addon_data, index) => (
                            <Col xs={24}>
                                <Hide hide={index === 0}>
                                    <div className="w-100">
                                        <Divider className="mt-3 mb-3"/>
                                    </div>
                                </Hide>
                                <div className="d-flex">
                                    <img src={addon_data.display_photo} width={100} height={100}
                                         alt={addon_data.title_en}
                                         className="border-radius-10"/>

                                    <div className="p-2 w-100 d-flex flex-column justify-content-between">
                                        <div className="w-100">
                                            <span
                                                className="font-size-14 fw-bold text-dots">{addon_data.title_en}</span>
                                            <br/>
                                            {/*<span className="font-size-12 text-black-50 text-dots-two-line">*/}
                                            {/*    {addon_data.description_en}*/}
                                            {/*</span>*/}
                                        </div>

                                        <div className="d-flex w-100 align-items-center  justify-content-between">
                                            <div className="flex-grow-1 font-size-12 fw-bold w-50">
                                                {parseFloat(addon_data.price)} {package_data.currency} / {addon_data.unit}
                                            </div>

                                            <div style={{width: 60}}>
                                                <CustomTextField
                                                    inputClassName="text-center"
                                                    name="selected_qty"
                                                    value={addon_data.selected_qty || 0}
                                                    onChange={(name, value)=>handelAddonQty(index, value)}
                                                    type="number"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        ))}

                    </Panel>
                </Collapse>
            </Hide>

            <Col xs={24} md={12}>
                <CustomTextField
                    label="Qty"
                    name="qty"
                    value={package_option.qty || 1}
                    onChange={handlePackageOptionChange}
                    type="number"
                    error={helper_text.qty !== undefined}
                    helper_text={helper_text.qty}
                />
            </Col>

            <Col xs={24} md={12}>
                <CustomTextField
                    disabled
                    label="Total Price"
                    value={(package_option.qty || 1) * package_option.price + getAddonsPrice()}
                />
            </Col>


            <Col xs={24} md={24} className="mt-2">
                <Button type="primary" onClick={() => onAdd(package_option)} block>
                    Add
                </Button>
            </Col>
        </Row>
    )
}
