import {CustomModelDraggable} from "../../../../global/components/CustomModelDraggable";
import {Col, Divider, Row} from "antd";


export const WhatIsPackageOptions = props => {
    return (
        <CustomModelDraggable
            open={props.open}
            onCancel={props.handleModelClose}
            title="What Is Package Options?"
            width={600}
        >
            <div className="d-flex">
                <p className="me-2">
                    -
                </p>
                <p className="m-0">
                    Package Options (Or previously known as VARIANTS) are the options or choices available for the
                    customers to book for in your package.<br/>
                    For example: <br/> <br/>
                    <Row>
                        <Col xs={24} sm={12}>
                            <label>1) Weekend Trip</label>
                        </Col>
                        <Col xs={24} sm={12}>
                            <label>Weekday Trip</label> <br/>
                        </Col>

                        <Divider className="mt-1 mb-1"/>

                        <Col xs={24} sm={12}>
                            <label>2) AM Session</label>
                        </Col>
                        <Col xs={24} sm={12}>
                            <label>PM Session</label> <br/>
                        </Col>

                        <Divider className="mt-1 mb-1"/>

                        <Col xs={12}>
                            <label>3) Single Occupancy</label>
                        </Col>
                        <Col xs={12}>
                            <label>Double Occupancy</label> <br/>
                        </Col>
                    </Row> <br/>
                </p>

            </div>

            <div className="d-flex">
                <p className="me-2">
                    -
                </p>
                <p className="m-0">
                    If one of the options is sold out, it will still appear in the package but marked as Sold Out and cannot be booked anymore.
                </p>
            </div><br/>

            <div className="d-flex">
                <p className="me-2">
                    -
                </p>
                <p className="m-0">
                    If you don't want this Option to appear on the website, you can change the status from "Active" to "Inactive".
                </p>
            </div><br/>


            <div className="d-flex">
                <p className="me-2">
                    -
                </p>
                <p className="m-0">
                    Your Package should have at least one Approved option for it to appear on the website.
                </p>
            </div><br/>

        </CustomModelDraggable>
    )
}
