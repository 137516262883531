import {useState} from "react";
import CustomModal from "../../../global/components/CustomModal";
import {Col, Row} from "antd";
import {CustomTextField} from "../../../global/components/fields/CustomTextField";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {ITSupportVerifyPasswordRL} from "../../../global/urls";
import {openNotification} from "../../../global/helper_functions";
import {ModelActionButton} from "../../../global/components/ModelActionButton";


const PasswordModal = props =>{
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});

    const handleFieldChange = (name, new_value) => {
        let temp_data = {...data};

        temp_data[name] = new_value;

        setData(temp_data);
    }

    const onSave = async () => {
        await setLoading(true);
        setHelperText({});


        let response;

        response = await httpRequestWithAuth(`${ITSupportVerifyPasswordRL}`, "POST", data);

        if (response.status) {
            openNotification("success", "IT Support Verify", `Verified`)
            props.onSave(data);
        } else {
            setHelperText(response.error_feedback);
        }

        await setLoading(false);

    }

    return (
        <CustomModal size="xs" title="IT Support Verify" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>




                <Col xs={24}>
                    <CustomTextField
                        label="Password"
                        name="password"
                        value={data.password}
                        onChange={handleFieldChange}
                        helper_text={helper_text.password}
                        error={helper_text.password !== undefined}
                    />
                </Col>



                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}


export default PasswordModal;
