import React, {useEffect, useState} from "react";
import CustomModal from "../../../../global/components/CustomModal";
import {Col, Row} from "antd";
import {isValidValue} from "../../../../global/helper_functions";
import Hide from "../../../../global/components/Hide";
import {Loading} from "../../../../global/components/Loading";
import {Pie, PieChart, ResponsiveContainer, Sector} from "recharts";
import {Gold_COLOR, PRIMARY_COLOR, PRIMARY_COLOR_TWO} from "../../../../global/variables";

const COLORS = {
    "Mobile Views": PRIMARY_COLOR,
    "Desktop Views": Gold_COLOR,
    "Other": PRIMARY_COLOR_TWO,
    "No Views": "black",
};


export const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        fill,
        payload,
        percent,
        value,
        count,
        name
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    const no_data = name === "No Data";

    return (
        <g fill={COLORS[name]}>
            <text x={cx} y={cy - (no_data ? 5 : 25)} dy={8} textAnchor="middle" fill={COLORS[name]}
                  className={`font-size-15 fw-bold`}>
                {name}
            </text>
            <Hide hide={no_data}>
                <text x={cx} y={cy - 5} dy={8} textAnchor="middle" fill={COLORS[name]}>
                    count: {isValidValue(count)?count:value}
                </text>
                <text x={cx} y={cy + 15} dy={8} textAnchor="middle" fill={COLORS[name]}>
                    ({(percent * 100).toFixed(2)}%)
                </text>
            </Hide>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={COLORS[name]}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={COLORS[name]}
            />
        </g>
    );
};


export const PackageAnalyticsDetailsModal = props => {
    const package_data = props.data || {};

    const [report_data, setReportData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [active_index, setActiveIndex] = useState(0);

    useEffect(() => {
        setLoading(true)
        const data_array = [];
        data_array.push({
            "name": "Desktop",
            "value": package_data.views.desktop_views || 0
        })

        data_array.push({
            "name": "Mobile",
            "value": package_data.views.mobile_views || 0
        })

        data_array.push({
            "name": "Other",
            "value": package_data.views.other || 0
        })

        if (package_data.views.page_views === 0) {
            data_array.push({
                "name": "No Views",
                "value": 1,
                "count": 0
            });
            setActiveIndex(data_array.length - 1)
        }

        setReportData(data_array)

        setLoading(false);
    }, [package_data.id])




    const onPieEnter = (_, index) => {
        setActiveIndex(index)
    };

    return (
        <CustomModal size="xs" title={`${package_data.title_en} Views Analytics`} visible={props.open} onCancel={props.handleModelClose}
                     paddingTop="0">
            <Row gutter={[12, 10]}>

                <Hide hide={!loading}>
                    <Col xs={24} className="mt-3">
                        <Loading/>
                    </Col>
                </Hide>

                <Hide hide={loading}>
                    <Col xs={24} className="text-end mt-2">
                        <h2>Total Views: {package_data.views.page_views}</h2>
                    </Col>
                    <Col xs={24} className="d-flex align-items-center" style={{height: 250}}>
                        <ResponsiveContainer width="100%" height="85%">
                            <PieChart width={400} height={400}>
                                <Pie
                                    activeIndex={active_index}
                                    activeShape={renderActiveShape}
                                    data={report_data}
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={65}
                                    outerRadius={85}
                                    fill={PRIMARY_COLOR}
                                    dataKey="value"
                                    onMouseEnter={onPieEnter}
                                />
                            </PieChart>
                        </ResponsiveContainer>
                    </Col>
                </Hide>


            </Row>
        </CustomModal>
    )
}
