import React, {useEffect, useState} from "react";
import CustomModal from "../../../../global/components/CustomModal";
import {Col, Divider, Empty, Row} from "antd";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {PackagesOptionDateTimeURL} from "../../../../global/urls";
import {openNotification} from "../../../../global/helper_functions";
import Hide from "../../../../global/components/Hide";
import moment from "moment";
import {AddButton} from "../../../../global/components/AddButton";
import {PackageDateTimeRow} from "./PackageDateTimeRow";



export const PackageOptionDateTimeForm = props => {
    const {package_option_date_data} = props;
    const [data, setData] = useState([]);
    const [save_loading, setSaveLoading] = useState({});
    const [delete_loading, setDeleteLoading] = useState({});
    const [helper_text, setHelperText] = useState([{}]);


    useEffect(() => {
        fetchData()
    }, [])


    const fetchData = async () => {
        let response = await httpRequestWithAuth(PackagesOptionDateTimeURL, "GET", null, {package_option_date: package_option_date_data.id});

        if (response.status) {
            setData(response.data);
            setHelperText(new Array(response.data.length).fill({}));
        }

    }


    const addTime = () => {
        const new_time = {
            id: `#temp-id-${parseInt(Math.random() * 1000)}`,
            time: moment().format("HH:mm"),
            capacity: package_option_date_data.capacity,
            is_active: true,
            is_sold_out: false,
            is_unique: false,
            is_unique_sold_out: false,
        }

        if (data.length > 1) {
            const last_index = data.length - 1;
            const last_date = moment(data[last_index].time, "HH:mm")
            let duration = last_date.diff(moment(data[last_index - 1].time, "HH:mm"))
            new_time.time = last_date.add(duration, 'milliseconds').format("HH:mm")
        }
        if (data.length > 0) {
            const last_index = data.length - 1;
            new_time.is_unique = data[last_index].is_unique;
        }

        setData([...data, new_time])
        setHelperText([...helper_text, {}])
    }


    const removeTime = async (row_data, index) => {
        await setDeleteLoading({...delete_loading, [row_data.id]: true});

        let temp_data = [...data];
        let temp_data_helper = [...helper_text];

        if ((row_data.id || "#").startsWith("#")) {
            openNotification("success", "Remove Time", `${moment(row_data.time, "HH:mm").format("hh:mm A")} time has been deleted`)
            temp_data.splice(index, 1);
            temp_data_helper.splice(index, 1);
        }else{
            let response = await httpRequestWithAuth(`${PackagesOptionDateTimeURL}${row_data.id}`, "Delete");

            if (response.status){
                openNotification("success", "Delete Time", `${row_data.time_view} time has been deleted`)
                temp_data.splice(index, 1);
                temp_data_helper.splice(index, 1);
            }else{
                temp_data_helper[index] = response.error_feedback;
                openNotification("error", "Delete Package Option Time", response.error_feedback.non_field_errors || "")
            }

        }

        // if (temp_data.length === 0) {
        //     temp_data.push({
        //         id: `#temp-id-${parseInt(Math.random() * 1000)}`,
        //         capacity: package_option_date_data.capacity,
        //         time: moment().format("HH:mm"),
        //         is_active: true,
        //         is_sold_out: false,
        //         is_unique: false,
        //     })
        //     temp_data_helper.push({});
        // }

        setData(temp_data);
        setHelperText(temp_data_helper);

        await setDeleteLoading({...delete_loading, [row_data.id]: false});
    }

    const handleTimeFieldChange = (name, value, index) => {
        let temp_data = [...data];
        temp_data[index][name] = value;

        if (temp_data[index].is_unique && temp_data[index].is_unique_sold_out){
            temp_data[index].is_sold_out = true;
        }

        setData(temp_data);
    }



    const onSave = async (row_data, index) => {
        await setSaveLoading({...save_loading, [row_data.id]: true});
        let temp_helper = [...helper_text];

        const is_new = (row_data.id || "#").startsWith("#");
        let response;


        if (is_new) {
            response = await httpRequestWithAuth(PackagesOptionDateTimeURL, "POST", {PackageOptionDateId: package_option_date_data.id, ...row_data});
        } else {
            response = await httpRequestWithAuth(`${PackagesOptionDateTimeURL}${row_data.id}`, "PUT", {PackageOptionDateId: package_option_date_data.id, ...row_data});
        }

        if (response.status) {
            if (is_new) {
                openNotification("success", "Add Package Option Date Time", `${response.data.time_view} package option date time has been added`)
            } else {
                openNotification("success", "Update Package Option Date Time", `${response.data.time_view} package option date time has been updated`)
            }

            let temp_data = [...data];
            temp_data[index] = response.data;
            setData(temp_data);

            temp_helper[index] = {};

        } else {
            temp_helper[index] = response.error_feedback;
        }

        setHelperText(temp_helper);
        await setSaveLoading({...save_loading, [row_data.id]: false});
    }

    const body = (
        <Row gutter={[12, 10]} className="pb-5">


            <Col xs={24} className="d-flex justify-content-end">
                <Col xs={24} sm={6} md={5} className="d-flex d-md-block">
                    <AddButton onClick={addTime}>Add Time</AddButton>
                </Col>
            </Col>

            <Divider className="m-0"/>

            {data.map((time_data, index) => <PackageDateTimeRow
                index={index}
                time_data={time_data}
                handleTimeFieldChange={handleTimeFieldChange}
                helper_text={helper_text}
                removeTime={removeTime}
                onSave={onSave}
                save_loading={save_loading}
                delete_loading={delete_loading}
            />)}


            <Hide hide={data.length < 5}>
                <Col xs={24} className="d-flex justify-content-end">
                    <Col xs={24} sm={6} md={5} className="d-flex d-md-block">
                        <AddButton onClick={addTime}>Add Time</AddButton>
                    </Col>
                </Col>
            </Hide>

            <Hide hide={data.length !== 0}>
                <Col xs={24}>
                    <Empty/>
                </Col>
            </Hide>

        </Row>
    )

    if (props.without_modal){
        return body
    }

    return (
        <CustomModal title="Package Option Date Time Form" visible={props.open} onCancel={props.handleModelClose}>
            {body}
        </CustomModal>
    )
}
