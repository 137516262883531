import React, {useState} from 'react'
import {Col, Row, Tooltip} from 'antd'
import {CustomDynamicTable} from '../../global/components/CustomDynamicTable'
import {PackagesCategoriesURL, TestimonialsURL} from '../../global/urls'
import {AddButton} from '../../global/components/AddButton'
import Hide from '../../global/components/Hide'
import TestimonialFormModal from './components/TestimonialFormModal'
import {isAdmin} from '../../global/auth_functions'
import {httpRequestWithAuth} from "../../global/axios_instance";
import {openNotification} from "../../global/helper_functions";
import {checkPermission} from "../../global/permissions";
import {permission_data} from "../../global/permissions_data";
import CustomRate from "../../global/components/fields/CustomRate";
import CustomRadioField from "../../global/components/fields/CustomRadioField";
import {activation_option, testimonial_feedback_for_options} from "../../global/variables";
import {IsActiveView} from "../../global/components/IsActiveView";

const Testimonials = (props) => {

    const is_admin = isAdmin();

    const [form_data, setFormData] = useState({});
    const [open_from_data, setOpenFormData] = useState(false);
    const [reload_data, setReloadData] = useState(false);
    const [filter, setFilter] = useState({
        feedback_for: "",
    });
    const [testimonial_perm] = useState({
        delete: checkPermission(permission_data.testimonial.delete),
        edit: checkPermission(permission_data.testimonial.edit),
        add: checkPermission(permission_data.testimonial.add),
    });

    const onAdd = async () => {
        handelOpenFormData()
    }

    const onEdit = async (row_data) => {
        handelOpenFormData(row_data)
    }

    const onDelete = async (row_data) => {
        let response = await httpRequestWithAuth(`${TestimonialsURL}${row_data.id}`, "DELETE");
        if (response.status) {
            openNotification("success", "Delete Testimonial", `${row_data.customer_name} testimonial has been deleted`)
        } else {
            openNotification("error", "Delete Testimonial", response.error_feedback.non_field_errors)
        }
    }

    const handelOpenFormData = (data = {}) => {
        setFormData(data)
        setOpenFormData(true)
    }

    const handelCloseFormData = (reload_data = false) => {
        setFormData({});
        setOpenFormData(false);

        if (reload_data) {
            setReloadData(Math.random());
        }

    }


    const handleFilterChange = (name, value) =>{
        setFilter({
            ...filter,
            [name]: value
        })
        setReloadData(Math.random());
    }

    const freeAction = (
        <>
            <Hide hide={!testimonial_perm.add}>
                <AddButton onClick={onAdd}>Add Testimonial</AddButton>
            </Hide>
        </>
    );



    const updateTestimonial = async (row_data, data_key="", value)=>{
        let response = await httpRequestWithAuth(`${TestimonialsURL}${row_data.id}`, "PUT", {...row_data, [data_key]: value});
        if (response.status){
            openNotification("success", "Update Testimonial", `Testimonial has been updated`)
            setReloadData(Math.random());
        }else{
            openNotification("error", "Update Testimonial", response.error_feedback.non_field_errors)
        }
    }

    const columns = [
        {
            title: "Rating",
            dataIndex: "rating",
            width: "17%",
            render:(value)=><CustomRate view_only value={value}/>
        },
        {
            title: "For",
            dataIndex: "feedback_for",
            width: "10%",
        },
        {
            title: "Customer",
            dataIndex: "customer_name",
            width: "25%",

        },
        {
            title: "FeedBack",
            dataIndex: "feedback",
            width: "40%",
            render: (value)=><div className="pre-line">{value}</div>
        },
        {
            title: "View In Home",
            dataIndex: "view_in_home_page",
            width: "10%",
            align:"center",
            render: (value, row_data)=><IsActiveView active_label="Yes" inactive_label="No" value={value} onChange={()=>updateTestimonial(row_data, "view_in_home_page", !value)} view_only={!testimonial_perm.edit}/>
        },
    ];


    const filter_toolbar = (
        <Row gutter={[10,10]}>
            <Col xs={24} md={12} className="mb-0">
                <CustomRadioField
                    add_all_option
                    name="feedback_for"
                    value={filter.feedback_for}
                    onChange={handleFilterChange}
                    options={testimonial_feedback_for_options}
                />
            </Col>
        </Row>
    )


        return (
        <Row>

            <Hide hide={!open_from_data}>
                <TestimonialFormModal
                    open={open_from_data}
                    data={form_data}
                    handleModelClose={handelCloseFormData}
                />
            </Hide>

            <Col xs={24} className="paper">
                <CustomDynamicTable
                    title={
                        <label className="text-start">
                            Testimonials
                            <br/>
                            <span className="font-size-13 text-black-50">The total rating will be updated within 3 hours after any modifications.</span>
                        </label>
                    }
                    columns={columns}
                    fetch_url={TestimonialsURL}
                    free_action={freeAction}
                    filter_toolbar={filter_toolbar}
                    reload_data={reload_data}
                    onEdit={testimonial_perm.edit && onEdit}
                    onDelete={testimonial_perm.delete && onDelete}
                    custom_params={filter}
                />
            </Col>
        </Row>
    )
}

export default Testimonials
