import HeLogo from "../../assets/img/he_logo_primary.png";

export const Welcome = props =>{
    return(
        <div className="vh-100 vw-100 d-flex flex-column justify-content-center align-items-center text-center p-2">
            <img src={HeLogo} className="w-50"/>
            <h1 className="mt-5">Welcome to HelloExplorer Dashbaord</h1>

            <h2 className="mt-5">We've Upgraded our Dashbaord website ! <br/> </h2>
            If you're already a guru please check your email to access the dashboard and checkout out the new suprises we've got for you in store ! <br/><br/>
<span>still having proplems ? please contact us by email on {" "}
    <a href = "mailto:help@helloexplorer.com ?subject=Help With Dashbaord">
    help@helloexplorer.com 
</a> {" "}
    if you're still having problems accessing the website <br/></span>
        </div>
    )
}