import DynamicAutocomplete from "./DynamicAutocomplete";
import {PackagesListAgencyPackagesURL} from "../../urls";

export const AgencyPackageAutoComplete = (props)=>{
    const agency = props.agency || "";
    const package_type = props.package_type || "";
    const categories = props.categories || [];


    return(
        <DynamicAutocomplete
            {...props}
            fetch_url={PackagesListAgencyPackagesURL}
            title_key="title_en"
            mode={props.multiple ? "multiple":""}
            params_array={[agency, package_type, categories]}
            params={{agency, package_type, categories}}
        />
    )
}