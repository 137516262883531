import {Image, Row, Tooltip} from "antd";
import {useState} from "react";
import {ProductsGalleryURL} from "../../../global/urls";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {openNotification} from "../../../global/helper_functions";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import {AddButton} from "../../../global/components/AddButton";
import Hide from "../../../global/components/Hide";
import {IsActiveView} from "../../../global/components/IsActiveView";
import {ProductGalleryFormModal} from "./components/ProductGalleryFormModal";
import {PRIMARY_COLOR} from "../../../global/variables";
import {InfoIconGreenFilled} from "../../../global/components/Icons";
import React from "react";
import {CustomDynamicTable} from "../../../global/components/CustomDynamicTable";

export const ProductGallery = props =>{
    const product_id = props.product_id || "";

    const [open_from_data, setOpenFormData] = useState(false);
    const [form_data, setFormData] = useState({});
    const [reload, setReload] = useState(false);

    const [product_gallery_perm] = useState({
        delete: checkPermission(permission_data.product_gallery.delete),
        edit: checkPermission(permission_data.product_gallery.edit),
        add: checkPermission(permission_data.product_gallery.add),
    });


    const [columns] = useState([
        {
            title: "Photo",
            dataIndex: "display_photo",
            width: "60%",
            align: "center",
            render:(value)=>(
            <Image
                width={200}
                src={value}
            />
            )
        },
        {
            title: "Order",
            dataIndex: "order",
            width: "15%",
            align: "center"
        },
        {
            title: "Activate",
            dataIndex: "is_active",
            width: "5%",
            align:"center",
            render: (value)=><IsActiveView value={value} view_only={!product_gallery_perm.edit}/>
        },

    ]);

    const handelOpenFormData = (data={})=>{
        setFormData(data)
        setOpenFormData(true)
    }

    const handelCloseFormData = (reload_data=false)=>{
        setFormData({});
        setOpenFormData(false);

        if (reload_data){
            setReload(Math.random());
        }

    }

    const onDelete = async (row_data) =>{
        let response = await httpRequestWithAuth(`${ProductsGalleryURL}${row_data.id}`, "DELETE");
        if (response.status){
            openNotification("success", "Delete Product Gallery", `${row_data.title} product gallery has been deleted`)
        }else{
            openNotification("error", "Delete Product Gallery", response.error_feedback.non_field_errors)
        }
    }

    const onEdit = async (row_data) =>{
        handelOpenFormData({...row_data, ProductId: product_id});
    }

    const onAdd = async () =>{
        handelOpenFormData({ProductId: product_id})
    }

    const freeAction = (
        <>
            <AddButton onClick={onAdd}>Add Photo</AddButton>
        </>
    );

    return(
        <div className="paper pt-0 w-100">

        <Row>
            <Hide hide={!open_from_data}>
                <ProductGalleryFormModal
                    open={open_from_data}
                    data={form_data}
                    handleModelClose={handelCloseFormData}
                />
            </Hide>

                <CustomDynamicTable
                    title={
                    <label className="text-start">
                        Gallery
                        <Tooltip
                            className="ms-1"
                            placement="topLeft"
                            title="Consists of the photos displayed for the product on the website"
                            color={PRIMARY_COLOR}
                            arrowPointAtCenter
                        >
                                                                   <span className="p-0 m-0">
                                                                       <InfoIconGreenFilled/>
                                                                   </span>
                        </Tooltip>
                        <br/>
                        <span className="font-size-13 text-black-50">Please upload minimum of 3 photos</span>
                    </label>
                    }
                    reload_data={reload}
                    columns={columns}
                    fetch_url={ProductsGalleryURL}
                    free_action={freeAction}
                    onDelete={product_gallery_perm.delete && onDelete}
                    onEdit={product_gallery_perm.edit && onEdit}
                    custom_params={{"product": product_id}}
                />
        </Row>
        </div>
    )
}
