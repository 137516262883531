import React, {useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import {isValidValue} from "../helper_functions";
import {CloseOutlined, InboxOutlined} from "@ant-design/icons";
import {PRIMARY_COLOR} from "../variables";
import {Button, Col, Row, Tooltip} from "antd";
import Hide from "./Hide";
import {IconButton} from "rsuite";

const CustomDragNDrop = (props) => {
    const [error_type, setErrorType]= useState(false);
    const multiple = props.multiple;
    const onDrop = (files) => {
        setErrorType(false)
        const file_type_general = files[0].type.split('/')[0];

        if (multiple){
            props.onChange(props.name, files);
            return;
        }

        if (isValidValue(accept[files[0].type]) || isValidValue(accept[`${file_type_general}/*`])){
            props.onChange(props.name, files[0]);
        }else{
            props.onChange(props.name, "");
            setErrorType(true)
        }

    };
    const [accept, setAccept] = useState({});

    const {getRootProps, getInputProps,acceptedFiles} = useDropzone({
        onDrop,
    });

    useEffect(() => {
        let temp_accept = {}
        const accept_list = (props.accept || "").split(" ")
        for (let i = 0; i < accept_list.length; i++) {
            temp_accept[accept_list[i]] = []
        }

        setAccept(temp_accept)

    }, [])


    const onRemovePhoto = (index=0) =>{
        index = parseInt(index);
        if (multiple){
            let temp_data = [...(props.value || [])];
            temp_data.splice(index, 1)
            props.onChange(props.name, temp_data);
        }else {
            props.onChange(props.name, "");
        }
    }
    return (
        <Row className="mt-1 mb-1">
            <Col span={24} {...getRootProps()} className={`${props.error && "border-danger"} drag-n-drop p-4`}>
                <input {...getInputProps()} />
                {(!isValidValue(props.value) || multiple) ? (
                    <Row justify="center" className="font-weight-bold mt-2 p-4 ">
                        <Col className="text-center">
                            <InboxOutlined style={{fontSize: 45, color: PRIMARY_COLOR}}/>
                        </Col>
                        <Col span={24} className="text-center">
                            {props.label ? props.label : "Click or drop the attachments here"}
                        </Col>
                    </Row>
                ) : (
                    <div className="p-3 text-center">
                        <div className="font-weight-bold">The uploaded file</div>
                        <div className="font-weight-bold">{props.display_value}</div>
                        <Button className="mt-3" onClick={() => onDrop("")}>
                            Remove the file
                        </Button>
                    </div>
                )}
            </Col>
            <div className={`${(props.error || error_type) && "text-danger"}`}>
                {props.helper_text || (error_type && "invalid file type")}
            </div>
            {multiple && (
                <div>
                    {(props.value || []).map((item, index)=>(
                        <div key={index} className="p-1 d-flex align-items-center">
                            {item.name}
                            <Tooltip title={<span className="font-size-12">Remove Photo</span>}>
                                <IconButton className="p-0 ms-2" onClick={()=>onRemovePhoto(index)}><CloseOutlined className="text-danger p-1"/></IconButton>
                            </Tooltip>
                        </div>
                    ))}
                </div>
            )}

        </Row>
    );
};

export default CustomDragNDrop;
