import DynamicAutocomplete from "./DynamicAutocomplete";
import {TiersListAllURL} from "../../urls";

export const TierAutoComplete = (props)=>{

    return(
        <DynamicAutocomplete
            {...props}
            fetch_url={TiersListAllURL}
            title_key="title_en"
            mode={props.multiple ? "multiple":""}
        />
    )
}