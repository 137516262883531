import React from "react";
import {Checkbox, Form, Radio, Switch, Tooltip} from "antd";
import CustomFieldWrapper from "../CustomFieldWrapper";
import {ptBR} from "rsuite/locales";
import {DISABLED_COLOR, PRIMARY_COLOR} from "../../variables";
import Hide from "../Hide";
import {InfoIconGreenFilled} from "../Icons";
import {PlusCircleFilled} from "@ant-design/icons";

const CustomSwitch = (props) => {
    const value = props.not_value ? !props.value: props.value;

  const handleFieldChange = (new_value) => {
      if (!props.view_only && !props.disabled) {
          new_value = props.not_value ? !new_value : new_value
          props.onChange(props.name, new_value);
      }
  };

  return (
    <CustomFieldWrapper {...props} label={null} tooltip={null} container_class_name="h-100">

        <Hide hide_down_md={props.hide_label_sm}>
        <Hide hide={!props.label}>
                <label className={`me-2 fw-bold font-size-${props.label_size || 14}`}>
                {props.label}{props.tooltip && (
                    <Tooltip
                        className="me-2"
                        placement="topLeft"
                        title={props.tooltip}
                        color={PRIMARY_COLOR}
                        arrowPointAtCenter
                    >
                              <span className="p-0 m-0">
                                  <InfoIconGreenFilled/>
                              </span>
                    </Tooltip>
                )}:
                </label>
        </Hide>
        </Hide>

        <Switch
        {...props}
        disabled={props.disabled || props.view_only}
        name={props.name}
        onChange={handleFieldChange}
        checked={value}
        checkedChildren={props.checked_label}
        unCheckedChildren={props.unchecked_label}
        style={{
            background: value?props.checked_color || PRIMARY_COLOR: props.unchecked_color || DISABLED_COLOR,
        }}
      />
    </CustomFieldWrapper>
  );
};

export default CustomSwitch;
