import {Button} from "antd";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {ITSupportUpdateBookingServiceChargePaidDateURL} from "../../../global/urls";
import {useState} from "react";
import {showError, showInfo} from "../../../global/helper_functions";

const UpdateBookingServiceChargePaidDate = (props) => {
    const it_support_data = props.it_support_data || {};
    const [loading, setLoading] = useState(false);

    const updateBookingServiceChargePaidDate = async ()=>{
        await setLoading(true);
        const password = it_support_data.password || "";

        let response = await httpRequestWithAuth(ITSupportUpdateBookingServiceChargePaidDateURL, "POST", {password});
        if (response.status){
            showInfo("Done","")
        }else{
            showError("Failed", response.error_feedback.non_field_errors)
        }
        await setLoading(false)
    }

  return(
      <Button type="primary" loading={loading} onClick={updateBookingServiceChargePaidDate} block>
          Update Booking Service Charge Paid Date
      </Button>
  )
}


export default UpdateBookingServiceChargePaidDate;
