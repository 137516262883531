import React, {useState} from "react";
import {Col, Row} from "antd";
import {CustomDynamicTable} from "../../../global/components/CustomDynamicTable";
import {ProductsURL} from "../../../global/urls";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {openNotification} from "../../../global/helper_functions";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import {AddButton} from "../../../global/components/AddButton";
import {IsActiveView} from "../../../global/components/IsActiveView";

export const Products = props => {
    const {slug} = props;
    const [product_perm] = useState({
        delete: checkPermission(permission_data.product.delete),
        edit: checkPermission(permission_data.product.edit),
        add: checkPermission(permission_data.product.add),
    });

    const [columns] = useState([
        {
            title: "Ref No.",
            dataIndex: "ref_no",
            width: "7%",
            render: (value, row_data) => <a href={`/${slug}/products/${row_data.id}`} target="_blank">{value}</a>
        },
        {
            title: "Title EN",
            dataIndex: "title_en",
            width: "30%"
        },
        {
            title: "Product Type",
            dataIndex: "product_type",
            width: "15%",
            align: "center"
        },
        {
            title: "price",
            dataIndex: "price",
            width: "5%",
            align: "center",
            render: (value)=> `${value} KWD`
        },
        {
            title: "Download Limit",
            dataIndex: "download_limit",
            width: "10%",
            align: "center",
        },
        {
            title: "Activate",
            dataIndex: "is_active",
            width: "5%",
            align: "center",
            render: (value) => <IsActiveView value={value} view_only={!product_perm.edit}/>
        },

    ])


    const onDelete = async (row_data) => {
        let response = await httpRequestWithAuth(`${ProductsURL}${row_data.id}`, "DELETE");
        if (response.status) {
            openNotification("success", "Delete Product", `${row_data.title_en} product has been deleted`)
        } else {
            openNotification("error", "Delete Product", response.error_feedback.non_field_errors)
        }
    }

    const onEdit = async (row_data) => {
        props.history.push(`/${slug}/products/${row_data.id}`);
    }

    const onAdd = async () => {
        props.history.push(`/${slug}/products/new`);
    }

    const freeAction = (
        <>
            <AddButton onClick={onAdd}>Add Product</AddButton>
        </>
    );

    return (
        <Row>
            <Col xs={24} className="paper">
                <CustomDynamicTable
                    title="Products"
                    columns={columns}
                    fetch_url={ProductsURL}
                    free_action={freeAction}
                    onDelete={product_perm.delete && onDelete}
                    onEdit={product_perm.edit && onEdit}
                />
            </Col>
        </Row>
    )
}
