import {Col, Row} from "antd";
import {CustomDynamicTable} from "../../../global/components/CustomDynamicTable";
import {useState} from "react";
import {CustomersURL} from "../../../global/urls";
import {isValidValue} from "../../../global/helper_functions";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import {AddButton} from "../../../global/components/AddButton";
import {CustomerFormModal} from "./components/CustomerFormModal";
import Hide from "../../../global/components/Hide";
import {CustomAvatarWithView} from "../../../global/components/CustomAvatarWithView";


export const Customers = props =>{
    const [open_from_data, setOpenFormData] = useState(false);
    const [form_data, setFormData] = useState({});
    const [reload, setReload] = useState(false);

    const [customer_perm] = useState({
        edit: checkPermission(permission_data.customer.edit),
        add: checkPermission(permission_data.customer.add),
    });

    const [columns] = useState([
        {
            title: "Profile Image",
            dataIndex: "display_profile_image",
            width: "15%",
            render:(value) =>isValidValue(value)?<CustomAvatarWithView src={value}/>: "No Image",
            align: "center"
        },
        {
            title: "Name",
            dataIndex: "full_name",
            width: "20%"
        },
        {
            title: "Email",
            dataIndex: "email",
            width: "20%",
        },
        {
            title: "Contact Number",
            dataIndex: "contact_number",
            width: "10%",
            align: "center"
        },
        {
            title: "Age",
            dataIndex: "age",
            width: "5%",
            align: "center"
        },
        {
            title: "Nationality",
            dataIndex: "nationality",
            width: "10%",
            render:value => value || "----",
            align: "center",
        },
        {
            title: "Residence",
            dataIndex: "country_of_residence",
            width: "10%",
            render:value => value || "----",
            align: "center",
        },
        {
            title: "Rank",
            dataIndex: "current_rank",
            width: "5%",
            align: "center"
        },
    ])

    const handelOpenFormData = (data={})=>{
        setFormData(data)
        setOpenFormData(true)
    }

    const handelCloseFormData = (reload_data=false)=>{
        setFormData({});
        setOpenFormData(false);

        if (reload_data){
            setReload(Math.random());
        }

    }

    const onEdit = async (row_data) =>{
        handelOpenFormData(row_data)
    }

    const onAdd = async () =>{
        handelOpenFormData()
    }

    const freeAction = (
        <>
            <AddButton onClick={onAdd}>Add Customer</AddButton>
        </>
    );

    return(
        <Row>
            <Hide hide={!open_from_data}>
                <CustomerFormModal
                    open={open_from_data}
                    data={form_data}
                    handleModelClose={handelCloseFormData}
                />
            </Hide>

            <Col xs={24} className="paper">
                <CustomDynamicTable
                    title="Customers"
                    reload_data={reload}
                    columns={columns}
                    fetch_url={CustomersURL}
                    free_action={freeAction}
                    onEdit={customer_perm.edit && onEdit}
                />
            </Col>
        </Row>
    )
}
