import DynamicAutocomplete from "./DynamicAutocomplete";
import {CountriesListAllURL} from "../../urls";

export const CountryAutoComplete = (props)=>{
    const params = {for_agency_experience: props.for_agency_experience, is_he_supported: props.is_he_supported};
    return(
        <DynamicAutocomplete
            {...props}
            value={props.value || ""}
            fetch_url={CountriesListAllURL}
            title_key="name_en"
            mode={props.multiple ? "multiple":""}
            params_array={[props.for_agency_experience]}
            params={params}
        />
    )
}
