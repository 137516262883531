import {useEffect, useState} from "react";
import {ChangeLogURL} from "../../../global/urls";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {showError} from "../../../global/helper_functions";
import {Col, Image, Row, Table} from "antd";

export const ChangeLogDetails = (props) => {
    const ref_no = props.ref_no;

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(false);


    const [columns, setColumns] = useState([
        {
            title: "Field Name",
            dataIndex: `field_name_view`,
            width: "15%"
        },
        {
            title: "Old Value",
            dataIndex: `old_value_view`,
            width: "15%",
            render: (value, row_data) => {
                if (row_data.old_value_type === "Image") {
                    return <Image
                        width={300}
                        src={value}
                    />
                }
                return <div dangerouslySetInnerHTML={{__html: value}}/>;
            }
        },
        {
            title: "New Value",
            dataIndex: `new_value_view`,
            width: "15%",
            render: (value, row_data) => {
                if (row_data.new_value_type === "Image") {
                    return <Image
                        width={300}
                        src={value}
                    />
                }
                return <div dangerouslySetInnerHTML={{__html: value}}/>;
            }
        },
    ]);

    useEffect(() => {
        fetchData();
    }, [reload])

    const reloadData = () => {
        props.reloadMainData();
        setReload(Math.random());
    }

    const fetchData = async () => {
        await setLoading(true);
        let response = await httpRequestWithAuth(`${ChangeLogURL}${ref_no}`, "GET");
        if (response.status) {
            setData(response.data);
        } else {
            showError("Fetch data failed", response.error_feedback.non_field_errors);
        }
        await setLoading(false);
    }


    return (
        <Row key={props.key}>
            <Col xs={24}>
                <Table
                    loading={loading}
                    dataSource={data}
                    columns={columns}
                    pagination={false}
                />
            </Col>

        </Row>

    );
}