import {DISABLED_COLOR, PRIMARY_COLOR, week_days} from "../variables";
import CustomBadge from "./CustomBadge";
import CustomFieldWrapper from "./CustomFieldWrapper";
import CustomCheckBox from "./fields/CustomCheckBox";

export const WeekDaysSelectChips = props => {

    const onChange = (day) => {
        if (!props.view_only && !props.disabled) {

            let new_value = props.value || "";
            if (new_value.includes(day)) {
                new_value = new_value.replace(`, ${day}`, "")
            } else {
                if (new_value.length > 0 && props.multiple) {
                    new_value += `,${day}`
                } else {
                    new_value = `${day}`
                }
            }

            props.onChange(props.name, new_value);
        }
    }

    return (
        <CustomFieldWrapper {...props}>
            <div className="w-100">
                {week_days.map((Day, index) => (
                    <div className="m-1 d-inline-block">
                    <CustomCheckBox
                        key={index}
                        label={Day}
                        value={(props.value || "").includes(Day)}
                        onChange={() => onChange(Day)}
                    />
                    </div>
                ))}
            </div>
        </CustomFieldWrapper>
    )
}
