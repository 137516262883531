import axios from "axios";
import { BaseBackEndAPIsURL } from "./urls";
import {logout} from "./auth_functions";
// import { logout } from "./auth_functions";

export let token = "";
export let axios_instance;

let anonymous_instance =  axios.create({
  // headers: {'content-type': "multipart/form-data"}
});

export async function createAxiosInstance(token, slug) {
  axios_instance = axios.create({
    baseURL: BaseBackEndAPIsURL,
    headers: {
      Authorization: "Bearer " + token,
    },
  });

   axios_instance.interceptors.response.use(
    async (response) => {
      return response;
    },
    (error) => {
      let status = ((error || {}).response || {}).status;
      if (status === 401) {
        logout();
        window.location.href = `/${slug}/signin`;
      }

      return Promise.reject(error);
    }
  );
}

export async function httpRequestWithAuth(url, method, data, params, headers) {
  let feedback;
  try {
    feedback = await axios_instance(url, {
      method: method,
      data,
      params,
      headers,
    })
      .then((res) => {
        return { status: true, data: res.data };
      })
      .catch((error) => {
        return { status: false, error_feedback: error.response.data || {non_field_errors: "Ops, Something went wrong"} };
      });
  } catch (e) {
    feedback = {
      status: false,
      fatal_error: true,
      msg: "Something went wrong, try again",
      error_feedback: {non_field_errors: "Ops, Something went wrong"},
    };
  }

  return feedback;
}

export async function httpRequestWithoutAuth(
  url,
  method,
  data,
  params,
  headers = {}
) {
  let feedback;
  try {
    feedback = await anonymous_instance(url, {
      method: method,
      data,
      params,
      headers,
    })
      .then((res) => {
        return { status: true, msg: res.statusText, data: res.data };
      })
      .catch((error) => {
        return {
          status: false,
          msg: error.response.status,
          error_feedback: error.response.data || {non_field_errors: "Ops, Something went wrong"},
        };
      });
  } catch (e) {
    feedback = {
      status: false,
      fatal_error: true,
      msg: "Something went wrong, try again",
      error_feedback: {non_field_errors: "Ops, Something went wrong"},
    };
  }

  return feedback;
}
