import {Button, Col, Row} from "antd";
import {CustomDynamicTable} from "../../../global/components/CustomDynamicTable";
import {useState} from "react";
import {LanguagesURL} from "../../../global/urls";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {openNotification, showError, showInfo} from "../../../global/helper_functions";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import {AddButton} from "../../../global/components/AddButton";
import {LanguagesFormModal} from "./components/LanguagesFormModal";
import Hide from "../../../global/components/Hide";
import {IsActiveView} from "../../../global/components/IsActiveView";

export const Languages = props =>{
    const [open_from_data, setOpenFormData] = useState(false);
    const [form_data, setFormData] = useState({});
    const [reload, setReload] = useState(false);

    const [language_perm] = useState({
        delete: checkPermission(permission_data.language.delete),
        edit: checkPermission(permission_data.language.edit),
        add: checkPermission(permission_data.language.add),
    });

    const [columns] = useState([
        {
            title: "Code",
            dataIndex: "code",
            width: "8%",
        },
        {
            title: "Name En",
            dataIndex: "name_en",
            width: "40%"
        },
        {
            title: "Name Ar",
            dataIndex: "name_ar",
            width: "40%"
        },
        {
            title: "Activate",
            dataIndex: "is_active",
            width: "5%",
            align:"center",
render: (value)=><IsActiveView value={value} view_only={!language_perm.edit}/>
        },

    ])

    const handelOpenFormData = (data={})=>{
        setFormData(data)
        setOpenFormData(true)
    }

    const handelCloseFormData = (reload_data=false)=>{
        setFormData({});
        setOpenFormData(false);

        if (reload_data){
            setReload(Math.random());
        }

    }

    const onDelete = async (row_data) =>{
        let response = await httpRequestWithAuth(`${LanguagesURL}${row_data.id}`, "DELETE");
        if (response.status){
            openNotification("success", "Delete Languages", `${row_data.name_en} language has been deleted`)
        }else{
            openNotification("error", "Delete Languages", response.error_feedback.non_field_errors)
        }
    }

    const onEdit = async (row_data) =>{
        handelOpenFormData(row_data)
    }

    const onAdd = async () =>{
        handelOpenFormData()
    }

    const freeAction = (
        <>
            <AddButton onClick={onAdd}>Add Languages</AddButton>
        </>
    );

    return(
        <Row>
            <Hide hide={!open_from_data}>
                <LanguagesFormModal
                    open={open_from_data}
                    data={form_data}
                    handleModelClose={handelCloseFormData}
                />
            </Hide>

            <Col xs={24} className="paper">
                <CustomDynamicTable
                    title="Languages"
                    reload_data={reload}
                    columns={columns}
                    fetch_url={LanguagesURL}
                    free_action={freeAction}
                    onDelete={language_perm.delete && onDelete}
                    onEdit={language_perm.edit && onEdit}
                />
            </Col>
        </Row>
    )
}