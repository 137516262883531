import React, {useState} from "react";
import {Button, Col, Row} from "antd";
import {ViewInfo} from "../../../../global/components/ViewInfo";
import CustomBadge from "../../../../global/components/CustomBadge";
import {
    isValidValue,
    openNotification,
    showConfirm,
    showError,
    viewDateAndTime
} from "../../../../global/helper_functions";
import Hide from "../../../../global/components/Hide";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {BookingUpdateStatusURL, BookingUpdateTransferredAmountToAgencyURL} from "../../../../global/urls";
import {checkPermission} from "../../../../global/permissions";
import {permission_data} from "../../../../global/permissions_data";
import {Loading} from "../../../../global/components/Loading";
import {UpdateBookingTransferredAmount} from "./UpdateBookingTransferredAmount";

const BookingFinanceRowView = (props) => {
    const {slug, data, reloadData, setOpenUpdateTransferredAmount} = props;
    const [loading, setLoading] = useState(false);

    const [booking_perm] = useState({
        update_transferred_amount: checkPermission(permission_data.booking.update_transferred_amount_to_agency),
    });

    const onChangeStatus = async (status, remove_refund = false) => {
        const booking_item = data.id;
        setLoading(true);

        let response = await httpRequestWithAuth(`${BookingUpdateStatusURL}${booking_item}`, "PATCH", {
            status,
            booking_item,
            remove_refund
        })
        if (response.status) {
            openNotification("success", "Change Booking Status", `Boobking status has been updated`)
            reloadData();
        } else {
            if (response.error_feedback.need_to_remove_refund) {
                showConfirm("Update Booking Status", "To change from Cancelled to Any other status, you have to delete the Refund first. By clicking on OK, refund will be deleted and status updated.", () => onChangeStatus(status, true));
            }
        }

        setLoading(false);
    }

    const onView = async () => {
        props.history.push(`/${slug}/bookings/${data.id}`);
    }

    const handelTransferFullAmount = async () => {
        let response = await httpRequestWithAuth(`${BookingUpdateTransferredAmountToAgencyURL}${data.id}`, "PATCH", {transferred_amount_to_agency: data.agency_accrual_amount})
        if (response.status){
            openNotification("success", "Transfer Full Amount", `Transferred Amount To Agency has been updated`)
            reloadData()
        }else{
            showError("Transfer Full Amount", response.error_feedback.non_field_errors);
        }
    }


    return (<Row className="mt-3 mb-3">

            <Hide hide={!loading}>
                <Loading/>
            </Hide>

            <Hide hide={loading}>
                <Col xs={24} className="text-dots-two-line">
                    <h6 className="fw-bold font-size-18">
                        <a href={`/${slug}/bookings/${data.id}`} target="_blank">
                            {data.title_en}
                        </a>
                    </h6>
                </Col>

                <Col xs={6} md={8}>
                    <ViewInfo
                        title="Ref No."
                        value={`#${data.ref_no}`}
                    />
                </Col>


                <Col xs={18} md={8}>
                    <ViewInfo
                        title="Customer"
                        value={data.customer_name}
                    />
                </Col>


                <Col xs={18} md={8}>
                    <ViewInfo
                        title="Agency"
                        value={data.agency_name}
                    />
                </Col>

                <Col xs={9} md={8}>
                    <ViewInfo
                        title="Status"
                        value={<CustomBadge
                            type={data.status !== "Approved" ? data.status === "Cancelled" ? "danger" : "disabled" : ""}
                            title={data.status}/>}
                    />
                </Col>


                <Col xs={9} md={8}>
                    <ViewInfo
                        title="Type"
                        value={data.booking_type}
                    />
                </Col>

                <Col xs={9} md={8}>
                    <ViewInfo
                        title="Date Booked"
                        value={viewDateAndTime(data.createdAt)}
                    />
                </Col>


                <Col xs={9} md={8}>
                    <ViewInfo
                        title="Paid Amount"
                        value={parseFloat(data.paid_amount) + ""}
                    />
                </Col>


                <Col xs={9} md={8}>
                    <ViewInfo
                        title="Unpaid Amount"
                        value={parseFloat(data.unpaid_amount) + ""}
                    />
                </Col>

                <Col xs={9} md={8}>
                    <ViewInfo
                        title="Qty"
                        value={data.qty}
                    />
                </Col>

                <Col xs={9} md={8}>
                        <ViewInfo
                            title="Total Price"
                            value={parseFloat(data.price_overall) + ""}
                        />
                    </Col>


                <Col xs={9} md={8}>
                        <ViewInfo
                            title="Discount Amount"
                            value={parseFloat(data.discount_amount_overall) + ""}
                        />
                    </Col>


                <Col xs={9} md={8}>
                        <ViewInfo
                            title="Net Total"
                            value={parseFloat(data.net_total) + ""}
                        />
                    </Col>

                <Col xs={9} md={8}>
                        <ViewInfo
                            title="HE Amount"
                            value={parseFloat(data.he_commission_amount) + ""}
                        />
                    </Col>

                <Col xs={9} md={8}>
                        <ViewInfo
                            title="Agency accrual amount"
                            value={parseFloat(data.agency_accrual_amount) + ""}
                        />
                    </Col>

                <Col xs={9} md={8}>
                        <ViewInfo
                            title="Transferred Amount"
                            value={parseFloat(data.transferred_amount_to_agency) + ""}
                        />
                    </Col>


                    <Col xs={24} md={8} className="p-2">
                    <Button className="m-1" type="ghost" onClick={onView} block>
                        View Details
                    </Button>
                    </Col>

                    <Hide hide={!booking_perm.update_transferred_amount}>

                        <Col xs={24} md={8} className="p-2">
                            <Button className="m-1" type="primary" onClick={() => setOpenUpdateTransferredAmount(data)} block>
                                Update Transferred Amount
                            </Button>
                        </Col>


                        <Col xs={24} md={8} className="p-2">
                        <Button className="m-1" type="primary" onClick={handelTransferFullAmount} block>
                            Transfer Full Amount
                        </Button>
                        </Col>

                    </Hide>

            </Hide>

        </Row>)
}


export default BookingFinanceRowView;
