import {useState} from "react";
import CustomModal from "../../../../global/components/CustomModal";
import {Button, Col, Row} from "antd";
import {CustomTextField} from "../../../../global/components/fields/CustomTextField";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {JoinUsRequestsURL} from "../../../../global/urls";
import {isValidValue, openNotification} from "../../../../global/helper_functions";
import {ModelActionButton} from "../../../../global/components/ModelActionButton";
import {NationalityAutoComplete} from "../../../../global/components/fields/NationalityAutoComplete";
import {ViewInfo} from "../../../../global/components/ViewInfo";
import {CustomDateField} from "../../../../global/components/fields/CustomDateField";


export const JoinUsRequestFormModal = props => {
    const [data, setData] = useState({
        ...props.data
    });

    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});

    const handleFieldChange = (name, new_value) => {
        let temp_data = {...data};

        temp_data[name] = new_value;

        setData(temp_data);
    }


    const onSave = async () => {
        await setLoading(true);


        const is_new = !isValidValue(data.id);
        let response;

        if (is_new) {
            response = await httpRequestWithAuth(JoinUsRequestsURL, "POST", data);
        } else {
            response = await httpRequestWithAuth(`${JoinUsRequestsURL}${data.id}`, "PUT", data);
        }

        if (response.status) {
            if (is_new) {
                openNotification("success", "Add Join Us Request", `${data.subject} join us request has been added`)
            } else {
                openNotification("success", "Update Join Us Request", `${data.subject} join us request has been updated`)
            }
            props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
        }

        await setLoading(false);

    }


    return (
        <CustomModal title="Join Us Request Form" visible={props.open} onCancel={props.handleModelClose} size="md">
            <Row gutter={[12, 10]}>

                <Col xs={24} md={8}>
                    <CustomTextField
                        view_only
                        label="ref_no"
                        value={data.ref_no}
                    />
                </Col>


                <Col xs={24} md={8}>
                    <CustomTextField
                        required
                        label="Name"
                        name="name"
                        value={data.name}
                        onChange={handleFieldChange}
                        helper_text={helper_text.name}
                        error={helper_text.name !== undefined}
                    />
                </Col>

                <Col xs={24} md={8}>
                    <CustomDateField
                        required
                        open_on="year"                        label="Birthdate"
                        name="birthdate"
                        value={data.birthdate}
                        onChange={handleFieldChange}
                        helper_text={helper_text.birthdate}
                        error={helper_text.birthdate !== undefined}
                    />
                </Col>


                <Col xs={24} md={8}>
                    <NationalityAutoComplete
                        required
                        label="Nationality"
                        name="nationality"
                        value={data.nationality}
                        onChange={handleFieldChange}
                        helper_text={helper_text.nationality}
                        error={helper_text.nationality !== undefined}
                    />
                </Col>


                <Col xs={24} md={8}>
                    <CustomTextField
                        required
                        label="Education"
                        name="education"
                        value={data.education}
                        onChange={handleFieldChange}
                        helper_text={helper_text.education}
                        error={helper_text.education !== undefined}
                    />
                </Col>


                <Col xs={24} md={8}>
                    <CustomTextField
                        required
                        label="Spoken Languages"
                        name="spoken_languages"
                        value={data.spoken_languages}
                        onChange={handleFieldChange}
                        helper_text={helper_text.spoken_languages}
                        error={helper_text.spoken_languages !== undefined}
                    />
                </Col>


                <Col xs={24} md={8}>
                    <CustomTextField
                        required
                        label="Tourism Experience"
                        name="tourism_experience"
                        value={data.tourism_experience}
                        onChange={handleFieldChange}
                        helper_text={helper_text.tourism_experience}
                        error={helper_text.tourism_experience !== undefined}
                    />
                </Col>


                <Col xs={24} md={8}>
                    <CustomTextField
                        required
                        label="Personal Skills"
                        name="personal_skills"
                        value={data.personal_skills}
                        onChange={handleFieldChange}
                        helper_text={helper_text.personal_skills}
                        error={helper_text.personal_skills !== undefined}
                    />
                </Col>


                <Col xs={24} md={8}>
                    <CustomTextField
                        required
                        label="Join Reason"
                        name="join_reason"
                        value={data.join_reason}
                        onChange={handleFieldChange}
                        helper_text={helper_text.join_reason}
                        error={helper_text.join_reason !== undefined}
                    />
                </Col>

                <Col xs={24}>
                </Col>

                <Col xs={24} md={8}>
                    <ViewInfo
                        title="Civil ID"
                        value={(
                            <a href={data.display_civil_id} target="_blank"><Button size="small"
                                                                                                className="font-size-13">View</Button></a>
                        )}
                    />
                </Col>

                <Col xs={24} md={8}>
                    <ViewInfo
                        title="Personal Photo"
                        value={(
                            <a href={data.display_personal_photo} target="_blank"><Button size="small"
                                                                                                className="font-size-13">View</Button></a>
                        )}
                    />
                </Col>

                <Col xs={24} md={8}>
                    <ViewInfo
                        title="CV"
                        value={(
                            <a href={data.display_cv} target="_blank"><Button size="small"
                                                                                                className="font-size-13">View</Button></a>
                        )}
                    />
                </Col>



                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}
