import {useState} from "react";
import CustomModal from "../../../../global/components/CustomModal";
import {Col, Row} from "antd";
import {CustomTextField} from "../../../../global/components/fields/CustomTextField";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {HEMediaURL} from "../../../../global/urls";
import {isValidValue, openNotification} from "../../../../global/helper_functions";
import {ModelActionButton} from "../../../../global/components/ModelActionButton";
import {CustomUploadField} from "../../../../global/components/fields/CustomUploadField";



export const HEMediaFormModal = props => {
    const [data, setData] = useState({
        is_active: true,
        ...props.data
    });

    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});

    const handleFieldChange = (name, value) => {
        let temp_data = {...data};
        temp_data[name] = value;

        if (["file"].includes(name)){
            temp_data[`${name}_filename`] = isValidValue(value)? value.name: "";
        }

        setData(temp_data);
    }

    const onSave = async () => {
        await setLoading(true);


        const is_new = !isValidValue(data.id);
        let response;

        const form_data = new FormData();
        Object.keys(data).map(Name=>{
            form_data.append(Name, data[Name]);
        })

        if (is_new) {
            response = await httpRequestWithAuth(HEMediaURL, "POST", form_data);
        } else {
            response = await httpRequestWithAuth(`${HEMediaURL}${data.id}`, "PUT", form_data);
        }

        if (response.status) {
            if (is_new) {
                openNotification("success", "Add Media", `${data.title} media has been added`)
            } else {
                openNotification("success", "Update Media", `${data.title} media has been updated`)
            }
            props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
        }

        await setLoading(false);

    }

    return (
        <CustomModal title="Media Form" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>

                <Col xs={24} md={12}>
                    <CustomTextField
                        label="Title"
                        name="title"
                        value={data.title}
                        onChange={handleFieldChange}
                        error={helper_text.title !== undefined}
                        helper_text={helper_text.title}
                    />
                </Col>


                <Col xs={24} md={12}>
                    <CustomUploadField
                        label="file"
                        name="file"
                        display_value={data.file_filename}
                        value={data.file}
                        onChange={handleFieldChange}
                        error={helper_text.file !== undefined}
                        helper_text={helper_text.file}
                    />
                </Col>



                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}