import React, {useEffect, useState} from "react";
import {checkPermission} from "../../global/permissions";
import {permission_data} from "../../global/permissions_data";
import {isValidValue} from "../../global/helper_functions";
import {Tabs} from "antd";
import Hide from "../../global/components/Hide";
import {CustomTabPane} from "../../global/components/CustomTabPane";
import {getUserType, isAdmin} from "../../global/auth_functions";
import {ConsultationDetails} from "./ConsultationDetails";
import {ConsultationResponses} from "./ConsultationResponses";
import ConsultationTours from "./ConsultationTours";


export const ConsultationInfo = props => {
    const slug = props.slug || "";
    const is_admin = isAdmin();
    const url_params = props.match.params || {};
    const [consultation_id] = React.useState(url_params.id || 'new');
    const url_query = new URLSearchParams(props.location.search);
    const tab = (url_query.get("tab") || "1") + "";
    const [user_type] = useState(getUserType());
    const [current_tab, setCurrentTab] = useState("1");
    const [is_new] = useState(consultation_id === "new");
    const [consultation_data, setConsultationData] = useState({});

    useEffect(() => {
        if ((!isValidValue(url_params.id) && props.location.pathname !== `/${slug}/consultations/new`) || (consultation_id !== "new" && !checkPermission(permission_data.consultation_request.view)) || (consultation_id === "new" && !checkPermission(permission_data.consultation_request.add))) {
            props.history.push(`/${slug}/consultations`);
        }
        setCurrentTab(tab);
    }, []);

    const handleTabsChange = (new_value, extra_params={}) => {
        new_value = new_value + "";
        let params_attributes = {tab: new_value, ...extra_params};
        const params = new URLSearchParams(params_attributes);

        props.history.push({
            pathname: props.location.pathname,
            search: params.toString()
        });

        setCurrentTab(new_value);
    };


    return (
        <>


            <div className="m-0 p-0 d-md-flex justify-content-between">
                <h5 className="mb-3 mt-2">{consultation_id === "new" ? "Add Consultation" :  (<>Edit Consultation > <span className="text-black-50">{`${consultation_data.ref_no} - ${consultation_data.name}`}</span></>)}</h5>
            </div>


            <div className="paper pt-0 w-100">

                <Tabs activeKey={current_tab} onChange={handleTabsChange}>
                    <CustomTabPane tab="Details" key="1"/>
                    <CustomTabPane tab="Responses" key="2"
                                   disabled={is_new || !checkPermission(permission_data.consultation_response.view)}/>
                    {is_admin && (
                        <CustomTabPane tab="Tours" key="3"
                                       disabled={is_new || !checkPermission(permission_data.tour.view)}/>
                    )}
                </Tabs>
            </div>

                    <Hide hide={current_tab !== '1'}>
                        <ConsultationDetails
                            {...props}
                            current_tab={current_tab}
                            is_new={is_new}
                            user_type={user_type}
                            consultation_id={consultation_id}
                            setConsultationData={setConsultationData}
                            handleTabsChange={handleTabsChange}
                        />
                    </Hide>

                    <Hide hide={current_tab !== '2'}>
                        <ConsultationResponses
                            {...props}
                            user_type={user_type}
                            consultation_id={consultation_id}
                            consultation_data={consultation_data}
                            handleTabsChange={handleTabsChange}
                        />
                    </Hide>


            <Hide hide={current_tab !== '3'}>
                <ConsultationTours
                    {...props}
                    user_type={user_type}
                    consultation_id={consultation_id}
                    consultation_data={consultation_data}
                    handleTabsChange={handleTabsChange}
                    is_admin={is_admin}
                    language="en"
                />
            </Hide>


        </>)
}
