import React, {useEffect, useState} from "react";
import CustomModal from "../../../../global/components/CustomModal";
import {Alert, Col, Row} from "antd";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {PackagesGalleryURL, PackagesPendingChangesForModalURL} from "../../../../global/urls";
import {isValidValue, openNotification} from "../../../../global/helper_functions";
import {ModelActionButton} from "../../../../global/components/ModelActionButton";
import CustomCheckBox from "../../../../global/components/fields/CustomCheckBox";
import {ImageUploaderWithView} from "../../../../global/components/ImageUploaderWithView";
import CustomSelectField from "../../../../global/components/fields/CustomSelectField";
import {package_photo_order, package_status} from "../../../../global/variables";
import {getUserType} from "../../../../global/auth_functions";
import Hide from "../../../../global/components/Hide";
import ViewActionsDetails from "../../../../global/components/ViewActionsDetails";


export const PackageGalleryFormModal = props => {
    const [is_admin] = useState(getUserType() === "Admin");
    const [data, setData] = useState({
        is_active: true,
        order: 5,
        preparation_time: 0,
        status: "Approved",
        ...props.data
    });
    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});
    const [new_pending_changes, setNewPendingChanges] = useState({});
    const is_new = !isValidValue(data.id);


    useEffect(() => {
        if (isValidValue(data.id)){
            getPendingChanges();
        }

    }, [])


    const getPendingChanges = async () => {
        let response = await httpRequestWithAuth(PackagesPendingChangesForModalURL, "GET", null, {
            model_name: "PackageGallery",
            package: data.PackageId,
            object_id: data.id
        });
        if (response.status) {
            setNewPendingChanges(response.data)
        }
    }

    const handleFieldChange = (name, value) => {
        let temp_data = {...data};
        temp_data[name] = value;

        if (name === "photo") {
            temp_data[`display_${name}`] = isValidValue((value || {}).size) ? URL.createObjectURL(value) : null;
        }

        setData(temp_data);
    }


    const onSave = async () => {
        setLoading(true);

        const form_data = new FormData();
        Object.keys(data).map(Name => {
            form_data.append(Name, data[Name])
        })


        let response;


        if (is_new) {
            response = await httpRequestWithAuth(PackagesGalleryURL, "POST", form_data);
        } else {
            response = await httpRequestWithAuth(`${PackagesGalleryURL}${data.id}`, "PUT", form_data);
        }

        if (response.status) {
            if (is_new) {
                openNotification("success", "Add Package Gallery", `${data.title} package gallery has been added`)
            } else {
                openNotification("success", "Update Package Gallery", response.data.msg || `${data.title} package gallery has been updated`)
            }
            props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
        }

        setLoading(false);

    }

    return (
        <CustomModal title="Package Gallery Form" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>

                <Col xs={24}>
                    <Alert
                        description="Please make sure to upload square images , if the image is not square , it will be cropped by our system."
                        type="info"
                        showIcon
                        closable
                    />
                </Col>

                <Col xs={24}>
                    <ImageUploaderWithView
                        aspect={1}
                        maxWidth={300}
                        with_crop
                        max_size_3_mb
                        label="Upload Photo"
                        display_image={data.display_photo}
                        name="photo"
                        value={data.photo}
                        onChange={handleFieldChange}
                        error={helper_text.photo !== undefined}
                        helper_text={helper_text.photo}
                        warning_tooltip={new_pending_changes[`photo`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                    />
                </Col>

                <Col xs={24} md={is_admin ? 12 : 24}>
                    <CustomSelectField
                        label="Order"
                        name="order"
                        value={data.order}
                        onChange={handleFieldChange}
                        error={helper_text.order !== undefined}
                        helper_text={helper_text.order}
                        options={package_photo_order}
                        warning_tooltip={new_pending_changes[`order`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                        tooltip="order of image view"
                    />
                </Col>

                <Hide hide={!is_admin}>
                    <Col xs={24} md={12}>
                        <CustomSelectField
                            label="Status"
                            name="status"
                            value={data.status}
                            onChange={handleFieldChange}
                            error={helper_text.status !== undefined}
                            helper_text={helper_text.status}
                            options={package_status}
                            warning_tooltip={new_pending_changes[`status`]}
                            warning_tooltip_title="New Value Waiting Approve:"
                        />
                    </Col>
                </Hide>


                <Col xs={24}>
                    <CustomCheckBox
                        label="Is Active"
                        name="is_active"
                        value={data.is_active}
                        onChange={handleFieldChange}
                        warning_tooltip={new_pending_changes[`is_active`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                    />
                </Col>

                <Hide hide={is_new}>
                    <ViewActionsDetails data={data}/>
                </Hide>

                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}
