import React, {useEffect, useState} from "react";
import CustomModal from "../../../../global/components/CustomModal";
import {Col, Row} from "antd";
import {CustomTextField} from "../../../../global/components/fields/CustomTextField";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {PackagesAddonURL, PackagesPendingChangesForModalURL} from "../../../../global/urls";
import {isValidValue, openNotification, sleep, upperCaseView} from "../../../../global/helper_functions";
import {ModelActionButton} from "../../../../global/components/ModelActionButton";
import {StaticLanguageTabs} from "../../../../global/components/StaticLanguageTabs";
import CustomSelectField from "../../../../global/components/fields/CustomSelectField";
import {package_status} from "../../../../global/variables";
import Hide from "../../../../global/components/Hide";
import {getUserType} from "../../../../global/auth_functions";
import ViewActionsDetails from "../../../../global/components/ViewActionsDetails";
import {CustomDurationField} from "../../../../global/components/fields/CustomDurationField";
import CustomRadioGroup from "../../../../global/components/fields/CustomRadioGroup";
import {PackageOptionsAutoComplete} from "../../../../global/components/fields/PackageOptionsAutoComplete";
import {ImageUploaderWithView} from "../../../../global/components/ImageUploaderWithView";
import CustomCheckBox from "../../../../global/components/fields/CustomCheckBox";




const applied_by_options = [
    {title: "Hello Explorer", value: "HelloExplorer"},
    {title: "Agency", value: "Agency"},
];

const specific_for_options = [
    {label: "All Package Options", value: 'false'},
    {label: "Specific Package Options", value: 'true'},
]


export const PackageAddonFormModal = props => {
    const [is_admin] = useState(getUserType() === "Admin");
    const [language, setLanguage] = useState(props.language || "en");
    const [data, setData] = useState({
        price: 0,
        order: 1,
        qty: 1,
        is_active: true,
        preparation_time: 0,
        is_for_specific_options: 'false',
        package_options_id: [],
        status: "Approved",
        ...props.data
    });

    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});
    const [new_pending_changes, setNewPendingChanges] = useState({});
    const is_new = !isValidValue(data.id);

    useEffect(() => {
        if (isValidValue(data.id)) {
            getPendingChanges();
        }
    }, [])

    // useEffect(() => {
    //     const saveFunction = async ()=>{
    //         console.log("savefund ", data.photo)
    //         if (crop_photo !== last_crop && !first_render && data.photo) {
    //             saveAction()
    //         } else {
    //             setLoading(false);
    //         }
    //
    //
    //         setLastCrop(crop_photo);
    //         setFirstRender(false);
    //     }
    //
    //     saveFunction()
    // }, [data.photo, crop_photo])

    const getPendingChanges = async () => {
        let response = await httpRequestWithAuth(PackagesPendingChangesForModalURL, "GET", null, {
            model_name: "PackageAddonRules",
            package: data.PackageId,
            object_id: data.id
        });
        if (response.status) {
            setNewPendingChanges(response.data)
        }
    }

    const handleFieldChange = (name, value) => {
        let temp_data = {...data};
        temp_data[name] = value;

        if (name === "photo") {
            // if (!isValidValue(value) && !first_render){
            //     setHelperText({...helper_text, photo: "this field is required"})
            // }else {
            //     setHelperText({...helper_text, photo: ""})
            // }
            temp_data[`display_${name}`] = isValidValue((value || {}).size) ? URL.createObjectURL(value) : null;
        }

        setData(temp_data);
    }


    const saveAction = async () => {
        await setLoading(true);


        let response;


        const form_data = new FormData();
        Object.keys(data).map(Name => {
            form_data.append(Name, data[Name])
        })


        if (is_new) {
            response = await httpRequestWithAuth(PackagesAddonURL, "POST", form_data);
        } else {
            response = await httpRequestWithAuth(`${PackagesAddonURL}${data.id}`, "PUT", form_data);
        }

        if (response.status) {
            if (is_new) {
                openNotification("success", "Add Package Addon", `${data[`title_${language}`]} package addon has been added`)
            } else {
                openNotification("success", "Update Package Addon", response.data.msg || `${data[`title_${language}`]} package addon has been updated`)
            }
            props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
        }

        await setLoading(false);

    }

    const onSave = async () => {
        saveAction()
        // setHelperText({})
        // console.log("crop")
        //     setCropPhoto(Math.random())
    }

    return (
        <CustomModal title="Package Add-on Form" visible={props.open} onCancel={props.handleModelClose} paddingTop="0">
            <StaticLanguageTabs value={language} onChange={setLanguage}/>

            <Row gutter={[12, 10]}>

                <Col xs={24}>
                    <ImageUploaderWithView
                        maxWidth={300}
                        with_crop
                        max_size_3_mb
                        label="Upload Photo"
                        display_image={data.display_photo}
                        name="photo"
                        value={data.photo}
                        onChange={handleFieldChange}
                        error={helper_text.photo !== undefined}
                        helper_text={helper_text.photo}
                        warning_tooltip={new_pending_changes[`photo`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                    />
                </Col>

                <Col xs={24} md={12}>
                    <CustomTextField
                        required
                        placeholder="i.e. birthday cake"
                        label={`Title ${upperCaseView(language)}`}
                        name={`title_${language}`}
                        value={data[`title_${language}`]}
                        onChange={handleFieldChange}
                        error={helper_text[`title_${language}`] !== undefined}
                        helper_text={helper_text[`title_${language}`]}
                        warning_tooltip={new_pending_changes[`title_${language}`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                    />
                </Col>


                <Col xs={24} md={6}>
                    <CustomTextField
                        required
                        label="Order"
                        name="order"
                        value={data.order}
                        onChange={handleFieldChange}
                        error={helper_text.order !== undefined}
                        helper_text={helper_text.order}
                        type="number"
                        warning_tooltip={new_pending_changes[`order`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                    />
                </Col>

                <Col xs={24} md={6}>
                    <CustomTextField
                        required
                        label="Qty"
                        name="qty"
                        value={data.qty}
                        onChange={handleFieldChange}
                        error={helper_text.qty !== undefined}
                        helper_text={helper_text.qty}
                        type="number"
                        warning_tooltip={new_pending_changes[`qty`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                    />
                </Col>


                {/*<Col xs={24} md={6}>*/}
                {/*    <CustomTextField*/}
                {/*        required*/}
                {/*        label="Unit"*/}
                {/*        name="unit"*/}
                {/*        value={data.unit}*/}
                {/*        onChange={handleFieldChange}*/}
                {/*        error={helper_text.unit !== undefined}*/}
                {/*        helper_text={helper_text.unit}*/}
                {/*        warning_tooltip={new_pending_changes[`unit`]}*/}
                {/*        warning_tooltip_title="New Value Waiting Approve:"*/}
                {/*    />*/}
                {/*</Col>*/}


                <Col xs={24} md={18}>
                    <CustomDurationField
                        required
                        label="Preparation Time"
                        name="preparation_time"
                        value={data.preparation_time}
                        onChange={handleFieldChange}
                        error={helper_text.preparation_time !== undefined}
                        helper_text={helper_text.preparation_time}
                        warning_tooltip={new_pending_changes[`preparation_time`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                    />
                </Col>

                <Col xs={24} md={6}>
                    <CustomTextField
                        required
                        placeholder="i.e. Person, Box"
                        label="Unit"
                        name="unit"
                        value={data.unit}
                        onChange={handleFieldChange}
                        error={helper_text.unit !== undefined}
                        helper_text={helper_text.unit}
                        warning_tooltip={new_pending_changes[`unit`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                    />
                </Col>


                <Col xs={24} md={18}>
                    <CustomTextField
                        label={`Short Description ${upperCaseView(language)}`}
                        name={`description_${language}`}
                        value={data[`description_${language}`]}
                        onChange={handleFieldChange}
                        error={helper_text[`description_${language}`] !== undefined}
                        helper_text={helper_text[`description_${language}`]}
                        warning_tooltip={new_pending_changes[`description_${language}`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                    />
                </Col>


                <Col xs={24} md={6}>
                    <CustomTextField
                        required
                        label="Price"
                        name="price"
                        value={data.price}
                        onChange={handleFieldChange}
                        error={helper_text.price !== undefined}
                        helper_text={helper_text.price}
                        type="number"
                        warning_tooltip={new_pending_changes[`price`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                    />
                </Col>


                <Col xs={24} md={18}>
                    <CustomRadioGroup
                        label="Applicable for"
                        name="is_for_specific_options"
                        value={data.is_for_specific_options + ""}
                        onChange={handleFieldChange}
                        error={helper_text.is_for_specific_options !== undefined}
                        helper_text={helper_text.is_for_specific_options}
                        warning_tooltip={new_pending_changes[`is_for_specific_options`]}
                        warning_tooltip_title="New Value Waiting Approve:"
                        options={specific_for_options}
                        type="default"
                    />
                </Col>


                <Hide hide={!is_admin}>
                    <Col xs={24} md={6}>
                        <CustomSelectField
                            label="Applied By"
                            name="applied_by"
                            value={data.applied_by}
                            onChange={handleFieldChange}
                            error={helper_text.applied_by !== undefined}
                            helper_text={helper_text.applied_by}
                            options={applied_by_options}
                            warning_tooltip={new_pending_changes[`applied_by`]}
                            warning_tooltip_title="New Value Waiting Approve:"
                        />
                    </Col>
                </Hide>


                <Hide hide={['false', false].includes(data.is_for_specific_options)}>
                    <Col xs={24}>
                        <PackageOptionsAutoComplete
                            multiple
                            packages={[props.package_id]}
                            label="Specific Options"
                            name="package_options_id"
                            value={data.package_options_id}
                            onChange={handleFieldChange}
                            error={helper_text.package_options_id !== undefined}
                            helper_text={helper_text.package_options_id}
                            warning_tooltip={new_pending_changes[`package_options_id`]}
                            warning_tooltip_title="New Value Waiting Approve:"
                        />
                    </Col>
                </Hide>


                <Hide hide={!is_admin}>
                    <Col xs={24} md={6}>
                        <CustomSelectField
                            label="Status"
                            name="status"
                            value={data.status}
                            onChange={handleFieldChange}
                            error={helper_text.status !== undefined}
                            helper_text={helper_text.status}
                            options={package_status}
                            warning_tooltip={new_pending_changes[`status`]}
                            warning_tooltip_title="New Value Waiting Approve:"
                        />
                    </Col>
                </Hide>


                <Col xs={24}>
                    <CustomCheckBox
                        name="is_active"
                        label="Is Active"
                        value={data.is_active}
                        onChange={handleFieldChange}
                    />
                </Col>
                <Hide hide={is_new}>
                    <ViewActionsDetails data={data}/>
                </Hide>

                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}
