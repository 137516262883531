 import {Col} from "antd";
import CustomRadioField from "../../../../global/components/fields/CustomRadioField";
import {package_type, title_options} from "../../../../global/variables";
import {CustomerField} from "../../../../global/components/fields/CustomerField";
import {CustomPhoneNumberField} from "../../../../global/components/fields/CustomPhoneNumberField";
import CustomSelectField from "../../../../global/components/fields/CustomSelectField";
import {CustomTextField} from "../../../../global/components/fields/CustomTextField";
import {isValidValue} from "../../../../global/helper_functions";
import {CountryAutoComplete} from "../../../../global/components/fields/CountryAutoComplete";
import Hide from "../../../../global/components/Hide";
import {useState} from "react";
import {getUserAgencyId, isAgent} from "../../../../global/auth_functions";



const service_charge_options = [
    {title: "Include Service Charge", value: true},
    {title: "Exclude Service Charge", value: false},
]

const send_payment_link_options = [
    {title: "Yes", value: true},
    {title: "No", value: false},
]

export const BookingInfo = props =>{
    const {booking_data, helper_text, handleBookingData, getCustomerInfo} = props;
    const [is_agent] = useState(isAgent());
    const [agent_id] = useState(getUserAgencyId());


    const getCountryObj = (country_obj) => {
        if (booking_data.package_type !== "Travel"){
            let temp_data = {};

            if (isValidValue(country_obj)){
                temp_data.ExperienceCountryId = country_obj.id;
                temp_data.currency = country_obj.currency;
            }else{
                temp_data.ExperienceCountryId = "";
                temp_data.currency = "";
            }
            handleBookingData("", "", temp_data);

        }
    }

    return (
    <>

        <Col xs={24}>
            <h2 className="font-size-22">
                Booking Info
            </h2>
        </Col>



        <Col xs={24} md={12}>
            <CustomerField
                required
                label="Customer Email"
                name="customer_email"
                value={booking_data.customer_email}
                onChange={handleBookingData}
                error={helper_text.customer_email !== undefined}
                helper_text={helper_text.customer_email}
                getCustomerInfo={getCustomerInfo}
            />
        </Col>


        <Col xs={24} md={12}>
            <CustomPhoneNumberField
                required
                label="Customer Contact Number"
                name="customer_contact_number"
                value={booking_data.customer_contact_number}
                onChange={handleBookingData}
                error={helper_text.customer_contact_number !== undefined}
                helper_text={helper_text.customer_contact_number}
            />
        </Col>

        <Col xs={24} md={3}>
            <CustomSelectField
                required
                label="Title"
                name="customer_title"
                value={booking_data.customer_title}
                onChange={handleBookingData}
                error={helper_text.customer_title !== undefined}
                helper_text={helper_text.customer_title}
                options={title_options}
            />
        </Col>

        <Col xs={24} md={9}>
            <CustomTextField
                required
                label="Customer First Name"
                name="customer_first_name"
                value={booking_data.customer_first_name}
                onChange={handleBookingData}
                error={helper_text.customer_first_name !== undefined}
                helper_text={helper_text.customer_first_name}
            />
        </Col>


        <Col xs={24} md={12}>
            <CustomTextField
                required
                label="Customer Last Name"
                name="customer_last_name"
                value={booking_data.customer_last_name}
                onChange={handleBookingData}
                error={helper_text.customer_last_name !== undefined}
                helper_text={helper_text.customer_last_name}
            />
        </Col>




        <Col xs={24} className="mt-2"/>

        <Col xs={24} md={8}>
            <CustomRadioField
                required
                vertical
                label="Booking Type"
                name="booking_type"
                value={booking_data.booking_type}
                onChange={handleBookingData}
                error={helper_text.booking_type !== undefined}
                helper_text={helper_text.booking_type}
                options={package_type}
            />
        </Col>

        <Hide hide={is_agent}>
        <Col xs={24} md={10}>
            <CustomRadioField
                required
                vertical
                label="Service Charge"
                name="add_service_charge"
                value={booking_data.add_service_charge}
                onChange={handleBookingData}
                error={helper_text.add_service_charge !== undefined}
                helper_text={helper_text.add_service_charge}
                options={service_charge_options}
            />
        </Col>
        </Hide>

        <Col xs={24} md={is_agent?12:6}>
            <CustomRadioField
                required
                vertical
                label="Send Payment Link"
                name="send_payment_link"
                value={booking_data.send_payment_link}
                onChange={handleBookingData}
                error={helper_text.send_payment_link !== undefined}
                helper_text={helper_text.send_payment_link}
                options={send_payment_link_options}
            />
        </Col>


        <Hide hide={booking_data.booking_type === "Travel"}>
            <Col xs={24} md={5}>
                <CountryAutoComplete
                    getFullObj={getCountryObj}
                    for_agency_experience={is_agent?agent_id:""}
                    label="Country Of Experience"
                    name="ExperienceCountryId"
                    value={booking_data.ExperienceCountryId}
                    onChange={handleBookingData}
                    error={helper_text.ExperienceCountryId !== undefined}
                    helper_text={helper_text.ExperienceCountryId}
                />
            </Col>
            <Col xs={24} md={12}/>
        </Hide>
    </>
    )
}
