import {Button, Col, Image, Row} from "antd";
import {CustomDynamicTable} from "../../global/components/CustomDynamicTable";
import {useState} from "react";
import {AgenciesURL} from "../../global/urls";
import {httpRequestWithAuth} from "../../global/axios_instance";
import {
    copyText,
    isValidValue,
    openNotification,
    showError,
    showInfo,
    viewDateAndTime
} from "../../global/helper_functions";
import {permission_data} from "../../global/permissions_data";
import {checkPermission} from "../../global/permissions";
import {AddButton} from "../../global/components/AddButton";
import {AgencyFormModal} from "./components/AgencyFormModal";
import Hide from "../../global/components/Hide";
import {IsActiveView} from "../../global/components/IsActiveView";
import {CustomAvatarWithView} from "../../global/components/CustomAvatarWithView";


const export_columns = [
    {
        title: "Private Code",
        key: "private_code",
    },
    {
        title: "Name",
        key: "name",
    },
    {
        title: "Slug",
        key: "slug",
    },
    {
        title: "Contact E-mail",
        key: "agency_contact_email",
    },
    {
        title: "Contact No.",
        key: "agency_contact_number",
    },
    {
        title: "Created at",
        key: "createdAt",
        format: value => viewDateAndTime(value),
    },
]
export const Agencies = props =>{
    const {slug} = props;
    const [open_from_data, setOpenFormData] = useState(false);
    const [form_data, setFormData] = useState({});
    const [reload, setReload] = useState(false);

    const [agency_perm] = useState({
        delete: checkPermission(permission_data.agency.delete),
        edit: checkPermission(permission_data.agency.edit),
        add: checkPermission(permission_data.agency.add),
    });
    const onCopy = (slug) => {
        openNotification("success", "", `Agency Dashboard Link has been copied`)
        copyText(`${process.env.REACT_APP_DASHBOARD_URL}`+slug);
    }
    const [columns] = useState([
        {
            title: "Logo",
            dataIndex: "display_logo",
            width: "15%",
            render:(value) =>isValidValue(value)?<CustomAvatarWithView src={value}/>: "No Logo",
            align: "center"
        },
        {
            title: "Private Code",
            dataIndex: "private_code",
            width: "10%",
        },
        {
            title: "Name",
            dataIndex: "name",
            width: "25%"
        },
        {
            title: "Slug",
            dataIndex: "slug",
            width: "25%",
            render:(slug,row)=>{
                return <Button type="link" className="m-1" onClick={()=>onCopy(slug)}>
                        {slug}
                        </Button>

            }
        },
        {
            title: "Contact E-mail",
            dataIndex: "agency_contact_email",
            width: "20%"
        },
        {
            title: "Contact No.",
            dataIndex: "agency_contact_number",
            width: "15%"
        },
        {
            title: "Activate",
            dataIndex: "is_active",
            width: "5%",
            align:"center",
            render: (value)=><IsActiveView value={value} view_only={agency_perm.edit}/>
        },

    ])

    const handelOpenFormData = (data={})=>{
        setFormData(data)
        setOpenFormData(true)
    }

    const handelCloseFormData = (reload_data=false)=>{
        setFormData({});
        setOpenFormData(false);

        if (reload_data){
            setReload(Math.random());
        }

    }

    const onDelete = async (row_data) =>{
        let response = await httpRequestWithAuth(`${AgenciesURL}${row_data.id}`, "DELETE");
        if (response.status){
            openNotification("success", "Delete Agency", `${row_data.name} agency has been deleted`)
        }else{
            openNotification("error", "Delete Agency", response.error_feedback.non_field_errors)
        }
    }

    const onEdit = async (row_data) =>{
        // handelOpenFormData(row_data)
        props.history.push(`/${slug}/agencies/${row_data.id}`)
    }

    const onAdd = async () =>{
        props.history.push(`/${slug}/agencies/new`)
        // handelOpenFormData()
    }

    const freeAction = (
        <>
            <AddButton onClick={onAdd}>Add Agency</AddButton>
        </>
    );

    return(
        <Row>
            <Hide hide={!open_from_data}>
                <AgencyFormModal
                    open={open_from_data}
                    data={form_data}
                    handleModelClose={handelCloseFormData}
                />
            </Hide>

            <Col xs={24} className="paper">
                <CustomDynamicTable
                    title="Agencies"
                    reload_data={reload}
                    columns={columns}
                    fetch_url={AgenciesURL}
                    free_action={freeAction}
                    onDelete={agency_perm.delete && onDelete}
                    onEdit={agency_perm.edit && onEdit}
                    allow_export
                    export_columns={export_columns}
                />
            </Col>
        </Row>
    )
}
