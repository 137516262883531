import {Col, Row} from "antd";
import {CustomDynamicTable} from "../../../global/components/CustomDynamicTable";
import {useState} from "react";
import {ServiceChargesURL} from "../../../global/urls";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {openNotification} from "../../../global/helper_functions";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import {AddButton} from "../../../global/components/AddButton";
import {ServiceChargeFormModal} from "./components/ServiceChargeFormModal";
import Hide from "../../../global/components/Hide";
import {IsActiveView} from "../../../global/components/IsActiveView";

export const ServiceCharges = props => {
    const [open_from_data, setOpenFormData] = useState(false);
    const [form_data, setFormData] = useState({});
    const [reload, setReload] = useState(false);

    const [service_charge_perm] = useState({
        delete: checkPermission(permission_data.service_charge.delete),
        edit: checkPermission(permission_data.service_charge.edit),
        add: checkPermission(permission_data.service_charge.add),
    });


    const [columns] = useState([
        {
            title: "Title",
            dataIndex: "title",
            width: "40%",
        },
        {
            title: "Package Type",
            dataIndex: "package_type",
            width: "15%",
        },
        {
            title: "From Amount (KWD)",
            dataIndex: "from_amount",
            width: "15%",
            align: "center",
        },
        {
            title: "Service Charge Amount (KWD)",
            dataIndex: "service_charge_amount",
            width: "15%",
            align: "center",
        },
        {
            title: "Activate",
            dataIndex: "is_active",
            width: "5%",
            align: "center",
            render: (value) => <IsActiveView value={value} view_only={!service_charge_perm.edit}/>
        }
    ])




    const handelOpenFormData = (data = {}) => {
        setFormData(data)
        setOpenFormData(true)
    }

    const handelCloseFormData = (reload_data = false) => {
        setFormData({});
        setOpenFormData(false);

        if (reload_data) {
            setReload(Math.random());
        }

    }

    const onDelete = async (row_data) => {
        let response = await httpRequestWithAuth(`${ServiceChargesURL}${row_data.id}`, "DELETE");
        if (response.status) {
            openNotification("success", "Delete Service Charge", `${row_data.title} service charge has been deleted`)
        } else {
            openNotification("error", "Delete Service Charge", response.error_feedback.non_field_errors)
        }
    }

    const onEdit = async (row_data) => {
        handelOpenFormData(row_data)
    }

    const onAdd = async () => {
        handelOpenFormData()
    }

    const freeAction = (
        <>
            <AddButton onClick={onAdd}>Add Service Charge</AddButton>
        </>
    );

    return (
        <Row>
            <Hide hide={!open_from_data}>
                <ServiceChargeFormModal
                    open={open_from_data}
                    data={form_data}
                    handleModelClose={handelCloseFormData}
                />
            </Hide>

            <Col xs={24} className="paper">
                <CustomDynamicTable
                    title="Service Charge"
                    reload_data={reload}
                    columns={columns}
                    fetch_url={ServiceChargesURL}
                    free_action={freeAction}
                    onDelete={service_charge_perm.delete && onDelete}
                    onEdit={service_charge_perm.edit && onEdit}
                />
            </Col>
        </Row>
    )
}