import {useState} from "react";
import CustomModal from "../../../../global/components/CustomModal";
import {Col, Row} from "antd";
import {CustomTextField} from "../../../../global/components/fields/CustomTextField";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {TrustedPartnersURL} from "../../../../global/urls";
import {customMoment, isValidValue, openNotification} from "../../../../global/helper_functions";
import {ModelActionButton} from "../../../../global/components/ModelActionButton";
import CustomCheckBox from "../../../../global/components/fields/CustomCheckBox";
import {ImageUploaderWithView} from "../../../../global/components/ImageUploaderWithView";

export const TrustedPartnerFormModal = props => {
    const [data, setData] = useState({
        is_active: true,
        order: 1,
        ...props.data
    });

    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});

    const handleFieldChange = (name, value) => {
        let temp_data = {...data};
        temp_data[name] = value;

        if (name === "logo") {
            temp_data[`display_${name}`] = isValidValue((value || {}).size)?URL.createObjectURL(value):null;
        }

        setData(temp_data);
    }



    const onSave = async () => {
        await setLoading(true);

        const form_data = new FormData();
        Object.keys(data).map(Name=>{
            form_data.append(Name, data[Name])
        })


        const is_new = !isValidValue(data.id);
        let response;


        if (is_new) {
            response = await httpRequestWithAuth(TrustedPartnersURL, "POST", form_data);
        } else {
            response = await httpRequestWithAuth(`${TrustedPartnersURL}${data.id}`, "PUT", form_data);
        }

        if (response.status) {
            if (is_new) {
                openNotification("success", "Add Trusted Partner", `${data.title_en} trusted partner has been added`)
            } else {
                openNotification("success", "Update Trusted Partner", `${data.title_en} trusted partner has been updated`)
            }
            props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
        }

        await setLoading(false);

    }

    return (
        <CustomModal size="md" title="Trusted Partner Form" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>


                <Col xs={24}>
                    <ImageUploaderWithView
                        required
                        label="Upload Logo"
                        display_image={data.display_logo}
                        name="logo"
                        value={data.logo}
                        onChange={handleFieldChange}
                        error={helper_text.logo !== undefined}
                        helper_text={helper_text.logo}
                    />
                </Col>



                <Col xs={24} md={10}>
                    <CustomTextField
                        required
                        label="Title EN"
                        name="title_en"
                        value={data.title_en}
                        onChange={handleFieldChange}
                        error={helper_text.title_en !== undefined}
                        helper_text={helper_text.title_en}
                    />
                </Col>


                <Col xs={24} md={10}>
                    <CustomTextField
                        required
                        label="Title Ar"
                        name="title_ar"
                        value={data.title_ar}
                        onChange={handleFieldChange}
                        error={helper_text.title_ar !== undefined}
                        helper_text={helper_text.title_ar}
                    />
                </Col>



                <Col xs={24} md={4}>
                    <CustomTextField
                        required
                        label="Order"
                        name="order"
                        value={data.order}
                        onChange={handleFieldChange}
                        error={helper_text.order !== undefined}
                        helper_text={helper_text.order}
                        type="number"
                    />
                </Col>


                <Col xs={24}>
                    <CustomCheckBox
                        label="Is Active"
                        name="is_active"
                        value={data.is_active}
                        onChange={handleFieldChange}
                    />
                </Col>

                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}
