import React, {useState} from "react";
import {Alert, Button, Col, Divider, Popconfirm, Row} from "antd";
import {CustomTextField} from "../../../../global/components/fields/CustomTextField";
import Hide from "../../../../global/components/Hide";
import {isValidValue, openNotification, upperCaseView} from "../../../../global/helper_functions";
import {CustomDateTimeField} from "../../../../global/components/fields/CustomDateTimeField";
import {ViewInfo} from "../../../../global/components/ViewInfo";
import {EditFilled} from "@ant-design/icons";
import {isAgent} from "../../../../global/auth_functions";
import {Loading} from "../../../../global/components/Loading";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {BookingsRefundsURL} from "../../../../global/urls";
import {checkPermission} from "../../../../global/permissions";
import {permission_data} from "../../../../global/permissions_data";
import moment from "moment";

export const BookingRefundRow = props => {
    const [is_agent] = useState(isAgent());
    const [data, setData] = useState({...props.data});
    const language = props.language || "en";
    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});
    const [edit_row, setEditRow] = useState(!isValidValue(data.id));

    const onCancel = () =>{
        setHelperText({});
        setEditRow(false);
        props.reloadData();
    }

    const onSave = async () => {
        setLoading(true);
        setHelperText({});

        let temp_data= {
            ...data,
            payment_date: isValidValue(data.payment_date) ? moment(data.payment_date).format("DD-MM-YYYY hh:mm") : "",
        }
        let response ;
        if (!isValidValue(data.id)){
            response = await httpRequestWithAuth(BookingsRefundsURL, "POST", temp_data);
        }else{
            response = await httpRequestWithAuth(`${BookingsRefundsURL}${temp_data.id}`, "PUT", temp_data);
        }

        if (response.status){
            if (!isValidValue(data.id)){
                openNotification("success", "Create Booking Refund", `Refund has been added`)
            }else{
                openNotification("success", "Update Booking Refund", `Refund has been updated`)
            }

            props.reloadData();
            setEditRow(false);
        }else{
            setHelperText(response.error_feedback);
        }

        setLoading(false);
    }


    const onDelete = async () => {
        setLoading(true);
        setHelperText({});
           let response = await httpRequestWithAuth(`${BookingsRefundsURL}${data.id}`, "DELETE");

        if (response.status){
                openNotification("success", "Delete Booking Refund", `Refund has been deleted`)
            props.reloadData();
            setEditRow(false);
        }else{
            setHelperText(response.error_feedback);
        }

        setLoading(false);
    }

    const handleFieldChange = (name, value) =>{
        let temp_data = {...data};
            temp_data[name] = value;

        setData(temp_data);
    }
    const view_body = (<Row gutter={[12, 10]} key={data.id}>

            <Col xs={24} md={12}>
                <ViewInfo
                    title={`Title ${upperCaseView(language)}`}
                    value={data[`title_${language}`]}
                    error={helper_text[`title_${language}`] !== undefined}
                    helper_text={helper_text[`title_${language}`]}
                />
            </Col>


            <Col xs={24} md={12}>
                <ViewInfo
                    title="Amount"
                    value={data.amount || "-----"}
                    error={helper_text.amount !== undefined}
                    helper_text={helper_text.amount}
                />
            </Col>

            <Col xs={24} md={12}>
                <ViewInfo
                    title="Refund Ref"
                    value={data.payment_ref || "-----"}
                    error={helper_text.payment_ref !== undefined}
                    helper_text={helper_text.payment_ref}
                />
            </Col>


            <Col xs={24} md={12}>
                <ViewInfo
                    title="Refund Date"
                    value={data.payment_date_view || "-----"}
                    error={helper_text.payment_date !== undefined}
                    helper_text={helper_text.payment_date}
                />
            </Col>


            <Hide hide={is_agent || !checkPermission(permission_data.booking_refund.edit)}>
                <Col xs={24} className="text-end">
                    <Button
                        disabled={is_agent}
                        size="small"
                        onClick={() => setEditRow(true)}>
                        <EditFilled/>
                        Edit
                    </Button>
                </Col>
            </Hide>


            <Hide hide={!isValidValue(helper_text.non_field_errors)}>
                <Col xs={24}>
                    <Alert
                        className="text-danger"
                        banner
                        description={helper_text.non_field_errors}
                        type="error"
                    />
                </Col>
            </Hide>


            <Col xs={24} className="mb-3">
                <Divider className="m-0 border-2"/>
            </Col>
        </Row>)


    const body = (<Row gutter={[12, 10]} key={data.id}>

            <Col xs={24} md={12}>
                <CustomTextField
                    required
                    label={`Title ${upperCaseView(language)}`}
                    name={`title_${language}`}
                    value={data[`title_${language}`]}
                    onChange={handleFieldChange}
                    error={helper_text[`title_${language}`] !== undefined}
                    helper_text={helper_text[`title_${language}`]}
                />
            </Col>


            <Col xs={24} md={12}>
                <CustomTextField
                    required
                    label="Amount"
                    name="amount"
                    value={data.amount}
                    onChange={handleFieldChange}
                    error={helper_text.amount !== undefined}
                    helper_text={helper_text.amount}
                    type="number"
                />
            </Col>

            <Col xs={24} md={12}>
                <CustomTextField
                    required
                    label="Refund Ref"
                    name="payment_ref"
                    value={data.payment_ref}
                    onChange={handleFieldChange}
                    error={helper_text.payment_ref !== undefined}
                    helper_text={helper_text.payment_ref}
                />
            </Col>


            <Col xs={24} md={12}>
                <CustomDateTimeField
                    required
                    label="Refund Date"
                    name="payment_date"
                    value={data.payment_date}
                    onChange={handleFieldChange}
                    error={helper_text.payment_date !== undefined}
                    helper_text={helper_text.payment_date}
                />
            </Col>


            <Col xs={24} className="d-flex justify-content-between">
                <Hide hide={!checkPermission(permission_data.booking_refund.delete)}>
                <Popconfirm
                    placement="bottomRight"
                    title={`Are you sure to Remove this refund ??`}
                    onConfirm={onDelete}
                    okText="Yes"
                    cancelText="No"
                    disabled={is_agent}
                >
                    <Button
                        disabled={is_agent || !data.id}
                        className="m-1"
                        size="small"
                        danger type="primary">
                        Delete
                    </Button>
                </Popconfirm>
                </Hide>


                <div className="ms-auto">

                    <Button
                        disabled={is_agent}
                        className="m-1"
                        size="small"
                        onClick={onCancel}>

                        Cancel
                    </Button>

                    <Button
                        type="primary"
                        disabled={is_agent}
                        className="m-1"
                        size="small"
                        onClick={onSave}>

                        Save
                    </Button>
                </div>

            </Col>


            <Hide hide={!isValidValue(helper_text.non_field_errors)}>
                <Col xs={24}>
                    <Alert
                        className="text-danger"
                        banner
                        description={helper_text.non_field_errors}
                        type="error"
                    />
                </Col>
            </Hide>

            <Col xs={24} className="mb-3">
                <Divider className="m-0 border-2"/>
            </Col>

        </Row>)

    return (<>
            <Hide hide={!loading}>
                <Loading/>
            </Hide>

            <Hide hide={loading}>
                <Hide hide={edit_row}>
                    {view_body}
                </Hide>

                <Hide hide={!edit_row}>
                    {body}
                </Hide>
            </Hide>
        </>)

}
