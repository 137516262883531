import {Image, Row, Tooltip} from "antd";
import React, {useState} from "react";
import {InvoicesItemsURL} from "../../global/urls";
import {httpRequestWithAuth} from "../../global/axios_instance";
import {openNotification} from "../../global/helper_functions";
import {permission_data} from "../../global/permissions_data";
import {checkPermission} from "../../global/permissions";
import {AddButton} from "../../global/components/AddButton";
import Hide from "../../global/components/Hide";
import {CustomDynamicTable} from "../../global/components/CustomDynamicTable";
import {InvoiceItemFormModal} from "./components/InvoiceItemFormModal";

export const InvoiceItems = props =>{
    const invoice_id = props.invoice_id || "";

    const [open_from_data, setOpenFormData] = useState(false);
    const [form_data, setFormData] = useState({});
    const [reload, setReload] = useState(false);

    const [invoice_item_perm] = useState({
        delete: checkPermission(permission_data.invoice.delete),
        edit: checkPermission(permission_data.invoice.edit),
        add: checkPermission(permission_data.invoice.add),
    });


    const [columns] = useState([
        {
            title: "Description",
            dataIndex: "description",
            width: "50%",
        },
        {
            title: "Price Per Item",
            dataIndex: "price_per_item",
            width: "15%",
            align: "center",
            render: (value, row_data) => `${value} ${row_data.currency}`
        },
        {
            title: "Qty",
            dataIndex: "qty",
            width: "15%",
            align: "center"
        },
        {
            title: "Amount",
            dataIndex: "amount",
            width: "15%",
            align: "center",
            render: (value, row_data) => `${value} ${row_data.currency}`
        },

    ]);

    const handelOpenFormData = (data={})=>{
        setFormData(data)
        setOpenFormData(true)
    }

    const handelCloseFormData = (reload_data=false)=>{
        setFormData({});
        setOpenFormData(false);

        if (reload_data){
            setReload(Math.random());
        }

    }

    const onDelete = async (row_data) =>{
        let response = await httpRequestWithAuth(`${InvoicesItemsURL}${row_data.id}`, "DELETE");
        if (response.status){
            openNotification("success", "Delete Invoice Item", `${row_data.title} invoice item has been deleted`)
        }else{
            openNotification("error", "Delete Invoice Item", response.error_feedback.non_field_errors)
        }
    }

    const onEdit = async (row_data) =>{
        handelOpenFormData({...row_data, InvoiceId: invoice_id});
    }

    const onAdd = async () =>{
        handelOpenFormData({InvoiceId: invoice_id})
    }

    const freeAction = (
        <>
            <AddButton onClick={onAdd}>Add Item</AddButton>
        </>
    );

    return(
        <div className="paper pt-0 w-100">

        <Row>
            <Hide hide={!open_from_data}>
                <InvoiceItemFormModal
                    open={open_from_data}
                    data={form_data}
                    handleModelClose={handelCloseFormData}
                />
            </Hide>

                <CustomDynamicTable
                    title="Invoice Items"
                    reload_data={reload}
                    columns={columns}
                    fetch_url={InvoicesItemsURL}
                    free_action={freeAction}
                    onDelete={invoice_item_perm.delete && onDelete}
                    onEdit={invoice_item_perm.edit && onEdit}
                    custom_params={{"invoice": invoice_id}}
                />
        </Row>
        </div>
    )
}
