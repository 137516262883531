import {useState} from "react";
import CustomModal from "../../../global/components/CustomModal";
import {Col, Row} from "antd";
import {CustomTextField} from "../../../global/components/fields/CustomTextField";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {InvoicesItemsURL} from "../../../global/urls";
import {isValidValue, openNotification} from "../../../global/helper_functions";
import {ModelActionButton} from "../../../global/components/ModelActionButton";


export const InvoiceItemFormModal = props => {
    const [data, setData] = useState({
        is_active: true,
        price_per_item: 0,
        qty: 1,
        amount: 0,
        currency: "KWD",
        ...props.data
    });
    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});


    const handleFieldChange = (name, value) => {
        let temp_data = {...data};

        if (name === "qty"){
            value = Math.max(1, value);
        }

        if (name === "price_per_item"){
            value = Math.max(0, value);
        }

        temp_data[name] = value;

        if (['qty', 'price_per_item'].includes(name)){
            temp_data.amount = temp_data.qty * temp_data.price_per_item;
        }

        setData(temp_data);
    }



    const onSave = async () => {
        await setLoading(true);

        const is_new = !isValidValue(data.id);
        let response;


        if (is_new) {
            response = await httpRequestWithAuth(InvoicesItemsURL, "POST", data);
        } else {
            response = await httpRequestWithAuth(`${InvoicesItemsURL}${data.id}`, "PUT", data);
        }

        if (response.status) {
            if (is_new) {
                openNotification("success", "Add Invoice Item", `${data.title} invoice item has been added`)
            } else {
                openNotification("success", "Update Invoice Item", response.data.msg || `${data.title} invoice item has been updated`)
            }
            props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
        }

        await setLoading(false);

    }

    return (
        <CustomModal title="Invoice Item Form" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>


                <Col xs={24}>
                    <CustomTextField
                        required
                        label="Description"
                        name="description"
                        value={data.description}
                        onChange={handleFieldChange}
                        error={helper_text.description !== undefined}
                        helper_text={helper_text.description}
                    />
                </Col>


                <Col xs={24} md={8}>
                    <CustomTextField
                        required
                        label="Price Per Item"
                        name="price_per_item"
                        value={data.price_per_item}
                        onChange={handleFieldChange}
                        error={helper_text.price_per_item !== undefined}
                        helper_text={helper_text.price_per_item}
                        type="number"
                        addonAfter={data.currency}
                    />
                </Col>


                <Col xs={24} md={8}>
                    <CustomTextField
                        required
                        label="Qty"
                        name="qty"
                        value={data.qty}
                        onChange={handleFieldChange}
                        error={helper_text.qty !== undefined}
                        helper_text={helper_text.qty}
                        type="number"
                    />
                </Col>

                <Col xs={24} md={8}>
                    <CustomTextField
                        disabled
                        label="Amount"
                        name="amount"
                        value={data.amount}
                        onChange={handleFieldChange}
                        error={helper_text.amount !== undefined}
                        helper_text={helper_text.amount}
                        type="number"
                        addonAfter={data.currency}
                    />
                </Col>


                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}
