import {useState} from "react";
import CustomModal from "../../../global/components/CustomModal";
import {Col, Row} from "antd";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {ConsultationRequestsBulkUpdateStatusURL} from "../../../global/urls";
import {openNotification} from "../../../global/helper_functions";
import {ModelActionButton} from "../../../global/components/ModelActionButton";
import CustomSelectField from "../../../global/components/fields/CustomSelectField";


const status_options = [
    {title: "New", value: "New"},
    {title: "Completed", value: "Completed", className: "text-success fw-bold"},
    {title: "Cancelled", value: "Cancelled", className: "text-danger fw-bold"},
]



export const ConsultationRequestChangeStatusModal = props => {
    const consultation_requests = props.consultation_requests || [];
    const [data, setData] = useState({});

    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});

    const handleFieldChange = (name, new_value) => {
        let temp_data = {...data};

        temp_data[name] = new_value;

        setData(temp_data);
    }


    const onSave = async () => {
        await setLoading(true);


        let response = await httpRequestWithAuth(ConsultationRequestsBulkUpdateStatusURL, "POST", {...data, consultation_requests});

        if (response.status) {
                openNotification("success", "Change Status Consultation Requests", `${consultation_requests.length} requests status has been changed to ${data.status}`)
                props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
        }

        await setLoading(false);

    }


    return (
        <CustomModal title="Consultation Request Change Status" visible={props.open} onCancel={()=>props.handleModelClose()} size="xs">
            <Row gutter={[12, 10]}>

                <Col xs={24}>
                    <CustomSelectField
                        label="Status"
                        name="status"
                        value={data.status}
                        onChange={handleFieldChange}
                        helper_text={helper_text.status}
                        error={helper_text.status !== undefined}
                        options={status_options}
                    />
                </Col>

                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}
