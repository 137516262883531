import React, {useEffect, useState} from "react";
import CustomModal from "../../../global/components/CustomModal";
import {Badge, Button, Col, Divider, Empty, Row} from "antd";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {BookingSendPaymentRequestToCustomerByEmailURL, BookingsPaymentsURL} from "../../../global/urls";
import Hide from "../../../global/components/Hide";
import {Loading} from "../../../global/components/Loading";
import {copyText, isValidValue, openNotification, upperCaseView} from "../../../global/helper_functions";
import {ModelActionButton} from "../../../global/components/ModelActionButton";
import {StaticLanguageTabs} from "../../../global/components/StaticLanguageTabs";
import {ViewInfo} from "../../../global/components/ViewInfo";
import CustomCheckBox from "../../../global/components/fields/CustomCheckBox";

export const SendPaymentRequestModal = (props) => {
    const booking = props.booking || "";
    const booking_item = props.booking_item || "";
    const group_by_item = props.group_by_item || false;
    const generate_payment_link = props.generate_payment_link || false;
    const [data, setData] = useState([]);
    const [language, setLanguage] = useState(props.language || "en");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [payment_link_generated, setPaymentLinkGenerated] = useState(false);
    const [payment_link, setPaymentLink] = useState("");
    const [view_booking_item_on, setViewBookingItemOn] = useState({});

    const fetchData = async () => {
        await setLoading(true);

        let response = await httpRequestWithAuth(BookingsPaymentsURL, "GET", null, {
            booking, booking_item, payment_status: "Pending"
        });

        if (response.status) {
            setData(response.data);
            let temp_view_booking_item_on = {};
            let count = 1;
            (response.data || []).map((payment,index)=> {
                if (!isValidValue(temp_view_booking_item_on[payment.BookingItemId])){
                    temp_view_booking_item_on[payment.BookingItemId] = count++;
                    temp_view_booking_item_on[payment.id] = true;
                }
            })

                console.log(temp_view_booking_item_on)
            setViewBookingItemOn(temp_view_booking_item_on);
        }
        await setLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, [])


    const handleSelectChange = (index) => {
        let temp_data = [...data];
        temp_data[index].is_selected = temp_data[index].is_selected ? false : true;
        if (temp_data[index].is_selected) {
            for (let i = index - 1; i >= 0; i--) {
                if (temp_data[i].BookingItemId === temp_data[index].BookingItemId){
                    temp_data[i].is_selected = true
                }
            }
        } else {
            for (let i = index + 1; i < data.length; i++) {
                if (temp_data[i].BookingItemId === temp_data[index].BookingItemId){
                    temp_data[i].is_selected = false;
            }
            }
        }

        setData(temp_data);
    }


    const onSave = async () => {
        setLoading(true);
        setError({})

        let temp_payment_id = [];
        let temp_booking_items_id = [];
        data.map(payment => {
            if (payment.is_selected) {
                temp_payment_id.push(payment.id);
                if (!temp_booking_items_id.includes(payment.BookingItemId)){
                    temp_booking_items_id.push(payment.BookingItemId);
                }
            }
        });

        const request_data = {
            booking, booking_items: temp_booking_items_id.join(","), booking_items_payments: temp_payment_id.join(",")
        }

        if (!generate_payment_link) {
            let response = await httpRequestWithAuth(BookingSendPaymentRequestToCustomerByEmailURL, "POST", request_data)
            if (response.status) {
                openNotification("success", "Send Payment Request", `Payment Request has been updated`)
                props.handleModelClose();
            } else {
                // openNotification("error", "Send Payment Request", response.error_feedback.non_field_errors)
                setError(response.error_feedback)
            }
        } else {
            const url = `${process.env.REACT_APP_CUSTOMER_WEBSITE_URL}payments/booking/?booking=${request_data.booking}&booking_items=${request_data.booking_items}&booking_items_payments=${request_data.booking_items_payments}`
            setPaymentLink(url)
            setPaymentLinkGenerated(true);
            copyText(url);
            openNotification("success", "", `Payment Link has been copied`)
        }

        setLoading(false);
    }


    const handleLanguageChange = (new_value) => {
        setLanguage(new_value);
    };

    const onCopy = () => {
        openNotification("success", "", `Payment Link has been copied`)
        copyText(payment_link);
    }

    const onBack = () => {
        setPaymentLink("")
        setPaymentLinkGenerated(false);
    }

    const PaymentRow = ({payment_data, onSelect, index}) => {
        return (
            <Row gutter={[12, 10]} className="border border-2 p-2">
                <div className="position-absolute end-0 me-4 z-index-100">
                    <Badge count={index + 1}>
                    </Badge>
                </div>

                <Col xs={24} md={2}
                     className="d-flex justify-content-md-center align-items-center">
                    <CustomCheckBox container_class_name="h-auto"
                                    value={payment_data.is_selected}
                                    view_only
                                    />
                </Col>

                <Col xs={24} md={22}>
                    <Row gutter={[12, 10]}>
                        <Col xs={24} sm={24} md={10} className="d-flex">

                            <ViewInfo
                                title={`Title ${upperCaseView(language)}`}
                                value={payment_data[`title_${language}`]}
                            />
                        </Col>

                        <Col xs={24} sm={10} md={6}>
                            <ViewInfo
                                title="Amount"
                                value={`${payment_data.amount} ${payment_data.currency}` || "-----"}
                            />
                        </Col>


                        <Col xs={24} sm={14} md={8}>
                            <ViewInfo
                                title="Payment Deadline"
                                value={payment_data.payment_deadline_view || "-----"}
                            />
                        </Col>
                    </Row>
                </Col>

                {/*<Col xs={24}>*/}
                {/*    <Divider className="m-0 border-2"/>*/}
                {/*</Col>*/}

            </Row>
        )
    }
    return (<CustomModal title={generate_payment_link ? "Generate Payment Link" : "Send Payment Request"}
                         visible={props.open} onCancel={props.handleModelClose}>


        <Row gutter={[12, 10]}>
            <Hide hide={!loading}>
                <Col xs={24}>
                    <Loading/>
                </Col>
            </Hide>

            <Hide hide={!payment_link_generated || !generate_payment_link}>
                <Col xs={24}>
                    <Button className="m-1" onClick={onBack}>Back</Button>
                </Col>

                <Col xs={24} className="mb-4">
                    <div className="m-0 d-flex align-items-center justify-content-center bg-light border p-3">
                        {/*<p className="fw-bold">{payment_link}</p>*/}
                        <div className="text-center">
                            <h6 className="fw-bold font-size-16 main-color">Payment Link Generated successfully</h6>
                            <Button className="m-1" onClick={onCopy}>Click Here To Copy The Payment Link</Button>
                        </div>

                    </div>
                </Col>
            </Hide>

            <Hide hide={payment_link_generated && generate_payment_link}>
                <Hide hide={loading}>
                    <Col xs={24}>
                        <StaticLanguageTabs value={language} onChange={handleLanguageChange}/>
                    </Col>


                    <Col xs={24}>
                        <h5 className="fw-bold mb-0">Please select payments</h5>
                    </Col>

                    {data.map((payment_data, index) => {
                        return (<Col xs={24} sm={24} md={24} key={index} className="pt-1 pb-1 pe-3 ps-3"
                                     onClick={() => handleSelectChange(index)}>

                            <Hide hide={!view_booking_item_on[payment_data.id]}>

                                <Hide hide={index === 0}>
                                    <Col xs={24}>
                                    <Divider className="m-0 mb-3"/>
                                    </Col>
                                </Hide>

                            <Col xs={24}>
                                <h2 className="font-size-14 fw-bold break-line w-100 p-0 m-0 mb-2">
                                    {view_booking_item_on[payment_data.BookingItemId]}) {payment_data.booking_title_en}
                                </h2>
                            </Col>
                            </Hide>

                            <PaymentRow index={index} payment_data={payment_data}
                                        onSelect={() => handleSelectChange(index)}/>
                        </Col>)
                    })}


                    <Hide hide={data.length !== 0}>
                        <Col xs={24}>
                            <Empty/>
                        </Col>
                    </Hide>


                    <Col xs={24} className="p-0">
                        <ModelActionButton
                            error_msg={error.non_field_errors}
                            loading={loading}
                            onSave={onSave}
                            hide_save={generate_payment_link ? payment_link_generated : false}
                            saveLabel={generate_payment_link ? "Generate" : "Send"}
                            onCancel={props.handleModelClose}
                        />
                    </Col>
                </Hide>

            </Hide>


        </Row>
    </CustomModal>)
}
