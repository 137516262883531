import {Button, Calendar} from 'antd';
import moment from "moment";
import {PRIMARY_COLOR} from "../../variables";
import {CaretLeftOutlined, CaretRightOutlined} from "@ant-design/icons";
import CustomFieldWrapper from "../CustomFieldWrapper";
import Hide from "../Hide";


const CustomMultiDateSelector = (props) => {
    const value = props.value || [];

    const value_format = props.format || "YYYY-MM-DD";

    const onValueChange = (date) => {
        if (!props.view_only && !props.disabled) {
            const new_value = moment(date).format(value_format)
            if (value.includes(new_value)) {
                props.onChange(props.name, [...value.filter(item => item !== new_value)])
            } else {
                props.onChange(props.name, [...value, new_value])
            }
        }
    };

    const dateRender = (currentDate) => {
        const isSelected = value.includes(moment(currentDate).format(value_format))
        let selectStyle = isSelected ?
            {
                position: 'relative',
                zIndex: 2,
                display: 'inlineBlock',
                width: "24px",
                height: "22px",
                lineHeight: "22px",
                backgroundColor: PRIMARY_COLOR,
                color: "#fff",
                margin: "auto",
                borderRadius: "2px",
                transition: "background 0.3s, border 0.3s"
            }
            : {}
        return (<div style={selectStyle} > {currentDate.date()}  </div >)
    }

    const isDisabledDate = (current) =>{

        let is_disabled = false;

        if (props.disabledDate){
            is_disabled = props.disabledDate(current)
        }

        if (props.availableDate){
            is_disabled = !props.availableDate(current)
        }

        return is_disabled
    }
    return (
        <>
        <CustomFieldWrapper {...props}>
            <Calendar
                fullscreen={false}
                dateFullCellRender={dateRender}
                onChange={onValueChange}
                disabledDate={isDisabledDate}
                className={`w-100 mt-0 ${!props.disabled && props.view_only?"bg-white text-black-50":""} ${props.className}`}
                disabled={props.disabled || props.view_only}
                headerRender={(header_data)=>{
                    const nextMonth = () =>{
                        header_data.onChange(header_data.value.add(1, "months"))
                    }
                    const preMonth = () =>{
                        header_data.onChange(header_data.value.add(-1, "months"))
                    }

                    return (<div className="w-100 d-flex justify-content-between align-items-center">
                        <Button onClick={preMonth} className="border-0"><CaretLeftOutlined/></Button>
                        <span className="fw-bold">{header_data.value.format("MMM, YYYY")}</span>
                        <Button onClick={nextMonth} className="border-0"><CaretRightOutlined/></Button>
                    </div>)
                }}
            />
        </CustomFieldWrapper>
            <Hide hide={!props.show_legend}>
                <div className="d-flex justify-content-center align-items-center">
                    <div className="d-flex align-items-center me-2">
                        <div style={{width:15, height:15, background: "#f5f5f5"}}/>
                        <span className="font-size-12 ms-2">{props.disabled_date_text || "Existing Date"}</span>
                    </div>

                    <div className="d-flex align-items-center me-2">
                        <div style={{width:15, height:15, background: PRIMARY_COLOR}}/>
                        <span className="font-size-12 ms-2">Selected Date</span>
                    </div>

                    <div className="d-flex align-items-center">
                        <div style={{width:15, height:15}} className="border"/>
                        <span className="font-size-12 ms-2">Available Date</span>
                    </div>
                </div>
            </Hide>
        </>
    );
};
export default CustomMultiDateSelector;
