import {useState} from "react";
import CustomModal from "../../../global/components/CustomModal";
import {Alert, Col, Row} from "antd";
import {CustomTextField} from "../../../global/components/fields/CustomTextField";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {ToursGalleryURL} from "../../../global/urls";
import {isValidValue, openNotification} from "../../../global/helper_functions";
import {ModelActionButton} from "../../../global/components/ModelActionButton";
import CustomCheckBox from "../../../global/components/fields/CustomCheckBox";
import {ImageUploaderWithView} from "../../../global/components/ImageUploaderWithView";


export const TourGalleryFormModal = props => {
    const [data, setData] = useState({
        is_active: true,
        order: 5,
        ...props.data
    });
    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});


    const handleFieldChange = (name, value) => {
        let temp_data = {...data};
        temp_data[name] = value;

        if (name === "photo") {
            temp_data[`display_${name}`] = isValidValue((value || {}).size)?URL.createObjectURL(value):null;
        }

        setData(temp_data);
    }


    const onSave = async () => {
        await setLoading(true);

        const form_data = new FormData();
        Object.keys(data).map(Name=>{
                form_data.append(Name, data[Name])
        })


        const is_new = !isValidValue(data.id);
        let response;


        if (is_new) {
            response = await httpRequestWithAuth(ToursGalleryURL, "POST", form_data);
        } else {
            response = await httpRequestWithAuth(`${ToursGalleryURL}${data.id}`, "PUT", form_data);
        }

        if (response.status) {
            if (is_new) {
                openNotification("success", "Add Tour Gallery", `${data.title} tour gallery has been added`)
            } else {
                openNotification("success", "Update Tour Gallery", response.data.msg || `${data.title} tour gallery has been updated`)
            }
            props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
        }

        await setLoading(false);

    }

    return (
        <CustomModal title="Tour Gallery Form" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>

                <Col xs={24}>
                    <Alert
                        description="Please make sure to upload square images , if the image is not square , it will be cropped by our system."
                        type="info"
                        showIcon
                        closable
                    />
                </Col>

                <Col xs={24}>
                    <ImageUploaderWithView
                        max_size_3_mb
                        maxWidth={250}
                        label="Upload Photo"
                        display_image={data.display_photo}
                        name="photo"
                        value={data.photo}
                        onChange={handleFieldChange}
                        error={helper_text.photo !== undefined}
                        helper_text={helper_text.photo}
                    />
                </Col>


                {/*<Col xs={24} md={18}>*/}
                {/*    <CustomTextField*/}
                {/*        required*/}
                {/*        label="Title"*/}
                {/*        name="title"*/}
                {/*        value={data.title}*/}
                {/*        onChange={handleFieldChange}*/}
                {/*        error={helper_text.title !== undefined}*/}
                {/*        helper_text={helper_text.title}*/}
                {/*    />*/}
                {/*</Col>*/}


                <Col xs={24}>
                    <CustomTextField
                        required
                        label="Order"
                        name="order"
                        value={data.order}
                        onChange={handleFieldChange}
                        error={helper_text.order !== undefined}
                        helper_text={helper_text.order}
                        type="number"
                    />
                </Col>



                <Col xs={24}>
                    <CustomCheckBox
                        label="Is Active"
                        name="is_active"
                        value={data.is_active}
                        onChange={handleFieldChange}
                    />
                </Col>

                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}
