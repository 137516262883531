import React, {useState} from "react";
import {PRIMARY_COLOR_TWO} from "../../global/variables";
import {Button, Col, Row} from "antd";
import TodayBookingNumber from "./components/TodayBookingNumber";
import WeekBookingNumber from "./components/WeekBookingNumber";
import MonthBookingNumber from "./components/MonthBookingNumber";
import YearBookingNumber from "./components/YearBookingNumber";
import TodaySalesIncoming from "./components/TodaySalesIncoming";
import WeekSalesIncoming from "./components/WeekSalesIncoming";
import MonthSalesIncoming from "./components/MonthSalesIncoming";
import YearSalesIncoming from "./components/YearSalesIncoming";
import TravelAgeScale from "./components/TravelAgeScale";
import CustomersTitleStatistic from "./components/CustomersTitleStatistic";
import BookingsStatusReport from "./components/BookingsStatusReport";
import ExperienceAgeScale from "./components/ExperienceAgeScale";
import BookingsNumberOverMonths from "./components/BookingsNumberOverMonths";
import PendingApprovalBookingsList from "./components/PendingApprovalBookingsList";
import useWindowSize from "../../global/useWindowSize";
import Hide from "../../global/components/Hide";
import TodaySalesRefund from "./components/TodaySalesRefund";
import WeekSalesRefund from "./components/WeekSalesRefund";
import MonthSalesRefund from "./components/MonthSalesRefund";
import YearSalesRefund from "./components/YearSalesRefund";


const style = {
    line_one_container: {
        borderBottomWidth: "10px",
        borderBottomColor: PRIMARY_COLOR_TWO,
        borderBottomStyle: "solid",
        borderEndEndRadius: "10px",
        borderEndStartRadius: "10px",
        height: "90px",// 140 for new update
    },
}

export const Dashboard = props => {
    const {inner_width} = useWindowSize();
    const [hidePendingApprovalBookingsList, setHidePendingApprovalBookingsList] = useState(false);

    return (
        <Row gutter={[12, 12]}>

            <Hide hide={inner_width > 767 || hidePendingApprovalBookingsList}>
                <Col xs={24} md={12}>
                    <div className="paper p-3 w-100" style={{height: "600px"}}>
                        <PendingApprovalBookingsList setHide={setHidePendingApprovalBookingsList}/>
                    </div>
                </Col>
            </Hide>

            <Col xs={24} sm={12} md={6}>
                <div className="paper p-3 w-100" style={style.line_one_container}>
                    <TodayBookingNumber {...props}/>
                </div>
            </Col>


            <Col xs={24} sm={12} md={6}>
                <div className="paper p-3 w-100" style={style.line_one_container}>
                    <WeekBookingNumber {...props}/>
                </div>
            </Col>

            <Col xs={24} sm={12} md={6}>
                <div className="paper p-3 w-100" style={style.line_one_container}>
                    <MonthBookingNumber {...props}/>
                </div>
            </Col>

            <Col xs={24} sm={12} md={6}>
                <div className="paper p-3 w-100" style={style.line_one_container}>
                    <YearBookingNumber {...props}/>
                </div>
            </Col>

            <Col xs={24} sm={12} md={6}>
                {/*<div className="paper p-3 w-100" style={{...style.line_one_container, height: "140px"}}>*/}
                <div className="paper p-3 w-100" style={style.line_one_container}>
                    <TodaySalesIncoming/>
                </div>
            </Col>

            <Col xs={24} sm={12} md={6}>
                <div className="paper p-3 w-100" style={style.line_one_container}>
                    <WeekSalesIncoming/>
                </div>
            </Col>

            <Col xs={24} sm={12} md={6}>
                <div className="paper p-3 w-100" style={style.line_one_container}>
                    <MonthSalesIncoming/>
                </div>
            </Col>

            <Col xs={24} sm={12} md={6}>
                <div className="paper p-3 w-100" style={style.line_one_container}>
                    <YearSalesIncoming/>
                </div>
            </Col>


            <Col xs={24} sm={12} md={6}>
                <div className="paper p-3 w-100" style={style.line_one_container}>
                    <TodaySalesRefund/>
                </div>
            </Col>

            <Col xs={24} sm={12} md={6}>
                <div className="paper p-3 w-100" style={style.line_one_container}>
                    <WeekSalesRefund/>
                </div>
            </Col>

            <Col xs={24} sm={12} md={6}>
                <div className="paper p-3 w-100" style={style.line_one_container}>
                    <MonthSalesRefund/>
                </div>
            </Col>

            <Col xs={24} sm={12} md={6}>
                <div className="paper p-3 w-100" style={style.line_one_container}>
                    <YearSalesRefund/>
                </div>
            </Col>


            <Hide hide={inner_width < 768 || hidePendingApprovalBookingsList}>
                <Col xs={24} md={12}>
                    <div className="paper p-3 w-100" style={{height: "300px"}}>
                        <PendingApprovalBookingsList setHide={setHidePendingApprovalBookingsList}/>
                    </div>
                </Col>
            </Hide>


            <Col xs={24} md={12}>
                <div className="paper p-3 w-100" style={{height: "300px"}}>
                    <TravelAgeScale/>
                </div>
            </Col>

            <Col xs={24} md={12}>
                <div className="paper p-3 w-100" style={{height: "300px"}}>
                    <ExperienceAgeScale/>
                </div>
            </Col>

            <Col xs={24} md={6}>
                <div className="paper p-3 w-100" style={{height: "300px"}}>
                    <CustomersTitleStatistic/>
                </div>
            </Col>

            <Col xs={24} md={6}>
                <div className="paper p-3 w-100" style={{height: "300px"}}>
                    <BookingsStatusReport/>
                </div>
            </Col>

            <Col xs={24} md={12}>
                <div className="paper p-3 w-100" style={{height: "300px"}}>
                    <BookingsNumberOverMonths/>
                </div>
            </Col>

        </Row>
    )
}
