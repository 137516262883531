import React, {useEffect, useState} from "react";
import {isValidValue} from "../../global/helper_functions";
import Hide from "../../global/components/Hide";
import {getUserType} from "../../global/auth_functions";
import {StaticLanguageTabs} from "../../global/components/StaticLanguageTabs";
import {BookingDetails} from "./BookingDetails";


export const BookingItemInfo = props => {
    const slug = props.slug || "";
    const url_params = props.match.params || {};
    const url_query = new URLSearchParams(props.location.search);
    const [booking_item_id] = React.useState(url_params.id);
    const view_payment = (url_query.get("view-payment") || "") + "";
    const view_refund = (url_query.get("view-refund") || "") + "";

    const [lang_classes, setLangClasses] = useState("");
    const [user_type] = useState(getUserType());
    const is_admin = user_type === "Admin";
    const [loading, setLoading] = useState(false);
    const [language, setLanguage] = useState("en");
    const [booking_item_data, setBookingItemData] = useState("en");


    const handleLoading = (value) =>{
        if (value){
            setTimeout(()=>{
                setLoading(false);
            },800)
        }else{
            setLoading(true)
        }
    }


    const handleLanguageChange = (new_value) => {
        handleLoading(true);
        setLanguage(new_value);
        handleLoading(false);
    };

    useEffect(()=>{
        if (props.scroll_height > 113){
            setLangClasses("top-0 position-fixed bg-white z-index-100 w-100")
        }else{
            setLangClasses("")
        }
    },[props.scroll_height])


    return (
        <>
            <div className="m-0 p-0 d-flex justify-content-between">
                <h5 className="mb-3 mt-2">View Booking > <span className="text-black-50">{booking_item_data.ref_no}</span></h5>
            </div>


            <div className="paper pt-0 w-100">
                <StaticLanguageTabs  value={language} onChange={handleLanguageChange}/>

                <Hide hide={!isValidValue(lang_classes)}>
                    <StaticLanguageTabs className={lang_classes} value={language} onChange={handleLanguageChange}/>
                </Hide>


                    <BookingDetails
                        {...props}
                        user_type={user_type}
                        language={language}
                        is_admin={is_admin}
                        booking_item_id={booking_item_id}
                        setBookingItemData={setBookingItemData}
                        view_payment={view_payment}
                        view_refund={view_refund}
                    />

            </div>

        </>)
}
