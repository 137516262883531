export const permission_data = {
  rule: {
    view: "can_view_rule",
    add: "can_add_rule",
    edit: "can_edit_rule",
    delete: "can_delete_rule",
  },
  user: {
    view: "can_view_user",
    add: "can_add_user",
    edit: "can_edit_user",
    delete: "can_delete_user",
    change_status: "can_change_user_status",
  },
  customer: {
    view: "can_view_customer",
    add: "can_add_customer",
    edit: "can_edit_customer",
    delete: "can_delete_customer",
  },
  agency: {
    view: "can_view_agency",
    add: "can_add_agency",
    edit: "can_edit_agency",
    delete: "can_delete_agency",
    has_travels: "agency_has_travels",
    has_experiences: "agency_has_experiences",
  },
  agency_contract: {
    view: "can_view_agency_contract",
    add: "can_add_agency_contract",
    edit: "can_edit_agency_contract",
    delete: "can_delete_agency_contract",
  },
  agency_document: {
    view: "can_view_agency_document",
    add: "can_add_agency_document",
    edit: "can_edit_agency_document",
    delete: "can_delete_agency_document",
  },
  country: {
    view: "can_view_country",
    add: "can_add_country",
    edit: "can_edit_country",
    delete: "can_delete_country",
  },
  city: {
    view: "can_view_city",
    add: "can_add_city",
    edit: "can_edit_city",
    delete: "can_delete_city",
  },
  language: {
    view: "can_view_language",
    add: "can_add_language",
    edit: "can_edit_language",
    delete: "can_delete_language",
  },
  advertisement: {
    view: "can_view_advertisement",
    add: "can_add_advertisement",
    edit: "can_edit_advertisement",
    delete: "can_delete_advertisement",
  },
  trusted_partner: {
    view: "can_view_trusted_partner",
    add: "can_add_trusted_partner",
    edit: "can_edit_trusted_partner",
    delete: "can_delete_trusted_partner",
  },
  global_data: {
    view: "can_view_global_data",
    add: "can_add_global_data",
    edit: "can_edit_global_data",
    delete: "can_delete_global_data",
    change_key: "can_delete_global_data",
  },
  he_media: {
    view: "can_view_he_media",
    add: "can_add_he_media",
    edit: "can_edit_he_media",
    delete: "can_delete_he_media",
  },
  service_charge: {
    view: "can_view_service_charge",
    add: "can_add_service_charge",
    edit: "can_edit_service_charge",
    delete: "can_delete_service_charge",
  },
  conversion_rate: {
    view: "can_view_conversion_rate",
    add: "can_add_conversion_rate",
    edit: "can_edit_conversion_rate",
    delete: "can_delete_conversion_rate",
  },
  currency: {
    view: "can_view_currency",
    add: "can_add_currency",
    edit: "can_edit_currency",
    delete: "can_delete_currency",
  },
  myfatoorah_payment_method: {
    view: "can_view_myfatoorah_payment_method",
    add: "can_add_myfatoorah_payment_method",
    edit: "can_edit_myfatoorah_payment_method",
    delete: "can_delete_myfatoorah_payment_method",
  },
  guru: {
    view: "can_view_guru",
    add: "can_add_guru",
    edit: "can_edit_guru",
    delete: "can_delete_guru",
  },
  package_category: {
    view: "can_view_package_category",
    add: "can_add_package_category",
    edit: "can_edit_package_category",
    delete: "can_delete_package_category",
  },
  package_inclusions: {
    view: "can_view_package_inclusions",
    add: "can_add_package_inclusions",
    edit: "can_edit_package_inclusions",
    delete: "can_delete_package_inclusions",
  },
  voucher: {
    view: "can_view_voucher",
    add: "can_add_voucher",
    edit: "can_edit_voucher",
    delete: "can_delete_voucher",
  },
  customize_voucher: {
    view: "can_view_customize_voucher",
    add: "can_add_customize_voucher",
    edit: "can_edit_customize_voucher",
    delete: "can_delete_customize_voucher",
  },
  tier: {
    view: "can_view_tier",
    add: "can_add_tier",
    edit: "can_edit_tier",
    delete: "can_delete_tier",
  },
  package: {
    view: "can_view_package",
    add: "can_add_package",
    edit: "can_edit_package",
    delete: "can_delete_package",
    view_analytics: "can_view_packages_analytics",
  },
  package_setting: {
    view: "can_view_package_setting",
    edit: "can_edit_package_setting",
    update_he_commission: "can_update_package_he_commission",
  },
  package_option: {
    view: "can_view_package_option",
    add: "can_add_package_option",
    edit: "can_edit_package_option",
    delete: "can_delete_package_option",
  },
  package_gallery: {
    view: "can_view_package_gallery",
    add: "can_add_package_gallery",
    edit: "can_edit_package_gallery",
    delete: "can_delete_package_gallery",
  },
  package_photo_album: {
    view: "can_view_package_photo_album",
    add: "can_add_package_photo_album",
    edit: "can_edit_package_photo_album",
    delete: "can_delete_package_photo_album",
  },
  package_installment: {
    view: "can_view_package_installment",
    add: "can_add_package_installment",
    edit: "can_edit_package_installment",
    delete: "can_delete_package_installment",
  },
  package_pending_change: {
    view: "can_view_package_pending_change",
    add: "can_add_package_pending_change",
    edit: "can_edit_package_pending_change",
    delete: "can_delete_package_pending_change",
  },
  change_log: {
    view: "can_view_change_log",
    add: "can_add_change_log",
    edit: "can_edit_change_log",
    delete: "can_delete_change_log",
  },
  package_itinerary: {
    view: "can_view_package_itinerary",
    add: "can_add_package_itinerary",
    edit: "can_edit_package_itinerary",
    delete: "can_delete_package_itinerary",
  },
  package_addon: {
    view: "can_view_package_addon",
    add: "can_add_package_addon",
    edit: "can_edit_package_addon",
    delete: "can_delete_package_addon",
  },
  package_option_date: {
    view: "can_view_package_option_date",
    add: "can_add_package_option_date",
    edit: "can_edit_package_option_date",
    delete: "can_delete_package_option_date",
  },
  online_payment: {
    view: "can_view_online_payment",
  },
  booking: {
    view: "can_view_booking",
    add: "can_add_booking",
    edit: "can_edit_booking",
    delete: "can_delete_booking",
    update_customer_info: "can_update_booking_customer_info",
    update_service_charge_info: "can_update_booking_service_charge_info",
    view_booking_finance: "can_view_booking_finance",
    update_transferred_amount_to_agency: "can_update_booking_transferred_amount_to_agency",
    send_payment_request: "can_send_payment_request_to_customer_by_email",
    send_payment_receipt: "can_send_payment_receipt_to_customer_by_email",
  },
  booking_payment: {
    view: "can_view_booking_payment",
    add: "can_add_booking_payment",
    edit: "can_edit_booking_payment",
    delete: "can_delete_booking_payment",
    edit_paid: "can_edit_paid_booking_payment",
  },
  booking_refund: {
    view: "can_view_booking_refund",
    add: "can_add_booking_refund",
    edit: "can_edit_booking_refund",
    delete: "can_delete_booking_refund",
  },
  booking_person: {
    view: "can_view_booking_person",
    add: "can_add_booking_person",
    edit: "can_edit_booking_person",
    delete: "can_delete_booking_person",
  },
  booking_addon: {
    view: "can_view_booking_addon",
    add: "can_add_booking_addon",
    edit: "can_edit_booking_addon",
    delete: "can_delete_booking_addon",
  },
  consultation_request: {
    view: "can_view_consultation_request",
    add: "can_add_consultation_request",
    edit: "can_edit_consultation_request",
    delete: "can_delete_consultation_request",
  },
  consultation_response: {
    view: "can_view_consultation_response",
    add: "can_add_consultation_response",
    edit: "can_edit_consultation_response",
    delete: "can_delete_consultation_response",
    send_payment_request: "can_send_payment_request_to_customer_for_consultation_response",
    can_send_consultation_response_email: "can_send_consultation_response_in_email_to_customer",
  },

  contact_us: {
    view: "can_view_contact_us",
    add: "can_add_contact_us",
    edit: "can_edit_contact_us",
    delete: "can_delete_contact_us",
    receive_email_for_contact_us_request: "receive_email_for_contact_us_request",
  },

  join_us_request: {
    view: "can_view_join_us_request",
    add: "can_add_join_us_request",
    edit: "can_edit_join_us_request",
    delete: "can_delete_join_us_request",
    receive_email_for_join_us_request: "receive_email_for_join_us_request",
  },

  product: {
    view: "can_view_product",
    add: "can_add_product",
    edit: "can_edit_product",
    delete: "can_delete_product",
  },

  product_gallery: {
    view: "can_view_product_gallery",
    add: "can_add_product_gallery",
    edit: "can_edit_product_gallery",
    delete: "can_delete_product_gallery",
  },
  product_sale: {
    view: "can_view_product_sale",
    add: "can_add_product_sale",
    edit: "can_edit_product_sale",
    delete: "can_delete_product_sale",
  },
  reports:{
    view_today_booking_number:"view_today_booking_number_report",
    view_week_booking_number:"view_week_booking_number_report",
    view_month_booking_number:"view_month_booking_number_report",
    view_year_booking_number:"view_year_booking_number_report",
    view_today_incoming:"view_today_incoming_report",
    view_week_incoming:"view_week_incoming_report",
    view_month_incoming:"view_month_incoming_report",
    view_year_incoming:"view_year_incoming_report",
    view_today_refund:"view_today_refund_report",
    view_week_refund:"view_week_refund_report",
    view_month_refund:"view_month_refund_report",
    view_year_refund:"view_year_refund_report",
    view_travel_age_scale:"view_travel_age_scale_report",
    view_experience_age_scale:"view_experience_age_scale_report",
    view_customers_statistics:"view_customers_statistics_report",
    view_bookings_status:"view_bookings_status_report",
    view_bookings_over_months:"view_bookings_over_months",
    view_bookings_revenue_over_months:"view_bookings_revenue_over_months",
    view_consultations_revenue_over_months:"view_consultations_revenue_over_months",
    view_top_packages_report:"view_top_packages_report",
    view_incoming_details_report:"view_incoming_details_report",
    view_refund_details_report:"view_refund_details_report",
  },
  invoice: {
    view: "can_view_invoice",
    add: "can_add_invoice",
    edit: "can_edit_invoice",
    delete: "can_delete_invoice",
    send_payment_request: "send_invoice_payment_request_email",
    send_payment_receipt: "send_invoice_payment_receipt_email",
  },
  testimonial: {
    view: "can_view_testimonial",
    add: "can_add_testimonial",
    edit: "can_edit_testimonial",
    delete: "can_delete_testimonial",
  },
  blog: {
    view: "can_view_blog",
    add: "can_add_blog",
    edit: "can_edit_blog",
    delete: "can_delete_blog",
  },
  tour: {
    view: "can_view_tour",
    add: "can_add_tour",
    edit: "can_edit_tour",
    delete: "can_delete_tour",
  },
};
