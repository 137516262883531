import React, {useEffect, useState} from "react";
import CustomModal from "../../../global/components/CustomModal";
import {Col, Divider, Empty, Row} from "antd";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {BookingUpdateNotificationEmailURL} from "../../../global/urls";
import Hide from "../../../global/components/Hide";
import {Loading} from "../../../global/components/Loading";
import {openNotification} from "../../../global/helper_functions";
import {ModelActionButton} from "../../../global/components/ModelActionButton";
import {CustomTextField} from "../../../global/components/fields/CustomTextField";

export const ChangeBookingNotificationEmail = (props) => {
    const booking_item = props.booking_item || "";
    const [notification_email, setNotificationEmail] = useState(props.current_value);
    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});

    const handelNotificationEmailChange = (value) =>{
        setNotificationEmail(value);
    }

    const onSave = async () =>{
         setLoading(true);
        setHelperText({})

             let response = await httpRequestWithAuth(`${BookingUpdateNotificationEmailURL}${booking_item}`, "PATCH", {notification_email, booking_item})

             if (response.status){
                 openNotification("success", "Change Booking Notification Email", `Booking notification email has been updated`)
                 props.handleModelClose();
             }else{
                 setHelperText(response.error_feedback)
             }

         setLoading(false);
    }




    return(
        <CustomModal title="Change Booking Notification Email" visible={props.open} onCancel={props.handleModelClose} small>
            <Row gutter={[12, 10]}>

                <Hide hide={!loading}>
                    <Col xs={24}>
                        <Loading/>
                    </Col>
                </Hide>

                <Hide hide={loading}>

                    <Col xs={24}>
                        <CustomTextField
                            label="Notification Email"
                            value={notification_email}
                            onChange={(name, value)=>handelNotificationEmailChange(value)}
                            error={helper_text.notification_email !== undefined}
                            helper_text={helper_text.notification_email}
                        />
                    </Col>



                    <Col xs={24} className="p-0">
                        <ModelActionButton
                            error_msg={helper_text.non_field_errors}
                            loading={loading}
                            onSave={onSave}
                            onCancel={props.handleModelClose}
                        />
                    </Col>

                </Hide>



            </Row>
        </CustomModal>
        )
}
