import {useState} from "react";
import CustomModal from "../../../global/components/CustomModal";
import {Col, Row} from "antd";
import {CustomTextField} from "../../../global/components/fields/CustomTextField";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {ITSupportVerifyPasswordRL} from "../../../global/urls";
import {isValidValue, openNotification} from "../../../global/helper_functions";
import {ModelActionButton} from "../../../global/components/ModelActionButton";
import CustomFileUpload from "../../../global/components/CustomFileUpload";
import {CustomUploadField} from "../../../global/components/fields/CustomUploadField";


const ITSupportUploadFileModal = props =>{
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});

    const handleFieldChange = (name, new_value) => {
        let temp_data = {...data};

        if (["file"].includes(name)){
            temp_data[`${name}_filename`] = isValidValue(new_value)? new_value.name: "";
        }

        temp_data[name] = new_value;

        setData(temp_data);
    }

    const onSave = async () => {
        await setLoading(true);
            await props.onSave(data);
        await setLoading(false);
    }

    return (
        <CustomModal size="xs" title="IT Support Upload File Modal" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>




                <Col xs={24}>
                    <CustomUploadField
                        label="file"
                        name="file"
                        display_value={data.file_filename}
                        value={data.file}
                        onChange={handleFieldChange}
                        helper_text={helper_text.file}
                        error={helper_text.file !== undefined}
                    />
                </Col>



                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}


export default ITSupportUploadFileModal;
