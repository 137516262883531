import {useState} from "react";
import CustomModal from "../../../../global/components/CustomModal";
import {Col, Row} from "antd";
import {CustomTextField} from "../../../../global/components/fields/CustomTextField";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {ServiceChargesURL} from "../../../../global/urls";
import {isValidValue, openNotification} from "../../../../global/helper_functions";
import {ModelActionButton} from "../../../../global/components/ModelActionButton";
import CustomCheckBox from "../../../../global/components/fields/CustomCheckBox";
import {isSuperAdmin} from "../../../../global/auth_functions";
import Hide from "../../../../global/components/Hide";
import {CustomTextAreaField} from "../../../../global/components/fields/CustomTextAreaField";
import CustomSelectField from "../../../../global/components/fields/CustomSelectField";
import {package_type} from "../../../../global/variables";


export const ServiceChargeFormModal = props => {
    const [data, setData] = useState({
        is_active: true,
        ...props.data
    });
    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});

    const handleFieldChange = (name, new_value) => {
        let temp_data = {...data};

        temp_data[name] = new_value;
        setData(temp_data);
    }

    const onSave = async () => {
        await setLoading(true);


        const is_new = !isValidValue(data.id);
        let response;

        if (is_new) {
            response = await httpRequestWithAuth(ServiceChargesURL, "POST", data);
        } else {
            response = await httpRequestWithAuth(`${ServiceChargesURL}${data.id}`, "PUT", data);
        }

        if (response.status) {
            if (is_new) {
                openNotification("success", "Add Service Charge", `${data.title} service charge has been added`)
            } else {
                openNotification("success", "Update Service Charge", `${data.title} service charge has been updated`)
            }
            props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
        }

        await setLoading(false);

    }

    return (
        <CustomModal title="Service Charge Form" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>


                <Col xs={24} md={12}>
                    <CustomTextField
                        label="Title"
                        name="title"
                        value={data.title}
                        onChange={handleFieldChange}
                        error={helper_text.title !== undefined}
                        helper_text={helper_text.title}
                    />
                </Col>

                <Col xs={24} md={12}>
                    <CustomSelectField
                        label="Package Type"
                        name="package_type"
                        value={data.package_type}
                        onChange={handleFieldChange}
                        error={helper_text.package_type !== undefined}
                        helper_text={helper_text.package_type}
                        options={package_type}
                    />
                </Col>


                <Col xs={24} md={12}>
                    <CustomTextField
                        label="From Amount"
                        name="from_amount"
                        value={data.from_amount}
                        onChange={handleFieldChange}
                        error={helper_text.from_amount !== undefined}
                        helper_text={helper_text.from_amount}
                        type="number"
                        addonAfter="KWD"
                    />
                </Col>


                <Col xs={24} md={12}>
                    <CustomTextField
                        label="Service Charge Amount"
                        name="service_charge_amount"
                        value={data.service_charge_amount}
                        onChange={handleFieldChange}
                        error={helper_text.service_charge_amount !== undefined}
                        helper_text={helper_text.service_charge_amount}
                        type="number"
                        addonAfter="KWD"
                    />
                </Col>


                <Col xs={24}>
                    <CustomCheckBox
                        label="Is Active"
                        name="is_active"
                        value={data.is_active}
                        onChange={handleFieldChange}
                    />
                </Col>

                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}
