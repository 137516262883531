import React, {useState} from "react";
import CustomModal from "../../../global/components/CustomModal";
import {Col, Row} from "antd";
import {CustomTextField} from "../../../global/components/fields/CustomTextField";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {ConsultationResponsesURL} from "../../../global/urls";
import {customMoment, isValidValue, openNotification} from "../../../global/helper_functions";
import {ModelActionButton} from "../../../global/components/ModelActionButton";
import CustomCheckBox from "../../../global/components/fields/CustomCheckBox";
import {ConsultationRequestAutoComplete} from "../../../global/components/fields/ConsultationRequestAutoComplete";
import {CustomDateTimeField} from "../../../global/components/fields/CustomDateTimeField";
import CustomDragNDrop from "../../../global/components/CustomDragNDrop";
import CustomSelectField from "../../../global/components/fields/CustomSelectField";
import {payment_methods} from "../../../global/variables";

export const ConsultationResponseFormModal = props => {
    const [data, setData] = useState({
        is_available: true,
        is_paid: false,
        discount_amount: 0,
        download_limit: 2,
        number_of_downloads: 0,
        ConsultationRequestId: props.consultation_request,
        ...props.data
    });

    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});

    const handleFieldChange = (name, new_value) => {
        let temp_data = {...data};

        temp_data[name] = new_value;

        if (name=== "consultation_file"){
            temp_data[`${name}_filename`] = isValidValue(new_value)? new_value.name: "";
        }

        setData(temp_data);
    }


    const onSave = async () => {
        setLoading(true);
        setHelperText({})


        let temp_data = {
            ...data,
            available_until: isValidValue(data.available_until) ? customMoment(data.available_until).format("DD-MM-YYYY hh:mm") : "",
            payment_date: isValidValue(data.payment_date) ? customMoment(data.payment_date).format("DD-MM-YYYY hh:mm") : "",
        }

        let form_data = new FormData();
        Object.keys(temp_data).map(data_key=>form_data.append(data_key, temp_data[data_key]));

        const is_new = !isValidValue(data.id);
        let response;

        if (is_new) {
            response = await httpRequestWithAuth(ConsultationResponsesURL, "POST", form_data);
        } else {
            response = await httpRequestWithAuth(`${ConsultationResponsesURL}${data.id}`, "PUT", form_data);
        }

        if (response.status) {
            if (is_new) {
                openNotification("success", "Add Consultation Response", `${data.ref_no} consultation response has been added`)
            } else {
                openNotification("success", "Update Consultation Response", `${data.ref_no} consultation response has been updated`)
            }
            props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
        }

        setLoading(false);

    }


    return (
        <CustomModal size="md" title="Consultation Response Form" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>

                <Col xs={24}>
                    <CustomDragNDrop
                        label={(
                            <span>
                                Click Or Drop Consultation File Here
                                <br/>
                                (PDF only)
                            </span>
                        )}
                        display_value={data.consultation_file_filename}
                        name="consultation_file"
                        value={data.consultation_file}
                        onChange={handleFieldChange}
                        error={helper_text.consultation_file !== undefined}
                        helper_text={helper_text.consultation_file}
                        accept="application/pdf"
                    />
                </Col>

                {/*<Col xs={24}>*/}
                {/*    <ConsultationRequestAutoComplete*/}
                {/*        label="Consultation Request"*/}
                {/*        name="ConsultationRequestId"*/}
                {/*        value={data.ConsultationRequestId}*/}
                {/*        onChange={handleFieldChange}*/}
                {/*        helper_text={helper_text.ConsultationRequestId}*/}
                {/*        error={helper_text.ConsultationRequestId !== undefined}*/}
                {/*    />*/}
                {/*</Col>*/}


                <Col xs={24} md={8}>
                    <CustomTextField
                        label="Download Limit"
                        name="download_limit"
                        value={data.download_limit}
                        onChange={handleFieldChange}
                        helper_text={helper_text.download_limit}
                        error={helper_text.download_limit !== undefined}
                        type="number"
                    />
                </Col>

                <Col xs={24} md={8}>
                    <CustomTextField
                        disabled
                        label="Num Of Downloads"
                        name="number_of_downloads"
                        value={data.number_of_downloads}
                    />
                </Col>

                <Col xs={24} md={8}>
                    <CustomDateTimeField
                        label="Available Until"
                        name="available_until"
                        value={data.available_until}
                        onChange={handleFieldChange}
                        helper_text={helper_text.available_until}
                        error={helper_text.available_until !== undefined}
                    />
                </Col>

                <Col xs={24} md={8}>
                    <CustomTextField
                        view_only={data.is_paid}
                        label="Price"
                        name="price"
                        value={data.price}
                        onChange={handleFieldChange}
                        helper_text={helper_text.price}
                        error={helper_text.price !== undefined}
                        type="number"
                        addonAfter="KWD"
                    />
                </Col>

                <Col xs={24} md={8}>
                    <CustomTextField
                        view_only={data.is_paid}
                        label="Discount Amount"
                        name="discount_amount"
                        value={data.discount_amount}
                        onChange={handleFieldChange}
                        helper_text={helper_text.discount_amount}
                        error={helper_text.discount_amount !== undefined}
                        type="number"
                        addonAfter="KWD"
                    />
                </Col>

                <Col xs={24} md={8}>
                    <CustomTextField
                        view_only
                        label="Net Amount"
                        name="net_amount"
                        value={data.price - data.discount_amount}
                        onChange={handleFieldChange}
                        helper_text={helper_text.net_amount}
                        error={helper_text.net_amount !== undefined}
                        type="number"
                        addonAfter="KWD"
                    />
                </Col>


                {/*<Col xs={24} md={8}>*/}
                {/*    <CustomUploadField*/}
                {/*        label="Consultation File"*/}
                {/*        display_value={data.consultation_file_filename}*/}
                {/*        name="consultation_file"*/}
                {/*        value={data.consultation_file}*/}
                {/*        onChange={handleFieldChange}*/}
                {/*        helper_text={helper_text.consultation_file}*/}
                {/*        error={helper_text.consultation_file !== undefined}*/}
                {/*    />*/}
                {/*</Col>*/}





                <Col xs={24} md={8}>
                    <CustomSelectField
                        required
                        label="Payment Method"
                        name="payment_method"
                        value={data.payment_method}
                        onChange={handleFieldChange}
                        error={helper_text.payment_method !== undefined}
                        helper_text={helper_text.payment_method}
                        options={payment_methods}
                        disabled={!data.is_paid}
                    />
                </Col>

                <Col xs={24} md={8}>
                    <CustomDateTimeField
                        disabled={!data.is_paid}
                        label="Payment Date"
                        name="payment_date"
                        value={data.payment_date}
                        onChange={handleFieldChange}
                        helper_text={helper_text.payment_date}
                        error={helper_text.payment_date !== undefined}
                    />
                </Col>




                <Col xs={24} md={8}>
                    <CustomTextField
                        disabled={!data.is_paid}
                        label="Payment Ref No"
                        name="payment_ref_no"
                        value={data.payment_ref_no}
                        onChange={handleFieldChange}
                        helper_text={helper_text.payment_ref_no}
                        error={helper_text.payment_ref_no !== undefined}
                    />
                </Col>


                <Col xs={24}>
                    <CustomCheckBox
                        label="Is Paid"
                        name="is_paid"
                        value={data.is_paid}
                        onChange={handleFieldChange}
                        helper_text={helper_text.is_paid}
                        error={helper_text.is_paid !== undefined}
                    />
                </Col>

                <Col xs={24}>
                    <CustomCheckBox
                        label="Is Available"
                        name="is_available"
                        value={data.is_available}
                        onChange={handleFieldChange}
                        helper_text={helper_text.is_available}
                        error={helper_text.is_available !== undefined}
                    />
                </Col>


                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}
