import React from "react";
import { Sidenav } from "rsuite";
// import Logo from "../../assets/img/he_logo.png"
import Logo from "../../assets/img/he_logo_no_bg.png";
import SidebarMenu from "./SidebarMenu";

const SideNavList = (props) => {

    return (
    <>
      <Sidenav.Header>
        <div className="w-100 text-center mt-lg-5 mb-5">
          <img src={Logo} className="logo" />
        </div>
      </Sidenav.Header>
            <div className="h-100 pb-5" style={{overflowY: "auto"}}>
                <SidebarMenu {...props}/>
            </div>

    </>
  );
};

export default SideNavList;
