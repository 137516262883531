import React, {useEffect} from "react";
import {Button, Col, Image, Slider, Typography} from "antd";
import {isValidValue} from "../helper_functions";
import CustomFileUpload from "./CustomFileUpload";
import {useCallback, useState} from "react";
import Cropper from "react-easy-crop";
import Hide from "./Hide";


export const ImageUploaderWithView = (props) => {
    const aspect = props.aspect || 1

    const with_crop = props.with_crop || false;
    const crop_height = props.crop_height || 300

    const avatar_shape = props.avatar_shape || false;
    const maxWidth = props.maxWidth || 450;
    const align = props.align || "center";

    const [crop_image_src, setCropImageSrc] = React.useState(null)
    const [zoom, setZoom] = useState(1)
    const [crop, setCrop] = useState({x: 0, y: 0})
    const [first_render, setFirstRender] = useState(true);

    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    useEffect(()=>{
        if (!first_render && with_crop){
            if (isValidValue(crop_image_src)){
                onSaveCrop();
            }else {
                props.onChange(props.name, props.value || "")
            }
        }

     setFirstRender(false);
    },[props.crop_now])

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])


    const handleImageChange = async (value_name, new_value) => {
        if (with_crop){
            setCropImageSrc(await readFile(new_value));
        }else{
            props.onChange(props.name, new_value);
        }
    };


    const onRemoveCropFile = async (e) => {
        setCropImageSrc(null)
    }

    const onSaveCrop = async () => {
        const croppedImage = await getCroppedImg(
            crop_image_src,
            croppedAreaPixels,
        )

        setCropImageSrc(null)
        props.onChange(props.name, croppedImage);
    }


    return (
        <div className={`w-100 h-100 d-flex justify-content-center align-items-${align}`}>

            {(isValidValue(crop_image_src) && with_crop) && (
                <div className="w-100">
                    <div className="w-100 position-relative" style={{height: crop_height}}>
                        <Cropper
                            image={crop_image_src}
                            crop={crop}
                            zoom={zoom}
                            aspect={aspect}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                        />
                    </div>
                    <div>
                        <div>
                            <Typography
                                variant="overline"
                            >
                                Zoom
                            </Typography>
                            <Slider
                                value={zoom}
                                min={1}
                                max={5}
                                step={0.1}
                                onChange={setZoom}
                            />
                        </div>

                        <div className="d-flex justify-content-end">
                            <Button
                                className="m-1"
                                type="primary"
                                danger
                                size="small"
                                onClick={onRemoveCropFile}
                            >
                                Remove
                            </Button>

                            <Hide hide={props.hide_crop_button}>
                            <Button
                                className="m-1"
                                size="small"
                                onClick={onSaveCrop}
                            >
                                Crop
                            </Button>
                            </Hide>

                        </div>

                    </div>
                </div>
            )}


            {isValidValue(props.display_image) && (
                <div className={`d-flex justify-content-center align-items-${align} m-0`}>
                    <div style={{maxWidth}}>
                        {/*<div className="position-absolute w-100 h-100 z-index-100">*/}

                        {/*</div>*/}

                        <Col xs={24} className="text-center w-100 p-0">
                            <Image
                                src={props.display_image}
                                className="h-100 w-100"
                                alt=""
                            />
                        </Col>

                        <Button
                            onClick={() => props.onChange(props.name, "")}
                            size="sm"
                            danger
                            variant="danger"
                            type="link"
                            className="w-100 text-end p-0 m-0"
                        >
                            {/*<CloseButton/>*/}
                            Remove
                        </Button>
                    </div>
                </div>
            )}


            {(!isValidValue(props.display_image) && !isValidValue(crop_image_src)) && (
                <CustomFileUpload
                    hide_remove
                    upload_to_server={props.upload_to_server}
                    avatar_shape={avatar_shape}
                    max_size_3_mb={props.max_size_3_mb}
                    name={props.name}
                    value={props.value}
                    onChange={handleImageChange}
                    label={props.label}
                    helper_text={props.helper_text}
                    error={props.error}
                    accept="image/*"
                    maxWidth={maxWidth}
                />
                )}

        </div>
    );
};


function readFile(file) {
    return new Promise((resolve) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
    })
}

export const createImage = (url) =>{
        const image = document.createElement('img')
        image.src = url
        return image;
    }

export function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width, height, rotation) {
    const rotRad = getRadianAngle(rotation)

    return {
        width:
            Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
        height:
            Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
    }
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export async function getCroppedImg(
    imageSrc,
    pixelCrop,
    rotation = 0,
    flip = { horizontal: false, vertical: false }
) {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    if (!ctx) {
        return null
    }

    const rotRad = getRadianAngle(rotation)

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
        image.width,
        image.height,
        rotation
    )

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth
    canvas.height = bBoxHeight

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
    ctx.rotate(rotRad)
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
    ctx.translate(-image.width / 2, -image.height / 2)

    // draw rotated image
    ctx.drawImage(image, 0, 0)

    // croppedAreaPixels values are bounding box relative
    // extract the cropped image using these values
    const data = ctx.getImageData(
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height
    )

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height

    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0)

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    // As a blob

    return new Promise((resolve, reject) => {
        canvas.toBlob((file) => {
            resolve(file)
        }, 'image/png')
    })
}
