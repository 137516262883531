import {Col, Row} from "antd";
import {CustomDynamicTable} from "../../../global/components/CustomDynamicTable";
import {useState} from "react";
import {MyfatoorahPaymentMethodsURL} from "../../../global/urls";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import Hide from "../../../global/components/Hide";
import {IsActiveView} from "../../../global/components/IsActiveView";
import {MyFatoorahPaymentMethodFormModal} from "./components/MyFatoorahPaymentMethodFormModal";

export const MyFatoorahPaymentMethods = props =>{
    const [open_from_data, setOpenFormData] = useState(false);
    const [form_data, setFormData] = useState({});
    const [reload, setReload] = useState(false);

    const [currency_perm] = useState({
        edit: checkPermission(permission_data.myfatoorah_payment_method.edit),
    });

    const [columns] = useState([
        {
            title: "Id",
            dataIndex: "payment_method_id",
            width: "4%",
            align: 'center'
        },
        {
            title: "Code",
            dataIndex: "payment_method_code",
            width: "8%",
        },
        {
            title: "Name En",
            dataIndex: "name_en",
            width: "31%"
        },
        {
            title: "Name Ar",
            dataIndex: "name_ar",
            width: "31%"
        },
        {
            title: "Amount",
            dataIndex: "service_charge_amount",
            width: "10%",
            render: (value, row_data) => `${value} ${row_data.service_charge_type === "Fixed"?"KWD":"%"}`
        },
        {
            title: "Activate",
            dataIndex: "is_active",
            width: "5%",
            align:"center",
            render: (value)=><IsActiveView value={value} view_only={!currency_perm.edit}/>
        },
    ])

    const handelOpenFormData = (data={})=>{
        setFormData(data)
        setOpenFormData(true)
    }

    const handelCloseFormData = (reload_data=false)=>{
        setFormData({});
        setOpenFormData(false);

        if (reload_data){
            setReload(Math.random());
        }

    }

    const onEdit = async (row_data) =>{
        handelOpenFormData(row_data)
    }



    return(
        <Row>

            <Hide hide={!open_from_data}>
                <MyFatoorahPaymentMethodFormModal
                    open={open_from_data}
                    data={form_data}
                    handleModelClose={handelCloseFormData}
                />
            </Hide>

            <Col xs={24} className="paper">
                <CustomDynamicTable
                    title="MyFatoorah Payment Methods"
                    reload_data={reload}
                    columns={columns}
                    fetch_url={MyfatoorahPaymentMethodsURL}
                    onEdit={currency_perm.edit && onEdit}
                />
            </Col>
        </Row>
    )
}