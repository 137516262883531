import React from "react";
import {Input} from "antd";
import CustomFieldWrapper from "../CustomFieldWrapper";
import 'react-quill/dist/quill.snow.css';
import Hide from "../Hide";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from 'quill-image-resize-module-react';
Quill.register('modules/imageResize', ImageResize);

const Delta = Quill.import('delta');
const Break = Quill.import('blots/break');
const Embed = Quill.import('blots/embed');

function lineBreakMatcher() {
    const newDelta = new Delta();
    newDelta.insert({'break': ''});
    return newDelta;
}

class SmartBreak extends Break {
    length () {
        return 1
    }
    value () {
        return '\n'
    }

    insertInto(parent, ref) {
        Embed.prototype.insertInto.call(this, parent, ref)
    }
}




SmartBreak.blotName = 'break';
SmartBreak.tagName = 'BR'

Quill.register(SmartBreak, true)


// https://quilljs.com/docs/modules/clipboard/
const clipboard = {
    // matchers: [
    //     ['BR', lineBreakMatcher]
    // ]
}

// https://quilljs.com/docs/formats/

const formats = [
    // 'header',
    // 'font',
    // 'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'align',
    'link',
    'image',
    'video',
    'color',
    'background',
];


// const toolbar = [
//     formats,
//     [{ list: "bullet" }, { list: "ordered" }]
// ];

const toolbar = [
    // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    // [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
    ],
    [{ 'align': [] }],
    [{ 'color': [] }, { 'background': [] }],
    ['link',
        // 'image',
        // 'video'
    ],
    ['clean']
]
const keyboard = {
    bindings: {
        space: {
            key: 32,
                handler: function (range, _context) {
                this.quill.clipboard.dangerouslyPasteHTML(
                    range.index,
                    "<span>&nbsp</span>"
                );
            }
        },
        linebreak: {
            key: 13,
            shiftKey: true,
            handler: function (range) {
                let currentLeaf = this.quill.getLeaf(range.index)[0]
                let nextLeaf = this.quill.getLeaf(range.index + 1)[0]

                this.quill.insertEmbed(range.index, 'break', true, 'user');

                // Insert a second break if:
                // At the end of the editor, OR next leaf has a different parent (<p>)
                if (nextLeaf === null || (currentLeaf.parent !== nextLeaf.parent)) {
                    this.quill.insertEmbed(range.index, 'break', true, 'user');
                }

                // Now that we've inserted a line break, move the cursor forward
                this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
            }
        }
        // linebreak: {
        //     key: 13,
        //         handler: function (range, _context) {
        //         this.quill.clipboard.dangerouslyPasteHTML(
        //             range.index,
        //             "<p><br/></p>"
        //         );
        //     }
        // }
    }
}
export const editor_modules = {
    toolbar,
    clipboard,
    keyboard,
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
    }
};

export const CustomTextAreaField = (props) => {
    const handleReactQuillFieldChange = (new_value, delta, source) => {
        if (!props.view_only && !props.disabled && source === "user") {
            props.onChange(props.name, new_value);
        }
    };

    const handleFieldChange = (new_value) => {
        if (!props.view_only && !props.disabled && new_value !== props.value) {
            props.onChange(props.name, new_value);
        }
    };


    return (
        <CustomFieldWrapper {...props}>
                <Hide hide={!props.withTextEditor}>
                <Hide hide={props.view_only}>
                <ReactQuill
                    readOnly={props.view_only}
                    className={`w-100 ${props.error ?"border border-danger ": ""}`}
                    value={props.value || ""}
                    onChange={handleReactQuillFieldChange}
                    modules={editor_modules}
                    formats={formats}
                    bounds={'#qlroot'}
                    placeholder="Write something here..."
                />
                </Hide>

                    <Hide hide={!props.view_only}>
                        <div dangerouslySetInnerHTML={{__html: props.value || "<>"}} className="border p-2 w-100"/>
                    </Hide>

                    </Hide>

            <Hide hide={props.withTextEditor}>
            <Input.TextArea
                {...props}
                name={props.name}
                value={props.value}
                status={props.error ? "error" : null}
                onChange={e=>handleFieldChange(e.target.value)}
                rows={props.rows || 4}
            />
            </Hide>
        </CustomFieldWrapper>
    );
};
