import {Col, Row} from "antd";
import {CustomDynamicTable} from "../../../global/components/CustomDynamicTable";
import {useState} from "react";
import {ConversionRatesURL} from "../../../global/urls";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import {AddButton} from "../../../global/components/AddButton";
import {ConversionRateFormModal} from "./components/ConversionRateFormModal";
import Hide from "../../../global/components/Hide";


export const ConversionRates = props => {
    const [open_from_data, setOpenFormData] = useState(false);
    const [form_data, setFormData] = useState({});
    const [reload, setReload] = useState(false);

    const [conversion_rate_perm] = useState({
        delete: checkPermission(permission_data.conversion_rate.delete),
        edit: checkPermission(permission_data.conversion_rate.edit),
        add: checkPermission(permission_data.conversion_rate.add),
    });


    const [columns] = useState([
        {
            title: "From",
            dataIndex: "from_currency",
            width: "40%",
        },
        {
            title: "To",
            dataIndex: "to_currency",
            width: "15%",
        },
        {
            title: "Rate",
            dataIndex: "rate",
            width: "15%",
            align: "center",
        },
        {
            title: "HE Rate",
            dataIndex: "he_rate",
            width: "15%",
            align: "center",
        },
    ])




    const handelOpenFormData = (data = {}) => {
        setFormData(data)
        setOpenFormData(true)
    }

    const handelCloseFormData = (reload_data = false) => {
        setFormData({});
        setOpenFormData(false);

        if (reload_data) {
            setReload(Math.random());
        }

    }

    const onEdit = async (row_data) => {
        handelOpenFormData(row_data)
    }

    const onAdd = async () => {
        handelOpenFormData()
    }

    const freeAction = (
        <>
            <AddButton onClick={onAdd}>Add Conversion Rate</AddButton>
        </>
    );

    return (
        <Row>
            <Hide hide={!open_from_data}>
                <ConversionRateFormModal
                    open={open_from_data}
                    data={form_data}
                    handleModelClose={handelCloseFormData}
                />
            </Hide>

            <Col xs={24} className="paper">
                <CustomDynamicTable
                    title="Conversion Rate"
                    reload_data={reload}
                    columns={columns}
                    fetch_url={ConversionRatesURL}
                    free_action={freeAction}
                    onEdit={conversion_rate_perm.edit && onEdit}
                />
            </Col>
        </Row>
    )
}