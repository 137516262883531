import {Button, Col, Image, Row, Tooltip} from "antd";
import {CustomDynamicTable} from "../../../global/components/CustomDynamicTable";
import {useEffect, useState} from "react";
import {PackagesGalleryURL} from "../../../global/urls";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {isValidValue, openNotification} from "../../../global/helper_functions";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import {AddButton} from "../../../global/components/AddButton";
import Hide from "../../../global/components/Hide";
import {IsActiveView} from "../../../global/components/IsActiveView";
import {PackageGalleryFormModal} from "./components/PackageGalleryFormModal";
import {CustomStatusView} from "../../../global/components/CustomStatusView";
import {PRIMARY_COLOR, WARNING_COLOR} from "../../../global/variables";
import CustomBadge from "../../../global/components/CustomBadge";
import {InfoIconGreenFilled} from "../../../global/components/Icons";
import React from "react";
import {ModelActionButton} from "../../../global/components/ModelActionButton";

export const PackageGallery = props =>{
    const package_id = props.package_id || "";
    const slug = props.slug || "";
    const {is_admin, has_pending_status, approve_pending_status_loading, approveAllPendingStatus} = props;
    const [open_from_data, setOpenFormData] = useState(false);
    const [form_data, setFormData] = useState({});
    const [reload, setReload] = useState(false);

    const [package_gallery_perm] = useState({
        delete: checkPermission(permission_data.package_gallery.delete),
        edit: checkPermission(permission_data.package_gallery.edit),
        add: checkPermission(permission_data.package_gallery.add),
    });

    const goToPendingChanges = (row_data) =>{
            props.handleTabsChange("7", {search: row_data.id})
    }

    const [columns] = useState([
        {
            title:"Pending Changes",
            dataIndex: "has_changes",
            width: "5%",
            align:"center",
            render: (value, row_data)=> {
                if (!value)return <CustomBadge type="disabled" title="No Changes"/>;
                return(
                        <CustomBadge
                            bg_color={WARNING_COLOR}
                            title="View Changes"
                            onClick={()=>goToPendingChanges(row_data)}
                        />
                )
            }
        },
        {
            title: "Photo",
            dataIndex: "display_photo",
            width: "40%",
            align: "center",
            render:(value)=>(
            <Image
                width={200}
                src={value}
            />
            )
        },
        {
            title: "Order",
            dataIndex: "order",
            width: "15%",
            align: "center"
        },
        {
            title: "Status",
            dataIndex: "status",
            width: "5%",
            align:"center",
            render: (value)=> <CustomStatusView value={value}/>
        },

        {
            title: "Activate",
            dataIndex: "is_active",
            width: "5%",
            align:"center",
            render: (value)=><IsActiveView value={value} view_only={!package_gallery_perm.edit}/>
        },

    ]);


    const handelOpenFormData = (data={})=>{
        setFormData(data)
        setOpenFormData(true)
    }

    const handelCloseFormData = (reload_data=false)=>{
        setFormData({});
        setOpenFormData(false);

        if (reload_data){
            setReload(Math.random());
            props.getPackageData();
        }

    }

    const onDelete = async (row_data) =>{
        let response = await httpRequestWithAuth(`${PackagesGalleryURL}${row_data.id}`, "DELETE");
        if (response.status){
            openNotification("success", "Delete Package Gallery", `${row_data.title} package gallery has been deleted`)
        }else{
            openNotification("error", "Delete Package Gallery", response.error_feedback.non_field_errors)
        }
        props.getPackageData();
    }

    const onEdit = async (row_data) =>{
        handelOpenFormData({...row_data, PackageId: package_id});
    }

    const onAdd = async () =>{
        handelOpenFormData({PackageId: package_id})
    }

    const onApproveAllPending = () => {
        setReload(Math.random());
    }

    const approveAllPendingStatusBtn = () =>{
        approveAllPendingStatus("PackageGallery", onApproveAllPending)
    }

    const freeAction = (
        <>
            <Hide hide={!has_pending_status || !is_admin}>
                <Button
                    block
                    type="primary"
                    className="mb-1 m-md-1 justify-content-center"
                    onClick={approveAllPendingStatusBtn}
                    loading={approve_pending_status_loading}
                >
                    Approve All Pending Status
                </Button>
            </Hide>

            <AddButton onClick={onAdd}>Add Photo</AddButton>
        </>
    );

    return(
        <div className="paper pt-0 w-100">

        <Row>
            <Hide hide={!open_from_data}>
                <PackageGalleryFormModal
                    open={open_from_data}
                    data={form_data}
                    handleModelClose={handelCloseFormData}
                />
            </Hide>

                <CustomDynamicTable
                    title={
                    <label className="text-start">
                        Gallery
                        <Tooltip
                            className="ms-1"
                            placement="topLeft"
                            title="Consists of the photos displayed for the package on the website"
                            color={PRIMARY_COLOR}
                            arrowPointAtCenter
                        >
                                                                   <span className="p-0 m-0">
                                                                       <InfoIconGreenFilled/>
                                                                   </span>
                        </Tooltip>
                        <br/>
                        <span className="font-size-13 text-black-50">Please upload maximum of 5 photos</span>
                    </label>
                    }
                    reload_data={reload}
                    columns={columns}
                    fetch_url={PackagesGalleryURL}
                    free_action={freeAction}
                    onDelete={package_gallery_perm.delete && onDelete}
                    onEdit={package_gallery_perm.edit && onEdit}
                    custom_params={{"package": package_id}}
                />

            <Col xs={24} className="mt-3">
                <ModelActionButton
                    hide_icon
                    cancel_label="Back"
                    onCancel={props.onBack}
                    saveLabel="Next"
                    onSave={props.onNext}
                />
            </Col>
        </Row>
        </div>
    )
}
