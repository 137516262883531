import {useState} from "react";
import CustomModal from "../../../../global/components/CustomModal";
import {Col, Divider, Row} from "antd";
import {CustomTextField} from "../../../../global/components/fields/CustomTextField";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {CustomersURL} from "../../../../global/urls";
import {isValidValue, openNotification} from "../../../../global/helper_functions";
import {ModelActionButton} from "../../../../global/components/ModelActionButton";
import {ImageUploaderWithView} from "../../../../global/components/ImageUploaderWithView";
import {CustomPhoneNumberField} from "../../../../global/components/fields/CustomPhoneNumberField";
import {CustomDateField} from "../../../../global/components/fields/CustomDateField";
import {CustomUploadField} from "../../../../global/components/fields/CustomUploadField";
import useWindowSize from "../../../../global/useWindowSize";
import {AllWorldCountriesAutoComplete} from "../../../../global/components/fields/AllWorldCountriesAutoComplete";
import Hide from "../../../../global/components/Hide";
import {NationalityAutoComplete} from "../../../../global/components/fields/NationalityAutoComplete";
import CustomSelectField from "../../../../global/components/fields/CustomSelectField";
import {title_options} from "../../../../global/variables";


export const CustomerFormModal = props => {
    const {inner_width}=useWindowSize()
    const is_new = !isValidValue(props.data.id);
    const [data, setData] = useState({
        current_rank: 0,
        ...props.data
    });

    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});

    const handleFieldChange = (name, value) => {

        let temp_data = {...data};

        temp_data[name] = value;

        if (name === "profile_image") {
            temp_data[`display_${name}`] = isValidValue((value || {}).size) ? URL.createObjectURL(value) : null;
        }

        if (["passport_copy"].includes(name)){
            temp_data[`${name}_filename`] = isValidValue(value)? value.name: "";
        }

        let temp_helper = {...helper_text};
        delete temp_helper[name];

        if (['confirm_password', 'password'].includes(name)) {
            if (temp_data.confirm_password !== temp_data.password && isValidValue(temp_data.confirm_password)) {
                temp_helper['confirm_password'] = "Password does not match";
            }
        }

        setHelperText(temp_helper);
        setData(temp_data);
    }

    const onSave = async () => {
        await setLoading(true);


        if (data.confirm_password !== data.password) {
            setHelperText({...helper_text, confirm_password: "Password does not match"})
            await setLoading(false);
            return
        }

        const form_data = new FormData();

        Object.keys(data).map(Name => {
            if (isValidValue(data[Name]))form_data.append(Name, data[Name]);
        });

        const is_new = !isValidValue(data.id);
        let response;

        if (is_new) {
            response = await httpRequestWithAuth(CustomersURL, "POST", form_data);
        } else {
            response = await httpRequestWithAuth(`${CustomersURL}${data.id}`, "PUT", form_data);
        }

        if (response.status) {
            if (is_new) {
                openNotification("success", "Add Customer", `${response.data.full_name} customer has been added`)
            } else {
                openNotification("success", "Update Customer", `${response.data.full_name} customer has been updated`)
            }
            props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
        }

        await setLoading(false);

    }

    return (
        <CustomModal size="md" title="Customer Form" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]} className="mt-2">

                <Col order={1} xs={24} md={6}>
                    <Row gutter={[12, 10]} className="h-100">
                        <Col xs={24}>
                            <ImageUploaderWithView
                                max_size_3_mb
                                avatar_shape
                                maxWidth={300}
                                align="top"
                                display_image={data.display_profile_image}
                                label="Profile Image"
                                name="profile_image"
                                value={data.profile_image}
                                onChange={handleFieldChange}
                                error={helper_text.profile_image !== undefined}
                                helper_text={helper_text.profile_image}
                            />
                        </Col>

                    </Row>
                </Col>


                <Col  order={2} xs={24} md={18}>
                    <Row gutter={[12, 10]}>

                        <Col xs={24}>
                            <Divider orientation="left" orientationMargin="0" className="m-0 fw-bold font-size-18">
                                Account Info
                            </Divider>
                        </Col>

                        <Col xs={24} md={3}>
                            <CustomSelectField
                                disabled={!is_new}
                                required
                                label="Title"
                                name="title"
                                value={data.title}
                                onChange={handleFieldChange}
                                error={helper_text.title !== undefined}
                                helper_text={helper_text.title}
                                options={title_options}
                            />
                        </Col>

                        <Col xs={24} md={9}>
                            <CustomTextField
                                disabled={!is_new}
                                required
                                label="First Name"
                                name="first_name"
                                value={data.first_name}
                                onChange={handleFieldChange}
                                error={helper_text.first_name !== undefined}
                                helper_text={helper_text.first_name}
                            />
                        </Col>

                        <Col xs={24} md={12}>
                            <CustomTextField
                                disabled={!is_new}
                                required
                                label="Last Name"
                                name="last_name"
                                value={data.last_name}
                                onChange={handleFieldChange}
                                error={helper_text.last_name !== undefined}
                                helper_text={helper_text.last_name}
                            />
                        </Col>


                        <Col xs={24} md={12}>
                            <CustomTextField
                                disabled={!is_new}
                                required
                                label="Email"
                                name="email"
                                value={data.email}
                                onChange={handleFieldChange}
                                error={helper_text.email !== undefined}
                                helper_text={helper_text.email}
                            />
                        </Col>


                        <Col xs={24} md={12}>
                            <CustomPhoneNumberField
                                disabled={!is_new}
                                required
                                label="Contact Number"
                                name="contact_number"
                                value={data.contact_number}
                                onChange={handleFieldChange}
                                error={helper_text.contact_number !== undefined}
                                helper_text={helper_text.contact_number}
                            />
                        </Col>


                <Hide hide={!is_new}>
                            <Col xs={24} md={12}>
                                <CustomTextField
                                    required
                                    label="Password"
                                    name="password"
                                    value={data.password}
                                    onChange={handleFieldChange}
                                    error={helper_text.password !== undefined}
                                    helper_text={helper_text.password}
                                    type="password"
                                />
                            </Col>

                            <Col xs={24} md={12}>
                                <CustomTextField
                                    required
                                    label="Confirm Password"
                                    name="confirm_password"
                                    value={data.confirm_password}
                                    onChange={handleFieldChange}
                                    error={helper_text.confirm_password !== undefined}
                                    helper_text={helper_text.confirm_password}
                                    type="password"
                                />
                            </Col>
                        </Hide>


                        <Col xs={24} className="mt-2">
                            <Divider orientation="left" orientationMargin="0" className="m-0 fw-bold font-size-18">
                                Personal Info
                            </Divider>
                        </Col>


                        <Col xs={24} md={12}>
                            <CustomTextField
                                label="Passport Number"
                                name="passport_number"
                                value={data.passport_number}
                                onChange={handleFieldChange}
                                error={helper_text.passport_number !== undefined}
                                helper_text={helper_text.passport_number}
                            />
                        </Col>


                        <Col xs={24} md={12}>
                            <CustomDateField
                                label="Birthdate"
                                name="birthdate"
                                value={data.birthdate}
                                onChange={handleFieldChange}
                                error={helper_text.birthdate !== undefined}
                                helper_text={helper_text.birthdate}
                                open_on="year"
                            />
                        </Col>

                        <Col xs={24} md={12}>
                            <AllWorldCountriesAutoComplete
                                label="Country Of Residence"
                                name="country_of_residence"
                                value={data.country_of_residence}
                                onChange={handleFieldChange}
                                error={helper_text.country_of_residence !== undefined}
                                helper_text={helper_text.country_of_residence}
                            />
                        </Col>

                        <Col xs={24} md={12}>
                            <NationalityAutoComplete
                                label="Nationality"
                                name="nationality"
                                value={data.nationality}
                                onChange={handleFieldChange}
                                error={helper_text.nationality !== undefined}
                                helper_text={helper_text.nationality}
                            />
                        </Col>


                        <Col xs={24} md={12}>
                            <CustomTextField
                                label="Rank"
                                name="current_rank"
                                value={data.current_rank}
                                onChange={handleFieldChange}
                                error={helper_text.current_rank !== undefined}
                                helper_text={helper_text.current_rank}
                                type="number"
                            />
                        </Col>

                        <Col xs={24} md={12}>
                            <CustomUploadField
                                label="Passport Copy"
                                name="passport_copy"
                                display_value={data.passport_copy_filename}
                                value={data.passport_copy}
                                onChange={handleFieldChange}
                                error={helper_text.passport_copy !== undefined}
                                helper_text={helper_text.passport_copy}
                            />
                        </Col>

                        <Col xs={24}>
                            <CustomTextField
                                label="Full Name In Passport"
                                name="full_name_in_passport"
                                value={data.full_name_in_passport}
                                onChange={handleFieldChange}
                                error={helper_text.full_name_in_passport !== undefined}
                                helper_text={helper_text.full_name_in_passport}
                            />
                        </Col>


                        <Col xs={24}>
                            <CustomTextField
                                label="Full Address"
                                name="full_address"
                                value={data.full_address}
                                onChange={handleFieldChange}
                                error={helper_text.full_address !== undefined}
                                helper_text={helper_text.full_address}
                            />
                        </Col>

                    </Row>
                </Col>



                {/*<Col order={inner_width>767?3: 4}  xs={24} md={6}>*/}
                {/*    <Row gutter={[12, 10]}>*/}

                {/*        <Col xs={24}>*/}
                {/*            <CustomTextField*/}
                {/*                label="rank"*/}
                {/*                name="rank"*/}
                {/*                value={data.rank}*/}
                {/*                onChange={handleFieldChange}*/}
                {/*                error={helper_text.rank !== undefined}*/}
                {/*                helper_text={helper_text.rank}*/}
                {/*                type="number"*/}
                {/*            />*/}
                {/*        </Col>*/}

                {/*        <Col xs={24}>*/}
                {/*            <CustomUploadField*/}
                {/*                label="Passport Copy"*/}
                {/*                name="passport_copy"*/}
                {/*                display_value={data.passport_copy_filename}*/}
                {/*                value={data.passport_copy}*/}
                {/*                onChange={handleFieldChange}*/}
                {/*                error={helper_text.passport_copy !== undefined}*/}
                {/*                helper_text={helper_text.passport_copy}*/}
                {/*            />*/}
                {/*        </Col>*/}
                {/*    </Row>*/}
                {/*</Col>*/}



                {/*<Col  order={inner_width>767?4: 3}  xs={24} md={18}>*/}
                {/*    <Row gutter={[12, 10]}>*/}

                {/*        <Col xs={24}>*/}
                {/*            <CustomTextField*/}
                {/*                label="Full Name In Passport"*/}
                {/*                name="full_name_in_passport"*/}
                {/*                value={data.full_name_in_passport}*/}
                {/*                onChange={handleFieldChange}*/}
                {/*                error={helper_text.full_name_in_passport !== undefined}*/}
                {/*                helper_text={helper_text.full_name_in_passport}*/}
                {/*            />*/}
                {/*        </Col>*/}


                {/*        <Col xs={24}>*/}
                {/*            <CustomTextField*/}
                {/*                label="Full Address"*/}
                {/*                name="full_address"*/}
                {/*                value={data.full_address}*/}
                {/*                onChange={handleFieldChange}*/}
                {/*                error={helper_text.full_address !== undefined}*/}
                {/*                helper_text={helper_text.full_address}*/}
                {/*            />*/}
                {/*        </Col>*/}

                {/*    </Row>*/}
                {/*</Col>*/}

                <Col order={5}  xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}
