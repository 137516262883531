import {useState} from "react";
import CustomModal from "../../../global/components/CustomModal";
import {Col, Row} from "antd";
import {CustomTextField} from "../../../global/components/fields/CustomTextField";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {ContactUsURL} from "../../../global/urls";
import {isValidValue, openNotification} from "../../../global/helper_functions";
import {ModelActionButton} from "../../../global/components/ModelActionButton";
import {CustomPhoneNumberField} from "../../../global/components/fields/CustomPhoneNumberField";
import {CustomTextAreaField} from "../../../global/components/fields/CustomTextAreaField";
import CustomSelectField from "../../../global/components/fields/CustomSelectField";
import Hide from "../../../global/components/Hide";


const purpose_of_contact_options = [
    {title: "Package Inquiry", value: "Package Inquiry"},
    {title: "Team Building Activities", value: "Team Building Activities"},
    {title: "Business Consulting Services", value: "Business Consulting Services"},
    {title: "General Questions", value: "General Questions"},
    {title: "Other", value: "Other"},
]

const status_options = [
    {title: "New", value: "New"},
    {title: "Done", value: "Done"},
    {title: "Cancelled", value: "Cancelled"},
]


const participants_gender_options = [
    {title: "Males", value: "Males"},
    {title: "Females", value: "Females"},
    {title: "Mixed", value: "Mixed"},
]

const time_of_activity_options = [
    {title: "Morning (During work week)", value: "Morning (During work week)"},
    {title: "Morning (Weekend)", value: "Morning (Weekend)"},
    {title: "Evening (During work week)", value: "Evening (During work week)"},
    {title: "Evening (Weekend)", value: "Evening (Weekend)"},
]

export const ContactUsFormModal = props => {
    const [data, setData] = useState({
        status: "New",
        ...props.data
    });

    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});

    const handleFieldChange = (name, new_value) => {
        let temp_data = {...data};

        temp_data[name] = new_value;

        setData(temp_data);
    }


    const onSave = async () => {
        await setLoading(true);


        const is_new = !isValidValue(data.id);
        let response;

        if (is_new) {
            response = await httpRequestWithAuth(ContactUsURL, "POST", data);
        } else {
            response = await httpRequestWithAuth(`${ContactUsURL}${data.id}`, "PUT", data);
        }

        if (response.status) {
            if (is_new) {
                openNotification("success", "Add Contact Us", `${data.subject} contact us has been added`)
            } else {
                openNotification("success", "Update Contact Us", `${data.subject} contact us has been updated`)
            }
            props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
        }

        await setLoading(false);

    }


    return (
        <CustomModal title="Contact Us Form" visible={props.open} onCancel={props.handleModelClose} size="md">
            <Row gutter={[12, 10]}>


                <Col xs={24} md={8}>
                    <CustomTextField
                        label="Name"
                        name="name"
                        value={data.name}
                        onChange={handleFieldChange}
                        helper_text={helper_text.name}
                        error={helper_text.name !== undefined}
                    />
                </Col>


                <Col xs={24} md={8}>
                    <CustomTextField
                        label="Email"
                        name="email"
                        value={data.email}
                        onChange={handleFieldChange}
                        helper_text={helper_text.email}
                        error={helper_text.email !== undefined}
                    />
                </Col>


                <Col xs={24} md={8}>
                    <CustomPhoneNumberField
                        label="Contact Number"
                        name="contact_number"
                        value={data.contact_number}
                        onChange={handleFieldChange}
                        helper_text={helper_text.contact_number}
                        error={helper_text.contact_number !== undefined}
                    />
                </Col>


                <Col xs={24} md={10}>
                    <CustomSelectField
                        label="Purpose Of Contact"
                        name="purpose_of_contact"
                        value={data.purpose_of_contact}
                        onChange={handleFieldChange}
                        helper_text={helper_text.purpose_of_contact}
                        error={helper_text.purpose_of_contact !== undefined}
                        options={purpose_of_contact_options}
                    />
                </Col>

                    <Col xs={24} md={10}>
                        <CustomTextField
                            label={data.purpose_of_contact === "Package Inquiry"?"Name of Package or URL Link":"Subject"}
                            name="subject"
                            value={data.subject}
                            onChange={handleFieldChange}
                            helper_text={helper_text.subject}
                            error={helper_text.subject !== undefined}
                        />
                    </Col>

                <Col xs={24} md={4}>
                    <CustomSelectField
                        label="Status"
                        name="status"
                        value={data.status}
                        onChange={handleFieldChange}
                        helper_text={helper_text.status}
                        error={helper_text.status !== undefined}
                        options={status_options}
                    />
                </Col>

                <Hide hide={data.purpose_of_contact !== "Team Building Activities"}>


                    <Col xs={24} md={8}>
                        <CustomTextField
                            label="Company Name"
                            name="company_name"
                            value={data.company_name}
                            onChange={handleFieldChange}
                            helper_text={helper_text.company_name}
                            error={helper_text.company_name !== undefined}
                        />
                    </Col>


                    <Col xs={24} md={8}>
                        <CustomTextField
                            label="Company Socials"
                            name="company_socials"
                            value={data.company_socials}
                            onChange={handleFieldChange}
                            helper_text={helper_text.company_socials}
                            error={helper_text.company_socials !== undefined}
                        />
                    </Col>


                    <Col xs={24} md={8}>
                        <CustomTextField
                            label="Company Website"
                            name="company_website"
                            value={data.company_website}
                            onChange={handleFieldChange}
                            helper_text={helper_text.company_website}
                            error={helper_text.company_website !== undefined}
                        />
                    </Col>


                    <Col xs={24} md={8}>
                        <CustomTextField
                            label="Age Range"
                            name="age_range_of_participants"
                            value={data.age_range_of_participants}
                            onChange={handleFieldChange}
                            helper_text={helper_text.age_range_of_participants}
                            error={helper_text.age_range_of_participants !== undefined}
                        />
                    </Col>


                    <Col xs={24} md={8}>
                        <CustomSelectField
                            label="Participants Gender"
                            name="participants_gender"
                            value={data.participants_gender}
                            onChange={handleFieldChange}
                            helper_text={helper_text.participants_gender}
                            error={helper_text.participants_gender !== undefined}
                            options={participants_gender_options}
                        />
                    </Col>

                    <Col xs={24} md={8}>
                        <CustomTextField
                            label="Participants Num."
                            name="number_of_participants"
                            value={data.number_of_participants}
                            onChange={handleFieldChange}
                            helper_text={helper_text.number_of_participants}
                            error={helper_text.number_of_participants !== undefined}
                            type="number"
                        />
                    </Col>


                    <Col xs={24} md={8}>
                        <CustomTextField
                            label="Budget Per Person"
                            name="estimated_budget_per_person"
                            value={data.estimated_budget_per_person}
                            onChange={handleFieldChange}
                            helper_text={helper_text.estimated_budget_per_person}
                            error={helper_text.estimated_budget_per_person !== undefined}
                            type="number"
                        />
                    </Col>

                    <Col xs={24} md={8}>
                        <CustomSelectField
                            label="Activity Time"
                            name="time_of_activity"
                            value={data.time_of_activity}
                            onChange={handleFieldChange}
                            helper_text={helper_text.time_of_activity}
                            error={helper_text.time_of_activity !== undefined}
                            options={time_of_activity_options}
                        />
                    </Col>


                    <Col xs={24} md={8}>
                        <CustomTextField
                            label="Activity Duration"
                            name="duration_of_activity"
                            value={data.duration_of_activity}
                            onChange={handleFieldChange}
                            helper_text={helper_text.duration_of_activity}
                            error={helper_text.duration_of_activity !== undefined}
                        />
                    </Col>

                    <Col xs={24} >
                        <CustomTextField
                            label="Activity Type"
                            name="type_of_activity"
                            value={data.type_of_activity}
                            onChange={handleFieldChange}
                            helper_text={helper_text.type_of_activity}
                            error={helper_text.type_of_activity !== undefined}
                        />
                    </Col>


                </Hide>




                <Hide hide={data.purpose_of_contact !== "Business Consulting Services"}>
                    <Col xs={24}>
                        <CustomTextField
                            label="Type of consulting"
                            name="type_of_consulting"
                            value={data.type_of_consulting}
                            onChange={handleFieldChange}
                            helper_text={helper_text.type_of_consulting}
                            error={helper_text.type_of_consulting !== undefined}
                        />
                    </Col>
                </Hide>

                <Col xs={24}>
                    <CustomTextAreaField
                        label="Message"
                        name="message"
                        value={data.message}
                        onChange={handleFieldChange}
                        helper_text={helper_text.message}
                        error={helper_text.message !== undefined}
                    />
                </Col>



                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}
