import React, {useEffect, useState} from "react";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {isValidValue, openNotification, showError, upperCaseView} from "../../../global/helper_functions";
import {Button, Col, Divider, Row} from "antd";
import Hide from "../../../global/components/Hide";
import {
    DollarOutlined,
} from "@ant-design/icons";
import {ViewInfo} from "../../../global/components/ViewInfo";
import CustomBadge from "../../../global/components/CustomBadge";
import {Loading} from "../../../global/components/Loading";
import {
    ProductsSalesSendPaymentReceiptURL,
    ProductsSalesSendPaymentRequestURL,
    ProductsSalesURL
} from "../../../global/urls";
import {ActionButton} from "../../../global/components/ActionButton";
import {ChangeDownloadsLimitFormModal} from "./components/ChangeDownloadsLimitFormModal";

export const ProductSaleDetails = props => {
    const slug = props.slug || "";
    const is_admin = props.is_admin || "";
    const language = props.language || "";
    const product_sale_id = props.product_sale_id || "";
    const setProductSaleData = props.setProductSaleData;
    const [loading, setLoading] = useState(false);
    const [send_payment_receipt_loading, setSendPaymentReceiptLoading] = useState(false);
    const [send_payment_request_loading, setSendPaymentRequestLoading] = useState(false);
    const [data, setData] = useState({});
    const [open_change_number_of_downloads, setOpenChangeDownloadsLimit] = useState(false);
    const [change_number_of_downloads_data, setChangeDownloadsLimitData] = useState({});

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        setLoading(true)
        let response = await httpRequestWithAuth(`${ProductsSalesURL}${product_sale_id}`, "GET");

        if (response.status) {
            setData({
                ...response.data,
            });
            setProductSaleData(response.data);
        } else {
            showError("Fetch data failed", response.error_feedback.non_field_errors);
            onCancel();
        }
        setLoading(false)
    }

    const onCancel = () => {
        props.history.push(`/${slug}/products-sales`);
    }



    const sendPaymentReceipt = async () => {
      setSendPaymentReceiptLoading(true);
      let response = await httpRequestWithAuth(ProductsSalesSendPaymentReceiptURL(data.id), "POST")

        if (response.data){
            openNotification("success", "Send Payment Receipt", `Email has been sent`)
        }else{
            openNotification("error", "Send Payment Receipt", response.error_feedback.non_field_errors)
        }
        setSendPaymentReceiptLoading(false);
    }

    const sendPaymentRequest = async () => {
        setSendPaymentRequestLoading(true);
      let response = await httpRequestWithAuth(ProductsSalesSendPaymentRequestURL(data.id), "POST")

        if (response.data){
            openNotification("success", "Send Payment Request", `Email has been sent`)
        }else{
            openNotification("error", "Send Payment Request", response.error_feedback.non_field_errors)
        }
        setSendPaymentRequestLoading(false);
    }

    const openChangeDownloadLimit = (row_data={}) => {
      setOpenChangeDownloadsLimit(true)
        setChangeDownloadsLimitData(row_data)
    }

    const closeChangeDownloadLimit = (reload=false) => {
      setOpenChangeDownloadsLimit(false)
        setChangeDownloadsLimitData({})

        if (reload){
            fetchData()
        }
    }

    return (
        <div className="paper pt-0 w-100">

            <Hide hide={!open_change_number_of_downloads}>
                <ChangeDownloadsLimitFormModal
                    open={open_change_number_of_downloads}
                    data={change_number_of_downloads_data}
                    handleModelClose={closeChangeDownloadLimit}
                />
            </Hide>

            <Row gutter={[12, 10]} className="p-2 pt-0">

                {/*<Col xs={24}>*/}
                {/*    <h2 className="font-size-18 mb-0">*/}
                {/*        <label className="text-start">*/}
                {/*            Details*/}
                {/*        </label>*/}
                {/*    </h2>*/}
                {/*</Col>*/}


                {/*<Hide hide={open_tab !== "1"}>*/}
                <Hide hide={!loading}>
                    <Loading/>
                </Hide>

                <Hide hide={loading}>
                    <Col xs={24} className="d-md-flex align-items-center justify-content-between">
                        <h2 className="font-size-20 d-flex align-items-center m-0">
                            <label className="text-start">
                                Details
                            </label>
                            <Hide hide={!data.is_paid}><CustomBadge className="ms-2" title="Paid" font_size="12"/></Hide>
                        </h2>


                        <div className="d-flex justify-content-center">
                                <Hide hide={!data.is_paid}>
                                    <Button
                                        block
                                        className="font-size-14 mt-3 m-md-0"
                                        type="primary"
                                        icon={<DollarOutlined/>}
                                        onClick={sendPaymentReceipt}
                                        loading={send_payment_receipt_loading}
                                    >Send Payment Receipt</Button>
                                </Hide>

                                <Hide hide={data.is_paid}>
                                    <Button
                                        block
                                        className="font-size-14 mt-3 m-md-0"
                                        type="primary"
                                        icon={<DollarOutlined/>}
                                        onClick={sendPaymentRequest}
                                        loading={send_payment_request_loading}
                                    >Send Payment Request</Button>
                                </Hide>
                        </div>

                    </Col>


                    <Col xs={24}>
                        <Row gutter={[12, 10]} className="border ps-2 pe-2 pt-3 pb-3 border-radius-10 secondary-6-bg">

                                <Col xs={24} md={6}>
                                    <ViewInfo
                                        title="Product Sale Ref"
                                        value={data.ref_no || "-----"}
                                    />
                                </Col>


                                <Col xs={24} md={6}>
                                    <ViewInfo
                                        title="Type"
                                        value={data.product_type}
                                    />
                                </Col>


                            <Col xs={24} md={6}>
                                <ViewInfo
                                    title="Purchase Date"
                                    value={data.created_at_view}
                                />
                            </Col>


                            <Col xs={24} md={6}>
                                <ViewInfo
                                    title="Payment Status"
                                    value={(
                                        <CustomBadge type={data.is_paid? "":"disabled"} title={data.is_paid?"Paid":"Unpaid"}/>
                                    )}
                                />
                            </Col>


                                <Col xs={24} md={6}>
                                    <ViewInfo
                                        title="Customer Name"
                                        value={data.customer_name || "-----"}
                                    />
                                </Col>

                                <Col xs={24} md={6}>
                                    <ViewInfo
                                        title="Customer Num."
                                        value={data.customer_contact_number_view || "-----"}
                                    />
                                </Col>


                            <Col xs={24} md={6}>
                                <ViewInfo
                                    title="Customer Email"
                                    value={data.customer_email || "-----"}
                                />
                            </Col>


                            <Col xs={24} md={6}>
                                    <ViewInfo
                                        title="Customer Type"
                                        value={data.is_customer?"Registered Customer":"Non-Registered Customer"}
                                    />
                                </Col>


                                <Col xs={24} className="mt-1 mb-0">
                                    <Divider className="mt-0 mb-0"/>
                                </Col>

                            <Col xs={24} md={6}>
                                <ViewInfo
                                    title="Voucher Code"
                                    value={data.voucher_code}
                                />
                            </Col>

                                <Col xs={24} md={6}>
                                    <ViewInfo
                                        title="Total Price"
                                        value={data.total_price}
                                        addonAfter={data.currency}
                                    />
                                </Col>

                                <Col xs={24} md={6}>
                                    <ViewInfo
                                        title="Total Discount"
                                        value={data.total_discount}
                                        addonAfter={data.currency}
                                    />
                                </Col>

                                <Col xs={24} md={6}>
                                    <ViewInfo
                                        title="Net. Total"
                                        value={data.net_total}
                                        addonAfter={data.currency}
                                    />
                                </Col>


                                <Col xs={24} md={6}>
                                    <ViewInfo
                                        title="Payment Method"
                                        value={data.payment_method}
                                    />
                                </Col>


                                <Col xs={24} md={6}>
                                    <ViewInfo
                                        title="Payment Date"
                                        value={data.payment_date_view}
                                    />
                                </Col>

                                <Col xs={24} md={6}>
                                    <ViewInfo
                                        title="Payment Ref No"
                                        value={data.payment_ref_no}
                                    />
                                </Col>
                        </Row>
                    </Col>



                    <Col xs={24} className="mt-4">
                        <h2 className="font-size-18 mb-0">
                            <label className="text-start">
                                Products Sale Items
                            </label>
                        </h2>
                    </Col>

                    {(data.ProductSaleItems || []).map((item, index)=>(
                        <Col xs={24} className="mb-3">
                            <Row key={index} gutter={[12, 10]} className="border ps-2 pe-2 pt-3 pb-3 border-radius-10 bg-light">

                                <Col xs={24} md={6}>
                                    <ViewInfo
                                        title="Ref No"
                                        value={item.ref_no}
                                    />
                                </Col>

                                <Col xs={24} md={6}>
                                    <ViewInfo
                                        title={`Title ${upperCaseView(language)}`}
                                        value={item[`product_title_${language}`]}
                                    />
                                </Col>


                                <Col xs={24} md={6}>
                                    <ViewInfo
                                        title="Downloads"
                                        value={`${item.number_of_downloads} / ${item.download_limit}`}
                                    />
                                </Col>

                                <Col xs={24} md={6}>
                                    <ViewInfo
                                        title="File Source Type"
                                        value={item.file_source_type}
                                    />
                                </Col>


                                <Col xs={24} className="mt-1 mb-0">
                                    <Divider className="mt-0 mb-0"/>
                                </Col>


                                <Col xs={24} md={6}>
                                    <ViewInfo
                                        title="Status"
                                        value={item.status}
                                    />
                                </Col>

                                <Col xs={24} md={6}>
                                    <ViewInfo
                                        title="Price Per Item"
                                        value={`${item.price_per_item} ${data.currency}`}
                                    />
                                </Col>

                                <Col xs={24} md={6}>
                                    <ViewInfo
                                        title="Discount Per Item"
                                        value={`${item.discount_amount_per_item} ${data.currency}`}
                                    />
                                </Col>

                                {/*<Col xs={24} md={6}>*/}
                                {/*    <ViewInfo*/}
                                {/*        title="Qty"*/}
                                {/*        value={item.qty}*/}
                                {/*    />*/}
                                {/*</Col>*/}


                                <Col xs={24} md={6}>
                                    <ViewInfo
                                        title="Net Total"
                                        value={`${item.net_total} ${data.currency}`}
                                    />
                                </Col>

                                <Col xs={24} className="mt-1 mb-1">
                                    <Divider className="mt-0 mb-0"/>
                                </Col>

                                    <Col xs={24} className="d-md-flex align-items-center justify-content-end">

                                    <div className="d-flex justify-content-center">
                                    <Button
                                        type="primary"
                                        block
                                        onClick={()=>openChangeDownloadLimit(item)}
                                    >
                                        Change Download Limit
                                    </Button>
                                    </div>
                                </Col>

                            </Row>
                        </Col>
                    ))}


                </Hide>


                <Col xs={24}>
                    <ActionButton
                        onCancel={onCancel}
                    />
                </Col>

            </Row>
        </div>
    )
}
