import React from "react";
import {Rate} from "antd";
import CustomFieldWrapper from "../CustomFieldWrapper";
import Hide from "../Hide";
import {isValidValue} from "../../helper_functions";

const CustomRate = (props) => {
  const handleFieldChange = (value) => {
      if (!props.disabled && !props.view_only){
            props.onChange(props.name, value);
      }
  };

  return (
    <CustomFieldWrapper {...props} inline container_class_name={`h-100 ${props.container_class_name}`}>
       <Hide hide={!isValidValue(props.label)}>: </Hide> <Rate allowHalf={props.allowHalf} disabled={props.disabled || props.view_only} className="ms-2" onChange={handleFieldChange} value={props.value} />
    </CustomFieldWrapper>
  );
};

export default CustomRate;
